import { Button, Stack } from "@mui/material";

interface ControlsProps {
  isDeleteDisabled: boolean;
  onStartCreate: () => void;
  onStartDelete: () => void;
  setShowAuditTrail: (value: boolean) => void;
}

export function Controls({
  isDeleteDisabled,
  onStartCreate,
  onStartDelete,
  setShowAuditTrail,
}: ControlsProps) {
  return (
    <Stack spacing={1}>
      <Button variant="contained" onClick={onStartCreate}>
        Create
      </Button>
      <Button
        variant="outlined"
        onClick={onStartDelete}
        disabled={isDeleteDisabled}
      >
        Delete
      </Button>
      <Button onClick={() => setShowAuditTrail(true)} variant="outlined">
        Audit Trail
      </Button>
    </Stack>
  );
}
