import { Card, CardContent } from "@mui/material";
import { styled } from "@mui/system";
import { theme } from "@src/constants/theme";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const Root = styled("div")({
  backgroundColor: "rgba(255, 255, 255, 1)",
  padding: "10px",
  color: "#212121",
  fontSize: "16px",
  boxShadow: "0px 9px 28px 8px #0000000D",
  minWidth: "150px",
  "& .label": {
    fontWeight: "500",
    margin: 0,
    marginBottom: "5px",
  },
  "& .intro": {
    display: "flex",
    justifyContent: "space-between",
    // fontFamily: "Alegreya SC",
    fontSize: "14px",
    lineHeight: 1.4,
    margin: 0,
  },
  "& .dot": {
    position: "relative",
    paddingLeft: "15px",
    "&::before": {
      content: '""',
      position: "absolute",
      backgroundColor: "#B7935D",
      borderRadius: "50%",
      width: "10px",
      height: "10px",
      top: "50%",
      transform: "translateY(-50%)",
      left: 0,
    },
  },
  "& .bold-font": {
    fontWeight: "500",
  },
});

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <Root className="custom-tooltip">
        <p className="label">{`${label}`}</p>
        <p className="intro">
          <span className="dot">Touchdown</span>{" "}
          <span className="bold-font">{payload[0].value}</span>
        </p>
      </Root>
    );
  }

  return null;
};

const TouchdownChart = (props: any) => {
  const { data } = props;

  return (
    <Card
      elevation={0}
      sx={{
        border: "1px solid #EEE8DC",
        padding: theme.spacing(1),
        marginBottom: theme.spacing(3),
      }}
    >
      <CardContent>
        <h2>Created Touchdown</h2>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            width={150}
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis dataKey="month" />
            <YAxis domain={[0, 200]} ticks={[0, 50, 100, 150, 200]} />
            <Tooltip content={<CustomTooltip />} />
            <Bar dataKey="count" fill="#B69056" />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default TouchdownChart;
