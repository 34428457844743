import WestIcon from "@mui/icons-material/West";
import { Box, Chip, Typography, styled } from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { EST_TIME_ZONE } from "@src/constants/Touchdown";
import { theme } from "@src/constants/theme";
import { useGetDailyLeaderBoardQuery } from "@src/graphql/graphql.generated";
import { DailyLeaderboardProps } from "@src/types/touchdown/dashboard";
import dayjs from "dayjs";
import { useMemo } from "react";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: "1px solid #EEE8DC",
  "& .MuiDataGrid-cell": {
    borderBottom: "1px solid #EEE8DC",
  },
  "& .MuiDataGrid-columnHeaders": {
    borderBottom: "1px solid #EEE8DC",
  },
  "& .MuiDataGrid-footerContainer": {
    borderTop: "1px solid #EEE8DC",
  },
}));

export function DailyLeaderboard(props: DailyLeaderboardProps) {
  const { title, prizePoolId, onBack } = props;

  const { data: leaderboardData } = useGetDailyLeaderBoardQuery({
    prizePoolId: prizePoolId,
    searchStr: "",
  });

  const COLUMNS: GridColumns = useMemo<GridColumns>(
    () => [
      {
        field: "leaderboardRank",
        headerName: "Rank",
        width: 200,
        renderCell: (params) => (
          <>
            &nbsp; &nbsp;
            <span style={{ fontSize: "16px" }}>{params.value}</span>
            &nbsp; &nbsp;
            {params.row.rank === 1 && params.row.score > 0 ? (
              <span
                style={{
                  color: "white",
                }}
              >
                {params.row.score === 7 ? (
                  <>
                    <span
                      style={{
                        background: "#146D34",
                        padding: "4px 6px",
                        borderRadius: "20px",
                        marginInlineEnd: "5px",
                      }}
                    >
                      Jackpot
                    </span>
                    <span
                      style={{
                        background: "#B69056",
                        padding: "4px 6px",
                        borderRadius: "20px",
                      }}
                    >
                      GPP
                    </span>
                  </>
                ) : (
                  <span
                    style={{
                      background: "#B69056",
                      padding: "4px 6px",
                      borderRadius: "20px",
                    }}
                  >
                    GPP
                  </span>
                )}
              </span>
            ) : (
              ""
            )}
          </>
        ),
      },
      {
        field: "winAmount",
        headerName: "Winning Amount",
        width: 200,
        renderCell: (params) => (
          <span>
            {params.value > 0 ? (
              <> ${parseFloat(params.value).toFixed(2)}</>
            ) : (
              "-"
            )}
          </span>
        ),
      },

      { field: "name", headerName: "User", width: 200 },
      { field: "score", headerName: "Points", width: 200 },
      {
        field: "status",
        headerName: "Status",
        width: 200,
        renderCell: (params) => {
          if (params.value === "-") return "-";

          let backgroundColor, borderColor, color;
          if (params.value === "Success") {
            backgroundColor = "#eaf1ed";
            borderColor = "rgba(20, 109, 52, 1)";
            color = "rgba(20, 109, 52, 1)";
          } else {
            backgroundColor = "rgba(255, 229, 231, 1)";
            borderColor = "rgba(245, 34, 45, 1)";
            color = "rgba(245, 34, 45, 1)";
          }

          return (
            <Chip
              label={params.value}
              sx={{
                fontWeight: "500",
                borderRadius: "5px",
                backgroundColor,
                color,
                border: `1px solid ${borderColor}`,
                height: "auto",
              }}
            />
          );
        },
      },
      {
        field: "payout",
        headerName: "Payout",
        width: 200,
        renderCell: (params) => (
          <span>
            {params?.value && params.value !== "-"
              ? dayjs(params.value)
                  .tz(EST_TIME_ZONE)
                  .format("MM-DD-YYYY, h:mm A")
              : params.value}
          </span>
        ),
      },
    ],
    []
  );

  return (
    <>
      <Box marginBottom={theme.spacing(3)}>
        <Typography
          style={{
            display: "flex",
            alignItems: "center",
            color: "#212121",
            textDecoration: "none",
            cursor: "pointer",
          }}
          // onClick={openModal}
          onClick={onBack}
        >
          <WestIcon
            style={{ fontSize: "16px", marginRight: theme.spacing(1) }}
          />{" "}
          Back
        </Typography>
      </Box>
      <Box marginBottom={theme.spacing(3)}>
        <Typography
          variant="h5"
          fontWeight={500}
          marginBottom={theme.spacing(3)}
        >
          {title}
        </Typography>
      </Box>
      <Box
        style={{ height: "550px", overflow: "auto" }}
        marginBottom={theme.spacing(3)}
      >
        <StyledDataGrid
          columns={COLUMNS}
          initialState={{
            sorting: {
              sortModel: [{ field: "startDate", sort: "asc" }],
            },
          }}
          rows={leaderboardData?.getDailyLeaderBoard.data || []}
          pageSize={10}
          pagination
        />
      </Box>
    </>
  );
}
