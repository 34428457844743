import CloudOffIcon from "@mui/icons-material/CloudOff";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WestIcon from "@mui/icons-material/West";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import TimePicker from "@organisms/Pickers/TimePicker";
import WeekPicker from "@organisms/Pickers/WeekPicker";
import { QueryKeys } from "@src/constants/QueryKeys";
import { EST_TIME_ZONE, YYYY_MM_DD } from "@src/constants/Touchdown";
import { theme } from "@src/constants/theme";
import {
  PrizePoolStatus,
  TouchdownType,
  useCreatePrizePoolMutation,
  useCreateTouchdownMutation,
  useGetMathConstantQuery,
  useGetTouchdownByDateQuery,
  useGetWeeklyConstantQuery,
  useLivePrizePoolMutation,
  useUpdatePrizePoolMutation,
} from "@src/graphql/graphql.generated";
import {
  PrizePoolCreationFields,
  TouchdownCreationFields,
  TouchdownFormValues,
  WeeklyPrizeObj,
  initPrizePoolCreationData,
  initTouchdownCreationData,
} from "@src/types/touchdown/contest";
import {
  PrizePoolDetails,
  TouchdownDetails,
} from "@src/types/touchdown/dashboard";
import { NumberValidationSchema, parseGQLError } from "@src/utils/parse-error";
import dayjs from "dayjs";
import "dayjs/locale/en";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { ContestCreation } from "./ContestCreation";
import { WeeklyPrizeDistribution } from "./WeeklyPrizeDistribution";

const StyledLabel = styled("label")(({ theme }) => ({
  color: "#212121",
  fontSize: "14px",
}));

const StyledTableCell = styled(TableCell)(({ theme: any }) => ({
  border: "none",
  padding: theme.spacing(1),
  "&.table_head": {
    fontSize: "16px",
  },
}));

const StyledInput = styled(TextField)(({ theme: any }) => ({
  marginTop: theme.spacing(1),
  "& input": {
    padding: "9px 12px 9px 12px",

    "&:focus": {
      outline: `none`,
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: `#E2D3BB !important`,
    "&:focus": {
      outline: `none`,
    },
  },
  "& .MuiOutlinedInput-root.Mui-error": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `#d32f2f !important`,
    },
  },
  "& .MuiFormHelperText-root.Mui-error": {
    fontSize: "12px",
    margin: "3px",
  },
}));

const getDailyMetrics = (mathConstants: { [key: string]: number }) => {
  const { PRIZE_POOL, GPP, WEEKLY_RESERVE } = mathConstants;

  const dailyMetrics = [
    {
      labelName: "Prize pool",
      formItemName: "predeterminePrizePool",
      tooltipValue: `Formula to count prize pool: \n ( Entry fee * Entrants ) * ${PRIZE_POOL}`,
    },
    {
      labelName: "Jackpot",
      formItemName: "predetermineJackpot",
      tooltipValue: `Formula to count jackpot: \n ( Prize pool - Weekly Reserve \n - GPP )`,
    },
    {
      labelName: "GPP",
      formItemName: "predetermineDailyGPP",
      tooltipValue: `Formula to calculate GPP: \n (${GPP} % Prize Pool )`,
    },
    {
      labelName: "Weekly reserve",
      formItemName: "predetermineWeeklyReserve",
      tooltipValue: `Formula to calculate weekly reserve: \n (${WEEKLY_RESERVE} % Prize Pool )`,
    },
    {
      labelName: "Contest vig",
      formItemName: "predetermineContestVig",
      tooltipValue: `Formula to calculate contest vig: \n ( Entrants * Entry Fee ) - Prize Pool`,
    },
  ];

  return dailyMetrics;
};

const getCompletedMetrics = (
  mathConstants: { [key: string]: number },
  activePrizePool: PrizePoolCreationFields
) => {
  const {
    predeterminePrizePool,
    predetermineJackpot,
    predetermineDailyGPP,
    predetermineWeeklyReserve,
    predetermineContestVig,
    actualPrizePool,
    actualJackpot,
    actualDailyGPP,
    actualWeeklyReserve,
    actualContestVig,
    userEntryCount,
    totalEntrants,
  } = activePrizePool;
  const { PRIZE_POOL, GPP, WEEKLY_RESERVE } = mathConstants;

  const completedMetrics = [
    {
      name: "Prize Pool",
      actual: actualPrizePool || 0,
      predetermine: predeterminePrizePool || 0,
      tooltipValue: `Formula to count prize pool:\n ( Entry fee * Entrants ) * ${PRIZE_POOL}`,
    },
    {
      name: "Jackpot",
      actual: actualJackpot || 0,
      predetermine: predetermineJackpot || 0,
      tooltipValue: `Formula to count Jackpot: \n ( Prize pool - Weekly Reserve \n - GPP )`,
    },
    {
      name: "GPP",
      actual: actualDailyGPP || 0,
      predetermine: predetermineDailyGPP || 0,
      tooltipValue: `Formula to calculate GPP: \n (${GPP} % Prize Pool )`,
    },
    {
      name: "Weekly reserve",
      actual: actualWeeklyReserve || 0,
      predetermine: predetermineWeeklyReserve || 0,
      tooltipValue: `Formula to calculate weekly reserve:\n (${WEEKLY_RESERVE} % Prize Pool )`,
    },
    {
      name: "Contest vig",
      actual: actualContestVig || 0,
      predetermine: predetermineContestVig || 0,
      tooltipValue: `Formula to calculate contest vig: \n ( Entrants * Entry Fee ) - Prize Pool`,
    },
    {
      name: "Entry",
      actual: userEntryCount || 0,
      predetermine: totalEntrants || 0,
      tooltipValue: `Maximum number of entries`,
    },
  ];

  return completedMetrics;
};

const EmptyContainer = () => (
  <Box
    sx={{
      marginTop: theme.spacing(3),
      dispaly: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      border: "1px solid #EEE8DC",
      backgroundColor: "#fff",
      padding: `${theme.spacing(12)} ${theme.spacing(2)}`,
    }}
  >
    <CloudOffIcon
      sx={{
        fontSize: "60px",
        opacity: "0.4",
        color: theme.palette.primary.main,
      }}
    />
    <Typography>Please select week to proceed further</Typography>
  </Box>
);

const statusToStyleMap: any = {
  COMPLETED_TEXT: "#146D34",
  COMPLETED_BORDER: "#D1E3D8",
  COMPLETED_BACKGROUND: "#e2efe7",
  LIVE_TEXT: "#EB1745",
  LIVE_BORDER: "#f2d6dc",
  LIVE_BACKGROUND: "#ffe5e7",
  DRAFT_TEXT: "#B69056",
  DRAFT_BORDER: "#F3DDBB",
  DRAFT_BACKGROUND: "#FFF3E0",
  PENDING_TEXT: "#F6C000",
  PENDING_BORDER: "#FCEAAC",
  PENDING_BACKGROUND: "#FFF7DA",
};

function TouchdownCreation() {
  /* States  */
  const [weekRange, setWeekRange] = useState<Array<dayjs.Dayjs> | []>([]);
  const [disable, setDisable] = useState<boolean>(true);
  const [activeBox, setActiveBox] = useState<number>(0);
  const [touchdownInfo, setTouchdownInfo] = useState<TouchdownCreationFields>();
  const [mathConstantInfo, setMathConstantInfo] = useState<{
    [key: string]: number;
  }>();
  const [weeklyPrize, setWeeklyPrize] = useState<WeeklyPrizeObj>();
  const [selectedPrizePoolId, setSelectedPrizePoolId] = useState<number>(0);
  const [selectedDate, setSelectedDate] = useState<string | null>();

  /* Hooks */
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
    clearErrors,
    control,
  } = useForm<TouchdownFormValues>({
    defaultValues: {
      predeterminePrizePool: 0,
      predetermineJackpot: 0,
      predetermineDailyGPP: 0,
      predetermineWeeklyReserve: 0,
      predetermineContestVig: 0,
    },
    mode: "all",
  });

  /* Queries */
  const queryClient = useQueryClient();
  const { data: weeklyTouchdownData, isLoading } = useGetTouchdownByDateQuery({
    startDate: weekRange?.[0]?.format("YYYY-MM-DD") || "",
    endDate: weekRange?.[1]?.format("YYYY-MM-DD") || "",
  });
  const { data: mathConstants } = useGetMathConstantQuery();
  const { data: weeklyConstants } = useGetWeeklyConstantQuery();

  /* Mutations */
  const { mutate: createTouchdownMutate, isLoading: createTouchdownLoading } =
    useCreateTouchdownMutation();
  const { mutate: createPrizePoolMutate, isLoading: createPrizePoolLoading } =
    useCreatePrizePoolMutation();
  const { mutate: updatePrizePoolMutate, isLoading: updatePrizePoolLoading } =
    useUpdatePrizePoolMutation();
  const { mutate: publishPrizePoolMutate } = useLivePrizePoolMutation();

  const handleDateChange = (selectedDate: dayjs.Dayjs | null) => {
    if (selectedDate) {
      const weekStartDate = selectedDate.startOf("week");
      const weekEndDate = selectedDate.endOf("week");
      setSelectedDate(null);
      setSelectedPrizePoolId(0);
      setWeekRange([weekStartDate, weekEndDate]);
      setActiveBox(0);
    }
  };

  const updateFormValues = (prizePool: PrizePoolCreationFields) => {
    const {
      entryFees,
      predeterminePrizePool,
      predetermineJackpot,
      predetermineDailyGPP,
      predetermineWeeklyReserve,
      predetermineContestVig,
      totalEntrants,
      maxEntriesPerUser,
      lockTime,
    } = prizePool;

    setValue("lockTime", lockTime);
    setValue("entryFees", entryFees);
    setValue("totalEntrants", totalEntrants);
    setValue("maxEntriesPerUser", maxEntriesPerUser);
    setValue("predetermineJackpot", predetermineJackpot);
    setValue("predeterminePrizePool", predeterminePrizePool);
    setValue("predetermineDailyGPP", predetermineDailyGPP);
    setValue("predetermineWeeklyReserve", predetermineWeeklyReserve);
    setValue("predetermineContestVig", predetermineContestVig);
  };

  const updateTouchdownData = (
    touchdownData: TouchdownDetails,
    weekDates: dayjs.Dayjs[]
  ) => {
    let totalWeeklyReserve: number = 0;
    let totalActualWeeklyReserve: number = 0;
    let totalActualTopPropVig: number = 0;

    const mondayPrizePool: PrizePoolDetails | null | undefined =
      touchdownData?.prizePools?.find(({ startDate }) =>
        weekRange[0].isSame(startDate, "d")
      );

    const prizePools: Array<PrizePoolCreationFields> = weekDates.map((date) => {
      const foundedPrizePool = touchdownData?.prizePools?.find(
        ({ startDate }) => dayjs(startDate).isSame(date)
      );

      const newPrizePoolCreation: PrizePoolCreationFields = {
        ...initPrizePoolCreationData,
        startDate: date,
      };

      if (!foundedPrizePool) {
        totalWeeklyReserve += mondayPrizePool?.predetermineWeeklyReserve || 0;
        return newPrizePoolCreation;
      }

      const {
        prizePoolId,
        entryFees,
        totalEntrants,
        supportedSports,
        maxEntriesPerUser,
        predeterminePrizePool,
        predetermineDailyGPP,
        predetermineWeeklyReserve,
        predetermineJackpot,
        predetermineContestVig,
        status,
        actualPrizePool,
        actualJackpot,
        actualWeeklyReserve,
        actualContestVig,
        actualRolloverVig,
        actualDailyGPP,
        userEntryCount,
        nflContestsCount,
        nbaContestsCount,
        soccerContestsCount,
        lockTime,
      } = foundedPrizePool;

      newPrizePoolCreation.prizePoolId = prizePoolId;
      newPrizePoolCreation.startDate = date;
      newPrizePoolCreation.entryFees = entryFees;
      newPrizePoolCreation.lockTime = lockTime
        ? dayjs(lockTime).tz(EST_TIME_ZONE)
        : null;
      newPrizePoolCreation.maxEntriesPerUser = maxEntriesPerUser;
      newPrizePoolCreation.totalEntrants = totalEntrants;
      newPrizePoolCreation.supportedSports = supportedSports;
      newPrizePoolCreation.predeterminePrizePool = predeterminePrizePool;
      newPrizePoolCreation.predetermineDailyGPP = predetermineDailyGPP;
      newPrizePoolCreation.predetermineWeeklyReserve =
        predetermineWeeklyReserve;
      newPrizePoolCreation.predetermineJackpot = predetermineJackpot;
      newPrizePoolCreation.predetermineContestVig = predetermineContestVig;
      newPrizePoolCreation.status = status;
      newPrizePoolCreation.actualPrizePool = actualPrizePool ?? 0;
      newPrizePoolCreation.actualDailyGPP = actualDailyGPP ?? 0;
      newPrizePoolCreation.actualWeeklyReserve = actualWeeklyReserve ?? 0;
      newPrizePoolCreation.actualJackpot = actualJackpot ?? 0;
      newPrizePoolCreation.actualContestVig = actualContestVig ?? 0;
      newPrizePoolCreation.actualRolloverVig = actualRolloverVig ?? 0;
      newPrizePoolCreation.userEntryCount = userEntryCount;
      newPrizePoolCreation.nflContestsCount = nflContestsCount;
      newPrizePoolCreation.nbaContestsCount = nbaContestsCount;
      newPrizePoolCreation.soccerContestsCount = soccerContestsCount;

      totalActualTopPropVig += actualRolloverVig ?? 0;
      totalActualTopPropVig += actualContestVig ?? 0;
      totalWeeklyReserve += predetermineWeeklyReserve;
      totalActualWeeklyReserve += actualWeeklyReserve ?? 0;

      return newPrizePoolCreation;
    });

    const currentPrizePool =
      (selectedPrizePoolId > 0 &&
        prizePools.find(
          ({ prizePoolId }) => prizePoolId === selectedPrizePoolId
        )) ||
      prizePools?.[0];

    const {
      touchdownId,
      touchDownType,
      mathConstant,
      weeklyPrizeDistribution,
    } = touchdownData;
    const { prizePoolId, status, startDate } = currentPrizePool;

    const touchdownInfo: TouchdownCreationFields = {
      prizePools: prizePools,
      startDate: weekDates[0],
      endDate: weekDates[6],
      totalWeeklyReserve: totalWeeklyReserve,
      totalActualWeeklyReserve,
      totalTopPropVig: 0,
      totalActualTopPropVig,
      touchdownId: touchdownId,
      // weeklyPrize: weeklyPrize,
      touchDownType: touchDownType,
    };

    setSelectedDate(startDate?.format(YYYY_MM_DD));
    setSelectedPrizePoolId(prizePoolId);
    setDisable(["COMPLETED", "LIVE"].includes(status));
    setTouchdownInfo({ ...touchdownInfo });
    setMathConstantInfo({ ...mathConstant });
    setWeeklyPrize({ ...weeklyPrizeDistribution });
    updateFormValues(currentPrizePool);
  };

  const initTouchdownData = (weekDates: dayjs.Dayjs[]) => {
    const prizePools: PrizePoolCreationFields[] = weekDates.map(
      (date: dayjs.Dayjs) => ({
        ...initPrizePoolCreationData,
        startDate: date,
      })
    );

    const touchdownInfo: TouchdownCreationFields = {
      ...initTouchdownCreationData,
      prizePools: prizePools,
      startDate: weekDates[0],
      endDate: weekDates[6],
    };

    const currentPrizePool = prizePools[0];
    setSelectedDate(currentPrizePool.startDate?.format(YYYY_MM_DD));
    setDisable(["COMPLETED", "LIVE"].includes(currentPrizePool.status));
    setWeeklyPrize({ prizes: weeklyConstants!.getWeeklyConstant });
    setTouchdownInfo(touchdownInfo);
    updateFormValues(currentPrizePool);
  };

  useEffect(() => {
    if (weeklyTouchdownData?.getTouchdownByDate && weekRange.length) {
      const touchdownData = weeklyTouchdownData.getTouchdownByDate?.[0];

      const weekDates: dayjs.Dayjs[] = [];
      let mondayDate = dayjs(weekRange[0])
        .tz(EST_TIME_ZONE, true)
        .startOf("day");
      const sundayDate = dayjs(weekRange[1])
        .tz(EST_TIME_ZONE, true)
        .endOf("day");
      while (mondayDate.isBefore(sundayDate) || mondayDate.isSame(sundayDate)) {
        weekDates.push(mondayDate.utc());
        mondayDate = mondayDate.add(1, "day");
      }

      if (touchdownData) {
        updateTouchdownData(touchdownData, weekDates);
      } else {
        initTouchdownData(weekDates);
      }
    }
  }, [weeklyTouchdownData?.getTouchdownByDate]);

  useEffect(() => {
    const mathConstantList = mathConstants?.getMathConstant;
    if (mathConstantList?.length) {
      const constantObj = mathConstantList.reduce(
        (acc: { [key: string]: number }, { name, value }) => {
          acc[name] = value;
          return acc;
        },
        {}
      );
      setMathConstantInfo(constantObj);
    }
  }, [mathConstants?.getMathConstant]);

  const showPublishButton = useCallback(() => {
    let currentEstTimeInUtc = dayjs().tz(EST_TIME_ZONE).utc();
    const { status, startDate } = touchdownInfo?.prizePools?.[activeBox] || {};
    return (
      currentEstTimeInUtc.hour() >= 9 &&
      currentEstTimeInUtc.minute() > 5 &&
      currentEstTimeInUtc.format(YYYY_MM_DD) ===
        startDate?.format(YYYY_MM_DD) &&
      PrizePoolStatus.Draft === status
    );
  }, [activeBox, touchdownInfo?.prizePools]);

  const calculateValues = () => {
    const { PRIZE_POOL, GPP, WEEKLY_RESERVE }: any = mathConstantInfo;
    const { entryFees, totalEntrants } = getValues();

    if (totalEntrants && entryFees) {
      const totalPrizePool = totalEntrants * entryFees;

      const prizePoolAmount = parseFloat(
        (totalPrizePool * PRIZE_POOL).toFixed(2)
      );

      const gppAmount = parseFloat((GPP * totalPrizePool).toFixed(2)) / 100;
      const weeklyReserveAmount =
        parseFloat((WEEKLY_RESERVE * totalPrizePool).toFixed(2)) / 100;

      const jackpotAmount = parseFloat(
        (prizePoolAmount - weeklyReserveAmount - gppAmount).toFixed(2)
      );
      const contestVig = parseFloat(
        (totalPrizePool - prizePoolAmount).toFixed(2)
      );

      setValue("predeterminePrizePool", prizePoolAmount);
      setValue("predetermineJackpot", jackpotAmount);
      setValue("predetermineDailyGPP", gppAmount);
      setValue("predetermineWeeklyReserve", weeklyReserveAmount);
      setValue("predetermineContestVig", contestVig);
    }
  };

  const handlePrizePoolChange = (
    index: number,
    data: Partial<PrizePoolCreationFields>
  ) => {
    const { prizePoolId, status, startDate: selectedDate } = data;
    const selectedPrizePool: PrizePoolCreationFields | undefined =
      touchdownInfo?.prizePools.find(({ startDate }) =>
        startDate?.isSame(selectedDate)
      );

    if (!selectedPrizePool) return;

    // GPP and WeeklyReserve is same over the week based on Monday
    if (index !== 0 && selectedPrizePool.status === "PENDING") {
      const mondayPrizePool: PrizePoolCreationFields | undefined =
        touchdownInfo?.prizePools.find(({ startDate }) =>
          weekRange[0].isSame(startDate, "d")
        );

      if (mondayPrizePool) {
        const { predetermineDailyGPP, predetermineWeeklyReserve } =
          mondayPrizePool;
        selectedPrizePool.predetermineDailyGPP = predetermineDailyGPP;
        selectedPrizePool.predetermineWeeklyReserve = predetermineWeeklyReserve;
      }
    }

    clearErrors();
    setActiveBox(index);
    setSelectedDate(selectedDate?.format(YYYY_MM_DD));
    setSelectedPrizePoolId(prizePoolId || 0);
    setDisable(["COMPLETED", "LIVE"].includes(status || ""));
    updateFormValues(selectedPrizePool);
  };

  const metricsData = mathConstantInfo
    ? touchdownInfo?.prizePools?.[activeBox]?.status ===
      PrizePoolStatus.Completed
      ? getCompletedMetrics(
          mathConstantInfo,
          touchdownInfo?.prizePools?.[activeBox]
        )
      : getDailyMetrics(mathConstantInfo)
    : [];

  const createTouchdown = (values: PrizePoolCreationFields) => {
    if (activeBox !== 0) {
      enqueueSnackbar("First create the monday touchdown !", {
        variant: "error",
      });

      return;
    }
    const [weekStart, weekEnd] = weekRange;
    const {
      supportedSports,
      predetermineDailyGPP,
      predeterminePrizePool,
      predetermineJackpot,
      predetermineWeeklyReserve,
      predetermineContestVig,
      entryFees,
      totalEntrants,
      maxEntriesPerUser,
      lockTime,
    } = values;

    const inputData: any = {
      startDate: weekStart.format(YYYY_MM_DD),
      endDate: weekEnd.format(YYYY_MM_DD),
      supportedSports,
      predetermineDailyGPP,
      predeterminePrizePool,
      predetermineJackpot,
      predetermineWeeklyReserve,
      predetermineContestVig,
      entryFees,
      lockTime,
      totalEntrants,
      maxEntriesPerUser,
      mathConstant: mathConstantInfo,
      touchdownType: TouchdownType.SevenForSeven,
      weeklyPrizeDistribution: weeklyPrize,
    };

    createTouchdownMutate(
      { data: inputData },
      {
        onSuccess: () => {
          enqueueSnackbar("Touchdown created successfully !", {
            variant: "success",
          });
          queryClient.invalidateQueries(QueryKeys.GetTouchdownByDate);
        },
        onError: (error: any) => {
          enqueueSnackbar(parseGQLError(error, "Unable to create Touchdown."), {
            variant: "error",
          });
        },
      }
    );
  };

  const createOrUpdatePrizePool = (data: any, prizePoolId: number) => {
    if (prizePoolId) {
      updatePrizePoolMutate(
        { data, prizePoolId },
        {
          onSuccess: () => {
            enqueueSnackbar("PrizePool updated successfully !", {
              variant: "success",
            });
            queryClient.invalidateQueries(QueryKeys.GetTouchdownByDate);
          },
          onError: (error: any) => {
            enqueueSnackbar(
              parseGQLError(error, "Unable to update PrizePool."),
              {
                variant: "error",
              }
            );
          },
        }
      );
    } else {
      createPrizePoolMutate(
        { data },
        {
          onSuccess: (data) => {
            enqueueSnackbar("PrizePool created successfully !", {
              variant: "success",
            });
            const { prizePoolId } = data.createPrizePool;
            setSelectedPrizePoolId(prizePoolId);
            queryClient.invalidateQueries(QueryKeys.GetTouchdownByDate);
          },
          onError: (error: any) => {
            enqueueSnackbar(
              parseGQLError(error, "Unable to create PrizePool."),
              {
                variant: "error",
              }
            );
          },
        }
      );
    }
  };

  const onFormSubmit = (data: TouchdownFormValues) => {
    const { supportedSports } = initPrizePoolCreationData;

    const updatedValues = Object.entries(data).reduce(
      (acc, [key, value]) => {
        acc[key] = Number(value);
        return acc;
      },
      { supportedSports } as any
    );

    const lockTime: dayjs.Dayjs = data.lockTime;
    updatedValues.lockTime = dayjs(selectedDate)
      .set("hour", lockTime.hour())
      .set("minute", lockTime.minute())
      .tz(EST_TIME_ZONE, true)
      .toISOString();

    const { touchdownId, prizePools } = touchdownInfo || {};
    if (touchdownId) {
      const prizeDate = dayjs(prizePools?.[activeBox].startDate).format(
        YYYY_MM_DD
      );
      updatedValues.touchdownId = touchdownId;
      updatedValues.status = "DRAFT";
      updatedValues.touchdownId = touchdownId;
      updatedValues.prizeDate = prizeDate;
      updatedValues.predetermineDailyJackpot = Number(data.predetermineJackpot);
      createOrUpdatePrizePool(updatedValues, selectedPrizePoolId);
    } else {
      createTouchdown(updatedValues);
    }
  };

  const publishTodayPrizePool = () => {
    publishPrizePoolMutate(
      {},
      {
        onSuccess: () => {
          enqueueSnackbar("Prize pool updated successfully. !", {
            variant: "success",
          });
          queryClient.invalidateQueries(QueryKeys.GetTouchdownByDate);
        },
        onError: (error: any) => {
          enqueueSnackbar(
            parseGQLError(error, "Unable to update prize pool."),
            {
              variant: "error",
            }
          );
        },
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Box marginBottom={theme.spacing(3)}>
        <Typography
          style={{
            display: "flex",
            alignItems: "center",
            color: "#212121",
            textDecoration: "none",
            cursor: "pointer",
          }}
          onClick={() => navigate("/touchdown/dashboard")}
        >
          <WestIcon
            style={{ fontSize: "16px", marginRight: theme.spacing(1) }}
          />{" "}
          Back
        </Typography>
      </Box>

      <Card
        elevation={0}
        sx={{
          border: "1px solid #EEE8DC",
          padding: theme.spacing(1),
          marginBottom: theme.spacing(3),
        }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <FormControl variant="standard" fullWidth>
                <StyledLabel htmlFor="SelectWeek">Select Week</StyledLabel>
                <WeekPicker handleDateChange={handleDateChange} />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl variant="standard" fullWidth>
                <StyledLabel htmlFor="EntryFee">Entry Fee</StyledLabel>
                <StyledInput
                  id="EntryFee"
                  disabled={disable}
                  aria-describedby="entry-fee"
                  InputProps={{
                    "aria-label": "entry fee",
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  {...register("entryFees", {
                    ...NumberValidationSchema,
                    onChange: calculateValues,
                  })}
                  error={!!errors?.entryFees}
                  helperText={errors?.entryFees?.message}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={7}
              justifyContent="flex-end"
              alignItems="end"
              display="flex"
            >
              <Button
                type="submit"
                variant="contained"
                size="large"
                sx={{ width: "140px", height: "40px" }}
                disabled={!weekRange.length || isLoading || disable}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {weekRange.length ? (
        <Box>
          {weeklyPrize?.prizes && (
            <WeeklyPrizeDistribution
              data={weeklyPrize?.prizes}
              title="Weekly Prize Distribution"
              totalWeeklyReserve={touchdownInfo?.totalWeeklyReserve || 0}
            />
          )}

          <Card
            elevation={0}
            sx={{ border: "1px solid #EEE8DC", padding: theme.spacing(1) }}
          >
            <CardContent>
              <Box marginBottom={theme.spacing(3)}>
                <FormControl variant="standard" fullWidth>
                  <Typography marginBottom={theme.spacing(1)} fontSize="14px">
                    Select Day
                  </Typography>
                  {touchdownInfo?.prizePools?.length ? (
                    <ToggleButtonGroup
                      color="primary"
                      exclusive
                      aria-label="Platform"
                      style={{ width: "100%" }}
                    >
                      {touchdownInfo.prizePools.map(
                        (
                          {
                            prizePoolId,
                            status,
                            startDate,
                          }: PrizePoolCreationFields,
                          index
                        ) => (
                          <ToggleButton
                            key={index}
                            value={startDate || ""}
                            style={{
                              margin: index !== 0 ? "0 8px" : "0",
                              borderRadius: "2px",
                              textAlign: "center",
                              padding: 0,
                              width: "100%",
                              flex: 1,
                              border: `1.5px solid ${
                                statusToStyleMap[
                                  activeBox === index
                                    ? `${status}_TEXT`
                                    : `${status}_BORDER`
                                ]
                              }`,
                              backgroundColor:
                                activeBox === index ? "transparent" : "#fff",
                            }}
                            disabled={
                              createTouchdownLoading ||
                              createPrizePoolLoading ||
                              updatePrizePoolLoading
                            }
                            onClick={() =>
                              handlePrizePoolChange(index, {
                                prizePoolId,
                                status,
                                startDate,
                              })
                            }
                          >
                            <Box sx={{ width: "100%" }}>
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                                sx={{ padding: `${theme.spacing(1)}` }}
                                color="black"
                              >
                                <Typography
                                  variant="body1"
                                  fontSize={20}
                                  fontWeight={600}
                                  sx={{
                                    marginRight: theme.spacing(1),
                                  }}
                                >
                                  {/* Display Date */}
                                  {dayjs(startDate).format("DD")}
                                </Typography>
                                {/* Display Day */}
                                {dayjs(startDate).format("ddd")}
                              </Stack>
                              <Typography
                                sx={{
                                  backgroundColor:
                                    statusToStyleMap[`${status}_BACKGROUND`],
                                  width: "100%",
                                  color: statusToStyleMap[`${status}_TEXT`],
                                  fontSize: "14px",
                                  fontWeight: "600",
                                }}
                              >
                                {status}
                              </Typography>
                            </Box>
                          </ToggleButton>
                        )
                      )}
                    </ToggleButtonGroup>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Box>
              <Box marginBottom={theme.spacing(3)}>
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <FormControl variant="standard" fullWidth>
                      <StyledLabel htmlFor="LockTime">Lock Time</StyledLabel>
                      <Controller
                        name="lockTime"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Please fill this field",
                          },
                        }}
                        render={({
                          field: { onChange, value },
                          fieldState,
                        }) => (
                          <TimePicker
                            value={value}
                            onChange={onChange}
                            disabled={disable}
                            style={{
                              input: {
                                padding: "9px 12px",
                              },
                            }}
                            error={fieldState?.error?.message}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl variant="standard" fullWidth>
                      <StyledLabel htmlFor="Entrants">Entrants</StyledLabel>
                      <StyledInput
                        id="totalEntrants"
                        disabled={disable}
                        aria-describedby="entrants"
                        InputProps={{
                          "aria-label": "entrants",
                        }}
                        placeholder="Enter Entrants"
                        {...register("totalEntrants", {
                          ...NumberValidationSchema,
                          onChange: calculateValues,
                        })}
                        error={!!errors?.totalEntrants}
                        helperText={errors?.totalEntrants?.message}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl variant="standard" fullWidth>
                      <StyledLabel htmlFor="MaxEntries">
                        Max Entries
                      </StyledLabel>
                      <StyledInput
                        id="maxEntriesPerUser"
                        disabled={disable}
                        aria-describedby="max-entries"
                        InputProps={{
                          "aria-label": "max entries",
                        }}
                        {...register(
                          "maxEntriesPerUser",
                          NumberValidationSchema
                        )}
                        error={!!errors?.maxEntriesPerUser}
                        helperText={errors?.maxEntriesPerUser?.message}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3} />
                  {showPublishButton() && (
                    <Grid
                      item
                      xs={3}
                      display={"flex"}
                      alignItems={"end"}
                      justifyContent={"end"}
                    >
                      <Button
                        variant="contained"
                        size="large"
                        sx={{ padding: "9px 35px" }}
                        disabled={!weekRange.length || isLoading || disable}
                        onClick={() => {
                          publishTodayPrizePool();
                        }}
                      >
                        Publish
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Box>
              <Box marginBottom={theme.spacing(9)}>
                <Grid container spacing={2}>
                  {/* ====================  Daily Metrics ==================== */}
                  <Grid item xs={6}>
                    <Typography variant="h5" fontWeight={500}>
                      Daily Metrics
                    </Typography>
                    <Box
                      sx={{
                        border: "1px solid #EEE8DC",
                        backgroundColor: "#FFFEFB",
                        padding: theme.spacing(2),
                        marginTop: theme.spacing(1),
                        height: "100%",
                      }}
                    >
                      <Grid container spacing={2}>
                        {touchdownInfo?.prizePools[activeBox].status ===
                        PrizePoolStatus.Completed ? (
                          <Table style={{ borderCollapse: "collapse" }}>
                            <TableHead>
                              <TableRow>
                                <StyledTableCell className="table_head"></StyledTableCell>
                                <StyledTableCell className="table_head">
                                  Pre
                                </StyledTableCell>
                                <StyledTableCell className="table_head">
                                  Post
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {metricsData?.map(
                                (
                                  {
                                    name,
                                    actual,
                                    predetermine,
                                    tooltipValue,
                                  }: any,
                                  index: number
                                ) => (
                                  <TableRow key={index}>
                                    <StyledTableCell>
                                      {name}{" "}
                                      {
                                        <Tooltip title={tooltipValue} arrow>
                                          <IconButton>
                                            <InfoOutlinedIcon fontSize="small" />
                                          </IconButton>
                                        </Tooltip>
                                      }
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {predetermine}
                                    </StyledTableCell>
                                    <StyledTableCell>{actual}</StyledTableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        ) : (
                          metricsData.map(
                            ({
                              labelName,
                              formItemName,
                              tooltipValue,
                            }: any) => (
                              <Grid item xs={6} key={formItemName}>
                                <FormControl variant="standard" fullWidth>
                                  <Stack direction="row" alignItems="center">
                                    <StyledLabel htmlFor={labelName}>
                                      {labelName}
                                    </StyledLabel>
                                    <Tooltip title={tooltipValue} arrow>
                                      <IconButton>
                                        <InfoOutlinedIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  </Stack>
                                  <StyledInput
                                    id={formItemName}
                                    disabled={disable}
                                    aria-describedby="prize pool"
                                    InputProps={{
                                      "aria-label": "prize pool",
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          $
                                        </InputAdornment>
                                      ),
                                    }}
                                    {...register(
                                      formItemName,
                                      NumberValidationSchema
                                    )}
                                    error={!!errors?.[formItemName]}
                                    helperText={errors?.[formItemName]?.message}
                                  />
                                </FormControl>
                              </Grid>
                            )
                          )
                        )}
                      </Grid>
                    </Box>
                  </Grid>

                  {/* ====================  Weekly Metrics ==================== */}

                  <Grid item xs={6}>
                    <Typography variant="h5" fontWeight={500}>
                      Weekly Metrics
                    </Typography>
                    <Box
                      sx={{
                        border: "1px solid #DBEBE1",
                        backgroundColor: "#FBFFFC",
                        padding: theme.spacing(2),
                        marginTop: theme.spacing(1),
                        height: "100%",
                      }}
                    >
                      <TableContainer
                        component={Paper}
                        style={{
                          background: "transparent",
                          boxShadow: "none",
                        }}
                      >
                        <Table style={{ borderCollapse: "collapse" }}>
                          <TableHead>
                            <TableRow>
                              <StyledTableCell className="table_head">
                                Days
                              </StyledTableCell>
                              <StyledTableCell className="table_head">
                                Weekly Reserve
                              </StyledTableCell>
                              <StyledTableCell className="table_head">
                                TopProp VIG
                                <Tooltip
                                  title={"Contest VIG + Rollover Vig"}
                                  arrow
                                >
                                  <IconButton
                                    sx={{ padding: 0, marginInlineStart: 1 }}
                                  >
                                    <InfoOutlinedIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {touchdownInfo?.prizePools?.map(
                              (
                                {
                                  startDate,
                                  actualContestVig,
                                  actualRolloverVig,
                                  actualWeeklyReserve,
                                },
                                index
                              ) => (
                                <TableRow key={index}>
                                  <StyledTableCell>
                                    {dayjs(startDate)
                                      .utc()
                                      .tz(EST_TIME_ZONE)
                                      .format("MM-DD-YYYY")}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {actualWeeklyReserve
                                      ? `$ ${actualWeeklyReserve}`
                                      : "-"}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {actualContestVig
                                      ? `$ ${
                                          actualContestVig + actualRolloverVig
                                        }`
                                      : "-"}
                                  </StyledTableCell>
                                </TableRow>
                              )
                            )}

                            {/* ===========  Weekly metrics total =========== */}

                            <TableRow sx={{ borderTop: "1px solid #D9D9D9" }}>
                              <StyledTableCell>Total</StyledTableCell>
                              <StyledTableCell>
                                ${touchdownInfo?.totalActualWeeklyReserve}
                              </StyledTableCell>
                              <StyledTableCell>
                                ${touchdownInfo?.totalActualTopPropVig}
                              </StyledTableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box marginBottom={theme.spacing(3)}>
                {selectedDate && touchdownInfo?.prizePools && (
                  <ContestCreation
                    selectedDate={selectedDate}
                    touchdownData={touchdownInfo}
                  />
                )}
              </Box>
            </CardContent>
          </Card>
        </Box>
      ) : (
        <EmptyContainer />
      )}
    </form>
  );
}

export default TouchdownCreation;
