import { Card, CardContent, Grid, Typography, styled } from "@mui/material";
import { theme } from "@src/constants/theme";
import { PartnerTouchDownDetailProps } from "@src/types/partner/dashboard";

const StyledCard = styled(Card)(({ theme }) => ({
  border: "1px solid #E2D3BB",
  padding: "15px",
  height: "100%",
  "&.profit": {
    backgroundColor: "#eaf1ed",
    border: "1px solid #8ab69a",
    "& h4": {
      color: "#8ab69a",
    },
  },
  "&.loss": {
    backgroundColor: "#FFE5E7",
    border: "1px solid #F5222D",
    "& h4": {
      color: "#F5222D",
    },
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  minHeight: "48px",
}));

export function TouchdownSummaryCard(props: PartnerTouchDownDetailProps) {
  const { index, title, value } = props;

  return (
    <Grid item xs={12} sm={6} md={6} lg key={index}>
      <StyledCard
        elevation={0}
      >
        <CardContent sx={{ padding: "0 !important" }}>
          <StyledTypography gutterBottom>{title}</StyledTypography>
          <Typography
            variant="h4"
            color={theme.palette.primary.main}
            fontWeight={theme.typography.fontWeightBold}
            fontSize={"24px"}
          >
            {typeof value === "string" ? "$" + value : value}
          </Typography>
        </CardContent>
      </StyledCard>
    </Grid>
  );
}
