import {
  Box,
  CardMedia,
  FormControl,
  Grid,
  IconButton,
  OutlinedInput,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { theme } from "@src/constants/theme";
import type { Identifier, XYCoord } from "dnd-core";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { ReactComponent as EditIcon } from "@src/icons/edit.svg";

const StyledInput = styled(OutlinedInput)(({ theme }) => ({
  marginTop: theme.spacing(1),
  "& input": {
    padding: "9px 12px 9px 12px",

    "&:focus": {
      outline: `none`,
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: `#E2D3BB !important`,
    "&:focus": {
      outline: `none`,
    },
  },
}));

interface DragItem {
  index: number;
  id: string;
  type: string;
}

interface SportCardProps {
  sport: any;
  sports: any;
  sportId: number;
  orderIndex: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  onDrop: () => void;
  onEdit: (selectedSportIndex: number) => void;
  onEnableOrDisable: (selectedSportIndex: number) => Promise<boolean>;
}

function SportCard(props: SportCardProps) {
  const { sport, sports, orderIndex, moveCard, onDrop, sportId, onEdit } =
    props;

  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: "card",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = orderIndex;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop() {
      onDrop();
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "card",
    item: () => {
      return { id: sportId, index: orderIndex };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  return (
    <Box
      sx={{
        border: "1px solid #EEE8DC",
        backgroundColor: "#FFFEFB",
        padding: theme.spacing(2),
        marginBottom: theme.spacing(3),
        opacity: opacity,
        cursor: "pointer",
      }}
      ref={ref}
    >
      <Grid container>
        <Grid
          item
          xs={1}
          display="flex"
          alignItems="flex-start"
          paddingTop="10px"
        >
          <Stack direction="row" alignItems="center">
            <CardMedia
              sx={{
                marginRight: theme.spacing(1),
                marginBottom: "0 !important",
                fontSize: "20px",
                color: "#B69056",
              }}
              component="img"
              alt="image"
              image={sport?.logoUrl}
            />

            <Typography variant="h6" color={theme.palette.primary.main}>
              {sport?.name}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={9}>
          {sport?.questions?.map((question: any, index: number) => {
            return (
              <Grid container spacing={2} key={index + 1}>
                <Grid item xs={7} marginLeft={theme.spacing(4)}>
                  <FormControl variant="standard" fullWidth>
                    <StyledInput
                      disabled
                      id="contestQuestion"
                      aria-describedby="contest-question"
                      inputProps={{
                        "aria-label": "Contest Question",
                      }}
                      placeholder="Ex: Who scores more points?"
                      sx={{ background: "#fff" }}
                      value={question?.question}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2} sx={{}}>
                  <FormControl variant="standard" fullWidth>
                    <StyledInput
                      disabled
                      id="bonusPoints"
                      aria-describedby="select-week"
                      inputProps={{
                        "aria-label": "select week",
                      }}
                      placeholder="Bonus points?"
                      sx={{
                        backgroundColor: "#fff",
                        width: "106px",
                      }}
                      value={question?.metric}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            );
          })}
        </Grid>

        <Grid
          item
          xs={1}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
            marginLeft: "32px",
          }}
        >
          <IconButton
            onClick={() => {
              onEdit(orderIndex);
            }}
          >
            <Box component={EditIcon} height={20} width={20} />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SportCard;
