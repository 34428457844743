import * as jwt from "jsonwebtoken";
import {
  ACCESS_TOKEN,
  ADMIN_ID,
  REFRESH_TOKEN,
} from "@src/constants/BrowserStorage";

export function saveAuthTokens(tokens: {
  accessToken: string;
  refreshToken: string;
}) {
  window.localStorage.setItem(ACCESS_TOKEN, tokens.accessToken);
  window.localStorage.setItem(REFRESH_TOKEN, tokens.refreshToken);
  window.localStorage.setItem(ADMIN_ID, "2");
}

export function loadAuthTokens(): {
  accessToken: string;
  refreshToken: string;
} {
  const accessToken = window.localStorage.getItem(ACCESS_TOKEN);
  const refreshToken = window.localStorage.getItem(REFRESH_TOKEN);

  if (!accessToken || !refreshToken) {
    throw new Error("Tokens not found");
  }
  // test both tokens
  if (!jwt.decode(accessToken) || !jwt.decode(refreshToken)) {
    throw new Error("Invalid tokens");
  }
  return { accessToken, refreshToken };
}

export function clearAuthTokens() {
  window.localStorage.removeItem(ACCESS_TOKEN);
  window.localStorage.removeItem(REFRESH_TOKEN);
  window.localStorage.removeItem(ADMIN_ID);
}
