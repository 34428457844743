import {
  Typography,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export interface DialogTitleProps {
  children?: React.ReactNode;
  onClose: Function;
}

export function DialogTitle(props: DialogTitleProps) {
  const { children, onClose } = props;

  return (
    <MuiDialogTitle>
      <Stack
        alignContent="center"
        direction="row"
        justifyContent="space-between"
      >
        <Typography variant="h5">{children}</Typography>
        {onClose ? (
          <IconButton onClick={() => onClose(false)} className="p-2">
            <CloseIcon />
          </IconButton>
        ) : null}
      </Stack>
    </MuiDialogTitle>
  );
}
