import { FC } from "react";
import { useSnackbar } from "notistack";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Stack,
  useTheme,
} from "@mui/material";
import { DialogTitle } from "@src/components/molecules/DialogTitle";
import { useGetCurrentTournamentPlayersMutation } from "@src/graphql/graphql.generated";
import { SOMETHING_WRONG_HAPPENED } from "@src/constants/strings";
import { GET_CURRENT_TOURNAMENT_PLAYERS, ModalProps } from "./crons";

export const GetCurrentTournamentPlayers: FC<ModalProps> = ({
  isOpen,
  onSubmit,
  onClose,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: getCurrentTournamentPlayers, isLoading } =
    useGetCurrentTournamentPlayersMutation();

  const handleSubmit = () => {
    getCurrentTournamentPlayers(
      {},
      {
        onSuccess: (data) => {
          if (data.getCurrentTournamentPlayers.success) {
            onSubmit(GET_CURRENT_TOURNAMENT_PLAYERS);
            onClose();
          } else {
            enqueueSnackbar(SOMETHING_WRONG_HAPPENED, {
              variant: "default",
            });
          }
        },
        onError: () => {
          enqueueSnackbar(SOMETHING_WRONG_HAPPENED, {
            variant: "default",
          });
        },
      }
    );
  };

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
      onClose={onClose}
    >
      <DialogTitle onClose={onClose}>
        Get Current Tournament Players
      </DialogTitle>
      <DialogContent>
        <Stack
          alignItems="center"
          flex={1}
          justifyContent="center"
          p={2}
          spacing={1}
        >
          Are you sure that you want to get current tournament players?
        </Stack>
      </DialogContent>
      <DialogActions sx={{ bgcolor: theme.palette.grey[100], p: 2 }}>
        <Button
          className="text-white"
          color="secondary"
          disableElevation
          onClick={onClose}
          type="button"
          variant="outlined"
        >
          Close
        </Button>
        <Button
          className="text-white"
          color="primary"
          disableElevation
          onClick={handleSubmit}
          type="submit"
          variant="contained"
          disabled={isLoading}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
