import { useMemo, useState, useEffect } from "react";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import {
  CreateContestStep,
  contestCreationStepState,
  tournamentTypeState,
  tournamentIdState,
  mainPlayerIdState,
  TournamentType,
  roundState,
} from "./state";
import {
  Button,
  Stack,
  DialogContent,
  DialogActions,
  ButtonBase,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useGetPlayersForSuggestionQuery } from "@src/graphql/graphql.generated";
import { PlayerImage } from "./PlayerImage";
import { SearchField } from "./SearchField";

export function MainPlayer() {
  const [searchString, setSearchString] = useState("");
  const [mainPlayerId, setMainPlayerId] = useRecoilState(mainPlayerIdState);
  const tournamentType = useRecoilValue(tournamentTypeState);
  const tournamentId = useRecoilValue(tournamentIdState);
  const round = useRecoilValue(roundState);
  const setStep = useSetRecoilState(contestCreationStepState);

  const {
    data: tournamentPlayers,
    isLoading: isTournamentPlayersLoading,
    isError: isTournamentPlayersError,
    refetch: refetchTournamentPlayers,
  } = useGetPlayersForSuggestionQuery({
    tournamentId: tournamentId!,
    contestType: tournamentType,
    roundNumber:
      tournamentType === TournamentType.RoundByRound ? +round! : null,
  });

  const players = useMemo(
    () =>
      (tournamentPlayers?.getPlayersForSuggestion ?? []).filter(
        (p) =>
          p.playerId !== mainPlayerId &&
          `${p.firstName} ${p.lastName}`
            .toLocaleLowerCase()
            .includes(searchString.toLocaleLowerCase())
      ),
    [searchString, tournamentPlayers]
  );

  const isComplete = useMemo(() => Boolean(mainPlayerId), [mainPlayerId]);

  useEffect(() => {
    refetchTournamentPlayers();
  }, [tournamentType, refetchTournamentPlayers, tournamentId, round]);

  return (
    <>
      <DialogContent
        sx={{
          display: "flex",
          overflow: "hidden",
          paddingX: 1,
          paddingY: 2.5,
        }}
      >
        {isTournamentPlayersLoading ? (
          <Stack alignItems="center" flex={1} justifyContent="center" m={5}>
            <CircularProgress size={24} />
          </Stack>
        ) : isTournamentPlayersError ? (
          <Stack flex={1} justifyContent="center" m={5}>
            <Typography variant="h5" align="center">
              Sorry, players can't be fetched at the moment. Please try again on
              later.
            </Typography>
          </Stack>
        ) : !tournamentPlayers?.getPlayersForSuggestion.length ? (
          <Stack alignItems="center" flex={1} justifyContent="center" m={5}>
            <Typography variant="h5" align="center">
              Sorry, no players available at the moment.
            </Typography>
          </Stack>
        ) : (
          <>
            <Stack
              border="solid 2px #B69056"
              borderRadius={2}
              direction="column"
              flex={1}
              overflow="hidden"
              sx={{ overflowY: "auto" }}
            >
              <Box
                flex={1}
                sx={{ display: "grid", gridTemplateRows: "60px 1fr" }}
                overflow="hidden"
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack sx={{ ml: 2 }}>
                    <Typography color="#B69056" variant="body1">
                      Choose main player
                    </Typography>
                  </Stack>
                  <Stack sx={{ mr: 1 }}>
                    <SearchField
                      onSearch={setSearchString}
                      placeholder="Search Player"
                      size="small"
                      value={searchString}
                    />
                  </Stack>
                </Stack>
                <Stack component="ul" m={0} overflow="auto" p={0}>
                  {players.map((player) => (
                    <ButtonBase
                      component="li"
                      key={player.id}
                      onClick={() => setMainPlayerId(player.playerId)}
                      sx={{
                        bgcolor:
                          mainPlayerId === player.playerId ? "#e9decc" : "",
                        borderTop: "solid 1px lightgrey",
                      }}
                    >
                      <Stack
                        direction="row"
                        py={1}
                        spacing={2}
                        sx={{ width: "100%" }}
                      >
                        <Stack ml={1}>
                          <PlayerImage
                            src={player.headshotUrlSmall}
                            alt={`${player.firstName} ${player.lastName}`}
                            borderRadius="50%"
                            size="md"
                          />
                        </Stack>
                        <Stack alignItems="flex-start" textAlign="left">
                          <Typography fontWeight={{ sm: 400, md: 600 }}>
                            {`${player.firstName} ${player.lastName}`}
                          </Typography>
                        </Stack>
                      </Stack>
                    </ButtonBase>
                  ))}
                </Stack>
              </Box>
            </Stack>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          className="text-white"
          color="primary"
          disableElevation
          onClick={() => setStep(CreateContestStep.SelectOpponents)}
          type="submit"
          variant="contained"
          size="large"
          disabled={!isComplete}
          sx={{
            fontWeight: "bold",
            px: 5,
            mb: 1,
          }}
        >
          Next
        </Button>
      </DialogActions>
    </>
  );
}
