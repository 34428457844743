import AddIcon from "@mui/icons-material/Add";
import {
  useCreatePartnerMutation,
  useUpdatePartnerMutation,
} from "@src/graphql/graphql.generated";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";

import ColorPicker from "@molecules/ColorPicker/ColorPicker";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/FileCopy";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import { useAuth } from "@src/hooks/useAuth";
import { getAuthInfo } from "@src/services/auth";
import { axiosPartnerInstance } from "@src/services/axiosInstances";
import { parseGQLError } from "@src/utils/parse-error";

interface CreateEditDialogProps {
  isCreate: boolean;
  isEdit: boolean;
  target: any | null;
  onClose: (refresh?: boolean) => void;
}

type Admin = {
  fullName: string;
  email: string;
};

type AdminData = {
  companyName: string;
  registeredEmail: string;
  phoneNumber: string;
  identifier: string;
  logo: string;
  primaryColor: string;
  secondaryColor: string;
};

const initialAdminData = {
  companyName: "",
  registeredEmail: "",
  phoneNumber: "",
  identifier: "",
  logo: "",
  primaryColor: "",
  secondaryColor: "",
};

const StyledLabel = styled("label")(({ theme }) => ({
  color: "#212121",
  fontSize: "14px",
}));
const StyledRequiredLabel = styled("label")(({ theme }) => ({
  color: "#EB1745",
  fontSize: "14px",
}));

const StyledTitleLabel = styled("label")(({ theme }) => ({
  color: "#262626",
  fontSize: "16px",
  fontWeight: 500,
}));
const StyledInput = styled(TextField)(({ theme: any }) => ({
  marginTop: "10px",
  "& input": {
    padding: "9px 12px 9px 12px",

    "&:focus": {
      outline: `none`,
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: `#E2D3BB !important`,
    "&:focus": {
      outline: `none`,
    },
  },
  "& .MuiOutlinedInput-root.Mui-error": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `#d32f2f !important`,
    },
  },
  "& .MuiFormHelperText-root.Mui-error": {
    fontSize: "12px",
    margin: "3px",
  },
}));

const CopyIconButton = styled(IconButton)(({ theme }) => ({
  marginLeft: "10px",
}));
export function CreateEditDialog({
  isCreate,
  isEdit,
  target,
  onClose,
}: CreateEditDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: create, isLoading: createLoading } =
    useCreatePartnerMutation();
  const { mutate: update, isLoading: updateLoading } =
    useUpdatePartnerMutation();

  const [logoUrl, setLogoUrl] = useState<any>("");
  const { data: authInfo } = useAuth();

  const [isValid, setValid] = useState(false);
  const [iframeKey, setIFrameKey] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [isForm, setForm] = useState(true);
  const [anotherAdmins, setAnotherAdmins] = useState<Admin[]>([
    { fullName: "", email: "" },
  ]);
  const [adminData, setAdminData] = useState<AdminData>(initialAdminData);

  useEffect(() => {
    if (target) {
      setAdminData({
        companyName: target?.name!,
        registeredEmail: target?.email,
        phoneNumber: target?.contact,
        identifier: target?.identifier,
        logo: target?.logo,
        primaryColor: target?.primaryColor,
        secondaryColor: target?.secondaryColor,
      });
      setLogoUrl(target?.logo);
      const result = target?.admins.map(({ fullName, email }: any) => ({
        fullName: fullName,
        email: email,
      }));
      setAnotherAdmins(result);
    }
  }, [target]);

  useEffect(() => {
    setValid(validateAdminDetails(adminData) && validateAdmins(anotherAdmins));
  }, [anotherAdmins, adminData]);

  const handleClose = (refresh = false) => {
    setAdminData(initialAdminData);
    setAnotherAdmins([{ fullName: "", email: "" }]);
    onClose(refresh);
    setForm(true);
  };

  const validateAdminDetails = (details: AdminData): boolean => {
    return Object.values(details).every((value) => value?.trim() !== "");
  };

  const validateAdmins = (admins: Admin[]): boolean => {
    return admins.every((admin) =>
      Object.values(admin).every((value) => value.trim() !== "")
    );
  };

  const updateAdmin = (index: number, field: string, value: string) => {
    const updatedAdmins = anotherAdmins.map((admin, i) =>
      i === index ? { ...admin, [field]: value } : admin
    );
    setAnotherAdmins(updatedAdmins);
  };

  const handleEdit = () => {
    update(
      {
        data: {
          id: target?.id,
          name: adminData?.companyName,
          email: adminData?.registeredEmail,
          contact: adminData?.phoneNumber,
          admins: anotherAdmins,
          active: true,
          logo: adminData?.logo,
          identifier: adminData?.identifier,
          primaryColor: adminData?.primaryColor,
          secondaryColor: adminData?.secondaryColor,
          // apiKeys: target?.apiKeys,
        },
      },
      {
        onSuccess: () => {
          enqueueSnackbar(`Partner has been updated successfully!`, {
            variant: "success",
          });
          onClose(true);
        },
        onError: (error: any) => {
          enqueueSnackbar(parseGQLError(error, `Unable to update`), {
            variant: "error",
          });
        },
      }
    );
  };
  const uploadPlayerImage = async (file: any) => {
    const body = new FormData();
    body.append("file", file);

    const { accessToken } = await getAuthInfo({ retry: true });
    try {
      const response = await axiosPartnerInstance.post("/upload-image", body, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response?.data?.data?.imageUrl;
    } catch (error) {
      console.error("Error uploading player image:", error);
    }
  };
  const handleFileChange = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        uploadPlayerImage(file).then((response) => {
          setLogoUrl(response);
          setAdminData({
            ...adminData,
            logo: response,
          });
        });
      };
      reader.onerror = (e) => {
        console.error("FileReader error:", e);
      };
      reader.readAsDataURL(file);
    } else {
      console.error("No file selected.");
    }
  };

  const handleCreate = () => {
    create(
      {
        data: {
          name: adminData?.companyName,
          email: adminData?.registeredEmail,
          contact: adminData?.phoneNumber,
          creatorId: Number(authInfo?.userId),
          admins: anotherAdmins,
          logo: adminData?.logo,
          identifier: adminData?.identifier,
          primaryColor: adminData?.primaryColor,
          secondaryColor: adminData?.secondaryColor,
        },
      },
      {
        onSuccess: (res) => {
          enqueueSnackbar(`Partner has been created successfully!`, {
            variant: "success",
          });
          setIFrameKey(res?.createPartner?.iframeKey!);
          setSecretKey(res?.createPartner?.secretKey!);
          setForm(false);
        },
        onError: (error: any) => {
          enqueueSnackbar(parseGQLError(error, `Unable to create`), {
            variant: "error",
          });
        },
      }
    );
  };
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    enqueueSnackbar(`Copied to clipboard!`, {
      variant: "success",
    });
  };

  const updateColor = (field: string, color: string) => {
    if (field === "primaryColor") {
      setAdminData({
        ...adminData,
        primaryColor: color,
      });
    } else {
      setAdminData({
        ...adminData,
        secondaryColor: color,
      });
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={isCreate || isEdit}>
      {isForm ? (
        <>
          <DialogTitle>{isCreate ? "Add new" : "Edit"} Partner</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => handleClose(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <Box component="main">
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <FormControl variant="standard" fullWidth>
                    <StyledLabel htmlFor="CompanyName">
                      Company Name
                      <StyledRequiredLabel htmlFor="CompanyName">
                        *
                      </StyledRequiredLabel>
                    </StyledLabel>
                    <StyledInput
                      id="CompanyName"
                      onChange={(e) => {
                        setAdminData({
                          ...adminData,
                          companyName: e.target.value,
                        });
                      }}
                      error={false}
                      value={adminData?.companyName}
                      placeholder="Enter Company Name"
                      aria-describedby="Company Name"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="standard" fullWidth>
                    <StyledLabel htmlFor="RegisteredEmail">
                      Registered email
                      <StyledRequiredLabel htmlFor="RegisteredEmail">
                        *
                      </StyledRequiredLabel>
                      <Tooltip
                        title="Registered email id will receive auto generated email with API key and Secret key"
                        arrow
                      >
                        <InfoOutlinedIcon
                          sx={{
                            color: "#212121",
                            fontSize: "16px",
                            marginLeft: "5px",
                          }}
                        />
                      </Tooltip>
                    </StyledLabel>
                    <StyledInput
                      error={false}
                      onChange={(e) => {
                        setAdminData({
                          ...adminData,
                          registeredEmail: e.target.value,
                        });
                      }}
                      value={adminData?.registeredEmail}
                      id="RegisteredEmail"
                      placeholder="Enter Registered Email ID"
                      aria-describedby="Registered Email"
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={6} sx={{ marginTop: "-30px" }}>
                <Grid item xs={6}>
                  <FormControl variant="standard" fullWidth>
                    <StyledLabel htmlFor="PhoneNumber">
                      Phone Number
                      <StyledRequiredLabel htmlFor="PhoneNumber">
                        *
                      </StyledRequiredLabel>
                    </StyledLabel>
                    <StyledInput
                      error={false}
                      onChange={(e) => {
                        setAdminData({
                          ...adminData,
                          phoneNumber: e.target.value,
                        });
                      }}
                      value={adminData?.phoneNumber}
                      id="PhoneNumber"
                      placeholder="Enter Phone Number"
                      aria-describedby="Phone Number"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="standard" fullWidth>
                    <StyledLabel htmlFor="identifier">
                      Unique name
                      <StyledRequiredLabel htmlFor="identifier">
                        *
                      </StyledRequiredLabel>
                    </StyledLabel>
                    <StyledInput
                      error={false}
                      onChange={(e) => {
                        setAdminData({
                          ...adminData,
                          identifier: e.target.value,
                        });
                      }}
                      value={adminData?.identifier}
                      id="identifier"
                      placeholder="Enter Unique name"
                      aria-describedby="Unique name"
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={6} sx={{ marginTop: "-30px" }}>
                <Grid item xs={6}>
                  <FormControl variant="standard" fullWidth>
                    <StyledLabel htmlFor="logo">Company logo</StyledLabel>
                    <Box
                      sx={{
                        position: "relative",
                        display: "inline-block",
                        marginTop: "5px",
                      }}
                    >
                      <label
                        htmlFor="image1"
                        style={{
                          cursor: "pointer",
                          background: "#FAFAFA",
                          display: "block",
                          height: "62px",
                          width: "62px",
                          border: "1px dashed #D9D9D9",
                          borderRadius: "50%",
                          overflow: "hidden",
                        }}
                      >
                        {!logoUrl ? (
                          <span
                            style={{
                              display: "flex",
                              color: "rgb(191 184 184)",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <AddIcon
                              style={{
                                color: "rgb(191 184 184)",
                                width: "14px",
                                height: "14px",
                              }}
                            />
                            <span>Upload</span>
                          </span>
                        ) : null}
                        <input
                          type="file"
                          id="image1"
                          accept=".jpg, .jpeg, .png"
                          // {...register("player1PhotoUrl")}
                          onChange={(e) => handleFileChange(e)}
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            top: 0,
                            left: 0,
                            opacity: 0,
                            zIndex: -1,
                          }}
                        />
                        {logoUrl && (
                          <Avatar
                            sx={{ width: 62, height: 62 }}
                            src={logoUrl}
                          />
                        )}
                      </label>
                    </Box>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={6} sx={{ marginTop: "-30px" }}>
                <Grid item xs={6}>
                  <FormControl variant="standard" fullWidth>
                    <StyledLabel htmlFor="primaryColor">
                      Primary Colour
                      <StyledRequiredLabel htmlFor="primaryColor">
                        *
                      </StyledRequiredLabel>
                    </StyledLabel>
                    <StyledInput
                      error={false}
                      onChange={(e) => {
                        setAdminData({
                          ...adminData,
                          primaryColor: e.target.value,
                        });
                      }}
                      value={adminData?.primaryColor}
                      id="primaryColor"
                      placeholder="Enter Primary  Colour"
                      aria-describedby="Primary  Colour"
                    />
                    <ColorPicker
                      field="primaryColor"
                      onChange={updateColor}
                      defaultColor={adminData?.primaryColor}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="standard" fullWidth>
                    <StyledLabel htmlFor="secondaryColor">
                      Secondary Color
                      <StyledRequiredLabel htmlFor="secondaryColor">
                        *
                      </StyledRequiredLabel>
                    </StyledLabel>
                    <StyledInput
                      error={false}
                      onChange={(e) => {
                        setAdminData({
                          ...adminData,
                          secondaryColor: e.target.value,
                        });
                      }}
                      value={adminData?.secondaryColor}
                      id="secondaryColor"
                      placeholder="Enter Secondary  Colour"
                      aria-describedby="Secondary  Colour"
                    />

                    <ColorPicker
                      field="secondaryColor"
                      onChange={updateColor}
                      defaultColor={adminData?.secondaryColor}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={6} sx={{ marginTop: "-30px" }}>
                <Grid item xs={6}>
                  <StyledTitleLabel>Add Admin</StyledTitleLabel>
                </Grid>
              </Grid>

              {anotherAdmins?.length > 0 &&
                anotherAdmins?.map((admin, index) => {
                  return (
                    <Grid container spacing={6} sx={{ marginTop: "-40px" }}>
                      {index !== 0 && (
                        <Grid item xs={1}>
                          <IconButton
                            aria-label="close"
                            onClick={() => {
                              const updatedAdmins = anotherAdmins.filter(
                                (_, i) => index !== i
                              );
                              setAnotherAdmins(updatedAdmins);
                            }}
                            sx={{
                              top: 10,
                              color: (theme) => theme.palette.primary.main,
                            }}
                          >
                            <RemoveCircleRoundedIcon />
                          </IconButton>
                        </Grid>
                      )}
                      <Grid item xs={index === 0 ? 6 : 5}>
                        <FormControl variant="standard" fullWidth>
                          <StyledLabel htmlFor="name">
                            Name
                            <StyledRequiredLabel htmlFor="name">
                              *
                            </StyledRequiredLabel>
                          </StyledLabel>
                          <StyledInput
                            error={false}
                            id="name"
                            value={anotherAdmins?.[index]?.fullName}
                            placeholder="Enter Contact Name"
                            aria-describedby="name"
                            onChange={(e) =>
                              updateAdmin(index, "fullName", e.target.value)
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl variant="standard" fullWidth>
                          <StyledLabel htmlFor="login-Id">
                            Email
                            <StyledRequiredLabel htmlFor="login-Id">
                              *
                            </StyledRequiredLabel>
                          </StyledLabel>
                          <StyledInput
                            error={false}
                            id="login-Id"
                            value={anotherAdmins?.[index]?.email}
                            placeholder="Enter Email"
                            aria-describedby="entry-fee"
                            onChange={(e) =>
                              updateAdmin(index, "email", e.target.value)
                            }
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  );
                })}

              <Grid container spacing={6} sx={{ marginTop: "-30px" }}>
                <Grid item xs={6}>
                  <Button
                    size="medium"
                    onClick={() => {
                      setAnotherAdmins([
                        ...anotherAdmins,
                        { fullName: "", email: "" },
                      ]);
                    }}
                  >
                    + Add another contact
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions sx={{ borderTop: "1px solid #F0F0F0" }}>
            {isEdit ? (
              <>
                <Button
                  variant="outlined"
                  size="medium"
                  // disabled={!isValid}
                  onClick={() => handleClose(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  disabled={!isValid || updateLoading}
                  onClick={handleEdit}
                >
                  {updateLoading ? "Loading..." : "Save"}
                </Button>
              </>
            ) : (
              <Button
                variant="contained"
                size="medium"
                disabled={!isValid || createLoading}
                onClick={handleCreate}
              >
                {createLoading ? "Loading..." : "generate"}
              </Button>
            )}
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle
            alignItems="center"
            sx={{ paddingX: "100px", textAlign: "center" }}
          >
            Partner successfully added and api keys are sent to registered email
            ID
          </DialogTitle>
          <DialogContent>
            <Box sx={{ mt: 2 }}>
              <Typography variant="body1" gutterBottom>
                <strong>API Key:-</strong>
                <span style={{ color: "#B69056" }}>{iframeKey}</span>
                <Tooltip title="Copy Secret Key">
                  <CopyIconButton
                    onClick={() => copyToClipboard(iframeKey)}
                    size="small"
                  >
                    <ContentCopyIcon
                      fontSize="small"
                      sx={{ color: "#B69056" }}
                    />
                  </CopyIconButton>
                </Tooltip>
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Secret Key:-</strong>{" "}
                <span style={{ color: "#B69056" }}>{secretKey}</span>
                <Tooltip title="Copy Secret Key">
                  <CopyIconButton
                    onClick={() => copyToClipboard(secretKey)}
                    size="small"
                  >
                    <ContentCopyIcon
                      fontSize="small"
                      sx={{ color: "#B69056" }}
                    />
                  </CopyIconButton>
                </Tooltip>
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions sx={{ borderTop: "1px solid #F0F0F0" }}>
            <Button
              variant="contained"
              size="medium"
              onClick={() => handleClose(true)}
            >
              CLOSE
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
