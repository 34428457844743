import { EST_TIME_ZONE } from "@src/constants/Touchdown";
import dayjs from "dayjs";

export function roundToTwoDecimalPlaces(points: number) {
  const rounded = Math.round(points * 2) / 2;
  return rounded;
}

export function formatDate(dateStr: string): string {
  const date = new Date(dateStr);
  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const year = date.getUTCFullYear();
  return `${day}-${month}-${year}`;
}

export function formatDateMmDdYyyy(dateStr: string): string {
  return dayjs.utc(dateStr).tz(EST_TIME_ZONE).format("MM-DD-YYYY"); // Eastern
}
