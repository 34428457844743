import { GraphQLClient } from "graphql-request";
import { getAuthInfo } from "@src/services/auth";
import { GRAPHQL_URL } from "@src/constants/ApiHosts";

const client = new GraphQLClient(
  process.env.NODE_ENV === "test"
    ? "http://localhost/api/pga/graphql"
    : GRAPHQL_URL!
);

export const useFetchData = <TDocument, TVariables>(
  query: string
): ((variables?: TVariables) => Promise<TDocument>) => {
  return async (variables?: TVariables) => {
    const { accessToken } = await getAuthInfo({ retry: true });

    return client.request<TDocument, TVariables>(query, variables, {
      Authorization: `Bearer ${accessToken}`,
    });
  };
};
