import { useMemo, useState } from "react";
import { useSnackbar } from "notistack";
import { Button, Autocomplete, Stack, TextField } from "@mui/material";
import { useQueryClient } from "react-query";
import {
  useGetAllNflPlayersQuery,
  useCreateNflMatchupMutation,
  AuditActionFliterType,
} from "@src/graphql/graphql.generated";
import { QueryKeys } from "@src/constants/QueryKeys";
import { AuditLogsModal } from "@src/components/templates/AuditLogsModal";
import { MatchUpsInTouchDown } from "@src/components/touchDown/ContestHub/MatchUpsInTouchdown";
interface ILabelId {
  label: string;
  id: number;
}

export function MatchupControls({auditTrail = true}) {
  const { data: players } = useGetAllNflPlayersQuery(undefined, {
    staleTime: 300_000, // 5 min
  });
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: createNflMatchup } = useCreateNflMatchupMutation();
  const [mainPlayer, setMainPlayer] = useState<ILabelId | null>();
  const [secondPlayer, setSecondPlayer] = useState<ILabelId | null>();
  const [showAuditTrail, setShowAuditTrail] = useState(false);
  const playersLabelValues: Array<ILabelId> | undefined = useMemo(
    () =>
      players?.getAllNflPlayers
        .map((player) => {
          return {
            label: player.fullName,
            id: player.id,
          };
        })
        .sort((player, nextPlayer) =>
          player.label < nextPlayer.label ? -1 : 1
        ),
    [players?.getAllNflPlayers]
  );
  const queryClient = useQueryClient();

  const handleChangeMainPlayer = (newValue: ILabelId | null) =>
    newValue && setMainPlayer(newValue);
  const handleChangeSecondPlayer = (newValue: ILabelId | null) =>
    newValue && setSecondPlayer(newValue);

  const handleCloseAudit = () => setShowAuditTrail(false);

  const handleSubmit = () => {
    if (!!mainPlayer && !!secondPlayer && mainPlayer.id !== secondPlayer.id) {
      createNflMatchup(
        {
          playerOneId: mainPlayer.id,
          playerTwoId: secondPlayer.id,
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Suggestion created successfully!", {
              variant: "success",
            });
            queryClient.invalidateQueries(QueryKeys.GetNflMatchups);
          },
        }
      );
    }
  };

  return (
    <>
    {/* <MatchUpsInTouchDown/> */}
      <Stack spacing={1}>
        {auditTrail && (
          <Button
            sx={{ mb: 4 }}
            fullWidth
            onClick={() => setShowAuditTrail(true)}
            variant="outlined"
          >
            Audit Trail
          </Button>
        )}
        {playersLabelValues && (
          <Autocomplete
            options={playersLabelValues}
            value={mainPlayer}
            clearOnEscape
            onChange={(_props, nextValue) => handleChangeMainPlayer(nextValue)}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.label}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField {...params} label="Main Player" variant="standard" />
            )}
          />
        )}
        {playersLabelValues && (
          <Autocomplete
            options={playersLabelValues}
            value={mainPlayer}
            clearOnEscape
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.label}
                </li>
              );
            }}
            onChange={(_props, nextValue) =>
              handleChangeSecondPlayer(nextValue)
            }
            renderInput={(params) => (
              <TextField
                sx={{ mb: 4 }}
                {...params}
                label="Second Player"
                variant="standard"
              />
            )}
          />
        )}
        <Button fullWidth onClick={handleSubmit} variant="contained">
          Create Matchup
        </Button>
      </Stack>
      <AuditLogsModal
        title="Matchups Audit"
        isOpen={showAuditTrail}
        onClose={handleCloseAudit}
        actions={[
          AuditActionFliterType.CreateNflMatchup,
          AuditActionFliterType.OrderNflMatchup,
          AuditActionFliterType.DeleteNflMatchup,
        ]}
      />
    </>
  );
}
