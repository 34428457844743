import { theme } from "@src/constants/theme";
import { Box, Button, Typography, styled } from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { useMemo } from "react";
import { formatDate } from "@src/utils/utils";
import { ActiveTouchdowns } from "@src/types/partner/dashboard";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: "1px solid #EEE8DC",
  "& .MuiDataGrid-cell": {
    borderBottom: "1px solid #EEE8DC",
  },
  "& .MuiDataGrid-columnHeaders": {
    borderBottom: "1px solid #EEE8DC",
  },
  "& .MuiDataGrid-footerContainer": {
    borderTop: "1px solid #EEE8DC",
  },
}));

export function ActiveTouchdown(props: ActiveTouchdowns) {
  const {
    title,
    activeTouchdownData,
    setActiveTouchDownIndex,
    setActiveTouchDownDetails,
  } = props;

  const COLUMNS: GridColumns = useMemo<GridColumns>(
    () => [
      { field: "name", headerName: "Name", width: 280 },
      {
        field: "startDateTime",
        headerName: "Date",
        width: 222,
        renderCell: (params) => (
          <span>
            {formatDate(params.row.startDateTime)} to{" "}
            {formatDate(params.row.endDateTime)}
          </span>
        ),
      },
      { field: "entryFees", headerName: "Entry Fees", width: 250 },
      { field: "allowedPick", headerName: "No of Picks", width: 250 },
      {
        field: "userEntryCount",
        headerName: "Daily Metrics",
        width: 140,
        renderCell: (params) => {
          return (
            <>
              {params.row.status === "DRAFT" ? (
                "-"
              ) : (
                <Button
                  variant="outlined"
                  onClick={() => {
                    setActiveTouchDownDetails(true);
                    setActiveTouchDownIndex(params.api.getRowIndex(params.row.id));
                  }}
                  size="small"
                  sx={{ width: "70px" }}
                >
                  VIEW
                </Button>
              )}
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <Box marginBottom={theme.spacing(3)}>
        <Typography
          variant="h5"
          fontWeight={500}
          marginBottom={theme.spacing(3)}
        >
          {title}
        </Typography>
      </Box>
      <Box
        style={{ height: "370px", overflow: "auto" }}
        marginBottom={theme.spacing(3)}
      >
        <StyledDataGrid
          columns={COLUMNS}
          initialState={{
            sorting: {
              sortModel: [{ field: "startDate", sort: "asc" }],
            },
          }}
          rows={activeTouchdownData || []}
          pageSize={5}
          components={{
            Pagination: () => null, // This hides the pagination component
          }}
          sx={{
            "& .MuiDataGrid-footerContainer": {
              display: "none", // Hide the footer container completely
            },
          }}
        />
      </Box>
    </>
  );
}
