import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  Grid,
  IconButton,
  OutlinedInput,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { theme } from "@src/constants/theme";

import DeleteIcon from "@mui/icons-material/Delete";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import { styled } from "@mui/styles";
import { QueryKeys } from "@src/constants/QueryKeys";
import {
  EST_TIME_ZONE,
  TOUCHDOWN_CONTEST_STATUSES,
} from "@src/constants/Touchdown";
import {
  useCreateNflTouchdownContestMutation,
  useDeleteNflTouchdownContestMutation,
  useGetNflTouchdownContestPlayersQuery,
  useGetNflTouchdownContestsQuery,
} from "@src/graphql/graphql.generated";
import { PrizePoolCreationFields } from "@src/types/touchdown/contest";
import { parseGQLError } from "@src/utils/parse-error";
import { roundToTwoDecimalPlaces } from "@src/utils/utils";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";

const StyledTypoItem = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 500,
}));

const StyledInput = styled(OutlinedInput)(({ theme: any }) => ({
  marginTop: theme.spacing(1),
  "& input": {
    padding: "9px 12px 9px 12px",

    "&:focus": {
      outline: `none`,
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: `#E2D3BB !important`,
    "&:focus": {
      outline: `none`,
    },
  },
}));

interface ILabelId {
  label: string;
  id: number;
  gameId: number;
  projectedPoints: number;
}

interface NflProps {
  selectedDate: string;
  updateCount: (count: number) => void;
  prizePool: PrizePoolCreationFields | null;
}

/* NFL games week start with Wednesday 5:00 AM EST to  Next week Tuesday 4:00 AM EST , so match-up creation allowed in this range of current week.
   Not allowed to create matchup between Tuesday 12:00 AM EST And Wednesday 5:00 AM EST */
const restrictToCreateMatchup = (
  prizePoolDate: dayjs.Dayjs | null | undefined
) => {
  const day = prizePoolDate?.day();
  if (day === 2) {
    return true;
  }

  let currentTime = dayjs().tz(EST_TIME_ZONE);
  if (currentTime.day() === 1) {
    currentTime = currentTime.clone().subtract(1, "week");
  }

  const startDatetime = currentTime
    .clone()
    .weekday(2)
    .set({ hour: 5, minute: 0, seconds: 0 });

  const endDatetime = currentTime
    .clone()
    .weekday(8)
    .set({ hour: 4, minute: 0, seconds: 0 });

  return !dayjs(prizePoolDate).isBetween(
    startDatetime,
    endDatetime,
    "date",
    "[]"
  );
};

export function NflContestCreation(props: NflProps) {
  const { selectedDate, updateCount, prizePool } = props;
  const [mainPlayer, setMainPlayer] = useState<ILabelId | null>();
  const [secondPlayer, setSecondPlayer] = useState<ILabelId | null>();
  const [mainPlayerBonusPoints, setMainPlayerBonusPoints] = useState<number>(0);
  const [secondPlayerBonusPoints, setSecondPlayerBonusPoints] =
    useState<number>(0);

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const disabled =
    prizePool?.status === "COMPLETED" ||
    restrictToCreateMatchup(prizePool?.startDate);

  const { data: players } = useGetNflTouchdownContestPlayersQuery(
    { date: selectedDate },
    {
      staleTime: 300_000, // 5 min
      enabled: !disabled,
    }
  );
  const { data: touchdownContests } = useGetNflTouchdownContestsQuery(
    {
      date: selectedDate,
    },
    {
      onSuccess: (data) => {
        if (data) updateCount(data.getNflTouchdownContests.length);
      },
    }
  );
  const { mutate: createNflTouchdownContest } =
    useCreateNflTouchdownContestMutation();
  const { mutate: deleteNflTouchdownContest } =
    useDeleteNflTouchdownContestMutation();

  const playersLabelValues: Array<ILabelId> | undefined = useMemo(
    () =>
      players?.getNflTouchdownContestPlayers
        .map((player) => {
          return {
            label: player.fullName,
            id: player.id,
            gameId: player.gameId,
            projectedPoints: player.projectedFantasyPoints || 0,
          };
        })
        .sort((player, nextPlayer) =>
          player.label < nextPlayer.label ? -1 : 1
        ) || [],
    [players?.getNflTouchdownContestPlayers]
  );

  useEffect(() => {
    setMainPlayer(null);
    setSecondPlayer(null);
  }, [selectedDate]);

  const handleChangeMainPlayer = (newValue: ILabelId | null) => {
    if (newValue) {
      setMainPlayer(newValue);
      if (secondPlayer) {
        const p1Points = newValue.projectedPoints;
        const p2Points = secondPlayer.projectedPoints;
        if (p1Points > p2Points) {
          setSecondPlayerBonusPoints(
            roundToTwoDecimalPlaces(p1Points - p2Points)
          );
          setMainPlayerBonusPoints(0);
        } else {
          setMainPlayerBonusPoints(
            roundToTwoDecimalPlaces(p2Points - p1Points)
          );
          setSecondPlayerBonusPoints(0);
        }
      }
    }
  };

  const handleChangeSecondPlayer = (newValue: ILabelId | null) => {
    if (newValue) {
      setSecondPlayer(newValue);
      if (mainPlayer) {
        const p1Points = mainPlayer.projectedPoints;
        const p2Points = newValue.projectedPoints;
        if (p1Points > p2Points) {
          setSecondPlayerBonusPoints(
            roundToTwoDecimalPlaces(p1Points - p2Points)
          );
          setMainPlayerBonusPoints(0);
        } else {
          setMainPlayerBonusPoints(
            roundToTwoDecimalPlaces(p2Points - p1Points)
          );
          setSecondPlayerBonusPoints(0);
        }
      }
    }
  };

  const handleSubmit = () => {
    if (!!mainPlayer && !!secondPlayer && mainPlayer.id !== secondPlayer.id) {
      createNflTouchdownContest(
        {
          startTime: selectedDate,
          player1Id: mainPlayer.id,
          player2Id: secondPlayer.id,
          player1GameId: mainPlayer.gameId,
          player2GameId: secondPlayer.gameId,
          player1ProjFantasyPoints: mainPlayer.projectedPoints,
          player2ProjFantasyPoints: secondPlayer.projectedPoints,
          player1BonusPoints: mainPlayerBonusPoints,
          player2BonusPoints: secondPlayerBonusPoints,
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Touchdown contest created successfully!", {
              variant: "success",
            });
            queryClient.invalidateQueries(QueryKeys.GetNflTouchdownContests);
            queryClient.invalidateQueries(QueryKeys.FetchContests);
          },
          onError: (err: any) => {
            enqueueSnackbar(parseGQLError(err), {
              variant: "error",
            });
          },
        }
      );
    }
  };

  const handleDelete = (id: number) => {
    deleteNflTouchdownContest(
      { status: TOUCHDOWN_CONTEST_STATUSES.DELETED, id },
      {
        onSuccess: () => {
          enqueueSnackbar("Touchdown contest deleted successfully!", {
            variant: "success",
          });
          queryClient.invalidateQueries(QueryKeys.GetNflTouchdownContests);
          queryClient.invalidateQueries(QueryKeys.FetchContests);
        },
        onError: (error: any) => {
          enqueueSnackbar(parseGQLError(error), {
            variant: "error",
          });
        },
      }
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={7}>
        {touchdownContests?.getNflTouchdownContests?.length ? (
          <Box
            sx={{
              border: "1px solid #EEE8DC",
              backgroundColor: "#fff",
              padding: theme.spacing(2),
              marginTop: theme.spacing(1),
              height: "600px",
              overflowY: "scroll",
            }}
          >
            {touchdownContests.getNflTouchdownContests.map(
              ({
                id,
                player1,
                player2,
                player1BonusPoints,
                player2BonusPoints,
                status,
              }) => (
                <Paper
                  key={id}
                  sx={{
                    position: "relative",
                    marginBottom: theme.spacing(2),
                    backgroundColor: `${
                      status === "cancelled"
                        ? "rgba(255, 240, 241, 1)"
                        : "#FFFEFB"
                    }`,
                    border: `${
                      status === "cancelled"
                        ? "1px solid rgba(235, 23, 69, 1)"
                        : "1px solid rgba(226, 211, 187, 1)"
                    }`,
                  }}
                  elevation={0}
                >
                  <Stack
                    sx={{ padding: 1 }}
                    justifyContent="space-between"
                    alignItems="center"
                    direction="row"
                    spacing={2}
                  >
                    {/* ==========  Player 1 ========== */}
                    <Stack flex={2}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Avatar
                          sx={{ width: 48, height: 48 }}
                          src={player1.photoUrl}
                        />
                        <Box>
                          <Stack direction="row">
                            <StyledTypoItem>{player1.firstName}</StyledTypoItem>
                            <StyledTypoItem marginLeft={theme.spacing(1)}>
                              {player1.lastName}
                            </StyledTypoItem>
                          </Stack>
                          {player1BonusPoints ? (
                            <Chip
                              label={`+${player1BonusPoints} ${
                                player1BonusPoints > 1 ? "points" : "point"
                              } `}
                              size="small"
                              sx={{
                                backgroundColor: "rgba(22, 77, 52, 1)",
                                color: "#fff",
                                marginTop: "3px",
                                fontSize: "11px",
                                fontWeight: 700,
                                height: "auto",
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </Box>
                      </Stack>
                    </Stack>
                    <Typography flex={1} variant="h4">
                      Vs
                    </Typography>
                    {/* ==========  Player 2 ========== */}
                    <Stack flex={2} alignItems="flex-start">
                      <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <Avatar
                          sx={{ width: 48, height: 48 }}
                          src={player2.photoUrl}
                        />
                        <Box>
                          <Stack direction="row">
                            <StyledTypoItem>{player2.firstName}</StyledTypoItem>
                            <StyledTypoItem marginLeft={theme.spacing(1)}>
                              {player2.lastName}
                            </StyledTypoItem>
                          </Stack>
                          {player2BonusPoints ? (
                            <Chip
                              label={`+${player2BonusPoints} ${
                                player2BonusPoints > 1 ? "points" : "point"
                              }`}
                              size="small"
                              sx={{
                                backgroundColor: "rgba(22, 77, 52, 1)",
                                color: "#fff",
                                marginTop: "3px",
                                fontSize: "11px",
                                fontWeight: 700,
                                height: "auto",
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </Box>
                      </Stack>
                    </Stack>
                  </Stack>
                  <IconButton
                    sx={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      bottom: 0,
                      cursor: "pointer",
                    }}
                    onClick={() => handleDelete(id)}
                  >
                    <DeleteIcon />
                  </IconButton>

                  {status === "cancelled" ? (
                    <Box sx={{ padding: 1 }}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          color: "rgba(235, 23, 69, 1)",
                          fontWeight: "500",
                        }}
                      >
                        {player1.injuryStatus === "Out" ||
                        player2.injuryStatus === "Out"
                          ? "Player Ruled out"
                          : "Contest cancelled"}
                      </Typography>
                    </Box>
                  ) : (
                    ""
                  )}
                </Paper>
              )
            )}
          </Box>
        ) : (
          <Box
            sx={{
              marginTop: theme.spacing(1),
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              border: "1px solid #EEE8DC",
              backgroundColor: "#fff",
              padding: `${theme.spacing(14)} ${theme.spacing(2)}`,
            }}
          >
            <EmojiEventsOutlinedIcon
              sx={{
                fontSize: "60px",
                color: "#EEE8DC",
              }}
            />
            <Typography>No Contest Created</Typography>
          </Box>
        )}
      </Grid>
      <Grid item xs={5}>
        <Box
          sx={{
            backgroundColor: "#FAF2E2",
            padding: theme.spacing(2),
            marginTop: theme.spacing(1),
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <FormControl fullWidth>
                {playersLabelValues && (
                  <Autocomplete
                    disabled={disabled}
                    options={playersLabelValues}
                    value={mainPlayer}
                    clearOnEscape
                    onChange={(_props, nextValue) =>
                      handleChangeMainPlayer(nextValue)
                    }
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Main Player"
                        variant="standard"
                      />
                    )}
                  />
                )}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl variant="standard" fullWidth>
                <StyledInput
                  id="mainPlayerBonusPoints"
                  disabled={disabled}
                  type="number"
                  aria-describedby="main-player-bonus-point"
                  inputProps={{
                    "aria-label": "main player bonus point",
                  }}
                  placeholder="Bonus Point"
                  sx={{ marginTop: 0, height: "50px" }}
                  value={mainPlayerBonusPoints}
                  onChange={(ev) =>
                    setMainPlayerBonusPoints(Number(ev.target.value))
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth>
                {playersLabelValues && (
                  <Autocomplete
                    disabled={disabled}
                    options={playersLabelValues}
                    value={secondPlayer}
                    clearOnEscape
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      );
                    }}
                    onChange={(_props, nextValue) =>
                      handleChangeSecondPlayer(nextValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        sx={{ mb: 4 }}
                        {...params}
                        label="Second Player"
                        variant="standard"
                      />
                    )}
                  />
                )}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl variant="standard" fullWidth>
                <StyledInput
                  id="secondPlayerBonusPoints"
                  disabled={disabled}
                  type="number"
                  aria-describedby="second-player-bonus-point"
                  inputProps={{
                    "aria-label": "main player bonus point",
                  }}
                  placeholder="Bonus Point"
                  sx={{ marginTop: 0, height: "50px" }}
                  value={secondPlayerBonusPoints}
                  onChange={(ev) =>
                    setSecondPlayerBonusPoints(Number(ev.target.value))
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                sx={{ fontSize: "16px" }}
                fullWidth
                onClick={handleSubmit}
                disabled={disabled}
              >
                Create Matchup
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Divider
                sx={{
                  margin: `${theme.spacing(1)} 0`,
                  borderColor: "rgba(217, 217, 217, 1)",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                sx={{ fontSize: "16px" }}
                fullWidth
                disabled={disabled}
              >
                Import Matches
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
