import { useCallback, useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { NflSuggestionCard } from "@molecules/NflSuggestionCard/NflSuggestionCard";
import {
  SuggestionOrderInput,
  useDeleteNflMatchupMutation,
  useOrderNflMatchupsMutation,
} from "@src/graphql/graphql.generated";
import { SignedInWithSidebarLayout } from "../Layouts";
import { MatchupControls } from "../../organisms/Nfl/MatchupControls";
import { SUGGESTION_STATUSES } from "@src/constants/nflSuggestion";
import { useSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import { QueryKeys } from "@src/constants/QueryKeys";

interface IMatchup {
  id: number;
  orderIndex: number;
  status: string;
  playerOne: {
    id: number;
    remoteId: number;
    firstName: string;
    lastName: string;
    photoUrl: string;
    photoUrlHiRes: string;
  };
  playerTwo: {
    id: number;
    remoteId: number;
    firstName: string;
    lastName: string;
    photoUrl: string;
    photoUrlHiRes: string;
  };
}

interface NflMatchupTemplateProps {
  matchups: Array<IMatchup>;
}

export function NflMatchupTemplate({ matchups }: NflMatchupTemplateProps) {
  const [suggestions, setSuggestions] = useState<Array<IMatchup> | undefined>(
    matchups
  );
  const { mutate: orderNflMatchups } = useOrderNflMatchupsMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: deleteNflMatchup } = useDeleteNflMatchupMutation();
  const queryClient = useQueryClient();

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setSuggestions((prevSuggestions) => {
      if (prevSuggestions) {
        const result = Array.from(prevSuggestions);
        const [removed] = result.splice(dragIndex, 1);
        result.splice(hoverIndex, 0, removed);

        return result;
      }
    });
  }, []);

  const handleDrop = () => {
    const request: SuggestionOrderInput[] | undefined = suggestions?.map(
      (sugg, index) => {
        return {
          matchupId: sugg.id,
          orderIndex: index + 1,
        };
      }
    );
    request && orderNflMatchups({ data: request });
  };

  useEffect(() => {
    setSuggestions(matchups);
  }, [matchups]);

  const handleDelete = (matchupId: number) => {
    deleteNflMatchup(
      { status: SUGGESTION_STATUSES.CLOSED, matchupId },
      {
        onSuccess: () => {
          enqueueSnackbar("Suggestion deleted successfully!", {
            variant: "success",
          });
          queryClient.invalidateQueries(QueryKeys.GetNflMatchups);
        },
      }
    );
  };

  return (
    <SignedInWithSidebarLayout
      title="Matchups"
      SidebarComponent={<MatchupControls></MatchupControls>}
    >
      <DndProvider backend={HTML5Backend}>
        <Stack spacing={2}>
          {suggestions?.map((matchup, index) => {
            const { playerOne, playerTwo } = matchup;
            return (
              <NflSuggestionCard
                onDelete={handleDelete}
                onDrop={handleDrop}
                moveCard={moveCard}
                orderIndex={index}
                matchupId={matchup.id}
                key={matchup.id}
                playerOne={playerOne}
                playerTwo={playerTwo}
              />
            );
          })}
        </Stack>
      </DndProvider>
    </SignedInWithSidebarLayout>
  );
}
