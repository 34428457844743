import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  CardMedia,
  Chip,
  FormControl,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  MenuProps,
  OutlinedInput,
  Stack,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import { theme } from "@src/constants/theme";
import type { Identifier, XYCoord } from "dnd-core";
import { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";

const StyledInput = styled(OutlinedInput)(({ theme }) => ({
  marginTop: theme.spacing(1),
  "& input": {
    padding: "9px 12px 9px 12px",

    "&:focus": {
      outline: `none`,
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: `#E2D3BB !important`,
    "&:focus": {
      outline: `none`,
    },
  },
}));

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

interface DragItem {
  index: number;
  id: string;
  type: string;
}

interface SportCardProps {
  sport: any;
  sports: any;
  sportId: number;
  orderIndex: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  onDrop: () => void;
  onEdit: (selectedSportIndex: number) => void;
  onEnableOrDisable: (selectedSportIndex: number) => Promise<boolean>;
}

function SportCard(props: SportCardProps) {
  const {
    sport,
    sports,
    orderIndex,
    moveCard,
    onDrop,
    sportId,
    onEdit,
    onEnableOrDisable,
  } = props;

  const ref = useRef<HTMLDivElement>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [indexOfEditAndEnableButton, setIndexOfEditAndEnableButton] =
    useState<number>(0);

  const [, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: "card",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = orderIndex;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop() {
      onDrop();
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "card",
    item: () => {
      return { id: sportId, index: orderIndex };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  return (
    <Box
      sx={{
        border: "1px solid #EEE8DC",
        backgroundColor: sport?.enabled ? "#FFFEFB" : "rgba(217, 217, 217, 1)",
        padding: theme.spacing(2),
        marginBottom: theme.spacing(3),
        opacity: opacity,
        cursor: "pointer",
        position: "relative",
      }}
      ref={ref}
    >
      {sport.commonSportId > 0 && (
        <Chip
          label="C"
          size="medium"
          variant="outlined"
          sx={{
            backgroundColor: "#B69056",
            color: "#fff",
            position: "absolute",
            top: "0px",
            left: "0px",
            height: "22px",
            width: "22px",
            border: "0",
            paddingRight: "8px",
          }}
          style={{
            borderRadius: "0px 16px 16px 16px",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        />
      )}
      <Grid container>
        <Grid
          item
          xs={1}
          display="flex"
          alignItems="flex-start"
          paddingTop="10px"
        >
          <Stack direction="row" alignItems="center">
            <CardMedia
              sx={{
                marginRight: theme.spacing(1),
                marginBottom: "0 !important",
                fontSize: "20px",
                color: "#B69056",
              }}
              component="img"
              alt="image"
              image={sport?.logoUrl}
            />

            <Typography variant="h6" color={theme.palette.primary.main}>
              {sport?.name}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={9}>
          {sport?.questions?.map((question: any, index: number) => {
            return (
              <Grid container spacing={2} key={index + 1}>
                <Grid item xs={7} marginLeft={theme.spacing(4)}>
                  <FormControl variant="standard" fullWidth>
                    <StyledInput
                      disabled
                      id="contestQuestion"
                      aria-describedby="contest-question"
                      inputProps={{
                        "aria-label": "Contest Question",
                      }}
                      placeholder="Ex: Who scores more points?"
                      sx={{
                        background: sport?.enabled
                          ? "#fff"
                          : "rgb(238 238 238)",
                      }}
                      value={question?.question}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2} sx={{}}>
                  <FormControl variant="standard" fullWidth>
                    <StyledInput
                      disabled
                      id="bonusPoints"
                      aria-describedby="select-week"
                      inputProps={{
                        "aria-label": "select week",
                      }}
                      placeholder="Bonus points?"
                      sx={{
                        backgroundColor: sport?.enabled
                          ? "#fff"
                          : "rgb(238 238 238)",
                        width: "106px",
                      }}
                      value={question?.metric}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            );
          })}
        </Grid>

        <Grid
          item
          xs={1}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
            marginLeft: "32px",
          }}
        >
          <IconButton
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
              setIndexOfEditAndEnableButton(orderIndex);
            }}
          >
            <MoreVertIcon />
          </IconButton>
          <StyledMenu
            id="kebab-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => {
              setAnchorEl(null);
            }}
          >
            <MenuItem
              onClick={() => {
                onEdit(indexOfEditAndEnableButton);
                setAnchorEl(null);
              }}
            >
              Edit details
            </MenuItem>
            <MenuItem
              onClick={() => {
                onEnableOrDisable(indexOfEditAndEnableButton).then(() => {
                  setAnchorEl(null);
                });
              }}
            >
              {sports?.[indexOfEditAndEnableButton]?.enabled
                ? `Disable Sport`
                : `Enable Sport`}
            </MenuItem>
          </StyledMenu>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SportCard;
