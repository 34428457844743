import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const DATE_FORMAT = "MM-DD-YYYY";

interface WeekPickerProps {
  handleDateChange: (value: dayjs.Dayjs | null) => void;
  format?: string;
  defaultValue?: dayjs.Dayjs | null;
  value?: dayjs.Dayjs | null;
}

function WeekPicker(props: WeekPickerProps) {
  const { handleDateChange, format, defaultValue, value } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        defaultValue={defaultValue}
        value={value}
        onChange={handleDateChange}
        format={format ?? DATE_FORMAT}
        sx={{
          input: {
            padding: "9px 12px",
          },
        }}
      />
    </LocalizationProvider>
  );
}

export default WeekPicker;
