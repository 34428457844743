import { DataGrid, GridColumns, GridComparatorFn } from "@mui/x-data-grid";
import { SignedInLayout } from "@src/components/templates/Layouts";
import { useGetTopupsQuery } from "@src/graphql/graphql.generated";
import { renderCurrency } from "@src/helpers/currency";
import { dateRangeOperators } from "@organisms/DataGridFilters/DateRange/dateRangeOperators";
import dayjs from "dayjs";
import { useMemo } from "react";

export function Topups() {
  const {
    data: topupsData,
    isLoading
  } = useGetTopupsQuery(
    {},
    {
      staleTime: 300_000, // 5 min
    }
  );

  const columns = useMemo<GridColumns>(
    () => [
      { field: "id", headerName: "Id", width: 200 },
      { field: "amount", headerName: "Amount", width: 200 },
      { field: "userId", headerName: "User Id", width: 200 },
      { field: "fullName", headerName: "User Full Name", width: 200 },
      { field: "email", headerName: "Email", width: 200 },
      { field: "notes", headerName: "Notes", width: 350 },
      { field: "createdAt", headerName: "Created At", width: 200, valueFormatter: (params) =>
      dayjs(params.value as string).format("MMM D, YYYY, H:ma"),
    sortComparator: dateComparator,
    filterOperators: dateRangeOperators, },
    ],
    []
  );

  const rows = useMemo(
    () =>
    topupsData?.getTopups.map((topup) => ({
        ...topup,
        amount: renderCurrency(topup.amount / 100),
        fullName: topup.user.fullName,
        email: topup.user.email,
        userId: topup.user.id,
      })) ?? [],
    [topupsData]
  );
  
  return (
    <SignedInLayout title="Topups">
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <DataGrid
          columns={columns}
          columnBuffer={columns.length}
          initialState={{
            sorting: {
              sortModel: [{ field: "startDate", sort: "asc" }],
            },
          }}
          rows={rows}
        />
      )}
    </SignedInLayout>
  );
}

const dateComparator: GridComparatorFn = (isoDate1, isoDate2): 1 | -1 => {
  return dayjs(isoDate1 as string).isBefore(dayjs(isoDate2 as string)) ? 1 : -1;
};
