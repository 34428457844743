import { createTheme } from "@mui/material/styles";

const PRIMARY = "#B69056";
const PRIMARY_LIGHT = "#e9decc";
const PRIMARY_CONTRAST_TEXT = "#fff";
const SECONDARY = "#164D34";

const SYSTEM_FONT =
  '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';
export const MAIN_FONT = `Alegreya Sans SC, ${SYSTEM_FONT}`;
export const INPUT_FONT = `Roboto, ${SYSTEM_FONT}`;

let theme = createTheme({
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
          fontWeight: "bold",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: "rgba(0, 0, 0, 0.23)!important",
        },
      },
    },
  },
  palette: {
    primary: {
      light: PRIMARY_LIGHT,
      main: PRIMARY,
      contrastText: PRIMARY_CONTRAST_TEXT,
    },
    divider: "#9e9e9e",
    secondary: {
      main: SECONDARY,
    },
    info: {
      main: "#007bff",
    },
    action: {
      disabled: "#fff",
    },
  },
  typography: {
    fontFamily: MAIN_FONT,
    caption: {
      fontSize: 10,
    },
  },
});

theme = createTheme(theme, {
  components: {
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          [theme.breakpoints.up("lg")]: {
            borderBottom: "1px solid #9e9e9e",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          // minWidth: "500px",
          // maxWidth: "250px",
          overflow: "visible",
        },
      },
    },
  },
});

export { theme };

declare module "@mui/material/styles" {
  interface Palette {
    primary: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    primary?: PaletteOptions["primary"];
  }
}

// Update the Button's color prop options
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    primary: true;
  }
}
