import { FC } from "react";
import { useSnackbar } from "notistack";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Stack,
  useTheme,
} from "@mui/material";
import { DialogTitle } from "@src/components/molecules/DialogTitle";
import { useVoidContestsMutation } from "@src/graphql/graphql.generated";
import { SOMETHING_WRONG_HAPPENED } from "@src/constants/strings";
import { ModalProps, VOID_CONTESTS } from "./crons";

export const VoidContests: FC<ModalProps> = ({ isOpen, onSubmit, onClose }) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: voidContests, isLoading } = useVoidContestsMutation();

  const handleSubmit = () => {
    voidContests(
      {},
      {
        onSuccess: (data) => {
          if (data.voidContests.success) {
            onSubmit(VOID_CONTESTS);
            onClose();
          } else {
            enqueueSnackbar(SOMETHING_WRONG_HAPPENED, {
              variant: "default",
            });
          }
        },
        onError: () => {
          enqueueSnackbar(SOMETHING_WRONG_HAPPENED, {
            variant: "default",
          });
        },
      }
    );
  };

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
      onClose={onClose}
    >
      <DialogTitle onClose={onClose}>Void Contests</DialogTitle>
      <DialogContent>
        <Stack
          alignItems="center"
          flex={1}
          justifyContent="center"
          p={2}
          spacing={1}
        >
          Are you sure that you want to void contests?
        </Stack>
      </DialogContent>
      <DialogActions sx={{ bgcolor: theme.palette.grey[100], p: 2 }}>
        <Button
          className="text-white"
          color="secondary"
          disableElevation
          onClick={onClose}
          type="button"
          variant="outlined"
        >
          Close
        </Button>
        <Button
          className="text-white"
          color="primary"
          disableElevation
          onClick={handleSubmit}
          type="submit"
          variant="contained"
          disabled={isLoading}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
