import styled from "@emotion/styled";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  MenuProps,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid, GridColumns, GridMoreVertIcon } from "@mui/x-data-grid";
import { SignedInLayout } from "@src/components/templates/Layouts";
import { theme } from "@src/constants/theme";
import {
  useCreatePartnerAdminMutation,
  useGetPartnerAdminQuery,
  User,
  useUpdateAdminActiveStatusMutation,
  useUpdatePartnerAdminMutation,
} from "@src/graphql/graphql.generated";
import { useAuth } from "@src/hooks/useAuth";
import { parseGQLError } from "@src/utils/parse-error";
import { useSnackbar } from "notistack";
import { useMemo, useState } from "react";
import AddUserModal from "./AddEditPartnerUserModal";

export interface UserAction {
  target: User | null;
  status: "edit" | "delete";
}
const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    minWidth: 180,
    color: "rgb(55, 65, 81)",
    boxShadow: "rgb(0 0 0 / 20%) 0px 0px 1px 0px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
      },
    },
  },
}));

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: "1px solid #EEE8DC !important",
  "& .MuiDataGrid-cell": {
    borderBottom: "1px solid #EEE8DC",
  },
  "& .MuiDataGrid-columnHeaders": {
    borderBottom: "1px solid #EEE8DC",
  },
  "& .MuiDataGrid-footerContainer": {
    borderTop: "1px solid #EEE8DC",
  },
}));

export function PartnerUsers() {
  const { enqueueSnackbar } = useSnackbar();

  const [anchorEls, setAnchorEls] = useState<{
    [key: number]: HTMLElement | null;
  }>({});
  const [selectedPartnerData, setSelectedPartner] = useState<any>({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [editId, setEditId] = useState<number>(0);

  const { data: authInfo } = useAuth();
  const currentPartnerId = authInfo?.partnerId!;

  const { mutate: UpdateAdminStatus, isLoading: statusUpdateLoading } =
    useUpdateAdminActiveStatusMutation();
  const { mutate: UpdateAdminUser, isLoading: userUpdateLoading } =
    useUpdatePartnerAdminMutation();
  const {
    data: partnerAdminUser,
    isLoading,
    refetch,
  } = useGetPartnerAdminQuery(
    {
      partnerId: currentPartnerId,
    },
    {
      staleTime: 300_000, // 5 min
      enabled: currentPartnerId > 0,
    }
  );

  const handleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    partnerData: any,
    id: number
  ) => {
    setAnchorEls((prev) => ({ ...prev, [id]: event.currentTarget }));
    setSelectedPartner(partnerData);
  };

  const handleClose = (id: number) => {
    setAnchorEls((prev) => ({ ...prev, [id]: null }));
  };

  const { mutate: createPartnerAdmin, isLoading: isLoadingPartnerCreation } =
    useCreatePartnerAdminMutation();

  const handleModalClose = () => {
    setName("");
    setEmail("");
    handleModal();
  };

  const handleSave = () => {
    createPartnerAdmin(
      {
        data: { fullName: name, email: email, partnerId: authInfo?.partnerId! },
      },
      {
        onSuccess: () => {
          handleModalClose();
          refetch();
          enqueueSnackbar("User created successfully.", {
            variant: "success",
          });
        },
        onError: (error: any) => {
          enqueueSnackbar(parseGQLError(error, "Error while creating User."), {
            variant: "error",
          });
        },
      }
    );
  };

  const handleUpdate = () => {
    UpdateAdminUser(
      {
        data: {
          id: editId,
          fullName: name,
          email: email,
        },
      },
      {
        onSuccess: () => {
          refetch();
          enqueueSnackbar("User updated successfully.", {
            variant: "success",
          });
        },
        onError: (error: any) => {
          enqueueSnackbar(parseGQLError(error, "Error while updating User."), {
            variant: "error",
          });
        },
      }
    );
  };

  const handleDisabled = (status: any, adminId: number) => {
    setAnchorEls({});
    setSelectedPartner(null);
    UpdateAdminStatus(
      { data: { active: status, id: adminId } },
      {
        onSuccess: () => {
          refetch();
          enqueueSnackbar("User status updated successfully.", {
            variant: "success",
          });
        },
        onError: (error: any) => {
          enqueueSnackbar(
            parseGQLError(error, "Error while updating User status."),
            {
              variant: "error",
            }
          );
        },
      }
    );
  };

  const handleEdit = (id: number) => {
    setEditId(id);
    setName(selectedPartnerData?.fullName);
    setEmail(selectedPartnerData?.email);
    handleModal();
  };
  const USER_LIST_COLUMN = useMemo<GridColumns>(
    () => [
      {
        field: "fullName",
        headerName: "Name",
        width: 450,
      },
      { field: "email", headerName: "Email ID", width: 350 },
      {
        field: "action",
        headerName: "Action",
        width: 150,
        renderCell: (params) => (
          <>
            <IconButton
              sx={{
                position: "absolute",
                cursor: "pointer",
              }}
              onClick={(event) => handleClick(event, params.row, params.row.id)}
            >
              <GridMoreVertIcon />
            </IconButton>
            <StyledMenu
              id={`contest-actions-menu-${params.row.id}`}
              anchorEl={anchorEls[params.row.id] || null}
              open={Boolean(anchorEls[params.row.id])}
              onClose={() => handleClose(params.row.id)}
            >
              <MenuItem
                disabled={statusUpdateLoading}
                onClick={() =>
                  handleDisabled(
                    selectedPartnerData?.active ? false : true,
                    params?.row?.id
                  )
                }
              >
                {selectedPartnerData?.active ? "Disable" : "Enable"}
              </MenuItem>
              <MenuItem
                disabled={statusUpdateLoading || userUpdateLoading}
                onClick={() => handleEdit(params?.row?.id)}
              >
                Edit
              </MenuItem>
            </StyledMenu>
          </>
        ),
      },
    ],
    [anchorEls]
  );

  return (
    <SignedInLayout title="Admins" showPartners>
      <div>
        <Box marginBottom={theme.spacing(3)}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6"></Typography>
            <Stack direction="row" alignItems="center">
              <Button
                variant="contained"
                size="medium"
                onClick={() => setIsModalOpen(true)}
                // onClick={handleNavigationTouchdownHistory}
              >
                Add Admin
              </Button>
            </Stack>
          </Stack>
        </Box>

        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <Box
            style={{ height: "475px", overflow: "auto" }}
            marginBottom={theme.spacing(3)}
          >
            <StyledDataGrid
              columns={USER_LIST_COLUMN}
              initialState={{
                sorting: {
                  sortModel: [{ field: "startDate", sort: "asc" }],
                },
              }}
              getRowId={(row) => row?.id}
              rows={partnerAdminUser?.getPartnerAdmin ?? []}
              pageSize={7}
            />
          </Box>
        )}
        <AddUserModal
          open={isModalOpen}
          handleClose={handleModal}
          editId={editId}
          isLoadingPartnerCreation={isLoadingPartnerCreation}
          handleSave={handleSave}
          handleUpdate={handleUpdate}
          name={name}
          email={email}
          setName={setName}
          setEmail={setEmail}
          isLoading={isLoadingPartnerCreation || userUpdateLoading}
        />
      </div>
    </SignedInLayout>
  );
}
