import { useQuery } from "react-query";
import { QueryKeys } from "@src/constants/QueryKeys";
import { getAuthInfo } from "@src/services/auth";

export function useAuth() {
  return useQuery(QueryKeys.Auth, async () => getAuthInfo({ retry: true }), {
    staleTime: 60000,
    retry: false,
  });
}
