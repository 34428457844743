import { Routes, Route } from "react-router-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { ProtectedRoute } from "@atoms/ProtectedRoute";
import { SnackbarProvider } from "@atoms/SnackbarProvider";
import { Login } from "@src/pages/Login";
import { Dashboard } from "./pages/touchdowns/Dashboard";
import { FeatureFlags } from "@src/pages/pga/FeatureFlags";
import { Matchups } from "./pages/pga/Matchups";
import { Tournaments } from "./pages/pga/Tournaments";
import { PGACrons } from "./pages/pga/Crons";
import { ContestSuggestions } from "./pages/pga/ContestSuggestions/ContestSuggestions";
import { BonusPayout } from "./pages/pga/BonusPayout";
import { UserSignup } from "./pages/general/UserSignup";
import { Contests } from "./pages/pgaLogs/Contests";
import { Contests as NflContests } from "./pages/nflLogs/Contests";
import { DailyMetrics } from "./pages/pgaLogs/DailyMetrics";
import { Coupons } from "./pages/general/Coupons";
import { Roles } from "./pages/userManagement/Roles";
import { Users } from "./pages/userManagement/Users";
import { Topups } from "./pages/general/Topups";
import { WithdrawRequests } from "./pages/general/WithdrawRequests";
import { SupportTickets } from "./pages/general/SupportTickets";
import { NflMatchup } from "./pages/nfl/NflMatchup";
import { FeaturedContest } from "./pages/general/FeaturedContest";
import { BroadcastNotifications } from "./pages/general/BroadcastNotifications";
import { DeletionRequests } from "./pages/general/DeletionRequests";
import { Contest } from "./pages/touchdowns/Contest";
import { ContestHistory } from "./pages/touchdowns/ContestHistory";
import { Configure } from "./pages/touchdowns/Configure";
import { Analytics } from "./pages/touchdowns/Analytics";
import { MatchUpsInTouchDown } from "./components/touchDown/ContestHub/MatchUpsInTouchdown";
import { AllContests } from "./components/touchDown/ContestHub/AllContests";
import { Touchdown } from "./pages/partner/Touchdown";
import { Touchdown as F2PTowndown } from "./pages/f2p/Touchdown";
import { Contests as PartnerContests } from "./pages/partner/Contests";
import { Contests as CommonContests } from "./pages/common/Contests";
import { Contests as F2PPartnerContests } from "./pages/f2p/Contests";
import { Configure as PartnerConfigure } from "./pages/partner/Configure";
import { Configure as F2PPartnerConfigure } from "./pages/f2p/Configure";
import { Configure as CommonConfigure } from "./pages/common/Configure";
import { ContestHistory as PartnerContestHistory } from "./pages/partner/ContestHistory";
import { ContestHistory as F2PPartnerContestHistory } from "./pages/f2p/ContestHistory";
import { ContestCreate } from "./pages/partner/ContestCreate";
import { ContestCreate as F2PContestCreate } from "./pages/f2p/ContestCreate";
import { Partners } from "./pages/partner/Partners";
import { Partners as F2PPartners } from "./pages/f2p/Partners";
import { PartnerDashboard } from "./pages/partner/Dashboard";
import { PartnerDashboard as F2PPartnerDashboard } from "./pages/f2p/Dashboard";
import { PartnerUsers } from "./pages/partner/PartnerUsers";
import { PartnerUsers as F2PPartnerUsers } from "./pages/f2p/PartnerUsers";

export const ROUTES = {
  Home: "",
  Login: "/login",
  Logs: "/logs",
  Touchdown: {
    Dashboard: "/touchdown/dashboard",
    Contest: "/touchdown/contest",
    ContestHistory: "/touchdown/contest-history",
    Configure: "/touchdown/configure",
    Analytics: "/touchdown/analytics",
    ContestHub: "/touchdown/contesthub",
    AllContests: "/touchdown/all-contests",
  },
  Partner: {
    partners: "/partners",
    Dashboard: "/partner/dashboard",
    Touchdown: "/partner/touchdown",
    CreateUpdateTouchdown: "/partner/touchdown/:id",
    Contest: "/partner/contest",
    TouchdownHistory: "/partner/touchdown-history",
    Configure: "/partner/configure",
  },
  Common: {
    Sports: "/common/sports",
    Contests: "/common/contests",
  },
  F2P: {
    partners: "/F2P/partners",
    Dashboard: "/F2P/dashboard",
    Touchdown: "/F2P/touchdown",
    CreateUpdateTouchdown: "/F2P/touchdown/:id",
    Contest: "/F2P/contest",
    TouchdownHistory: "/F2P/touchdown-history",
    Configure: "/F2P/configure",
    User: "/F2P/users",
  },
  PGA: {
    FeatureFlags: "/pga/feature-flags",
    Matchups: "/pga/matchups",
    Tournaments: "/pga/tournaments",
    Crons: "/pga/crons",
    BonusPayout: "/pga/bonus-payout",
    ContestSuggestions: "/pga/contest-suggestions",
  },
  General: {
    UserSignup: "/general/user-signup",
    DeletionRequests: "/general/deletion-requests",
    Coupons: "/general/coupons",
    BonusPayout: "/general/bonus-payout",
    WithdrawRequests: "/general/withdraw-requests",
    Topups: "/general/topups",
    SupportTickets: "/general/support-tickets",
    FeaturedContests: "/general/featured-contests",
    BroadcastNotifications: "/general/broadcast-notifications",
  },
  Admin: {
    Roles: "/user-management/roles",
    Users: "/user-management/users",
  },
  PGAReports: {
    Contests: "/pga-reports/contests/:status",
    Daily: "/pga-reports/daily-metrics",
  },
  Nfl: {
    Matchups: "/nfl/matchups",
  },
  NFLReports: {
    Contests: "/nfl-reports/contests/:status",
  },
  Dashboard: "/dashboard",
  TouchdownScreen: "/touchdown",
  Contest: "/contest",
  Configure: "/configure",
  Users: "/Users",
  CreateUpdateTouchdown: "/touchdown/:id",
  TouchdownHistory: "/history",
};

export function AppRoutes() {
  return (
    <SnackbarProvider>
      <Routes>
        <Route
          path={ROUTES.Home}
          element={
            <GoogleReCaptchaProvider
              reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY!}
              useRecaptchaNet={true}
            >
              <Login />
            </GoogleReCaptchaProvider>
          }
        />
        <Route
          path={ROUTES.Login}
          element={
            <GoogleReCaptchaProvider
              reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY!}
              useRecaptchaNet={true}
            >
              <Login />
            </GoogleReCaptchaProvider>
          }
        />
        <Route
          path={ROUTES.Touchdown.Dashboard}
          element={
            <ProtectedRoute type="superAdmin">
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.Touchdown.Contest}
          element={
            <ProtectedRoute type="superAdmin">
              <Contest />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.Touchdown.ContestHistory}
          element={
            <ProtectedRoute type="superAdmin">
              <ContestHistory />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.Touchdown.Analytics}
          element={
            <ProtectedRoute type="superAdmin">
              <Analytics />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.Touchdown.ContestHub}
          element={
            <ProtectedRoute type="superAdmin">
              <MatchUpsInTouchDown />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.Touchdown.AllContests}
          element={
            <ProtectedRoute type="superAdmin">
              <AllContests />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.Touchdown.Configure}
          element={
            <ProtectedRoute type="superAdmin">
              <Configure />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.Partner.partners}
          element={
            <ProtectedRoute type="superAdmin">
              <Partners />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.Partner.Dashboard}
          element={
            <ProtectedRoute type="superAdmin">
              <PartnerDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.Partner.Touchdown}
          element={
            <ProtectedRoute type="superAdmin">
              <Touchdown />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.Partner.CreateUpdateTouchdown}
          element={
            <ProtectedRoute type="superAdmin">
              <ContestCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.Partner.Contest}
          element={
            <ProtectedRoute type="superAdmin">
              <PartnerContests />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.Partner.TouchdownHistory}
          element={
            <ProtectedRoute type="superAdmin">
              <PartnerContestHistory />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.Partner.Configure}
          element={
            <ProtectedRoute type="superAdmin">
              <PartnerConfigure />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.F2P.partners}
          element={
            <ProtectedRoute type="superAdmin">
              <F2PPartners />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.F2P.Dashboard}
          element={
            <ProtectedRoute type="superAdmin">
              <F2PPartnerDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.F2P.Touchdown}
          element={
            <ProtectedRoute type="superAdmin">
              <F2PTowndown />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.F2P.CreateUpdateTouchdown}
          element={
            <ProtectedRoute type="superAdmin">
              <F2PContestCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.F2P.Contest}
          element={
            <ProtectedRoute type="superAdmin">
              <F2PPartnerContests />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.F2P.TouchdownHistory}
          element={
            <ProtectedRoute type="superAdmin">
              <F2PPartnerContestHistory />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.F2P.Configure}
          element={
            <ProtectedRoute type="superAdmin">
              <F2PPartnerConfigure />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.F2P.User}
          element={
            <ProtectedRoute type="superAdmin">
              <F2PPartnerUsers />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.Common.Sports}
          element={
            <ProtectedRoute type="superAdmin">
              <CommonConfigure />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.Common.Contests}
          element={
            <ProtectedRoute type="superAdmin">
              <CommonContests />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.PGA.FeatureFlags}
          element={
            <ProtectedRoute type="superAdmin">
              <FeatureFlags />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.PGA.Matchups}
          element={
            <ProtectedRoute type="superAdmin">
              <Matchups />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.PGA.Tournaments}
          element={
            <ProtectedRoute type="superAdmin">
              <Tournaments />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.PGA.Crons}
          element={
            <ProtectedRoute type="superAdmin">
              <PGACrons />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.PGA.BonusPayout}
          element={
            <ProtectedRoute type="superAdmin">
              <BonusPayout />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.General.Coupons}
          element={
            <ProtectedRoute type="superAdmin">
              <Coupons />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.General.UserSignup}
          element={
            <ProtectedRoute type="superAdmin">
              <UserSignup />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.General.DeletionRequests}
          element={
            <ProtectedRoute type="superAdmin">
              <DeletionRequests />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.General.BonusPayout}
          element={
            <ProtectedRoute type="superAdmin">
              <BonusPayout />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.General.WithdrawRequests}
          element={
            <ProtectedRoute type="superAdmin">
              <WithdrawRequests />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.General.SupportTickets}
          element={
            <ProtectedRoute type="superAdmin">
              <SupportTickets />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.General.Topups}
          element={
            <ProtectedRoute type="superAdmin">
              <Topups />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.PGAReports.Contests}
          element={
            <ProtectedRoute type="superAdmin">
              <Contests />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.Admin.Roles}
          element={
            <ProtectedRoute type="superAdmin">
              <Roles />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.Admin.Users}
          element={
            <ProtectedRoute type="superAdmin">
              <Users />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.PGAReports.Daily}
          element={
            <ProtectedRoute type="superAdmin">
              <DailyMetrics />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.PGA.ContestSuggestions}
          element={
            <ProtectedRoute type="superAdmin">
              <ContestSuggestions />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.Nfl.Matchups}
          element={
            <ProtectedRoute type="superAdmin">
              <NflMatchup />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.General.FeaturedContests}
          element={
            <ProtectedRoute type="superAdmin">
              <FeaturedContest />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.General.BroadcastNotifications}
          element={
            <ProtectedRoute type="superAdmin">
              <BroadcastNotifications />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTES.NFLReports.Contests}
          element={
            <ProtectedRoute type="superAdmin">
              <NflContests />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.Dashboard}
          element={
            <ProtectedRoute type="admin">
              <PartnerDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.TouchdownScreen}
          element={
            <ProtectedRoute type="admin">
              <Touchdown />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.Contest}
          element={
            <ProtectedRoute type="admin">
              <PartnerContests />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.Configure}
          element={
            <ProtectedRoute type="admin">
              <PartnerConfigure />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.Users}
          element={
            <ProtectedRoute type="admin">
              <PartnerUsers />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.CreateUpdateTouchdown}
          element={
            <ProtectedRoute type="admin">
              <ContestCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.TouchdownHistory}
          element={
            <ProtectedRoute type="admin">
              <PartnerContestHistory />
            </ProtectedRoute>
          }
        />
      </Routes>
    </SnackbarProvider>
  );
}
