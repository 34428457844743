import { useMutation } from "react-query";
import { ISignInPayload, ISignInResponse, signIn } from "@src/services/auth";

export function useLogin() {
  return useMutation<
    Omit<ISignInResponse, "accessToken" | "refreshToken">,
    Error,
    ISignInPayload
  >(async (vars) => {
    const resp = await signIn(vars);
    await Promise.resolve(setTimeout(() => {}, 1000));
    await new Promise((resolve) => setTimeout(resolve, 500));
    return resp;
  });
}
