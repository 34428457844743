import {
  Avatar,
  Box,
  Checkbox,
  Chip,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import {
  ICommonContest,
  ISelectedContests,
} from "@src/components/partner/ContestHub/CommonContest";
import { Contest_Source } from "@src/graphql/graphql.generated";
import { useRef } from "react";

const contestStyles: any = {
  draft_background: "rgba(249, 249, 249, 1)",
  draft_border: "1px solid rgba(204, 204, 204, 1)",
  cancelled_background: "rgba(255, 240, 241, 1)",
  cancelled_border: "1px solid rgba(235, 23, 69, 1)",
  published_background: "rgba(255, 252, 246, 1)",
  published_border: "1px solid rgba(226, 211, 187, 1)",
  completed_background: "rgba(245, 255, 249, 1) ",
  completed_border: "1px solid rgba(189, 227, 202, 1)",
};

export function CommonContestCard(props: {
  contestData: ICommonContest;
  publishedContests: string[];
  setPublishedContests: any;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const { contestData, publishedContests, setPublishedContests } = props;

  const {
    id,
    source,
    player1Name,
    player2Name,
    player1BonusPoints,
    player2BonusPoints,
    metric,
    player1PhotoUrl,
    player2PhotoUrl,
  } = contestData;

  const getContestStyle = (styleName: string) => {
    return contestStyles[`published_${styleName}`];
  };

  const handleContestSelection = (
    checked: boolean,
    id: number,
    source: string
  ) => {
    if (checked) {
      setPublishedContests([...publishedContests, `${source}_${id}`]);
    } else {
      const updatedArray = publishedContests.filter(
        (item) => item !== `${source}_${id}`
      );
      setPublishedContests(updatedArray);
    }
  };

  return (
    <Box
      ref={ref}
      sx={{
        maxWidth: 600,
        margin: "10px 0px 10px 0px",
      }}
    >
      <Paper
        sx={{
          position: "relative",
          padding: "6px",
          backgroundColor: getContestStyle("background"),
          border: getContestStyle("border"),
          boxShadow: "none",
        }}
        elevation={4}
      >
        <Stack
          sx={{ padding: 1 }}
          justifyContent="space-between"
          alignItems="center"
          direction="row"
          spacing={2}
        >
          <Stack flex={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Checkbox
                style={{ color: "#A67A45" }}
                checked={publishedContests.includes(`${source}_${id}`)}
                onChange={(_, checked) =>
                  handleContestSelection(checked, id, source)
                }
              />
              <Avatar sx={{ width: 48, height: 48 }} src={player1PhotoUrl} />
              <Stack spacing={1}>
                <Typography>{player1Name}</Typography>
                {player1BonusPoints ? (
                  <Chip
                    label={`+${player1BonusPoints} ${metric ?? "Points"}`}
                    size="small"
                    sx={{
                      backgroundColor: "rgba(22, 77, 52, 1)",
                      color: "#fff",
                      marginTop: "3px",
                      fontSize: "11px",
                      fontWeight: 700,
                      height: "auto",
                      maxWidth: "70px",
                    }}
                  />
                ) : (
                  ""
                )}
              </Stack>
            </Stack>
          </Stack>
          <Typography flex={1} variant="h4">
            Vs
          </Typography>
          <Stack flex={2} alignItems="flex-start">
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="flex-end"
            >
              <Avatar sx={{ width: 48, height: 48 }} src={player2PhotoUrl} />
              <Stack spacing={1}>
                <Typography>{player2Name}</Typography>
                {player2BonusPoints ? (
                  <Chip
                    label={`+${player2BonusPoints} ${metric ?? "Points"}`}
                    size="small"
                    sx={{
                      backgroundColor: "rgba(22, 77, 52, 1)",
                      color: "#fff",
                      marginTop: "3px",
                      fontSize: "11px",
                      fontWeight: 700,
                      height: "auto",
                      maxWidth: "70px",
                    }}
                  />
                ) : (
                  ""
                )}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
}
