import { useMemo, useState } from "react";
import {
  DataGrid,
  GridColumns,
  GridComparatorFn,
  GridFilterModel,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import {
  ContestsReportFilterType,
  useGetAllContestsQuery,
} from "@src/graphql/graphql.generated";
import { c2d, renderCurrency } from "@src/helpers/currency";
import { dateRangeOperators } from "@organisms/DataGridFilters/DateRange/dateRangeOperators";

export function ClosedUnmatchedContests() {
  const [isFilterDate, setIsFilterDate] = useState(false);
  const { data: contests, isLoading } = useGetAllContestsQuery(
    { data: { filter: ContestsReportFilterType.ClosedUnmatched } },
    {
      staleTime: 300_000, // 5 min
    }
  );

  const onFilterChange = (model: GridFilterModel) => {
    const { columnField } = model.items[0];
    if (columnField === "createdAt" || columnField === "endTime") {
      setIsFilterDate(true);
      return;
    }
    setIsFilterDate(false);
  };

  const columns = useMemo<GridColumns>(
    () => [
      { field: "id", headerName: "Contest ID", width: 80 },
      { field: "contestType", headerName: "Type", width: 130 },
      {
        field: "createdAt",
        headerName: "Creation Date",
        width: 200,
        type: "date",
        valueFormatter: (params) =>
          dayjs(params.value as string).format("MMM D, YYYY, H:ma"),
        sortComparator: dateComparator,
        filterOperators: dateRangeOperators,
      },
      {
        field: "endTime",
        headerName: "End Date",
        width: 200,
        type: "date",
        valueGetter: (params) =>
          dayjs(params.value as string).format("MMM D, YYYY, H:ma"),
        valueFormatter: (params) =>
          dayjs(params.value as string).format("MMM D, YYYY, H:ma"),
        sortComparator: dateComparator,
        filterOperators: dateRangeOperators,
      },
      { field: "creatorName", headerName: "Creator Name", width: 200 },
      { field: "creatorEmail", headerName: "Creator Email", width: 200 },
      { field: "creatorPlayer", headerName: "Creator Player", width: 200 },
      { field: "claimerPlayer", headerName: "Claimer Player", width: 200 },
      { field: "entryAmount", headerName: "Entry", width: 120 },
    ],
    []
  );

  const rows = useMemo(
    () =>
      contests?.getAllContests.map((contest) => ({
        ...contest,
        creatorName: contest.creatorUser?.fullName,
        creatorEmail: contest.creatorUser?.email,
        creatorPlayer: `${contest.creatorPlayer.firstName} ${contest.creatorPlayer.lastName}`,
        claimerPlayer: `${contest.claimerPlayer.firstName} ${contest.claimerPlayer.lastName}`,
        entryAmount: renderCurrency(c2d(contest.entryAmount)),
      })) ?? [],
    [contests]
  );

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <DataGrid
          columns={columns}
          initialState={{
            sorting: {
              sortModel: [{ field: "startDate", sort: "asc" }],
            },
          }}
          rows={rows}
          sx={{
            minHeight: "75vh",
          }}
          componentsProps={{
            panel: {
              sx: {
                "& .MuiDataGrid-filterForm": {
                  width: isFilterDate ? 600 : 500,
                },
              },
            },
          }}
          onFilterModelChange={onFilterChange}
        />
      )}
    </>
  );
}

const dateComparator: GridComparatorFn = (isoDate1, isoDate2): 1 | -1 => {
  return dayjs(isoDate1 as string).isBefore(dayjs(isoDate2 as string)) ? 1 : -1;
};
