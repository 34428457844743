import { CommonContestCard } from "@molecules/CommonContestCard";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import FilterListIcon from "@mui/icons-material/FilterAltOutlined";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tab,
  Typography,
  styled,
} from "@mui/material";
import { QueryKeys } from "@src/constants/QueryKeys";
import { theme } from "@src/constants/theme";
import {
  Contest_Source,
  Contest_Statuses,
  useCountCommonContestsQuery,
  useGetCommonSportQuery,
  useGetPartnerCommonContestsQuery,
  usePublishPartnerCommonContestMutation,
} from "@src/graphql/graphql.generated";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";

const StyledTab = styled(Tab)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)} 0`,
  minHeight: "auto",
  justifyContent: "flex-start",
  marginRight: theme.spacing(3),
  fontSize: "16px",
}));

const StyledTabList = styled(TabList)((theme) => ({
  position: "relative",
  "&::after": {
    content: '""',
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    height: "1px",
    backgroundColor: "#E0E0E0",
    zIndex: 1,
  },
  "& .MuiTabs-indicator": {
    height: "3px",
  },
}));

const getSportIcon = (url: string, name: number, selectedTab: number) => {
  return (
    <CardMedia
      sx={{
        marginRight: theme.spacing(1),
        marginBottom: "0 !important",
        fontSize: "18px",
        filter: name !== selectedTab ? "grayscale(100%)" : "",
        height:
          url ===
          "https://topprop-static-assets.s3.amazonaws.com/sports/pga.svg"
            ? "23px"
            : "18px",
        width: "18px",
      }}
      component="img"
      alt="image"
      image={url}
    />
  );
};

export interface ICommonContest {
  id: number;
  player1Name: string;
  player2Name: string;
  player1Id: string;
  player2Id: string;
  source: Contest_Source;
  player1BonusPoints: number;
  player2BonusPoints: number;
  metric: string;
  question: string;
  player1PhotoUrl: string;
  player2PhotoUrl: string;
  status: Contest_Statuses;
}

export interface ISelectedContests {
  hubContests: number[];
  automated: { NBA: number[]; SOCCER: number[] };
}

export const ContestContest = ({
  selectedDate,
  partnerId,
  prizePoolId,
}: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const {
    data: commonSports,
    isLoading: isSportsListLoading,
    isError: isSportListError,
    isFetching: isSportsFetching,
  } = useGetCommonSportQuery();
  const { mutate: publishContest } = usePublishPartnerCommonContestMutation();

  const [availableSports, setAvailableSports] = useState<Array<any>>([]);
  const [selectedTabId, setSelectedTabId] = useState<any>(0);
  const [allContestCount, setAllContestCount] = useState<any>(0);
  const [contests, setContests] = useState<any>([]);
  const [publishedContests, setPublishedContests] = useState<any[]>([]);
  const [contestsCount, setContestsCount] = useState<any>({});
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const updateSelectedContests = () => {
    const hubContests = publishedContests
      .filter((id) => id.startsWith(Contest_Source.Hub))
      .map((id) => parseInt(id.split("_")[1]));

    const nbaContests = publishedContests
      .filter((id) => id.startsWith(Contest_Source.Nba))
      .map((id) => parseInt(id.split("_")[1]));

    const soccerContests = publishedContests
      .filter((id) => id.startsWith(Contest_Source.Soccer))
      .map((id) => parseInt(id.split("_")[1]));

    return {
      hubContests,
      automated: { NBA: nbaContests, SOCCER: soccerContests },
    };
  };

  const handleClose = (type?: string) => {
    setAnchorEl(null);
    if (type === "all") {
      setContests(getAllContests?.getPartnerCommonContests?.commonContests);
    } else if (type === "selected") {
      const selectedArray =
        getAllContests?.getPartnerCommonContests?.commonContests.filter(
          (contest) =>
            getAllContests?.getPartnerCommonContests?.publishedContestsId.includes(
              `${contest.source}_${contest.id}`
            )
        );
      setContests(selectedArray);
    } else if (type === "unselected") {
      const unselectedArray =
        getAllContests?.getPartnerCommonContests?.commonContests.filter(
          (contest) =>
            !getAllContests?.getPartnerCommonContests?.publishedContestsId.includes(
              `${contest.source}_${contest.id}`
            )
        );
      setContests(unselectedArray);
    }
  };

  const {
    data: getAllContests,
    isLoading: isContestsLoading,
    isError: isContestsError,
  } = useGetPartnerCommonContestsQuery(
    {
      date: selectedDate,
      sportId: selectedTabId,
      prizePoolId: prizePoolId,
    },
    { cacheTime: 0, enabled: selectedTabId > 0 }
  );
  const { data: hubContestsCount } = useCountCommonContestsQuery(
    {
      date: selectedDate,
      type: "both",
    },
    {
      staleTime: 300_000, // 5 min
      enabled: partnerId > 0,
    }
  );

  useEffect(() => {
    setContests(getAllContests?.getPartnerCommonContests?.commonContests);
    setPublishedContests(
      getAllContests?.getPartnerCommonContests?.publishedContestsId ?? []
    );
  }, [getAllContests?.getPartnerCommonContests]);

  useEffect(() => {
    let sum = 0;
    const countObj = hubContestsCount?.countCommonContests.reduce(
      (acc: any, curr) => {
        acc[curr.id] = curr.count;
        sum = sum + curr.count;
        return acc;
      },
      {}
    );
    setContestsCount({ ...countObj, "0": sum });
  }, [hubContestsCount?.countCommonContests]);

  useEffect(() => {
    if (
      commonSports?.getCommonSport?.length &&
      hubContestsCount?.countCommonContests
    ) {
      // const sports = commonSports.getCommonSport.filter((sport) => {
      //   const { enabled, id: sportId } = sport;
      //   if (enabled) {
      //     return sport;
      //   } else {
      //     const contest = hubContestsCount.countPartnerContests.find(
      //       ({ id }) => id === sportId
      //     );
      //     if (contest && +contest?.count > 0) return sport;
      //   }
      // });
      setAvailableSports(commonSports.getCommonSport);
      setSelectedTabId(commonSports.getCommonSport?.[0].id);
    }
  }, [commonSports, setSelectedTabId, hubContestsCount]);

  useEffect(() => {
    if (selectedTabId === 0) {
      setAllContestCount(
        getAllContests?.getPartnerCommonContests?.commonContests.length
      );
    }
  }, [selectedTabId, getAllContests?.getPartnerCommonContests]);

  const publishContestsInPartner = () => {
    const selectedIds = updateSelectedContests();
    publishContest(
      {
        data: {
          contests: selectedIds,
          partnerId,
          prizePoolId,
          commonSportId: selectedTabId,
        },
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Contests published successfully.", {
            variant: "success",
          });

          queryClient.invalidateQueries(QueryKeys.FetchPartnerContests);
          queryClient.invalidateQueries(QueryKeys.GetPartnerSports);
        },
        onError: () => {
          enqueueSnackbar("Error while publishing contests.", {
            variant: "error",
          });
        },
      }
    );
  };

  return (
    <Box marginBottom={theme.spacing(3)}>
      <Card
        elevation={0}
        sx={{
          // border: "1px solid #EEE8DC",
          // padding: theme.spacing(1),
          marginBottom: theme.spacing(3),
        }}
      >
        <CardContent sx={{ p: 0 }}>
          <Box>
            <TabContext value={selectedTabId}>
              {isSportsListLoading || isSportListError || isSportsFetching ? (
                <Box
                  py={2}
                  justifyContent="center"
                  textAlign="center"
                  display="flex"
                  alignItems="center"
                >
                  <Typography sx={{ fontSize: "20px" }}>Loading...</Typography>
                  <CircularProgress size={22} />
                </Box>
              ) : (
                <>
                  {availableSports?.length === 0 ? (
                    <Box
                      sx={{
                        marginTop: theme.spacing(1),
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        border: "1px solid #EEE8DC",
                        backgroundColor: "#fff",
                        padding: `${theme.spacing(14)} ${theme.spacing(2)}`,
                      }}
                    >
                      <EmojiEventsOutlinedIcon
                        sx={{
                          fontSize: "60px",
                          color: "#EEE8DC",
                        }}
                      />
                      <Typography>No Sport Created</Typography>
                    </Box>
                  ) : (
                    <>
                      <Box>
                        <StyledTabList
                          onChange={(_, value) => {
                            setSelectedTabId(value);
                          }}
                          aria-label={"sports"}
                          variant="scrollable"
                          scrollButtons={false}
                          sx={{
                            minHeight: "auto",
                          }}
                        >
                          {availableSports?.map((sport, index) => (
                            <StyledTab
                              key={index + 1}
                              label={
                                <>
                                  {sport?.name}
                                  <Badge
                                    // badgeContent={`${ selectedTabId === 0 ? allContestCount : contestsCount[sport.id] || 0 }`}
                                    badgeContent={`${
                                      contestsCount[sport.id] || 0
                                    }`}
                                    sx={{
                                      marginLeft: theme.spacing(2),
                                      "& .MuiBadge-badge": {
                                        backgroundColor:
                                          sport?.id !== selectedTabId
                                            ? "#F0F0F0"
                                            : "rgba(250, 242, 226, 1)",

                                        color:
                                          sport?.id !== selectedTabId
                                            ? "#8C8C8C"
                                            : theme.palette.primary.main,
                                        fontSize: "16px",
                                      },
                                    }}
                                  ></Badge>
                                </>
                              }
                              value={sport.id}
                              icon={getSportIcon(
                                sport?.logoUrl,
                                sport?.id,
                                selectedTabId
                              )}
                            />
                          ))}
                        </StyledTabList>
                      </Box>

                      <Box
                        sx={{
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#fff",
                          // padding: `${theme.spacing(0)} ${theme.spacing(3)}`,
                          // marginTop: `${theme.spacing(4)}`,
                        }}
                      >
                        <Stack spacing={2}>
                          <TabPanel
                            value={selectedTabId}
                            sx={{
                              padding: `${theme.spacing(2)} 0 ${theme.spacing(
                                3
                              )} 0`,
                            }}
                          >
                            {isContestsLoading || isContestsError ? (
                              <Box
                                py={2}
                                sx={{
                                  fontSize: "20px",
                                  textAlign: "center",
                                }}
                              >
                                Loading...
                                <CircularProgress size={24} />
                              </Box>
                            ) : (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    maxWidth: 600,
                                  }}
                                >
                                  <Typography
                                    variant="body1"
                                    style={{
                                      color: "#A67A45",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      const selectAll =
                                        getAllContests?.getPartnerCommonContests?.commonContests?.map(
                                          (contest) =>
                                            `${contest?.source}_${contest?.id}`
                                        );
                                      setPublishedContests(selectAll || []);
                                    }}
                                  >
                                    SELECT ALL CONTEST
                                  </Typography>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "10px",
                                    }}
                                  >
                                    <IconButton
                                      style={{
                                        border: "1px solid #A67A45",
                                        color: "#A67A45",
                                        borderRadius: 2,
                                      }}
                                      onClick={handleClick}
                                    >
                                      <FilterListIcon />
                                    </IconButton>
                                    <Menu
                                      anchorEl={anchorEl}
                                      open={Boolean(anchorEl)}
                                      onClose={() => handleClose()}
                                    >
                                      <MenuItem
                                        onClick={() => handleClose("all")}
                                      >
                                        All Contest
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => handleClose("selected")}
                                      >
                                        Selected Contest
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() =>
                                          handleClose("unselected")
                                        }
                                      >
                                        Unselected Contest
                                      </MenuItem>
                                    </Menu>
                                    <Button
                                      variant="contained"
                                      style={{
                                        backgroundColor: "#A67A45",
                                        color: "#FFF",
                                        opacity:
                                          publishedContests?.length === 0
                                            ? 0.6
                                            : 1,
                                      }}
                                      disabled={publishedContests?.length === 0}
                                      onClick={() => {
                                        publishContestsInPartner();
                                      }}
                                    >
                                      PUBLISH CONTEST
                                    </Button>
                                  </div>
                                </div>
                                {contests?.length > 0 ? (
                                  contests?.map((contest: ICommonContest) => {
                                    return (
                                      <CommonContestCard
                                        key={contest.id}
                                        contestData={contest}
                                        publishedContests={publishedContests}
                                        setPublishedContests={
                                          setPublishedContests
                                        }
                                      />
                                    );
                                  })
                                ) : (
                                  <Box
                                    sx={{
                                      marginTop: theme.spacing(1),
                                      alignItems: "center",
                                      justifyContent: "center",
                                      textAlign: "center",
                                      backgroundColor: "#fff",
                                      padding: `${theme.spacing(
                                        14
                                      )} ${theme.spacing(2)}`,
                                    }}
                                  >
                                    <EmojiEventsOutlinedIcon
                                      sx={{
                                        fontSize: "60px",
                                        color: "#EEE8DC",
                                      }}
                                    />
                                    <Typography>No contests Created</Typography>
                                  </Box>
                                )}
                              </>
                            )}
                          </TabPanel>
                        </Stack>
                      </Box>
                    </>
                  )}
                </>
              )}
            </TabContext>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
