import { useMemo } from "react";
import dayjs from "dayjs";
import { DataGrid, GridColumns, GridComparatorFn } from "@mui/x-data-grid";
import { SignedInLayout } from "@src/components/templates/Layouts";
import { useGetAllSupportTicketsQuery } from "@src/graphql/graphql.generated";
import { dateRangeOperators } from "@organisms/DataGridFilters/DateRange/dateRangeOperators";

export function SupportTickets() {
  const {
    data,
    isLoading
  } = useGetAllSupportTicketsQuery(
    {},
    {
      staleTime: 300_000, // 5 min
    }
  );

  const columns = useMemo<GridColumns>(
    () => [
      { field: "id", headerName: "Id", width: 200 },
      { field: "userId", headerName: "User Id", width: 200 },
      { field: "email", headerName: "Email", width: 200 },
      { field: "message", headerName: "Message", width: 200 },
      { field: "createdAt", headerName: "Created At", width: 200, valueFormatter: (params) =>
        dayjs(params.value as string).format("MMM D, YYYY, H:ma"),
        sortComparator: dateComparator,
        filterOperators: dateRangeOperators,
      }
    ],
    []
  );

  const rows = useMemo(
    () =>
    data?.getAllSupportTickets.map((ticket) => ({
        ...ticket,
      })) ?? [],
    [data?.getAllSupportTickets]
  );

  return (
    <SignedInLayout title="Support Tickets">
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <DataGrid
          columns={columns}
          columnBuffer={columns.length}
          initialState={{
            sorting: {
              sortModel: [{ field: "startDate", sort: "asc" }],
            },
          }}
          rows={rows}
        />
      )}
    </SignedInLayout>
  );
}


const dateComparator: GridComparatorFn = (isoDate1, isoDate2): 1 | -1 => {
  return dayjs(isoDate1 as string).isBefore(dayjs(isoDate2 as string)) ? 1 : -1;
};
