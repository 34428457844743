import WestIcon from "@mui/icons-material/West";
import { Box, Typography, styled } from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { MM_DD_YYYY, YYYY_MM_DD } from "@src/constants/Touchdown";
import { theme } from "@src/constants/theme";
import { useGetWeeklySubscribersQuery } from "@src/graphql/graphql.generated";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

interface WeeklySubscriberProps {
  currentWeekRange: Array<dayjs.Dayjs>;
}

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: "1px solid #EEE8DC",
  "& .MuiDataGrid-cell": {
    borderBottom: "1px solid #EEE8DC",
  },
  "& .MuiDataGrid-columnHeaders": {
    borderBottom: "1px solid #EEE8DC",
  },
  "& .MuiDataGrid-footerContainer": {
    borderTop: "1px solid #EEE8DC",
  },
}));

export default function WeeklySubscriber(props: WeeklySubscriberProps) {
  const { currentWeekRange } = props;

  const navigate = useNavigate();

  const { data: weeklySubscriber } = useGetWeeklySubscribersQuery({
    startDate: currentWeekRange[0].format(YYYY_MM_DD),
    endDate: currentWeekRange[1].format(YYYY_MM_DD),
  });


  const COLUMNS: GridColumns = useMemo<GridColumns>(
    () => [
      { field: "email", headerName: "Email", width: 200 },
      { field: "userName", headerName: "Username", width: 200 },
      { field: "fullName", headerName: "User", width: 200 },
      { field: "subScriberDate", headerName: "Date of Subscribe", width: 200 },
      { field: "subScribedDays", headerName: "Subscribe Days", width: 200 },
      { field: "entryFee", headerName: "Entry Fee", width: 200 },
    ],
    []
  );

  return (
    <>
      <Box marginBottom={theme.spacing(3)}>
        <Typography
          style={{
            display: "flex",
            alignItems: "center",
            color: "#212121",
            textDecoration: "none",
            cursor: "pointer",
          }}
          // onClick={openModal}
          onClick={() => navigate("/touchdown/contest")}
        >
          <WestIcon
            style={{ fontSize: "16px", marginRight: theme.spacing(1) }}
          />{" "}
          Back
        </Typography>
      </Box>

      <Box marginBottom={theme.spacing(3)}>
        <Typography
          variant="h5"
          fontWeight={500}
          marginBottom={theme.spacing(3)}
        >
          weekly subscriber ({currentWeekRange[0].format(MM_DD_YYYY)} to{" "}
          {currentWeekRange[1].format(MM_DD_YYYY)})
        </Typography>
      </Box>
      <Box
        style={{ height: "370px", overflow: "auto" }}
        marginBottom={theme.spacing(3)}
      >
        <StyledDataGrid
          columns={COLUMNS}
          initialState={{
            sorting: {
              sortModel: [{ field: "email", sort: "asc" }],
            },
          }}
          getRowId={(row)=> row.email}
          rows={weeklySubscriber?.getWeeklySubscribers?.items || []}
          pageSize={5}
          pagination
        />
      </Box>
    </>
  );
}
