import { ActiveTouchdownDetails } from "@src/components/f2pPartner/Dashboard/ActiveTouchdownDetails";
import { TouchdownDetails } from "@src/components/f2pPartner/TouchdownDetails";
import { SignedInLayout } from "@src/components/templates/Layouts";
import { usePartner } from "@src/context/PartnerContext";
import {
  useFetchF2PTouchdownQuery,
  useGetF2PActiveTouchdownsQuery,
} from "@src/graphql/graphql.generated";
import { useAuth } from "@src/hooks/useAuth";
import { formatDate } from "@src/utils/utils";
import dayjs from "dayjs";
import { useState } from "react";

export function Touchdown() {
  const { data: authInfo } = useAuth();
  const { partnerId }: any = usePartner();
  const currentPartnerId =
    authInfo?.role === "ADMIN" ? authInfo?.partnerId! : partnerId!;
  const weekRange: Array<dayjs.Dayjs> = [
    dayjs().startOf("week"),
    dayjs().endOf("week"),
  ];
  const { data: currentWeekTouchdownData } = useGetF2PActiveTouchdownsQuery(
    {
      partnerId: currentPartnerId,
    },
    {
      staleTime: 300_000, // 5 min
      enabled: Number(currentPartnerId) > 0,
    }
  );
  const [activeTouchDownDetails, setActiveTouchDownDetails] =
    useState<boolean>(false);
  const [activeTouchDownIndex, setActiveTouchDownIndex] = useState<number>(0);
  const activeTouchdowns = currentWeekTouchdownData?.getF2PActiveTouchdowns;

  return (
    <SignedInLayout title="Partner - Touchdown" showF2pPartners>
      {activeTouchDownDetails && activeTouchdowns ? (
        <ActiveTouchdownDetails
          title={`${activeTouchdowns[activeTouchDownIndex]?.name} (${formatDate(
            activeTouchdowns[activeTouchDownIndex]?.startDateTime
          )} to
            ${formatDate(
              activeTouchdowns[activeTouchDownIndex]?.endDateTime
            )})`}
          prizePoolsData={activeTouchdowns?.[activeTouchDownIndex]?.prizePools}
          activeTouchDownId={activeTouchdowns[activeTouchDownIndex]?.id}
          setActiveTouchDownDetails={setActiveTouchDownDetails}
        />
      ) : (
        <TouchdownDetails
          currentWeekRange={weekRange}
          partnerId={currentPartnerId}
          role={authInfo?.role}
          currentWeekTouchdownData={currentWeekTouchdownData}
          setActiveTouchDownIndex={setActiveTouchDownIndex}
          setActiveTouchDownDetails={setActiveTouchDownDetails}
        />
      )}
    </SignedInLayout>
  );
}
