import { useMemo, useState } from "react";

import {
  DataGrid,
  GridColumns,
  GridRowParams,
  GridComparatorFn,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import { SignedInWithSidebarLayout } from "@src/components/templates/Layouts";
import {
  useGetUserSignUpsQuery,
  useDeleteUserPermanentlyMutation,
  AuditActionFliterType,
} from "@src/graphql/graphql.generated";
import { renderCurrency } from "@src/helpers/currency";
import { Button, Stack } from "@mui/material";
import { AuditLogsModal } from "@src/components/templates/AuditLogsModal";
import { useQueryClient } from "react-query";

function Controls() {
  const [showAuditTrail, setShowAuditTrail] = useState(false);

  const handleCloseAudit = () => setShowAuditTrail(false);
  return (
    <Stack spacing={1}>
      <Button
        sx={{ mb: 4 }}
        fullWidth
        onClick={() => setShowAuditTrail(true)}
        variant="outlined"
      >
        Audit Trail
      </Button>
      <AuditLogsModal
        title="Delete User Audit"
        isOpen={showAuditTrail}
        onClose={handleCloseAudit}
        actions={[AuditActionFliterType.DeleteUserPermanently]}
      />
    </Stack>
  );
}

export function DeletionRequests() {
  const { data: users, isLoading } = useGetUserSignUpsQuery(undefined, {
    staleTime: 300_000, // 5 min
  });
  const { mutate: deleteUserPermanently } = useDeleteUserPermanentlyMutation();
  const queryClient = useQueryClient();

  const willBeDeletedUsers = useMemo(() => {
    return users?.getUserSignUps.filter(
      (user) => user.isDeactivated && !user.isDeactivatedPermanently
    );
  }, [users]);

  const columns = useMemo<GridColumns>(
    () => [
      { field: "fullName", headerName: "Full Name", width: 200 },
      { field: "email", headerName: "Email", width: 200 },
      { field: "signUpState", headerName: "Sign Up State", width: 120 },
      { field: "promo", headerName: "Promo", width: 120 },
      { field: "signUpState", headerName: "SignUp State", width: 120 },
      {
        field: "createdAt",
        headerName: "Creation Date",
        width: 200,
        valueFormatter: (params) =>
          dayjs(params.value as string).format("MMM D, YYYY, H:ma"),
        sortComparator: dateComparator,
      },
      {
        field: "dateOfBirth",
        headerName: "Date of Birth",
        width: 120,
        valueFormatter: (params) =>
          dayjs(params.value as string).format("MMM D, YYYY"),
        sortComparator: dateComparator,
      },
      { field: "id", headerName: "id", width: 15 },
      { field: "phone", headerName: "phone", width: 120 },
      {
        field: "walletAmount",
        headerName: "Wallet Balance",
        width: 120,
        valueFormatter: (params) =>
          renderCurrency((params.value as number) / 100),
      },
      {
        field: "actions",
        type: "actions",
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem
            icon={<CheckIcon />}
            onClick={async () => {
              deleteUserPermanently(
                { shouldDelete: true, userId: +params.id },
                {
                  onSuccess: () =>
                    queryClient.invalidateQueries("GetUserSignUps"),
                }
              );
            }}
            label="Approve"
          />,
          <GridActionsCellItem
            icon={<CloseIcon />}
            onClick={() =>
              deleteUserPermanently(
                { shouldDelete: false, userId: +params.id },
                {
                  onSuccess: () =>
                    queryClient.invalidateQueries("GetUserSignUps"),
                }
              )
            }
            label="Reject"
          />,
        ],
      },
    ],
    []
  );

  return (
    <SignedInWithSidebarLayout
      SidebarComponent={<Controls />}
      title="Deletion Requests"
    >
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <DataGrid
          columns={columns}
          initialState={{
            sorting: {
              sortModel: [{ field: "startDate", sort: "asc" }],
            },
          }}
          rows={willBeDeletedUsers ?? []}
        />
      )}
    </SignedInWithSidebarLayout>
  );
}

const dateComparator: GridComparatorFn = (isoDate1, isoDate2): 1 | -1 => {
  return dayjs(isoDate1 as string).isBefore(dayjs(isoDate2 as string)) ? 1 : -1;
};
