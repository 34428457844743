import { Checkbox, CheckboxProps, ListItemText } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import React from "react";

interface OptionType {
  prizePoolId: number;
  touchdown: {
    touchdownId: number;
    touchdownName: string;
  };
}

const StyledLabel = styled("label")(({ theme }) => ({
  color: "#212121",
  fontSize: "14px",
  lineHeight: "20px",
}));

const useStyles = makeStyles((theme) => ({
  required: {
    "&:after": {
      content: '" *"',
      color: "red",
    },
  },
}));

interface MultiSelectAutocompleteProps {
  options: OptionType[];
  selectedOptions: OptionType[];
  onChange: (selected: OptionType[]) => void;
  label: string;
  placeholder?: string;
}
const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    paddingTop: "2px",
    paddingBottom: "2px",
    backgroundColor: "white",
    borderRadius: "4px",
  },
  "& .MuiInputBase-input": {
    fontWeight: 500,
    color: "#3d3d3d",
  },
  "& .MuiSvgIcon-root": {
    color: "#555555",
  },
  "& .MuiInputBase-input::placeholder": {
    fontSize: "16px",
    color: "black",
  },
}));
const StyledCheckbox = styled((props: CheckboxProps) => (
  <Checkbox color="default" {...props} />
))(({ theme }) => ({
  color: theme.palette.primary.main,
  "&.Mui-checked": {
    color: theme.palette.primary.main,
  },
}));
const MultiSelectAutocomplete: React.FC<MultiSelectAutocompleteProps> = ({
  options,
  selectedOptions,
  onChange,
  label,
  placeholder,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <div>
      <StyledLabel className={classes.required} htmlFor="CONTEST TITLE">
        {label}
      </StyledLabel>
      <Autocomplete
        {...rest}
        multiple
        id="multi-select-autocomplete"
        className="autocomplete-touchdown"
        options={options}
        getOptionLabel={(option) => option?.touchdown?.touchdownName}
        value={selectedOptions}
        onChange={(event, newValue) => onChange(newValue)}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              backgroundColor: selected ? "#fae3c8" : "white",
            }}
          >
            <StyledCheckbox checked={selected} style={{ marginRight: 8 }} />
            <ListItemText primary={option?.touchdown?.touchdownName} />
          </li>
        )}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            variant="outlined"
            placeholder={placeholder || "select..."}
            fullWidth
          />
        )}
      />
    </div>
  );
};

export default MultiSelectAutocomplete;
