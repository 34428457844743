import { FC, useState, useEffect } from "react";
import { List, ListItem, ListItemText, Button } from "@mui/material";
import {
  CRONS,
  GET_CURRENT_TOURNAMENT_PLAYERS,
  GET_TEE_TIME_BY_ROUND,
  GET_TEE_TIME_FOR_CURRENT_TOURNAMENT,
  SYNC_TOURNAMENTS,
  SYNC_TOURNAMENT_PLAYERS,
  GRADE_TOURNAMENT,
  VOID_CONTESTS,
  SEND_CONTEST_GRADED_EMAIL,
  SyncTournaments,
  SyncTournamentPlayers,
  GetCurrentTournamentPlayers,
  GetTeeTimeForCurrentTournament,
  GetTeeTimeByRound,
  GradeTournament,
  VoidContests,
  SendContestGradedEmail,
} from "./crons";

export const CronJobList: FC = () => {
  const [modalTarget, setModalTarget] = useState("");
  const [disabledButtons, setDisabledButtons] = useState<Array<string>>([]);
  const disabledButtonTimeouts: Array<NodeJS.Timeout> = [];

  useEffect(() => {
    return () => {
      disabledButtonTimeouts.forEach((buttonTimeout) =>
        clearTimeout(buttonTimeout)
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpen = (target: string) => {
    setModalTarget(target);
  };

  const handleOnSubmit = (target: string) => {
    setDisabledButtons([...disabledButtons, target]);
    disabledButtonTimeouts.push(
      setTimeout(() => {
        setDisabledButtons((currentDisabledButtons) =>
          currentDisabledButtons.filter((button) => button !== target)
        );
      }, 30 * 1000)
    );
  };

  const handleClose = () => {
    setModalTarget("");
  };

  return (
    <>
      <List sx={{ width: "100%", maxWidth: 400 }}>
        {CRONS.map((cron) => (
          <ListItem
            key={cron.id}
            disableGutters
            secondaryAction={
              <Button
                variant="contained"
                onClick={() => handleOpen(cron.id)}
                disabled={disabledButtons.includes(cron.id)}
              >
                Run Now
              </Button>
            }
          >
            <ListItemText primary={cron.label} />
          </ListItem>
        ))}
      </List>
      <SyncTournaments
        isOpen={modalTarget === SYNC_TOURNAMENTS}
        onSubmit={handleOnSubmit}
        onClose={handleClose}
      />
      <SyncTournamentPlayers
        isOpen={modalTarget === SYNC_TOURNAMENT_PLAYERS}
        onSubmit={handleOnSubmit}
        onClose={handleClose}
      />
      <GetCurrentTournamentPlayers
        isOpen={modalTarget === GET_CURRENT_TOURNAMENT_PLAYERS}
        onSubmit={handleOnSubmit}
        onClose={handleClose}
      />
      <GetTeeTimeForCurrentTournament
        isOpen={modalTarget === GET_TEE_TIME_FOR_CURRENT_TOURNAMENT}
        onSubmit={handleOnSubmit}
        onClose={handleClose}
      />
      <GetTeeTimeByRound
        isOpen={modalTarget === GET_TEE_TIME_BY_ROUND}
        onSubmit={handleOnSubmit}
        onClose={handleClose}
      />
      <GradeTournament
        isOpen={modalTarget === GRADE_TOURNAMENT}
        onSubmit={handleOnSubmit}
        onClose={handleClose}
      />
      <VoidContests
        isOpen={modalTarget === VOID_CONTESTS}
        onSubmit={handleOnSubmit}
        onClose={handleClose}
      />
      <SendContestGradedEmail
        isOpen={modalTarget === SEND_CONTEST_GRADED_EMAIL}
        onSubmit={handleOnSubmit}
        onClose={handleClose}
      />
    </>
  );
};
