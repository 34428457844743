import { useEffect, useMemo, useState } from "react";
import { useSnackbar } from "notistack";
import { SignedInWithSidebarLayout } from "@src/components/templates/Layouts";
import {
  AuditActionFliterType,
  useFeatureFlagsQuery,
  useUpdateFeatureFlagsMutation,
} from "@src/graphql/graphql.generated";
import {
  Button,
  Stack,
  Checkbox,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { parseGQLError } from "@src/utils/parse-error";
import { AuditLogsModal } from "@src/components/templates/AuditLogsModal";

export function FeatureFlags() {
  const { enqueueSnackbar } = useSnackbar();
  const { data: featureFlags, isSuccess } = useFeatureFlagsQuery();
  const { mutate: update } = useUpdateFeatureFlagsMutation();
  const [flagState, setFlagState] = useState<{
    [key: string]: boolean;
  }>({});
  const [showAuditTrail, setShowAuditTrail] = useState(false);

  const isDirty = useMemo(
    () =>
      (featureFlags &&
        JSON.stringify(featureFlags.featureFlags) !==
          JSON.stringify(flagState)) ??
      false,
    [featureFlags, flagState]
  );

  const handleToggle = (key: string) => () => {
    setFlagState({
      ...flagState,
      [key]: !flagState[key],
    });
  };

  const handleCloseAudit = () => {
    setShowAuditTrail(false);
  };

  const handleSave = () => {
    update(
      {
        data: flagState,
      },
      {
        onError: (err: any) => {
          enqueueSnackbar(parseGQLError(err, "Unable to save"), {
            variant: "error",
          });
        },
        onSuccess: () => {
          enqueueSnackbar("Saved", {
            variant: "success",
          });
        },
      }
    );
  };

  useEffect(() => {
    setFlagState({ ...(featureFlags?.featureFlags ?? {}) });
  }, [isSuccess]);

  return (
    <SignedInWithSidebarLayout
      title="PGA Feature Flags"
      SidebarComponent={
        <Controls
          isDirty={isDirty}
          onSave={handleSave}
          setShowAuditTrail={setShowAuditTrail}
        />
      }
    >
      <List sx={{ width: "100%", maxWidth: 500, bgcolor: "background.paper" }}>
        {featureFlags ? (
          Object.entries(featureFlags.featureFlags).map(([key, val]) => (
            <ListItemButton key={key} onClick={handleToggle(key)}>
              <ListItemIcon sx={{ alignItems: "center" }}>
                <Checkbox
                  edge="start"
                  checked={Boolean(flagState[key])}
                  tabIndex={-1}
                  disableRipple
                />
                <ListItemText primary={key} />
              </ListItemIcon>
            </ListItemButton>
          ))
        ) : (
          <div>Loading</div>
        )}
      </List>
      <AuditLogsModal
        title="Feature Flags Audit"
        isOpen={showAuditTrail}
        onClose={handleCloseAudit}
        actions={[AuditActionFliterType.UpdateFeatureFlags]}
      />
    </SignedInWithSidebarLayout>
  );
}

interface ControlsProps {
  isDirty: boolean;
  onSave: () => void;
  setShowAuditTrail: (value: boolean) => void;
}

function Controls({ isDirty, onSave, setShowAuditTrail }: ControlsProps) {
  return (
    <Stack spacing={1}>
      <Button disabled={!isDirty} onClick={onSave} variant="contained">
        Save
      </Button>
      <Button onClick={() => setShowAuditTrail(true)} variant="outlined">
        Audit Trail
      </Button>
    </Stack>
  );
}
