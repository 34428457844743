import { useMemo, useState } from "react";
import {
  DataGrid,
  GridColumns,
  GridComparatorFn,
  GridFilterModel,
} from "@mui/x-data-grid";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ButtonBase } from "@mui/material";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import {
  ContestsReportFilterType,
  useGetAllContestsQuery,
  useVoidContestMutation,
} from "@src/graphql/graphql.generated";
import { c2d, renderCurrency } from "@src/helpers/currency";
import { dateRangeOperators } from "@organisms/DataGridFilters/DateRange/dateRangeOperators";
import { useHasPermissions } from "@src/hooks/useHasPermission";
import { PermissionId } from "@src/constants/permissions";
import { parseGQLError } from "@src/utils/parse-error";

export function ActiveContests() {
  const [isFilterDate, setIsFilterDate] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const showCancelContest = useHasPermissions(PermissionId.GolfContestVoidCancel);
  const { mutate: voidContest } = useVoidContestMutation();
  
  const { data: contests, isLoading, refetch: refetchContests } = useGetAllContestsQuery(
    { data: { filter: ContestsReportFilterType.Active } },
    {
      staleTime: 300_000, // 5 min
    }
  );

  const onFilterChange = (model: GridFilterModel) => {
    const { columnField } = model.items[0];
    if (columnField === "createdAt") {
      setIsFilterDate(true);
      return;
    }
    setIsFilterDate(false);
  };

  const handleVoid = (id: number) => {
    voidContest(
      { contestId: id },
      {
        onSuccess: () => {
          enqueueSnackbar("Contest has been cancelled successfully!", {
            variant: "success",
          });
          refetchContests();
        },
        onError: (error: any) => {
          enqueueSnackbar(parseGQLError(error, "Unable to cancel"), {
            variant: "error"
          });
        },
      }
    )
  }

  const columns = useMemo<GridColumns>(
    () => {
      const cols: GridColumns = [
        { field: "id", headerName: "Contest ID", width: 80 },
        { field: "contestType", headerName: "Type", width: 130 },
        {
          field: "createdAt",
          headerName: "Creation Date",
          width: 200,
          type: "date",
          valueFormatter: (params) =>
            dayjs(params.value as string).format("MMM D, YYYY, H:ma"),
          sortComparator: dateComparator,
          filterOperators: dateRangeOperators,
        },
        { field: "creatorName", headerName: "Creator Name", width: 200 },
        { field: "creatorEmail", headerName: "Creator Email", width: 200 },
        { field: "claimerName", headerName: "Claimer Name", width: 200 },
        { field: "claimerEmail", headerName: "Claimer Email", width: 200 },
        { field: "creatorPlayer", headerName: "Creator Player", width: 200 },
        { field: "claimerPlayer", headerName: "Claimer Player", width: 200 },
        { field: "entryAmount", headerName: "Entry", width: 120 },
      ]
      showCancelContest && cols.push({
        field: "void", headerName: "Void", width: 120, renderCell: (params) => {
          return (
            <>
              {showCancelContest && (
                <ButtonBase onClick={() => handleVoid(+params.id)}>
                  <DeleteOutlineIcon />
                </ButtonBase>
              )}
            </>
          );
        }
      })

      return cols;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showCancelContest]
  );

  const rows = useMemo(
    () =>
      contests?.getAllContests.map((contest) => ({
        ...contest,
        creatorName: contest.creatorUser?.fullName,
        creatorEmail: contest.creatorUser?.email,
        claimerName: contest.claimerUser?.fullName,
        claimerEmail: contest.claimerUser?.email,
        creatorPlayer: `${contest.creatorPlayer.firstName} ${contest.creatorPlayer.lastName}`,
        claimerPlayer: `${contest.claimerPlayer.firstName} ${contest.claimerPlayer.lastName}`,
        entryAmount: renderCurrency(c2d(contest.entryAmount)),
        void: ''
      })) ?? [],
    [contests]
  );

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <DataGrid
          columns={columns}
          initialState={{
            sorting: {
              sortModel: [{ field: "startDate", sort: "asc" }],
            },
          }}
          rows={rows}
          sx={{
            minHeight: "75vh",
          }}
          componentsProps={{
            panel: {
              sx: {
                "& .MuiDataGrid-filterForm": {
                  width: isFilterDate ? 600 : 500,
                },
              },
            },
          }}
          onFilterModelChange={onFilterChange}
        />
      )}
    </>
  );
}

const dateComparator: GridComparatorFn = (isoDate1, isoDate2): 1 | -1 => {
  return dayjs(isoDate1 as string).isBefore(dayjs(isoDate2 as string)) ? 1 : -1;
};
