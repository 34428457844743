import { PermissionId } from "@src/constants/permissions";
import {
  Box,
  FormControlLabel,
  Checkbox,
  Typography,
  FormGroup,
} from "@mui/material";

interface CheckboxOption {
  key: string | number;
  label: string;
}

interface AsyncCheckboxGroupInputProps {
  selected: Array<string | number | PermissionId>;
  header: string;
  isLoading: boolean;
  options: Array<CheckboxOption>;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function AsyncCheckboxGroupInput({
  selected,
  header,
  isLoading,
  options,
  handleChange,
}: AsyncCheckboxGroupInputProps) {
  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box>
      <Typography>
        {header} ({selected.length})
      </Typography>
      <FormGroup row sx={{ maxHeight: 120, overflowY: "auto" }}>
        {options.map((option: CheckboxOption) => (
          <FormControlLabel
            key={option.key}
            control={
              <Checkbox
                name={option.key.toString()}
                checked={selected.includes(option.key)}
                onChange={handleChange}
              />
            }
            label={option.label}
          />
        ))}
      </FormGroup>
    </Box>
  );
}
