export enum QueryKeys {
  SignIn = "SignIn",
  Auth = "Auth",
  Permissions = "Permissions",
  GetNflMatchups = "GetNflMatchups",
  GetNflTouchdownContests = "GetNflTouchdownContests",
  GetNbaTouchdownContests = "GetNbaTouchdownContests",
  GetFeaturedContests = "GetFeaturedContests",
  GetSuggestion = "GetSuggestion",
  GetTouchdownByDate = "GetTouchdownByDate",
  FetchHubContests = "FetchHubContests",
  GetSports = "GetSports",
  GetSport = "GetSport",
  CountHubContests = "CountHubContests",
  FetchContests = "FetchContests",
  GetSoccerTouchdownContests = "GetSoccerTouchdownContests",
  GetPartnerSoccerTouchdownContests = "GetPartnerSoccerTouchdownContests",
  GetPartnerSports = "GetPartnerSports",
  FetchPartnerTouchdown = "FetchPartnerTouchdown",
  FetchPartnerHubContests = "FetchPartnerHubContests",
  FetchPartnerContests = "FetchPartnerContests",
  CountPartnerContests = "CountPartnerContests",
  GetPartnerNbaTouchdownContests = "GetPartnerNbaTouchdownContests",
  FetchPartnerTouchdownByDate = "FetchPartnerTouchdownByDate",
  GetPartnerTouchdownAnalytics = "GetPartnerTouchdownAnalytics",
  GetPartnerActiveTouchdowns = "GetPartnerActiveTouchdowns",
  GetF2PSports = "GetF2PSports",
  FetchF2PTouchdown = "FetchF2PTouchdown",
  GetF2PNbaTouchdownContestPlayers = "GetF2PNbaTouchdownContestPlayers",
  CreateF2PNbaTouchdownContest = "CreateF2PNbaTouchdownContest",
  GetF2PNbaTouchdownContests = "GetF2PNbaTouchdownContests",
  DeleteF2PNbaTouchdownContest = "DeleteF2PNbaTouchdownContest",
  UpdateF2PNbaContest = "UpdateF2PNbaContest ",
  GetF2PSoccerTouchdownContestPlayers = "GetF2PSoccerTouchdownContestPlayers",
  CreateF2PSoccerTouchdownContest = "CreateF2PSoccerTouchdownContest",
  GetF2PSoccerTouchdownContests = "GetF2PSoccerTouchdownContests",
  DeleteF2PSoccerTouchdownContest = "DeleteF2PSoccerTouchdownContest",
  UpdateF2PSoccerTouchdownContest = "UpdateF2PSoccerTouchdownContest",
  OrderF2PContests = "OrderF2PContests",
  CreateF2PHubContest = "CreateF2PHubContest",
  FetchF2PHubContests = "FetchF2PHubContests",
  UpdateF2PHubContest = "UpdateF2PHubContest",
  CountF2PHubContests = "CountF2PHubContests",
  DeleteF2PHubContest = "DeleteF2PHubContest",
  PublishF2PHubContest = "PublishF2PHubContest",
  GradeF2PHubContest = "GradeF2PHubContest",
  FetchF2PContests = "FetchF2PContests",
  CountF2PContests = "CountF2PContests",
  GetF2PActiveTouchdowns = "GetF2PActiveTouchdowns",
  GetCommonSport = "GetCommonSport",
  GetCommonHubContest = "GetCommonHubContest",
  GetCommonNbaTouchdownContests = "GetCommonNbaTouchdownContests",
  GetCommonSoccerTouchdownContests = "GetCommonSoccerTouchdownContests",
  GetPartnerCommonContests = "GetPartnerCommonContests",
  PublishPartnerCommonContest = "PublishPartnerCommonContest",
  CountCommonContests = "CountCommonContests",
}
