import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { theme } from "@src/constants/theme";
import {
  useCreateOrUpdateMathConstantMutation,
  useGetMathConstantQuery,
} from "@src/graphql/graphql.generated";
import { NumberValidationSchema, parseGQLError } from "@src/utils/parse-error";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

const StyledInput = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(1),
  "& input": {
    padding: "9px 12px 9px 12px",

    "&:focus": {
      outline: `none`,
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: `#E2D3BB !important`,
    "&:focus": {
      outline: `none`,
    },
  },
  "& .MuiOutlinedInput-root.Mui-error": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `#d32f2f !important`,
    },
  },
  "& .MuiFormHelperText-root.Mui-error": {
    fontSize: "12px",
    position: "absolute",
    width: "300px",
    top: "100%",
    margin: "5px 0",
  },
}));

const StyledLabel = styled("label")(({ theme }) => ({
  color: "#212121",
  fontSize: "14px",
}));

interface IFormValues {
  PRIZE_POOL: number;
  GPP: number;
  WEEKLY_RESERVE: number;
  entryFee: number;
  totalEntrants: number;
  prizePool: number;
  jackpot: number;
  gpp: number;
  weeklyReserve: number;
  contestVig: number;
  [key: string]: number;
}

export function TouchdownMath() {
  const { enqueueSnackbar } = useSnackbar();
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<IFormValues>({
    defaultValues: {
      entryFee: 0,
      totalEntrants: 0,
      prizePool: 0,
      jackpot: 0,
      gpp: 0,
      weeklyReserve: 0,
      contestVig: 0,
    },
    mode: "all",
  });

  const entryFee = watch("entryFee");
  const totalEntrants = watch("totalEntrants");

  const {
    data: mathConstants,
    isLoading,
    refetch: refetchConstants,
  } = useGetMathConstantQuery();
  const { mutate: mathConstantMutate } =
    useCreateOrUpdateMathConstantMutation();

  useEffect(() => {
    if (mathConstants?.getMathConstant?.length) {
      mathConstants.getMathConstant.forEach(({ name, value }) => {
        setValue(name, value);
      });
    }
  }, [mathConstants?.getMathConstant]);

  const calculateValues = () => {
    const { PRIZE_POOL, GPP, WEEKLY_RESERVE } = getValues();
    const totalPrizePool = totalEntrants * entryFee;

    const prizePoolAmount = parseFloat(
      (totalPrizePool * PRIZE_POOL).toFixed(2)
    );
    const gppAmount = parseFloat((GPP * totalPrizePool).toFixed(2)) / 100;
    const weeklyReserveAmount =
      parseFloat((WEEKLY_RESERVE * totalPrizePool).toFixed(2)) / 100;
    const jackpotAmount = parseFloat(
      (prizePoolAmount - weeklyReserveAmount - gppAmount).toFixed(2)
    );
    const contestVig = parseFloat(
      (totalPrizePool - prizePoolAmount).toFixed(2)
    );

    setValue("prizePool", prizePoolAmount);
    setValue("jackpot", jackpotAmount);
    setValue("gpp", gppAmount);
    setValue("weeklyReserve", weeklyReserveAmount);
    setValue("contestVig", contestVig);
  };

  useEffect(() => {
    if (entryFee && totalEntrants) calculateValues();
  }, [entryFee, totalEntrants]);

  const onSubmit = (data: any) => {
    const constantsList = mathConstants?.getMathConstant;

    if (constantsList) {
      constantsList.forEach((constant) => {
        // + for convert string to number
        constant.value = +data[constant.name];
      });

      mathConstantMutate(
        { mathConstant: constantsList },
        {
          onSuccess: () => {
            enqueueSnackbar("Math constants has been added successfully!", {
              variant: "success",
            });
            refetchConstants().then(() => {
              calculateValues();
            });
          },
          onError: (error: any) => {
            enqueueSnackbar(
              parseGQLError(error, "Unable to create Math constants."),
              {
                variant: "error",
              }
            );
          },
        }
      );
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box marginBottom={theme.spacing(4)}>
          <Grid container spacing={1}>
            <Grid item xs={2} display="flex" alignItems="center">
              <Typography variant="h6" fontWeight={500}>
                Prize Pool :
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Stack direction="row" alignItems="center">
                <Typography
                  variant={"h6"}
                  fontWeight={400}
                  sx={{ fontSize: "18px" }}
                >
                  (Entrants X Entry Fee)
                </Typography>
                <Typography
                  variant={"h6"}
                  margin={`0 ${theme.spacing(2)} 0 ${theme.spacing(2)}`}
                >
                  X
                </Typography>
                <FormControl variant="standard">
                  <StyledInput
                    id="prizePool"
                    required
                    InputProps={{
                      "aria-label": "prize-pool",
                    }}
                    sx={{ width: "100px" }}
                    {...register("PRIZE_POOL", NumberValidationSchema)}
                    error={!!errors?.PRIZE_POOL}
                    helperText={errors?.PRIZE_POOL?.message}
                  />
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box marginBottom={theme.spacing(4)}>
          <Grid container spacing={1}>
            <Grid item xs={2} display="flex" alignItems="center">
              <Typography variant="h6" fontWeight={500}>
                GPP :
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Stack direction="row" alignItems="center">
                <FormControl variant="standard">
                  <StyledInput
                    id="gpp"
                    sx={{ width: "100px" }}
                    {...register("GPP", NumberValidationSchema)}
                    error={!!errors?.GPP}
                    helperText={errors?.GPP?.message}
                  />
                </FormControl>
                <Typography
                  variant={"h6"}
                  margin={`0 ${theme.spacing(2)} 0 ${theme.spacing(2)}`}
                >
                  %
                </Typography>

                <Typography
                  variant={"h6"}
                  fontWeight={400}
                  sx={{ fontSize: "18px" }}
                >
                  Prize Pool
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box marginBottom={theme.spacing(4)}>
          <Grid container spacing={1}>
            <Grid item xs={2} display="flex" alignItems="center">
              <Typography variant="h6" fontWeight={500}>
                Weekly Reserve :
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Stack direction="row" alignItems="center">
                <FormControl variant="standard">
                  <StyledInput
                    id="weekly_reserve"
                    sx={{ width: "100px" }}
                    {...register("WEEKLY_RESERVE", NumberValidationSchema)}
                    error={!!errors?.WEEKLY_RESERVE}
                    helperText={errors?.WEEKLY_RESERVE?.message}
                  />
                </FormControl>
                <Typography
                  variant={"h6"}
                  margin={`0 ${theme.spacing(2)} 0 ${theme.spacing(2)}`}
                >
                  %
                </Typography>

                <Typography
                  variant={"h6"}
                  fontWeight={400}
                  sx={{ fontSize: "18px" }}
                >
                  Prize Pool
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box marginBottom={theme.spacing(4)}>
          <Grid container spacing={1}>
            <Grid item xs={2} display="flex" alignItems="center">
              <Typography variant="h6" fontWeight={500}>
                Jackpot :
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Stack direction="row" alignItems="center">
                <Typography
                  variant={"h6"}
                  fontWeight={400}
                  sx={{ fontSize: "18px" }}
                >
                  Prize Pool
                </Typography>
                <Typography
                  variant={"h6"}
                  margin={`0 ${theme.spacing(2)} 0 ${theme.spacing(2)}`}
                >
                  -
                </Typography>
                <Typography
                  variant={"h6"}
                  fontWeight={400}
                  sx={{ fontSize: "18px" }}
                >
                  Weekly Reserve
                </Typography>
                <Typography
                  variant={"h6"}
                  margin={`0 ${theme.spacing(2)} 0 ${theme.spacing(2)}`}
                >
                  -
                </Typography>
                <Typography
                  variant={"h6"}
                  fontWeight={400}
                  sx={{ fontSize: "18px" }}
                >
                  GPP
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box marginBottom={theme.spacing(4)}>
          <Grid container spacing={1}>
            <Grid item xs={2} display="flex" alignItems="center">
              <Typography variant="h6" fontWeight={500}>
                Contest VIG :
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Stack direction="row" alignItems="center">
                <Typography
                  variant={"h6"}
                  fontWeight={400}
                  sx={{ fontSize: "18px" }}
                >
                  ( Entrants
                </Typography>
                <Typography
                  variant={"h6"}
                  margin={`0 ${theme.spacing(2)} 0 ${theme.spacing(2)}`}
                >
                  X
                </Typography>
                <Typography
                  variant={"h6"}
                  fontWeight={400}
                  sx={{ fontSize: "18px" }}
                >
                  Entry Fee )
                </Typography>
                <Typography
                  variant={"h6"}
                  margin={`0 ${theme.spacing(2)} 0 ${theme.spacing(2)}`}
                >
                  -
                </Typography>
                <Typography
                  variant={"h6"}
                  fontWeight={400}
                  sx={{ fontSize: "18px" }}
                >
                  Prize Pool
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container>
            <Grid item xs={12}>
              <Button
                disabled={isLoading}
                variant="contained"
                size="large"
                sx={{ width: "140px" }}
                type="submit"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>

      {/* Touchdown Calculator  */}
      <Box marginTop={theme.spacing(5)}>
        <Typography
          variant="h5"
          fontWeight={500}
          marginBottom={theme.spacing(3)}
        >
          Touchdown Calculator
        </Typography>
        <Box
          sx={{
            border: "1px solid #EEE8DC",
            backgroundColor: "#FFFEFB",
            padding: theme.spacing(2),
            marginTop: theme.spacing(1),
            height: "100%",
          }}
        >
          <Grid container spacing={2} marginBottom={theme.spacing(3)}>
            <Grid item xs={3}>
              <FormControl variant="standard" fullWidth>
                <StyledLabel htmlFor="EntryFee">Entry Fee</StyledLabel>
                <StyledInput
                  id="EntryFee"
                  aria-describedby="entry-fee"
                  InputProps={{
                    "aria-label": "entry fee",
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  {...register("entryFee")}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl variant="standard" fullWidth>
                <StyledLabel htmlFor="Entrants">Entrants</StyledLabel>
                <StyledInput
                  id="Entrants"
                  aria-describedby="entrants"
                  InputProps={{
                    "aria-label": "entrants",
                  }}
                  placeholder="Enter Entrants"
                  {...register("totalEntrants")}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs>
              <FormControl variant="standard" fullWidth>
                <Stack direction="row" alignItems="center">
                  <StyledLabel htmlFor="PrizePool">Prize Pool</StyledLabel>
                  {/* <Tooltip
                    title="Formula to count prize pool: ( Entry fee * Entrants ) * 0.9 "
                    arrow
                  >
                    <IconButton>
                      <InfoOutlinedIcon fontSize="small" />
                    </IconButton>
                  </Tooltip> */}
                </Stack>
                <StyledInput
                  id="PrizePool"
                  disabled
                  type="number"
                  aria-describedby="prize pool"
                  InputProps={{
                    "aria-label": "prize pool",
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  {...register("prizePool")}
                />
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl variant="standard" fullWidth>
                <Stack direction="row" alignItems="center">
                  <StyledLabel htmlFor="jackpot">Jackpot</StyledLabel>
                  {/* <Tooltip
                    title="Formula to count prize pool: ( Entry fee * Entrants ) * 0.9 "
                    arrow
                  >
                    <IconButton>
                      <InfoOutlinedIcon fontSize="small" />
                    </IconButton>
                  </Tooltip> */}
                </Stack>
                <StyledInput
                  id="jackpot"
                  disabled
                  type="number"
                  aria-describedby="7 for 7"
                  InputProps={{
                    "aria-label": "7 for 7",
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  {...register("jackpot")}
                />
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl variant="standard" fullWidth>
                <Stack direction="row" alignItems="center">
                  <StyledLabel htmlFor="gpp">GPP</StyledLabel>
                  {/* <Tooltip
                    title="Formula to count prize pool: ( Entry fee * Entrants ) * 0.9 "
                    arrow
                  >
                    <IconButton>
                      <InfoOutlinedIcon fontSize="small" />
                    </IconButton>
                  </Tooltip> */}
                </Stack>
                <StyledInput
                  id="gpp"
                  disabled
                  type="number"
                  aria-describedby="gpp"
                  InputProps={{
                    "aria-label": "gpp",
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  {...register("gpp")}
                />
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl variant="standard" fullWidth>
                <Stack direction="row" alignItems="center">
                  <StyledLabel htmlFor="WeeklyReserve">
                    Weekly Reserve
                  </StyledLabel>
                  {/* <Tooltip
                    title="Formula to count prize pool: ( Entry fee * Entrants ) * 0.9 "
                    arrow
                  >
                    <IconButton>
                      <InfoOutlinedIcon fontSize="small" />
                    </IconButton>
                  </Tooltip> */}
                </Stack>
                <StyledInput
                  id="weeklyReserve"
                  disabled
                  type="number"
                  aria-describedby="weekly reserve"
                  InputProps={{
                    "aria-label": "weekly reserve",
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  {...register("weeklyReserve")}
                />
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl variant="standard" fullWidth>
                <Stack direction="row" alignItems="center">
                  <StyledLabel htmlFor="TopPropVig">Contest VIG</StyledLabel>
                  {/* <Tooltip
                    title="Formula to count prize pool: ( Entry fee * Entrants ) * 0.9 "
                    arrow
                  >
                    <IconButton>
                      <InfoOutlinedIcon fontSize="small" />
                    </IconButton>
                  </Tooltip> */}
                </Stack>
                <StyledInput
                  id="contestVig"
                  disabled
                  type="number"
                  aria-describedby="contest vig"
                  InputProps={{
                    "aria-label": "contest vig",
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  {...register("contestVig")}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
