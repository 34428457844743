import React from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import { makeStyles } from "@mui/styles";

const StyledLabel = styled("label")(({ theme }) => ({
  color: "#212121",
  fontSize: "14px",
}));
const StyledInput = styled(TextField)(({ theme: any }) => ({
  marginTop: "10px",
  "& input": {
    padding: "9px 12px 9px 12px",
    "&:focus": {
      outline: `none`,
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    "&:focus": {
      outline: `none`,
    },
  },
  "& .MuiOutlinedInput-root.Mui-error": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `#d32f2f !important`,
    },
  },
  "& .MuiFormHelperText-root.Mui-error": {
    fontSize: "12px",
    margin: "3px",
  },
}));

interface AddUserModalProps {
  open: boolean;
  handleClose: () => void;
  isLoadingPartnerCreation: boolean;
  handleSave: () => void;
  name: string;
  email: string;
  setName: (value: string) => void;
  setEmail: (value: string) => void;
  isLoading: boolean;
  editId: number;
  handleUpdate: () => void;
}

const useStyles = makeStyles(() => ({
  required: {
    "&:after": {
      content: '" *"',
      color: "red",
    },
  },
}));

const AddUserModal: React.FC<AddUserModalProps> = ({
  open,
  handleClose,
  isLoadingPartnerCreation,
  handleSave,
  name,
  email,
  setName,
  setEmail,
  isLoading,
  editId,
  handleUpdate,
}) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="add-user-modal-title"
      aria-describedby="add-user-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          borderRadius: 1,
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h6" component="h2">
            {editId ? "Edit Admin" : "Add Admin"}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <StyledLabel htmlFor="name" className={classes.required}>
              Name
            </StyledLabel>
            <StyledInput
              fullWidth
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled={isLoadingPartnerCreation}
            />
          </Grid>

          <Grid item xs={6}>
            <StyledLabel htmlFor="email" className={classes.required}>
              Email
            </StyledLabel>
            <StyledInput
              fullWidth
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={isLoadingPartnerCreation}
            />
          </Grid>
        </Grid>

        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={editId ? handleUpdate : handleSave}
            disabled={isLoadingPartnerCreation || !name || !email}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddUserModal;
