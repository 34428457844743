import { FC } from "react";
import { useSnackbar } from "notistack";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Stack,
  useTheme,
} from "@mui/material";
import { DialogTitle } from "@src/components/molecules/DialogTitle";
import { useSyncTournamentsMutation } from "@src/graphql/graphql.generated";
import { SOMETHING_WRONG_HAPPENED } from "@src/constants/strings";
import { ModalProps, SYNC_TOURNAMENTS } from "./crons";
import { GQLError, parseGQLError } from "@src/utils/parse-error";

export const SyncTournaments: FC<ModalProps> = ({
  isOpen,
  onSubmit,
  onClose,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: syncTournaments, isLoading } = useSyncTournamentsMutation();

  const handleSubmit = () => {
    syncTournaments(
      {},
      {
        onSuccess: (data) => {
          if (data.syncTournaments.success) {
            onSubmit(SYNC_TOURNAMENTS);
            onClose();
          } else {
            enqueueSnackbar(SOMETHING_WRONG_HAPPENED, {
              variant: "error",
            });
          }
        },
        onError: (err) => {
          enqueueSnackbar(parseGQLError(err as GQLError), {
            variant: "error",
          });
        },
      }
    );
  };

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
      onClose={onClose}
    >
      <DialogTitle onClose={onClose}>Sync Tournaments</DialogTitle>
      <DialogContent>
        <Stack
          alignItems="center"
          flex={1}
          justifyContent="center"
          p={2}
          spacing={1}
        >
          Are you sure that you want to sync tournaments?
        </Stack>
      </DialogContent>
      <DialogActions sx={{ bgcolor: theme.palette.grey[100], p: 2 }}>
        <Button
          className="text-white"
          color="secondary"
          disableElevation
          onClick={onClose}
          type="button"
          variant="outlined"
        >
          Close
        </Button>
        <Button
          className="text-white"
          color="primary"
          disableElevation
          onClick={handleSubmit}
          type="submit"
          variant="contained"
          disabled={isLoading}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
