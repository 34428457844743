import { FC, useState } from "react";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Stack,
  useTheme,
  FormControl,
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { DialogTitle } from "@src/components/molecules/DialogTitle";
import { useGradeTournamentMutation } from "@src/graphql/graphql.generated";
import { SOMETHING_WRONG_HAPPENED } from "@src/constants/strings";
import { GRADE_TOURNAMENT, ModalProps } from "./crons";

interface IFormValues {
  year: number;
  tournamentId?: string;
}

export const GradeTournament: FC<ModalProps> = ({
  isOpen,
  onSubmit,
  onClose,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: gradeTournament, isLoading } = useGradeTournamentMutation();
  const { register, handleSubmit } = useForm<IFormValues>();
  const [currentTournament, setCurrentTournament] = useState(false);

  const onFormSubmit = (data: IFormValues) => {
    gradeTournament(
      {
        year: !currentTournament ? Number(data.year) : null,
        tournamentId: !currentTournament ? data.tournamentId : null,
      },
      {
        onSuccess: (data) => {
          if (data.gradeTournament.success) {
            onSubmit(GRADE_TOURNAMENT);
            onClose();
          } else {
            enqueueSnackbar(SOMETHING_WRONG_HAPPENED, {
              variant: "default",
            });
          }
        },
        onError: () => {
          enqueueSnackbar(SOMETHING_WRONG_HAPPENED, {
            variant: "default",
          });
        },
      }
    );
  };

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <DialogTitle onClose={onClose}>Grade Tournament</DialogTitle>
        <DialogContent>
          <Stack
            alignItems="center"
            flex={1}
            justifyContent="center"
            p={2}
            spacing={1}
          >
            <Box component="main" sx={{ maxWidth: "400px" }}>
              <Stack spacing={2}>
                <FormControl>
                  <TextField
                    type="number"
                    label="Year"
                    required
                    disabled={currentTournament}
                    inputProps={{
                      sx: {
                        WebkitAppearance: "none",
                        MozAppearance: "textfield",
                      },
                    }}
                    {...register("year")}
                  />
                </FormControl>

                <FormControl>
                  <TextField
                    label="Tournament ID"
                    required
                    disabled={currentTournament}
                    {...register("tournamentId")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={currentTournament}
                        onChange={() =>
                          setCurrentTournament(!currentTournament)
                        }
                      />
                    }
                    label="Current Tournament"
                  />
                </FormControl>
              </Stack>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ bgcolor: theme.palette.grey[100], p: 2 }}>
          <Button
            className="text-white"
            color="secondary"
            disableElevation
            onClick={onClose}
            type="button"
            variant="outlined"
          >
            Close
          </Button>
          <Button
            className="text-white"
            color="primary"
            disableElevation
            type="submit"
            variant="contained"
            disabled={isLoading}
          >
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
