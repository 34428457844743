import { RangeInput } from "@mui/lab/DateRangePicker/RangeTypes";
import {
  Box,
  Button,
  FormControl,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import RangePicker from "@organisms/Pickers/RangePicker";
import TouchdownCreation from "@src/components/f2pPartner/Contest/TouchdownCreation";
import { SignedInLayout } from "@src/components/templates/Layouts";
import { WeeklyLeaderboard } from "@src/components/touchDown/Dashboard/WeeklyLeaderboard";
import { EST_TIME_ZONE } from "@src/constants/Touchdown";
import { theme } from "@src/constants/theme";
import { usePartner } from "@src/context/PartnerContext";
import { useFetchPartnerTouchdownQuery } from "@src/graphql/graphql.generated";
import { useAuth } from "@src/hooks/useAuth";
import { IWeeklyHistory } from "@src/types/touchdown/contest";
import { formatDate } from "@src/utils/utils";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: "1px solid #EEE8DC",
  "& .MuiDataGrid-cell": {
    borderBottom: "1px solid #EEE8DC",
  },
  "& .MuiDataGrid-columnHeaders": {
    borderBottom: "1px solid #EEE8DC",
  },
  "& .MuiDataGrid-footerContainer": {
    borderTop: "1px solid #EEE8DC",
  },
}));

export function ContestHistory() {
  const navigate = useNavigate();
  const { data: authInfo } = useAuth();
  const { partnerId: selectedPartnerId }: any = usePartner();
  const partnerId =
    authInfo?.role === "ADMIN" ? authInfo?.partnerId! : selectedPartnerId!;
  const [weekRange, setWeekRange] = useState<RangeInput<dayjs.Dayjs | null>>([
    dayjs().startOf("month"),
    dayjs().startOf("week"),
  ]);

  const [startDateTime, setStartDateTime] = useState<String | null>(null);
  const [viewDetails, setViewDetails] = useState<String | null>(null);
  const [endDateTime, setEndDateTime] = useState<String | null>(null);

  const [selectedTouchdown, setSelectedTouchdown] =
    useState<IWeeklyHistory | null>();

  useEffect(() => {
    if (weekRange?.[0] && weekRange?.[1]) {
      setStartDateTime(
        dayjs(weekRange?.[0])?.tz(EST_TIME_ZONE, true)?.utc()?.toISOString()
      );
      setEndDateTime(
        dayjs(weekRange?.[1])?.tz(EST_TIME_ZONE, true)?.utc()?.toISOString()
      );
    }
  }, [weekRange]);

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: "startDateTime",
        headerName: "Date",
        width: 250,
        renderCell: (params) => (
          <span>
            {formatDate(params.row.startDateTime)} to{" "}
            {formatDate(params.row.endDateTime)}
          </span>
        ),
      },
      {
        field: "name",
        headerName: "Touchdown name",
        width: 230,
      },
      { field: "allowedPick", headerName: "Picks", width: 230 },
      {
        field: "entryFees",
        headerName: "Entry Fee",
        minWidth: 130,
        renderCell: (params) => <span>${params.row.entryFees}</span>,
      },
      {
        field: "totalContestVig",
        headerName: "Daily Metrics",
        minWidth: 130,
        renderCell: (params) => (
          <Button
            variant="outlined"
            size="medium"
            sx={{ marginRight: theme.spacing(2) }}
            onClick={() => {
              setViewDetails(params.row?.id);
              // navigate(`/partner/touchdown/${params.row?.id}`, {
              //   state: {
              //     from: "history",
              //   },
              // })
            }}
          >
            View
          </Button>
        ),
      },
    ],
    []
  );

  const { data: weeklyHistory, refetch: reFetchHistory } =
    useFetchPartnerTouchdownQuery(
      {
        data: {
          duration: "history",
          limit: 10,
          page: 1,
          partnerId: partnerId!,
          startDateTime: startDateTime,
          endDateTime: endDateTime,
        },
      },
      {
        staleTime: 300_000, // 5 min
        enabled:
          partnerId !== undefined &&
          startDateTime !== null &&
          endDateTime !== null,
      }
    );

  return (
    <SignedInLayout title="Partner - Touchdown History" showF2pPartners>
      {selectedTouchdown ? (
        <WeeklyLeaderboard
          title={`Weekly Leaderboard (${selectedTouchdown.weekDate?.toLowerCase()})`}
          touchdownId={selectedTouchdown.touchdownId}
          renderBack
          parentComponent="contest-history"
          onBack={() => {
            setSelectedTouchdown(null);
          }}
        />
      ) : (
        <>
          {viewDetails ? (
            <TouchdownCreation
              partnerId={partnerId}
              isFromHistory
              touchdownId={viewDetails}
              setViewDetails={setViewDetails}
            />
          ) : (
            <>
              <Stack
                marginBottom={theme.spacing(3)}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h5" fontWeight={500}>
                  Weekly History
                </Typography>
                <FormControl variant="standard">
                  <RangePicker
                    key={"contestHistory"}
                    onChange={setWeekRange}
                    value={weekRange}
                  />
                </FormControl>
                {/* <FormControl variant="standard">
                  <WeekPicker
                    defaultValue={dayjs()}
                    handleDateChange={handleDateChange}
                  />
                </FormControl> */}
              </Stack>
              <Box style={{ height: "80vh", overflow: "auto" }}>
                <StyledDataGrid
                  columns={columns}
                  // initialState={{
                  //   sorting: {
                  //     sortModel: [{ field: "name", sort: "asc" }],
                  //   },
                  // }}
                  getRowId={(row) => row?.id}
                  rows={weeklyHistory?.fetchPartnerTouchdown?.data ?? []}
                  // pageSize={10}
                  // pagination
                />
              </Box>
            </>
          )}
        </>
      )}
    </SignedInLayout>
  );
}
