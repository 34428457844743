import { Card, Grid, Typography, useMediaQuery } from "@mui/material";
import { SignedInLayout } from "@src/components/templates/Layouts";
import { theme } from "@src/constants/theme";
import { useGetDailyReportQuery } from "@src/graphql/graphql.generated";
import { renderCurrency } from "@src/helpers/currency";

export function DailyMetrics() {
  const { data: dailyReportResponse, isLoading } = useGetDailyReportQuery(undefined, {
    staleTime: 300_000, // 5 min
  });
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  
  if (isLoading) return <SignedInLayout title="Daily Metrics"><div>Loading...</div></SignedInLayout>
  const getDailyReport = dailyReportResponse?.getDailyReport;

  return (
    <SignedInLayout title="Daily Metrics">
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          <Grid container justifyContent='space-between'>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
            > 
              <Card
                sx={{
                  display: "grid",
                  height: {
                    xs: "8rem",
                  },
                  width: '95%',
                  placeItems: "center",
                  padding: "0px 1rem 0.5rem 1rem",
                  marginBottom: '2rem',
                  borderRadius: "1rem",
                  cursor: "not-allowed",
                }}>
                  <Typography variant={isSmall ? "h5" : "h4"}>
                    New User Count: {getDailyReport ? getDailyReport.userCount : ''}
                  </Typography>
              </Card>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
            > 
              <Card
                sx={{
                  display: "grid",
                  width: '95%',
                  height: {
                    xs: "8rem",
                  },
                  placeItems: "center",
                  padding: "0px 1rem 0.5rem 1rem",
                  borderRadius: "1rem",
                  cursor: "not-allowed",
                }}>
                  <Typography variant={isSmall ? "h5" : "h4"}>
                    Today's Contest Count: {getDailyReport ? getDailyReport.battlegroundContestCount : ''}
                  </Typography>
              </Card>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
            > 
              <Card
                sx={{
                  display: "grid",
                  width: '95%',
                  height: {
                    xs: "8rem",
                  },
                  placeItems: "center",
                  padding: "0px 1rem 0.5rem 1rem",
                  borderRadius: "1rem",
                  cursor: "not-allowed",
                }}>
                  <Typography variant={isSmall ? "h5" : "h4"}>
                    Added Amount: {getDailyReport ? renderCurrency(getDailyReport.amountAddedSystemCount/100) : ''}
                  </Typography>
              </Card>
            </Grid>
          </Grid>
        </>
      )}
    </SignedInLayout>
  );
}
