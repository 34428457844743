import { useSnackbar } from "notistack";
import {
  useUserActivationMutation,
  User,
} from "@src/graphql/graphql.generated";
import { parseGQLError } from "@src/utils/parse-error";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Box,
  Typography,
} from "@mui/material";

interface ActivationDialogProps {
  isOpen: boolean;
  target: User | null;
  onClose: (refresh: boolean) => void;
}

export function ActivationDialog({
  isOpen,
  target,
  onClose,
}: ActivationDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: activation, isLoading } = useUserActivationMutation();

  const handleDelete = () => {
    activation(
      { userId: target?.id ?? 0 },
      {
        onSuccess: () => {
          enqueueSnackbar("User has been updated successfully!", {
            variant: "success",
          });
          onClose(true);
        },
        onError: (error: any) => {
          enqueueSnackbar(parseGQLError(error, "Unable to update"), {
            variant: "error",
          });
        },
      }
    );
  };

  if (!target) {
    return null;
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={onClose}>
      <DialogTitle>{target.isActive ? "Block" : "Unblock"} User</DialogTitle>
      <DialogContent>
        <Box component="main">
          <Stack spacing={2}>
            <Typography>
              Do you want to {target.isActive ? "block" : "unblock"} the
              selected user? <strong>({target.email})</strong>
            </Typography>
            <Button
              variant="contained"
              onClick={handleDelete}
              disabled={isLoading}
            >
              {target.isActive ? "Block" : "Unblock"}
            </Button>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
