import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { logOut } from "@src/services/auth";
import { clearAuthTokens } from "@src/services/authTokenStorage";
import { ROUTES } from "@src/Routes";
import { QueryKeys } from "@src/constants/QueryKeys";

export function useLogOut() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(
    async () => {
      await logOutAndClearAuthTokens();
      navigate(ROUTES.Login, { replace: true });
    },
    {
      onSuccess: () => {
        queryClient.clear();
        queryClient.invalidateQueries(QueryKeys.Permissions);
      },
    }
  );
}

async function logOutAndClearAuthTokens(): Promise<void> {
  try {
    await logOut();
  } catch (_ex) {}
  clearAuthTokens();
  await new Promise((resolve) => setTimeout(resolve, 500));
}
