import { GetPartnerTouchdownAnalyticsQuery } from "@src/graphql/graphql.generated";
import { Dispatch, SetStateAction } from "react";

export interface WeeklyCounts {
  totalActiveTouchdowns: number;
  totalContestVig: string;
  totalReceivedFees: string;
  totalTouchdownCount: number;
  totalUsersCount: number;
  upcomingTouchdownCount: number;
  [key: string]: number | string;
}

export const initTotals = {
  totalActiveTouchdowns: 0,
  totalContestVig: "0",
  totalReceivedFees: "0",
  totalTouchdownCount: 0,
  totalUsersCount: 0,
  upcomingTouchdownCount: 0,
};

export interface PartnerTouchDownProps {
  title: string;
  partnerTouchdownAnalytics: GetPartnerTouchdownAnalyticsQuery | undefined;
}

export interface PartnerTouchDownDetailProps {
  title: string;
  value: number | string;
  index: number;
}

interface activeTouchdownType {
  allowedPick: number;
  endDateTime: string;
  entryFees: number;
  id: number;
  name: string;
  prizePools: PricePoolsData[];
  startDateTime: string;
}

export interface ActiveTouchdowns {
  title: string;
  activeTouchdownData: any;
  setActiveTouchDownIndex: Dispatch<SetStateAction<number>>;
  setActiveTouchDownDetails: Dispatch<SetStateAction<boolean>>;
}

export interface PricePoolsData {
  allowedSports: number[];
  contestVig: number;
  createdAt: string;
  endDateTime: string;
  gppAmount: number;
  id: number;
  isRollsOver: boolean;
  jackpotAmount: number;
  lockTime: string;
  maxEntriesPerUser: number;
  predetermineJackpotAmount: number;
  rolloverAmount: number;
  rolloverJackpotAmount: number;
  rolloverVig: number;
  startDateTime: string;
  status: string;
  totalEntrants: number;
  totalPrize: number;
  updatedAt: string;
  weeklyReserveAmount: number;
}

export interface PartnerDailyLeaderboardProps {
  prizePoolId: number;
  setShowPartnerDailyLeaderBoard: Dispatch<SetStateAction<boolean>>;
}

export interface ActiveTouchdownsDetails {
  title: string;
  prizePoolsData?: PricePoolsData[];
  activeTouchDownId: number;
  setActiveTouchDownDetails: Dispatch<SetStateAction<boolean>>;
}

export const WEEKLY_COUNT_LABELS = [
  {
    key: "totalTouchdownCount",
    title: "Total Touchdown",
  },
  {
    key: "totalActiveTouchdowns",
    title: "Live Touchdown",
  },
  {
    key: "upcomingTouchdownCount",
    title: "Upcoming Touchdown",
  },
  {
    key: "totalReceivedFees",
    title: "Total Received Fees",
  },
  {
    key: "totalContestVig",
    title: "Total Partner vig",
  },
  {
    key: "totalUsersCount",
    title: "Total user",
  },
];
