import {
  DataGrid,
  GridColumns,
  GridComparatorFn,
  GridFilterModel,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import {
  ContestsReportFilterType,
  useGetNflContestsReportQuery,
} from "@src/graphql/graphql.generated";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { dateRangeOperators } from "@organisms/DataGridFilters/DateRange/dateRangeOperators";
import { c2d, renderCurrency } from "@src/helpers/currency";

export function OpenContests() {
  const [isFilterDate, setIsFilterDate] = useState(false);
  const { data: contests, isLoading } = useGetNflContestsReportQuery(
    { data: { filter: ContestsReportFilterType.Open } },
    {
      staleTime: 300_000, // 5 min
    }
  );

  const columns = useMemo<GridColumns>(() => {
    const cols: GridColumns = [
      { field: "id", headerName: "Contest ID", width: 80 },
      {
        field: "createdAt",
        headerName: "Creation Date",
        width: 200,
        valueFormatter: (params: GridValueFormatterParams) =>
          dayjs(params.value as string).format("MMM D, YYYY, H:mma"),
        sortComparator: dateComparator,
        filterOperators: dateRangeOperators,
      },
      { field: "creatorName", headerName: "Creator Name", width: 200 },
      { field: "creatorEmail", headerName: "Creator Email", width: 200 },
      { field: "creatorPlayer", headerName: "Creator Player", width: 200 },
      { field: "claimerPlayer", headerName: "Claimer Player", width: 200 },
      { field: "entryAmount", headerName: "Entry", width: 150 },
    ];
    return cols;
  }, []);

  const rows = useMemo(
    () =>
      contests?.getContestsReport?.map((contest) => ({
        ...contest,
        creatorName: contest.creatorUser.fullName,
        creatorEmail: contest.creatorUser.email,
        creatorPlayer: contest.creatorPlayer.fullName,
        claimerPlayer: contest.claimerPlayer.fullName,
        entryAmount: renderCurrency(c2d(contest.entryAmount)),
      })) ?? [],
    [contests]
  );

  const onFilterChange = (model: GridFilterModel) => {
    const { columnField } = model.items[0];
    if (columnField === "createdAt") {
      setIsFilterDate(true);
      return;
    }
    setIsFilterDate(false);
  };

  return (
    <>
      {isLoading ? (
        <div>...loading</div>
      ) : (
        <DataGrid
          columns={columns}
          initialState={{
            sorting: {
              sortModel: [{ field: "startDate", sort: "asc" }],
            },
          }}
          rows={rows}
          sx={{
            minHeight: "75vh",
          }}
          componentsProps={{
            panel: {
              sx: {
                "& .MuiDataGrid-filterForm": {
                  width: isFilterDate ? 600 : 500,
                },
              },
            },
          }}
          onFilterModelChange={onFilterChange}
        />
      )}
    </>
  );
}

const dateComparator: GridComparatorFn = (isoDate1, isoDate2): 1 | -1 => {
  return dayjs(isoDate1 as string).isBefore(dayjs(isoDate2 as string)) ? 1 : -1;
};
