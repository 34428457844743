import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  Stack,
  Tab,
  TextField,
  styled,
} from "@mui/material";
import { TitleOfTheContest } from "@src/components/partner/Configure/TitleOfTheContest/TitleOfTheContest";
import { TouchdownMath } from "@src/components/partner/Configure/TouchdownMath";
import { SignedInLayout } from "@src/components/templates/Layouts";
import { theme } from "@src/constants/theme";
import { usePartner } from "@src/context/PartnerContext";
import { useGetAllPartnerQuery } from "@src/graphql/graphql.generated";
import { useAuth } from "@src/hooks/useAuth";
import { useSnackbar } from "notistack";
import { useState } from "react";

const StyledLabel = styled("label")(({ theme }) => ({
  color: "#212121",
  fontSize: "14px",
}));

const StyledInput = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(1),
  "& input": {
    padding: "9px 12px 9px 12px",

    "&:focus": {
      outline: `none`,
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: `none`,
    "&:focus": {
      outline: `none`,
    },
  },
  "& .MuiOutlinedInput-root.Mui-error": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `#d32f2f !important`,
    },
  },
  "& .MuiFormHelperText-root.Mui-error": {
    fontSize: "12px",
    position: "absolute",
    width: "300px",
    top: "100%",
    margin: "5px 0",
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)} 0`,
  minHeight: "auto",
  justifyContent: "flex-start",
  marginRight: theme.spacing(3),
}));

export function Configure() {
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState("1");
  const { data: allPartners } = useGetAllPartnerQuery();
  const { data: authInfo } = useAuth();
  const { partnerId: selectedPartnerId }: any = usePartner();
  const partnerId =
    authInfo?.role === "ADMIN" ? authInfo?.partnerId! : selectedPartnerId!;
  const getFirstPartner = allPartners?.getAllPartner?.find(
    (partner) => partner.id === partnerId
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <SignedInLayout title="Configure" showPartners>
      <Box
        marginTop={theme.spacing(3)}
        sx={{
          border: "1px solid #EEE8DC",
          backgroundColor: "#fff",
          padding: theme.spacing(2),
        }}
      >
        <TabContext value={value}>
          <Box>
            <TabList
              onChange={handleTabChange}
              aria-label="lab API tabs example"
              sx={{
                minHeight: "auto",
                "& .MuiTabs-flexContainer": {
                  borderBottom: "1px solid rgba(240, 240, 240, 1)",
                },
              }}
            >
              <StyledTab label="KEYS" value="1" />
              <StyledTab label="Touchdown Math" value="2" />
              <StyledTab label="Title Of The Contests" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Grid item xs={3}>
              <Stack direction="row" alignItems="center">
                <FormControl variant="standard" fullWidth>
                  <StyledLabel htmlFor="iFrameKey">IFRAME KEY :</StyledLabel>
                  <StyledInput
                    id="iFrameKey"
                    value={getFirstPartner?.iframeKey}
                    disabled
                    onClick={async () => {
                      await navigator.clipboard.writeText(
                        getFirstPartner?.iframeKey!
                      );
                      enqueueSnackbar("IFrame key copied to clipboard!", {
                        variant: "success",
                      });
                    }}
                    sx={{ backgroundColor: "#F6F6F6", width: "50%" }}
                    aria-describedby="api-key"
                    InputProps={{
                      "aria-label": "api key",
                      endAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ cursor: "copy" }}
                        >
                          <ContentCopyIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Stack>
              <Stack direction="row" alignItems="center">
                <FormControl variant="standard" fullWidth>
                  <StyledLabel htmlFor="secretKey">SECRET KEY :</StyledLabel>
                  <StyledInput
                    id="secretKey"
                    value={getFirstPartner?.secretKey}
                    disabled
                    onClick={async () => {
                      await navigator.clipboard.writeText(
                        getFirstPartner?.secretKey!
                      );
                      enqueueSnackbar("Secret key copied to clipboard!", {
                        variant: "success",
                      });
                    }}
                    sx={{ backgroundColor: "#F6F6F6", width: "50%" }}
                    aria-describedby="api-key"
                    InputProps={{
                      "aria-label": "api key",
                      endAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ cursor: "copy" }}
                        >
                          <ContentCopyIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Stack>
            </Grid>
          </TabPanel>
          <TabPanel
            value="2"
            sx={{
              padding: `${theme.spacing(3)} 0 ${theme.spacing(3)} 0`,
            }}
          >
            <TouchdownMath partnerId={partnerId} />
          </TabPanel>
          <TabPanel value="3">
            <TitleOfTheContest partnerId={partnerId} />
          </TabPanel>
        </TabContext>
      </Box>
    </SignedInLayout>
  );
}
