import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import {
  Badge,
  Box,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  Tab,
  Typography,
  styled,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { AllContestsCard } from "@molecules/AllContestsCard";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import WeekPicker from "@organisms/Pickers/WeekPicker";
import { ROUTES } from "@src/Routes";
import { SignedInLayout } from "@src/components/templates/Layouts";
import { theme } from "@src/constants/theme";
import {
  ContestOrderInput,
  useCountHubContestsQuery,
  useFetchContestsQuery,
  useGetSportsQuery,
  useOrderContestsMutation,
} from "@src/graphql/graphql.generated";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

const StyledLabel = styled("label")(({ theme }) => ({
  color: "#212121",
  fontSize: "14px",
  lineHeight: "20px",
}));

enum createTypes {
  automated = "Automated",
  manual = "Manual",
}

const StyledTab = styled(Tab)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)} 0`,
  minHeight: "auto",
  justifyContent: "flex-start",
  marginRight: theme.spacing(3),
  fontSize: "16px",
}));

const StyledTabList = styled(TabList)((theme) => ({
  position: "relative",
  "&::after": {
    content: '""',
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    height: "1px",
    backgroundColor: "#E0E0E0",
    zIndex: 1,
  },
  "& .MuiTabs-indicator": {
    height: "3px",
  },
}));

const getSportIcon = (url: string, name: number, selectedTab: number) => {
  return (
    <CardMedia
      sx={{
        marginRight: theme.spacing(1),
        marginBottom: "0 !important",
        fontSize: "18px",
        filter: name !== selectedTab ? "grayscale(100%)" : "",
        height:
          url ===
          "https://topprop-static-assets.s3.amazonaws.com/sports/pga.svg"
            ? "23px"
            : "18px",
        width: "18px",
      }}
      component="img"
      alt="image"
      image={url}
    />
  );
};

const tabAll = {
  id: 0,
  name: "ALL",
  logoUrl: "https://topprop-static-assets.s3.amazonaws.com/trophy.svg",
  enabled: true,
  questions: [],
};

export const AllContests = () => {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const {
    data: hubContestSportsList,
    isLoading: isSportsListLoading,
    isError: isSportListError,
    isFetching: isSportsFetching,
  } = useGetSportsQuery();

  const { mutate: orderContests } = useOrderContestsMutation();

  const [pickedDate, setPickedDate] = useState<string>(
    dayjs().format("MM-DD-YYYY")
  );
  const [availableSports, setAvailableSports] = useState<Array<any>>([]);
  const [selectedTabId, setSelectedTabId] = useState<any>(0);
  const [allContestCount, setAllContestCount] = useState<any>(0);
  const [contests, setContests] = useState<any>([]);
  const [contestsCount, setContestsCount] = useState<any>({});

  const {
    data: getAllContests,
    isLoading: isContestsLoading,
    isError: isContestsError,
  } = useFetchContestsQuery(
    {
      date: pickedDate,
      sportId: selectedTabId,
    },
    { cacheTime: 0 }
  );
  const { data: hubContestsCount } = useCountHubContestsQuery({
    date: pickedDate,
  });

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setContests((prevContests: any) => {
      if (prevContests) {
        const result = Array.from(prevContests);
        const [removed] = result.splice(dragIndex, 1);
        result.splice(hoverIndex, 0, removed);
        return result;
      }
    });
  }, []);

  useEffect(() => {
    setContests(getAllContests?.fetchContests);
  }, [getAllContests]);

  const handleDateChange = (selectedDate: dayjs.Dayjs | null) => {
    if (selectedDate) {
      setPickedDate(selectedDate.format("MM-DD-YYYY"));
    }
  };

  useEffect(() => {
    const countObj = hubContestsCount?.countHubContests.reduce(
      (acc: any, curr) => {
        acc[curr.sportId] = curr.contestCount;
        return acc;
      },
      {}
    );
    setContestsCount({ ...countObj });
  }, [hubContestsCount]);

  useEffect(() => {
    if (
      hubContestSportsList?.getSports?.length &&
      hubContestsCount?.countHubContests
    ) {
      const sports = hubContestSportsList.getSports.filter((sport) => {
        const { enabled, id } = sport;
        if (enabled) {
          return sport;
        } else {
          const contest = hubContestsCount.countHubContests.find(
            ({ sportId }) => sportId === id
          );
          if (contest && +contest?.contestCount > 0) return sport;
        }
      });
      setAvailableSports([tabAll, ...sports]);
      setSelectedTabId(tabAll.id);
    }
  }, [hubContestSportsList, setSelectedTabId, hubContestsCount]);

  const handleDrop = () => {
    const request: ContestOrderInput[] | undefined = contests?.map(
      (contest: any, index: number) => {
        return {
          contestId: contest.id,
          sportName: contest.type,
          orderIndex: index + 1,
        };
      }
    );
    request &&
      orderContests(
        { data: request },
        {
          onSuccess: () => {
            enqueueSnackbar("Contest order updated successfully.", {
              variant: "success",
            });
          },
        }
      );
  };

  useEffect(() => {
    if (selectedTabId === 0) {
      setAllContestCount(getAllContests?.fetchContests?.length);
    }
  }, [selectedTabId]);

  return (
    <SignedInLayout title="All Contest">
      <Box marginBottom={theme.spacing(3)}>
        <Card
          elevation={0}
          sx={{
            border: "1px solid #EEE8DC",
            padding: theme.spacing(1),
            marginBottom: theme.spacing(3),
          }}
        >
          <CardContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6}>
                <FormControl variant="standard">
                  <StyledLabel
                    sx={{ paddingBottom: "4px" }}
                    htmlFor="SelectWeek"
                  >
                    Select date
                  </StyledLabel>
                  <WeekPicker
                    defaultValue={dayjs()}
                    handleDateChange={handleDateChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Box textAlign="right">
                  <FormControl>
                    <Select
                      id="create-types"
                      onChange={(event) => {
                        if (event.target.value === "automated") {
                          navigate(ROUTES.Touchdown.Contest, {
                            state: "touchdown",
                          });
                        } else if (event.target.value === "manual") {
                          navigate(ROUTES.Touchdown.ContestHub);
                        }
                      }}
                      value={"+ Contest"}
                      renderValue={(value: any) => "+ Contest"}
                      sx={{
                        "#create-types": {
                          paddingTop: "10px",
                          paddingBottom: "10px",
                          paddingLeft: "10px",
                          color: "#b69056",
                        },
                        "&.MuiSvgIcon-root": {
                          color: "rgb(182 144 86)",
                        },
                        "& .MuiOutlinedInput-root": {
                          borderColor: "#B69056",
                          "&:hover": {
                            borderColor: "#B69056",
                          },
                          "&.Mui-focused": {
                            borderColor: "#B69056",
                          },
                        },
                      }}
                    >
                      {Object.entries(createTypes).map(
                        ([value, label], index) => (
                          <MenuItem key={index + 1} value={value}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={1}
                            >
                              <Typography fontSize={14}>{label}</Typography>
                            </Stack>
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>

            <Box marginTop={theme.spacing(3)}>
              <TabContext value={selectedTabId}>
                {isSportsListLoading || isSportListError || isSportsFetching ? (
                  <Box
                    py={2}
                    justifyContent="center"
                    textAlign="center"
                    display="flex"
                    alignItems="center"
                  >
                    <Typography sx={{ fontSize: "20px" }}>
                      Loading...
                    </Typography>
                    <CircularProgress size={22} />
                  </Box>
                ) : (
                  <>
                    {availableSports?.length === 0 ? (
                      <Box
                        sx={{
                          marginTop: theme.spacing(1),
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          border: "1px solid #EEE8DC",
                          backgroundColor: "#fff",
                          padding: `${theme.spacing(14)} ${theme.spacing(2)}`,
                        }}
                      >
                        <EmojiEventsOutlinedIcon
                          sx={{
                            fontSize: "60px",
                            color: "#EEE8DC",
                          }}
                        />
                        <Typography>No Sport Created</Typography>
                      </Box>
                    ) : (
                      <>
                        <Box>
                          <StyledTabList
                            onChange={(_, value) => {
                              setSelectedTabId(value);
                            }}
                            aria-label={"sports"}
                            variant="scrollable"
                            scrollButtons={false}
                            sx={{
                              minHeight: "auto",
                            }}
                          >
                            {availableSports?.map((sport, index) => (
                              <StyledTab
                                key={index + 1}
                                label={
                                  <>
                                    {sport?.name}
                                    <Badge
                                      // badgeContent={`${ selectedTabId === 0 ? allContestCount : contestsCount[sport.id] || 0 }`}
                                      badgeContent={`${
                                        contestsCount[sport.id] || 0
                                      }`}
                                      sx={{
                                        marginLeft: theme.spacing(2),
                                        "& .MuiBadge-badge": {
                                          backgroundColor:
                                            sport?.id !== selectedTabId
                                              ? "#F0F0F0"
                                              : "rgba(250, 242, 226, 1)",

                                          color:
                                            sport?.id !== selectedTabId
                                              ? "#8C8C8C"
                                              : theme.palette.primary.main,
                                          fontSize: "16px",
                                        },
                                      }}
                                    ></Badge>
                                  </>
                                }
                                value={sport.id}
                                icon={getSportIcon(
                                  sport?.logoUrl,
                                  sport?.id,
                                  selectedTabId
                                )}
                              />
                            ))}
                          </StyledTabList>
                        </Box>

                        <Box
                          sx={{
                            alignItems: "center",
                            justifyContent: "center",
                            border: "1px solid #EEE8DC",
                            backgroundColor: "#fff",
                            padding: `${theme.spacing(0)} ${theme.spacing(3)}`,
                            marginTop: `${theme.spacing(4)}`,
                          }}
                        >
                          <DndProvider backend={HTML5Backend} key={1}>
                            <Stack spacing={2}>
                              <TabPanel
                                value={selectedTabId}
                                sx={{
                                  padding: `${theme.spacing(
                                    2
                                  )} 0 ${theme.spacing(3)} 0`,
                                }}
                              >
                                {isContestsLoading || isContestsError ? (
                                  <Box
                                    py={2}
                                    sx={{
                                      fontSize: "20px",
                                      textAlign: "center",
                                    }}
                                  >
                                    Loading...
                                    <CircularProgress size={24} />
                                  </Box>
                                ) : (
                                  <>
                                    {contests?.length > 0 ? (
                                      contests?.map(
                                        (contest: any, index: number) => {
                                          return (
                                            <AllContestsCard
                                              key={index}
                                              draggable={selectedTabId === 0}
                                              contestData={contest}
                                              moveCard={
                                                selectedTabId === 0
                                                  ? moveCard
                                                  : {}
                                              }
                                              onDrop={
                                                selectedTabId === 0
                                                  ? handleDrop
                                                  : {}
                                              }
                                              orderIndex={index}
                                            />
                                          );
                                        }
                                      )
                                    ) : (
                                      <Box
                                        sx={{
                                          marginTop: theme.spacing(1),
                                          alignItems: "center",
                                          justifyContent: "center",
                                          textAlign: "center",
                                          backgroundColor: "#fff",
                                          padding: `${theme.spacing(
                                            14
                                          )} ${theme.spacing(2)}`,
                                        }}
                                      >
                                        <EmojiEventsOutlinedIcon
                                          sx={{
                                            fontSize: "60px",
                                            color: "#EEE8DC",
                                          }}
                                        />
                                        <Typography>
                                          No contests Created
                                        </Typography>
                                      </Box>
                                    )}
                                  </>
                                )}
                              </TabPanel>
                            </Stack>
                          </DndProvider>
                        </Box>
                      </>
                    )}
                  </>
                )}
              </TabContext>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </SignedInLayout>
  );
};
