import { theme } from "@src/constants/theme";
import WestIcon from "@mui/icons-material/West";
import { Box, Button, Chip, Typography, styled } from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ActiveTouchdownsDetails } from "@src/types/partner/dashboard";
import { DailyLeaderboard } from "./DailyLeaderboard";
import { MM_DD_YYYY } from "@src/constants/Touchdown";
import dayjs from "dayjs";
import { useAuth } from "@src/hooks/useAuth";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: "1px solid #EEE8DC",
  "& .MuiDataGrid-cell": {
    borderBottom: "1px solid #EEE8DC",
  },
  "& .MuiDataGrid-columnHeaders": {
    borderBottom: "1px solid #EEE8DC",
  },
  "& .MuiDataGrid-footerContainer": {
    borderTop: "1px solid #EEE8DC",
  },
}));

export function ActiveTouchdownDetails(props: ActiveTouchdownsDetails) {
  const {
    title,
    prizePoolsData,
    activeTouchDownId,
    setActiveTouchDownDetails,
  } = props;
  const { data: authUser } = useAuth();
  const [showPartnerDailyLeaderBoard, setShowPartnerDailyLeaderBoard] =
    useState<boolean>(false);
  const [prizePoolsID, setPrizePoolsID] = useState<number>(undefined!);

  const navigate = useNavigate();

  const COLUMNS: GridColumns = useMemo<GridColumns>(
    () => [
      {
        field: "startDateTime",
        headerName: "Days",
        width: 150,
        renderCell: (params) =>
          `${dayjs(params?.row?.startDateTime).format(MM_DD_YYYY)}`,
      },
      {
        field: "status",
        headerName: "Status",
        width: 150,
        renderCell: (params) => {
          let backgroundColor, borderColor, color;

          if (params.value === "COMPLETED") {
            backgroundColor = "#eaf1ed";
            borderColor = "rgba(20, 109, 52, 1)";
            color = "rgba(20, 109, 52, 1)";
          }
          if (params.value === "DRAFT") {
            backgroundColor = "rgba(255, 247, 234, 1)";
            borderColor = "rgba(182, 144, 86, 1)";
            color = "rgba(182, 144, 86, 1)";
          }
          if (params.value === "LIVE") {
            backgroundColor = "rgba(255, 229, 231, 1)";
            borderColor = "rgba(245, 34, 45, 1)";
            color = "rgba(245, 34, 45, 1)";
          }

          return (
            <Chip
              label={params.value}
              sx={{
                fontWeight: "500",
                borderRadius: "5px",
                backgroundColor,
                color,
                border: `1px solid ${borderColor}`,
                height: "auto",
              }}
            />
          );
        },
      },
      { field: "totalEntrants", headerName: "Entry Limit", width: 150 },
      { field: "userEntryCount", headerName: "User Entry", width: 150 },
      {
        field: "totalPrize",
        headerName: "Prize Pool",
        width: 150,
        renderCell: (params) => <span>${params.value}</span>,
      },
      {
        field: "isRollsOver",
        headerName: "Pot Rolls Over",
        width: 150,
        renderCell: (params) => (
          <div
            style={{
              fontWeight: "500",
              borderRadius: "5px",
              color:
                params.row.status === "COMPLETED"
                  ? params.value === true
                    ? "rgba(20, 109, 52, 1)"
                    : "rgba(245, 34, 45, 1)"
                  : undefined,
            }}
          >
            {params.row.status === "COMPLETED"
              ? params.value
                ? "Yes"
                : "No"
              : "-"}
          </div>
        ),
      },
      {
        field: "jackpotAmount",
        headerName: "Jackpot Price",
        width: 150,
        renderCell: (params) => <span>${params.value}</span>,
      },
      {
        field: "jackpotWinners",
        headerName: "Winners",
        width: 150,
        renderCell: (params) => (
          <span>{params.row.status === "COMPLETED" ? params.value : "-"}</span>
        ),
      },
      {
        field: "gppAmount",
        headerName: "GPP Price",
        width: 150,
        renderCell: (params) => <span>${params.value}</span>,
      },
      {
        field: "gppWinners",
        headerName: "Winners",
        width: 150,
        renderCell: (params) => (
          <span>{params.row.status === "COMPLETED" ? params.value : "-"}</span>
        ),
      },
      {
        field: "partnerVig",
        headerName: "Partner VIG",
        width: 150,
        renderCell: (params) => (
          <span>${params.row.rolloverVig + params.row.contestVig}</span>
        ),
      },
      {
        field: "nbaContestCount",
        headerName: "Created NBA Contest",
        width: 150,
      },
      {
        field: "nflContestCount",
        headerName: "Created NFL Contest",
        width: 150,
      },
      {
        field: "soccerContestCount",
        headerName: "Created Soccer Contest",
        width: 160,
      },
      {
        field: "dailyLeaderBoard",
        headerName: "Daily Leaderboard",
        width: 150,
        renderCell: (params) => (
          <>
            {params.row.status === "DRAFT" ? (
              "-"
            ) : (
              <Button
                variant="outlined"
                onClick={() => {
                  setShowPartnerDailyLeaderBoard(true);
                  setPrizePoolsID(params.row.id);
                }}
                size="small"
                sx={{ width: "70px" }}
              >
                VIEW
              </Button>
            )}
          </>
        ),
      },
      {
        field: "action",
        headerName: "Actions",
        width: 150,
        renderCell: (params) => (
          <>
            <Button
              variant="outlined"
              onClick={() => {
                authUser?.role === "ADMIN"
                  ? navigate(`/touchdown/${activeTouchDownId}`, {
                      state: "kambi",
                    })
                  : navigate(`/partner/touchdown/${activeTouchDownId}`, {
                      state: "kambi",
                    });
              }}
              size="small"
              sx={{
                width: "70px",
                "&.Mui-disabled": {
                  backgroundColor: "#F5F5F5",
                  color: "#00000040",
                  borderColor: "#D9D9D9",
                },
              }}
              disabled={params.row.status !== "DRAFT"}
            >
              Edit
            </Button>
          </>
        ),
      },
    ],
    []
  );

  return (
    <>
      {showPartnerDailyLeaderBoard ? (
        <DailyLeaderboard
          prizePoolId={prizePoolsID}
          setShowPartnerDailyLeaderBoard={setShowPartnerDailyLeaderBoard}
        />
      ) : (
        <>
          <Box marginBottom={theme.spacing(3)}>
            <Typography
              style={{
                display: "flex",
                alignItems: "center",
                color: "#212121",
                textDecoration: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                setActiveTouchDownDetails(false);
              }}
            >
              <WestIcon
                style={{ fontSize: "16px", marginRight: theme.spacing(1) }}
              />
              Back
            </Typography>
          </Box>
          <Box marginBottom={theme.spacing(3)}>
            <Typography
              variant="h5"
              fontWeight={500}
              marginBottom={theme.spacing(3)}
            >
              {title}
            </Typography>
          </Box>
          <Box
            style={{ height: "370px", overflow: "auto" }}
            marginBottom={theme.spacing(3)}
          >
            <StyledDataGrid
              columns={COLUMNS}
              initialState={{
                sorting: {
                  sortModel: [{ field: "startDate", sort: "asc" }],
                },
              }}
              rows={prizePoolsData || []}
              pageSize={5}
              components={{
                Pagination: () => null, // This hides the pagination component
              }}
              sx={{
                "& .MuiDataGrid-footerContainer": {
                  display: "none", // Hide the footer container completely
                },
              }}
            />
          </Box>
        </>
      )}
    </>
  );
}
