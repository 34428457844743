import { RangeInput } from "@mui/lab/DateRangePicker/RangeTypes";
import {
  Box,
  Button,
  FormControl,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import RangePicker from "@organisms/Pickers/RangePicker";
import { SignedInLayout } from "@src/components/templates/Layouts";
import { WeeklyLeaderboard } from "@src/components/touchDown/Dashboard/WeeklyLeaderboard";
import { YYYY_MM_DD } from "@src/constants/Touchdown";
import { theme } from "@src/constants/theme";
import { useGetWeeklyHistoryByDateRangeQuery } from "@src/graphql/graphql.generated";
import { IWeeklyHistory } from "@src/types/touchdown/contest";
import dayjs from "dayjs";
import { useMemo, useState } from "react";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: "1px solid #EEE8DC",
  "& .MuiDataGrid-cell": {
    borderBottom: "1px solid #EEE8DC",
  },
  "& .MuiDataGrid-columnHeaders": {
    borderBottom: "1px solid #EEE8DC",
  },
  "& .MuiDataGrid-footerContainer": {
    borderTop: "1px solid #EEE8DC",
  },
}));

export function ContestHistory() {
  const [weekRange, setWeekRange] = useState<RangeInput<dayjs.Dayjs | null>>([
    dayjs().startOf("month"),
    dayjs().endOf("month"),
  ]);
  const [
    selectedTouchdown,
    setSelectedTouchdown,
  ] = useState<IWeeklyHistory | null>();

  const columns = useMemo<GridColumns>(
    () => [
      { field: "weekDate", headerName: "Week", width: 200 },
      {
        field: "entryFee",
        headerName: "Entry Fee",
        width: 150,
        renderCell: (params) => <span>${params.value}</span>,
      },
      { field: "totalUserEntry", headerName: "User Entry", width: 150 },
      {
        field: "totalEntryFees",
        headerName: "Recieved Fees",
        width: 150,
        renderCell: (params) => <span>${params.value}</span>,
      },
      {
        field: "totalContestVig",
        headerName: "Total Contest VIG",
        width: 150,
        renderCell: (params) => <span>${params.value}</span>,
      },
      {
        field: "totalRolloverVig",
        headerName: "Total Rollover VIG",
        width: 150,
        renderCell: (params) => <span>${params.value}</span>,
      },
      {
        field: "totalWeeklyReserve",
        headerName: "Total Weekly Reserve",
        width: 150,
        renderCell: (params) => <span>${params.value}</span>,
      },
      {
        field: "weeklyPrize",
        headerName: "Weekly Prize",
        width: 150,
        renderCell: (params) => <span>${params.value}</span>,
      },
      {
        field: "moneyAddedByTopprop",
        headerName: "Money Added By Topprop",
        width: 150,
        renderCell: (params) => <span>${params.value}</span>,
      },
      {
        field: "profit",
        headerName: "Profit/Loss",
        width: 150,
        renderCell: (params) => (
          <span style={{ color: params.value < 0 ? "red" : "green" }}>
            ${params.value}
          </span>
        ),
      },
      {
        field: "sportContestCount",
        headerName: "Created NFL Contest",
        width: 150,
        renderCell: (params) => <span>{params.value["NFL"]}</span>,
      },
      {
        field: "",
        headerName: "Created NBA Contest",
        width: 150,
        renderCell: (params) => (
          <span>{params?.row?.sportContestCount["NBA"]}</span>
        ),
      },
      {
        field: "totalSubscribers",
        headerName: "Weekly Subscriber",
        width: 150,
      },
      {
        field: "weeklyLeaderBoard",
        headerName: "Weekly Leaderboard",
        width: 150,
        renderCell: (params) => (
          <Button
            variant="outlined"
            onClick={() => {
              setSelectedTouchdown(params.row);
            }}
          >
            View
          </Button>
        ),
      },
    ],
    []
  );

  const { data: weeklyHistory } = useGetWeeklyHistoryByDateRangeQuery({
    startDate: dayjs(weekRange[0])?.format(YYYY_MM_DD),
    endDate: dayjs(weekRange[1])?.format(YYYY_MM_DD),
  });

  return (
    <SignedInLayout title="Touchdown Dashboard">
      {selectedTouchdown ? (
        <WeeklyLeaderboard
          title={`Weekly Leaderboard (${selectedTouchdown.weekDate?.toLowerCase()})`}
          touchdownId={selectedTouchdown.touchdownId}
          renderBack
          parentComponent="contest-history"
          onBack={() => {
            setSelectedTouchdown(null);
          }}
        />
      ) : (
        <>
          <Stack
            marginBottom={theme.spacing(3)}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5" fontWeight={500}>
              Weekly History
            </Typography>
            <FormControl variant="standard">
              <RangePicker
                key={"contestHistory"}
                onChange={setWeekRange}
                value={weekRange}
              />
            </FormControl>
            {/* <FormControl variant="standard">
              <WeekPicker
                defaultValue={dayjs()}
                handleDateChange={handleDateChange}
              />
            </FormControl> */}
          </Stack>
          <Box style={{ height: "370px", overflow: "auto" }}>
            <StyledDataGrid
              columns={columns}
              initialState={{
                sorting: {
                  sortModel: [{ field: "weekDate", sort: "asc" }],
                },
              }}
              getRowId={(row) => row.touchdownId}
              rows={weeklyHistory?.getWeeklyHistoryByDateRange ?? []}
              pageSize={10}
              pagination
            />
          </Box>
        </>
      )}
    </SignedInLayout>
  );
}
