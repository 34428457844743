import { useAuth } from "@src/hooks/useAuth";
import { ROUTES } from "@src/Routes";
import { clearAuthTokens } from "@src/services/authTokenStorage";
import { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UnauthorizedAccess from "./UnauthorizedAccess";

export function ProtectedRoute({
  children,
  type,
}: {
  children: ReactNode;
  type: String;
}) {
  const { isError, isSuccess, data: authInfo } = useAuth();
  const navigate = useNavigate();

  const isPartnerAdmin = authInfo && authInfo?.role === "ADMIN";

  useEffect(() => {
    if (isError) {
      clearAuthTokens();
      navigate(ROUTES.Login, { replace: true });
    }
  }, [isError, navigate]);

  if (!isPartnerAdmin) {
    return isSuccess ? <>{children}</> : null;
  }

  if (isPartnerAdmin && type === "admin") {
    return isSuccess ? <>{children}</> : null;
  }
  return <UnauthorizedAccess />;
}
