import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import {
  EST_TIME_ZONE,
  MM_DD_YYYY,
  YYYY_MM_DD,
} from "@src/constants/Touchdown";
import { theme } from "@src/constants/theme";
import { useGetTouchdownByDateQuery } from "@src/graphql/graphql.generated";
import { PrizePoolDetails } from "@src/types/touchdown/dashboard";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DailyLeaderboard } from "../Dashboard/DailyLeaderboard";

interface ContestDetailsProps {
  currentWeekRange: Array<dayjs.Dayjs>;
}

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: "1px solid #EEE8DC !important",
  "& .MuiDataGrid-cell": {
    borderBottom: "1px solid #EEE8DC",
  },
  "& .MuiDataGrid-columnHeaders": {
    borderBottom: "1px solid #EEE8DC",
  },
  "& .MuiDataGrid-footerContainer": {
    borderTop: "1px solid #EEE8DC",
  },
}));

export function ContestDetails(props: ContestDetailsProps) {
  const { currentWeekRange } = props;
  const upcomingWeekRange = [
    currentWeekRange[0].add(1, "week"),
    currentWeekRange[1].add(1, "week"),
  ];

  const [selectedPrizePool, setSelectedPrizePool] =
    useState<null | PrizePoolDetails>(null);
  const [currentWeekPrizePools, setCurrentWeekPrizePools] = useState<
    Array<PrizePoolDetails>
  >([]);
  const [upcomingWeekPrizePools, setUpcomingWeekPrizePools] = useState<
    Array<PrizePoolDetails>
  >([]);

  const navigate = useNavigate();
  const { data: currentWeekTouchdownData } = useGetTouchdownByDateQuery({
    startDate: currentWeekRange[0].format(YYYY_MM_DD),
    endDate: currentWeekRange[1].format(YYYY_MM_DD),
  });
  const { data: upcomingWeekTouchdownData } = useGetTouchdownByDateQuery({
    startDate: upcomingWeekRange[0].format(YYYY_MM_DD),
    endDate: upcomingWeekRange[1].format(YYYY_MM_DD),
  });

  const CURRENT_WEEK_COLUMNS = useMemo<GridColumns>(
    () => [
      { field: "date", headerName: "Days", width: 150 },
      {
        field: "status",
        headerName: "Status",
        width: 150,
        renderCell: (params) => {
          let backgroundColor, borderColor, color;

          if (params.value === "COMPLETED") {
            backgroundColor = "#eaf1ed";
            borderColor = "rgba(20, 109, 52, 1)";
            color = "rgba(20, 109, 52, 1)";
          }
          if (params.value === "DRAFT") {
            backgroundColor = "rgba(255, 247, 234, 1)";
            borderColor = "rgba(182, 144, 86, 1)";
            color = "rgba(182, 144, 86, 1)";
          }
          if (params.value === "LIVE") {
            backgroundColor = "rgba(255, 229, 231, 1)";
            borderColor = "rgba(245, 34, 45, 1)";
            color = "rgba(245, 34, 45, 1)";
          }

          return (
            <Chip
              label={params.value}
              sx={{
                fontWeight: "500",
                borderRadius: "5px",
                backgroundColor,
                color,
                border: `1px solid ${borderColor}`,
                height: "auto",
              }}
            />
          );
        },
      },
      { field: "entryFees", headerName: "Entry Fees", width: 150 },
      { field: "totalEntrants", headerName: "Entry Limit", width: 150 },
      { field: "userEntryCount", headerName: "User Entry", width: 150 },
      {
        field: "predeterminePrizePool",
        headerName: "Prize Pool",
        width: 150,
        renderCell: (params) => <span>${params.value}</span>,
      },
      {
        field: "isRollsOver",
        headerName: "Pot Rolls Over",
        width: 150,
        renderCell: (params) => (
          <div
            style={{
              fontWeight: "500",
              borderRadius: "5px",
              color:
                params.row.status === "COMPLETED"
                  ? params.value === true
                    ? "rgba(20, 109, 52, 1)"
                    : "rgba(245, 34, 45, 1)"
                  : undefined,
            }}
          >
            {params.row.status === "COMPLETED"
              ? params.value
                ? "Yes"
                : "No"
              : "-"}
          </div>
        ),
      },
      {
        field: "predetermineJackpot",
        headerName: "Jackpot",
        width: 150,
        renderCell: (params) => <span>${params.value}</span>,
      },
      {
        field: "jackpotWinnersCount",
        headerName: "Winners",
        width: 150,
        renderCell: (params) => (
          <span>{params.row.status === "COMPLETED" ? params.value : "-"}</span>
        ),
      },
      {
        field: "predetermineDailyGPP",
        headerName: "GPP",
        width: 150,
        renderCell: (params) => <span>${params.value}</span>,
      },
      {
        field: "gppWinnersCount",
        headerName: "Winners",
        width: 150,
        renderCell: (params) => (
          <span>{params.row.status === "COMPLETED" ? params.value : "-"}</span>
        ),
      },
      {
        field: "predetermineContestVig",
        headerName: "Contest VIG",
        width: 150,
        renderCell: (params) => <span>${params.value}</span>,
      },
      {
        field: "addedByTopProp",
        headerName: "Money Added By TopProp",
        width: 150,
        renderCell: (params) => <span>${params.value}</span>,
      },
      {
        field: "profit",
        headerName: "Profit/Loss",
        width: 150,
        renderCell: (params) => (
          <span>
            {params.value && params.row.status === "COMPLETED"
              ? "$" + params.value
              : "-"}
          </span>
        ),
      },
      {
        field: "nbaContestsCount",
        headerName: "Created NBA Contest",
        width: 150,
      },
      {
        field: "nflContestsCount",
        headerName: "Created NFL Contest",
        width: 150,
      },
      {
        field: "soccerContestsCount",
        headerName: "Created Soccer Contest",
        width: 150,
      },
      {
        field: "dailyLeaderBoard",
        headerName: "Daily Leaderboard",
        width: 150,
        renderCell: (params) => (
          <>
            {params.row.status === "DRAFT" ? (
              "-"
            ) : (
              <Button
                variant="outlined"
                onClick={() => setSelectedPrizePool(params.row)}
                size="small"
                sx={{ width: "70px" }}
              >
                VIEW
              </Button>
            )}
          </>
        ),
      },
    ],
    []
  );

  const createPrizePoolList = (prizePools: Array<PrizePoolDetails>) =>
    prizePools?.map(
      (pp: PrizePoolDetails) =>
        ({
          ...pp,
          id: pp.prizePoolId,
          date: dayjs(pp.startDate)
            .utc()
            .tz(EST_TIME_ZONE)
            .format("MM-DD-YYYY"),
          predetermineJackpot: +pp.predetermineJackpot.toFixed(2),
          predetermineDailyGPP: +pp.predetermineDailyGPP.toFixed(2),
          profit: pp.profit,
        } as PrizePoolDetails)
    );

  useEffect(() => {
    const currentPrizePools =
      currentWeekTouchdownData?.getTouchdownByDate?.[0]?.prizePools;
    const upcomingPrizePools =
      upcomingWeekTouchdownData?.getTouchdownByDate?.[0]?.prizePools;

    if (currentPrizePools) {
      setCurrentWeekPrizePools(createPrizePoolList(currentPrizePools));
    }
    if (upcomingPrizePools) {
      setUpcomingWeekPrizePools(createPrizePoolList(upcomingPrizePools));
    }
  }, [currentWeekTouchdownData, upcomingWeekTouchdownData]);

  return (
    <>
      {selectedPrizePool ? (
        <DailyLeaderboard
          parentComponent="contest"
          prizePoolId={selectedPrizePool.prizePoolId}
          title={`Daily Leaderboard (${dayjs(
            selectedPrizePool.startDate
          ).format(MM_DD_YYYY)})`}
          onBack={() => setSelectedPrizePool(null)}
        />
      ) : (
        <div>
          <Box marginBottom={theme.spacing(3)}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">
                Current Week ({currentWeekRange[0].format(MM_DD_YYYY)} to{" "}
                {currentWeekRange[1].format(MM_DD_YYYY)})
              </Typography>
              <Stack direction="row" alignItems="center">
                <Button
                  variant="outlined"
                  size="medium"
                  sx={{ marginRight: theme.spacing(2) }}
                  onClick={() =>
                    navigate("/touchdown/contest", {
                      state: "weeklySubscriber",
                    })
                  }
                >
                  Weekly Subscriber
                </Button>
                <Button
                  variant="outlined"
                  size="medium"
                  sx={{ marginRight: theme.spacing(2) }}
                  onClick={() =>
                    navigate("/touchdown/contest", {
                      state: "weeklyLeaderboard",
                    })
                  }
                >
                  Weekly Leaderboard
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  onClick={() =>
                    navigate("/touchdown/contest", {
                      state: "touchdown",
                    })
                  }
                >
                  Create Touchdown
                </Button>
              </Stack>
            </Stack>
          </Box>

          {/* ======  Current Week  ======== */}
          <Box
            style={{ height: "475px", overflow: "auto" }}
            marginBottom={theme.spacing(3)}
          >
            <StyledDataGrid
              columns={CURRENT_WEEK_COLUMNS}
              initialState={{
                sorting: {
                  sortModel: [{ field: "startDate", sort: "asc" }],
                },
              }}
              getRowId={(row) => row.prizePoolId}
              rows={currentWeekPrizePools}
              pageSize={7}
            />
          </Box>

          {/* ======  Upcoming Week  ======== */}
          <Box marginBottom={theme.spacing(3)}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">
                Upcoming Week ({upcomingWeekRange[0].format(MM_DD_YYYY)} to{" "}
                {upcomingWeekRange[1].format(MM_DD_YYYY)})
              </Typography>
            </Stack>
          </Box>
          <Box
            style={{ height: "475px", overflow: "auto" }}
            marginBottom={theme.spacing(3)}
          >
            <StyledDataGrid
              columns={CURRENT_WEEK_COLUMNS}
              initialState={{
                sorting: {
                  sortModel: [{ field: "startDate", sort: "asc" }],
                },
              }}
              getRowId={(row) => row.prizePoolId}
              rows={upcomingWeekPrizePools}
              pageSize={7}
            />
          </Box>
        </div>
      )}
    </>
  );
}
