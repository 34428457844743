import { Box, Grid, Typography } from "@mui/material";
import { theme } from "@src/constants/theme";
import { useEffect, useState } from "react";
import {
  initTotals,
  PartnerTouchDownProps,
  WEEKLY_COUNT_LABELS,
  WeeklyCounts
} from "@src/types/partner/dashboard";
import { TouchdownSummaryCard } from "../TouchdownSummaryCard";

export function TouchdownSummary(props: PartnerTouchDownProps) {
  const { title, partnerTouchdownAnalytics } = props;

  const [weeklyCounts, setWeeklyCounts] = useState<WeeklyCounts>(initTotals);

  const calculateCounts = (touchdowns: any) => {
    const totals: WeeklyCounts = {
      totalActiveTouchdowns: 0,
      totalContestVig: "0",
      totalReceivedFees: "0",
      totalTouchdownCount: 0,
      totalUsersCount: 0,
      upcomingTouchdownCount: 0,
    };
    
    totals.totalActiveTouchdowns = touchdowns.totalActiveTouchdowns;
    totals.totalContestVig = touchdowns.totalContestVig;
    totals.totalReceivedFees = touchdowns.totalReceivedFees;
    totals.totalTouchdownCount = touchdowns.totalTouchdownCount;
    totals.totalUsersCount = touchdowns.totalUsersCount;
    totals.upcomingTouchdownCount = touchdowns.upcomingTouchdownCount;

    setWeeklyCounts({ ...totals });
  };

  useEffect(() => {
    const touchdowns = partnerTouchdownAnalytics?.getPartnerTouchdownAnalytics;
    if (touchdowns) {
      calculateCounts(touchdowns);
    } else {
      setWeeklyCounts({ ...initTotals });
    }
  }, [partnerTouchdownAnalytics]);

  return (
    <>
      <Box marginBottom={theme.spacing(3)}>
        {title ? (
          <Typography
            variant="h5"
            fontWeight={500}
            marginBottom={theme.spacing(3)}
          >
            {title}
          </Typography>
        ) : (
          ""
        )}
        <Grid container spacing={2}>
          {WEEKLY_COUNT_LABELS?.map((item, index) => (
            <TouchdownSummaryCard
              key={index}
              index={index}
              title={item.title}
              value={weeklyCounts[item.key]}
            />
          ))}
        </Grid>
      </Box>
    </>
  );
}
