import AddIcon from "@mui/icons-material/Add";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import TimePicker from "@organisms/Pickers/TimePicker";
import { QueryKeys } from "@src/constants/QueryKeys";
import {
  useCreateHubContestMutation,
  useUpdateHubContestMutation,
} from "@src/graphql/graphql.generated";
import { getAuthInfo } from "@src/services/auth";
import { axiosTouchdownInstance } from "@src/services/axiosInstances";
import { parseGQLError } from "@src/utils/parse-error";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

const useStyles = makeStyles((theme) => ({
  required: {
    "&:after": {
      content: '" *"',
      color: "red",
    },
  },
}));

const StyledInput = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(1),
  "& input": {
    padding: "9px 12px 9px 12px",

    "&:focus": {
      outline: `none`,
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: `#E2D3BB !important`,
    "&:focus": {
      outline: `none`,
    },
  },
  "& .MuiOutlinedInput-root.Mui-error": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `#d32f2f !important`,
    },
  },
  "& .MuiFormHelperText-root.Mui-error": {
    fontSize: "12px",
    position: "absolute",
    width: "300px",
    top: "100%",
    margin: "5px 0",
  },
}));

const StyledLabel = styled("label")(({ theme }) => ({
  color: "#212121",
  fontSize: "14px",
  lineHeight: "20px",
}));

interface QuestionType {
  id: number;
  question: string;
  metric: string;
}
interface CreateMatchUpsFormProps {
  formData: any;
  selectedDate: any;
  type: string;
  isDisabled: boolean;
  updateContestCount: (id?: number) => {};
  setTypeOfForm: (type: string) => void;
}
export const CreateMatchUpsForm = ({
  formData,
  selectedDate,
  type,
  isDisabled,
  updateContestCount,
  setTypeOfForm,
}: CreateMatchUpsFormProps) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { mutate: createHubContest } = useCreateHubContestMutation();
  const { mutate: editHubContest } = useUpdateHubContestMutation();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    shouldUnregister: false,
  });

  const [selectedQuestionId, setSelectedQuestionId] = useState<number>(-1);
  const [uploadPhotoUrl1, setUploadPhotoUrl1] = useState<any>(
    formData?.player1PhotoUrl
  );
  const [uploadPhotoUrl2, setUploadPhotoUrl2] = useState<any>(
    formData?.player2PhotoUrl
  );
  const defaultValueOfContestQuestion =
    type === "edit" ? formData?.questionId?.question : "";

  useEffect(() => {
    if (formData && type === "edit") {
      const player1TimeString = formData?.player1GameStartTime;
      const player2TimeString = formData?.player2GameStartTime;
      const player1TimePart = player1TimeString.split("T")[1];
      const player2TimePart = player2TimeString.split("T")[1];

      setValue("player1FullName", formData?.player1FullName);
      setValue("player2FullName", formData?.player2FullName);
      setValue("player1Game", formData?.player1Game);
      setValue("player2Game", formData?.player2Game);
      setValue("player1GameStartTime", dayjs(player1TimePart, "HH:mm:ss"));
      setValue("player2GameStartTime", dayjs(player2TimePart, "HH:mm:ss"));
      setValue("player1BonusPoints", formData?.player1BonusPoints);
      setValue("player2BonusPoints", formData?.player2BonusPoints);
      setValue("questionId", formData?.questionId?.question);
      setValue("published", formData?.published);
      setValue("questionId", formData?.formData?.questionId?.question);
    }
  }, [formData, type, setValue]);

  useEffect(() => {
    setUploadPhotoUrl1(formData?.player1PhotoUrl);
    setUploadPhotoUrl2(formData?.player2PhotoUrl);
  }, [formData?.player1PhotoUrl, formData?.player2PhotoUrl]);

  const uploadPlayerImage = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file);
    const { accessToken } = await getAuthInfo({ retry: true });
    try {
      const response = await axiosTouchdownInstance.post(
        "/upload-image",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return response?.data?.data?.imageUrl;
    } catch (error) {
      console.error("Error uploading player image:", error);
    }
  };

  const handleFileChange = (event: any, imageNumber: string) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (imageNumber === "first") {
          uploadPlayerImage(file).then((response) => {
            setUploadPhotoUrl1(response);
          });
        } else if (imageNumber === "second") {
          uploadPlayerImage(file).then((response) => {
            setUploadPhotoUrl2(response);
          });
        }
      };
      reader.onerror = (e) => {
        console.error("FileReader error:", e);
      };
      reader.readAsDataURL(file);
    } else {
      console.error("No file selected.");
    }
  };

  const onSubmit = async (submitData: any) => {
    if (type === "create") {
      const {
        player1FullName,
        player1BonusPoints,
        player1Game,
        player2FullName,
        player2BonusPoints,
        player2Game,
        published,
        player1GameStartTime,
        player2GameStartTime,
      } = submitData;

      const formattedDate = dayjs(selectedDate, "MM-DD-YYYY").format(
        "YYYY-MM-DD"
      );
      const player1MatchStartTime = player1GameStartTime.format("HH:mm:ss");
      const player2MatchStartTime = player2GameStartTime.format("HH:mm:ss");

      const player1GameStartDateTime = `${formattedDate}T${player1MatchStartTime}`;
      const player2GameStartDateTime = `${formattedDate}T${player2MatchStartTime}`;

      if (
        player1GameStartDateTime.includes("Invalid Date") ||
        player2GameStartDateTime.includes("Invalid Date")
      ) {
        enqueueSnackbar("Please enter a valid match start time.", {
          variant: "error",
        });
        return;
      }

      if (player1BonusPoints < 0 || player2BonusPoints < 0) {
        enqueueSnackbar("Please enter a valid bonus points.", {
          variant: "error",
        });
        return;
      }

      if (player1BonusPoints > 0 && player2BonusPoints > 0) {
        enqueueSnackbar(
          "Both players cannot have bonus points at the same time. Please ensure only one player has bonus points.",
          {
            variant: "error",
          }
        );

        return;
      }

      createHubContest(
        {
          data: {
            questionId: selectedQuestionId,
            player1FullName,
            player1PhotoUrl: uploadPhotoUrl1,
            player1BonusPoints: parseFloat(player1BonusPoints),
            player1Game,
            player2FullName,
            player1GameStartTime: player1GameStartDateTime,
            player2PhotoUrl: uploadPhotoUrl2,
            player2BonusPoints: parseFloat(player2BonusPoints),
            player2Game,
            sportId: formData?.id,
            published: published,
            player2GameStartTime: player2GameStartDateTime,
          },
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(QueryKeys.FetchHubContests);
            queryClient.invalidateQueries(QueryKeys.FetchContests);
            enqueueSnackbar("Contest created successfully.", {
              variant: "success",
            });
            reset();
            setUploadPhotoUrl1("");
            setUploadPhotoUrl2("");
            updateContestCount(formData?.id);
          },
          onError: (error: any) => {
            enqueueSnackbar(
              parseGQLError(error, "Error while creating contest."),
              {
                variant: "error",
              }
            );
          },
        }
      );
    } else if (type === "edit") {
      const { player1FullName, player1Game, player2FullName, player2Game } =
        submitData;

      editHubContest(
        {
          data: {
            id: formData?.id,
            player1FullName,
            player1PhotoUrl: uploadPhotoUrl1,
            player1Game,
            player2FullName,
            player2PhotoUrl: uploadPhotoUrl2,
            player2Game,
          },
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(QueryKeys.FetchHubContests);
            queryClient.invalidateQueries(QueryKeys.FetchContests);
            enqueueSnackbar("Contest updated successfully.", {
              variant: "success",
            });
            reset();
            setTypeOfForm("create");
          },
          onError: (error: any) => {
            enqueueSnackbar(
              parseGQLError(error, "Error while updating contest."),
              {
                variant: "error",
              }
            );
          },
        }
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid sx={{ paddingBottom: "16px" }}>
        <Stack spacing={2}>
          <FormControl>
            <StyledLabel className={classes.required} htmlFor="CONTEST TITLE">
              Contest title
            </StyledLabel>
            <Controller
              name="questionId"
              control={control}
              defaultValue=""
              rules={type !== "edit" ? { required: true } : { required: false }}
              render={({ field }) => (
                <>
                  <Select
                    sx={{
                      height: "40px",
                      marginTop: "7px",
                      background: "#fff",
                    }}
                    {...field}
                    id="questionid"
                    disabled={isDisabled}
                    {...register("questionId")}
                    value={field.value || ""}
                    onChange={(e) => field.onChange(e.target.value)}
                    renderValue={(value) => {
                      return value
                        ? value
                        : type === "edit"
                        ? defaultValueOfContestQuestion
                        : "Select title";
                    }}
                    displayEmpty
                  >
                    {formData?.questions?.map(
                      (question: QuestionType, index: number) => {
                        return (
                          <MenuItem
                            onClick={() => setSelectedQuestionId(question.id)}
                            key={index + 300}
                            value={question?.question}
                          >
                            {question?.question}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                  {errors.questionId && (
                    <Typography sx={{ fontSize: "12px" }} color="error">
                      Contest title required
                    </Typography>
                  )}
                </>
              )}
            />
          </FormControl>
          <FormControl
            sx={{ display: "flex", flexDirection: "row", gap: "16px" }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <StyledLabel htmlFor="player 1 imagee">
                Player 1 image
              </StyledLabel>
              <Box
                sx={{
                  position: "relative",
                  display: "inline-block",
                  marginTop: "5px",
                }}
              >
                <label
                  htmlFor="image1"
                  style={{
                    cursor: "pointer",
                    background: "#FAFAFA",
                    display: "block",
                    height: "62px",
                    width: "62px",
                    border: "1px dashed #D9D9D9",
                    borderRadius: "50%",
                    overflow: "hidden",
                  }}
                >
                  {!uploadPhotoUrl1 ? (
                    <span
                      style={{
                        display: "flex",
                        color: "rgb(191 184 184)",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <AddIcon
                        style={{
                          color: "rgb(191 184 184)",
                          width: "14px",
                          height: "14px",
                        }}
                      />
                      <span>Upload</span>
                    </span>
                  ) : null}
                  <input
                    type="file"
                    id="image1"
                    accept=".jpg, .jpeg, .png"
                    {...register("player1PhotoUrl")}
                    onChange={(e) => handleFileChange(e, "first")}
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      top: 0,
                      left: 0,
                      opacity: 0,
                      zIndex: -1,
                    }}
                  />
                  {uploadPhotoUrl1 && (
                    <Avatar
                      sx={{ width: 62, height: 62 }}
                      src={uploadPhotoUrl1}
                    />
                  )}
                </label>
              </Box>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <StyledLabel htmlFor="Player 2 image">Player 2 image</StyledLabel>

              <Box
                sx={{
                  position: "relative",
                  display: "inline-block",
                  marginTop: "5px",
                }}
              >
                <label
                  htmlFor="image2"
                  style={{
                    cursor: "pointer",
                    background: "#FAFAFA",
                    display: "block",
                    height: "62px",
                    width: "62px",
                    border: "1px dashed #D9D9D9",
                    borderRadius: "50%",
                    overflow: "hidden",
                  }}
                >
                  {!uploadPhotoUrl2 ? (
                    <span
                      style={{
                        display: "flex",
                        color: "rgb(191 184 184)",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <AddIcon
                        style={{
                          color: "rgb(191 184 184)",
                          width: "14px",
                          height: "14px",
                        }}
                      />
                      <span>Upload</span>
                    </span>
                  ) : null}

                  <input
                    type="file"
                    id="image2"
                    accept=".jpg, .jpeg, .png"
                    {...register("player2PhotoUrl")}
                    onChange={(e) => handleFileChange(e, "second")}
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      top: 0,
                      left: 0,
                      opacity: 0,
                      zIndex: -1,
                    }}
                  />
                  {uploadPhotoUrl2 && (
                    <Avatar
                      sx={{ width: 62, height: 62 }}
                      src={uploadPhotoUrl2}
                    />
                  )}
                </label>
              </Box>
            </Box>
          </FormControl>
          <FormControl sx={{ display: "flex", flexDirection: "row" }}>
            <Box sx={{ width: "216px" }}>
              <StyledLabel className={classes.required} htmlFor="player 1 name">
                Player 1 name
              </StyledLabel>

              <StyledInput
                sx={{ background: "#fff" }}
                id="player1FullName"
                type="string"
                required
                {...register("player1FullName", {
                  required: true,
                  validate: (value) => value.trim() !== "",
                })}
                placeholder="Enter player name"
              />
              {(errors.player1FullName ||
                errors.player1FullName?.type === "validate") && (
                <Typography sx={{ fontSize: "12px" }} color="error">
                  Player 1 name is required.
                </Typography>
              )}
            </Box>
            <Box sx={{ width: "122px", paddingTop: "23px" }}>
              <StyledInput
                id="player1BonusPoints"
                sx={{ background: "#fff" }}
                disabled={isDisabled}
                defaultValue={0}
                type="number"
                required
                {...register("player1BonusPoints", { required: true })}
                placeholder="Bonus point"
              />
              {errors.player1BonusPoints && (
                <Typography sx={{ fontSize: "12px" }} color="error">
                  Bonus point is required.
                </Typography>
              )}
            </Box>
          </FormControl>
          <FormControl>
            <StyledLabel
              className={classes.required}
              htmlFor="player 1 match name"
            >
              Match name
            </StyledLabel>
            <StyledInput
              sx={{ background: "#fff" }}
              id="player1Game"
              type="string"
              required
              {...register("player1Game", {
                required: true,
                validate: (value) => value.trim() !== "",
              })}
              placeholder="Enter match name"
            />
            {(errors.player1Game ||
              errors.player1Game?.type === "validate") && (
              <Typography sx={{ fontSize: "12px" }} color="error">
                Player 1 match name is required.
              </Typography>
            )}
          </FormControl>

          <FormControl>
            <StyledLabel
              className={classes.required}
              htmlFor="Match Start date for first"
            >
              Match start time
            </StyledLabel>
            <Controller
              name="player1GameStartTime"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Please fill this field",
                },
              }}
              render={({ field: { onChange, value }, fieldState }) => {
                return (
                  <TimePicker
                    value={value}
                    onChange={onChange}
                    disabled={isDisabled}
                    style={{
                      input: {
                        padding: "9px 12px",
                      },
                      background: "#fff",
                      marginTop: "8px",
                    }}
                  />
                );
              }}
            />
            {errors["player1GameStartTime"] && (
              <Typography sx={{ fontSize: "12px" }} color="error">
                Please fill this field
              </Typography>
            )}
          </FormControl>
          <FormControl sx={{ display: "flex", flexDirection: "row" }}>
            <Box sx={{ width: "216px" }}>
              <StyledLabel className={classes.required} htmlFor="player2name">
                Player 2 name
              </StyledLabel>

              <StyledInput
                sx={{ background: "#fff" }}
                id="player2FullName"
                {...register("player2FullName", {
                  required: true,
                  validate: (value) => value.trim() !== "",
                })}
                placeholder="Enter player name"
              />
              {(errors.player2FullName ||
                errors.player2FullName?.type === "validate") && (
                <Typography sx={{ fontSize: "12px" }} color="error">
                  Player 2 Name is required.
                </Typography>
              )}
            </Box>
            <Box sx={{ width: "122px", paddingTop: "23px" }}>
              <StyledInput
                id="player2BonusPoints"
                defaultValue={0}
                sx={{ background: "#fff" }}
                disabled={isDisabled}
                type="number"
                {...register("player2BonusPoints", { required: true })}
                placeholder="Bonus point"
              />
              {errors.player2BonusPoints && (
                <Typography sx={{ fontSize: "12px" }} color="error">
                  Bonus point is required
                </Typography>
              )}
            </Box>
          </FormControl>
          <FormControl>
            <StyledLabel
              className={classes.required}
              htmlFor="player 2 match name"
            >
              Match name
            </StyledLabel>
            <StyledInput
              sx={{ background: "#fff" }}
              id="player2Game"
              required
              {...register("player2Game", {
                required: true,
                validate: (value) => value.trim() !== "",
              })}
              placeholder="Enter match name"
            />
            {(errors.player2Game ||
              errors.player2Game?.type === "validate") && (
              <Typography sx={{ fontSize: "12px" }} color="error">
                Player 2 match name is required.
              </Typography>
            )}
          </FormControl>
          <FormControl>
            <StyledLabel
              className={classes.required}
              htmlFor="match start date for player 2 "
            >
              Match start time
            </StyledLabel>
            <Controller
              name="player2GameStartTime"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Please fill this field",
                },
              }}
              render={({ field: { onChange, value }, fieldState }) => (
                <TimePicker
                  value={value}
                  onChange={onChange}
                  disabled={isDisabled}
                  style={{
                    input: {
                      padding: "9px 12px",
                    },
                    background: "#fff",
                    marginTop: "8px",
                  }}
                />
              )}
            />
            {errors["player2GameStartTime"] && (
              <Typography sx={{ fontSize: "12px" }} color="error">
                Please fill this field
              </Typography>
            )}
          </FormControl>

          <FormControl
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <StyledLabel
              className={classes.required}
              htmlFor="publish matchup"
              sx={{ paddingTop: "10px" }}
            >
              Publish matchup
            </StyledLabel>
            <Controller
              name="published"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <Switch
                  {...field}
                  onChange={(e) => field.onChange(e.target.checked)}
                  checked={field.value}
                  name={field.name}
                  disabled={type === "edit"}
                />
              )}
            />
          </FormControl>
          <Button
            variant="contained"
            size="large"
            type="submit"
            onClick={handleSubmit(onSubmit)}
            disabled={type === "create" && !formData?.enabled}
          >
            {type === "edit" ? "Update matchup" : "Create matchup"}
          </Button>
        </Stack>
      </Grid>
    </form>
  );
};
