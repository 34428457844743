import { ChangeEvent } from "react";

import {
  TextField,
  InputAdornment,
  TextFieldProps,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Close";

type SearchFieldProps = Omit<TextFieldProps, "onChange"> & {
  onSearch: (value: string) => void;
  value?: string;
};

export function SearchField(props: SearchFieldProps) {
  const { onSearch, value, InputProps, variant, ...textFieldProps } = props;

  const handleChange = (
    ev: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    onSearch(ev.target.value);
  };

  const handleClear = () => {
    onSearch("");
  };

  return (
    <TextField
      {...textFieldProps}
      InputProps={{
        ...InputProps,
        sx: {
          ...InputProps?.sx,
        },
        startAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="clear search or filter"
              onClick={handleClear}
              sx={{ visibility: value ? "visible" : "hidden" }}
            >
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      onChange={handleChange}
      sx={{ "& .MuiInputBase-root": { px: 0 } }}
      value={value}
    />
  );
}
