import {
  Card,
  CardContent,
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { weeklyPrizeDistributionLabels } from "@src/constants/Touchdown";
import { theme } from "@src/constants/theme";
import { WeeklyPrizeDistributionProps } from "@src/types/touchDown";
import { useEffect, useState } from "react";

const StyledLabel = styled("label")(({ theme }) => ({
  color: "#212121",
  fontSize: "14px",
}));

const StyledInput = styled(OutlinedInput)(({ theme }) => ({
  marginTop: theme.spacing(1),
  "& input": {
    padding: "9px 12px 9px 12px",

    "&:focus": {
      outline: `none`,
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: `#E2D3BB !important`,
    "&:focus": {
      outline: `none`,
    },
  },
}));

export function WeeklyPrizeDistribution({
  data,
  title,
  totalWeeklyReserve,
}: WeeklyPrizeDistributionProps) {
  const [weeklyPrize, setWeeklyPrize] = useState<{ [key: string]: number }>();
  const [totalReservePercentage, setTotalReservePercentage] =
    useState<number>(0);

  const sumOfPrizeDistribution = (prizeData: { [key: string]: number }) => {
    const sumOfWeeklyReserve: number = Object.values(prizeData).reduce(
      (accumulator: number, currentValue: number) =>
        accumulator + +currentValue,
      0
    );
    setTotalReservePercentage(sumOfWeeklyReserve);
  };

  useEffect(() => {
    const prizeData = data?.reduce((obj: any, { id, value }) => {
      obj[id] = value;
      return obj;
    }, {});
    setWeeklyPrize(prizeData);
    sumOfPrizeDistribution(prizeData);
  }, [data]);

  const prizeDistributed = totalWeeklyReserve * (totalReservePercentage / 100);

  const items = Object.entries(weeklyPrizeDistributionLabels).map(
    ([key, label]: any) => (
      <Grid item xs key={key}>
        <FormControl variant="standard" fullWidth>
          <StyledLabel htmlFor={`Item${key + 1}`}>{label}</StyledLabel>
          <StyledInput
            id={`Item${key + 1}`}
            aria-describedby={`item${key + 1}`}
            endAdornment={<InputAdornment position="end">%</InputAdornment>}
            disabled
            inputProps={{
              "aria-label": `item${key + 1}`,
            }}
            value={weeklyPrize?.[key] || 0}
          />
        </FormControl>
      </Grid>
    )
  );

  return (
    <Card
      elevation={0}
      sx={{
        border: "1px solid #EEE8DC",
        padding: theme.spacing(1),
        marginBottom: theme.spacing(3),
      }}
    >
      <CardContent>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          marginBottom={theme.spacing(3)}
        >
          <Typography variant="h5" fontWeight={500}>
            {title}
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5" fontWeight={500}>
              Total Weekly Reserve :
            </Typography>
            <Typography
              variant="h5"
              fontWeight={800}
              marginLeft={theme.spacing(1)}
              sx={{ color: "green" }}
            >
              ${totalWeeklyReserve.toFixed(2)}
            </Typography>
          </Stack>
        </Stack>
        <Grid container spacing={2} marginBottom={theme.spacing(3)}>
          {items}
        </Grid>
        <Stack direction="row" alignItems="center">
          <Stack direction="row" alignItems="center">
            <Typography variant="body1" fontWeight={500}>
              Prize Distributed :
            </Typography>
            <Typography
              variant="body1"
              fontWeight={800}
              marginLeft={theme.spacing(1)}
              sx={{ color: theme.palette.primary.main }}
            >
              ${prizeDistributed.toFixed(2)} &nbsp; ({totalReservePercentage})%
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            marginLeft={theme.spacing(3)}
          >
            <Typography variant="body1" fontWeight={500}>
              Prize Remaining :
            </Typography>
            <Typography
              variant="body1"
              fontWeight={800}
              marginLeft={theme.spacing(1)}
              sx={{ color: "green" }}
            >
              ${(totalWeeklyReserve - prizeDistributed).toFixed(2)}
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
