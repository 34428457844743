import { Box, Button, Stack, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { MM_DD_YYYY } from "@src/constants/Touchdown";
import { theme } from "@src/constants/theme";
import {
  GetF2PActiveTouchdownsQuery,
  useFetchF2PTouchdownQuery,
} from "@src/graphql/graphql.generated";
import { formatDateMmDdYyyy } from "@src/utils/utils";
import dayjs from "dayjs";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

interface ContestDetailsProps {
  currentWeekRange: Array<dayjs.Dayjs>;
  partnerId?: number;
  role?: string;
  setActiveTouchDownIndex: Dispatch<SetStateAction<number>>;
  setActiveTouchDownDetails: Dispatch<SetStateAction<boolean>>;
  currentWeekTouchdownData: GetF2PActiveTouchdownsQuery | undefined;
}

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: "1px solid #EEE8DC !important",
  "& .MuiDataGrid-cell": {
    borderBottom: "1px solid #EEE8DC",
  },
  "& .MuiDataGrid-columnHeaders": {
    borderBottom: "1px solid #EEE8DC",
  },
  "& .MuiDataGrid-footerContainer": {
    borderTop: "1px solid #EEE8DC",
  },
}));

export function TouchdownDetails(props: ContestDetailsProps) {
  const {
    currentWeekRange,
    partnerId,
    role,
    setActiveTouchDownIndex,
    setActiveTouchDownDetails,
    currentWeekTouchdownData,
  } = props;
  const upcomingWeekRange = [
    currentWeekRange[0].add(1, "week"),
    currentWeekRange[1].add(1, "week"),
  ];

  const [currentWeekPrizePools, setCurrentWeekPrizePools] = useState<
    Array<any>
  >([]);
  const [upcomingWeekPrizePools, setUpcomingWeekPrizePools] = useState<
    Array<any>
  >([]);

  const navigate = useNavigate();
  const { data: upcomingWeekTouchdownData } = useFetchF2PTouchdownQuery(
    {
      data: {
        duration: "upcoming",
        limit: 10,
        page: 1,
        partnerId: partnerId!,
      },
    },
    {
      staleTime: 300_000, // 5 min
      enabled: Number(partnerId) > 0,
    }
  );

  const CURRENT_WEEK_COLUMNS = useMemo<GridColumns>(
    () => [
      { field: "name", headerName: "Name", width: 280 },
      {
        field: "startDateTime",
        headerName: "Date",
        width: 250,
        renderCell: (params) => (
          <span>
            {formatDateMmDdYyyy(params.row.startDateTime)} to{" "}
            {formatDateMmDdYyyy(params.row.endDateTime)}
          </span>
        ),
      },
      { field: "allowedPick", headerName: "No of Picks", width: 280 },
      {
        field: "userEntryCount",
        headerName: "Daily Metrics",
        width: 280,
        renderCell: (params) => (
          <>
            {params.row.status === "DRAFT" ? (
              "-"
            ) : (
              <Button
                variant="outlined"
                onClick={() => {
                  setActiveTouchDownDetails(true);
                  setActiveTouchDownIndex(
                    params.api.getRowIndex(params.row.id)
                  );
                }}
                size="small"
                sx={{ width: "70px" }}
              >
                VIEW
              </Button>
            )}
          </>
        ),
      },
    ],
    []
  );

  const UPCOMING_WEEK_COLUMNS = useMemo<GridColumns>(
    () => [
      { field: "name", headerName: "Name", width: 250 },
      {
        field: "startDateTime",
        headerName: "Date",
        width: 300,
        renderCell: (params) => (
          <span>
            {formatDateMmDdYyyy(params.row.startDateTime)} to{" "}
            {formatDateMmDdYyyy(params.row.endDateTime)}
          </span>
        ),
      },

      { field: "allowedPick", headerName: "No of Picks", width: 250 },
      {
        field: "userEntryCount",
        headerName: "Actions",
        width: 250,
        renderCell: (params) => (
          <>
            {params.row.status === "DRAFT" ? (
              "-"
            ) : (
              <Button
                variant="outlined"
                onClick={() =>
                  navigate(`/F2P/touchdown/${params.row?.id}`, {
                    state: "kambi",
                  })
                }
                size="small"
                sx={{ width: "70px" }}
              >
                Edit
              </Button>
            )}
          </>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    const currentPrizePools = currentWeekTouchdownData?.getF2PActiveTouchdowns;
    const upcomingPrizePools =
      upcomingWeekTouchdownData?.fetchF2PTouchdown?.data;

    if (currentPrizePools) {
      setCurrentWeekPrizePools(currentPrizePools);
    }
    if (upcomingPrizePools) {
      setUpcomingWeekPrizePools(upcomingPrizePools);
    }
  }, [currentWeekTouchdownData, upcomingWeekTouchdownData]);

  const handleNavigationCreateTouchdown = () => {
    if (role === "ADMIN") {
      navigate("/touchdown/create", {
        state: "kambi",
      });
    } else {
      navigate("/F2P/touchdown/create", {
        state: "kambi",
      });
    }
  };
  const handleNavigationTouchdownHistory = () => {
    if (role === "ADMIN") {
      navigate("/history");
    } else {
      navigate("/F2P/touchdown-history");
    }
  };
  return (
    <>
      <div>
        <Box marginBottom={theme.spacing(3)}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">
              {partnerId ? "Active Touchdown" : "Current Week"} (
              {currentWeekRange[0].format(MM_DD_YYYY)} to{" "}
              {currentWeekRange[1].format(MM_DD_YYYY)})
            </Typography>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Button
                variant="outlined"
                size="medium"
                onClick={handleNavigationTouchdownHistory}
              >
                Touchdown History
              </Button>
              <Button
                variant="contained"
                size="medium"
                onClick={handleNavigationCreateTouchdown}
                sx={{ marginLeft: 1 }}
              >
                Create Touchdown
              </Button>
            </Stack>
          </Stack>
        </Box>

        {/* ======  Current Week  ======== */}
        <Box
          style={{ height: "475px", overflow: "auto" }}
          marginBottom={theme.spacing(3)}
        >
          <StyledDataGrid
            columns={CURRENT_WEEK_COLUMNS}
            initialState={{
              sorting: {
                sortModel: [{ field: "startDate", sort: "asc" }],
              },
            }}
            getRowId={(row) => row?.id}
            rows={currentWeekPrizePools}
            pageSize={7}
          />
        </Box>

        {/* ======  Upcoming Week  ======== */}
        <Box marginBottom={theme.spacing(3)}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">
              {partnerId ? "Upcoming Touchdown" : "Upcoming Week"} (
              {upcomingWeekRange[0].format(MM_DD_YYYY)} to{" "}
              {upcomingWeekRange[1].format(MM_DD_YYYY)})
            </Typography>
          </Stack>
        </Box>
        <Box
          style={{ height: "475px", overflow: "auto" }}
          marginBottom={theme.spacing(3)}
        >
          <StyledDataGrid
            columns={UPCOMING_WEEK_COLUMNS}
            initialState={{
              sorting: {
                sortModel: [{ field: "startDate", sort: "asc" }],
              },
            }}
            getRowId={(row) => row?.id}
            rows={upcomingWeekPrizePools}
            pageSize={7}
          />
        </Box>
      </div>
    </>
  );
}
