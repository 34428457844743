import { useState } from "react";
import { Button, Stack, Grid } from "@mui/material";
import { FeaturedContestCard } from "@molecules/FeaturedContestCard";
import { SignedInWithSidebarLayout } from "@templates/Layouts";
import { AuditLogsModal } from "@templates/AuditLogsModal";
import {
  AuditActionFliterType,
  useGetFeaturedContestsQuery,
} from "@src/graphql/graphql.generated";

const MAX_FEATURED_CONTEST_COUNT = 4;

function Controls() {
  const [showAuditTrail, setShowAuditTrail] = useState(false);

  const handleCloseAudit = () => setShowAuditTrail(false);
  return (
    <Stack spacing={1}>
      <Button
        sx={{ mb: 4 }}
        fullWidth
        onClick={() => setShowAuditTrail(true)}
        variant="outlined"
      >
        Audit Trail
      </Button>
      <AuditLogsModal
        title="Featured Contests Audit"
        isOpen={showAuditTrail}
        onClose={handleCloseAudit}
        actions={[
          AuditActionFliterType.DeleteFeaturedContest,
          AuditActionFliterType.UpdateFeaturedContest,
        ]}
      />
    </Stack>
  );
}

export function FeaturedContest() {
  const { data: featuredContests } = useGetFeaturedContestsQuery();
  return (
    <SignedInWithSidebarLayout
      SidebarComponent={<Controls />}
      title="Featured Contest"
    >
      <Grid spacing={4} container>
        {Array(MAX_FEATURED_CONTEST_COUNT)
          .fill("")
          .map((_item, index) => (
            <Grid item xs={6}>
              <FeaturedContestCard
                contest={featuredContests?.getFeaturedContests[index]}
                index={index}
              />
            </Grid>
          ))}
      </Grid>
    </SignedInWithSidebarLayout>
  );
}
