import { Box } from "@mui/material";
import { TitleOfTheContest } from "@src/components/common/TitleOfTheContest/TitleOfTheContest";
import { SignedInLayout } from "@src/components/templates/Layouts";
import { theme } from "@src/constants/theme";
import { usePartner } from "@src/context/PartnerContext";

export function Configure() {
  const { partnerId }: any = usePartner();
  return (
    <SignedInLayout title="Configure">
      <Box
        marginTop={theme.spacing(3)}
        sx={{
          border: "1px solid #EEE8DC",
          backgroundColor: "#fff",
          padding: theme.spacing(2),
        }}
      >
        <TitleOfTheContest partnerId={partnerId} />
      </Box>
    </SignedInLayout>
  );
}
