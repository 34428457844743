import { RangeInput } from "@mui/lab/DateRangePicker/RangeTypes";
import {
  Box,
  Button,
  FormControl,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import RangePicker from "@organisms/Pickers/RangePicker";
import { ActiveTouchdownDetails } from "@src/components/partner/Dashboard/ActiveTouchdownDetails";
import { SignedInLayout } from "@src/components/templates/Layouts";
import { EST_TIME_ZONE } from "@src/constants/Touchdown";
import { theme } from "@src/constants/theme";
import { usePartner } from "@src/context/PartnerContext";
import { useFetchPartnerTouchdownQuery } from "@src/graphql/graphql.generated";
import { useAuth } from "@src/hooks/useAuth";
import { formatDate } from "@src/utils/utils";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: "1px solid #EEE8DC",
  "& .MuiDataGrid-cell": {
    borderBottom: "1px solid #EEE8DC",
  },
  "& .MuiDataGrid-columnHeaders": {
    borderBottom: "1px solid #EEE8DC",
  },
  "& .MuiDataGrid-footerContainer": {
    borderTop: "1px solid #EEE8DC",
  },
}));

export function ContestHistory() {
  const { data: authInfo } = useAuth();
  const { partnerId: selectedPartnerId }: any = usePartner();
  const partnerId =
    authInfo?.role === "ADMIN" ? authInfo?.partnerId! : selectedPartnerId!;
  const [weekRange, setWeekRange] = useState<RangeInput<dayjs.Dayjs | null>>([
    dayjs().startOf("month"),
    dayjs().startOf("week"),
  ]);

  const [startDateTime, setStartDateTime] = useState<String | null>(null);
  const [viewDetails, setViewDetails] = useState<boolean>(false);
  const [endDateTime, setEndDateTime] = useState<String | null>(null);
  const [activeTouchDownIndex, setActiveTouchDownIndex] = useState<number>(0);

  useEffect(() => {
    if (weekRange?.[0] && weekRange?.[1]) {
      setStartDateTime(
        dayjs(weekRange?.[0])?.tz(EST_TIME_ZONE, true)?.utc()?.toISOString()
      );
      setEndDateTime(
        dayjs(weekRange?.[1])?.tz(EST_TIME_ZONE, true)?.utc()?.toISOString()
      );
    }
  }, [weekRange]);

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: "startDateTime",
        headerName: "Date",
        width: 250,
        renderCell: (params) => (
          <span>
            {formatDate(params.row.startDateTime)} to{" "}
            {formatDate(params.row.endDateTime)}
          </span>
        ),
      },
      {
        field: "name",
        headerName: "Touchdown name",
        width: 230,
      },
      { field: "allowedPick", headerName: "Picks", width: 230 },
      {
        field: "entryFees",
        headerName: "Entry Fee",
        minWidth: 130,
        renderCell: (params) => <span>${params.row.entryFees}</span>,
      },
      {
        field: "totalContestVig",
        headerName: "Daily Metrics",
        minWidth: 130,
        renderCell: (params) => (
          <Button
            variant="outlined"
            size="medium"
            sx={{ marginRight: theme.spacing(2) }}
            onClick={() => {
              setViewDetails(true);
              setActiveTouchDownIndex(params.api.getRowIndex(params.row.id));
            }}
          >
            View
          </Button>
        ),
      },
    ],
    []
  );

  const { data: weeklyHistory } = useFetchPartnerTouchdownQuery(
    {
      data: {
        duration: "history",
        limit: 10,
        page: 1,
        partnerId: partnerId!,
        startDateTime: startDateTime,
        endDateTime: endDateTime,
      },
    },
    {
      staleTime: 300_000, // 5 min
      enabled: partnerId > 0 && startDateTime != null && endDateTime != null,
    }
  );
  const touchdowns = weeklyHistory?.fetchPartnerTouchdown?.data;

  return (
    <SignedInLayout title="Partner - Touchdown History" showPartners>
      {viewDetails ? (
        <ActiveTouchdownDetails
          title={`${touchdowns?.[activeTouchDownIndex]?.name} (${formatDate(
            touchdowns?.[activeTouchDownIndex]?.startDateTime!
          )} to
            ${formatDate(touchdowns?.[activeTouchDownIndex]?.endDateTime!)})`}
          prizePoolsData={touchdowns?.[activeTouchDownIndex]?.prizePools}
          activeTouchDownId={touchdowns?.[activeTouchDownIndex]?.id!}
          setActiveTouchDownDetails={setViewDetails}
        />
      ) : (
        <>
          <Stack
            marginBottom={theme.spacing(3)}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5" fontWeight={500}>
              Weekly History
            </Typography>
            <FormControl variant="standard">
              <RangePicker
                key={"contestHistory"}
                onChange={setWeekRange}
                value={weekRange}
              />
            </FormControl>
          </Stack>
          <Box style={{ height: "80vh", overflow: "auto" }}>
            <StyledDataGrid
              columns={columns}
              getRowId={(row) => row?.id}
              rows={touchdowns ?? []}
            />
          </Box>
        </>
      )}
    </SignedInLayout>
  );
}
