import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { Role, User } from "@src/graphql/graphql.generated";
import { PermissionId } from "@src/constants/permissions";
import {
  useCreateEditUserMutation,
  useGetAllPermissionsQuery,
  useGetRolesQuery,
} from "@src/graphql/graphql.generated";
import { parseGQLError } from "@src/utils/parse-error";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  Stack,
  TextField,
  Box,
} from "@mui/material";
import LabelIcon from "@mui/icons-material/Label";
import { AsyncCheckboxGroupInput } from "@organisms/AsyncCheckboxGroupInput";

interface CreateEditDialogProps {
  isCreate: boolean;
  isEdit: boolean;
  target: User | null;
  onClose: (refresh?: boolean) => void;
}

export function CreateEditDialog({
  isCreate,
  isEdit,
  target,
  onClose,
}: CreateEditDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: create, isLoading } = useCreateEditUserMutation();
  const [email, setEmail] = useState("");
  const { data: permissions, isLoading: isPermissionsLoading } =
    useGetAllPermissionsQuery();
  const { data: roles, isLoading: isRolesLoading } = useGetRolesQuery();
  const [selectedPermissions, setSelectedPermissions] = useState<
    Array<PermissionId>
  >([]);
  const [selectedRoles, setSelectedRoles] = useState<Array<number>>([]);

  useEffect(() => {
    if (target) {
      setEmail(target.email);
      setSelectedPermissions(target.permissions);
      const roleIds = target.roles.map((role) => role.id);
      setSelectedRoles(roleIds);
    }
  }, [target]);

  const handleChangePermissions = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedTarget = event.target;
    if (selectedTarget.checked) {
      setSelectedPermissions([
        ...selectedPermissions,
        selectedTarget.name as PermissionId,
      ]);
    } else {
      setSelectedPermissions(
        selectedPermissions.filter(
          (permission) => permission !== selectedTarget.name
        )
      );
    }
  };

  const handleChangeRoles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedTarget = event.target;
    if (selectedTarget.checked) {
      setSelectedRoles([...selectedRoles, Number(selectedTarget.name)]);
    } else {
      setSelectedRoles(
        selectedRoles.filter((role) => role !== Number(selectedTarget.name))
      );
    }
  };

  const handleClose = () => {
    setEmail("");
    setSelectedPermissions([]);
    onClose();
  };

  const handleCreate = () => {
    create(
      {
        data: {
          email,
          permissions: selectedPermissions,
          roles: selectedRoles,
          userId: target?.id,
        },
      },
      {
        onSuccess: () => {
          enqueueSnackbar(
            `Role has been ${isCreate ? "created" : "edited"} successfully!`,
            {
              variant: "success",
            }
          );
          onClose(true);
        },
        onError: (error: any) => {
          enqueueSnackbar(
            parseGQLError(error, `Unable to ${isCreate ? "creat" : "edit"}`),
            {
              variant: "error",
            }
          );
        },
      }
    );
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isCreate || isEdit}
      onClose={handleClose}
    >
      <DialogTitle>{isCreate ? "Create" : "Edit"} User</DialogTitle>
      <DialogContent>
        <Box component="main" mt={2}>
          <Stack spacing={2} flex="1">
            <FormControl>
              <TextField
                label="Email"
                type="email"
                required
                InputProps={{
                  "aria-label": "email-input",
                  startAdornment: (
                    <InputAdornment position="start">
                      <LabelIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(ev) => setEmail(ev.target.value)}
                value={email}
              />
            </FormControl>
            <AsyncCheckboxGroupInput
              header="Roles"
              options={
                roles
                  ? roles.getRoles.map((role: Pick<Role, "id" | "name">) => ({
                      key: role.id,
                      label: role.name,
                    }))
                  : []
              }
              isLoading={isRolesLoading}
              selected={selectedRoles}
              handleChange={handleChangeRoles}
            />
            <AsyncCheckboxGroupInput
              header="Permissions"
              options={
                permissions
                  ? permissions.getAllPermissions.map((permission) => ({
                      key: permission,
                      label: permission,
                    }))
                  : []
              }
              isLoading={isPermissionsLoading}
              selected={selectedPermissions}
              handleChange={handleChangePermissions}
            />
            <Button
              variant="contained"
              onClick={handleCreate}
              disabled={!email || selectedRoles.length < 1 || isLoading}
            >
              {isCreate ? "Create" : "Edit"}
            </Button>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
