import { useState, SyntheticEvent, useEffect, useMemo } from "react";
import { useNavigate, useMatch } from "react-router-dom";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab, Stack } from "@mui/material";
import { SignedInLayout } from "@src/components/templates/Layouts";
import {
  OpenContests,
  ActiveContests,
  ClosedUnmatchedContests,
  ClosedMatchedContests,
} from "@src/components/templates/ContestReports";
import { ContestReportIds } from "@src/constants/BattlegroundTabIds";

const contestRootRoute = "/pga-reports/contests";

export function Contests() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<ContestReportIds>(
    ContestReportIds.Open
  );

  const contestRoutes = useMemo(
    () => ({
      Open: `${contestRootRoute}/open`,
      Active: `${contestRootRoute}/active`,
      ClosedMatched: `${contestRootRoute}/closed-matched`,
      ClosedUnmatched: `${contestRootRoute}/closed-unmatched`,
    }),
    []
  );

  const matchActive = useMatch(contestRoutes.Active);
  const matchClosedMatched = useMatch(contestRoutes.ClosedMatched);
  const matchClosedUnmatched = useMatch(contestRoutes.ClosedUnmatched);

  useEffect(() => {
    if (matchClosedUnmatched) {
      setActiveTab(ContestReportIds.ClosedUnmatched);
    } else if (matchActive) {
      setActiveTab(ContestReportIds.Active);
    } else if (matchClosedMatched) {
      setActiveTab(ContestReportIds.ClosedMatched);
    } else {
      setActiveTab(ContestReportIds.Open);
      navigate(contestRoutes.Open);
    }
  }, [
    matchActive,
    matchClosedUnmatched,
    matchClosedMatched,
    contestRoutes.Open,
    navigate,
  ]);

  const handleChangeTab = (
    _e: SyntheticEvent,
    newActiveTab: ContestReportIds
  ) => {
    const { Active, ClosedMatched, ClosedUnmatched, Open } = contestRoutes;
    switch (newActiveTab) {
      case ContestReportIds.Active:
        navigate(Active);
        break;

      case ContestReportIds.ClosedMatched:
        navigate(ClosedMatched);
        break;

      case ContestReportIds.ClosedUnmatched:
        navigate(ClosedUnmatched);
        break;

      case ContestReportIds.Open:
      default:
        navigate(Open);
        break;
    }
  };

  return (
    <SignedInLayout title="Contest Reports">
      <TabContext value={activeTab}>
        <Stack flex={1} width="100%">
          <Stack
            direction="row"
            justifyContent="center"
            spacing={2}
            width="100%"
          >
            <TabList
              onChange={handleChangeTab}
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                flex: 10,
              }}
            >
              <Tab
                label="Open"
                sx={{ fontSize: { md: "1rem !important" } }}
                value={ContestReportIds.Open}
              />
              <Tab
                label="Active"
                sx={{ fontSize: { md: "1rem !important" } }}
                value={ContestReportIds.Active}
              />
              <Tab
                label="Closed Matched"
                sx={{ fontSize: { md: "1rem !important" } }}
                value={ContestReportIds.ClosedMatched}
              />
              <Tab
                label="Closed Unmatched"
                sx={{ fontSize: { md: "1rem !important" } }}
                value={ContestReportIds.ClosedUnmatched}
              />
            </TabList>
          </Stack>
          <TabPanel value={ContestReportIds.Open}>
            <OpenContests />
          </TabPanel>
          <TabPanel value={ContestReportIds.Active}>
            <ActiveContests />
          </TabPanel>
          <TabPanel value={ContestReportIds.ClosedMatched}>
            <ClosedMatchedContests />
          </TabPanel>
          <TabPanel value={ContestReportIds.ClosedUnmatched}>
            <ClosedUnmatchedContests />
          </TabPanel>
        </Stack>
      </TabContext>
    </SignedInLayout>
  );
}
