import { TouchdownSummary } from "@src/components/partner/Dashboard/TouchdownSummary";
import { SignedInLayout } from "@src/components/templates/Layouts";
import {
  useGetAllPartnerQuery,
  useGetPartnerTouchdownAnalyticsQuery,
} from "@src/graphql/graphql.generated";
import TouchdownChart from "./TouchdownChart";
import { ActiveTouchdown } from "@src/components/partner/Dashboard/ActiveTouchdown";
import { ActiveTouchdownDetails } from "@src/components/partner/Dashboard/ActiveTouchdownDetails";
import { useState } from "react";
import { formatDate } from "@src/utils/utils";
import { useAuth } from "@src/hooks/useAuth";
import { usePartner } from "@src/context/PartnerContext";

export function PartnerDashboard() {
  const { data: authUser } = useAuth();
  const { partnerId }: any = usePartner();
  const currentPartnerId =
    authUser?.role === "ADMIN" ? authUser?.partnerId! : partnerId!;
  const { data: partnerTouchdownAnalytics } =
    useGetPartnerTouchdownAnalyticsQuery({
      partnerId: currentPartnerId,
    });
  const touchdownCount =
    partnerTouchdownAnalytics?.getPartnerTouchdownAnalytics?.touchdownCount;
  const activeTouchdowns =
    partnerTouchdownAnalytics?.getPartnerTouchdownAnalytics?.activeTouchdowns;

  const [activeTouchDownDetails, setActiveTouchDownDetails] =
    useState<boolean>(false);
  const [activeTouchDownIndex, setActiveTouchDownIndex] = useState<number>(0);

  return (
    <SignedInLayout title="Partner Dashboard" showPartners>
      <>
        {activeTouchDownDetails && activeTouchdowns ? (
          <ActiveTouchdownDetails
            title={`${
              activeTouchdowns[activeTouchDownIndex]?.name
            } (${formatDate(
              activeTouchdowns[activeTouchDownIndex]?.startDateTime
            )} to
            ${formatDate(
              activeTouchdowns[activeTouchDownIndex]?.endDateTime
            )})`}
            prizePoolsData={activeTouchdowns[activeTouchDownIndex]?.prizePools}
            activeTouchDownId={activeTouchdowns[activeTouchDownIndex]?.id}
            setActiveTouchDownDetails={setActiveTouchDownDetails}
          />
        ) : (
          <>
            <TouchdownSummary
              title=""
              partnerTouchdownAnalytics={partnerTouchdownAnalytics}
            />
            <TouchdownChart data={touchdownCount} />
            <ActiveTouchdown
              title={"Active Touchdown"}
              activeTouchdownData={activeTouchdowns}
              setActiveTouchDownIndex={setActiveTouchDownIndex}
              setActiveTouchDownDetails={setActiveTouchDownDetails}
            />
          </>
        )}
      </>
    </SignedInLayout>
  );
}
