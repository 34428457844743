import { FC, ReactElement, useState } from "react";
import { Box, Stack, styled } from "@mui/material";
import { grey } from "@mui/material/colors";
import { AppBar } from "@molecules/AppBar/AppBar";
import { DrawerHeader } from "@organisms/Navigation/DrawerHeader";
import { DRAWER_WIDTH, Navigation } from "@organisms/Navigation";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  marginLeft: 0,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: `${DRAWER_WIDTH}px`,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const SignedInWithSidebarLayout: FC<{
  breadcrumbs?: Array<string>;
  title: string;
  SidebarComponent: ReactElement;
}> = ({ breadcrumbs, title, children, SidebarComponent }) => {
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Stack flex={1}>
      <AppBar
        open={open}
        onOpen={handleDrawerOpen}
        breadcrumbs={breadcrumbs}
        title={title}
      />
      <Navigation open={open} onClose={handleDrawerClose} />
      <Main open={open}>
        <DrawerHeader />
        <Stack direction="row" flex={1}>
          <Box flexGrow={1} padding={3}>
            {children}
          </Box>
          <Box bgcolor={grey[100]} component="aside" padding={3} width={300}>
            {SidebarComponent}
          </Box>
        </Stack>
      </Main>
    </Stack>
  );
};
