export enum PermissionId {
  GolfTournamentView = "Golf.Tournaments.View",
  GolfTournamentManage = "Golf.Tournaments.Manage",
  GolfContestSuggestionsView = "Golf.ContestSuggestions.View",
  GolfContestSuggestionsManage = "Golf.ContestSuggestions.Manage",
  GolfContestCreationManage = "Golf.ContestCreation.Manage",
  GolfContestVoidCancel = "Golf.GolfContestVoidCancel.Manage",
  GolfFeaturesView = "Golf.Features.View",
  GolfFeaturesManage = "Golf.Features.Manage",
  GolfReports = "Golf.Reports", // view all golf reports
  GolfTasks = "Golf.Tasks", // Trigger crons
  CommonContestSuggestions = "Common.ContestSuggestions",
  CommonBonusPayout = "Common.BonusPayout",
  CommonUserSignups = "Common.UserSignups",
  CommonCoupons = "Common.Coupons",
  CommonWithdrawRequests = "Common.WithdrawRequests",
  CommonSupportTickets = "Common.SupportTickets",
  NflMatchups = "Nfl.Matchups",
  NflReports = "Nfl.Reports.Show",
  CommonFeaturedContestsView = "Common.FeaturedContests.View",
  CommonFeaturedContestsManage = "Common.FeaturedContests.Manage",
  CommonBroadcastNotifications = "Common.BroadcastNotifications",
  CommonDeletionRequestsManage = "Common.DeletionRequests.Manage",
}
