import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import {
  CreateContestStep,
  contestCreationStepState,
  tournamentTypeState,
  tournamentIdState,
  mainPlayerIdState,
  TournamentType,
  opponentPlayerIdsState,
  roundState,
  suggestionStepState,
} from "./state";
import {
  Stack,
  Typography,
  DialogTitle,
  Dialog,
  CircularProgress,
} from "@mui/material";
import {
  useTournamentsQuery,
  useCreateSuggestionMutation,
  useGetPlayersForSuggestionQuery,
} from "@src/graphql/graphql.generated";
import { parseGQLError } from "@src/utils/parse-error";
import { MainPlayer } from "./MainPlayer";
import { Opponents } from "./Opponents";
interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
}
export function CreateSuggestion(props: SimpleDialogProps) {
  const { onClose, open } = props;
  const setSuggestionStep = useSetRecoilState(suggestionStepState);
  const { enqueueSnackbar } = useSnackbar();
  const currentStep = useRecoilValue(contestCreationStepState);
  const tournamentId = useRecoilValue(tournamentIdState);
  const round = useRecoilValue(roundState);
  const tournamentType = useRecoilValue(tournamentTypeState);
  const mainPlayerId = useRecoilValue(mainPlayerIdState);
  const opponentIds = useRecoilValue(opponentPlayerIdsState);
  const resetCurrentStep = useResetRecoilState(contestCreationStepState);
  const resetOpponent = useResetRecoilState(opponentPlayerIdsState);
  const resetMainPlayerId = useResetRecoilState(mainPlayerIdState);
  const resetTournamentType = useResetRecoilState(tournamentTypeState);
  const queryClient = useQueryClient();
  const { mutate: create, isLoading: isUpdating } =
    useCreateSuggestionMutation();
  const tournamentsQueryKey = useTournamentsQuery.getKey();

  const {
    data: tournamentPlayers,
    isLoading: isTournamentPlayersLoading,
    refetch: refetchTournamentPlayers,
  } = useGetPlayersForSuggestionQuery({
    tournamentId: tournamentId!,
    contestType: tournamentType,
    roundNumber:
      tournamentType === TournamentType.RoundByRound ? +round! : null,
  });

  useEffect(() => {
    refetchTournamentPlayers();
  }, [tournamentType, refetchTournamentPlayers, tournamentId]);

  const handleCreateContest = () => {
    create(
      {
        mainPlayerId: mainPlayerId!,
        tournamentId: tournamentId,
        roundNumber:
          tournamentType === TournamentType.RoundByRound ? +round! : null,
        oppenentIds: opponentIds,
        contestType: tournamentType,
      },
      {
        onError: (err: any) => {
          enqueueSnackbar(parseGQLError(err, "Unable to save"), {
            variant: "error",
          });
        },
        onSuccess: () => {
          queryClient.invalidateQueries(tournamentsQueryKey);
          enqueueSnackbar("Created", {
            variant: "success",
          });
          onClose();
          setSuggestionStep(true);
          setSuggestionStep(false);
          resetMainPlayerId();
          resetOpponent();
          resetCurrentStep();
          resetTournamentType();
        },
      }
    );
  };

  const handleClose = () => {
    resetMainPlayerId();
    resetOpponent();
    resetCurrentStep();
    resetTournamentType();
    onClose();
  };

  return (
    <>
      <Dialog
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle>Create a Suggestion</DialogTitle>
        {!tournamentPlayers?.getPlayersForSuggestion ? (
          <Stack
            alignItems="center"
            flex={1}
            justifyContent="center"
            p={2}
            spacing={1}
          >
            {isTournamentPlayersLoading ? (
              <Stack alignItems="center" flex={1} justifyContent="center" m={5}>
                <CircularProgress size={24} />
              </Stack>
            ) : (
              <>
                <Stack flex={1} justifyContent="center" m={5}>
                  <Typography variant="h5" align="center">
                    Suggestions can't be created at this moment.
                  </Typography>
                </Stack>
              </>
            )}
          </Stack>
        ) : currentStep === CreateContestStep.SelectMainPlayer ? (
          <MainPlayer />
        ) : currentStep === CreateContestStep.SelectOpponents ? (
          <Opponents onSubmit={handleCreateContest} />
        ) : null}
      </Dialog>
    </>
  );
}
