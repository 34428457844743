import { useMemo, useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridComparatorFn,
  GridFilterModel,
  GridRowParams,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { SignedInWithSidebarLayout } from "@src/components/templates/Layouts";
import {
  AuditActionFliterType,
  Role,
  useGetRolesQuery,
} from "@src/graphql/graphql.generated";
import {
  CreateEditDialog,
  DeleteDialog,
  Controls,
} from "@src/components/organisms/Roles";
import { dateRangeOperators } from "@organisms/DataGridFilters/DateRange/dateRangeOperators";
import { AuditLogsModal } from "@src/components/templates/AuditLogsModal";

export interface RoleAction {
  target: Role | null;
  status: "edit" | "delete";
}

export function Roles() {
  const {
    data: roles,
    isLoading,
    refetch,
  } = useGetRolesQuery(
    {},
    {
      staleTime: 300_000, // 5 min
    }
  );
  const [isFilterDate, setIsFilterDate] = useState(false);
  const [isCreateOpen, setCreateOpen] = useState(false);
  const [action, setAction] = useState<RoleAction | null>(null);
  const [showAuditTrail, setShowAuditTrail] = useState(false);

  const handleCloseAudit = () => {
    setShowAuditTrail(false);
  };

  const onFilterChange = (model: GridFilterModel) => {
    const { columnField } = model.items[0];
    if (columnField === "createdAt") {
      setIsFilterDate(true);
      return;
    }
    setIsFilterDate(false);
  };

  const handleCloseCreateEdit = (refresh = false) => {
    setCreateOpen(false);
    setAction(null);
    if (refresh) {
      refetch();
    }
  };

  const handleCloseDelete = (refresh = false) => {
    setAction(null);
    if (refresh) {
      refetch();
    }
  };

  const handleActionDialogOpen = (
    params: GridRowParams,
    status: "edit" | "delete"
  ) => {
    setAction({ target: params.row as Role, status });
  };

  const columns = useMemo<GridColumns>(
    () => [
      { field: "id", headerName: "ID", width: 80 },
      { field: "name", headerName: "Name", width: 80 },
      {
        field: "createdAt",
        headerName: "Creation Date",
        width: 200,
        valueFormatter: (params) =>
          dayjs(params.value as string).format("MMM D, YYYY, H:ma"),
        sortComparator: dateComparator,
        filterOperators: dateRangeOperators,
      },
      {
        field: "permissions",
        headerName: "Permissions",
        flex: 1,
        valueGetter: (params) => params.value.join(", "),
      },
      {
        field: "actions",
        type: "actions",
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem
            icon={<EditIcon />}
            onClick={() => handleActionDialogOpen(params, "edit")}
            label="Edit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            onClick={() => handleActionDialogOpen(params, "delete")}
            label="Delete"
          />,
        ],
      },
    ],
    []
  );

  return (
    <SignedInWithSidebarLayout
      title="Roles"
      SidebarComponent={
        <Controls
          onStartCreate={() => setCreateOpen(true)}
          setShowAuditTrail={setShowAuditTrail}
        />
      }
    >
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <DataGrid
          columns={columns}
          initialState={{
            sorting: {
              sortModel: [{ field: "startDate", sort: "asc" }],
            },
          }}
          rows={roles?.getRoles ?? []}
          sx={{
            minHeight: "75vh",
          }}
          componentsProps={{
            panel: {
              sx: {
                "& .MuiDataGrid-filterForm": {
                  width: isFilterDate ? 600 : 500,
                },
              },
            },
          }}
          onFilterModelChange={onFilterChange}
        />
      )}
      <CreateEditDialog
        isCreate={isCreateOpen}
        isEdit={action?.status === "edit"}
        target={action?.target ?? null}
        onClose={handleCloseCreateEdit}
      />
      <DeleteDialog
        target={action?.target ?? null}
        isOpen={action?.status === "delete"}
        onClose={handleCloseDelete}
      />
      <AuditLogsModal
        title="Roles Audit"
        isOpen={showAuditTrail}
        onClose={handleCloseAudit}
        actions={[
          AuditActionFliterType.CreateRole,
          AuditActionFliterType.EditRole,
          AuditActionFliterType.DeleteRole,
        ]}
      />
    </SignedInWithSidebarLayout>
  );
}

const dateComparator: GridComparatorFn = (isoDate1, isoDate2): 1 | -1 => {
  return dayjs(isoDate1 as string).isBefore(dayjs(isoDate2 as string)) ? 1 : -1;
};
