import SportsBasketballIcon from "@mui/icons-material/SportsBasketball";
import SportsRugbyIcon from "@mui/icons-material/SportsRugby";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Badge,
  Box,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  FormControl,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import WeekPicker from "@organisms/Pickers/WeekPicker";
import { MatchUpsInTouchDown } from "@src/components/common/Contest/MatchUpsInTouchDown";
import { NbaContestCreation } from "@src/components/common/Contest/NbaContestCreation";
import { NflContestCreation } from "@src/components/common/Contest/NflContestCreation";
import { SoccerCreation } from "@src/components/common/Contest/SoccerCreation";
import { SignedInLayout } from "@src/components/templates/Layouts";
import { theme } from "@src/constants/theme";
import { usePartner } from "@src/context/PartnerContext";
import {
  SupportedSports,
  useCountPartnerContestsQuery,
  useFetchPartnerTouchdownByDateQuery,
  useGetCommonSportQuery,
  useGetPartnerSportsQuery,
  Win_Criteria,
} from "@src/graphql/graphql.generated";
import { useAuth } from "@src/hooks/useAuth";
import { PrizePoolCreationFields } from "@src/types/touchdown/contest";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

const StyledLabel = styled("label")(({ theme }) => ({
  color: "#212121",
  fontSize: "14px",
  lineHeight: "20px",
}));

const useStyles = makeStyles((theme) => ({
  required: {
    "&:after": {
      content: '" *"',
      color: "red",
    },
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface SportInfo {
  id?: number | null;
  key?: string | null;
  logoUrl?: string | null;
  name?: string | null;
  questions?: Array<{
    id: number;
    question: string;
    metric: string;
  }> | null;
  winCriteria?: Win_Criteria | null;
}

const getSportIcon = (url: string, name: string, selectedTab: string) => {
  return (
    <CardMedia
      sx={{
        marginRight: theme.spacing(1),
        marginBottom: "0 !important",
        fontSize: "18px",
        filter: name !== selectedTab ? "grayscale(100%)" : "",
        height:
          url ===
          "https://topprop-static-assets.s3.amazonaws.com/sports/pga.svg"
            ? "23px"
            : "18px",
        width: "18px",
      }}
      component="img"
      alt="image"
      image={url}
    />
  );
};

function MainTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ width: "100%" }}
    >
      {value === index && (
        <Box sx={{ pl: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export function Contests() {
  const { data: authInfo } = useAuth();
  const { partnerId: selectedPartnerId }: any = usePartner();
  const partnerId =
    authInfo?.role === "ADMIN" ? authInfo?.partnerId! : selectedPartnerId!;

  const [selectedTab, setSelectedTab] = useState<SupportedSports>(
    SupportedSports.Nba
  );
  const [selectedDate, setSelectedDate] = useState<string>(
    dayjs().format("MM-DD-YYYY")
  );
  const [nbaContestsCount, setNbaContestsCount] = useState<number>(0);
  const [nflContestsCount, setNflContestsCount] = useState<number>(0);
  const [prizePoolId, setPrizePoolId] = useState<number | null>(null);
  const [soccerContestsCount, setSoccerContestsCount] = useState<number>(0);
  const [contestsCount, setContestsCount] = useState<any>({});
  const [prizePool, setPrizePool] = useState<PrizePoolCreationFields | null>(
    null
  );
  const [paidCheckbox, SetPaidCheckbox] = useState<boolean>(true);
  const [f2pCheckbox, SetF2PCheckbox] = useState<boolean>(false);
  const [value, setValue] = useState(0);

  const [automatedSport, setAutomatedSport] = useState<any>([]);

  const { data: hubContestsCount } = useCountPartnerContestsQuery(
    {
      date: selectedDate,
      partnerId: partnerId!,
      type: "automated",
      prizePoolId: prizePoolId!,
    },
    {
      staleTime: 300_000, // 5 min
      enabled: partnerId > 0 && prizePoolId! > 0,
    }
  );

  const { data: { getCommonSport: sports } = {} } = useGetCommonSportQuery();

  useEffect(() => {
    const countObj = hubContestsCount?.countPartnerContests.reduce(
      (acc: any, curr) => {
        acc[curr.name] = curr.count;
        return acc;
      },
      {}
    );
    setContestsCount({ ...countObj });
  }, [hubContestsCount?.countPartnerContests]);

  useEffect(() => {
    if (sports) {
      const automatedSport = sports.filter(
        (sport) => sport.key === "SOCCER" || sport.key === "NBA"
      ); // "Temporary condition"
      // const automatedSport = sports.filter((sport) => sport.automated); // "Actual condition"
      setAutomatedSport(automatedSport);
    }
  }, [sports]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleDateChange = (selectedDate: dayjs.Dayjs | null) => {
    if (selectedDate) {
      setSelectedDate(selectedDate.format("MM-DD-YYYY"));
    }
  };
  return (
    <SignedInLayout title="Partner - Touchdown">
      <Box marginBottom={theme.spacing(3)}>
        <Card
          elevation={0}
          sx={{
            // border: "1px solid #EEE8DC",
            padding: theme.spacing(1),
          }}
        >
          <CardContent>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row">
                <FormControl variant="standard">
                  <StyledLabel htmlFor="SelectWeek">Select date</StyledLabel>
                  <WeekPicker
                    defaultValue={dayjs()}
                    handleDateChange={handleDateChange}
                  />
                </FormControl>
                <FormControl fullWidth sx={{ marginLeft: 1 }}>
                  <StyledLabel htmlFor="SelectWeek">Select Partner</StyledLabel>
                  <Stack
                    direction="row"
                    alignItems="center"
                    style={{ opacity: "0.6" }}
                  >
                    <Checkbox
                      checked={paidCheckbox}
                      disabled
                      style={{ color: "#A67A45" }}
                    />
                    <Typography>Paid Partner</Typography>
                    <Checkbox
                      disabled
                      checked={f2pCheckbox}
                      style={{ color: "#A67A45" }}
                    />
                    <Typography>F2P</Typography>
                  </Stack>
                </FormControl>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "background.paper",
            display: "flex",
            minHeight: 500,
          }}
        >
          <Tabs
            orientation="vertical"
            value={value}
            onChange={handleChange}
            sx={{
              borderRight: 1,
              borderRightColor: "#D6D6D6",
              minWidth: 130,
              "& .MuiTabs-flexContainer": {
                borderBottom: "none",
              },
            }}
          >
            <Tab label="Automated " {...a11yProps(0)} />
            <Tab label="Contest Hub" {...a11yProps(1)} />
          </Tabs>
          <MainTabPanel value={value} index={0}>
            <Typography variant="h5" fontWeight={500}>
              Automated
            </Typography>
            <Box>
              <TabContext value={selectedTab}>
                <Box>
                  <TabList
                    onChange={(_, value) => setSelectedTab(value)}
                    aria-label="lab API tabs example"
                    sx={{
                      minHeight: "auto",
                      "& .MuiTabs-flexContainer": {
                        borderBottom: "1px solid rgba(240, 240, 240, 1)",
                      },
                    }}
                  >
                    {automatedSport?.map((sport: any, i: number) => (
                      <Tab
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          padding: `${theme.spacing(2)} ${theme.spacing(
                            2
                          )} ${theme.spacing(2)} 0`,
                          minHeight: "auto",
                          justifyContent: "flex-start",
                          marginRight: theme.spacing(3),
                          fontSize: "16px",
                        }}
                        key={sport.key}
                        label={
                          <>
                            {sport.name}
                            <Badge
                              badgeContent={
                                sport.key
                                  ? contestsCount[sport.key] ?? "0"
                                  : "0"
                              }
                              sx={{
                                marginLeft: theme.spacing(2),
                                "& .MuiBadge-badge": {
                                  backgroundColor: "rgba(250, 242, 226, 1)",
                                  color: theme.palette.primary.main,
                                  fontSize: "16px",
                                },
                              }}
                            />
                          </>
                        }
                        value={sport.key}
                        icon={getSportIcon(
                          sport?.logoUrl,
                          sport?.name,
                          selectedTab
                        )}
                      />
                    ))}
                  </TabList>
                </Box>

                <TabPanel
                  value={SupportedSports.Nba}
                  sx={{
                    padding: `${theme.spacing(3)} 0 ${theme.spacing(3)} 0`,
                  }}
                >
                  <NbaContestCreation
                    key={SupportedSports.Nba}
                    selectedDate={selectedDate}
                    updateCount={setNbaContestsCount}
                    prizePool={prizePool}
                    prizePoolId={prizePoolId}
                  />
                </TabPanel>

                <TabPanel
                  value={SupportedSports.Nfl}
                  sx={{
                    padding: `${theme.spacing(3)} 0 ${theme.spacing(3)} 0`,
                  }}
                >
                  <NflContestCreation
                    key={SupportedSports.Nfl}
                    selectedDate={selectedDate}
                    updateCount={setNflContestsCount}
                    prizePool={prizePool}
                  />
                </TabPanel>
                <TabPanel
                  value={"SOCCER"}
                  sx={{
                    padding: `${theme.spacing(3)} 0 ${theme.spacing(3)} 0`,
                  }}
                >
                  <SoccerCreation
                    key={SupportedSports.Nfl}
                    selectedDate={selectedDate}
                    updateCount={setSoccerContestsCount}
                    prizePool={prizePool}
                    partnerId={partnerId}
                    prizePoolId={prizePoolId!}
                    touchdowns={[]}
                  />
                </TabPanel>
              </TabContext>
            </Box>
          </MainTabPanel>
          <MainTabPanel value={value} index={1}>
            <Typography variant="h5" fontWeight={500}>
              Contest Hub
            </Typography>
            <MatchUpsInTouchDown
              selectedDate={selectedDate}
              partnerId={partnerId}
              prizePoolId={prizePoolId}
              touchdowns={[]}
            />
          </MainTabPanel>
        </Box>
      </Box>
    </SignedInLayout>
  );
}
