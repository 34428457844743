import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { QueryKeys } from "@src/constants/QueryKeys";
import { useGradePartnerHubContestMutation } from "@src/graphql/graphql.generated";
import { parseGQLError } from "@src/utils/parse-error";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
const useStyles = makeStyles((theme) => ({
  required: {
    "&:after": {
      content: '" *"',
      color: "red",
    },
  },
}));

const StyledInput = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(1),
  "& input": {
    padding: "9px 12px 9px 12px",

    "&:focus": {
      outline: `none`,
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: `#E2D3BB !important`,
    "&:focus": {
      outline: `none`,
    },
  },
  "& .MuiOutlinedInput-root.Mui-error": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `#d32f2f !important`,
    },
  },
  "& .MuiFormHelperText-root.Mui-error": {
    fontSize: "12px",
    position: "absolute",
    width: "300px",
    top: "100%",
    margin: "5px 0",
  },
}));

const StyledLabel = styled("label")(({ theme }) => ({
  color: "#212121",
  fontSize: "14px",
  lineHeight: "20px",
}));

type ContestStatusType = "COMPLETED" | "CANCELLED";
type WinnerStatusType = "PLAYER1" | "PLAYER2" | "DRAW";

enum ContestStatus {
  COMPLETED = "Completed",
  CANCELLED = "Ruled Out",
}

export const GradeContestForm = ({ contestData, setTypeOfForm }: any) => {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    control,
    clearErrors,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const { enqueueSnackbar } = useSnackbar();

  const player1Points = watch("player1Points");
  const player2Points = watch("player2Points");

  const {
    id: contestId,
    player1FullName,
    player2FullName,
    questionId,
    sportId,
    player1BonusPoints,
    player2BonusPoints,
  } = contestData;
  const { mutate: mutateGradeContest } = useGradePartnerHubContestMutation();
  const queryClient = useQueryClient();

  const WinnerStatus = {
    PLAYER1: player1FullName,
    PLAYER2: player2FullName,
    DRAW: "Tie",
  };

  useEffect(() => {
    if (
      !player1Points ||
      !player2Points ||
      Number.isNaN(+player1Points) ||
      Number.isNaN(+player2Points)
    ) {
      return;
    }
    const contestStatus = getValues("contestStatus");

    if (contestStatus === "CANCELLED") {
      setValue("contestWinner", "DRAW");
      return;
    }

    const player1FantasyPoints = +player1Points + player1BonusPoints;
    const player2FantasyPoints = +player2Points + player2BonusPoints;

    const { winCriteria } = sportId;
    if (player1FantasyPoints === player2FantasyPoints) {
      setValue("contestWinner", "DRAW");
    } else if (winCriteria === "HIGHER") {
      setValue(
        "contestWinner",
        player1FantasyPoints > player2FantasyPoints ? "PLAYER1" : "PLAYER2"
      );
    } else if (winCriteria === "LOWER") {
      setValue(
        "contestWinner",
        player1FantasyPoints < player2FantasyPoints ? "PLAYER1" : "PLAYER2"
      );
    }
    clearErrors("contestWinner");
  }, [player1Points, player2Points, setValue]);

  const onSubmit = (data: any) => {
    const { player1Points, player2Points, contestWinner, contestStatus } = data;

    if (Number.isNaN(+player1Points) || Number.isNaN(+player2Points)) {
      enqueueSnackbar("Please enter valid player points.", {
        variant: "error",
      });
      return;
    }

    if (
      contestStatus === "CANCELLED" &&
      player1Points > 0 &&
      player2Points > 0
    ) {
      enqueueSnackbar(
        "Contest Cancelled: Both players cannot have positive points. Please enter 0 (zero) points for the ruled out player.",
        {
          variant: "error",
        }
      );
      return;
    }

    mutateGradeContest(
      {
        data: {
          id: contestId,
          player1FantasyPoints: parseFloat(player1Points),
          player2FantasyPoints: parseFloat(player2Points),
          winnerLabel: contestWinner,
          status: contestStatus,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(QueryKeys.FetchPartnerHubContests);
          enqueueSnackbar("Contest graded successfully.", {
            variant: "success",
          });
          setTypeOfForm("create");
        },
        onError: (error: any) => {
          enqueueSnackbar(
            parseGQLError(error, "Error while grading contest."),
            {
              variant: "error",
            }
          );
        },
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid sx={{ paddingBottom: "16px" }}>
        <Stack spacing={2}>
          <Box sx={{ fontWeight: 500, fontSize: "17px", lineHeight: "22px" }}>
            {player1FullName} Vs {player2FullName}
          </Box>
          <FormControl>
            <StyledLabel className={classes.required} htmlFor="CONTEST STATUS">
              Contest status
            </StyledLabel>
            <Controller
              name="contestStatus"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <>
                  <Select
                    sx={{
                      height: "40px",
                      marginTop: "7px",
                      background: "#fff",
                    }}
                    {...field}
                    id="contentStatus"
                    renderValue={(value) => {
                      const selectedValue: ContestStatusType = value;
                      return (
                        ContestStatus[selectedValue] ?? "Select contest status"
                      );
                    }}
                    displayEmpty
                  >
                    {Object.entries(ContestStatus).map(
                      ([value, label], index) => (
                        <MenuItem key={index + 1} value={value}>
                          {label}
                        </MenuItem>
                      )
                    )}
                  </Select>

                  {errors.contestStatus && (
                    <Typography sx={{ fontSize: "12px" }} color="error">
                      Contest Status required.
                    </Typography>
                  )}
                </>
              )}
            />
          </FormControl>
          <FormControl>
            <StyledLabel className={classes.required} htmlFor="player 1 ">
              {player1FullName} {questionId?.metric}
            </StyledLabel>
            <StyledInput
              sx={{ background: "#fff" }}
              id="player1Points"
              type="string"
              required
              {...register("player1Points", {
                required: true,
                validate: (value) => value.trim() !== "",
              })}
              placeholder="Enter points"
            />
            {(errors.player1Points ||
              errors.player1Points?.type === "validate") && (
              <Typography sx={{ fontSize: "12px" }} color="error">
                Please fill this field
              </Typography>
            )}
          </FormControl>

          <FormControl>
            <StyledLabel className={classes.required} htmlFor="player 2">
              {player2FullName} {questionId?.metric}
            </StyledLabel>
            <StyledInput
              sx={{ background: "#fff" }}
              id="player2Points"
              required
              {...register("player2Points", {
                required: true,
                validate: (value) => value.trim() !== "",
              })}
              placeholder="Enter points"
            />
            {(errors.player2Points ||
              errors.player2Points?.type === "validate") && (
              <Typography sx={{ fontSize: "12px" }} color="error">
                Please fill this field
              </Typography>
            )}
          </FormControl>
          <FormControl>
            <StyledLabel className={classes.required} htmlFor="CONTEST WINNER">
              Select winner
            </StyledLabel>
            <Controller
              name="contestWinner"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <>
                  <Select
                    sx={{
                      height: "40px",
                      marginTop: "7px",
                      background: "#fff",
                    }}
                    {...field}
                    id="contestWinner"
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    renderValue={(value) => {
                      const selectedValue: WinnerStatusType = value;
                      return WinnerStatus[selectedValue] ?? "Select winner";
                    }}
                    displayEmpty
                  >
                    {Object.entries(WinnerStatus).map(
                      ([value, label], index) => (
                        <MenuItem key={index + 1} value={value}>
                          {label}
                        </MenuItem>
                      )
                    )}
                  </Select>
                  {errors.contestWinner && (
                    <Typography sx={{ fontSize: "12px" }} color="error">
                      Please fill this field
                    </Typography>
                  )}
                </>
              )}
            />
          </FormControl>
          <Button
            variant="contained"
            size="large"
            type="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Confirm
          </Button>
        </Stack>
      </Grid>
    </form>
  );
};
