import { useQuery } from "react-query";
import { QueryKeys } from "@src/constants/QueryKeys";
import { getUserPermissions, IUserPermissions } from "@src/services/auth";

export function usePermissions() {
  return useQuery<IUserPermissions, Error>(
    QueryKeys.Permissions,
    getUserPermissions,
    {
      staleTime: 5 * 60 * 1000, // 5 min
    }
  );
}
