import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Badge,
  Box,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  FormControl,
  Grid,
  Tab,
  Typography,
  styled,
} from "@mui/material";
import { useEffect, useState } from "react";

import WeekPicker from "@organisms/Pickers/WeekPicker";
import { SignedInLayout } from "@src/components/templates/Layouts";
import { theme } from "@src/constants/theme";
import {
  useCountHubContestsQuery,
  useGetSportsQuery,
} from "@src/graphql/graphql.generated";
import dayjs from "dayjs";
import { PlayersDashboard } from "./PlayersDashboard";

const StyledLabel = styled("label")(({ theme }) => ({
  color: "#212121",
  fontSize: "14px",
  lineHeight: "20px",
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)} 0`,
  minHeight: "auto",
  justifyContent: "flex-start",
  marginRight: theme.spacing(3),
  fontSize: "16px",
}));

const StyledTabList = styled(TabList)((theme) => ({
  position: "relative",
  "&::after": {
    content: '""',
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    height: "1px",
    backgroundColor: "#E0E0E0",
    zIndex: 1,
  },
  "& .MuiTabs-indicator": {
    height: "3px",
  },
}));

const getSportIcon = (url: string, name: string, selectedTab: string) => {
  return (
    <CardMedia
      sx={{
        marginRight: theme.spacing(1),
        marginBottom: "0 !important",
        fontSize: "18px",
        filter: name !== selectedTab ? "grayscale(100%)" : "",
        height:
          url ===
          "https://topprop-static-assets.s3.amazonaws.com/sports/pga.svg"
            ? "23px"
            : "18px",
        width: "18px",
      }}
      component="img"
      alt="image"
      image={url}
    />
  );
};

export const MatchUpsInTouchDown = () => {
  const [pickedDate, setPickedDate] = useState<string>(
    dayjs().format("MM-DD-YYYY")
  );
  const [contestsCount, setContestsCount] = useState<any>({});

  const {
    data: hubContestSportsList,
    isLoading: isSportsListLoading,
    isError: isSportListError,
    isFetching: isSportsFetching,
  } = useGetSportsQuery();
  const { data: hubContestsCount } = useCountHubContestsQuery({
    date: pickedDate,
  });

  const [availableSports, setAvailableSports] = useState<Array<any>>([]);
  const [selectedTab, setSelectedTab] = useState<string>("");

  useEffect(() => {
    if (
      hubContestSportsList?.getSports?.length &&
      hubContestsCount?.countHubContests
    ) {
      const sports = hubContestSportsList.getSports.filter((sport) => {
        const { enabled, id } = sport;
        if (enabled) {
          return sport;
        } else {
          const contest = hubContestsCount.countHubContests.find(
            ({ sportId }) => sportId === id
          );
          if (contest && +contest?.contestCount > 0) return sport;
        }
      });
      setAvailableSports([...sports]);
      setSelectedTab(sports[0]?.name!);
    }
  }, [hubContestSportsList, setSelectedTab, hubContestsCount]);

  useEffect(() => {
    const countObj = hubContestsCount?.countHubContests.reduce(
      (acc: any, curr) => {
        acc[curr.sportId] = curr.contestCount;
        return acc;
      },
      {}
    );
    setContestsCount({ ...countObj });
  }, [hubContestsCount]);

  const handleDateChange = (selectedDate: dayjs.Dayjs | null) => {
    if (selectedDate) {
      setPickedDate(selectedDate.format("MM-DD-YYYY"));
    }
  };

  return (
    <SignedInLayout title="Contest hub">
      <Box marginBottom={theme.spacing(3)}>
        <Card
          elevation={0}
          sx={{
            border: "1px solid #EEE8DC",
            padding: theme.spacing(1),
            marginBottom: theme.spacing(3),
          }}
        >
          <CardContent>
            <Grid item xs={3}>
              <FormControl variant="standard">
                <StyledLabel sx={{ paddingBottom: "4px" }} htmlFor="SelectWeek">
                  Select date
                </StyledLabel>
                <WeekPicker
                  handleDateChange={handleDateChange}
                  defaultValue={dayjs()}
                />
              </FormControl>
            </Grid>
            <Box>
              <Box marginTop={theme.spacing(3)}>
                <TabContext value={selectedTab}>
                  {isSportsListLoading ||
                  isSportListError ||
                  isSportsFetching ? (
                    <Box py={2} sx={{ fontSize: "20px", textAlign: "center" }}>
                      Loading...
                      <CircularProgress size={24} />
                    </Box>
                  ) : (
                    <>
                      {availableSports?.length === 0 ? (
                        <Box
                          sx={{
                            marginTop: theme.spacing(1),
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            border: "1px solid #EEE8DC",
                            backgroundColor: "#fff",
                            padding: `${theme.spacing(14)} ${theme.spacing(2)}`,
                          }}
                        >
                          <EmojiEventsOutlinedIcon
                            sx={{
                              fontSize: "60px",
                              color: "#EEE8DC",
                            }}
                          />
                          <Typography>No Sport Created</Typography>
                        </Box>
                      ) : (
                        <>
                          <Box>
                            <StyledTabList
                              onChange={(_, value) => setSelectedTab(value)}
                              aria-label={"sports"}
                              variant="scrollable"
                              scrollButtons={false}
                              sx={{
                                minHeight: "auto",
                                "& .MuiTabs-flexContainer": {
                                  borderBottom:
                                    "1px solid rgba(240, 240, 240, 1)",
                                },
                              }}
                            >
                              {availableSports?.map((sport, index) => (
                                <StyledTab
                                  key={index + 1}
                                  label={
                                    <>
                                      {sport?.name}
                                      <Badge
                                        badgeContent={`${
                                          contestsCount[sport.id] || 0
                                        }`}
                                        sx={{
                                          marginLeft: theme.spacing(2),
                                          "& .MuiBadge-badge": {
                                            backgroundColor:
                                              sport?.name !== selectedTab
                                                ? "#F0F0F0"
                                                : "rgba(250, 242, 226, 1)",

                                            color:
                                              sport?.name !== selectedTab
                                                ? "#8C8C8C"
                                                : theme.palette.primary.main,
                                            fontSize: "16px",
                                          },
                                        }}
                                      ></Badge>
                                    </>
                                  }
                                  value={sport?.name}
                                  icon={getSportIcon(
                                    sport?.logoUrl,
                                    sport?.name,
                                    selectedTab
                                  )}
                                />
                              ))}
                            </StyledTabList>
                          </Box>

                          {availableSports.map((sport, index) => {
                            return (
                              <TabPanel
                                key={index}
                                value={sport?.name}
                                sx={{
                                  padding: `${theme.spacing(
                                    3
                                  )} 0 ${theme.spacing(3)} 0`,
                                }}
                              >
                                <PlayersDashboard
                                  key={sport?.name}
                                  gameData={sport}
                                  selectedDate={pickedDate}
                                  updateContestCount={(
                                    sportId: any,
                                    increase: boolean = true
                                  ) => {
                                    if (contestsCount[sportId]) {
                                      contestsCount[sportId] = increase
                                        ? +contestsCount[sportId] + 1
                                        : +contestsCount[sportId] - 1;
                                    } else {
                                      contestsCount[sportId] = 1;
                                    }
                                    setContestsCount({ ...contestsCount });
                                  }}
                                />
                              </TabPanel>
                            );
                          })}
                        </>
                      )}
                    </>
                  )}
                </TabContext>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </SignedInLayout>
  );
};
