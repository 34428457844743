import { useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import {
  DataGrid,
  GridColumns,
  GridComparatorFn,
  GridFilterModel,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import { SignedInWithSidebarLayout } from "@src/components/templates/Layouts";
import {
  AuditActionFliterType,
  useImportTournamentsMutation,
  useTournamentsQuery,
  useUpdateTournamentsMutation,
} from "@src/graphql/graphql.generated";
import { GQLError, parseGQLError } from "@src/utils/parse-error";
import { dateRangeOperators } from "@organisms/DataGridFilters/DateRange/dateRangeOperators";
import { AuditLogsModal } from "@src/components/templates/AuditLogsModal";

export function Tournaments() {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const {
    data: tournaments,
    isLoading: isLoadingTournaments,
    isSuccess: hasLoadedTournaments,
    error,
    isError,
  } = useTournamentsQuery(undefined, {
    staleTime: 300_000, // 5 min
  });
  const { mutate: update, isLoading: isUpdating } =
    useUpdateTournamentsMutation();
  const { mutate: importTournaments, isLoading: isImporting } =
    useImportTournamentsMutation();

  const tournamentsQueryKey = useTournamentsQuery.getKey();
  const [shouldRebuildSelection, setRebuildSelection] = useState(true);
  const [selectedTournaments, setSelectedTournaments] = useState<Array<number>>(
    []
  );
  const [isImportOpen, setImportOpen] = useState(false);
  const [isFilterDate, setIsFilterDate] = useState(false);
  const [showAuditTrail, setShowAuditTrail] = useState(false);

  const onFilterChange = (model: GridFilterModel) => {
    const { columnField } = model.items[0];
    if (columnField === "startDate" || columnField === "endDate") {
      setIsFilterDate(true);
      return;
    }
    setIsFilterDate(false);
  };

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: "startDate",
        headerName: "Start Date",
        width: 200,
        valueFormatter: (params) =>
          dayjs(params.value as string).format("MMM D, YY, H:ma"),
        sortComparator: dateComparator,
        filterOperators: dateRangeOperators,
      },
      {
        field: "endDate",
        headerName: "End Date",
        width: 200,
        valueFormatter: (params) =>
          dayjs(params.value as string).format("MMM D, YY, H:ma"),
        sortComparator: dateComparator,
        filterOperators: dateRangeOperators,
      },
      { field: "name", headerName: "Name", flex: 1 },
      { field: "purse", headerName: "Purse", width: 150 },
    ],
    []
  );

  const handleRevert = () => {
    setSelectedTournaments(
      (tournaments?.tournaments ?? [])
        .filter((t) => t.isActive)
        .map((t) => t.id)
    );
    setRebuildSelection(false);
  };

  const handleCloseAudit = () => {
    setShowAuditTrail(false);
  };

  const handleSave = () => {
    update(
      {
        data: {
          tournaments: (tournaments?.tournaments ?? []).map((tournament) => ({
            id: tournament.id,
            isActive: selectedTournaments.includes(tournament.id),
          })),
        },
      },
      {
        onError: (err: any) => {
          enqueueSnackbar(parseGQLError(err, "Unable to save"), {
            variant: "error",
          });
        },
        onSuccess: () => {
          queryClient.invalidateQueries(tournamentsQueryKey);
          enqueueSnackbar("Saved", {
            variant: "success",
          });
        },
      }
    );
  };

  const handleImport = (data: any) => {
    importTournaments(
      {
        data,
      },
      {
        onError: (err: any) => {
          enqueueSnackbar("Import failed", {
            variant: "error",
          });
        },
        onSuccess: () => {
          queryClient.invalidateQueries(tournamentsQueryKey).then(() => {
            setRebuildSelection(true);
          });
          setImportOpen(false);
          enqueueSnackbar("Import was a success!", {
            variant: "success",
          });
        },
      }
    );
  };

  useEffect(() => {
    if (
      shouldRebuildSelection &&
      hasLoadedTournaments &&
      tournaments?.tournaments
    ) {
      handleRevert();
    }
  }, [shouldRebuildSelection, hasLoadedTournaments, tournaments]);

  return (
    <SignedInWithSidebarLayout
      title="PGA Tournaments"
      SidebarComponent={
        <Controls
          isEnabled={hasLoadedTournaments && !isUpdating}
          onRevert={handleRevert}
          onSave={handleSave}
          onStartImport={() => setImportOpen(true)}
          setShowAuditTrail={setShowAuditTrail}
        />
      }
    >
      {isLoadingTournaments && !isError && (
        <Box py={2}>
          Loading...
          <CircularProgress size={16} />
        </Box>
      )}
      {isError && (
        <Typography color="error">
          {parseGQLError(error as GQLError)}
        </Typography>
      )}
      {!isLoadingTournaments && !isError && (
        <DataGrid
          checkboxSelection
          columns={columns}
          initialState={{
            sorting: {
              sortModel: [{ field: "startDate", sort: "asc" }],
            },
          }}
          rows={tournaments?.tournaments ?? []}
          onSelectionModelChange={(idArr) =>
            setSelectedTournaments(idArr as Array<number>)
          }
          selectionModel={selectedTournaments}
          componentsProps={{
            panel: {
              sx: {
                "& .MuiDataGrid-filterForm": {
                  width: isFilterDate ? 600 : 500,
                },
              },
            },
          }}
          onFilterModelChange={onFilterChange}
        />
      )}
      <ImportDialog
        isEnabled={!isImporting}
        isOpen={isImportOpen}
        onClose={() => setImportOpen(false)}
        onSave={handleImport}
      />
      <AuditLogsModal
        title="PGA Tournaments Audit"
        isOpen={showAuditTrail}
        onClose={handleCloseAudit}
        actions={[AuditActionFliterType.ImportTournaments]}
      />
    </SignedInWithSidebarLayout>
  );
}

interface ImportDialogProps {
  isEnabled: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSave: (data: any) => void;
}

function ImportDialog({
  isEnabled,
  isOpen,
  onClose,
  onSave,
}: ImportDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState("");

  const handleSave = () => {
    try {
      const items = JSON.parse(data); // test for json validity
      if (!Array.isArray(items)) {
        throw new Error("invalid json");
      }
      onSave(items);
    } catch (_ex) {
      enqueueSnackbar("Invalid JSON", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setData("");
    }
  }, [isOpen]);

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClose={onClose}>
      <DialogTitle>Import Tournaments</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Paste Fanjection JSON data from this endpoint:
          <br />
          https://www.fanjections.com/api/projections/golf/production/v1/tournaments/2022/PGA/all.json?api_key=YOUR_API_KEY
        </DialogContentText>
        <TextField
          autoFocus
          fullWidth
          label="Tournaments JSON"
          margin="dense"
          multiline
          onChange={(ev) => setData(ev.target.value)}
          rows={10}
          value={data}
          variant="filled"
        />
      </DialogContent>
      <DialogActions sx={{ p: 2, pr: 3 }}>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button disabled={!isEnabled} onClick={handleSave} variant="contained">
          Import
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface ControlsProps {
  isEnabled: boolean;
  onRevert: () => void;
  onSave: () => void;
  onStartImport: () => void;
  setShowAuditTrail: (value: boolean) => void;
}

function Controls({
  isEnabled,
  onRevert,
  onSave,
  onStartImport,
  setShowAuditTrail,
}: ControlsProps) {
  return (
    <Stack spacing={1}>
      <Button disabled={!isEnabled} onClick={onSave} variant="contained">
        Save
      </Button>
      <Button
        disabled={!isEnabled}
        onClick={onStartImport}
        startIcon={<UploadIcon />}
        variant="outlined"
      >
        Import
      </Button>
      <Button disabled={!isEnabled} onClick={onRevert} variant="outlined">
        Revert
      </Button>
      <Button onClick={() => setShowAuditTrail(true)} variant="outlined">
        Audit Trail
      </Button>
    </Stack>
  );
}

const dateComparator: GridComparatorFn = (isoDate1, isoDate2): 1 | -1 => {
  return dayjs(isoDate1 as string).isBefore(dayjs(isoDate2 as string)) ? 1 : -1;
};
