export const EST_TIME_ZONE = "America/New_York";
export const MM_DD_YYYY = "MM-DD-YYYY";
export const YYYY_MM_DD = "YYYY-MM-DD";

export enum TOUCHDOWN_CONTEST_STATUSES {
  "UPCOMING" = "UPCOMING",
  "STARTED" = "STARTED",
  "CANCELLED" = "CANCELLED",
  "DELETED" = "DELETED",
}

export const weeklyPrizeDistributionLabels: any = {
  1: "1st",
  2: "2nd",
  3: "3rd",
  4: "4th",
  5: "5th",
  6: "6th",
  7: "7th",
  8: "8th",
  9: "9th",
  10: "10th",
};

export const NFL_IGNORED_SLOT = {
  startDay: 1,
  startHour: 5,
  startMinute: 30,
  endDay: 2,
  endHour: 5,
  endMinute: 0,
};
