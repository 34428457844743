import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { Role } from "@src/graphql/graphql.generated";
import {
  useCreateEditRoleMutation,
  useGetAllPermissionsQuery,
} from "@src/graphql/graphql.generated";
import { parseGQLError } from "@src/utils/parse-error";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  Stack,
  TextField,
  Box,
} from "@mui/material";
import LabelIcon from "@mui/icons-material/Label";
import { AsyncCheckboxGroupInput } from "@organisms/AsyncCheckboxGroupInput";
import { PermissionId } from "@src/constants/permissions";

interface CreateEditDialogProps {
  isCreate: boolean;
  isEdit: boolean;
  target: Role | null;
  onClose: (refresh?: boolean) => void;
}

export function CreateEditDialog({
  isCreate,
  isEdit,
  target,
  onClose,
}: CreateEditDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: create, isLoading } = useCreateEditRoleMutation();
  const [name, setName] = useState("");
  const { data: permissions, isLoading: isPermissionsLoading } =
    useGetAllPermissionsQuery();
  const [selectedPermissions, setSelectedPermissions] = useState<
    Array<PermissionId>
  >([]);

  useEffect(() => {
    if (target) {
      setName(target.name);
      setSelectedPermissions(target.permissions);
    }
  }, [target]);

  const handleChangePermissions = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedTarget = event.target;
    if (selectedTarget.checked) {
      setSelectedPermissions([
        ...selectedPermissions,
        selectedTarget.name as PermissionId,
      ]);
    } else {
      setSelectedPermissions(
        selectedPermissions.filter(
          (permission) => permission !== selectedTarget.name
        )
      );
    }
  };

  const handleClose = () => {
    setName("");
    setSelectedPermissions([]);
    onClose();
  };

  const handleCreate = () => {
    create(
      { data: { name, permissions: selectedPermissions, roleId: target?.id } },
      {
        onSuccess: (data) => {
          enqueueSnackbar(
            `Role has been ${isCreate ? "created" : "edited"} successfully!`,
            {
              variant: "success",
            }
          );
          onClose(true);
        },
        onError: (error: any) => {
          enqueueSnackbar(
            parseGQLError(error, `Unable to ${isCreate ? "creat" : "edit"}`),
            {
              variant: "error",
            }
          );
        },
      }
    );
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isCreate || isEdit}
      onClose={handleClose}
    >
      <DialogTitle>{isCreate ? "Create" : "Edit"} Role</DialogTitle>
      <DialogContent>
        <Box component="main" mt={2}>
          <Stack spacing={2} flex="1">
            <FormControl>
              <TextField
                label="Name"
                required
                InputProps={{
                  "aria-label": "name-input",
                  startAdornment: (
                    <InputAdornment position="start">
                      <LabelIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(ev) => setName(ev.target.value)}
                value={name}
              />
            </FormControl>
            <AsyncCheckboxGroupInput
              header="Permissions"
              options={
                permissions
                  ? permissions.getAllPermissions.map((permission) => ({
                      key: permission,
                      label: permission,
                    }))
                  : []
              }
              isLoading={isPermissionsLoading}
              selected={selectedPermissions}
              handleChange={handleChangePermissions}
            />
            <Button
              variant="contained"
              onClick={handleCreate}
              disabled={!name || selectedPermissions.length < 1 || isLoading}
            >
              {isCreate ? "Create" : "Edit"}
            </Button>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
