import WestIcon from "@mui/icons-material/West";
import { Box, Typography, styled } from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { theme } from "@src/constants/theme";
import { useGetF2PDailyLeaderBoardQuery } from "@src/graphql/graphql.generated";
import { PartnerDailyLeaderboardProps } from "@src/types/partner/dashboard";
import { useMemo } from "react";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: "1px solid #EEE8DC",
  "& .MuiDataGrid-cell": {
    borderBottom: "1px solid #EEE8DC",
  },
  "& .MuiDataGrid-columnHeaders": {
    borderBottom: "1px solid #EEE8DC",
  },
  "& .MuiDataGrid-footerContainer": {
    borderTop: "1px solid #EEE8DC",
  },
}));

export function DailyLeaderboard(props: PartnerDailyLeaderboardProps) {
  const { prizePoolId, setShowPartnerDailyLeaderBoard } = props;

  const { data: partnerDailyLeaderBoard } = useGetF2PDailyLeaderBoardQuery({
    prizePoolId: prizePoolId,
  });

  const COLUMNS: GridColumns = useMemo<GridColumns>(
    () => [
      {
        field: "leaderBoardRank",
        headerName: "Rank",
        width: 200,
        renderCell: (params) => (
          <>
            &nbsp; &nbsp;
            <span style={{ fontSize: "16px" }}>{params.value}</span>
            &nbsp; &nbsp;
            {params.row.rank === 1 && params.row.score > 0 ? (
              <span
                style={{
                  color: "white",
                }}
              >
                {params.row.score === 7 ? (
                  <>
                    <span
                      style={{
                        background: "#146D34",
                        padding: "4px 6px",
                        borderRadius: "20px",
                        marginInlineEnd: "5px",
                      }}
                    >
                      Jackpot
                    </span>
                    <span
                      style={{
                        background: "#B69056",
                        padding: "4px 6px",
                        borderRadius: "20px",
                      }}
                    >
                      GPP
                    </span>
                  </>
                ) : (
                  <span
                    style={{
                      background: "#B69056",
                      padding: "4px 6px",
                      borderRadius: "20px",
                    }}
                  >
                    GPP
                  </span>
                )}
              </span>
            ) : (
              ""
            )}
          </>
        ),
      },
      {
        field: "winAmount",
        headerName: "Winning Amount",
        width: 200,
        renderCell: (params) => (
          <span>
            {params.value > 0 ? (
              <> ${parseFloat(params.value).toFixed(2)}</>
            ) : (
              "-"
            )}
          </span>
        ),
      },

      { field: "name", headerName: "User Name", width: 300 },
      { field: "userId", headerName: "User ID", width: 250 },
      { field: "score", headerName: "Points", width: 250 },
    ],
    []
  );

  return (
    <>
      <Box marginBottom={theme.spacing(3)}>
        <Typography
          style={{
            display: "flex",
            alignItems: "center",
            color: "#212121",
            textDecoration: "none",
            cursor: "pointer",
          }}
          onClick={() => {
            setShowPartnerDailyLeaderBoard(false);
          }}
        >
          <WestIcon
            style={{ fontSize: "16px", marginRight: theme.spacing(1) }}
          />{" "}
          Back
        </Typography>
      </Box>
      <Box marginBottom={theme.spacing(3)}>
        <Typography
          variant="h5"
          fontWeight={500}
          marginBottom={theme.spacing(3)}
        >
          {`Daily Leaderboard`}
        </Typography>
      </Box>
      <Box
        style={{ height: "550px", overflow: "auto" }}
        marginBottom={theme.spacing(3)}
      >
        <StyledDataGrid
          columns={COLUMNS}
          initialState={{
            sorting: {
              sortModel: [{ field: "startDate", sort: "asc" }],
            },
          }}
          rows={partnerDailyLeaderBoard?.getF2PDailyLeaderBoard?.entries || []}
          getRowId={(row) => row.entryId}
          pageSize={10}
          pagination
        />
      </Box>
    </>
  );
}
