import { atom } from "recoil";
enum StateKeys {
  Step = "CreateContest.Step",
  MainPlayer = "CreateContest.Mainplayer",
  OpponentPlayer = "CreateContest.OpponentPlayer",
  TournamentType = "Battleground.TournamentType",
  TournamentName = "Battleground.TournamentName",
  TournamentId = "Battleground.TournamentId",
  Round = "Battleground.Round",
  SuggestionId = "Battleground.SuggestionId",
  SuggestionStep = "Battleground.SuggestionStep",
}

export enum TournamentType {
  FullTournament = "fulltournament",
  RoundByRound = "roundbyround",
}
export type TournamentTypes =
  | TournamentType.RoundByRound
  | TournamentType.FullTournament;

export enum CreateContestStep {
  SelectMainPlayer = "SelectMainPlayer",
  SelectOpponents = "SelectOpponents",
}
export const contestCreationStepState = atom<CreateContestStep>({
  key: StateKeys.Step,
  default: CreateContestStep.SelectMainPlayer,
});
export const mainPlayerIdState = atom<string | null>({
  key: StateKeys.MainPlayer,
  default: null,
});
export const tournamentTypeState = atom<TournamentTypes>({
  key: StateKeys.TournamentType,
  default: TournamentType.FullTournament,
});
export const opponentPlayerIdsState = atom<Array<string | null>>({
  key: StateKeys.OpponentPlayer,
  default: [],
});
export const tournamentNameState = atom<string | undefined>({
  key: StateKeys.TournamentName,
  default: "",
});
export const tournamentIdState = atom<string>({
  key: StateKeys.TournamentId,
  default: "",
});
export const roundState = atom<string | undefined>({
  key: StateKeys.Round,
  default: "1",
});
export const suggestionIdState = atom<number | null>({
  key: StateKeys.SuggestionId,
  default: null,
});
export const suggestionStepState = atom<boolean>({
  key: StateKeys.SuggestionStep,
  default: false,
});
