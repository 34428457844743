import styled from "@emotion/styled";
import { Box, Menu, MenuProps } from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { SignedInLayout } from "@src/components/templates/Layouts";
import { theme } from "@src/constants/theme";
import { usePartner } from "@src/context/PartnerContext";
import {
  useGetF2PPartnerUsersQuery,
  User,
} from "@src/graphql/graphql.generated";
import { useMemo } from "react";

export interface UserAction {
  target: User | null;
  status: "edit" | "delete";
}
const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    minWidth: 180,
    color: "rgb(55, 65, 81)",
    boxShadow: "rgb(0 0 0 / 20%) 0px 0px 1px 0px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
      },
    },
  },
}));

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: "1px solid #EEE8DC !important",
  "& .MuiDataGrid-cell": {
    borderBottom: "1px solid #EEE8DC",
  },
  "& .MuiDataGrid-columnHeaders": {
    borderBottom: "1px solid #EEE8DC",
  },
  "& .MuiDataGrid-footerContainer": {
    borderTop: "1px solid #EEE8DC",
  },
}));

export function PartnerUsers() {
  const { partnerId }: any = usePartner();

  const {
    data: partnerUsers,
    isLoading,
    refetch,
  } = useGetF2PPartnerUsersQuery(
    {
      partnerId: partnerId!,
    },
    {
      staleTime: 300_000, // 5 min
      enabled: partnerId !== undefined && partnerId !== null,
    }
  );

  const USER_LIST_COLUMN = useMemo<GridColumns>(
    () => [
      {
        field: "firstName",
        headerName: "Name",
        width: 250,
        renderCell: (params) =>
          `${params?.row?.firstName} ${params?.row?.lastName}`,
      },
      { field: "email", headerName: "Email ID", width: 350 },
      { field: "gender", headerName: "Gender", width: 250 },
      { field: "userName", headerName: "userName", width: 250 },
    ],
    []
  );

  return (
    <SignedInLayout title="Users" showF2pPartners>
      <div>
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <Box
            style={{ height: "475px", overflow: "auto" }}
            marginBottom={theme.spacing(3)}
          >
            <StyledDataGrid
              columns={USER_LIST_COLUMN}
              initialState={{
                sorting: {
                  sortModel: [{ field: "startDate", sort: "asc" }],
                },
              }}
              getRowId={(row) => row?.id}
              rows={partnerUsers?.getF2PPartnerUsers ?? []}
              pageSize={7}
            />
          </Box>
        )}
      </div>
    </SignedInLayout>
  );
}
