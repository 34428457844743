import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Badge,
  Box,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Tab,
  Typography,
  styled,
} from "@mui/material";
import { useEffect, useState } from "react";

import { theme } from "@src/constants/theme";
import {
  useCountPartnerContestsQuery,
  useGetPartnerSportsQuery,
} from "@src/graphql/graphql.generated";
import { PlayersDashboard } from "./ContestHub/PlayersDashboard";

const StyledTab = styled(Tab)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)} 0`,
  minHeight: "auto",
  justifyContent: "flex-start",
  marginRight: theme.spacing(3),
  fontSize: "16px",
}));

const StyledTabList = styled(TabList)((theme) => ({
  position: "relative",
  "&::after": {
    content: '""',
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    height: "1px",
    backgroundColor: "#E0E0E0",
    zIndex: 1,
  },
  "& .MuiTabs-indicator": {
    height: "3px",
  },
}));

const getSportIcon = (url: string, name: string, selectedTab: string) => {
  return (
    <CardMedia
      sx={{
        marginRight: theme.spacing(1),
        marginBottom: "0 !important",
        fontSize: "18px",
        filter: name !== selectedTab ? "grayscale(100%)" : "",
        height:
          url ===
          "https://topprop-static-assets.s3.amazonaws.com/sports/pga.svg"
            ? "23px"
            : "18px",
        width: "18px",
      }}
      component="img"
      alt="image"
      image={url}
    />
  );
};

export const MatchUpsInTouchDown = ({
  partnerId,
  selectedDate: pickedDate,
  prizePoolId,
  touchdowns,
}: any) => {
  const [contestsCount, setContestsCount] = useState<any>({});
  const {
    data: { getPartnerSports: hubContestSportsList } = {},
    isLoading: isSportsListLoading,
    isError: isSportListError,
    isFetching: isSportsFetching,
  } = useGetPartnerSportsQuery({ partnerId: partnerId!, type: "manual" });
  const { data: hubContestsCount } = useCountPartnerContestsQuery(
    {
      date: pickedDate,
      partnerId: partnerId,
      type: "manual",
      prizePoolId: prizePoolId,
    },
    {
      staleTime: 300_000, // 5 min
      enabled: partnerId > 0,
    }
  );

  const [availableSports, setAvailableSports] = useState<Array<any>>([]);
  const [selectedTab, setSelectedTab] = useState<string>("");

  useEffect(() => {
    if (
      hubContestSportsList?.length &&
      hubContestsCount?.countPartnerContests
    ) {
      const sports = hubContestSportsList?.filter((sport) => {
        if (!sport.commonSportId) {
          const { enabled, id: sportId } = sport;
          if (enabled) {
            return sport;
          } else {
            const contest = hubContestsCount?.countPartnerContests.find(
              ({ id }) => id === sportId
            );
            if (contest && +contest?.count > 0) return sport;
          }
        }
      });
      setAvailableSports([...sports]);
      setSelectedTab(sports?.[0]?.name!);
    }
  }, [hubContestSportsList, setSelectedTab]);

  useEffect(() => {
    const countObj = hubContestsCount?.countPartnerContests.reduce(
      (acc: any, curr) => {
        acc[curr?.id!] = curr.count;
        return acc;
      },
      {}
    );
    setContestsCount({ ...countObj });
  }, [hubContestsCount]);

  return (
    <Box>
      <Card
        elevation={0}
        sx={{
          marginBottom: theme.spacing(3),
        }}
      >
        <CardContent sx={{ p: 0 }}>
          <Box>
            <Box>
              <TabContext value={selectedTab}>
                {isSportsListLoading || isSportListError || isSportsFetching ? (
                  <Box py={2} sx={{ fontSize: "20px", textAlign: "center" }}>
                    Loading...
                    <CircularProgress size={24} />
                  </Box>
                ) : (
                  <>
                    {availableSports?.length === 0 ? (
                      <Box
                        sx={{
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          border: "1px solid #EEE8DC",
                          backgroundColor: "#fff",
                          padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
                        }}
                      >
                        <EmojiEventsOutlinedIcon
                          sx={{
                            fontSize: "60px",
                            color: "#EEE8DC",
                          }}
                        />
                        <Typography>No Sport Created</Typography>
                      </Box>
                    ) : (
                      <>
                        <Box>
                          <StyledTabList
                            onChange={(_, value) => setSelectedTab(value)}
                            aria-label={"sports"}
                            variant="scrollable"
                            scrollButtons={false}
                            sx={{
                              minHeight: "auto",
                              "& .MuiTabs-flexContainer": {
                                borderBottom:
                                  "1px solid rgba(240, 240, 240, 1)",
                              },
                            }}
                          >
                            {availableSports?.map((sport, index) => (
                              <StyledTab
                                key={index + 1}
                                label={
                                  <>
                                    {sport?.name}
                                    <Badge
                                      badgeContent={`${
                                        contestsCount[sport.id] || 0
                                      }`}
                                      sx={{
                                        marginLeft: theme.spacing(2),
                                        "& .MuiBadge-badge": {
                                          backgroundColor:
                                            sport?.name !== selectedTab
                                              ? "#F0F0F0"
                                              : "rgba(250, 242, 226, 1)",

                                          color:
                                            sport?.name !== selectedTab
                                              ? "#8C8C8C"
                                              : theme.palette.primary.main,
                                          fontSize: "16px",
                                        },
                                      }}
                                    ></Badge>
                                  </>
                                }
                                value={sport?.name}
                                icon={getSportIcon(
                                  sport?.logoUrl,
                                  sport?.name,
                                  selectedTab
                                )}
                              />
                            ))}
                          </StyledTabList>
                        </Box>

                        {availableSports.map((sport, index) => {
                          return (
                            <TabPanel
                              key={index}
                              value={sport?.name}
                              sx={{
                                padding: `${theme.spacing(3)} 0 ${theme.spacing(
                                  3
                                )} 0`,
                              }}
                            >
                              <PlayersDashboard
                                key={sport?.name}
                                gameData={sport}
                                selectedDate={pickedDate}
                                paddingTop="10%"
                                partnerId={partnerId}
                                prizePoolId={prizePoolId}
                                touchdowns={touchdowns}
                                updateContestCount={(
                                  sportId: any,
                                  increase: boolean = true
                                ) => {
                                  if (contestsCount[sportId]) {
                                    contestsCount[sportId] = increase
                                      ? +contestsCount[sportId] + 1
                                      : +contestsCount[sportId] - 1;
                                  } else {
                                    contestsCount[sportId] = 1;
                                  }
                                  setContestsCount({ ...contestsCount });
                                }}
                              />
                            </TabPanel>
                          );
                        })}
                      </>
                    )}
                  </>
                )}
              </TabContext>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
