import { useMemo, useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridComparatorFn,
  GridFilterModel,
  GridRowParams,
} from "@mui/x-data-grid";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import { SignedInWithSidebarLayout } from "@src/components/templates/Layouts";
import {
  AuditActionFliterType,
  useGetWithdrawRequestsQuery,
  UserDto,
  WithdrawRequestDto,
  WithdrawRequestStatus,
} from "@src/graphql/graphql.generated";
import {
  ActionDialog,
  Controls,
} from "@src/components/organisms/WithdrawRequests";
import { c2d, renderCurrency } from "@src/helpers/currency";
import { dateRangeOperators } from "@organisms/DataGridFilters/DateRange/dateRangeOperators";
import { AuditLogsModal } from "@src/components/templates/AuditLogsModal";

export interface WithdrawRequestAction {
  target: Omit<WithdrawRequestDto, "user"> & {
    user: Pick<UserDto, "email" | "fullName">;
  };
  status: WithdrawRequestStatus;
}

export function WithdrawRequests() {
  const {
    data: withdrawRequests,
    refetch,
    isLoading,
  } = useGetWithdrawRequestsQuery(
    {},
    {
      staleTime: 300_000, // 5 min
    }
  );
  const [isFilterDate, setIsFilterDate] = useState(false);
  const [action, setAction] = useState<WithdrawRequestAction | null>(null);
  
  const [showAuditTrail, setShowAuditTrail] = useState(false);

  const handleCloseAudit = () => {
    setShowAuditTrail(false);
  };

  const onFilterChange = (model: GridFilterModel) => {
    const { columnField } = model.items[0];
    if (columnField === "createdAt") {
      setIsFilterDate(true);
      return;
    }
    setIsFilterDate(false);
  };

  const handleActionDialogClose = (refresh = false) => {
    setAction(null);
    if (refresh) {
      refetch();
    }
  };

  const handleActionDialogOpen = (
    params: GridRowParams,
    status: WithdrawRequestStatus
  ) => {
    setAction({ target: params.row as WithdrawRequestDto, status });
  };

  const columns = useMemo<GridColumns>(
    () => [
      { field: "fullName", headerName: "User Full Name", width: 180 },
      { field: "email", headerName: "User Email", width: 180 },
      { field: "amount", headerName: "Amount", width: 120 },
      { field: "id", headerName: "ID", width: 80 },
      {
        field: "createdAt",
        headerName: "Creation Date",
        flex: 1,
        valueFormatter: (params) =>
          dayjs(params.value as string).format("MMM D, YYYY, H:ma"),
        sortComparator: dateComparator,
        filterOperators: dateRangeOperators,
      },
      {
        field: "actions",
        type: "actions",
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem
            icon={<CheckIcon />}
            onClick={() =>
              handleActionDialogOpen(params, WithdrawRequestStatus.Approved)
            }
            label="Approve"
          />,
          <GridActionsCellItem
            icon={<CloseIcon />}
            onClick={() =>
              handleActionDialogOpen(params, WithdrawRequestStatus.Denied)
            }
            label="Reject"
          />,
        ],
      },
    ],
    []
  );

  const rows = useMemo(
    () =>
      withdrawRequests?.getWithdrawRequests.map((withdrawRequest) => ({
        ...withdrawRequest,
        amount: renderCurrency(c2d(withdrawRequest.amount)),
        fullName: withdrawRequest.user.fullName,
        email: withdrawRequest.user.email,
      })) ?? [],
    [withdrawRequests]
  );

  return (
    <SignedInWithSidebarLayout
      title="Withdraw Requests"
      SidebarComponent={<Controls setShowAuditTrail={setShowAuditTrail} />}
    >
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <DataGrid
          columns={columns}
          columnBuffer={columns.length}
          initialState={{
            sorting: {
              sortModel: [{ field: "startDate", sort: "asc" }],
            },
          }}
          rows={rows}
          sx={{
            minHeight: "75vh",
          }}
          componentsProps={{
            panel: {
              sx: {
                "& .MuiDataGrid-filterForm": {
                  width: isFilterDate ? 600 : 500,
                },
              },
            },
          }}
          onFilterModelChange={onFilterChange}
        />
      )}
      <ActionDialog
        isOpen={action !== null}
        onClose={handleActionDialogClose}
        action={action}
      />
      <AuditLogsModal
        title="Withdraw Requests Audit"
        isOpen={showAuditTrail}
        onClose={handleCloseAudit}
        actions={[AuditActionFliterType.WithDrawRequest]}
      />
    </SignedInWithSidebarLayout>
  );
}

const dateComparator: GridComparatorFn = (isoDate1, isoDate2): 1 | -1 => {
  return dayjs(isoDate1 as string).isBefore(dayjs(isoDate2 as string)) ? 1 : -1;
};
