import React, { FC } from "react";
import { Modal, ModalProps, Typography, Box, Button } from "@mui/material"
interface CustomModalProps {
    isOpen: boolean,
    onClose: () => void,
    title: string,
    children: any,
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 572,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius: "2px 2px 2px 2px",
    boxShadow: 24,
    padding: "24px 24px 8px 24px",
    
    // p: 4,
};

export const CustomModal: FC<CustomModalProps> = ({ isOpen, onClose, title, children }) => {

    return (

        <Modal  open={isOpen} onClose={onClose} >
            <>
                <Box sx={style}>
                    <Typography sx={{ lineHeight: "28px", fontSize:"20px", fontWeight:"500" }}>
                        {title}
                    </Typography>
                    {children}
                   
                </Box>

            </>


        </Modal>

    )
}