import { Avatar, Box, Chip, Paper, Stack, Typography } from "@mui/material";
import type { Identifier, XYCoord } from "dnd-core";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

interface DragItem {
  index: any;
  orderIndex: number;
  id: string;
  type: string;
}

const contestStyles: any = {
  draft_background: "rgba(249, 249, 249, 1)",
  draft_border: "1px solid rgba(204, 204, 204, 1)",
  cancelled_background: "rgba(255, 240, 241, 1)",
  cancelled_border: "1px solid rgba(235, 23, 69, 1)",
  published_background: "rgba(255, 252, 246, 1)",
  published_border: "1px solid rgba(226, 211, 187, 1)",
  completed_background: "rgba(245, 255, 249, 1) ",
  completed_border: "1px solid rgba(189, 227, 202, 1)",
};

export function AllContestsCard(props: any) {
  const ref = useRef<HTMLDivElement>(null);
  const { contestData, moveCard, onDrop, orderIndex, draggable } = props;

  const {
    id,
    player1FullName,
    player1PhotoUrl,
    player1BonusPoints,
    player2FullName,
    player2PhotoUrl,
    player2BonusPoints,
    matchDate,
    matchDateStartTime,
    type,
    metric,
    commonContestId,
  } = contestData;

  const [, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: "card",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!draggable) return;
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = orderIndex;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop() {
      if (!draggable) return;
      onDrop();
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "card",
    item: () => {
      return { id: id, index: orderIndex };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.6 : 1;

  if (draggable) {
    drag(drop(ref));
  }

  const getContestStyle = (styleName: string) => {
    return contestStyles[`published_${styleName}`];
  };

  const ContestLabel = (props: { label: string }) => (
    <Chip
      label={props.label}
      size="medium"
      variant="outlined"
      sx={{
        backgroundColor: "#B69056",
        color: "#fff",
        position: "absolute",
        top: "0px",
        left: "0px",
        height: "22px",
        width: "22px",
        border: "0",
        paddingRight: "10px",
      }}
      style={{
        borderRadius: "0px 16px 16px 16px",
        fontSize: "12px",
        fontWeight: "bold",
      }}
    />
  );

  return (
    <Box
      ref={ref}
      sx={{
        maxWidth: 600,
        opacity: opacity,
        cursor: draggable ? "pointer" : "default",
        margin: "10px 0px 10px 0px",
      }}
    >
      <Paper
        sx={{
          position: "relative",
          padding: "6px",
          backgroundColor: getContestStyle("background"),
          border: getContestStyle("border"),
          boxShadow: "none",
        }}
        elevation={4}
      >
        {["HUB", "COMMON_HUB"].includes(type) && (
          <ContestLabel label={type.charAt(0)} />
        )}
        <Stack
          sx={{ padding: 1 }}
          justifyContent="space-between"
          alignItems="center"
          direction="row"
          spacing={2}
        >
          <Stack flex={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Avatar sx={{ width: 48, height: 48 }} src={player1PhotoUrl} />
              <Stack spacing={1}>
                <Typography>{player1FullName}</Typography>
                {player1BonusPoints ? (
                  <Chip
                    label={`+${player1BonusPoints} ${metric ?? "Points"}`}
                    size="small"
                    sx={{
                      backgroundColor: "rgba(22, 77, 52, 1)",
                      color: "#fff",
                      marginTop: "3px",
                      fontSize: "11px",
                      fontWeight: 700,
                      height: "auto",
                      maxWidth: "70px",
                    }}
                  />
                ) : (
                  ""
                )}
              </Stack>
            </Stack>
          </Stack>
          <Typography flex={1} variant="h4">
            Vs
          </Typography>
          <Stack flex={2} alignItems="flex-start">
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="flex-end"
            >
              <Avatar sx={{ width: 48, height: 48 }} src={player2PhotoUrl} />
              <Stack spacing={1}>
                <Typography>{player2FullName}</Typography>
                {player2BonusPoints ? (
                  <Chip
                    label={`+${player2BonusPoints} ${metric ?? "Points"}`}
                    size="small"
                    sx={{
                      backgroundColor: "rgba(22, 77, 52, 1)",
                      color: "#fff",
                      marginTop: "3px",
                      fontSize: "11px",
                      fontWeight: 700,
                      height: "auto",
                      maxWidth: "70px",
                    }}
                  />
                ) : (
                  ""
                )}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack alignItems="center" direction="row" spacing={3}>
          <Typography
            sx={{
              marginInlineStart: "5px",
              fontSize: 13,
              fontWeight: 500,
              lineHeight: 2,
            }}
          >
            Match Time :- {matchDateStartTime}
          </Typography>

          <Typography
            sx={{
              fontSize: 13,
              fontWeight: 500,
              lineHeight: 2,
            }}
          >
            Match Date :- {matchDate}
          </Typography>
        </Stack>
      </Paper>
    </Box>
  );
}
