import { useState } from "react";
import { SignedInWithSidebarLayout } from "@src/components/templates/Layouts";
import { CronJobList } from "@src/components/templates/CronJobList";
import { AuditLogsModal } from "@src/components/templates/AuditLogsModal";
import { AuditActionFliterType } from "@src/graphql/graphql.generated";
import { Button, Stack } from "@mui/material";

export function PGACrons() {
  const [showAuditTrail, setShowAuditTrail] = useState(false);

  const handleCloseAudit = () => {
    setShowAuditTrail(false);
  };

  return (
    <SignedInWithSidebarLayout
      title="PGA Crons"
      SidebarComponent={<Controls setShowAuditTrail={setShowAuditTrail} />}
    >
      <CronJobList />
      <AuditLogsModal
        title="PGA Crons Audit"
        isOpen={showAuditTrail}
        onClose={handleCloseAudit}
        actions={[
          AuditActionFliterType.VoidContestsCron,
          AuditActionFliterType.GradeTournamentCron,
          AuditActionFliterType.SyncTournamentsCron,
          AuditActionFliterType.GetTeeTimeByRoundCron,
          AuditActionFliterType.SyncTournamentPlayersCron,
          AuditActionFliterType.SendContestGradedEmailCron,
          AuditActionFliterType.GetCurrentTournamentPlayersCron,
          AuditActionFliterType.GetTeeTimeForCurrentTournamentCron,
        ]}
      />
    </SignedInWithSidebarLayout>
  );
}

interface ControlsProps {
  setShowAuditTrail: (value: boolean) => void;
}

function Controls({ setShowAuditTrail }: ControlsProps) {
  return (
    <Stack spacing={1}>
      <Button onClick={() => setShowAuditTrail(true)} variant="contained">
        Audit Trail
      </Button>
    </Stack>
  );
}
