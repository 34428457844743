import { useMemo, useState, SyntheticEvent, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { DataGrid, GridColumns, GridComparatorFn } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import {
  Button,
  ButtonGroup,
  Typography,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  Autocomplete,
  CircularProgress,
  Stack,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import {
  tournamentTypeState,
  tournamentIdState,
  TournamentType,
  TournamentTypes,
  roundState,
  suggestionIdState,
  suggestionStepState,
} from "./state";
import {
  useTournamentsQuery,
  useGetTournamentSuggestionsQuery,
  useGetCurrentTournamentQuery,
  AuditActionFliterType,
} from "@src/graphql/graphql.generated";
import { SignedInWithSidebarLayout } from "@src/components/templates/Layouts";
import { GQLError, parseGQLError } from "@src/utils/parse-error";
import { UpdateSuggestion } from "./UpdateSuggestion";
import { CreateSuggestion } from "./CreateSuggestion";
import { dateRangeOperators } from "@organisms/DataGridFilters/DateRange/dateRangeOperators";
import { AuditLogsModal } from "@src/components/templates/AuditLogsModal";

export function ContestSuggestions() {
  const [openUpdate, setOpenUpdate] = useState(false);
  const suggestionStep = useRecoilValue(suggestionStepState);
  const setSuggestiontId = useSetRecoilState(suggestionIdState);
  const setTournamentType = useSetRecoilState(tournamentTypeState);
  const [showAuditTrail, setShowAuditTrail] = useState(false);
  const [showPastTournaments, setShowPastTournaments] = useState(false);

  const {
    data: currentTournament,
  } = useGetCurrentTournamentQuery(undefined, {
    staleTime: 300_000, // 5 min
  });
  const {
    data: suggestions,
    isLoading,
    isSuccess,
    error,
    isError,
    refetch: refetchSuggestions,
  } = useGetTournamentSuggestionsQuery();
  const columns = useMemo<GridColumns>(
    () => [
      { field: "tournamentName", headerName: "Tournament Name", width: 200 },
      { field: "tournamentStartDate",
        headerName: "Tournament Start Date",
        width: 200, 
        valueFormatter: (params) =>
          dayjs(params.value as string).format("MMM D, YY, H:ma"),
          sortComparator: dateComparator,
          filterOperators: dateRangeOperators,
      },
      { field: "round", headerName: "Round", width: 80 },
      {
        field: "mainPlayer",
        headerName: "Main Player",
        width: 150,
      },
      { field: "suggestedPlayer", headerName: "Suggested Player", width: 150 },
      
    ],
    []
  );
  const rows = useMemo(
    () => {
      let items = suggestions?.getTournamentSuggestions;
      if(!showPastTournaments) {
        items = suggestions?.getTournamentSuggestions.filter((item) => dayjs(item.tournament.startDate) >= dayjs(currentTournament?.getCurrentTournament.startDate))
      };

      return  (items ?? []).map((s) => ({
        ...s,
        id: s.id,
        tournamentName: s.tournament.name,
        tournamentStartDate: s.tournament.startDate,
        round: s.roundNumber ? s.roundNumber : null,
        mainPlayer: `${s.mainPlayer.firstName} ${s.mainPlayer.lastName}`,
        suggestedPlayer: `${s.suggestedPlayer?.firstName} ${s.suggestedPlayer?.lastName}`,
      })) ?? [] 
    },
    [suggestions, showPastTournaments]
  );
  const handleChangeTournamentType = (
    _e: SyntheticEvent,
    newAlignment: TournamentTypes
  ) => {
    newAlignment && setTournamentType(newAlignment);
  };
  const handleClickOpenUpdateSuggestion = (event: any) => {
    setSuggestiontId(event.id);
    setOpenUpdate(true);
  };
  const handleClose = () => {
    setOpenUpdate(false);
  };
  const handleCloseAudit = () => {
    setShowAuditTrail(false);
  };

  useEffect(() => {
    refetchSuggestions();
  }, [refetchSuggestions, suggestionStep]);

  return (
    <SignedInWithSidebarLayout
      title="Contest Suggestions"
      SidebarComponent={
        <Controls
          onChangeTournamentType={handleChangeTournamentType}
          isEnabled={!isSuccess}
          setShowAuditTrail={setShowAuditTrail}
        />
      }
    >
      <FormControlLabel 
        label={"Show past tournaments"}
        sx={{marginBottom: 1}}
        control={
          <Checkbox
            checked={showPastTournaments}
            onChange={() => setShowPastTournaments(!showPastTournaments)}
          />
        } 
      />
      {isLoading && !isError && (
        <Stack alignItems="center" flex={1} justifyContent="center" m={5}>
          <CircularProgress size={24} />
        </Stack>
      )}
      {isError && (
        <Typography color="error">
          {parseGQLError(error as GQLError)}
        </Typography>
      )}
      {!isLoading && !isError && (
        <DataGrid
          rows={rows}
          columns={columns}
          onCellClick={handleClickOpenUpdateSuggestion}
        />
      )}
      <AuditLogsModal
        title="Suggestions Audit"
        isOpen={showAuditTrail}
        onClose={handleCloseAudit}
        actions={[
          AuditActionFliterType.CreateSuggestion,
          AuditActionFliterType.UpdateSuggestion,
          AuditActionFliterType.DeleteSuggestion
        ]}
      />

      <UpdateSuggestion open={openUpdate} onClose={handleClose} />
    </SignedInWithSidebarLayout>
  );
}

interface ControlsProps {
  setShowAuditTrail: (value: boolean) => void;
  onChangeTournamentType: (
    _e: SyntheticEvent,
    newAlignment: TournamentTypes
  ) => void;
  isEnabled: boolean;
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    borderColor: theme.palette.grey[400],
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
    "&.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.dark,
      color: "#fff",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      color: "#fff",
    },
    "&.Mui-disabled": {
      backgroundColor: theme.palette.grey[100],
      color: "#fff",
    },
  },
}));

function Controls({ onChangeTournamentType, isEnabled, setShowAuditTrail }: ControlsProps) {
  const [selectedRound, setSelectedRound] = useState<string | null>("1");
  const [openCreate, setOpenCreate] = useState(false);
  const tournamentType = useRecoilValue(tournamentTypeState);
  const tournamentId = useRecoilValue(tournamentIdState);
  const setTournamentId = useSetRecoilState(tournamentIdState);
  const setRound = useSetRecoilState(roundState);

  const {
    data: tournaments,
    isLoading: isTournamentsLoading,
    isSuccess: hasLoadedTournaments,
    isError: isTournamentsError,
  } = useTournamentsQuery(undefined, {
    staleTime: 300_000, // 5 min
  });
  const {
    data: currentTournament,
    isLoading: isCurrentTournamentLoading,
    isSuccess: hasLoadedTournament,
    isError: isCurrentTournamentError,
  } = useGetCurrentTournamentQuery(undefined, {
    staleTime: 300_000, // 5 min
  });
  const selectedTournament = tournaments?.tournaments.find((tour) => tour.remoteId === tournamentId);
  const isLoading = useMemo(
    () => isCurrentTournamentLoading || isTournamentsLoading,
    [isCurrentTournamentLoading, isTournamentsLoading]
  );
  const isError = useMemo(
    () => isTournamentsError || isCurrentTournamentError,
    [isTournamentsError || isCurrentTournamentError]
  );
  const availableTournaments = useMemo(
    () => {
      return (
        tournaments?.tournaments ?? []).filter(
          (t) => new Date(t.endDate) >= new Date()
      )
    },
    [tournaments?.tournaments]
  );
  const tournamentsLabelValues = useMemo(
    () => (availableTournaments ?? []).sort((tempTournament, nextTempTournament) => {
      const currTournamentDate = new Date(tempTournament.startDate);
      const nextTournamentDate = new Date(nextTempTournament.startDate);
      return currTournamentDate.getTime() - nextTournamentDate.getTime();
    }).map((t) => {return {
      label: t.name,
      value: t.remoteId
    }}),
    [availableTournaments]
  );
  const handleClickOpen = () => {
    setOpenCreate(true);
  };
  const handleCloseCreate = () => {
    setOpenCreate(false);
  };
  const handleTournamentChange = (tempTournament: {label?: string, value?: string} | null) => {
    setTournamentId(`${tempTournament?.value}`);
  };
  const handleRoundChange = (newValue: string | null) => {
    setSelectedRound(newValue);
    setRound(newValue!);
  };
  useEffect(() => {
    if (
      hasLoadedTournament &&
      currentTournament?.getCurrentTournament &&
      hasLoadedTournaments &&
      tournaments?.tournaments
    ) {
      setTournamentId(currentTournament.getCurrentTournament.remoteId);
    }
  }, [hasLoadedTournament, hasLoadedTournaments]);
  return (
    <>
      {isLoading ? (
        <Stack alignItems="center" flex={1} justifyContent="center" m={5}>
          <CircularProgress size={24} />
        </Stack>
      ) : isError ? (
        <Stack alignItems="center" flex={1} justifyContent="center" m={5}>
          <Typography variant="h5" align="center">
            Sorry, tournaments can't be fetched at the moment. Please try again
            on later.
          </Typography>
        </Stack>
      ) : (
        <>
          <Button 
            sx={{ mb: 4 }}
            fullWidth
            onClick={() => setShowAuditTrail(true)}
            variant="outlined"
          >
            Audit Trail
          </Button>
          <StyledToggleButtonGroup
            value={tournamentType}
            exclusive
            fullWidth
            onChange={onChangeTournamentType}
            sx={{ mb: 3 }}
          >
            <ToggleButton
              disabled={isEnabled}
              value={TournamentType.FullTournament}
              style={{ padding: "0px" }}
            >
              Full Tournament
            </ToggleButton>
            <ToggleButton
              value={TournamentType.RoundByRound}
              sx={{ py: 1, px: 0 }}
              disabled={isEnabled}
            >
              Round by Round
            </ToggleButton>
          </StyledToggleButtonGroup>
          <Autocomplete
            disabled={isEnabled}
            value={{label: selectedTournament?.name, value: selectedTournament?.remoteId}}
            onChange={(_event: any, newValue: {label?: string, value?: string} | null) => {
              handleTournamentChange(newValue);
            }}
            id="controllable-states-tournament"
            options={tournamentsLabelValues}
            fullWidth
            sx={{ mb: 3 }}
            renderInput={(params) => (
              <TextField {...params} label="Tournament" size="small" />
            )}
          />
          <Autocomplete
            value={selectedRound}
            onChange={(event: any, newValue: string | null) => {
              handleRoundChange(newValue);
            }}
            id="controllable-states-round"
            options={["1", " 2", "3", "4"]}
            fullWidth
            sx={{ mb: 3 }}
            disabled={
              tournamentType === TournamentType.FullTournament || isEnabled
            }
            renderInput={(params) => (
              <TextField {...params} label="Round" size="small" />
            )}
          />
          <ButtonGroup
            variant="contained"
            orientation="vertical"
            fullWidth
            disableElevation
            disabled={isEnabled}
          >
            <Button variant="contained" onClick={handleClickOpen}>
              Create a Suggestion
            </Button>
          </ButtonGroup>
          <CreateSuggestion open={openCreate} onClose={handleCloseCreate} />
        </>
      )}
    </>
  );
}

const dateComparator: GridComparatorFn = (isoDate1, isoDate2): 1 | -1 => {
  return dayjs(isoDate1 as string).isBefore(dayjs(isoDate2 as string)) ? 1 : -1;
};
