import { PermissionId } from "@src/constants/permissions";
import { useEffect, useState } from "react";
import { usePermissions } from "./usePermissions";

export function useHasPermissions(
  requiredPermissions: PermissionId | Array<PermissionId>
): boolean {
  const [isSuperuser, setIsSuperuser] = useState(false);
  const [permissions, setPermissions] = useState<Array<PermissionId>>([]);
  const { data } = usePermissions();

  useEffect(() => {
    if (!data) {
      return;
    }
    setIsSuperuser(data.isSuperuser);
    setPermissions(data.permissions);
  }, [data]);

  return (
    isSuperuser ||
    (Array.isArray(requiredPermissions)
      ? requiredPermissions
      : [requiredPermissions]
    ).every((reqdPerm) => {
      return permissions.includes(reqdPerm);
    })
  );
}
