import React from "react";
import { NflMatchupTemplate } from "@src/components/templates/Nfl/NflMatchupTemplate";
import { useGetNflMatchupsQuery } from "@src/graphql/graphql.generated";

export function NflMatchup() {
  const { data: matchups } = useGetNflMatchupsQuery();

  return (
    <>{matchups && <NflMatchupTemplate matchups={matchups.getNflMatchups} />}</>
  );
}
