export const AUTH_BASE_URL = "https://dev.v2.toppropfantasy.com/api/admin/auth";
export const TOUCHDOWN_BASE_URL =
  "https://dev.v2.toppropfantasy.com/api/admin/touchdown";
export const GRAPHQL_URL =
  "https://dev.v2.toppropfantasy.com/api/admin/graphql";
export const PARTNER_BASE_URL =
  "https://dev.v2.toppropfantasy.com/api/admin/partner";
export const F2P_PARTNER_BASE_URL =
  "https://dev.v2.toppropfantasy.com/api/admin/f2p";

// export const AUTH_BASE_URL = "/api/admin/auth";
// export const TOUCHDOWN_BASE_URL = "/api/admin/touchdown";
// export const PARTNER_BASE_URL = "/api/admin/partner";
// export const GRAPHQL_URL = "/api/admin/graphql";
// export const F2P_PARTNER_BASE_URL = "/api/admin/f2p";
