import DeleteIcon from "@mui/icons-material/Delete";
import { Avatar, Box, Paper, Stack, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import type { Identifier, XYCoord } from "dnd-core";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

interface IPlayer {
  firstName: string;
  lastName: string;
  photoUrl: string;
}

interface NflSuggestionCardProps {
  playerOne: IPlayer;
  playerTwo: IPlayer;
  matchupId: number;
  orderIndex: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  onDrop: () => void;
  onDelete: (matchupId: number) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export function NflSuggestionCard(props: NflSuggestionCardProps) {
  const ref = useRef<HTMLDivElement>(null);
  const {
    playerOne,
    playerTwo,
    matchupId,
    orderIndex,
    moveCard,
    onDrop,
    onDelete,
  } = props;

  const [, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: "card",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = orderIndex;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop() {
      onDrop();
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "card",
    item: () => {
      return { id: matchupId, index: orderIndex };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <Box ref={ref} sx={{ width: 600, opacity: opacity }}>
      <Paper sx={{ position: "relative" }} elevation={4}>
        <Stack
          sx={{ padding: 1 }}
          justifyContent="space-between"
          alignItems="center"
          direction="row"
          spacing={2}
        >
          <Stack flex={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Avatar sx={{ width: 48, height: 48 }} src={playerOne.photoUrl} />
              <Typography>{playerOne.firstName}</Typography>
              <Typography>{playerOne.lastName}</Typography>
            </Stack>
          </Stack>
          <Typography flex={1} variant="h4">
            Vs
          </Typography>
          <Stack flex={2} alignItems="flex-start">
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="flex-end"
            >
              <Avatar sx={{ width: 48, height: 48 }} src={playerTwo.photoUrl} />
              <Typography>{playerTwo.firstName}</Typography>
              <Typography>{playerTwo.lastName}</Typography>
            </Stack>
          </Stack>
        </Stack>
        <IconButton
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            bottom: 0,
            cursor: "pointer",
          }}
          onClick={() => onDelete(matchupId)}
        >
          <DeleteIcon />
        </IconButton>
      </Paper>
    </Box>
  );
}
