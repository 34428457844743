import { useSnackbar } from "notistack";
import { Role, useDeleteRoleMutation } from "@src/graphql/graphql.generated";
import { parseGQLError } from "@src/utils/parse-error";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Box,
  Typography,
} from "@mui/material";

interface DeleteDialogProps {
  isOpen: boolean;
  target: Role | null;
  onClose: (refresh: boolean) => void;
}

export function DeleteDialog({ isOpen, target, onClose }: DeleteDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: deleteRole, isLoading } = useDeleteRoleMutation();

  const handleDelete = () => {
    deleteRole(
      { roleId: target?.id ?? 0 },
      {
        onSuccess: () => {
          enqueueSnackbar("Role has been deleted successfully!", {
            variant: "success",
          });
          onClose(true);
        },
        onError: (error: any) => {
          enqueueSnackbar(parseGQLError(error, "Unable to delete"), {
            variant: "error",
          });
        },
      }
    );
  };

  if (!target) {
    return null;
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={onClose}>
      <DialogTitle>Delete Role</DialogTitle>
      <DialogContent>
        <Box component="main">
          <Stack spacing={2}>
            <Typography>
              Do you want to delete the selected role?{" "}
              <strong>({target.name})</strong>
            </Typography>
            <Button
              variant="contained"
              onClick={handleDelete}
              disabled={isLoading}
            >
              Delete
            </Button>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
