import CloudOffIcon from "@mui/icons-material/CloudOff";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WestIcon from "@mui/icons-material/West";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import TimePicker from "@organisms/Pickers/TimePicker";
import WeekPicker from "@organisms/Pickers/WeekPicker";
import { QueryKeys } from "@src/constants/QueryKeys";
import { EST_TIME_ZONE, YYYY_MM_DD } from "@src/constants/Touchdown";
import { theme } from "@src/constants/theme";
import {
  PartnerUpdateTouchdownInputDto,
  PrizePoolStatus,
  useCreatePartnerTouchdownMutation,
  useFetchPartnerTouchdownByIdQuery,
  useGetPartnerPrizeConstantQuery,
  useGetPartnerSportsQuery,
  usePublishPartnerPrizePoolMutation,
  useUpdatePartnerTouchdownMutation,
} from "@src/graphql/graphql.generated";
import { useAuth } from "@src/hooks/useAuth";
import {
  initPrizePoolCreationData,
  initTouchdownCreationData,
  PartnerPrizePoolCreationFields,
  PartnerTouchdownCreationFields,
  PartnerTouchdownFormValues,
  PrizePoolCreationFields,
  TouchdownFormValues,
} from "@src/types/touchdown/contest";
import {
  PartnerPrizePoolDetails,
  PartnerTouchdownDetails,
} from "@src/types/touchdown/dashboard";
import {
  NumberOfPicksValidationSchema,
  NumberValidationSchema,
  parseGQLError,
  StringValidationSchema,
} from "@src/utils/parse-error";
import { formatDate } from "@src/utils/utils";
import dayjs from "dayjs";
import "dayjs/locale/en";
import { useSnackbar } from "notistack";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

const StyledLabel = styled("label")(({ theme }) => ({
  color: "#212121",
  fontSize: "14px",
}));

const StyledTitle = styled("label")(({ theme }) => ({
  color: "#000",
  fontSize: "20px",
}));

const StyledDate = styled("label")(({ theme }) => ({
  color: "#787878",
  fontSize: "16px",
}));

const StyledTableCell = styled(TableCell)(({ theme: any }) => ({
  border: "none",
  padding: theme.spacing(1),
  "&.table_head": {
    fontSize: "16px",
  },
}));

const StyledError = styled("label")(({ theme }) => ({
  color: "#d32f2f",
  fontSize: "12px",
}));

const StyledInput = styled(TextField)(({ theme: any }) => ({
  marginTop: theme.spacing(1),
  "& input": {
    padding: "9px 12px 9px 12px",

    "&:focus": {
      outline: `none`,
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: `#E2D3BB !important`,
    "&:focus": {
      outline: `none`,
    },
  },
  "& .MuiOutlinedInput-root.Mui-error": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `#d32f2f !important`,
    },
  },
  "& .MuiFormHelperText-root.Mui-error": {
    fontSize: "12px",
    margin: "3px",
  },
}));

interface Sport {
  id: number;
  key: string;
  name: string;
  logoUrl: string;
  enabled: boolean;
}

type SportId = number;

const getDailyMetrics = (mathConstants: { [key: string]: number }) => {
  const { PRIZE_POOL, GPP, WEEKLY_RESERVE } = mathConstants;

  const dailyMetrics = [
    {
      labelName: "Prize pool",
      formItemName: "predeterminePrizePool",
      tooltipValue: `Formula to count prize pool: \n ( Entry fee * Entrants ) * ${PRIZE_POOL}`,
    },
    {
      labelName: "Jackpot",
      formItemName: "predetermineJackpot",
      tooltipValue: `Formula to count jackpot: \n ( Prize pool - Weekly Reserve \n - GPP )`,
    },
    {
      labelName: "GPP",
      formItemName: "predetermineDailyGPP",
      tooltipValue: `Formula to calculate GPP: \n (${GPP} % Prize Pool )`,
    },
    // {
    //   labelName: "Weekly reserve",
    //   formItemName: "predetermineWeeklyReserve",
    //   tooltipValue: `Formula to calculate weekly reserve: \n (${WEEKLY_RESERVE} % Prize Pool )`,
    // },
    {
      labelName: "Contest vig",
      formItemName: "predetermineContestVig",
      tooltipValue: `Formula to calculate contest vig: \n ( Entrants * Entry Fee ) - Prize Pool`,
    },
  ];

  return dailyMetrics;
};

const getCompletedMetrics = (
  mathConstants: { [key: string]: number },
  activePrizePool: PrizePoolCreationFields
) => {
  const {
    predeterminePrizePool,
    predetermineJackpot,
    predetermineDailyGPP,
    predetermineWeeklyReserve,
    predetermineContestVig,
    actualPrizePool,
    actualJackpot,
    actualDailyGPP,
    actualWeeklyReserve,
    actualContestVig,
    userEntryCount,
    totalEntrants,
  } = activePrizePool;
  const { PRIZE_POOL, GPP, WEEKLY_RESERVE } = mathConstants;

  const completedMetrics = [
    {
      name: "Prize Pool",
      actual: actualPrizePool || 0,
      predetermine: predeterminePrizePool || 0,
      tooltipValue: `Formula to count prize pool:\n ( Entry fee * Entrants ) * ${PRIZE_POOL}`,
    },
    {
      name: "7-for-7",
      actual: actualJackpot || 0,
      predetermine: predetermineJackpot || 0,
      tooltipValue: `Formula to count Jackpot: \n ( Prize pool - Weekly Reserve \n - GPP )`,
    },
    {
      name: "6-for-7",
      actual: actualDailyGPP || 0,
      predetermine: predetermineDailyGPP || 0,
      tooltipValue: `Formula to calculate GPP: \n (${GPP} % Prize Pool )`,
    },
    {
      name: "Weekly reserve",
      actual: actualWeeklyReserve || 0,
      predetermine: predetermineWeeklyReserve || 0,
      tooltipValue: `Formula to calculate weekly reserve:\n (${WEEKLY_RESERVE} % Prize Pool )`,
    },
    {
      name: "TopProp vig",
      actual: actualContestVig || 0,
      predetermine: predetermineContestVig || 0,
      tooltipValue: `Formula to calculate contest vig: \n ( Entrants * Entry Fee ) - Prize Pool`,
    },
  ];

  return completedMetrics;
};

const EmptyContainer = () => (
  <Box
    sx={{
      marginTop: theme.spacing(3),
      dispaly: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      border: "1px solid #EEE8DC",
      backgroundColor: "#fff",
      padding: `${theme.spacing(12)} ${theme.spacing(2)}`,
    }}
  >
    <CloudOffIcon
      sx={{
        fontSize: "60px",
        opacity: "0.4",
        color: theme.palette.primary.main,
      }}
    />
    <Typography>Please select week to proceed further</Typography>
  </Box>
);

const statusToStyleMap: any = {
  COMPLETED_TEXT: "#146D34",
  COMPLETED_BORDER: "#D1E3D8",
  COMPLETED_BACKGROUND: "#e2efe7",
  LIVE_TEXT: "#EB1745",
  LIVE_BORDER: "#f2d6dc",
  LIVE_BACKGROUND: "#ffe5e7",
  DRAFT_TEXT: "#B69056",
  DRAFT_BORDER: "#F3DDBB",
  DRAFT_BACKGROUND: "#FFF3E0",
  PENDING_TEXT: "#F6C000",
  PENDING_BORDER: "#FCEAAC",
  PENDING_BACKGROUND: "#FFF7DA",
};

function TouchdownCreation({
  partnerId,
  isFromHistory = false,
  touchdownId = null,
  setViewDetails,
}: any) {
  const params = useParams();
  const id = params?.id || touchdownId;
  /* States  */
  const [weekRange, setWeekRange] = useState<Array<dayjs.Dayjs> | []>([]);
  const [selectedSports, setSelectedSports] = useState<SportId[]>([]);
  const [partnerSports, setPartnerSports] = useState<any>([]);
  const [isSelectAllChecked, setSelectAllChecked] = useState<boolean>(false);
  const [disable, setDisable] = useState<boolean>(true);
  const [isLived, setLived] = useState<boolean>(false);
  const [activeBox, setActiveBox] = useState<number>(0);
  const [touchdownInfo, setTouchdownInfo] =
    useState<PartnerTouchdownCreationFields>();
  const [mathConstantInfo, setMathConstantInfo] = useState<{
    [key: string]: number;
  }>();
  const [selectedPrizePoolId, setSelectedPrizePoolId] = useState<number>(0);
  const [selectedDate, setSelectedDate] = useState<string | null>();

  /* Hooks */
  const navigate = useNavigate();
  const { data: authInfo } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
    clearErrors,
    control,
  } = useForm<TouchdownFormValues>({
    defaultValues: {
      predeterminePrizePool: 0,
      predetermineJackpot: 0,
      predetermineDailyGPP: 0,
      predetermineWeeklyReserve: 0,
      predetermineContestVig: 0,
    },
    mode: "all",
  });

  /* Queries */
  const queryClient = useQueryClient();
  const { data: mathConstants } = useGetPartnerPrizeConstantQuery({
    partnerId: partnerId!,
  });
  const {
    data: touchdownData,
    isLoading,
    refetch: refetchTouchdown,
  } = useFetchPartnerTouchdownByIdQuery(
    {
      touchdownId: Number(id),
    },
    {
      staleTime: 300_000, // 5 min
      enabled: Number(id) > 0,
    }
  );
  const { data: fetchedPartnerSports } = useGetPartnerSportsQuery(
    {
      partnerId: partnerId!,
      type: "all",
    },
    {
      staleTime: 300_000, // 5 min
      enabled: partnerId > 0,
    }
  );

  useEffect(() => {
    return setPartnerSports(fetchedPartnerSports?.getPartnerSports);
  }, [fetchedPartnerSports?.getPartnerSports]);

  /* Mutations */
  const { mutate: createTouchdownMutate, isLoading: createTouchdownLoading } =
    useCreatePartnerTouchdownMutation();
  const { mutate: updateTouchdownMutate, isLoading: updateTouchdownLoading } =
    useUpdatePartnerTouchdownMutation();
  const { mutate: publishPrizePoolMutate } =
    usePublishPartnerPrizePoolMutation();

  const handleDateChange = (selectedDate: dayjs.Dayjs | null) => {
    if (selectedDate) {
      const weekStartDate = selectedDate.startOf("week");
      const weekEndDate = selectedDate.endOf("week");
      setSelectedDate(weekStartDate?.format(YYYY_MM_DD));
      setSelectedPrizePoolId(0);
      setWeekRange([weekStartDate, weekEndDate]);
      setActiveBox(0);

      const weekDates: dayjs.Dayjs[] = [];
      let mondayDate = dayjs(weekStartDate)
        .tz(EST_TIME_ZONE, true)
        .startOf("week");
      const sundayDate = dayjs(weekStartDate)
        .tz(EST_TIME_ZONE, true)
        .endOf("week");
      while (mondayDate.isBefore(sundayDate) || mondayDate.isSame(sundayDate)) {
        weekDates.push(mondayDate.utc());
        mondayDate = mondayDate.add(1, "day");
      }
      initTouchdownData(weekDates);
    }
  };

  const updateFormValues = (prizePool: PrizePoolCreationFields) => {
    const {
      entryFees,
      predeterminePrizePool,
      predetermineJackpot,
      predetermineDailyGPP,
      predetermineWeeklyReserve,
      predetermineContestVig,
      totalEntrants,
      maxEntriesPerUser,
      lockTime,
      allowedSports,
    } = prizePool;

    setValue("lockTime", lockTime);
    setValue("totalEntrants", totalEntrants);
    setValue("maxEntriesPerUser", maxEntriesPerUser);
    setValue("predetermineJackpot", predetermineJackpot);
    setValue("predeterminePrizePool", predeterminePrizePool);
    setValue("predetermineDailyGPP", predetermineDailyGPP);
    setValue("predetermineWeeklyReserve", predetermineWeeklyReserve);
    setValue("predetermineContestVig", predetermineContestVig);
    setValue("selectedSport", allowedSports || []);
    setSelectedSports(allowedSports || []);
  };

  useEffect(() => {
    if (partnerSports && partnerSports.length > 0) {
      const enabledSports = partnerSports.filter(
        (sport: Sport) => sport.enabled
      );
      const allSelected = enabledSports.every((sport: Sport) =>
        selectedSports.includes(sport.id)
      );
      setSelectAllChecked(allSelected);
    }
  }, [selectedSports, partnerSports]);

  const handleCheckboxChange = (
    event: ChangeEvent<HTMLInputElement>,
    sport: Sport
  ) => {
    setSelectedSports((prevSelected) => {
      if (event.target.checked) {
        return [...prevSelected, sport.id];
      } else {
        return prevSelected.filter((id: number) => id !== sport.id);
      }
    });
  };

  // Handle "Select All" checkbox
  const handleSelectAllChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      // Select all non-disabled sports IDs
      setSelectAllChecked(true);
      const enabledData = partnerSports
        .filter((sport: any) => sport.enabled)
        .map((enabledSport: any) => enabledSport?.id);
      setSelectedSports(enabledData);
      setValue("selectedSport", enabledData);
    } else {
      // Deselect all sports IDs
      setSelectAllChecked(false);
      setSelectedSports([]);
      setValue("selectedSport", []);
    }
  };

  const updateTouchdownData = (
    touchdownData: PartnerTouchdownDetails,
    weekDates: dayjs.Dayjs[],
    weekStartDate: dayjs.Dayjs
  ) => {
    let totalWeeklyReserve: number = 0;
    let totalActualWeeklyReserve: number = 0;
    let totalActualTopPropVig: number = 0;

    const mondayPrizePool: PartnerPrizePoolDetails | null | undefined =
      touchdownData?.prizePools?.find(({ startDateTime }) =>
        weekStartDate.isSame(startDateTime, "d")
      );

    const prizePools: Array<PrizePoolCreationFields> = weekDates.map((date) => {
      const foundedPrizePool = touchdownData?.prizePools?.find(
        ({ startDateTime }) => dayjs(startDateTime).isSame(date)
      );

      const newPrizePoolCreation: PrizePoolCreationFields = {
        ...initPrizePoolCreationData,
        startDate: date,
      };

      if (!foundedPrizePool) {
        totalWeeklyReserve += mondayPrizePool?.weeklyReserveAmount || 0;
        return newPrizePoolCreation;
      }

      const {
        id,
        totalEntrants,
        allowedSports,
        maxEntriesPerUser,
        totalPrize: predeterminePrizePool,
        gppAmount,
        weeklyReserveAmount: predetermineWeeklyReserve,
        jackpotAmount,
        contestVig,
        status,
        lockTime,
      } = foundedPrizePool;

      newPrizePoolCreation.prizePoolId = id;
      newPrizePoolCreation.startDate = date;
      newPrizePoolCreation.lockTime = lockTime
        ? dayjs(lockTime).tz(EST_TIME_ZONE)
        : null;
      newPrizePoolCreation.maxEntriesPerUser = maxEntriesPerUser;
      newPrizePoolCreation.totalEntrants = totalEntrants;
      newPrizePoolCreation.allowedSports = allowedSports;
      newPrizePoolCreation.predeterminePrizePool = predeterminePrizePool;
      newPrizePoolCreation.predetermineDailyGPP = gppAmount;
      newPrizePoolCreation.predetermineWeeklyReserve =
        predetermineWeeklyReserve;
      newPrizePoolCreation.predetermineJackpot = jackpotAmount;
      newPrizePoolCreation.predetermineContestVig = contestVig;
      newPrizePoolCreation.status = status;
      newPrizePoolCreation.actualJackpot = jackpotAmount;

      totalActualTopPropVig += 0;
      totalActualTopPropVig += 0;
      totalActualWeeklyReserve += 0;

      return newPrizePoolCreation;
    });

    const currentPrizePool =
      (selectedPrizePoolId > 0 &&
        prizePools.find(
          ({ prizePoolId }) => prizePoolId === selectedPrizePoolId
        )) ||
      prizePools?.[activeBox];

    const { id, allowedPick, name, entryFees } = touchdownData;
    const { prizePoolId, status, startDate } = currentPrizePool;

    const touchdownInfo: PartnerTouchdownCreationFields = {
      prizePools: prizePools,
      startDate: weekDates[0],
      endDate: weekDates[6],
      totalWeeklyReserve: totalWeeklyReserve,
      totalActualWeeklyReserve,
      totalTopPropVig: 0,
      totalActualTopPropVig,
      id: id,
    };

    setValue("NoOfPicks", allowedPick);
    setValue("name", name);
    setValue("entryFees", entryFees);
    setSelectedDate(startDate?.format(YYYY_MM_DD));
    setSelectedPrizePoolId(prizePoolId!);
    setDisable(["COMPLETED"].includes(status));
    setTouchdownInfo({ ...touchdownInfo });
    updateFormValues(currentPrizePool);
    setActiveBox(activeBox);
  };

  const initTouchdownData = (weekDates: dayjs.Dayjs[]) => {
    const prizePools: PrizePoolCreationFields[] = weekDates.map(
      (date: dayjs.Dayjs) => ({
        ...initPrizePoolCreationData,
        startDate: date,
      })
    );

    const touchdownInfo: PartnerTouchdownCreationFields = {
      ...initTouchdownCreationData,
      prizePools: prizePools,
      startDate: weekDates[0],
      endDate: weekDates[6],
      id: 0,
    };
    const currentPrizePool = prizePools[0];
    setSelectedDate(currentPrizePool.startDate?.format(YYYY_MM_DD));

    setDisable(["COMPLETED"].includes(currentPrizePool.status));
    setTouchdownInfo(touchdownInfo);
    updateFormValues(currentPrizePool);
  };

  useEffect(() => {
    if (isFromHistory) {
      setDisable(true);
      setLived(true);
    }
  }, [isFromHistory]);

  useEffect(() => {
    if (touchdownData?.fetchPartnerTouchdownById) {
      const touchdownIdData = touchdownData?.fetchPartnerTouchdownById;
      handleDateChange(dayjs(touchdownIdData?.startDateTime));

      const livedData = touchdownIdData?.prizePools.find(
        (item) => item.status === "COMPLETED"
      );

      setLived(livedData?.id ? true : false);
      const weekDates: dayjs.Dayjs[] = [];

      const weekStartDate = dayjs(touchdownIdData?.startDateTime).startOf(
        "week"
      );
      const weekEndDate = dayjs(touchdownIdData?.startDateTime).endOf("week");
      let mondayDate = dayjs(weekStartDate)
        .tz(EST_TIME_ZONE, true)
        .startOf("day");
      const sundayDate = dayjs(weekEndDate)
        .tz(EST_TIME_ZONE, true)
        .endOf("day");
      while (mondayDate.isBefore(sundayDate) || mondayDate.isSame(sundayDate)) {
        weekDates.push(mondayDate.utc());
        mondayDate = mondayDate.add(1, "day");
      }

      if (touchdownIdData) {
        updateTouchdownData(touchdownIdData, weekDates, weekStartDate);
      } else {
        initTouchdownData(weekDates);
      }
    } else {
      setDisable(false);

      const weekDates: dayjs.Dayjs[] = [];
      let mondayDate = dayjs(weekRange[0])
        .tz(EST_TIME_ZONE, true)
        .startOf("week");
      const sundayDate = dayjs(weekRange[0])
        .tz(EST_TIME_ZONE, true)
        .endOf("week");
      while (mondayDate.isBefore(sundayDate) || mondayDate.isSame(sundayDate)) {
        weekDates.push(mondayDate.utc());
        mondayDate = mondayDate.add(1, "day");
      }
      initTouchdownData(weekDates);
    }
  }, [touchdownData?.fetchPartnerTouchdownById]);

  useEffect(() => {
    if (mathConstants?.getPartnerPrizeConstant?.prizeConfiguration) {
      setMathConstantInfo(
        mathConstants?.getPartnerPrizeConstant?.prizeConfiguration
      );
    }
  }, [mathConstants?.getPartnerPrizeConstant]);

  const showPublishButton = useCallback(() => {
    let currentEstTimeInUtc = dayjs().tz(EST_TIME_ZONE).utc();
    const { status, startDate } = touchdownInfo?.prizePools?.[activeBox] || {};
    return (
      currentEstTimeInUtc.hour() >= 9 &&
      currentEstTimeInUtc.minute() > 5 &&
      currentEstTimeInUtc.format(YYYY_MM_DD) ===
        startDate?.format(YYYY_MM_DD) &&
      status === PrizePoolStatus.Draft
    );
  }, [activeBox, touchdownInfo?.prizePools]);

  const calculateValues = () => {
    const { PRIZE_POOL, GPP, WEEKLY_RESERVE }: any = mathConstantInfo;
    const { entryFees, totalEntrants } = getValues();

    if (totalEntrants && entryFees) {
      const totalPrizePool = totalEntrants * entryFees;

      const prizePoolAmount = parseFloat(
        (totalPrizePool * PRIZE_POOL).toFixed(2)
      );

      const gppAmount = parseFloat((GPP * totalPrizePool).toFixed(2)) / 100;
      const weeklyReserveAmount =
        parseFloat((WEEKLY_RESERVE * totalPrizePool).toFixed(2)) / 100;

      const jackpotAmount = parseFloat(
        (prizePoolAmount - weeklyReserveAmount - gppAmount).toFixed(2)
      );
      const contestVig = parseFloat(
        (totalPrizePool - prizePoolAmount).toFixed(2)
      );

      setValue("predeterminePrizePool", prizePoolAmount);
      setValue("predetermineJackpot", jackpotAmount);
      setValue("predetermineDailyGPP", gppAmount);
      setValue("predetermineWeeklyReserve", weeklyReserveAmount);
      setValue("predetermineContestVig", contestVig);
    }
  };

  const handlePrizePoolChange = (
    index: number,
    data: Partial<PrizePoolCreationFields>
  ) => {
    const { prizePoolId, status, startDate: selectedDate } = data;
    const selectedPrizePool: PrizePoolCreationFields | undefined =
      touchdownInfo?.prizePools.find(({ startDate }) =>
        startDate?.isSame(selectedDate)
      );

    if (!selectedPrizePool) return;

    // GPP and WeeklyReserve is same over the week based on Monday
    if (index !== 0 && selectedPrizePool.status === "PENDING") {
      const mondayPrizePool: PrizePoolCreationFields | undefined =
        touchdownInfo?.prizePools.find(({ startDate }) =>
          weekRange[0].isSame(startDate, "d")
        );

      if (mondayPrizePool) {
        const { predetermineDailyGPP, predetermineWeeklyReserve } =
          mondayPrizePool;
        selectedPrizePool.predetermineDailyGPP = predetermineDailyGPP;
        selectedPrizePool.predetermineWeeklyReserve = predetermineWeeklyReserve;
      }
    }

    clearErrors();
    setSelectedSports([]);
    setValue("selectedSport", []);
    setActiveBox(index);
    setSelectedDate(selectedDate?.format(YYYY_MM_DD));
    setSelectedPrizePoolId(prizePoolId || 0);
    setDisable(["COMPLETED"].includes(status || ""));
    updateFormValues(selectedPrizePool);
  };

  const metricsData = mathConstantInfo
    ? touchdownInfo?.prizePools?.[activeBox]?.status ===
      PrizePoolStatus.Completed
      ? getCompletedMetrics(
          mathConstantInfo,
          touchdownInfo?.prizePools?.[activeBox]
        )
      : getDailyMetrics(mathConstantInfo)
    : [];

  const createTouchdown = (values: PartnerPrizePoolCreationFields) => {
    const [weekStart, weekEnd] = weekRange;
    const {
      predetermineDailyGPP,
      predeterminePrizePool,
      predetermineJackpot,
      predetermineContestVig,
      entryFees,
      totalEntrants,
      maxEntriesPerUser,
      lockTime,
      name,
      NoOfPicks,
    } = values;

    const inputData: any = {
      partnerId: partnerId!,
      startDateTime: dayjs(weekStart)
        .tz(EST_TIME_ZONE, true)
        .utc()
        .toISOString(),
      endDateTime: dayjs(weekEnd).tz(EST_TIME_ZONE, true).utc().toISOString(),
      prizeConfiguration: mathConstantInfo,
      prizePool: {
        startDateTime: dayjs(selectedDate)
          .tz(EST_TIME_ZONE, true)
          .startOf("day")
          .utc()
          .toISOString(),
        endDateTime: dayjs(selectedDate)
          .tz(EST_TIME_ZONE, true)
          .endOf("day")
          .utc()
          .toISOString(),
        allowedSports: selectedSports,
        lockTime,
        totalEntrants,
        maxEntriesPerUser,
        totalPrize: predeterminePrizePool,
        gppAmount: predetermineDailyGPP,
        jackpotAmount: predetermineJackpot,
        contestVig: predetermineContestVig,
      },
      name,
      entryFees,
      allowedPick: NoOfPicks,
    };

    createTouchdownMutate(
      { data: inputData },
      {
        onSuccess: () => {
          enqueueSnackbar("Touchdown created successfully !", {
            variant: "success",
          });
          if (authInfo?.role === "ADMIN") {
            navigate("/touchdown");
          } else {
            navigate("/partner/touchdown");
          }
          queryClient.invalidateQueries(QueryKeys.FetchPartnerTouchdown);
          queryClient.invalidateQueries(QueryKeys.FetchPartnerTouchdownByDate);
          queryClient.invalidateQueries(QueryKeys.GetPartnerTouchdownAnalytics);
          queryClient.invalidateQueries(QueryKeys.GetPartnerActiveTouchdowns);
        },
        onError: (error: any) => {
          enqueueSnackbar(parseGQLError(error, "Unable to create Touchdown."), {
            variant: "error",
          });
        },
      }
    );
  };

  const createOrUpdatePrizePool = (
    data: any,
    prizePoolId: number,
    touchdownId: number
  ) => {
    const {
      predetermineDailyGPP,
      predeterminePrizePool,
      predetermineJackpot,
      predetermineContestVig,
      entryFees,
      totalEntrants,
      maxEntriesPerUser,
      lockTime,
      name,
      NoOfPicks,
    } = data;

    const inputData: PartnerUpdateTouchdownInputDto = {
      id: touchdownId,
      name,
      entryFees,
      allowedPick: NoOfPicks,
      prizePool: {
        id: prizePoolId,
        startDateTime: dayjs(selectedDate)
          .tz(EST_TIME_ZONE, true)
          .startOf("day")
          .utc()
          .toISOString(),
        endDateTime: dayjs(selectedDate)
          .tz(EST_TIME_ZONE, true)
          .endOf("day")
          .utc()
          .toISOString(),
        allowedSports: selectedSports,
        lockTime,
        totalEntrants,
        maxEntriesPerUser,
        totalPrize: predeterminePrizePool,
        gppAmount: predetermineDailyGPP,
        jackpotAmount: predetermineJackpot,
        contestVig: predetermineContestVig,
      },
    };

    updateTouchdownMutate(
      { data: inputData },
      {
        onSuccess: () => {
          enqueueSnackbar("Touchdown updated successfully !", {
            variant: "success",
          });
          refetchTouchdown();
          queryClient.invalidateQueries(QueryKeys.FetchPartnerTouchdown);
          queryClient.invalidateQueries(QueryKeys.FetchPartnerTouchdownByDate);
          queryClient.invalidateQueries(QueryKeys.GetPartnerTouchdownAnalytics);
          queryClient.invalidateQueries(QueryKeys.GetPartnerActiveTouchdowns);
        },
        onError: (error: any) => {
          enqueueSnackbar(parseGQLError(error, "Unable to create Touchdown."), {
            variant: "error",
          });
        },
      }
    );
  };

  const onFormSubmit = (data: PartnerTouchdownFormValues) => {
    const { supportedSports } = initPrizePoolCreationData;

    const updatedValues = Object.entries(data).reduce(
      (acc, [key, value]) => {
        acc[key] = Number(value);
        return acc;
      },
      { supportedSports } as any
    );

    const lockTime: dayjs.Dayjs = data.lockTime;

    if (selectedDate) {
      updatedValues.lockTime = dayjs(selectedDate)
        .set("hour", lockTime.hour())
        .set("minute", lockTime.minute())
        .tz(EST_TIME_ZONE, true)
        .toISOString();
    } else {
      updatedValues.lockTime = dayjs(weekRange[0])
        .set("hour", lockTime.hour())
        .set("minute", lockTime.minute())
        .tz(EST_TIME_ZONE, true)
        .toISOString();
    }

    const { id: touchdownId, prizePools } = touchdownInfo || {};
    if (touchdownId) {
      const prizeDate = dayjs(prizePools?.[activeBox].startDate).format(
        YYYY_MM_DD
      );
      updatedValues.status = "DRAFT";
      updatedValues.touchdownId = touchdownId;
      updatedValues.prizeDate = prizeDate;
      updatedValues.predetermineDailyJackpot = Number(data.predetermineJackpot);
      createOrUpdatePrizePool(
        { ...updatedValues, name: data.name },
        selectedPrizePoolId,
        touchdownId
      );
    } else {
      createTouchdown({ ...updatedValues, name: data.name });
    }
  };

  const publishTodayPrizePool = () => {
    const { prizePoolId } = touchdownInfo?.prizePools?.[activeBox] || {};
    if (!prizePoolId) {
      enqueueSnackbar("Prize Pool not found.", {
        variant: "error",
      });
      return;
    }

    publishPrizePoolMutate(
      { prizePoolId },
      {
        onSuccess: () => {
          enqueueSnackbar("Prize pool updated successfully. !", {
            variant: "success",
          });
          refetchTouchdown();
          queryClient.invalidateQueries(QueryKeys.FetchPartnerTouchdown);
          queryClient.invalidateQueries(QueryKeys.FetchPartnerTouchdownByDate);
          queryClient.invalidateQueries(QueryKeys.GetPartnerTouchdownAnalytics);
          queryClient.invalidateQueries(QueryKeys.GetPartnerActiveTouchdowns);
        },
        onError: (error: any) => {
          enqueueSnackbar(
            parseGQLError(error, "Unable to update prize pool."),
            {
              variant: "error",
            }
          );
        },
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Box marginBottom={theme.spacing(3)}>
        <Typography
          style={{
            display: "flex",
            alignItems: "center",
            color: "#212121",
            textDecoration: "none",
            cursor: "pointer",
          }}
          onClick={() =>
            partnerId
              ? navigate("/touchdown")
              : isFromHistory
              ? setViewDetails(null)
              : navigate(-1)
          }
        >
          <WestIcon
            style={{ fontSize: "16px", marginRight: theme.spacing(1) }}
          />{" "}
          Back
        </Typography>
      </Box>

      <Card
        elevation={0}
        sx={{
          border: "1px solid #EEE8DC",
          padding: theme.spacing(1),
          marginBottom: theme.spacing(3),
        }}
      >
        <CardContent>
          {isFromHistory || touchdownData?.fetchPartnerTouchdownById?.name ? (
            <Grid container spacing={2}>
              <Grid
                item
                xs={4}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <StyledTitle htmlFor="title">
                  {touchdownData?.fetchPartnerTouchdownById?.name}
                </StyledTitle>
                <StyledDate htmlFor="title">
                  {formatDate(
                    touchdownData?.fetchPartnerTouchdownById?.startDateTime!
                  )}{" "}
                  to{" "}
                  {formatDate(
                    touchdownData?.fetchPartnerTouchdownById?.endDateTime!
                  )}
                </StyledDate>
              </Grid>
              {!isFromHistory && (
                <Grid
                  item
                  xs={8}
                  justifyContent="flex-end"
                  alignItems="end"
                  display="flex"
                >
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ width: "140px", height: "40px" }}
                    disabled={!weekRange.length || isLoading || disable}
                  >
                    Save
                  </Button>
                </Grid>
              )}
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={4} sx={{ flexDirection: "row" }}>
                <FormControl variant="standard" fullWidth>
                  <StyledLabel htmlFor="SelectWeek">Select Week</StyledLabel>
                  <WeekPicker
                    handleDateChange={handleDateChange}
                    value={weekRange?.[0] ? dayjs(weekRange[0]) : null}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={8}
                justifyContent="flex-end"
                alignItems="end"
                display="flex"
              >
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ width: "140px", height: "40px" }}
                  disabled={!weekRange.length || isLoading || disable}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2} sx={{ marginTop: theme.spacing(1) }}>
            <Grid item xs={2}>
              <FormControl variant="standard" fullWidth>
                <StyledLabel htmlFor="NoOfPicks">No of picks</StyledLabel>
                <StyledInput
                  id="NoOfPicks"
                  placeholder="Enter no of picks"
                  disabled={disable || isLived || isFromHistory}
                  aria-describedby="entry-fee"
                  {...register("NoOfPicks", {
                    ...NumberOfPicksValidationSchema,
                    onChange: calculateValues,
                  })}
                  error={!!errors?.NoOfPicks}
                  helperText={errors?.NoOfPicks?.message}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl variant="standard" fullWidth>
                <StyledLabel htmlFor="name">Touchdown name</StyledLabel>
                <StyledInput
                  id="name"
                  placeholder="Touchdown name"
                  disabled={disable || isLived || isFromHistory}
                  aria-describedby="touchdown-name"
                  {...register("name", {
                    ...StringValidationSchema,
                  })}
                  error={!!errors?.name}
                  helperText={errors?.name?.message}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl variant="standard" fullWidth>
                <StyledLabel htmlFor="EntryFee">entry Fee</StyledLabel>
                <StyledInput
                  id="EntryFee"
                  disabled={disable || isLived || isFromHistory}
                  aria-describedby="entry-fee"
                  InputProps={{
                    "aria-label": "entry fee",
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  {...register("entryFees", {
                    ...NumberValidationSchema,
                    onChange: calculateValues,
                  })}
                  error={!!errors?.entryFees}
                  helperText={errors?.entryFees?.message}
                />
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {weekRange.length ? (
        <Box>
          <Card
            elevation={0}
            sx={{ border: "1px solid #EEE8DC", padding: theme.spacing(1) }}
          >
            <CardContent>
              <Box marginBottom={theme.spacing(3)}>
                <FormControl variant="standard" fullWidth>
                  <Typography marginBottom={theme.spacing(1)} fontSize="14px">
                    Select Day
                  </Typography>
                  {touchdownInfo?.prizePools?.length ? (
                    <ToggleButtonGroup
                      color="primary"
                      exclusive
                      aria-label="Platform"
                      style={{ width: "100%" }}
                    >
                      {touchdownInfo.prizePools.map(
                        (
                          {
                            prizePoolId,
                            status,
                            startDate,
                          }: PrizePoolCreationFields,
                          index
                        ) => (
                          <ToggleButton
                            key={index}
                            value={startDate || ""}
                            style={{
                              margin: index !== 0 ? "0 8px" : "0",
                              borderRadius: "2px",
                              textAlign: "center",
                              padding: 0,
                              width: "100%",
                              flex: 1,
                              border: `1.5px solid ${
                                statusToStyleMap[
                                  activeBox === index
                                    ? `${status}_TEXT`
                                    : `${status}_BORDER`
                                ]
                              }`,
                              backgroundColor:
                                activeBox === index ? "transparent" : "#fff",
                            }}
                            disabled={
                              createTouchdownLoading || updateTouchdownLoading
                            }
                            onClick={() =>
                              handlePrizePoolChange(index, {
                                prizePoolId,
                                status,
                                startDate,
                              })
                            }
                          >
                            <Box sx={{ width: "100%" }}>
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                                sx={{ padding: `${theme.spacing(1)}` }}
                                color="black"
                              >
                                <Typography
                                  variant="body1"
                                  fontSize={20}
                                  fontWeight={600}
                                  sx={{
                                    marginRight: theme.spacing(1),
                                  }}
                                >
                                  {/* Display Date */}
                                  {dayjs(startDate).format("DD")}
                                </Typography>
                                {/* Display Day */}
                                {dayjs(startDate).format("ddd")}
                              </Stack>
                              <Typography
                                sx={{
                                  backgroundColor:
                                    statusToStyleMap[`${status}_BACKGROUND`],
                                  width: "100%",
                                  color: statusToStyleMap[`${status}_TEXT`],
                                  fontSize: "14px",
                                  fontWeight: "600",
                                }}
                              >
                                {status}
                              </Typography>
                            </Box>
                          </ToggleButton>
                        )
                      )}
                    </ToggleButtonGroup>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Box>
              <Box marginBottom={theme.spacing(3)}>
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <FormControl variant="standard" fullWidth>
                      <StyledLabel htmlFor="LockTime">Lock Time</StyledLabel>
                      <Controller
                        name="lockTime"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Please fill this field",
                          },
                        }}
                        render={({
                          field: { onChange, value },
                          fieldState,
                        }) => (
                          <TimePicker
                            value={value}
                            onChange={onChange}
                            disabled={disable || isFromHistory}
                            style={{
                              input: {
                                padding: "9px 12px",
                              },
                              p: { fontSize: "12px", color: "black" },
                            }}
                            error={fieldState?.error?.message}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl variant="standard" fullWidth>
                      <StyledLabel htmlFor="Entrants">Entrants</StyledLabel>
                      <StyledInput
                        id="totalEntrants"
                        disabled={disable || isFromHistory}
                        aria-describedby="entrants"
                        InputProps={{
                          "aria-label": "entrants",
                        }}
                        placeholder="Enter Entrants"
                        {...register("totalEntrants", {
                          ...NumberValidationSchema,
                          onChange: calculateValues,
                        })}
                        error={!!errors?.totalEntrants}
                        helperText={errors?.totalEntrants?.message}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl variant="standard" fullWidth>
                      <StyledLabel htmlFor="MaxEntries">
                        Max Entries
                      </StyledLabel>
                      <StyledInput
                        id="maxEntriesPerUser"
                        disabled={disable || isFromHistory}
                        aria-describedby="max-entries"
                        InputProps={{
                          "aria-label": "max entries",
                        }}
                        {...register(
                          "maxEntriesPerUser",
                          NumberValidationSchema
                        )}
                        error={!!errors?.maxEntriesPerUser}
                        helperText={errors?.maxEntriesPerUser?.message}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3} />
                  {showPublishButton() && (
                    <Grid
                      item
                      xs={3}
                      display={"flex"}
                      alignItems={"end"}
                      justifyContent={"end"}
                    >
                      <Button
                        variant="contained"
                        size="large"
                        sx={{ padding: "9px 35px" }}
                        disabled={
                          !weekRange.length ||
                          isLoading ||
                          disable ||
                          isFromHistory
                        }
                        onClick={() => {
                          publishTodayPrizePool();
                        }}
                      >
                        Publish
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Box>
              <Box marginBottom={theme.spacing(9)}>
                <Grid container spacing={2}>
                  {/* ====================  Select sports ==================== */}
                  <Grid item xs={12}>
                    <Typography variant="h5" fontWeight={500}>
                      Select sports
                    </Typography>
                    <Box
                      sx={{
                        padding: theme.spacing(2),
                        marginTop: theme.spacing(1),
                        height: "100%",
                      }}
                    >
                      <Grid container spacing={2}>
                        <FormGroup row>
                          <FormControlLabel
                            sx={{
                              opacity: disable || isFromHistory ? "0.7" : "1",
                              pointerEvents:
                                disable || isFromHistory ? "none" : "all",
                              color: errors?.selectedSport
                                ? "#d32f2f"
                                : "currentColor",

                              svg: {
                                fill: errors?.selectedSport
                                  ? "#d32f2f"
                                  : "currentColor",
                              },
                            }}
                            control={
                              <Checkbox
                                checked={isSelectAllChecked}
                                onChange={(event) =>
                                  disable || isFromHistory
                                    ? {}
                                    : handleSelectAllChange(event)
                                }
                              />
                            }
                            label="All"
                          />
                          {partnerSports &&
                            partnerSports?.length > 0 &&
                            partnerSports?.map((sport: any, index: number) => {
                              return (
                                <FormControlLabel
                                  sx={{
                                    opacity:
                                      isFromHistory || disable || !sport.enabled
                                        ? "0.5"
                                        : "1",
                                    pointerEvents:
                                      disable || isFromHistory || !sport.enabled
                                        ? "none"
                                        : "all",
                                    color:
                                      errors?.selectedSport && sport.enabled
                                        ? "#d32f2f"
                                        : "currentColor",
                                    svg: {
                                      fill:
                                        errors?.selectedSport && sport.enabled
                                          ? "#d32f2f"
                                          : "currentColor",
                                    },
                                  }}
                                  control={
                                    <Checkbox
                                      checked={selectedSports?.some(
                                        (id: number) => id === sport.id
                                      )}
                                      {...register("selectedSport", {
                                        ...StringValidationSchema,
                                      })}
                                      onChange={(event) =>
                                        isFromHistory ||
                                        disable ||
                                        !sport.enabled
                                          ? {}
                                          : handleCheckboxChange(event, sport)
                                      }
                                    />
                                  }
                                  label={sport?.name}
                                />
                              );
                            })}
                        </FormGroup>
                      </Grid>
                      {errors?.selectedSport && (
                        <StyledError>Please select sports</StyledError>
                      )}
                    </Box>
                  </Grid>

                  {/* ====================  Daily Metrics ==================== */}
                  <Grid item xs={12}>
                    <Typography variant="h5" fontWeight={500}>
                      Daily Metrics
                    </Typography>
                    <Box
                      sx={{
                        border: "1px solid #EEE8DC",
                        backgroundColor: "#FFFEFB",
                        padding: theme.spacing(2),
                        marginTop: theme.spacing(1),
                        height: "100%",
                      }}
                    >
                      <Grid container spacing={2}>
                        {touchdownInfo?.prizePools[activeBox].status ===
                        PrizePoolStatus.Completed ? (
                          <Table style={{ borderCollapse: "collapse" }}>
                            <TableHead>
                              <TableRow>
                                <StyledTableCell className="table_head"></StyledTableCell>
                                <StyledTableCell className="table_head">
                                  Pre
                                </StyledTableCell>
                                <StyledTableCell className="table_head">
                                  Post
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {metricsData?.map(
                                (
                                  {
                                    name,
                                    actual,
                                    predetermine,
                                    tooltipValue,
                                  }: any,
                                  index: number
                                ) => (
                                  <TableRow key={index}>
                                    <StyledTableCell>
                                      {name}{" "}
                                      {
                                        <Tooltip title={tooltipValue} arrow>
                                          <IconButton>
                                            <InfoOutlinedIcon fontSize="small" />
                                          </IconButton>
                                        </Tooltip>
                                      }
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {predetermine}
                                    </StyledTableCell>
                                    <StyledTableCell>{actual}</StyledTableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        ) : (
                          metricsData.map(
                            ({
                              labelName,
                              formItemName,
                              tooltipValue,
                            }: any) => (
                              <Grid item xs={3} key={formItemName}>
                                <FormControl variant="standard" fullWidth>
                                  <Stack direction="row" alignItems="center">
                                    <StyledLabel htmlFor={labelName}>
                                      {labelName}
                                    </StyledLabel>
                                    <Tooltip title={tooltipValue} arrow>
                                      <IconButton>
                                        <InfoOutlinedIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  </Stack>
                                  <StyledInput
                                    id={formItemName}
                                    disabled={disable || isFromHistory}
                                    aria-describedby="prize pool"
                                    InputProps={{
                                      "aria-label": "prize pool",
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          $
                                        </InputAdornment>
                                      ),
                                    }}
                                    {...(formItemName ===
                                    "predetermineWeeklyReserve"
                                      ? register(formItemName)
                                      : register(
                                          formItemName,
                                          NumberValidationSchema
                                        ))}
                                    error={!!errors?.[formItemName]}
                                    helperText={errors?.[formItemName]?.message}
                                  />
                                </FormControl>
                              </Grid>
                            )
                          )
                        )}
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Box>
      ) : (
        <EmptyContainer />
      )}
    </form>
  );
}

export default TouchdownCreation;
