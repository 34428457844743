import SportCard from "@molecules/SportCard/SportCard";
import { Box, Button, CircularProgress } from "@mui/material";
import { CustomModal } from "@src/components/templates/Modal/CustomModal";
import { QueryKeys } from "@src/constants/QueryKeys";
import {
  SportOrderInput,
  useEnabledSportMutation,
  useGetSportsLogoQuery,
  useGetSportsQuery,
  useOrderSportsMutation,
} from "@src/graphql/graphql.generated";
import { parseGQLError } from "@src/utils/parse-error";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useQueryClient } from "react-query";
import { AddNewSportForm } from "./AddNewSportForm";

interface Question {
  id?: number;
  question: string;
  metric: string;
}

interface Sport {
  id: number;
  name: string;
  logoUrl: string;
  winCriteria: "LOWER" | "HIGHER";
  enabled: boolean;
  questions?: Question[];
}

export function TitleOfTheContest() {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const {
    data: { getSports: allSportsData } = {},
    isLoading: isTitleContestsLoading,
    isError: isTitleContestError,
  } = useGetSportsQuery({});
  const { data: { getSportsLogo: sportLogosData } = {} } =
    useGetSportsLogoQuery();
  const { mutate: enableOrDisable } = useEnabledSportMutation();
  const { mutate: orderSports } = useOrderSportsMutation();

  const [showSportModal, setShowSportModal] = useState<boolean>(false);
  const [addNewSportFormData, setAddNewSportFormData] = useState<any>("");
  const [sports, setSports] = useState(allSportsData);

  const handleEditDetails = (index: number) => {
    const formData = allSportsData?.[index];
    setAddNewSportFormData(formData);
    setShowSportModal(true);
  };

  const handleEnableOrDisable = (index: number): Promise<boolean> => {
    return new Promise((resolve) => {
      const formData = allSportsData?.[index];
      if (formData) {
        enableOrDisable(
          {
            data: {
              enabled: !formData.enabled,
              sportId: formData?.id!,
            },
          },
          {
            onSuccess: () => {
              enqueueSnackbar(
                `Sport ${
                  formData?.enabled ? "disabled" : "enabled"
                } successfully ! `,
                {
                  variant: "success",
                }
              );
              queryClient.invalidateQueries(QueryKeys.GetSports);
              queryClient.invalidateQueries(QueryKeys.GetSport);

              queryClient.invalidateQueries(QueryKeys.CountHubContests);
              resolve(true);
            },
            onError: (error: any) => {
              enqueueSnackbar(
                parseGQLError(
                  error,
                  `Error while ${
                    formData?.enabled ? "disabling" : "enabling"
                  } creating sport.`
                ),
                {
                  variant: "error",
                }
              );
            },
          }
        );
      }
    });
  };

  const handleDrop = () => {
    const request: SportOrderInput[] | undefined = sports?.map(
      (sport, index) => {
        return {
          sportId: sport.id!,
          orderIndex: index + 1,
        };
      }
    );

    request &&
      orderSports(
        { data: request },
        {
          onSuccess: () => {
            enqueueSnackbar("Sport order updated successfully.", {
              variant: "success",
            });
            queryClient.invalidateQueries(QueryKeys.GetSports);
            queryClient.invalidateQueries(QueryKeys.GetSport);
          },
        }
      );
  };

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setSports((prevSports) => {
      if (prevSports) {
        const result = Array.from(prevSports);
        const [removed] = result.splice(dragIndex, 1);
        result.splice(hoverIndex, 0, removed);
        return result;
      }
    });
  }, []);

  useEffect(() => {
    setSports(allSportsData ?? []);
  }, [allSportsData]);

  return (
    <Box>
      <Box sx={{ textAlign: "end" }}>
        <Button
          variant="outlined"
          size="large"
          sx={{
            fontSize: "16px",
            lineHeight: "22px",
            border: "1px solid #B69056",
            marginBottom: "12px",
          }}
          onClick={() => setShowSportModal(true)}
        >
          Add new sport
        </Button>
      </Box>

      <CustomModal
        isOpen={showSportModal}
        onClose={() => {
          setShowSportModal(false);
        }}
        title={addNewSportFormData ? "UPDATE SPORT" : "ADD NEW SPORT"}
      >
        <AddNewSportForm
          addNewSportFormData={addNewSportFormData}
          closeSportModal={() => {
            setShowSportModal(false);
            setAddNewSportFormData("");
          }}
          sportLogosData={sportLogosData!}
        />
      </CustomModal>

      {isTitleContestsLoading || isTitleContestError ? (
        <Box py={2} sx={{ fontSize: "20px", textAlign: "center" }}>
          Loading...
          <CircularProgress size={24} />
        </Box>
      ) : (
        <DndProvider backend={HTML5Backend}>
          {sports?.map((sport, index) => (
            <SportCard
              key={sport.id}
              onDrop={handleDrop}
              moveCard={moveCard}
              orderIndex={index}
              sport={sport}
              sportId={sport.id!}
              onEdit={handleEditDetails}
              onEnableOrDisable={handleEnableOrDisable}
              sports={sports}
            />
          ))}
        </DndProvider>
      )}
    </Box>
  );
}
