import {
  PrizePoolStatus,
  SupportedSports,
} from "@src/graphql/graphql.generated";
import dayjs from "dayjs";

export interface TouchdownCreationFields {
  touchdownId: number;
  startDate: dayjs.Dayjs | null;
  endDate: dayjs.Dayjs | null;
  // weeklyPrize: number;
  touchDownType: string;
  totalWeeklyReserve: number;
  totalActualWeeklyReserve: number;
  totalTopPropVig: number;
  totalActualTopPropVig: number;
  prizePools: Array<PrizePoolCreationFields> | [];
}

export interface F2PTouchdownCreationFields {
  id: number;
  startDate: dayjs.Dayjs | null;
  endDate: dayjs.Dayjs | null;
  prizePools: Array<PrizePoolCreationFields> | [];
}

export interface PartnerTouchdownCreationFields {
  id: number;
  startDate: dayjs.Dayjs | null;
  endDate: dayjs.Dayjs | null;
  totalWeeklyReserve: number;
  totalActualWeeklyReserve: number;
  totalTopPropVig: number;
  totalActualTopPropVig: number;
  prizePools: Array<PrizePoolCreationFields> | [];
}

export interface PrizePoolCreationFields {
  prizePoolId: number;
  startDate: dayjs.Dayjs | null;
  entryFees: number;
  lockTime: dayjs.Dayjs | null;
  totalEntrants: number;
  maxEntriesPerUser: number;
  supportedSports: Array<SupportedSports>;
  predeterminePrizePool: number;
  predetermineDailyGPP: number;
  predetermineWeeklyReserve: number;
  predetermineJackpot: number;
  predetermineContestVig: number;
  status: PrizePoolStatus;
  actualPrizePool: number;
  actualDailyGPP: number;
  actualWeeklyReserve: number;
  actualJackpot: number;
  actualContestVig: number;
  actualRolloverVig: number;
  userEntryCount: number;
  nbaContestsCount?: number;
  nflContestsCount?: number;
  soccerContestsCount?: number;
  allowedSports?: Array<number>;
  id?: number;
}

export interface PartnerPrizePoolCreationFields {
  startDateTime: dayjs.Dayjs | null;
  endDateTime: dayjs.Dayjs | null;
  supportedSports: Array<SupportedSports>;
  lockTime: dayjs.Dayjs | null;
  totalEntrants: number;
  maxEntriesPerUser: number;
  predeterminePrizePool: number;
  predetermineDailyGPP: number;
  predetermineJackpot: number;
  predetermineContestVig: number;
  entryFees: number;
  name: string;
  allowedPick: number;
  NoOfPicks: number;
}

export interface WeeklyPrizeObj {
  prizes: Array<{ id: number; value: number }>;
}

export interface TouchdownFormValues {
  name: string;
  entryFees: number;
  lockTime: any;
  totalEntrants: number;
  maxEntriesPerUser: number;
  predeterminePrizePool: number;
  predetermineJackpot: number;
  predetermineDailyGPP: number;
  predetermineWeeklyReserve: number;
  predetermineContestVig: number;
  [key: string]: any;
}

export interface PartnerTouchdownFormValues {
  // name: any;
  entryFees: number;
  lockTime: any;
  totalEntrants: number;
  maxEntriesPerUser: number;
  predeterminePrizePool: number;
  predetermineJackpot: number;
  predetermineDailyGPP: number;
  predetermineWeeklyReserve: number;
  predetermineContestVig: number;
  [key: string]: number;
}

export const initTouchdownCreationData: TouchdownCreationFields = {
  touchdownId: 0,
  startDate: null,
  endDate: null,
  // weeklyPrize: 0,
  touchDownType: "7 FOR 7",
  totalWeeklyReserve: 0,
  totalActualWeeklyReserve: 0,
  totalTopPropVig: 0,
  totalActualTopPropVig: 0,
  prizePools: [],
};

export const initPrizePoolCreationData: PrizePoolCreationFields = {
  prizePoolId: 0,
  startDate: null,
  entryFees: 0,
  totalEntrants: 0,
  maxEntriesPerUser: 0,
  lockTime: null,
  supportedSports: [
    SupportedSports.Nba,
    SupportedSports.Nfl,
    SupportedSports.Hub,
    SupportedSports.Soccer,
  ],
  predeterminePrizePool: 0,
  predetermineDailyGPP: 0,
  predetermineWeeklyReserve: 0,
  predetermineJackpot: 0,
  predetermineContestVig: 0,
  status: PrizePoolStatus.Pending, // default status for upcoming | current weeks
  actualPrizePool: 0,
  actualDailyGPP: 0,
  actualWeeklyReserve: 0,
  actualJackpot: 0,
  actualContestVig: 0,
  actualRolloverVig: 0,
  userEntryCount: 0,
};

export interface IWeeklyHistory {
  touchdownId: number;
  weekDate: string;
  entryFee: number;
  totalUserEntry: number;
  totalEntryFees: number;
  totalContestVig: number;
  totalRolloverVig: number;
  totalWeeklyReserve: number;
  weeklyPrize: number;
  profit: number;
  totalSubscribers: number;
  moneyAddedByTopprop: number;
  sportContestCount: { NBA: number; NFL: number };
}
