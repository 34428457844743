import { useEffect, useState } from "react";
import { usePermissions } from "./usePermissions";

export function useIsSuperuser(): boolean {
  const [isSuperuser, setIsSuperuser] = useState(false);
  const { data } = usePermissions();

  useEffect(() => {
    if (!data) {
      return;
    }
    setIsSuperuser(data.isSuperuser);
  }, [data]);

  return isSuperuser;
}
