import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  IconButton,
  OutlinedInput,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { theme } from "@src/constants/theme";

import MultiSelectAutocomplete from "@atoms/MultiSelectAutocomplete/MultiSelectAutocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import { styled } from "@mui/styles";
import { QueryKeys } from "@src/constants/QueryKeys";
import {
  EST_TIME_ZONE,
  TOUCHDOWN_CONTEST_STATUSES,
} from "@src/constants/Touchdown";
import {
  useCreateF2PSoccerTouchdownContestMutation,
  useDeleteF2PSoccerTouchdownContestMutation,
  useGetF2PSoccerTouchdownContestPlayersQuery,
  useGetF2PSoccerTouchdownContestsQuery,
  useGetF2PSportsQuery,
} from "@src/graphql/graphql.generated";
import { PrizePoolCreationFields } from "@src/types/touchdown/contest";
import { parseGQLError } from "@src/utils/parse-error";
import { roundToTwoDecimalPlaces } from "@src/utils/utils";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";

const StyledTypoItem = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 500,
}));

const StyledInput = styled(OutlinedInput)(({ theme: any }) => ({
  marginTop: theme.spacing(1),
  "& input": {
    padding: "9px 12px 9px 12px",

    "&:focus": {
      outline: `none`,
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: `#E2D3BB !important`,
    "&:focus": {
      outline: `none`,
    },
  },
}));

interface ILabelId {
  [key: number]: number;
  label: string;
  id: number;
  gameId: number;
  projectedAssists: number;
  projectedGoals: number;
  projectedPasses: number;
  projectedSaves: number;
  projectedShots: number;
  projectedShotsOnGoal: number;
  projectedTackles: number;
}

interface SoccerQues {
  id: number;
  question: string;
  label: string;
  metric: string;
}

interface SoccerProps {
  selectedDate: string;
  updateCount: (count: number) => void;
  prizePool: PrizePoolCreationFields | null;
  partnerId?: number;
  prizePoolId: number;
  touchdowns: any;
}

interface OptionType {
  prizePoolId: number;
  touchdown: {
    touchdownId: number;
    touchdownName: string;
  };
}

export const playerStats: Array<{ key: string; label: string; value: string }> =
  [
    { key: "shots", label: "Shots", value: "projectedShots" },
    { key: "saves", label: "Saves", value: "projectedSaves" },
    { key: "passes", label: "Passes", value: "projectedPasses" },
    {
      key: "shotsOnGoal",
      label: "Shots On Goal",
      value: "projectedShotsOnGoal",
    },
    { key: "goals", label: "Goals", value: "projectedGoals" },
    { key: "assists", label: "Assists", value: "projectedAssists" },
    { key: "tackles", label: "Tackles", value: "projectedTackles" },
  ];

export function SoccerCreation(props: SoccerProps) {
  const {
    selectedDate,
    updateCount,
    prizePool,
    partnerId,
    prizePoolId,
    touchdowns,
  } = props;
  const [mainPlayer, setMainPlayer] = useState<ILabelId | null>();
  const [selectedQuestion, setSelectedQuestion] = useState<SoccerQues | null>(
    null
  );
  const [statName, setStatName] = useState<{
    key: string;
    label: string;
    value: string;
  } | null>(null);
  const [secondPlayer, setSecondPlayer] = useState<ILabelId | null>();
  const [mainPlayerBonusPoints, setMainPlayerBonusPoints] = useState<number>(0);
  const [secondPlayerBonusPoints, setSecondPlayerBonusPoints] =
    useState<number>(0);
  const [selectedOptions, setSelectedOptions] = useState<OptionType[]>([]);

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { data: soccerData } = useGetF2PSportsQuery({
    partnerId: partnerId!,
    type: "both",
  });

  // We get the next 5 days projections points in advanced, so after the next 5 days we are not allowed to create match-ups
  const next5Days = dayjs().tz(EST_TIME_ZONE).add(5, "days").endOf("day");
  const disabled =
    dayjs(prizePool?.startDate).isAfter(next5Days) ||
    prizePool?.status === "COMPLETED";

  const { data: players } = useGetF2PSoccerTouchdownContestPlayersQuery(
    { date: selectedDate },
    {
      staleTime: 300_000, // 5 min
      enabled: !disabled,
    }
  );
  const { data: touchdownContests } = useGetF2PSoccerTouchdownContestsQuery(
    {
      date: selectedDate,
      prizePoolId: prizePoolId!,
    },
    {
      staleTime: 300_000, // 5 min
      enabled: prizePoolId !== undefined && prizePoolId !== null,
    }
  );

  useEffect(() => {
    updateCount(touchdownContests?.getF2PSoccerTouchdownContests?.length!);
  }, [touchdownContests]);
  const { mutate: createSoccerTouchdownContest } =
    useCreateF2PSoccerTouchdownContestMutation();
  const { mutate: deleteSoccerTouchdownContest } =
    useDeleteF2PSoccerTouchdownContestMutation();

  const playersLabelValues: Array<ILabelId> | undefined = useMemo(
    () =>
      players?.getF2PSoccerTouchdownContestPlayers
        .map((player) => {
          const {
            id,
            fullName,
            gameId,
            projectedAssists,
            projectedGoals,
            projectedPasses,
            projectedSaves,
            projectedShots,
            projectedShotsOnGoal,
            projectedTackles,
          } = player;
          return {
            label: fullName,
            id: id,
            gameId: gameId,
            projectedAssists: projectedAssists ?? 0,
            projectedGoals: projectedGoals ?? 0,
            projectedPasses: projectedPasses ?? 0,
            projectedSaves: projectedSaves ?? 0,
            projectedShots: projectedShots ?? 0,
            projectedShotsOnGoal: projectedShotsOnGoal ?? 0,
            projectedTackles: projectedTackles ?? 0,
          };
        })
        .sort((player, nextPlayer) =>
          player.label < nextPlayer.label ? -1 : 1
        ) || [],
    [players?.getF2PSoccerTouchdownContestPlayers]
  );

  const soccerQuestions: Array<SoccerQues> | undefined = useMemo(
    () =>
      soccerData?.getF2PSports
        ?.find((sport) => sport.name?.toLocaleLowerCase() === "soccer")
        ?.questions?.map((ques) => {
          const { id, metric, question } = ques;
          return {
            id,
            metric,
            question,
            label: question,
          };
        }) || [],
    [soccerData?.getF2PSports]
  );

  useEffect(() => {
    setMainPlayer(null);
    setSecondPlayer(null);
    setStatName(null);
    setSelectedQuestion(null);
  }, [selectedDate]);

  const handleChangeMainPlayer = (newValue: ILabelId | null) => {
    if (newValue) {
      setMainPlayer(newValue);
      if (secondPlayer && statName) {
        const p1Points: number = newValue[statName.value as any];
        const p2Points: number = secondPlayer[statName.value as any];

        if (p1Points > p2Points) {
          setSecondPlayerBonusPoints(
            roundToTwoDecimalPlaces(p1Points - p2Points)
          );
          setMainPlayerBonusPoints(0);
        } else {
          setMainPlayerBonusPoints(
            roundToTwoDecimalPlaces(p2Points - p1Points)
          );
          setSecondPlayerBonusPoints(0);
        }
      }
    }
  };

  const handleChangeSecondPlayer = (newValue: ILabelId | null) => {
    if (newValue) {
      setSecondPlayer(newValue);
      if (mainPlayer && statName) {
        const p1Points: number = mainPlayer[statName.value as any];
        const p2Points: number = newValue[statName.value as any];

        if (p1Points > p2Points) {
          setSecondPlayerBonusPoints(
            roundToTwoDecimalPlaces(p1Points - p2Points)
          );
          setMainPlayerBonusPoints(0);
        } else {
          setMainPlayerBonusPoints(
            roundToTwoDecimalPlaces(p2Points - p1Points)
          );
          setSecondPlayerBonusPoints(0);
        }
      }
    }
  };

  const handleChangeWinningCriteria = (
    newValue: {
      key: string;
      label: string;
      value: string;
    } | null
  ) => {
    if (newValue) {
      setStatName(newValue);
      if (mainPlayer && secondPlayer) {
        const p1Points: number = mainPlayer[newValue.value as any];
        const p2Points: number = secondPlayer[newValue.value as any];
        if (p1Points > p2Points) {
          setSecondPlayerBonusPoints(
            roundToTwoDecimalPlaces(p1Points - p2Points)
          );
          setMainPlayerBonusPoints(0);
        } else {
          setMainPlayerBonusPoints(
            roundToTwoDecimalPlaces(p2Points - p1Points)
          );
          setSecondPlayerBonusPoints(0);
        }
      }
    }
  };

  const handleSubmit = () => {
    if (
      !mainPlayer ||
      !secondPlayer ||
      mainPlayer.id === secondPlayer.id ||
      !selectedQuestion ||
      !statName
    ) {
      enqueueSnackbar("Please select all fields values.", {
        variant: "error",
      });
      return;
    }
    const data = {
      question: selectedQuestion.question,
      metric: selectedQuestion.metric,
      startTime: selectedDate,
      player1Id: mainPlayer.id,
      player2Id: secondPlayer.id,
      player1GameId: mainPlayer.gameId,
      player2GameId: secondPlayer.gameId,
      player1ProjFantasyPoints: mainPlayer[statName.value as any],
      player2ProjFantasyPoints: secondPlayer[statName.value as any],
      player1BonusPoints: mainPlayerBonusPoints,
      player2BonusPoints: secondPlayerBonusPoints,
      statName: statName.key,
      prizePoolIds: selectedOptions.map((item) => item.prizePoolId),
    };
    createSoccerTouchdownContest(
      {
        data: data,
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Touchdown contest created successfully!", {
            variant: "success",
          });
          queryClient.invalidateQueries(
            QueryKeys.GetF2PSoccerTouchdownContests
          );
          queryClient.invalidateQueries(QueryKeys.FetchF2PHubContests);
        },
        onError: (err: any) => {
          enqueueSnackbar(parseGQLError(err), {
            variant: "error",
          });
        },
      }
    );
  };

  const handleDelete = (id: number) => {
    deleteSoccerTouchdownContest(
      { data: { status: TOUCHDOWN_CONTEST_STATUSES.DELETED, id } },
      {
        onSuccess: () => {
          enqueueSnackbar("Touchdown contest deleted successfully!", {
            variant: "success",
          });
          queryClient.invalidateQueries(
            QueryKeys.GetF2PSoccerTouchdownContests
          );
          queryClient.invalidateQueries(QueryKeys.FetchF2PHubContests);
        },
        onError: (error: any) => {
          enqueueSnackbar(parseGQLError(error), {
            variant: "error",
          });
        },
      }
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={7}>
        {touchdownContests?.getF2PSoccerTouchdownContests?.length ? (
          <Box
            sx={{
              border: "1px solid #EEE8DC",
              backgroundColor: "#fff",
              padding: theme.spacing(2),
              marginTop: theme.spacing(1),
              height: "600px",
              overflowY: "scroll",
            }}
          >
            {touchdownContests?.getF2PSoccerTouchdownContests.map(
              ({
                id,
                player1,
                player2,
                player1BonusPoints,
                player2BonusPoints,
                status,
                metric,
              }) => (
                <Paper
                  key={id}
                  sx={{
                    position: "relative",
                    marginBottom: theme.spacing(2),
                    backgroundColor: `${
                      status === "cancelled"
                        ? "rgba(255, 240, 241, 1)"
                        : "#FFFEFB"
                    }`,
                    border: `${
                      status === "cancelled"
                        ? "1px solid rgba(235, 23, 69, 1)"
                        : "1px solid rgba(226, 211, 187, 1)"
                    }`,
                  }}
                  elevation={0}
                >
                  <Stack
                    sx={{ padding: 1 }}
                    justifyContent="space-between"
                    alignItems="center"
                    direction="row"
                    spacing={2}
                  >
                    {/* ==========  Player 1 ========== */}
                    <Stack flex={2}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Avatar
                          sx={{ width: 48, height: 48 }}
                          src={player1.photoUrl}
                        />
                        <Box>
                          <Stack direction="row">
                            <StyledTypoItem>{player1.firstName}</StyledTypoItem>
                            <StyledTypoItem marginLeft={theme.spacing(1)}>
                              {player1.lastName}
                            </StyledTypoItem>
                          </Stack>
                          {player1BonusPoints ? (
                            <Chip
                              label={`+${player1BonusPoints} ${metric} `}
                              size="small"
                              sx={{
                                backgroundColor: "rgba(22, 77, 52, 1)",
                                color: "#fff",
                                marginTop: "3px",
                                fontSize: "11px",
                                fontWeight: 700,
                                height: "auto",
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </Box>
                      </Stack>
                    </Stack>
                    <Typography flex={1} variant="h4">
                      Vs
                    </Typography>
                    {/* ==========  Player 2 ========== */}
                    <Stack flex={2} alignItems="flex-start">
                      <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <Avatar
                          sx={{ width: 48, height: 48 }}
                          src={player2.photoUrl}
                        />
                        <Box>
                          <Stack direction="row">
                            <StyledTypoItem>{player2.firstName}</StyledTypoItem>
                            <StyledTypoItem marginLeft={theme.spacing(1)}>
                              {player2.lastName}
                            </StyledTypoItem>
                          </Stack>
                          {player2BonusPoints ? (
                            <Chip
                              label={`+${player2BonusPoints} ${metric}`}
                              size="small"
                              sx={{
                                backgroundColor: "rgba(22, 77, 52, 1)",
                                color: "#fff",
                                marginTop: "3px",
                                fontSize: "11px",
                                fontWeight: 700,
                                height: "auto",
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </Box>
                      </Stack>
                    </Stack>
                  </Stack>
                  <IconButton
                    sx={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      bottom: 0,
                      cursor: "pointer",
                    }}
                    onClick={() => handleDelete(id)}
                  >
                    <DeleteIcon />
                  </IconButton>

                  {status === "cancelled" ? (
                    <Box sx={{ padding: 1 }}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          color: "rgba(235, 23, 69, 1)",
                          fontWeight: "500",
                        }}
                      >
                        {player1.injuryStatus === "Out" ||
                        player2.injuryStatus === "Out"
                          ? "Player Ruled out"
                          : "Contest cancelled"}
                      </Typography>
                    </Box>
                  ) : (
                    ""
                  )}
                </Paper>
              )
            )}
          </Box>
        ) : (
          <Box
            sx={{
              marginTop: theme.spacing(1),
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              border: "1px solid #EEE8DC",
              backgroundColor: "#fff",
              padding: `${theme.spacing(14)} ${theme.spacing(2)}`,
            }}
          >
            <EmojiEventsOutlinedIcon
              sx={{
                fontSize: "60px",
                color: "#EEE8DC",
              }}
            />
            <Typography>No Contest Created</Typography>
          </Box>
        )}
      </Grid>

      <Grid item xs={5}>
        <Box
          sx={{
            backgroundColor: "#FAF2E2",
            padding: theme.spacing(2),
            marginTop: theme.spacing(1),
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                {soccerQuestions && (
                  <Autocomplete
                    disabled={disabled}
                    options={soccerQuestions}
                    value={selectedQuestion}
                    clearOnEscape
                    onChange={(_props, nextValue) => {
                      setSelectedQuestion(nextValue);
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Question"
                        variant="standard"
                      />
                    )}
                  />
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                {playerStats && (
                  <Autocomplete
                    options={playerStats}
                    value={statName}
                    clearOnEscape
                    onChange={(_props, nextValue) => {
                      handleChangeWinningCriteria(nextValue);
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.key}>
                          {option.label}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Winning Criteria  "
                        variant="standard"
                      />
                    )}
                  />
                )}
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth>
                {playersLabelValues && (
                  <Autocomplete
                    disabled={disabled}
                    options={playersLabelValues}
                    value={mainPlayer}
                    clearOnEscape
                    onChange={(_props, nextValue) => {
                      handleChangeMainPlayer(nextValue);
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Main Player"
                        variant="standard"
                      />
                    )}
                  />
                )}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl variant="standard" fullWidth>
                <StyledInput
                  disabled={disabled}
                  id="mainPlayerBonusPoints"
                  type="number"
                  aria-describedby="main-player-bonus-point"
                  inputProps={{
                    "aria-label": "main player bonus point",
                  }}
                  placeholder="Bonus Point"
                  sx={{ marginTop: 0, height: "50px" }}
                  value={mainPlayerBonusPoints}
                  onChange={(ev) =>
                    setMainPlayerBonusPoints(Number(ev.target.value))
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth>
                {playersLabelValues && (
                  <Autocomplete
                    disabled={disabled}
                    options={playersLabelValues}
                    value={secondPlayer}
                    clearOnEscape
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.label}
                        </li>
                      );
                    }}
                    onChange={(_props, nextValue) => {
                      handleChangeSecondPlayer(nextValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{ mb: 4 }}
                        {...params}
                        label="Second Player"
                        variant="standard"
                      />
                    )}
                  />
                )}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl variant="standard" fullWidth>
                <StyledInput
                  disabled={disabled}
                  id="secondPlayerBonusPoints"
                  type="number"
                  aria-describedby="second-player-bonus-point"
                  inputProps={{
                    "aria-label": "main player bonus point",
                  }}
                  placeholder="Bonus Point"
                  sx={{ marginTop: 0, height: "50px" }}
                  value={secondPlayerBonusPoints}
                  onChange={(ev) =>
                    setSecondPlayerBonusPoints(Number(ev.target.value))
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <MultiSelectAutocomplete
                  options={touchdowns}
                  selectedOptions={selectedOptions}
                  onChange={setSelectedOptions}
                  label="Select Touchdown"
                  placeholder="Select Touchdowns..."
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                sx={{ fontSize: "16px" }}
                fullWidth
                onClick={handleSubmit}
              >
                Create Matchup
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
