import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, Typography, styled } from "@mui/material";
import { SignedInLayout } from "@src/components/templates/Layouts";
import { ContestAnalysis } from "@src/components/touchDown/Analytics/ContestAnalysis";
import { TouchdownAnalysis } from "@src/components/touchDown/Analytics/TouchdownAnalysis";
import { theme } from "@src/constants/theme";
import { useState } from "react";

const StyledTab = styled(Tab)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)} 0`,
  minHeight: "auto",
  justifyContent: "flex-start",
  marginRight: theme.spacing(3),
}));

export function Analytics() {
  const [value, setValue] = useState("1");

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <SignedInLayout title="Analytics">
      <Box
        marginTop={theme.spacing(3)}
        sx={{
          border: "1px solid #EEE8DC",
          backgroundColor: "#fff",
          padding: theme.spacing(2),
        }}
      >
        <TabContext value={value}>
          <Box>
            <TabList
              onChange={handleTabChange}
              aria-label="lab API tabs example"
              sx={{
                minHeight: "auto",
                "& .MuiTabs-flexContainer": {
                  borderBottom: "1px solid rgba(240, 240, 240, 1)",
                },
              }}
            >
              <StyledTab label="Touchdown Analysis" value="1" />
              <StyledTab label="Contest Analysis" value="2" />
            </TabList>
          </Box>
          <TabPanel
            value="1"
            sx={{
              padding: `${theme.spacing(3)} 0 ${theme.spacing(3)} 0`,
            }}
          >
            <>
              <TouchdownAnalysis />
            </>
          </TabPanel>
          <TabPanel
            value="2"
            sx={{
              padding: `${theme.spacing(3)} 0 ${theme.spacing(3)} 0`,
            }}
          >
            <ContestAnalysis />
          </TabPanel>
        </TabContext>
      </Box>
      <Box
        sx={{
          marginTop: theme.spacing(3),
          dispaly: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          border: "1px solid #EEE8DC",
          backgroundColor: "#fff",
          padding: `${theme.spacing(6)} ${theme.spacing(2)}`,
        }}
      >
        <LeaderboardIcon
          sx={{
            fontSize: "60px",
            opacity: "0.4",
            color: theme.palette.primary.main,
          }}
        />
        <Typography>Use the above form to generate an analytics</Typography>
      </Box>
    </SignedInLayout>
  );
}
