import { useSnackbar } from "notistack";
import { SignedInWithSidebarLayout } from "@src/components/templates/Layouts";
import { Button, ButtonGroup } from "@mui/material";

export function Matchups() {
  const { enqueueSnackbar } = useSnackbar();

  const handleSave = () => {};

  return (
    <SignedInWithSidebarLayout
      title="PGA Matchups"
      SidebarComponent={<Controls onSave={handleSave} />}
    ></SignedInWithSidebarLayout>
  );
}

interface ControlsProps {
  onSave: () => void;
}

function Controls({ onSave }: ControlsProps) {
  return (
    <ButtonGroup variant="contained" orientation="vertical" fullWidth>
      <Button onClick={onSave}>Save</Button>
    </ButtonGroup>
  );
}
