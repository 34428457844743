import {
  Button,
  Stack,
  DialogContent,
  Typography,
  DialogTitle,
  Dialog,
} from "@mui/material";

interface DeleteDialogProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}
export function DeleteSuggestion(props: DeleteDialogProps) {
  const { onClose, open, onDelete } = props;
  const handleClose = () => {
    onClose();
  };
  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      open={open}
    >
      <DialogTitle>Delete Suggestion</DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          overflow: "hidden",
          paddingX: 1,
          paddingY: 2.5,
        }}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          {" "}
          <Typography>Do you want to delete this suggestion?</Typography>
          <Button
            color="primary"
            disableElevation
            onClick={onDelete}
            type="submit"
            variant="contained"
            size="large"
            sx={{
              fontWeight: "bold",
              mt: 4,
            }}
          >
            Delete
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
