import BlockIcon from "@mui/icons-material/Block";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import { Menu, MenuProps } from "@mui/material";
import { styled } from "@mui/styles";
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridComparatorFn,
  GridFilterModel,
  GridRowParams,
} from "@mui/x-data-grid";
import { dateRangeOperators } from "@organisms/DataGridFilters/DateRange/dateRangeOperators";
import {
  ActivationDialog,
  CreateEditDialog,
} from "@src/components/organisms/Users";
import { AuditLogsModal } from "@src/components/templates/AuditLogsModal";
import { SignedInLayout } from "@src/components/templates/Layouts";
import {
  AuditActionFliterType,
  Role,
  useGetUsersQuery,
  User,
} from "@src/graphql/graphql.generated";
import { useAuth } from "@src/hooks/useAuth";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    minWidth: 180,
    color: "rgb(55, 65, 81)",
    boxShadow: "rgb(0 0 0 / 20%) 0px 0px 1px 0px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
      },
    },
  },
}));

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: "1px solid #EEE8DC !important",
  "& .MuiDataGrid-cell": {
    borderBottom: "1px solid #EEE8DC",
  },
  "& .MuiDataGrid-columnHeaders": {
    borderBottom: "1px solid #EEE8DC",
  },
  "& .MuiDataGrid-footerContainer": {
    borderTop: "1px solid #EEE8DC",
  },
}));

export interface UserAction {
  target: User | null;
  status: "edit" | "delete";
}

export function Users() {
  const {
    data: users,
    isLoading,
    refetch,
  } = useGetUsersQuery(
    {},
    {
      staleTime: 300_000, // 5 min
    }
  );
  const [isFilterDate, setIsFilterDate] = useState(false);
  const [isCreateOpen, setCreateOpen] = useState(false);
  const [action, setAction] = useState<UserAction | null>(null);
  const [showAuditTrail, setShowAuditTrail] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPartnerData, setSelectedPartner] = useState<any>({});

  const { data: authInfo } = useAuth();

  const handleCloseAudit = () => {
    setShowAuditTrail(false);
  };

  const onFilterChange = (model: GridFilterModel) => {
    const { columnField } = model.items[0];
    if (columnField === "createdAt") {
      setIsFilterDate(true);
      return;
    }
    setIsFilterDate(false);
  };

  const handleCloseCreateEdit = (refresh = false) => {
    setCreateOpen(false);
    setAction(null);
    if (refresh) {
      refetch();
    }
  };

  const handleCloseDelete = (refresh = false) => {
    setAction(null);
    if (refresh) {
      refetch();
    }
  };

  const handleActionDialogOpen = (
    params: GridRowParams,
    status: "edit" | "delete"
  ) => {
    setAction({ target: params.row as User, status });
  };

  const columns = useMemo<GridColumns>(
    () => [
      { field: "id", headerName: "ID", width: 80 },
      { field: "email", headerName: "Email", width: 200 },
      {
        field: "createdAt",
        headerName: "Creation Date",
        width: 170,
        valueFormatter: (params) =>
          dayjs(params.value as string).format("MMM D, YYYY, H:ma"),
        sortComparator: dateComparator,
        filterOperators: dateRangeOperators,
      },
      {
        field: "isActive",
        headerName: "Status",
        width: 90,
        valueGetter: (params) => (params.value ? "Active" : "Inactive"),
      },
      {
        field: "roles",
        headerName: "Roles",
        width: 180,
        valueGetter: (params) =>
          params.value.map((role: Role) => role.name).join(", "),
      },
      {
        field: "permissions",
        headerName: "Permissions",
        minWidth: 200,
        flex: 1,
        valueGetter: (params) => params.value.join(", "),
      },
      {
        field: "actions",
        type: "actions",
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem
            icon={<EditIcon />}
            onClick={() => handleActionDialogOpen(params, "edit")}
            label="Edit"
          />,
          <GridActionsCellItem
            icon={
              params.row.isActive ? <BlockIcon /> : <CheckCircleOutlineIcon />
            }
            onClick={() => handleActionDialogOpen(params, "delete")}
            label="Block/Unblock"
          />,
        ],
      },
    ],
    []
  );
  const navigate = useNavigate();

  return (
    <SignedInLayout title="Users">
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <DataGrid
          columns={columns}
          initialState={{
            sorting: {
              sortModel: [{ field: "startDate", sort: "asc" }],
            },
          }}
          rows={users?.getUsers ?? []}
          sx={{
            minHeight: "75vh",
          }}
          componentsProps={{
            panel: {
              sx: {
                "& .MuiDataGrid-filterForm": {
                  width: isFilterDate ? 600 : 500,
                },
              },
            },
          }}
          onFilterModelChange={onFilterChange}
        />
      )}
      <CreateEditDialog
        isCreate={isCreateOpen}
        isEdit={action?.status === "edit"}
        target={action?.target ?? null}
        onClose={handleCloseCreateEdit}
      />
      <ActivationDialog
        target={action?.target ?? null}
        isOpen={action?.status === "delete"}
        onClose={handleCloseDelete}
      />
      <AuditLogsModal
        title="Users Audit"
        isOpen={showAuditTrail}
        onClose={handleCloseAudit}
        actions={[
          AuditActionFliterType.CreateUser,
          AuditActionFliterType.EditUser,
          AuditActionFliterType.BlockUser,
          AuditActionFliterType.UnblockUser,
        ]}
      />
    </SignedInLayout>
  );
}

const dateComparator: GridComparatorFn = (isoDate1, isoDate2): 1 | -1 => {
  return dayjs(isoDate1 as string).isBefore(dayjs(isoDate2 as string)) ? 1 : -1;
};
