import { RequestInit } from 'graphql-request/dist/types.dom';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
import { useFetchData } from './fetcher';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
};

export type ApiKeyDto = {
  active: Scalars['Boolean'];
  apiKey: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy: Scalars['Int'];
  expiredAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
};

export type Audit = {
  action: AuditActionFliterType;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  payload: Scalars['JSONObject'];
  updatedAt: Scalars['DateTime'];
  user: User;
};

export enum AuditActionFliterType {
  BlockUser = 'BlockUser',
  BonusPayout = 'BonusPayout',
  BroadcastNotification = 'BroadcastNotification',
  CancelContest = 'CancelContest',
  CreateCoupon = 'CreateCoupon',
  CreateNflMatchup = 'CreateNflMatchup',
  CreateRole = 'CreateRole',
  CreateSuggestion = 'CreateSuggestion',
  CreateUser = 'CreateUser',
  DeleteCoupon = 'DeleteCoupon',
  DeleteFeaturedContest = 'DeleteFeaturedContest',
  DeleteNflMatchup = 'DeleteNflMatchup',
  DeleteRole = 'DeleteRole',
  DeleteSuggestion = 'DeleteSuggestion',
  DeleteUserPermanently = 'DeleteUserPermanently',
  EditRole = 'EditRole',
  EditUser = 'EditUser',
  GetCurrentTournamentPlayersCron = 'GetCurrentTournamentPlayersCron',
  GetTeeTimeByRoundCron = 'GetTeeTimeByRoundCron',
  GetTeeTimeForCurrentTournamentCron = 'GetTeeTimeForCurrentTournamentCron',
  GradeTournamentCron = 'GradeTournamentCron',
  ImportTournaments = 'ImportTournaments',
  OrderNflMatchup = 'OrderNflMatchup',
  SendContestGradedEmailCron = 'SendContestGradedEmailCron',
  SyncTournamentPlayersCron = 'SyncTournamentPlayersCron',
  SyncTournamentsCron = 'SyncTournamentsCron',
  UnblockUser = 'UnblockUser',
  UpdateFeatureFlags = 'UpdateFeatureFlags',
  UpdateFeaturedContest = 'UpdateFeaturedContest',
  UpdateSuggestion = 'UpdateSuggestion',
  VoidContest = 'VoidContest',
  VoidContestsCron = 'VoidContestsCron',
  WithDrawRequest = 'WithDrawRequest'
}

export type AuditActionInputType = {
  filter?: InputMaybe<Array<AuditActionFliterType>>;
};

export type AutomatedSportInput = {
  NBA?: InputMaybe<Array<Scalars['Int']>>;
  SOCCER?: InputMaybe<Array<Scalars['Int']>>;
};

export type AvailableIn = {
  name: Scalars['String'];
  prizePoolId: Scalars['Int'];
};

export type BattlegroundContest = {
  claimedAt?: Maybe<Scalars['String']>;
  claimerId?: Maybe<Scalars['Int']>;
  claimerPlayer: TournamentPlayer;
  claimerProjectedScore: Scalars['Float'];
  claimerProjectedStrokes: Scalars['Float'];
  claimerScore?: Maybe<Scalars['Float']>;
  claimerStrokes?: Maybe<Scalars['Float']>;
  claimerUser?: Maybe<UserDto>;
  closeReason: Scalars['String'];
  contestType: Scalars['String'];
  createdAt: Scalars['String'];
  creatorId: Scalars['Int'];
  creatorPlayer: TournamentPlayer;
  creatorProjectedScore: Scalars['Float'];
  creatorProjectedStrokes: Scalars['Float'];
  creatorScore?: Maybe<Scalars['Float']>;
  creatorStrokes?: Maybe<Scalars['Float']>;
  creatorUser?: Maybe<UserDto>;
  endTime?: Maybe<Scalars['String']>;
  entryAmount: Scalars['Float'];
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  updatedAt: Scalars['String'];
  winAmount: Scalars['Float'];
  winnerId?: Maybe<Scalars['Int']>;
};

export type BroadcastNotificationInputType = {
  body: Scalars['String'];
  title: Scalars['String'];
};

export enum Contest_Source {
  Hub = 'HUB',
  Nba = 'NBA',
  Nfl = 'NFL',
  Soccer = 'SOCCER'
}

export enum Contest_Stack_Holder {
  Draw = 'DRAW',
  Player1 = 'PLAYER1',
  Player2 = 'PLAYER2'
}

export enum Contest_Status {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Deleted = 'DELETED',
  Upcoming = 'UPCOMING'
}

export enum Contest_Statuses {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Deleted = 'DELETED',
  Started = 'STARTED',
  Upcoming = 'UPCOMING'
}

export type CancelContestInputType = {
  contestId: Scalars['Int'];
};

export type CommonContest = {
  id: Scalars['Int'];
  metric: Scalars['String'];
  player1BonusPoints: Scalars['Float'];
  player1Id: Scalars['String'];
  player1Name: Scalars['String'];
  player1PhotoUrl: Scalars['String'];
  player2BonusPoints: Scalars['Float'];
  player2Id: Scalars['String'];
  player2Name: Scalars['String'];
  player2PhotoUrl: Scalars['String'];
  question: Scalars['String'];
  source: Contest_Source;
  status: Contest_Statuses;
};

export type CommonContestCountDto = {
  count: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type CommonContestDeleteInput = {
  id: Scalars['Int'];
  status: Scalars['String'];
};

export type CommonContestOutput = {
  commonContests: Array<CommonContest>;
  publishedContestsId: Array<Scalars['String']>;
};

export type CommonHubContestResponse = {
  contestDate: Scalars['String'];
  id: Scalars['Int'];
  player1BonusPoints: Scalars['Float'];
  player1FantasyPoints?: Maybe<Scalars['Int']>;
  player1FullName: Scalars['String'];
  player1Game: Scalars['String'];
  player1GameStartTime: Scalars['String'];
  player1Id: Scalars['String'];
  player1PhotoUrl?: Maybe<Scalars['String']>;
  player2BonusPoints: Scalars['Float'];
  player2FantasyPoints?: Maybe<Scalars['Int']>;
  player2FullName: Scalars['String'];
  player2Game: Scalars['String'];
  player2GameStartTime: Scalars['String'];
  player2Id: Scalars['String'];
  player2PhotoUrl?: Maybe<Scalars['String']>;
  questionId?: Maybe<QuestionDto>;
  sport?: Maybe<CommonSportOutputDto>;
  status: Scalars['String'];
  winnerLabel?: Maybe<Scalars['String']>;
  winnerPlayerId?: Maybe<Scalars['String']>;
};

export type CommonSportOutputDto = {
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  questions?: Maybe<Array<QuestionDto>>;
  winCriteria?: Maybe<Win_Criteria>;
};

export type CommonSportQuestionDto = {
  id?: InputMaybe<Scalars['Int']>;
  metric: Scalars['String'];
  question: Scalars['String'];
};

export type ContestDto = {
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  matchDate: Scalars['String'];
  matchDateStartTime: Scalars['String'];
  metric: Scalars['String'];
  orderIndex?: Maybe<Scalars['Int']>;
  player1BonusPoints: Scalars['Float'];
  player1FullName: Scalars['String'];
  player1PhotoUrl: Scalars['String'];
  player2BonusPoints: Scalars['Float'];
  player2FullName: Scalars['String'];
  player2PhotoUrl: Scalars['String'];
  sportName: Scalars['String'];
  type: Scalars['String'];
};

export type ContestOrderF2PInput = {
  contestId: Scalars['Int'];
  orderIndex: Scalars['Int'];
  sportName: Supported_Sport;
};

export type ContestOrderF2PResponseDto = {
  message: Scalars['String'];
};

export type ContestOrderInput = {
  contestId: Scalars['Int'];
  orderIndex: Scalars['Int'];
  sportName: SupportedSports;
};

export type ContestOrderPartnerInput = {
  contestId: Scalars['Int'];
  contestType?: InputMaybe<Scalars['String']>;
  orderIndex: Scalars['Int'];
  sportName: SupportedSports;
};

export type ContestOrderPartnerResponseDto = {
  message: Scalars['String'];
};

export enum ContestStackHolder {
  Draw = 'DRAW',
  Player1 = 'PLAYER1',
  Player2 = 'PLAYER2'
}

export enum ContestStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Deleted = 'DELETED',
  Upcoming = 'UPCOMING'
}

export type ContestTitleDto = {
  question: Scalars['String'];
  statName: Scalars['String'];
};

export type ContestTitleInput = {
  question: Scalars['String'];
};

export enum ContestsReportFilterType {
  Active = 'Active',
  ClosedMatched = 'ClosedMatched',
  ClosedUnmatched = 'ClosedUnmatched',
  Open = 'Open'
}

export type ContestsReportInputType = {
  filter?: InputMaybe<ContestsReportFilterType>;
};

export type CouponDto = {
  code: Scalars['String'];
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['String'];
  value: Scalars['Int'];
};

export type CreateCommonHubContestInput = {
  contestDate: Scalars['String'];
  player1BonusPoints: Scalars['Float'];
  player1FullName: Scalars['String'];
  player1Game: Scalars['String'];
  player1GameStartTime: Scalars['String'];
  player1PhotoUrl?: InputMaybe<Scalars['String']>;
  player2BonusPoints: Scalars['Float'];
  player2FullName: Scalars['String'];
  player2Game: Scalars['String'];
  player2GameStartTime: Scalars['String'];
  player2PhotoUrl?: InputMaybe<Scalars['String']>;
  questionId: Scalars['Int'];
  sportId: Scalars['Int'];
};

export type CreateCommonSportDto = {
  id?: InputMaybe<Scalars['Int']>;
  logoUrl: Scalars['String'];
  name: Scalars['String'];
  questions: Array<CommonSportQuestionDto>;
  winCriteria: Win_Criteria;
};

export type CreateCouponDto = {
  code: Scalars['String'];
  value: Scalars['Int'];
};

export type CreateEditRoleDto = {
  name: Scalars['String'];
  permissions: Array<PermissionId>;
  roleId?: InputMaybe<Scalars['Int']>;
};

export type CreateEditUserDto = {
  email: Scalars['String'];
  permissions: Array<PermissionId>;
  roles: Array<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type CreateF2PPartnerInput = {
  contact: Scalars['String'];
  creatorId: Scalars['Int'];
  description: Scalars['String'];
  email: Scalars['String'];
  enableUserAuth: Scalars['Boolean'];
  identifier: Scalars['String'];
  logo?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  primaryColor?: InputMaybe<Scalars['String']>;
  secondaryColor?: InputMaybe<Scalars['String']>;
};

export type CreateF2PPrizePoolInputDto = {
  allowedSports: Array<Scalars['Int']>;
  endDateTime: Scalars['String'];
  lockTime: Scalars['String'];
  maxEntriesPerUser: Scalars['Int'];
  startDateTime: Scalars['String'];
  totalEntrants: Scalars['Int'];
  touchdownId: Scalars['Int'];
};

export type CreateFeatureContestInput = {
  contestId: Scalars['Int'];
  sportName: Supported_Sport;
};

export type CreateHubContestInput = {
  player1BonusPoints: Scalars['Float'];
  player1FullName: Scalars['String'];
  player1Game: Scalars['String'];
  player1GameStartTime: Scalars['String'];
  player1PhotoUrl?: InputMaybe<Scalars['String']>;
  player2BonusPoints: Scalars['Float'];
  player2FullName: Scalars['String'];
  player2Game: Scalars['String'];
  player2GameStartTime: Scalars['String'];
  player2PhotoUrl?: InputMaybe<Scalars['String']>;
  published: Scalars['Boolean'];
  questionId: Scalars['Int'];
  sportId: Scalars['Int'];
};

export type CreatePartnerAdminInputDto = {
  email: Scalars['String'];
  fullName: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  partnerId: Scalars['Int'];
};

export type CreatePartnerDto = {
  admins: Array<PartnerAdminInputDto>;
  contact: Scalars['String'];
  creatorId: Scalars['Int'];
  email: Scalars['String'];
  identifier: Scalars['String'];
  logo: Scalars['String'];
  name: Scalars['String'];
  primaryColor: Scalars['String'];
  secondaryColor: Scalars['String'];
};

export type CreatePrizePoolInput = {
  entryFees: Scalars['Int'];
  lockTime: Scalars['String'];
  maxEntriesPerUser: Scalars['Int'];
  predetermineContestVig: Scalars['Float'];
  predetermineDailyGPP: Scalars['Float'];
  predetermineDailyJackpot: Scalars['Float'];
  predetermineJackpot: Scalars['Float'];
  predeterminePrizePool: Scalars['Float'];
  predetermineWeeklyReserve: Scalars['Float'];
  prizeDate: Scalars['String'];
  status: PrizePoolStatus;
  supportedSports: Array<SupportedSports>;
  totalEntrants: Scalars['Int'];
  touchdownId: Scalars['Int'];
};

export type CreatePrizePoolInputDto = {
  allowedSports: Array<Scalars['Int']>;
  contestVig: Scalars['Float'];
  endDateTime: Scalars['String'];
  gppAmount: Scalars['Float'];
  jackpotAmount: Scalars['Float'];
  lockTime: Scalars['String'];
  maxEntriesPerUser: Scalars['Int'];
  startDateTime: Scalars['String'];
  totalEntrants: Scalars['Int'];
  totalPrize: Scalars['Float'];
  touchdownId: Scalars['Int'];
};

export type CreateSportDto = {
  logoUrl: Scalars['String'];
  name: Scalars['String'];
  partnerId: Scalars['Int'];
  questions: Array<SportQuestionDto>;
  winCriteria: Win_Criteria;
};

export type CreateSportInput = {
  logoUrl: Scalars['String'];
  name: Scalars['String'];
  questions: Array<SportQuestionInput>;
  winCriteria: WinCriteria;
};

export type CreateSuggestionInputType = {
  contestType?: InputMaybe<Scalars['String']>;
  mainPlayerId: Scalars['String'];
  oppenentIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  roundNumber?: InputMaybe<Scalars['Int']>;
  tournamentId: Scalars['String'];
};

export type CreateTouchdownDto = {
  endDate: Scalars['String'];
  mathConstant: MathConstantField;
  prizePool: Array<PrizePoolContestDto>;
  startDate: Scalars['String'];
  touchDownType: Scalars['String'];
  touchdownId: Scalars['Int'];
};

export type CreateTouchdownInput = {
  endDate: Scalars['String'];
  entryFees: Scalars['Int'];
  lockTime: Scalars['String'];
  mathConstant: MathConstantFieldInput;
  maxEntriesPerUser: Scalars['Int'];
  predetermineContestVig: Scalars['Float'];
  predetermineDailyGPP: Scalars['Float'];
  predetermineJackpot: Scalars['Float'];
  predeterminePrizePool: Scalars['Float'];
  predetermineWeeklyReserve: Scalars['Float'];
  startDate: Scalars['String'];
  supportedSports: Array<SupportedSports>;
  totalEntrants: Scalars['Int'];
  touchdownType: TouchdownType;
  weeklyPrizeDistribution: WeeklyPrizeInput;
};

export type DailyCountReport = {
  amountAddedSystemCount: Scalars['Int'];
  battlegroundContestCount: Scalars['Int'];
  userCount: Scalars['Int'];
};

export type DailyLeaderBoardDto = {
  entryId: Scalars['Int'];
  entryNumber: Scalars['Int'];
  id: Scalars['Int'];
  leaderboardRank: Scalars['String'];
  name: Scalars['String'];
  payout?: Maybe<Scalars['String']>;
  rank: Scalars['Int'];
  score: Scalars['Int'];
  status: Scalars['String'];
  winAmount: Scalars['String'];
};

export type DeleteCommonHubContestResponse = {
  message: Scalars['String'];
};

export type DeleteCouponsDto = {
  ids: Array<Scalars['Int']>;
};

export type DeleteCouponsExecDto = {
  affected: Scalars['Int'];
};

export type DeleteFeaturedContestInputType = {
  matchupId: Scalars['Int'];
};

export type DeleteSuggestionInputType = {
  suggestionId: Scalars['Int'];
};

export type DeleteUserPermanentlyDto = {
  shouldDelete: Scalars['Boolean'];
  userId: Scalars['Int'];
};

export type EnableSportInputDto = {
  enabled: Scalars['Boolean'];
  partnerId: Scalars['Int'];
  sportId: Scalars['Int'];
};

export type EnableSportOutputDto = {
  message: Scalars['String'];
};

export type EnabledSportInput = {
  enabled: Scalars['Boolean'];
  sportId: Scalars['Int'];
};

export type Entry = {
  entryId: Scalars['Int'];
  leaderBoardRank: Scalars['String'];
  name: Scalars['String'];
  rank: Scalars['String'];
  score: Scalars['Int'];
  userId: Scalars['Int'];
  winAmount: Scalars['Float'];
};

export type ExecResultDto = {
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type F2PAvailableIn = {
  name: Scalars['String'];
  prizePoolId: Scalars['Int'];
};

export type F2PCreateHubContestInputDto = {
  partnerId: Scalars['Int'];
  player1BonusPoints: Scalars['Float'];
  player1FullName: Scalars['String'];
  player1Game: Scalars['String'];
  player1GameStartTime: Scalars['String'];
  player1PhotoUrl: Scalars['String'];
  player2BonusPoints: Scalars['Float'];
  player2FullName: Scalars['String'];
  player2Game: Scalars['String'];
  player2GameStartTime: Scalars['String'];
  player2PhotoUrl: Scalars['String'];
  prizePoolIds: Array<Scalars['Int']>;
  published: Scalars['Boolean'];
  questionId: Scalars['Int'];
  sportId: Scalars['Int'];
};

export type F2PCreateSportDto = {
  logoUrl: Scalars['String'];
  name: Scalars['String'];
  partnerId: Scalars['Int'];
  questions: Array<F2PSportQuestionDto>;
  winCriteria: F2P_Win_Criteria;
};

export type F2PCreateTouchdownInputDto = {
  allowedPick: Scalars['Int'];
  endDateTime: Scalars['String'];
  name: Scalars['String'];
  partnerId: Scalars['Int'];
  prizePool: F2PPrizePoolInputDto;
  startDateTime: Scalars['String'];
};

export type F2PCreateTouchdownResponseDto = {
  allowedPick: Scalars['Int'];
  createdAt: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  endDateTime: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  partners?: Maybe<Array<F2PPartnerDto>>;
  prizePools: Array<F2PPrizePoolResponseDto>;
  startDateTime: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type F2PDailyLeaderBoardDto = {
  endDateTime: Scalars['String'];
  entries: Array<F2PEntry>;
  startDateTime: Scalars['String'];
};

export type F2PEnableSportInputDto = {
  enabled: Scalars['Boolean'];
  partnerId: Scalars['Int'];
  sportId: Scalars['Int'];
};

export type F2PEnableSportOutputDto = {
  message: Scalars['String'];
};

export type F2PEntry = {
  entryId: Scalars['Int'];
  leaderBoardRank: Scalars['String'];
  name: Scalars['String'];
  rank: Scalars['String'];
  score: Scalars['Int'];
  userId: Scalars['Int'];
};

export type F2PFetchContestResponseDto = {
  createdAt: Scalars['String'];
  featureContestOrder?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  isFeatureContest?: Maybe<Scalars['Boolean']>;
  matchDate: Scalars['String'];
  matchDateStartTime: Scalars['String'];
  metric: Scalars['String'];
  orderIndex?: Maybe<Scalars['Int']>;
  player1BonusPoints: Scalars['Float'];
  player1FullName: Scalars['String'];
  player1PhotoUrl: Scalars['String'];
  player2BonusPoints: Scalars['Float'];
  player2FullName: Scalars['String'];
  player2PhotoUrl: Scalars['String'];
  prizePoolId: Scalars['Int'];
  sportName: Scalars['String'];
  type: Scalars['String'];
};

export type F2PFetchTouchdownInputDto = {
  duration: Scalars['String'];
  endDateTime?: InputMaybe<Scalars['DateTime']>;
  limit: Scalars['Int'];
  page: Scalars['Int'];
  partnerId: Scalars['Int'];
  startDateTime?: InputMaybe<Scalars['DateTime']>;
};

export type F2PHubContestCountResponseDto = {
  count: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type F2PHubContestResponseDto = {
  availableInTouchdowns: Array<F2PAvailableIn>;
  createdAt?: Maybe<Scalars['String']>;
  featureContestOrder?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  isFeatureContest?: Maybe<Scalars['Boolean']>;
  player1BonusPoints: Scalars['Float'];
  player1FantasyPoints?: Maybe<Scalars['Float']>;
  player1FullName: Scalars['String'];
  player1Game: Scalars['String'];
  player1GameStartTime: Scalars['String'];
  player1PhotoUrl: Scalars['String'];
  player2BonusPoints: Scalars['Float'];
  player2FantasyPoints?: Maybe<Scalars['Float']>;
  player2FullName: Scalars['String'];
  player2Game: Scalars['String'];
  player2GameStartTime: Scalars['String'];
  player2PhotoUrl: Scalars['String'];
  prizePoolId: Scalars['Int'];
  published: Scalars['Boolean'];
  questionId: F2PQuestionDto;
  sport: F2PSportOutputDto;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  winnerLabel?: Maybe<Scalars['String']>;
};

export type F2PNbaTouchdownPlayerDto = {
  available: Scalars['Boolean'];
  espnPlayerId: Scalars['Int'];
  fantasyPoints: Scalars['Int'];
  fantasyPointsFullPpr: Scalars['Int'];
  fantasyPointsHalfPpr: Scalars['Int'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  gameId: Scalars['Int'];
  gameStartTime: Scalars['String'];
  hasStarted: Scalars['Boolean'];
  homeOrAway: Scalars['String'];
  id: Scalars['Int'];
  injuryStatus?: Maybe<Scalars['String']>;
  isOver: Scalars['Boolean'];
  lastName: Scalars['String'];
  opponentName: Scalars['String'];
  photoUrl: Scalars['String'];
  photoUrlHiRes: Scalars['String'];
  position: Scalars['String'];
  projectedFantasyPoints?: Maybe<Scalars['Float']>;
  projectedFantasyPointsHalfPpr: Scalars['Int'];
  remoteId: Scalars['Int'];
  shortName: Scalars['String'];
  teamId: Scalars['Int'];
  teamName: Scalars['String'];
  yahooPlayerId: Scalars['Int'];
};

export type F2POrderSport = {
  orderIndex: Scalars['Int'];
  sportId: Scalars['Int'];
};

export type F2POrderSportsDto = {
  partnerId: Scalars['Int'];
  sports: Array<F2POrderSport>;
};

export type F2PPaginationResponseDto = {
  data?: Maybe<Array<F2PCreateTouchdownResponseDto>>;
  limit: Scalars['Int'];
  page: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type F2PPartnerActiveInputDto = {
  active: Scalars['Boolean'];
  id: Scalars['Int'];
};

export type F2PPartnerDto = {
  active: Scalars['Boolean'];
  contact: Scalars['String'];
  createdBy: Scalars['Int'];
  description: Scalars['String'];
  email: Scalars['String'];
  enableUserAuth: Scalars['Boolean'];
  id: Scalars['Int'];
  identifier: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  touchdowns?: Maybe<Array<F2PCreateTouchdownResponseDto>>;
};

export type F2PPartnerInfoDto = {
  active: Scalars['Boolean'];
  contact: Scalars['String'];
  createdBy: Scalars['Int'];
  description: Scalars['String'];
  email: Scalars['String'];
  enableUserAuth: Scalars['Boolean'];
  id: Scalars['Int'];
  identifier: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  totalTouchdownCount: Scalars['Int'];
  touchdowns?: Maybe<Array<F2PCreateTouchdownResponseDto>>;
  userCount: Scalars['Int'];
};

export type F2PPrizePoolInfoResponseDto = {
  allowedSports: Array<Scalars['Int']>;
  createdAt: Scalars['String'];
  endDateTime: Scalars['String'];
  hubContestCount: Scalars['Int'];
  id: Scalars['Int'];
  lockTime: Scalars['String'];
  maxEntriesPerUser: Scalars['Int'];
  nbaContestCount: Scalars['Int'];
  nflContestCount: Scalars['Int'];
  soccerContestCount: Scalars['Int'];
  startDateTime: Scalars['String'];
  status: Prize_Pool_Status;
  totalEntrants: Scalars['Int'];
  updatedAt: Scalars['String'];
  userEntryCount: Scalars['Int'];
};

export type F2PPrizePoolInputDto = {
  allowedSports: Array<Scalars['Int']>;
  endDateTime: Scalars['String'];
  lockTime: Scalars['String'];
  maxEntriesPerUser: Scalars['Int'];
  startDateTime: Scalars['String'];
  totalEntrants: Scalars['Int'];
};

export type F2PPrizePoolResponseDto = {
  allowedSports: Array<Scalars['Int']>;
  createdAt: Scalars['String'];
  endDateTime: Scalars['String'];
  id: Scalars['Int'];
  lockTime: Scalars['String'];
  maxEntriesPerUser: Scalars['Int'];
  startDateTime: Scalars['String'];
  status: Prize_Pool_Status;
  totalEntrants: Scalars['Int'];
  updatedAt: Scalars['String'];
};

export type F2PPublishHubContestResponseDto = {
  createdAt?: Maybe<Scalars['String']>;
  featureContestOrder?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  isFeatureContest?: Maybe<Scalars['Boolean']>;
  player1BonusPoints: Scalars['Float'];
  player1FantasyPoints?: Maybe<Scalars['Float']>;
  player1FullName: Scalars['String'];
  player1Game: Scalars['String'];
  player1GameStartTime: Scalars['String'];
  player1PhotoUrl: Scalars['String'];
  player2BonusPoints: Scalars['Float'];
  player2FantasyPoints?: Maybe<Scalars['Float']>;
  player2FullName: Scalars['String'];
  player2Game: Scalars['String'];
  player2GameStartTime: Scalars['String'];
  player2PhotoUrl: Scalars['String'];
  prizePoolId: Scalars['Int'];
  published: Scalars['Boolean'];
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  winnerLabel?: Maybe<Scalars['String']>;
};

export type F2PQuestionDto = {
  id: Scalars['Int'];
  metric: Scalars['String'];
  question: Scalars['String'];
};

export type F2PSoccerTouchdownContestDto = {
  featureContestOrder?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  isFeatureContest?: Maybe<Scalars['Boolean']>;
  metric: Scalars['String'];
  player1: F2PSoccerTouchdownPlayerDto;
  player1BonusPoints?: Maybe<Scalars['Float']>;
  player2: F2PSoccerTouchdownPlayerDto;
  player2BonusPoints?: Maybe<Scalars['Float']>;
  prizePoolId: Scalars['Int'];
  status: Scalars['String'];
};

export type F2PSoccerTouchdownPlayerDto = {
  assists?: Maybe<Scalars['Float']>;
  commonName: Scalars['String'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  gameId: Scalars['Int'];
  gameStartTime: Scalars['String'];
  goals?: Maybe<Scalars['Float']>;
  hasStarted: Scalars['Boolean'];
  homeOrAway: Scalars['String'];
  id: Scalars['Int'];
  injuryStatus?: Maybe<Scalars['String']>;
  isOver: Scalars['Boolean'];
  lastName: Scalars['String'];
  opponentName: Scalars['String'];
  passes?: Maybe<Scalars['Float']>;
  photoUrl: Scalars['String'];
  position: Scalars['String'];
  positionCategory: Scalars['String'];
  projectedAssists?: Maybe<Scalars['Float']>;
  projectedGoals?: Maybe<Scalars['Float']>;
  projectedPasses?: Maybe<Scalars['Float']>;
  projectedSaves?: Maybe<Scalars['Float']>;
  projectedShots?: Maybe<Scalars['Float']>;
  projectedShotsOnGoal?: Maybe<Scalars['Float']>;
  projectedTackles?: Maybe<Scalars['Float']>;
  remoteId: Scalars['Int'];
  saves?: Maybe<Scalars['Float']>;
  shortName: Scalars['String'];
  shots?: Maybe<Scalars['Float']>;
  shotsOnGoal?: Maybe<Scalars['Float']>;
  tackles?: Maybe<Scalars['Float']>;
  teamName: Scalars['String'];
};

export type F2PSportOutputDto = {
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  orderIndex?: Maybe<Scalars['Int']>;
  partnerId?: Maybe<Scalars['Int']>;
  questions?: Maybe<Array<F2PQuestionDto>>;
  winCriteria?: Maybe<F2P_Win_Criteria>;
};

export type F2PSportQuestionDto = {
  id?: InputMaybe<Scalars['Int']>;
  metric: Scalars['String'];
  question: Scalars['String'];
};

export type F2PTouchdownAnalyticsDto = {
  totalActiveTouchdowns: Scalars['Int'];
  totalPartners: Scalars['Int'];
  totalTouchdowns: Scalars['Int'];
  totalUpcomingTouchdowns: Scalars['Int'];
  totalUsers: Scalars['Int'];
  touchdownsPerMonth: Array<F2PTouchdownPerMonth>;
};

export type F2PTouchdownContestDeleteInput = {
  id: Scalars['Int'];
  status: Scalars['String'];
};

export type F2PTouchdownInfoResponseDto = {
  allowedPick: Scalars['Int'];
  endDateTime: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  prizePools: Array<F2PPrizePoolInfoResponseDto>;
  startDateTime: Scalars['String'];
};

export type F2PTouchdownPerMonth = {
  count: Scalars['Int'];
  month: Scalars['String'];
};

export type F2PUpdatePrizePoolInput = {
  allowedSports: Array<Scalars['Int']>;
  endDateTime: Scalars['String'];
  id: Scalars['Int'];
  lockTime: Scalars['String'];
  maxEntriesPerUser: Scalars['Int'];
  startDateTime: Scalars['String'];
  totalEntrants: Scalars['Int'];
};

export type F2PUpdateSportDto = {
  id: Scalars['Int'];
  logoUrl: Scalars['String'];
  name: Scalars['String'];
  partnerId: Scalars['Int'];
  questions: Array<F2PSportQuestionDto>;
  winCriteria: F2P_Win_Criteria;
};

export type F2PUpdateTouchdownInputDto = {
  allowedPick: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
  prizePool: F2PUpdatePrizePoolInput;
};

export type F2PUserInfoDto = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender: Gender;
  id: Scalars['Int'];
  lastName: Scalars['String'];
  userName: Scalars['String'];
};

export enum F2P_Win_Criteria {
  Higher = 'HIGHER',
  Lower = 'LOWER'
}

export type FjTournamentInputType = {
  active: Scalars['Int'];
  city_state?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  end_dateISO: Scalars['String'];
  event_type?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  network?: InputMaybe<Scalars['String']>;
  period_id?: InputMaybe<Scalars['String']>;
  points?: InputMaybe<Scalars['String']>;
  purse?: InputMaybe<Scalars['String']>;
  scheduled?: InputMaybe<Scalars['String']>;
  scheduledISO: Scalars['String'];
  score?: InputMaybe<Scalars['String']>;
  sport?: InputMaybe<Scalars['String']>;
  sr_id?: InputMaybe<Scalars['String']>;
  start_dateISO: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  total_rounds?: InputMaybe<Scalars['Int']>;
  venue_id?: InputMaybe<Scalars['String']>;
  winning_share?: InputMaybe<Scalars['String']>;
};

export type FeatureContestResponse = {
  message: Scalars['String'];
};

export type FeatureFlag = {
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  roundByRoundContestCreationEnabled: Scalars['Boolean'];
  tournamentContestCreationEnabled: Scalars['Boolean'];
  updatedAt: Scalars['String'];
};

export type FeatureFlagInputType = {
  roundByRoundContestCreationEnabled?: InputMaybe<Scalars['Boolean']>;
  tournamentContestCreationEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type FetchCommonContestInput = {
  automated?: InputMaybe<AutomatedSportInput>;
  hubContests?: InputMaybe<Array<Scalars['Int']>>;
};

export type FetchF2PTouchdown = {
  touchdownId: Scalars['Int'];
  touchdownName: Scalars['String'];
};

export type FetchF2PTouchdownByDateInputDto = {
  date: Scalars['String'];
  partnerId: Scalars['Int'];
};

export type FetchF2PTouchdownByDateResponse = {
  prizePoolId?: Maybe<Scalars['Int']>;
  touchdown: FetchF2PTouchdown;
};

export type FetchPartnerTouchdown = {
  touchdownId: Scalars['Int'];
  touchdownName: Scalars['String'];
};

export type FetchPartnerTouchdownByDateInputDto = {
  date: Scalars['String'];
  partnerId: Scalars['Int'];
};

export type FetchPartnerTouchdownByDateResponse = {
  prizePoolId?: Maybe<Scalars['Int']>;
  prizePoolStatus: Scalars['String'];
  touchdown: FetchPartnerTouchdown;
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER'
}

export type GenericExecDto = {
  message: Scalars['String'];
  succcess: Scalars['Boolean'];
};

export type GetContestsReportInput = {
  filter: Scalars['String'];
};

export type GetPlayersForSuggestionInputType = {
  contestType?: InputMaybe<Scalars['String']>;
  roundNumber?: InputMaybe<Scalars['Int']>;
  tournamentId?: InputMaybe<Scalars['String']>;
};

export type GetSuggestionsInputType = {
  sportType: Scalars['String'];
};

export type GetTournamentSuggestionDto = {
  contestType: Scalars['String'];
  id: Scalars['Int'];
  mainPlayer: Player;
  roundNumber?: Maybe<Scalars['Int']>;
  suggestedPlayer?: Maybe<Player>;
  tournament: Tournament;
};

export type GetTournamentSuggestionsInputType = {
  contestType?: InputMaybe<Scalars['String']>;
  roundNumber?: InputMaybe<Scalars['Int']>;
  tournamentId?: InputMaybe<Scalars['String']>;
};

export type GradeCommonHubContestInput = {
  id: Scalars['Int'];
  player1FantasyPoints: Scalars['Float'];
  player2FantasyPoints: Scalars['Float'];
  status: ContestStatus;
  winnerLabel: ContestStackHolder;
};

export type GradeF2PHubContestInputDto = {
  id: Scalars['Int'];
  player1FantasyPoints: Scalars['Float'];
  player2FantasyPoints: Scalars['Float'];
  status: Contest_Status;
  winnerLabel: Contest_Stack_Holder;
};

export type GradeHubContestInput = {
  id: Scalars['Int'];
  player1FantasyPoints: Scalars['Float'];
  player2FantasyPoints: Scalars['Float'];
  status: ContestStatus;
  winnerLabel: ContestStackHolder;
};

export type GradeHubContestInputDto = {
  id: Scalars['Int'];
  player1FantasyPoints: Scalars['Float'];
  player2FantasyPoints: Scalars['Float'];
  status: ContestStatus;
  winnerLabel: ContestStackHolder;
};

export type HubContestCountDto = {
  contestCount: Scalars['String'];
  sportId: Scalars['Int'];
};

export type HubContestDto = {
  createdAt?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  player1BonusPoints: Scalars['Float'];
  player1FantasyPoints?: Maybe<Scalars['Float']>;
  player1FullName: Scalars['String'];
  player1Game: Scalars['String'];
  player1GameStartTime: Scalars['String'];
  player1PhotoUrl: Scalars['String'];
  player2BonusPoints: Scalars['Float'];
  player2FantasyPoints?: Maybe<Scalars['Float']>;
  player2FullName: Scalars['String'];
  player2Game: Scalars['String'];
  player2GameStartTime: Scalars['String'];
  player2PhotoUrl: Scalars['String'];
  published: Scalars['Boolean'];
  questionId: SportQuestionsDto;
  sportId: SportDto;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  winnerLabel?: Maybe<Scalars['String']>;
};

export type HubContestResponseDto = {
  availableInTouchdowns: Array<AvailableIn>;
  createdAt?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  player1BonusPoints: Scalars['Float'];
  player1FantasyPoints?: Maybe<Scalars['Float']>;
  player1FullName: Scalars['String'];
  player1Game: Scalars['String'];
  player1GameStartTime: Scalars['String'];
  player1PhotoUrl: Scalars['String'];
  player2BonusPoints: Scalars['Float'];
  player2FantasyPoints?: Maybe<Scalars['Float']>;
  player2FullName: Scalars['String'];
  player2Game: Scalars['String'];
  player2GameStartTime: Scalars['String'];
  player2PhotoUrl: Scalars['String'];
  prizePoolId: Scalars['Int'];
  published: Scalars['Boolean'];
  questionId: QuestionDto;
  sport: SportOutputDto;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  winnerLabel?: Maybe<Scalars['String']>;
};

export type INflContest = {
  claimerPlayer: NflPlayerDto;
  claimerUser?: Maybe<NflReportUserDto>;
  createdAt: Scalars['String'];
  creatorPlayer: NflPlayerDto;
  creatorUser: NflReportUserDto;
  ended: Scalars['Boolean'];
  endedAt?: Maybe<Scalars['String']>;
  entryAmount: Scalars['Int'];
  id: Scalars['Int'];
  status: Scalars['String'];
  winnerLabel?: Maybe<Scalars['String']>;
};

export type LeaderBoardDto = {
  id: Scalars['Int'];
  leaderboardRank: Scalars['String'];
  name: Scalars['String'];
  payout?: Maybe<Scalars['String']>;
  rank: Scalars['Int'];
  score: Scalars['Int'];
  status: Scalars['String'];
  winAmount: Scalars['String'];
};

export type MatchupDto = {
  id: Scalars['Int'];
  orderIndex: Scalars['Int'];
  playerOne: NflPlayerDto;
  playerTwo: NflPlayerDto;
  status: Scalars['String'];
};

export type MathConstantDto = {
  id: Scalars['Int'];
  name: TouchdownMathConstant;
  value: Scalars['Float'];
};

export type MathConstantField = {
  GPP: Scalars['Float'];
  PRIZE_POOL: Scalars['Float'];
  WEEKLY_RESERVE: Scalars['Float'];
};

export type MathConstantFieldInput = {
  GPP: Scalars['Float'];
  PRIZE_POOL: Scalars['Float'];
  WEEKLY_RESERVE: Scalars['Float'];
};

export type MathConstantInput = {
  id?: InputMaybe<Scalars['Int']>;
  name: TouchdownMathConstant;
  value: Scalars['Float'];
};

export type Mutation = {
  broadcastNotification: Scalars['String'];
  cancelContest: BattlegroundContest;
  createCommonHubContest: CommonHubContestResponse;
  createCommonNbaTouchdownContest: Array<NbaCommonTouchdownContestDto>;
  createCommonSoccerTouchdownContest: Array<SoccerCommonTouchdownContestDto>;
  createCommonSport: CommonSportOutputDto;
  createCoupon: CouponDto;
  createEditRole: Role;
  createEditUser: User;
  createF2PFeatureContest: FeatureContestResponse;
  createF2PHubContest: Array<F2PHubContestResponseDto>;
  createF2PNbaTouchdownContest: Array<NbaTouchdownF2PContestDto>;
  createF2PPartner: F2PPartnerDto;
  createF2PPrizePool: F2PPrizePoolResponseDto;
  createF2PSoccerTouchdownContest: Array<F2PSoccerTouchdownContestDto>;
  createF2PSport: F2PSportOutputDto;
  createF2PTouchdown: F2PCreateTouchdownResponseDto;
  createHubContest: HubContestDto;
  createNbaTouchdownContest: Array<NbaTouchdownContestDto>;
  createNflMatchup: Array<MatchupDto>;
  createNflTouchdownContest: Array<NflTouchdownContestDto>;
  createOrUpdateMathConstant: Array<MathConstantDto>;
  createOrUpdateNbaContestTitle: ContestTitleDto;
  createOrUpdateNflContestTitle: ContestTitleDto;
  createPartner: PartnerDto;
  createPartnerAdmin: PartnerAdminDto;
  createPartnerHubContest: Array<HubContestResponseDto>;
  createPartnerNbaTouchdownContest: Array<NbaTouchdownPartnerContestDto>;
  createPartnerPrizePool: PrizePoolResponseDto;
  createPartnerSoccerTouchdownContest: Array<SoccerTouchdownPartnerContestDto>;
  createPartnerSport: SportOutputDto;
  createPartnerTouchdown: PartnerCreateTouchdownResponseDto;
  createPrizePool: PrizePoolContestDto;
  createSoccerTouchdownContest: Array<SoccerTouchdownContestDto>;
  createSport: SportDto;
  createSuggestion: ExecResultDto;
  createTouchdown: CreateTouchdownDto;
  deleteCommonHubContest: DeleteCommonHubContestResponse;
  deleteCommonNbaTouchdownContest: Scalars['Float'];
  deleteCommonSoccerTouchdownContest: Scalars['Float'];
  deleteCoupons: DeleteCouponsExecDto;
  deleteF2PFeatureContest: FeatureContestResponse;
  deleteF2PHubContest: Scalars['String'];
  deleteF2PNbaTouchdownContest: Scalars['Float'];
  deleteF2PSoccerTouchdownContest: Scalars['Float'];
  deleteFeaturedContest: Scalars['String'];
  deleteHubContest: Scalars['String'];
  deleteNbaTouchdownContest: NbaTouchdownContestDto;
  deleteNflMatchup: MatchupDto;
  deleteNflTouchdownContest: NflTouchdownContestDto;
  deletePartnerHubContest: Scalars['String'];
  deletePartnerNbaTouchdownContest: Scalars['Float'];
  deletePartnerSoccerTouchdownContest: Scalars['Float'];
  deleteRole: GenericExecDto;
  deleteSoccerTouchdownContest: SoccerTouchdownContestDto;
  deleteSuggestion: ExecResultDto;
  deleteUserPermanently: GenericExecDto;
  enableF2PSport: F2PEnableSportOutputDto;
  enablePartnerSport: EnableSportOutputDto;
  enabledSport: Scalars['String'];
  getCurrentTournamentPlayers: ExecResultDto;
  getTeeTimeByRound: ExecResultDto;
  getTeeTimeForCurrentTournament: ExecResultDto;
  gradeCommonHubContest: CommonHubContestResponse;
  gradeF2PHubContest: Array<F2PHubContestResponseDto>;
  gradeHubContest: HubContestDto;
  gradePartnerHubContest: Array<HubContestResponseDto>;
  gradeTournament: ExecResultDto;
  importTournaments: ExecResultDto;
  livePrizePool: Scalars['String'];
  orderContests: Array<Scalars['Float']>;
  orderF2PContests: ContestOrderF2PResponseDto;
  orderF2PSport: Array<F2PSportOutputDto>;
  orderMatchups: Array<MatchupDto>;
  orderPartnerContests: ContestOrderPartnerResponseDto;
  orderPartnerSport: Array<SportOutputDto>;
  orderSports: Array<SportDto>;
  publishF2PHubContest: F2PPublishHubContestResponseDto;
  publishF2PPrizePool: Scalars['String'];
  publishHubContest: HubContestDto;
  publishPartnerCommonContest: PublishPartnerCommonContestResponse;
  publishPartnerHubContest: PartnerPublishHubContestResponseDto;
  publishPartnerPrizePool: Scalars['String'];
  regeneratePartnerKey: PartnerDto;
  runTask: ExecResultDto;
  sendContestGradedEmail: ExecResultDto;
  syncTournamentPlayers: ExecResultDto;
  syncTournaments: ExecResultDto;
  updateAdminActiveStatus: PartnerAdminDto;
  updateCommonHubContest: CommonHubContestResponse;
  updateCommonSport: CommonSportOutputDto;
  updateF2PHubContest: Array<F2PHubContestResponseDto>;
  updateF2PNbaContest: Array<NbaF2PTouchdownContestDto>;
  updateF2PPartner: F2PPartnerDto;
  updateF2PPartnerActiveStatus: F2PPartnerDto;
  updateF2PSoccerTouchdownContest: Array<F2PSoccerTouchdownContestDto>;
  updateF2PSport: F2PSportOutputDto;
  updateF2PTouchdown: F2PCreateTouchdownResponseDto;
  updateFeatureFlags: FeatureFlag;
  updateFeaturedContest: Scalars['String'];
  updateHubContest: HubContestDto;
  updatePartner: UpdatePartnerOutputDto;
  updatePartnerActiveStatus: PartnerDto;
  updatePartnerAdmin: PartnerAdminDto;
  updatePartnerHubContest: Array<PartnerUpdateContestHubResponseDto>;
  updatePartnerNbaContest: Array<NbaPartnerTouchdownContestDto>;
  updatePartnerPrizeConstant: PartnerPrizeConstantDto;
  updatePartnerSoccerTouchdownContest: Array<SoccerTouchdownPartnerContestDto>;
  updatePartnerSport: SportOutputDto;
  updatePartnerTouchdown: PartnerCreateTouchdownResponseDto;
  updatePrizePool: UpdatePrizePoolDto;
  updateSport: SportDto;
  updateSuggestion: ExecResultDto;
  updateTouchdown: UpdateTouchdownDto;
  updateTournaments: ExecResultDto;
  updateWeeklyConstant: UpdateWeeklyConstantDto;
  userActivation: GenericExecDto;
  voidContest: BattlegroundContest;
  voidContests: ExecResultDto;
  walletBonusDeposit: WalletResponseDto;
  withdrawRequestAction: WalletResponseDto;
};


export type MutationBroadcastNotificationArgs = {
  data: BroadcastNotificationInputType;
};


export type MutationCancelContestArgs = {
  data: CancelContestInputType;
};


export type MutationCreateCommonHubContestArgs = {
  data: CreateCommonHubContestInput;
};


export type MutationCreateCommonNbaTouchdownContestArgs = {
  data: NbaTouchdownCommonContestCreateInput;
};


export type MutationCreateCommonSoccerTouchdownContestArgs = {
  data: SoccerCommonTouchdownContestCreateInput;
};


export type MutationCreateCommonSportArgs = {
  data: CreateCommonSportDto;
};


export type MutationCreateCouponArgs = {
  data: CreateCouponDto;
};


export type MutationCreateEditRoleArgs = {
  data: CreateEditRoleDto;
};


export type MutationCreateEditUserArgs = {
  data: CreateEditUserDto;
};


export type MutationCreateF2PFeatureContestArgs = {
  data: CreateFeatureContestInput;
};


export type MutationCreateF2PHubContestArgs = {
  data: F2PCreateHubContestInputDto;
};


export type MutationCreateF2PNbaTouchdownContestArgs = {
  data: TouchdownF2PContestCreateInput;
};


export type MutationCreateF2PPartnerArgs = {
  data: CreateF2PPartnerInput;
};


export type MutationCreateF2PPrizePoolArgs = {
  data: CreateF2PPrizePoolInputDto;
};


export type MutationCreateF2PSoccerTouchdownContestArgs = {
  data: SoccerTouchdownF2PContestCreateInput;
};


export type MutationCreateF2PSportArgs = {
  data: F2PCreateSportDto;
};


export type MutationCreateF2PTouchdownArgs = {
  data: F2PCreateTouchdownInputDto;
};


export type MutationCreateHubContestArgs = {
  data: CreateHubContestInput;
};


export type MutationCreateNbaTouchdownContestArgs = {
  data: TouchdownContestCreateInput;
};


export type MutationCreateNflMatchupArgs = {
  data: SuggestionCreateInput;
};


export type MutationCreateNflTouchdownContestArgs = {
  data: TouchdownContestCreateInput;
};


export type MutationCreateOrUpdateMathConstantArgs = {
  mathConstant: Array<MathConstantInput>;
};


export type MutationCreateOrUpdateNbaContestTitleArgs = {
  data: ContestTitleInput;
};


export type MutationCreateOrUpdateNflContestTitleArgs = {
  data: ContestTitleInput;
};


export type MutationCreatePartnerArgs = {
  data: CreatePartnerDto;
};


export type MutationCreatePartnerAdminArgs = {
  data: CreatePartnerAdminInputDto;
};


export type MutationCreatePartnerHubContestArgs = {
  data: PartnerCreateHubContestInputDto;
};


export type MutationCreatePartnerNbaTouchdownContestArgs = {
  data: TouchdownPartnerContestCreateInput;
};


export type MutationCreatePartnerPrizePoolArgs = {
  data: CreatePrizePoolInputDto;
};


export type MutationCreatePartnerSoccerTouchdownContestArgs = {
  data: SoccerTouchdownPartnerContestCreateInput;
};


export type MutationCreatePartnerSportArgs = {
  data: CreateSportDto;
};


export type MutationCreatePartnerTouchdownArgs = {
  data: PartnerCreateTouchdownInputDto;
};


export type MutationCreatePrizePoolArgs = {
  data: CreatePrizePoolInput;
};


export type MutationCreateSoccerTouchdownContestArgs = {
  data: SoccerTouchdownContestCreateInput;
};


export type MutationCreateSportArgs = {
  data: CreateSportInput;
};


export type MutationCreateSuggestionArgs = {
  data: CreateSuggestionInputType;
};


export type MutationCreateTouchdownArgs = {
  data: CreateTouchdownInput;
};


export type MutationDeleteCommonHubContestArgs = {
  contestId: Scalars['Int'];
};


export type MutationDeleteCommonNbaTouchdownContestArgs = {
  data: CommonContestDeleteInput;
};


export type MutationDeleteCommonSoccerTouchdownContestArgs = {
  data: CommonContestDeleteInput;
};


export type MutationDeleteCouponsArgs = {
  data: DeleteCouponsDto;
};


export type MutationDeleteF2PFeatureContestArgs = {
  data: CreateFeatureContestInput;
};


export type MutationDeleteF2PHubContestArgs = {
  contestId: Scalars['Int'];
};


export type MutationDeleteF2PNbaTouchdownContestArgs = {
  data: F2PTouchdownContestDeleteInput;
};


export type MutationDeleteF2PSoccerTouchdownContestArgs = {
  data: F2PTouchdownContestDeleteInput;
};


export type MutationDeleteFeaturedContestArgs = {
  data: DeleteFeaturedContestInputType;
};


export type MutationDeleteHubContestArgs = {
  contestId: Scalars['Int'];
};


export type MutationDeleteNbaTouchdownContestArgs = {
  data: TouchdownContestDeleteInput;
};


export type MutationDeleteNflMatchupArgs = {
  data: SuggestionDeleteInput;
};


export type MutationDeleteNflTouchdownContestArgs = {
  data: TouchdownContestDeleteInput;
};


export type MutationDeletePartnerHubContestArgs = {
  contestId: Scalars['Int'];
};


export type MutationDeletePartnerNbaTouchdownContestArgs = {
  data: TouchdownContestDeleteInput;
};


export type MutationDeletePartnerSoccerTouchdownContestArgs = {
  data: TouchdownContestDeleteInput;
};


export type MutationDeleteRoleArgs = {
  roleId: Scalars['Int'];
};


export type MutationDeleteSoccerTouchdownContestArgs = {
  data: TouchdownContestDeleteInput;
};


export type MutationDeleteSuggestionArgs = {
  data: DeleteSuggestionInputType;
};


export type MutationDeleteUserPermanentlyArgs = {
  data: DeleteUserPermanentlyDto;
};


export type MutationEnableF2PSportArgs = {
  data: F2PEnableSportInputDto;
};


export type MutationEnablePartnerSportArgs = {
  data: EnableSportInputDto;
};


export type MutationEnabledSportArgs = {
  data: EnabledSportInput;
};


export type MutationGetTeeTimeByRoundArgs = {
  roundNumber: Scalars['Int'];
  tournamentId?: InputMaybe<Scalars['String']>;
  year: Scalars['Int'];
};


export type MutationGradeCommonHubContestArgs = {
  data: GradeCommonHubContestInput;
};


export type MutationGradeF2PHubContestArgs = {
  data: GradeF2PHubContestInputDto;
};


export type MutationGradeHubContestArgs = {
  data: GradeHubContestInput;
};


export type MutationGradePartnerHubContestArgs = {
  data: GradeHubContestInputDto;
};


export type MutationGradeTournamentArgs = {
  tournamentId?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Int']>;
};


export type MutationImportTournamentsArgs = {
  data: Array<FjTournamentInputType>;
};


export type MutationOrderContestsArgs = {
  data: Array<ContestOrderInput>;
};


export type MutationOrderF2PContestsArgs = {
  data: Array<ContestOrderF2PInput>;
  orderFeatureContest: Scalars['Boolean'];
  prizePoolId: Scalars['Int'];
};


export type MutationOrderF2PSportArgs = {
  data: F2POrderSportsDto;
};


export type MutationOrderMatchupsArgs = {
  data: Array<SuggestionOrderInput>;
};


export type MutationOrderPartnerContestsArgs = {
  data: Array<ContestOrderPartnerInput>;
  prizePoolId: Scalars['Int'];
};


export type MutationOrderPartnerSportArgs = {
  data: OrderSportsDto;
};


export type MutationOrderSportsArgs = {
  data: Array<SportOrderInput>;
};


export type MutationPublishF2PHubContestArgs = {
  contestId: Scalars['Int'];
  published: Scalars['Boolean'];
};


export type MutationPublishF2PPrizePoolArgs = {
  prizePoolId: Scalars['Int'];
};


export type MutationPublishHubContestArgs = {
  contestId: Scalars['Int'];
  published: Scalars['Boolean'];
};


export type MutationPublishPartnerCommonContestArgs = {
  data: PublishCommonContestInput;
};


export type MutationPublishPartnerHubContestArgs = {
  contestId: Scalars['Int'];
  published: Scalars['Boolean'];
};


export type MutationPublishPartnerPrizePoolArgs = {
  prizePoolId: Scalars['Int'];
};


export type MutationRegeneratePartnerKeyArgs = {
  data: RegeneratePartnerKeyDto;
};


export type MutationRunTaskArgs = {
  taskId: Scalars['String'];
};


export type MutationSendContestGradedEmailArgs = {
  contestIds: Scalars['String'];
};


export type MutationUpdateAdminActiveStatusArgs = {
  data: PartnerAdminActiveInputDto;
};


export type MutationUpdateCommonHubContestArgs = {
  data: UpdateCommonHubContestInput;
};


export type MutationUpdateCommonSportArgs = {
  data: UpdateCommonSportDto;
};


export type MutationUpdateF2PHubContestArgs = {
  data: UpdateF2PHubContestInputDto;
};


export type MutationUpdateF2PNbaContestArgs = {
  data: UpdateF2PNbaContestInput;
};


export type MutationUpdateF2PPartnerArgs = {
  data: UpdateF2PPartnerInput;
};


export type MutationUpdateF2PPartnerActiveStatusArgs = {
  data: F2PPartnerActiveInputDto;
};


export type MutationUpdateF2PSoccerTouchdownContestArgs = {
  data: UpdateF2PSoccerContestInput;
};


export type MutationUpdateF2PSportArgs = {
  data: F2PUpdateSportDto;
};


export type MutationUpdateF2PTouchdownArgs = {
  data: F2PUpdateTouchdownInputDto;
};


export type MutationUpdateFeatureFlagsArgs = {
  data: FeatureFlagInputType;
};


export type MutationUpdateFeaturedContestArgs = {
  data: UpdateFeaturedContestInputType;
};


export type MutationUpdateHubContestArgs = {
  data: UpdateHubContestInput;
};


export type MutationUpdatePartnerArgs = {
  data: UpdatePartnerDto;
};


export type MutationUpdatePartnerActiveStatusArgs = {
  data: PartnerActiveInputDto;
};


export type MutationUpdatePartnerAdminArgs = {
  data: UpdatePartnerAdminInputDto;
};


export type MutationUpdatePartnerHubContestArgs = {
  data: UpdateHubContestInputDto;
};


export type MutationUpdatePartnerNbaContestArgs = {
  data: UpdatePartnerNbaContestInput;
};


export type MutationUpdatePartnerPrizeConstantArgs = {
  prizeConstant: PartnerUpdatePrizeConstantInputDto;
};


export type MutationUpdatePartnerSoccerTouchdownContestArgs = {
  data: UpdateSoccerContestInput;
};


export type MutationUpdatePartnerSportArgs = {
  data: UpdateSportDto;
};


export type MutationUpdatePartnerTouchdownArgs = {
  data: PartnerUpdateTouchdownInputDto;
};


export type MutationUpdatePrizePoolArgs = {
  data: CreatePrizePoolInput;
  prizePoolId: Scalars['Float'];
};


export type MutationUpdateSportArgs = {
  data: UpdateSportInput;
};


export type MutationUpdateSuggestionArgs = {
  data: UpdateSuggestionInputType;
};


export type MutationUpdateTouchdownArgs = {
  data: UpdateTouchdownInput;
  touchdownId: Scalars['Float'];
};


export type MutationUpdateTournamentsArgs = {
  data: TournamentsStateInputType;
};


export type MutationUpdateWeeklyConstantArgs = {
  data: WeeklyConstantInput;
};


export type MutationUserActivationArgs = {
  userId: Scalars['Int'];
};


export type MutationVoidContestArgs = {
  data: VoidContestInputType;
};


export type MutationWalletBonusDepositArgs = {
  data: WalletDepositDto;
};


export type MutationWithdrawRequestActionArgs = {
  data: WithdrawRequestActionDto;
};

export type NbaCommonTouchdownContestDto = {
  id: Scalars['Int'];
  player1: NbaCommonTouchdownPlayerDto;
  player1BonusPoints?: Maybe<Scalars['Float']>;
  player2: NbaCommonTouchdownPlayerDto;
  player2BonusPoints?: Maybe<Scalars['Float']>;
  status: Scalars['String'];
};

export type NbaCommonTouchdownPlayerDto = {
  available: Scalars['Boolean'];
  espnPlayerId: Scalars['Int'];
  fantasyPoints: Scalars['Int'];
  fantasyPointsFullPpr: Scalars['Int'];
  fantasyPointsHalfPpr: Scalars['Int'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  gameId: Scalars['Int'];
  gameStartTime: Scalars['String'];
  hasStarted: Scalars['Boolean'];
  homeOrAway: Scalars['String'];
  id: Scalars['Int'];
  injuryStatus?: Maybe<Scalars['String']>;
  isOver: Scalars['Boolean'];
  lastName: Scalars['String'];
  opponentName: Scalars['String'];
  photoUrl: Scalars['String'];
  photoUrlHiRes: Scalars['String'];
  position: Scalars['String'];
  projectedFantasyPoints?: Maybe<Scalars['Float']>;
  projectedFantasyPointsHalfPpr: Scalars['Int'];
  remoteId: Scalars['Int'];
  shortName: Scalars['String'];
  teamId: Scalars['Int'];
  teamName: Scalars['String'];
  yahooPlayerId: Scalars['Int'];
};

export type NbaF2PTouchdownContestDto = {
  availableInTouchdowns: Array<F2PAvailableIn>;
  featureContestOrder?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  isFeatureContest?: Maybe<Scalars['Boolean']>;
  player1: F2PNbaTouchdownPlayerDto;
  player1BonusPoints?: Maybe<Scalars['Float']>;
  player2: F2PNbaTouchdownPlayerDto;
  player2BonusPoints?: Maybe<Scalars['Float']>;
  prizePoolId: Scalars['Int'];
  status: Scalars['String'];
};

export type NbaPartnerTouchdownContestDto = {
  availableInTouchdowns: Array<AvailableIn>;
  id: Scalars['Int'];
  player1: NbaTouchdownPlayerDto;
  player1BonusPoints?: Maybe<Scalars['Float']>;
  player2: NbaTouchdownPlayerDto;
  player2BonusPoints?: Maybe<Scalars['Float']>;
  prizePoolId: Scalars['Int'];
  status: Scalars['String'];
};

export type NbaTouchdownCommonContestCreateInput = {
  player1BonusPoints: Scalars['Float'];
  player1GameId: Scalars['Int'];
  player1Id: Scalars['Int'];
  player1ProjFantasyPoints: Scalars['Float'];
  player2BonusPoints: Scalars['Float'];
  player2GameId: Scalars['Int'];
  player2Id: Scalars['Int'];
  player2ProjFantasyPoints: Scalars['Float'];
  startTime: Scalars['String'];
};

export type NbaTouchdownContestDto = {
  id: Scalars['Int'];
  player1: NbaTouchdownPlayerDto;
  player1BonusPoints?: Maybe<Scalars['Float']>;
  player2: NbaTouchdownPlayerDto;
  player2BonusPoints?: Maybe<Scalars['Float']>;
  status: Scalars['String'];
};

export type NbaTouchdownF2PContestDto = {
  featureContestOrder?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  isFeatureContest?: Maybe<Scalars['Boolean']>;
  player1: F2PNbaTouchdownPlayerDto;
  player1BonusPoints?: Maybe<Scalars['Float']>;
  player2: F2PNbaTouchdownPlayerDto;
  player2BonusPoints?: Maybe<Scalars['Float']>;
  prizePoolId: Scalars['Int'];
  status: Scalars['String'];
};

export type NbaTouchdownPartnerContestDto = {
  id: Scalars['Int'];
  player1: NbaTouchdownPlayerDto;
  player1BonusPoints?: Maybe<Scalars['Float']>;
  player2: NbaTouchdownPlayerDto;
  player2BonusPoints?: Maybe<Scalars['Float']>;
  prizePoolId: Scalars['Int'];
  status: Scalars['String'];
};

export type NbaTouchdownPlayerDto = {
  available: Scalars['Boolean'];
  espnPlayerId: Scalars['Int'];
  fantasyPoints: Scalars['Int'];
  fantasyPointsFullPpr: Scalars['Int'];
  fantasyPointsHalfPpr: Scalars['Int'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  gameId: Scalars['Int'];
  gameStartTime: Scalars['String'];
  hasStarted: Scalars['Boolean'];
  homeOrAway: Scalars['String'];
  id: Scalars['Int'];
  injuryStatus?: Maybe<Scalars['String']>;
  isOver: Scalars['Boolean'];
  lastName: Scalars['String'];
  opponentName: Scalars['String'];
  photoUrl: Scalars['String'];
  photoUrlHiRes: Scalars['String'];
  position: Scalars['String'];
  projectedFantasyPoints?: Maybe<Scalars['Float']>;
  projectedFantasyPointsHalfPpr: Scalars['Int'];
  remoteId: Scalars['Int'];
  shortName: Scalars['String'];
  teamId: Scalars['Int'];
  teamName: Scalars['String'];
  yahooPlayerId: Scalars['Int'];
};

export type NflPlayerDto = {
  available: Scalars['Boolean'];
  espnPlayerId: Scalars['Int'];
  fantasyPoints: Scalars['Int'];
  fantasyPointsFullPpr: Scalars['Int'];
  fantasyPointsHalfPpr: Scalars['Int'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  hasStarted: Scalars['Boolean'];
  homeOrAway: Scalars['String'];
  id: Scalars['Int'];
  injuryStatus?: Maybe<Scalars['String']>;
  isOver: Scalars['Boolean'];
  lastName: Scalars['String'];
  opponentName: Scalars['String'];
  photoUrl: Scalars['String'];
  photoUrlHiRes: Scalars['String'];
  position: Scalars['String'];
  projectedFantasyPoints: Scalars['Int'];
  projectedFantasyPointsHalfPpr: Scalars['Int'];
  remoteId: Scalars['Int'];
  shortName: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  teamId: Scalars['Int'];
  teamName: Scalars['String'];
  yahooPlayerId: Scalars['Int'];
};

export type NflReportUserDto = {
  email: Scalars['String'];
  fullName: Scalars['String'];
};

export type NflTouchdownContestDto = {
  id: Scalars['Int'];
  player1: NflTouchdownPlayerDto;
  player1BonusPoints?: Maybe<Scalars['Float']>;
  player2: NflTouchdownPlayerDto;
  player2BonusPoints?: Maybe<Scalars['Float']>;
  status: Scalars['String'];
};

export type NflTouchdownPlayerDto = {
  available: Scalars['Boolean'];
  espnPlayerId: Scalars['Int'];
  fantasyPoints: Scalars['Int'];
  fantasyPointsFullPpr: Scalars['Int'];
  fantasyPointsHalfPpr: Scalars['Int'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  gameId: Scalars['Int'];
  gameStartTime: Scalars['String'];
  hasStarted: Scalars['Boolean'];
  homeOrAway: Scalars['String'];
  id: Scalars['Int'];
  injuryStatus?: Maybe<Scalars['String']>;
  isOver: Scalars['Boolean'];
  lastName: Scalars['String'];
  opponentName: Scalars['String'];
  photoUrl: Scalars['String'];
  photoUrlHiRes: Scalars['String'];
  position: Scalars['String'];
  projectedFantasyPoints?: Maybe<Scalars['Float']>;
  projectedFantasyPointsHalfPpr: Scalars['Int'];
  remoteId: Scalars['Int'];
  shortName: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  teamId: Scalars['Int'];
  teamName: Scalars['String'];
  yahooPlayerId: Scalars['Int'];
};

export type OrderSport = {
  orderIndex: Scalars['Int'];
  sportId: Scalars['Int'];
};

export type OrderSportsDto = {
  partnerId: Scalars['Int'];
  sports: Array<OrderSport>;
};

export enum Prize_Pool_Status {
  Completed = 'COMPLETED',
  Draft = 'DRAFT',
  Live = 'LIVE',
  Pending = 'PENDING'
}

export type PaginatedDailyLeaderBoardDto = {
  data: Array<DailyLeaderBoardDto>;
  limit: Scalars['Int'];
  page: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PaginatedLeaderBoardDto = {
  data: Array<LeaderBoardDto>;
  limit: Scalars['Int'];
  page: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PartnerActiveInputDto = {
  active: Scalars['Boolean'];
  id: Scalars['Int'];
};

export type PartnerAdminActiveInputDto = {
  active: Scalars['Boolean'];
  id: Scalars['Int'];
};

export type PartnerAdminDto = {
  active: Scalars['Boolean'];
  email: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['Int'];
  phone?: Maybe<Scalars['String']>;
};

export type PartnerAdminInputDto = {
  email: Scalars['String'];
  fullName: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
};

export type PartnerCreateHubContestInputDto = {
  partnerId: Scalars['Int'];
  player1BonusPoints: Scalars['Float'];
  player1FullName: Scalars['String'];
  player1Game: Scalars['String'];
  player1GameStartTime: Scalars['String'];
  player1PhotoUrl: Scalars['String'];
  player2BonusPoints: Scalars['Float'];
  player2FullName: Scalars['String'];
  player2Game: Scalars['String'];
  player2GameStartTime: Scalars['String'];
  player2PhotoUrl: Scalars['String'];
  prizePoolIds: Array<Scalars['Int']>;
  published: Scalars['Boolean'];
  questionId: Scalars['Int'];
  sportId: Scalars['Int'];
};

export type PartnerCreateTouchdownInputDto = {
  allowedPick: Scalars['Int'];
  endDateTime: Scalars['String'];
  entryFees: Scalars['Float'];
  name: Scalars['String'];
  partnerId: Scalars['Int'];
  prizeConfiguration: PrizeConfigurationInputDto;
  prizePool: PrizePoolInputDto;
  startDateTime: Scalars['String'];
};

export type PartnerCreateTouchdownResponseDto = {
  allowedPick: Scalars['Int'];
  createdAt: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  endDateTime: Scalars['String'];
  entryFees: Scalars['Float'];
  id: Scalars['Int'];
  name: Scalars['String'];
  partners?: Maybe<Array<PartnerDto>>;
  prizeConfiguration: PartnerPrizeValuesDto;
  prizePools: Array<PrizePoolResponseDto>;
  startDateTime: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type PartnerDto = {
  active: Scalars['Boolean'];
  admins: Array<PartnerAdminDto>;
  apiKeys: Array<ApiKeyDto>;
  contact: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['Int'];
  identifier: Scalars['String'];
  iframeKey: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  primaryColor?: Maybe<Scalars['String']>;
  prizeConfiguration: PartnerPrizeValuesDto;
  secondaryColor?: Maybe<Scalars['String']>;
  secretKey: Scalars['String'];
  totalTouchdownCount?: Maybe<Scalars['Int']>;
  userCount?: Maybe<Scalars['Int']>;
};

export type PartnerDailyLeaderBoardDto = {
  endDateTime: Scalars['String'];
  entries: Array<Entry>;
  startDateTime: Scalars['String'];
};

export type PartnerFetchContestResponseDto = {
  commonContestId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  matchDate: Scalars['String'];
  matchDateStartTime: Scalars['String'];
  metric: Scalars['String'];
  orderIndex?: Maybe<Scalars['Int']>;
  player1BonusPoints: Scalars['Float'];
  player1FullName: Scalars['String'];
  player1PhotoUrl: Scalars['String'];
  player2BonusPoints: Scalars['Float'];
  player2FullName: Scalars['String'];
  player2PhotoUrl: Scalars['String'];
  prizePoolId: Scalars['Int'];
  sportName: Scalars['String'];
  type: Scalars['String'];
};

export type PartnerFetchTouchdownInputDto = {
  duration: Scalars['String'];
  endDateTime?: InputMaybe<Scalars['DateTime']>;
  limit: Scalars['Int'];
  page: Scalars['Int'];
  partnerId: Scalars['Int'];
  startDateTime?: InputMaybe<Scalars['DateTime']>;
};

export type PartnerHubContestCountResponseDto = {
  count: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type PartnerPaginationResponseDto = {
  data?: Maybe<Array<PartnerCreateTouchdownResponseDto>>;
  limit: Scalars['Int'];
  page: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PartnerPrizeConstantDto = {
  prizeConfiguration: PartnerPrizeValuesDto;
};

export type PartnerPrizePoolInfoResponseDto = {
  allowedSports: Array<Scalars['Int']>;
  contestVig: Scalars['Float'];
  createdAt: Scalars['String'];
  endDateTime: Scalars['String'];
  gppAmount: Scalars['Float'];
  gppWinners: Scalars['Int'];
  hubContestCount: Scalars['Int'];
  id: Scalars['Int'];
  isRollsOver: Scalars['Boolean'];
  jackpotAmount: Scalars['Float'];
  jackpotWinners: Scalars['Int'];
  lockTime: Scalars['String'];
  maxEntriesPerUser: Scalars['Int'];
  nbaContestCount: Scalars['Int'];
  nflContestCount: Scalars['Int'];
  predetermineJackpotAmount: Scalars['Float'];
  rolloverAmount: Scalars['Float'];
  rolloverJackpotAmount: Scalars['Float'];
  rolloverVig: Scalars['Float'];
  soccerContestCount: Scalars['Int'];
  startDateTime: Scalars['String'];
  status: PrizePoolStatus;
  totalEntrants: Scalars['Int'];
  totalPrize: Scalars['Float'];
  updatedAt: Scalars['String'];
  userEntryCount: Scalars['Int'];
  weeklyReserveAmount: Scalars['Float'];
};

export type PartnerPrizeValuesDto = {
  GPP: Scalars['Float'];
  PRIZE_POOL: Scalars['Float'];
  WEEKLY_RESERVE: Scalars['Float'];
};

export type PartnerPublishHubContestResponseDto = {
  createdAt?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  player1BonusPoints: Scalars['Float'];
  player1FantasyPoints?: Maybe<Scalars['Float']>;
  player1FullName: Scalars['String'];
  player1Game: Scalars['String'];
  player1GameStartTime: Scalars['String'];
  player1PhotoUrl: Scalars['String'];
  player2BonusPoints: Scalars['Float'];
  player2FantasyPoints?: Maybe<Scalars['Float']>;
  player2FullName: Scalars['String'];
  player2Game: Scalars['String'];
  player2GameStartTime: Scalars['String'];
  player2PhotoUrl: Scalars['String'];
  prizePoolId: Scalars['Int'];
  published: Scalars['Boolean'];
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  winnerLabel?: Maybe<Scalars['String']>;
};

export type PartnerTouchdownInfoResponseDto = {
  allowedPick: Scalars['Int'];
  endDateTime: Scalars['String'];
  entryFees: Scalars['Float'];
  id: Scalars['Int'];
  name: Scalars['String'];
  prizePools: Array<PartnerPrizePoolInfoResponseDto>;
  startDateTime: Scalars['String'];
};

export type PartnerUpdateContestHubResponseDto = {
  createdAt?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  player1BonusPoints: Scalars['Float'];
  player1FantasyPoints?: Maybe<Scalars['Float']>;
  player1FullName: Scalars['String'];
  player1Game: Scalars['String'];
  player1GameStartTime: Scalars['String'];
  player1PhotoUrl: Scalars['String'];
  player2BonusPoints: Scalars['Float'];
  player2FantasyPoints?: Maybe<Scalars['Float']>;
  player2FullName: Scalars['String'];
  player2Game: Scalars['String'];
  player2GameStartTime: Scalars['String'];
  player2PhotoUrl: Scalars['String'];
  prizePoolId: Scalars['Int'];
  published: Scalars['Boolean'];
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  winnerLabel?: Maybe<Scalars['String']>;
};

export type PartnerUpdatePrizeConstantInputDto = {
  GPP: Scalars['Float'];
  PRIZE_POOL: Scalars['Float'];
  WEEKLY_RESERVE: Scalars['Float'];
  partnerId: Scalars['Int'];
};

export type PartnerUpdateTouchdownInputDto = {
  allowedPick: Scalars['Int'];
  entryFees: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
  prizePool: UpdatePrizePoolInput;
};

/** Permission ID */
export enum PermissionId {
  CommonBonusPayout = 'CommonBonusPayout',
  CommonBroadcastNotifications = 'CommonBroadcastNotifications',
  CommonContestSuggestions = 'CommonContestSuggestions',
  CommonCoupons = 'CommonCoupons',
  CommonDeletionRequestsManage = 'CommonDeletionRequestsManage',
  CommonFeaturedContestsManage = 'CommonFeaturedContestsManage',
  CommonFeaturedContestsView = 'CommonFeaturedContestsView',
  CommonSupportTickets = 'CommonSupportTickets',
  CommonUserSignups = 'CommonUserSignups',
  CommonWithdrawRequests = 'CommonWithdrawRequests',
  GolfContestCreationManage = 'GolfContestCreationManage',
  GolfContestSuggestionsManage = 'GolfContestSuggestionsManage',
  GolfContestSuggestionsView = 'GolfContestSuggestionsView',
  GolfContestVoidCancel = 'GolfContestVoidCancel',
  GolfFeaturesManage = 'GolfFeaturesManage',
  GolfFeaturesView = 'GolfFeaturesView',
  GolfReports = 'GolfReports',
  GolfTasks = 'GolfTasks',
  GolfTournamentManage = 'GolfTournamentManage',
  GolfTournamentView = 'GolfTournamentView',
  NflMatchups = 'NflMatchups',
  NflReports = 'NflReports'
}

export type Player = {
  createdAt: Scalars['String'];
  firstName: Scalars['String'];
  headshotUrlLarge: Scalars['String'];
  headshotUrlSmall: Scalars['String'];
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  lastName: Scalars['String'];
  playerId: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type PlayerDto = {
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['Int'];
  lastName: Scalars['String'];
  photoUrl: Scalars['String'];
  photoUrlHiRes: Scalars['String'];
};

export type PrizeConfigurationInputDto = {
  GPP: Scalars['Float'];
  PRIZE_POOL: Scalars['Float'];
  WEEKLY_RESERVE: Scalars['Float'];
};

export type PrizeInput = {
  id: Scalars['Int'];
  value: Scalars['Float'];
};

export type PrizePoolContestDto = {
  actualContestVig?: Maybe<Scalars['Float']>;
  actualDailyGPP?: Maybe<Scalars['Float']>;
  actualJackpot?: Maybe<Scalars['Float']>;
  actualPrizePool?: Maybe<Scalars['Float']>;
  actualWeeklyReserve?: Maybe<Scalars['Float']>;
  endDate: Scalars['String'];
  entryFees: Scalars['Int'];
  isRollsOver: Scalars['Boolean'];
  lockTime?: Maybe<Scalars['String']>;
  maxEntriesPerUser: Scalars['Int'];
  predetermineContestVig: Scalars['Float'];
  predetermineDailyGPP: Scalars['Float'];
  predetermineJackpot: Scalars['Float'];
  predeterminePrizePool: Scalars['Float'];
  predetermineWeeklyReserve: Scalars['Float'];
  prizePoolId: Scalars['Int'];
  startDate: Scalars['String'];
  status: PrizePoolStatus;
  supportedSports: Array<SupportedSports>;
  totalEntrants: Scalars['Int'];
};

export type PrizePoolDto = {
  actualContestVig?: Maybe<Scalars['Float']>;
  actualDailyGPP?: Maybe<Scalars['Float']>;
  actualJackpot?: Maybe<Scalars['Float']>;
  actualPrizePool?: Maybe<Scalars['Float']>;
  actualRolloverVig?: Maybe<Scalars['Float']>;
  actualWeeklyReserve?: Maybe<Scalars['Float']>;
  addedByTopProp: Scalars['Float'];
  endDate: Scalars['String'];
  entryFees: Scalars['Float'];
  gppWinnersCount: Scalars['Int'];
  isRollsOver: Scalars['Boolean'];
  jackpotWinnersCount: Scalars['Int'];
  lockTime?: Maybe<Scalars['String']>;
  maxEntriesPerUser: Scalars['Float'];
  mlbContestsCount: Scalars['Int'];
  nbaContestsCount: Scalars['Int'];
  nflContestsCount: Scalars['Int'];
  pgaContestsCount: Scalars['Int'];
  predetermineContestVig: Scalars['Float'];
  predetermineDailyGPP: Scalars['Float'];
  predetermineJackpot: Scalars['Float'];
  predeterminePrizePool: Scalars['Float'];
  predetermineRolloverVig: Scalars['Float'];
  predetermineWeeklyReserve: Scalars['Float'];
  prizePoolId: Scalars['Int'];
  profit?: Maybe<Scalars['Float']>;
  soccerContestsCount: Scalars['Int'];
  startDate: Scalars['String'];
  status: PrizePoolStatus;
  supportedSports: Array<SupportedSports>;
  totalEntrants: Scalars['Float'];
  userEntryCount: Scalars['Int'];
};

export type PrizePoolInputDto = {
  allowedSports: Array<Scalars['Int']>;
  contestVig: Scalars['Float'];
  endDateTime: Scalars['String'];
  gppAmount: Scalars['Float'];
  jackpotAmount: Scalars['Float'];
  lockTime: Scalars['String'];
  maxEntriesPerUser: Scalars['Int'];
  startDateTime: Scalars['String'];
  totalEntrants: Scalars['Int'];
  totalPrize: Scalars['Float'];
};

export type PrizePoolResponseDto = {
  allowedSports: Array<Scalars['Int']>;
  contestVig: Scalars['Float'];
  createdAt: Scalars['String'];
  endDateTime: Scalars['String'];
  gppAmount: Scalars['Float'];
  id: Scalars['Int'];
  isRollsOver: Scalars['Boolean'];
  jackpotAmount: Scalars['Float'];
  lockTime: Scalars['String'];
  maxEntriesPerUser: Scalars['Int'];
  predetermineJackpotAmount: Scalars['Float'];
  rolloverAmount: Scalars['Float'];
  rolloverJackpotAmount: Scalars['Float'];
  rolloverVig: Scalars['Float'];
  startDateTime: Scalars['String'];
  status: PrizePoolStatus;
  totalEntrants: Scalars['Int'];
  totalPrize: Scalars['Float'];
  updatedAt: Scalars['String'];
  weeklyReserveAmount: Scalars['Float'];
};

export enum PrizePoolStatus {
  Completed = 'COMPLETED',
  Draft = 'DRAFT',
  Live = 'LIVE',
  Pending = 'PENDING'
}

export type PublishCommonContestInput = {
  commonSportId: Scalars['Int'];
  contests: FetchCommonContestInput;
  partnerId: Scalars['Int'];
  prizePoolId: Scalars['Int'];
};

export type PublishPartnerCommonContestResponse = {
  message: Scalars['String'];
};

export type Query = {
  countCommonContests: Array<CommonContestCountDto>;
  countF2PContests: Array<F2PHubContestCountResponseDto>;
  countF2PHubContests: Array<F2PHubContestCountResponseDto>;
  countHubContests: Array<HubContestCountDto>;
  countPartnerContests: Array<PartnerHubContestCountResponseDto>;
  countPartnerHubContests: Array<PartnerHubContestCountResponseDto>;
  featureFlags: FeatureFlag;
  fetchContests: Array<ContestDto>;
  fetchF2PContests: Array<F2PFetchContestResponseDto>;
  fetchF2PHubContests: Array<F2PHubContestResponseDto>;
  fetchF2PTouchdown: F2PPaginationResponseDto;
  fetchF2PTouchdownByDate: Array<FetchF2PTouchdownByDateResponse>;
  fetchF2PTouchdownById: F2PCreateTouchdownResponseDto;
  fetchHubContests: Array<HubContestDto>;
  fetchPartnerContests: Array<PartnerFetchContestResponseDto>;
  fetchPartnerHubContests: Array<HubContestResponseDto>;
  fetchPartnerTouchdown: PartnerPaginationResponseDto;
  fetchPartnerTouchdownByDate: Array<FetchPartnerTouchdownByDateResponse>;
  fetchPartnerTouchdownById: PartnerCreateTouchdownResponseDto;
  getAllContests: Array<BattlegroundContest>;
  getAllF2PPartner: Array<F2PPartnerInfoDto>;
  getAllNflPlayers: Array<NflPlayerDto>;
  getAllPartner: Array<PartnerDto>;
  getAllPermissions: Array<PermissionId>;
  getAllSupportTickets: Array<TicketDto>;
  getAudits: Array<Audit>;
  getCommonHubContest: Array<CommonHubContestResponse>;
  getCommonNbaTouchdownContestPlayers: Array<NbaCommonTouchdownPlayerDto>;
  getCommonNbaTouchdownContests: Array<NbaCommonTouchdownContestDto>;
  getCommonSoccerTouchdownContestPlayers: Array<SoccerCommonTouchdownPlayerDto>;
  getCommonSoccerTouchdownContests: Array<SoccerCommonTouchdownContestDto>;
  getCommonSport: Array<CommonSportOutputDto>;
  getContestsReport?: Maybe<Array<INflContest>>;
  getCoupons: Array<CouponDto>;
  getCurrentTournament: Tournament;
  getDailyLeaderBoard: PaginatedDailyLeaderBoardDto;
  getDailyReport: DailyCountReport;
  getF2PActiveTouchdowns: Array<F2PTouchdownInfoResponseDto>;
  getF2PDailyLeaderBoard: F2PDailyLeaderBoardDto;
  getF2PManualSports: Array<F2PSportOutputDto>;
  getF2PNbaTouchdownContestPlayers: Array<F2PNbaTouchdownPlayerDto>;
  getF2PNbaTouchdownContests: Array<NbaF2PTouchdownContestDto>;
  getF2PPartnerById: F2PPartnerDto;
  getF2PPartnerUsers: Array<F2PUserInfoDto>;
  getF2PSoccerTouchdownContestPlayers: Array<F2PSoccerTouchdownPlayerDto>;
  getF2PSoccerTouchdownContests: Array<F2PSoccerTouchdownContestDto>;
  getF2PSportByName: F2PSportOutputDto;
  getF2PSports: Array<F2PSportOutputDto>;
  getF2PTouchdownAnalytics: F2PTouchdownAnalyticsDto;
  getFeaturedContests: Array<SuggestionDto>;
  getMathConstant: Array<MathConstantDto>;
  getNbaContestTitle: ContestTitleDto;
  getNbaTouchdownContestPlayers: Array<NbaTouchdownPlayerDto>;
  getNbaTouchdownContests: Array<NbaTouchdownContestDto>;
  getNflContestTitle: ContestTitleDto;
  getNflMatchups: Array<MatchupDto>;
  getNflTouchdownContestPlayers: Array<NflTouchdownPlayerDto>;
  getNflTouchdownContests: Array<NflTouchdownContestDto>;
  getPartnerActiveTouchdowns: Array<PartnerTouchdownInfoResponseDto>;
  getPartnerAdmin: Array<PartnerAdminDto>;
  getPartnerById: PartnerDto;
  getPartnerCommonContests: CommonContestOutput;
  getPartnerDailyLeaderBoard: PartnerDailyLeaderBoardDto;
  getPartnerNbaTouchdownContestPlayers: Array<NbaTouchdownPlayerDto>;
  getPartnerNbaTouchdownContests: Array<NbaPartnerTouchdownContestDto>;
  getPartnerPrizeConstant: PartnerPrizeConstantDto;
  getPartnerSoccerTouchdownContestPlayers: Array<SoccerTouchdownPlayerDto>;
  getPartnerSoccerTouchdownContests: Array<SoccerTouchdownContestDto>;
  getPartnerSportByName: SportOutputDto;
  getPartnerSports: Array<SportOutputDto>;
  getPartnerTouchdownAnalytics: TouchdownAnalyticsDto;
  getPlayersForSuggestion: Array<Player>;
  getRoles: Array<Role>;
  getSoccerTouchdownContestPlayers: Array<SoccerTouchdownPlayerDto>;
  getSoccerTouchdownContests: Array<SoccerTouchdownContestDto>;
  getSport: SportDto;
  getSports: Array<SportDto>;
  getSportsLogo: Array<Scalars['String']>;
  getSuggestions: Array<SuggestionDto>;
  getTopups: Array<TopupDto>;
  getTouchdownByDate: Array<TouchdownDto>;
  getTournamentSuggestions: Array<GetTournamentSuggestionDto>;
  getUserSignUps: Array<UserDto>;
  getUsers: Array<User>;
  getWeeklyConstant: Array<WeeklyConstantDto>;
  getWeeklyHistoryByDateRange: Array<WeeklyHistoryDto>;
  getWeeklyLeaderBoard: PaginatedLeaderBoardDto;
  getWeeklySubscribers: WeeklySubscriberDto;
  getWithdrawRequests: Array<WithdrawRequestDto>;
  tournaments: Array<Tournament>;
};


export type QueryCountCommonContestsArgs = {
  date: Scalars['String'];
  type: Scalars['String'];
};


export type QueryCountF2PContestsArgs = {
  date: Scalars['String'];
  partnerId: Scalars['Int'];
  prizePoolId: Scalars['Int'];
  type: Scalars['String'];
};


export type QueryCountF2PHubContestsArgs = {
  date: Scalars['String'];
  prizePoolId: Scalars['Int'];
};


export type QueryCountHubContestsArgs = {
  date: Scalars['String'];
};


export type QueryCountPartnerContestsArgs = {
  date: Scalars['String'];
  partnerId: Scalars['Int'];
  prizePoolId: Scalars['Int'];
  type: Scalars['String'];
};


export type QueryCountPartnerHubContestsArgs = {
  date: Scalars['String'];
  prizePoolId: Scalars['Int'];
};


export type QueryFetchContestsArgs = {
  date: Scalars['String'];
  sportId?: InputMaybe<Scalars['Int']>;
};


export type QueryFetchF2PContestsArgs = {
  date: Scalars['String'];
  fetchFeatureContest: Scalars['Boolean'];
  partnerId: Scalars['Int'];
  prizePoolId: Scalars['Int'];
  sportId?: InputMaybe<Scalars['Int']>;
};


export type QueryFetchF2PHubContestsArgs = {
  date: Scalars['String'];
  prizePoolId: Scalars['Int'];
  sportId: Scalars['Int'];
};


export type QueryFetchF2PTouchdownArgs = {
  data: F2PFetchTouchdownInputDto;
};


export type QueryFetchF2PTouchdownByDateArgs = {
  data: FetchF2PTouchdownByDateInputDto;
};


export type QueryFetchF2PTouchdownByIdArgs = {
  touchdownId: Scalars['Float'];
};


export type QueryFetchHubContestsArgs = {
  date: Scalars['String'];
  sportId: Scalars['Int'];
};


export type QueryFetchPartnerContestsArgs = {
  date: Scalars['String'];
  partnerId: Scalars['Int'];
  prizePoolId: Scalars['Int'];
  sportId?: InputMaybe<Scalars['Int']>;
};


export type QueryFetchPartnerHubContestsArgs = {
  date: Scalars['String'];
  prizePoolId: Scalars['Int'];
  sportId: Scalars['Int'];
};


export type QueryFetchPartnerTouchdownArgs = {
  data: PartnerFetchTouchdownInputDto;
};


export type QueryFetchPartnerTouchdownByDateArgs = {
  data: FetchPartnerTouchdownByDateInputDto;
};


export type QueryFetchPartnerTouchdownByIdArgs = {
  touchdownId: Scalars['Float'];
};


export type QueryGetAllContestsArgs = {
  data: ContestsReportInputType;
};


export type QueryGetAuditsArgs = {
  data: AuditActionInputType;
};


export type QueryGetCommonHubContestArgs = {
  date: Scalars['String'];
  sportId: Scalars['Int'];
};


export type QueryGetCommonNbaTouchdownContestPlayersArgs = {
  date: Scalars['String'];
};


export type QueryGetCommonNbaTouchdownContestsArgs = {
  date: Scalars['String'];
};


export type QueryGetCommonSoccerTouchdownContestPlayersArgs = {
  date: Scalars['String'];
};


export type QueryGetCommonSoccerTouchdownContestsArgs = {
  date: Scalars['String'];
};


export type QueryGetContestsReportArgs = {
  data: GetContestsReportInput;
};


export type QueryGetDailyLeaderBoardArgs = {
  prizePoolId: Scalars['Int'];
  searchStr?: Scalars['String'];
};


export type QueryGetF2PActiveTouchdownsArgs = {
  partnerId: Scalars['Int'];
};


export type QueryGetF2PDailyLeaderBoardArgs = {
  prizePoolId: Scalars['Int'];
};


export type QueryGetF2PManualSportsArgs = {
  partnerId: Scalars['Int'];
};


export type QueryGetF2PNbaTouchdownContestPlayersArgs = {
  date: Scalars['String'];
};


export type QueryGetF2PNbaTouchdownContestsArgs = {
  date: Scalars['String'];
  prizePoolId: Scalars['Int'];
};


export type QueryGetF2PPartnerByIdArgs = {
  partnerId: Scalars['Int'];
};


export type QueryGetF2PPartnerUsersArgs = {
  partnerId: Scalars['Int'];
};


export type QueryGetF2PSoccerTouchdownContestPlayersArgs = {
  date: Scalars['String'];
};


export type QueryGetF2PSoccerTouchdownContestsArgs = {
  date: Scalars['String'];
  prizePoolId: Scalars['Int'];
};


export type QueryGetF2PSportByNameArgs = {
  name: Scalars['String'];
  partnerId: Scalars['Int'];
};


export type QueryGetF2PSportsArgs = {
  partnerId: Scalars['Int'];
  type: Scalars['String'];
};


export type QueryGetF2PTouchdownAnalyticsArgs = {
  partnerId: Scalars['Int'];
};


export type QueryGetNbaTouchdownContestPlayersArgs = {
  date: Scalars['String'];
};


export type QueryGetNbaTouchdownContestsArgs = {
  date: Scalars['String'];
};


export type QueryGetNflTouchdownContestPlayersArgs = {
  date: Scalars['String'];
};


export type QueryGetNflTouchdownContestsArgs = {
  date: Scalars['String'];
};


export type QueryGetPartnerActiveTouchdownsArgs = {
  partnerId: Scalars['Int'];
};


export type QueryGetPartnerAdminArgs = {
  partnerId: Scalars['Int'];
};


export type QueryGetPartnerByIdArgs = {
  partnerId: Scalars['Int'];
};


export type QueryGetPartnerCommonContestsArgs = {
  date: Scalars['String'];
  prizePoolId: Scalars['Int'];
  sportId: Scalars['Int'];
};


export type QueryGetPartnerDailyLeaderBoardArgs = {
  prizePoolId: Scalars['Int'];
};


export type QueryGetPartnerNbaTouchdownContestPlayersArgs = {
  date: Scalars['String'];
};


export type QueryGetPartnerNbaTouchdownContestsArgs = {
  date: Scalars['String'];
  prizePoolId: Scalars['Int'];
};


export type QueryGetPartnerPrizeConstantArgs = {
  partnerId: Scalars['Int'];
};


export type QueryGetPartnerSoccerTouchdownContestPlayersArgs = {
  date: Scalars['String'];
};


export type QueryGetPartnerSoccerTouchdownContestsArgs = {
  date: Scalars['String'];
  prizePoolId: Scalars['Int'];
};


export type QueryGetPartnerSportByNameArgs = {
  name: Scalars['String'];
  partnerId: Scalars['Int'];
};


export type QueryGetPartnerSportsArgs = {
  partnerId: Scalars['Int'];
  type: Scalars['String'];
};


export type QueryGetPartnerTouchdownAnalyticsArgs = {
  partnerId: Scalars['Int'];
};


export type QueryGetPlayersForSuggestionArgs = {
  data: GetPlayersForSuggestionInputType;
};


export type QueryGetSoccerTouchdownContestPlayersArgs = {
  date: Scalars['String'];
};


export type QueryGetSoccerTouchdownContestsArgs = {
  date: Scalars['String'];
};


export type QueryGetSportArgs = {
  name: Scalars['String'];
};


export type QueryGetSuggestionsArgs = {
  data: GetSuggestionsInputType;
};


export type QueryGetTouchdownByDateArgs = {
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryGetTournamentSuggestionsArgs = {
  data: GetTournamentSuggestionsInputType;
};


export type QueryGetWeeklyHistoryByDateRangeArgs = {
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryGetWeeklyLeaderBoardArgs = {
  searchStr?: Scalars['String'];
  touchdownId: Scalars['Int'];
};


export type QueryGetWeeklySubscribersArgs = {
  endDate: Scalars['String'];
  searchStr?: Scalars['String'];
  startDate: Scalars['String'];
};

export type QuestionDto = {
  id: Scalars['Int'];
  metric: Scalars['String'];
  question: Scalars['String'];
};

export type RegeneratePartnerKeyDto = {
  creatorId: Scalars['Int'];
  partnerId: Scalars['Int'];
};

export type Role = {
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  permissions: Array<PermissionId>;
  updatedAt: Scalars['DateTime'];
};

export enum Supported_Sport {
  Hub = 'HUB',
  Nba = 'NBA',
  Nfl = 'NFL',
  Soccer = 'SOCCER'
}

export type SoccerCommonTouchdownContestCreateInput = {
  metric: Scalars['String'];
  player1BonusPoints: Scalars['Float'];
  player1GameId: Scalars['Int'];
  player1Id: Scalars['Int'];
  player1ProjFantasyPoints: Scalars['Float'];
  player2BonusPoints: Scalars['Float'];
  player2GameId: Scalars['Int'];
  player2Id: Scalars['Int'];
  player2ProjFantasyPoints: Scalars['Float'];
  question: Scalars['String'];
  startTime: Scalars['String'];
};

export type SoccerCommonTouchdownContestDto = {
  id: Scalars['Int'];
  metric: Scalars['String'];
  player1: SoccerCommonTouchdownPlayerDto;
  player1BonusPoints?: Maybe<Scalars['Float']>;
  player2: SoccerCommonTouchdownPlayerDto;
  player2BonusPoints?: Maybe<Scalars['Float']>;
  status: Scalars['String'];
};

export type SoccerCommonTouchdownPlayerDto = {
  assists?: Maybe<Scalars['Float']>;
  commonName: Scalars['String'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  gameId: Scalars['Int'];
  gameStartTime: Scalars['String'];
  goals?: Maybe<Scalars['Float']>;
  hasStarted: Scalars['Boolean'];
  homeOrAway: Scalars['String'];
  id: Scalars['Int'];
  injuryStatus?: Maybe<Scalars['String']>;
  isOver: Scalars['Boolean'];
  lastName: Scalars['String'];
  opponentName: Scalars['String'];
  passes?: Maybe<Scalars['Float']>;
  photoUrl: Scalars['String'];
  position: Scalars['String'];
  positionCategory: Scalars['String'];
  projectedAssists?: Maybe<Scalars['Float']>;
  projectedGoals?: Maybe<Scalars['Float']>;
  projectedPasses?: Maybe<Scalars['Float']>;
  projectedSaves?: Maybe<Scalars['Float']>;
  projectedShots?: Maybe<Scalars['Float']>;
  projectedShotsOnGoal?: Maybe<Scalars['Float']>;
  projectedTackles?: Maybe<Scalars['Float']>;
  remoteId: Scalars['Int'];
  saves?: Maybe<Scalars['Float']>;
  shortName: Scalars['String'];
  shots?: Maybe<Scalars['Float']>;
  shotsOnGoal?: Maybe<Scalars['Float']>;
  tackles?: Maybe<Scalars['Float']>;
  teamName: Scalars['String'];
};

export type SoccerTouchdownContestCreateInput = {
  metric: Scalars['String'];
  player1BonusPoints: Scalars['Float'];
  player1GameId: Scalars['Int'];
  player1Id: Scalars['Int'];
  player1ProjFantasyPoints: Scalars['Float'];
  player2BonusPoints: Scalars['Float'];
  player2GameId: Scalars['Int'];
  player2Id: Scalars['Int'];
  player2ProjFantasyPoints: Scalars['Float'];
  question: Scalars['String'];
  startTime: Scalars['String'];
  statName: Scalars['String'];
};

export type SoccerTouchdownContestDto = {
  id: Scalars['Int'];
  metric: Scalars['String'];
  player1: SoccerTouchdownPlayerDto;
  player1BonusPoints?: Maybe<Scalars['Float']>;
  player2: SoccerTouchdownPlayerDto;
  player2BonusPoints?: Maybe<Scalars['Float']>;
  status: Scalars['String'];
};

export type SoccerTouchdownF2PContestCreateInput = {
  metric: Scalars['String'];
  player1BonusPoints: Scalars['Float'];
  player1GameId: Scalars['Int'];
  player1Id: Scalars['Int'];
  player1ProjFantasyPoints: Scalars['Float'];
  player2BonusPoints: Scalars['Float'];
  player2GameId: Scalars['Int'];
  player2Id: Scalars['Int'];
  player2ProjFantasyPoints: Scalars['Float'];
  prizePoolIds: Array<Scalars['Int']>;
  question: Scalars['String'];
  startTime: Scalars['String'];
  statName: Scalars['String'];
};

export type SoccerTouchdownPartnerContestCreateInput = {
  metric: Scalars['String'];
  player1BonusPoints: Scalars['Float'];
  player1GameId: Scalars['Int'];
  player1Id: Scalars['Int'];
  player1ProjFantasyPoints: Scalars['Float'];
  player2BonusPoints: Scalars['Float'];
  player2GameId: Scalars['Int'];
  player2Id: Scalars['Int'];
  player2ProjFantasyPoints: Scalars['Float'];
  prizePoolIds: Array<Scalars['Int']>;
  question: Scalars['String'];
  startTime: Scalars['String'];
  statName: Scalars['String'];
};

export type SoccerTouchdownPartnerContestDto = {
  id: Scalars['Int'];
  metric: Scalars['String'];
  player1: SoccerTouchdownPlayerDto;
  player1BonusPoints?: Maybe<Scalars['Float']>;
  player2: SoccerTouchdownPlayerDto;
  player2BonusPoints?: Maybe<Scalars['Float']>;
  prizePoolId: Scalars['Int'];
  status: Scalars['String'];
};

export type SoccerTouchdownPlayerDto = {
  assists?: Maybe<Scalars['Float']>;
  commonName: Scalars['String'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  gameId: Scalars['Int'];
  gameStartTime: Scalars['String'];
  goals?: Maybe<Scalars['Float']>;
  hasStarted: Scalars['Boolean'];
  homeOrAway: Scalars['String'];
  id: Scalars['Int'];
  injuryStatus?: Maybe<Scalars['String']>;
  isOver: Scalars['Boolean'];
  lastName: Scalars['String'];
  opponentName: Scalars['String'];
  passes?: Maybe<Scalars['Float']>;
  photoUrl: Scalars['String'];
  position: Scalars['String'];
  positionCategory: Scalars['String'];
  projectedAssists?: Maybe<Scalars['Float']>;
  projectedGoals?: Maybe<Scalars['Float']>;
  projectedPasses?: Maybe<Scalars['Float']>;
  projectedSaves?: Maybe<Scalars['Float']>;
  projectedShots?: Maybe<Scalars['Float']>;
  projectedShotsOnGoal?: Maybe<Scalars['Float']>;
  projectedTackles?: Maybe<Scalars['Float']>;
  remoteId: Scalars['Int'];
  saves?: Maybe<Scalars['Float']>;
  shortName: Scalars['String'];
  shots?: Maybe<Scalars['Float']>;
  shotsOnGoal?: Maybe<Scalars['Float']>;
  tackles?: Maybe<Scalars['Float']>;
  teamName: Scalars['String'];
};

export type SportContestCount = {
  NBA: Scalars['Int'];
  NFL: Scalars['Int'];
};

export type SportDto = {
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  logoUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  questions?: Maybe<Array<SportQuestionsDto>>;
  winCriteria?: Maybe<WinCriteria>;
};

export type SportOrderInput = {
  orderIndex: Scalars['Int'];
  sportId: Scalars['Int'];
};

export type SportOutputDto = {
  automated?: Maybe<Scalars['Boolean']>;
  commonSportId?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  orderIndex?: Maybe<Scalars['Int']>;
  partnerId?: Maybe<Scalars['Int']>;
  questions?: Maybe<Array<QuestionDto>>;
  winCriteria?: Maybe<Win_Criteria>;
};

export type SportQuestionDto = {
  id?: InputMaybe<Scalars['Int']>;
  metric: Scalars['String'];
  question: Scalars['String'];
};

export type SportQuestionInput = {
  id?: InputMaybe<Scalars['Int']>;
  metric: Scalars['String'];
  question: Scalars['String'];
};

export type SportQuestionsDto = {
  id: Scalars['Int'];
  metric: Scalars['String'];
  question: Scalars['String'];
};

export type SuggestionCreateInput = {
  playerOneId: Scalars['Int'];
  playerTwoId: Scalars['Int'];
};

export type SuggestionDeleteInput = {
  matchupId: Scalars['Int'];
  status: Scalars['String'];
};

export type SuggestionDto = {
  id: Scalars['Int'];
  isFeaturedContest?: Maybe<Scalars['Boolean']>;
  playerOne: PlayerDto;
  playerTwo: PlayerDto;
  sportType: Scalars['String'];
};

export type SuggestionOrderInput = {
  matchupId: Scalars['Int'];
  orderIndex: Scalars['Int'];
};

export enum SupportedSports {
  CommonHub = 'COMMON_HUB',
  CommonNba = 'COMMON_NBA',
  CommonSoccer = 'COMMON_SOCCER',
  Hub = 'HUB',
  Nba = 'NBA',
  Nfl = 'NFL',
  Soccer = 'SOCCER'
}

export type TicketDto = {
  createdAt: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['Int'];
  message: Scalars['String'];
  updatedAt: Scalars['String'];
  userId: Scalars['Int'];
};

export type TopupDto = {
  amount: Scalars['Int'];
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  notes: Scalars['String'];
  transactionUrl: Scalars['String'];
  user: UserDto;
};

export type TouchdownAnalyticsDto = {
  activeTouchdowns: Array<PartnerTouchdownInfoResponseDto>;
  totalActiveTouchdowns: Scalars['Int'];
  totalContestVig: Scalars['String'];
  totalReceivedFees: Scalars['String'];
  totalTouchdownCount: Scalars['Int'];
  totalUsersCount: Scalars['Int'];
  touchdownCount: Array<TouchdownCount>;
  upcomingTouchdownCount: Scalars['Int'];
};

export type TouchdownContestCreateInput = {
  player1BonusPoints: Scalars['Float'];
  player1GameId: Scalars['Int'];
  player1Id: Scalars['Int'];
  player1ProjFantasyPoints: Scalars['Float'];
  player2BonusPoints: Scalars['Float'];
  player2GameId: Scalars['Int'];
  player2Id: Scalars['Int'];
  player2ProjFantasyPoints: Scalars['Float'];
  startTime: Scalars['String'];
};

export type TouchdownContestDeleteInput = {
  id: Scalars['Int'];
  status: Scalars['String'];
};

export type TouchdownCount = {
  count: Scalars['Int'];
  month: Scalars['String'];
};

export type TouchdownDto = {
  endDate?: Maybe<Scalars['String']>;
  mathConstant: MathConstantField;
  prizePools?: Maybe<Array<PrizePoolDto>>;
  startDate?: Maybe<Scalars['String']>;
  touchDownType: Scalars['String'];
  touchdownId: Scalars['Int'];
  weeklyPrizeDistribution: WeeklyPrizeDto;
};

export type TouchdownF2PContestCreateInput = {
  player1BonusPoints: Scalars['Float'];
  player1GameId: Scalars['Int'];
  player1Id: Scalars['Int'];
  player1ProjFantasyPoints: Scalars['Float'];
  player2BonusPoints: Scalars['Float'];
  player2GameId: Scalars['Int'];
  player2Id: Scalars['Int'];
  player2ProjFantasyPoints: Scalars['Float'];
  prizePoolIds: Array<Scalars['Int']>;
  startTime: Scalars['String'];
};

export enum TouchdownMathConstant {
  Gpp = 'GPP',
  PrizePool = 'PRIZE_POOL',
  ToppropProfit = 'TOPPROP_PROFIT',
  WeeklyReserve = 'WEEKLY_RESERVE'
}

export type TouchdownPartnerContestCreateInput = {
  player1BonusPoints: Scalars['Float'];
  player1GameId: Scalars['Int'];
  player1Id: Scalars['Int'];
  player1ProjFantasyPoints: Scalars['Float'];
  player2BonusPoints: Scalars['Float'];
  player2GameId: Scalars['Int'];
  player2Id: Scalars['Int'];
  player2ProjFantasyPoints: Scalars['Float'];
  prizePoolIds: Array<Scalars['Int']>;
  startTime: Scalars['String'];
};

export enum TouchdownType {
  SevenForSeven = 'SEVEN_FOR_SEVEN'
}

export type Tournament = {
  createdAt: Scalars['String'];
  currentRound: Scalars['Int'];
  endDate: Scalars['String'];
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  purse: Scalars['Float'];
  remoteId: Scalars['String'];
  startDate: Scalars['String'];
  updatedAt: Scalars['String'];
  year: Scalars['Int'];
};

export type TournamentPlayer = {
  createdAt: Scalars['String'];
  firstName: Scalars['String'];
  headshotUrlLarge: Scalars['String'];
  headshotUrlSmall: Scalars['String'];
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  lastName: Scalars['String'];
  playerId: Scalars['String'];
  projectedScore: Scalars['Float'];
  projectedStrokes: Scalars['Float'];
  round1ProjectedStrokes: Scalars['Float'];
  round2ProjectedStrokes: Scalars['Float'];
  round3ProjectedStrokes: Scalars['Float'];
  round4ProjectedStrokes: Scalars['Float'];
  teeTimeRound1?: Maybe<Scalars['String']>;
  teeTimeRound2?: Maybe<Scalars['String']>;
  teeTimeRound3?: Maybe<Scalars['String']>;
  teeTimeRound4?: Maybe<Scalars['String']>;
  tournament: Tournament;
  updatedAt: Scalars['String'];
};

export type TournamentStateInputType = {
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
};

export type TournamentsStateInputType = {
  tournaments: Array<TournamentStateInputType>;
};

export type UpdateCommonHubContestInput = {
  id: Scalars['Int'];
  player1FullName: Scalars['String'];
  player1Game: Scalars['String'];
  player1PhotoUrl?: InputMaybe<Scalars['String']>;
  player2FullName: Scalars['String'];
  player2Game: Scalars['String'];
  player2PhotoUrl?: InputMaybe<Scalars['String']>;
};

export type UpdateCommonSportDto = {
  id: Scalars['Int'];
  logoUrl: Scalars['String'];
  name: Scalars['String'];
  questions: Array<CommonSportQuestionDto>;
  winCriteria: Win_Criteria;
};

export type UpdateF2PHubContestInputDto = {
  id: Scalars['Int'];
  player1FullName: Scalars['String'];
  player1Game: Scalars['String'];
  player1PhotoUrl?: InputMaybe<Scalars['String']>;
  player2FullName: Scalars['String'];
  player2Game: Scalars['String'];
  player2PhotoUrl?: InputMaybe<Scalars['String']>;
  prizePoolIds: Array<Scalars['Int']>;
};

export type UpdateF2PNbaContestInput = {
  id: Scalars['Int'];
  prizePoolIds: Array<Scalars['Int']>;
};

export type UpdateF2PPartnerInput = {
  contact: Scalars['String'];
  creatorId: Scalars['Int'];
  description: Scalars['String'];
  email: Scalars['String'];
  enableUserAuth: Scalars['Boolean'];
  id: Scalars['Int'];
  identifier: Scalars['String'];
  logo?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  primaryColor?: InputMaybe<Scalars['String']>;
  secondaryColor?: InputMaybe<Scalars['String']>;
};

export type UpdateF2PSoccerContestInput = {
  id: Scalars['Int'];
  prizePoolIds: Array<Scalars['Int']>;
};

export type UpdateFeaturedContestInputType = {
  newMatchupId: Scalars['Int'];
  oldMatchupId?: InputMaybe<Scalars['Int']>;
};

export type UpdateHubContestInput = {
  id: Scalars['Int'];
  player1FullName: Scalars['String'];
  player1Game: Scalars['String'];
  player1PhotoUrl?: InputMaybe<Scalars['String']>;
  player2FullName: Scalars['String'];
  player2Game: Scalars['String'];
  player2PhotoUrl?: InputMaybe<Scalars['String']>;
};

export type UpdateHubContestInputDto = {
  id: Scalars['Int'];
  player1FullName: Scalars['String'];
  player1Game: Scalars['String'];
  player1PhotoUrl?: InputMaybe<Scalars['String']>;
  player2FullName: Scalars['String'];
  player2Game: Scalars['String'];
  player2PhotoUrl?: InputMaybe<Scalars['String']>;
  prizePoolIds: Array<Scalars['Int']>;
};

export type UpdatePartnerAdminInputDto = {
  email: Scalars['String'];
  fullName: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
};

export type UpdatePartnerDto = {
  active: Scalars['Boolean'];
  admins: Array<PartnerAdminInputDto>;
  contact: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['Int'];
  identifier: Scalars['String'];
  logo?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  primaryColor?: InputMaybe<Scalars['String']>;
  secondaryColor?: InputMaybe<Scalars['String']>;
};

export type UpdatePartnerNbaContestInput = {
  id: Scalars['Int'];
  prizePoolIds: Array<Scalars['Int']>;
};

export type UpdatePartnerOutputDto = {
  active?: Maybe<Scalars['Boolean']>;
  admins: Array<PartnerAdminDto>;
  apiKeys: Array<ApiKeyDto>;
  contact?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  identifier: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
};

export type UpdatePrizePoolDto = {
  actualContestVig?: Maybe<Scalars['Float']>;
  actualDailyGPP?: Maybe<Scalars['Float']>;
  actualJackpot?: Maybe<Scalars['Float']>;
  actualPrizePool?: Maybe<Scalars['Float']>;
  actualWeeklyReserve?: Maybe<Scalars['Float']>;
  endDate: Scalars['String'];
  entryFees: Scalars['Int'];
  isRollsOver: Scalars['Boolean'];
  lockTime?: Maybe<Scalars['String']>;
  maxEntriesPerUser: Scalars['Int'];
  predetermineContestVig: Scalars['Float'];
  predetermineDailyGPP: Scalars['Float'];
  predetermineJackpot: Scalars['Float'];
  predeterminePrizePool: Scalars['Float'];
  predetermineWeeklyReserve: Scalars['Float'];
  prizeDate: Scalars['String'];
  prizePoolId: Scalars['Int'];
  startDate: Scalars['String'];
  status: PrizePoolStatus;
  supportedSports: Array<SupportedSports>;
  totalEntrants: Scalars['Int'];
  touchdownId: Scalars['Int'];
};

export type UpdatePrizePoolInput = {
  allowedSports: Array<Scalars['Int']>;
  contestVig: Scalars['Float'];
  endDateTime: Scalars['String'];
  gppAmount: Scalars['Float'];
  id: Scalars['Int'];
  jackpotAmount: Scalars['Float'];
  lockTime: Scalars['String'];
  maxEntriesPerUser: Scalars['Int'];
  startDateTime: Scalars['String'];
  totalEntrants: Scalars['Int'];
  totalPrize: Scalars['Float'];
};

export type UpdateSoccerContestInput = {
  id: Scalars['Int'];
  prizePoolIds: Array<Scalars['Int']>;
};

export type UpdateSportDto = {
  id: Scalars['Int'];
  logoUrl: Scalars['String'];
  name: Scalars['String'];
  partnerId: Scalars['Int'];
  questions: Array<SportQuestionDto>;
  winCriteria: Win_Criteria;
};

export type UpdateSportInput = {
  id: Scalars['Int'];
  logoUrl: Scalars['String'];
  name: Scalars['String'];
  questions: Array<SportQuestionInput>;
  winCriteria: WinCriteria;
};

export type UpdateSuggestionInputType = {
  oppenentId: Scalars['String'];
  suggestionId: Scalars['Int'];
};

export type UpdateTouchdownDto = {
  endDate: Scalars['String'];
  mathConstant: MathConstantField;
  startDate: Scalars['String'];
  touchDownType: Scalars['String'];
  touchdownId: Scalars['Int'];
};

export type UpdateTouchdownInput = {
  endDate: Scalars['String'];
  mathConstant: MathConstantFieldInput;
  startDate: Scalars['String'];
  touchDownType: Scalars['String'];
};

export type UpdateWeeklyConstantDto = {
  message: Scalars['String'];
};

export type User = {
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  permissions: Array<PermissionId>;
  roles: Array<Role>;
  updatedAt: Scalars['DateTime'];
};

export type UserDto = {
  createdAt: Scalars['String'];
  dateOfBirth: Scalars['String'];
  email: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['Int'];
  isDeactivated: Scalars['Boolean'];
  isDeactivatedPermanently: Scalars['Boolean'];
  phone: Scalars['String'];
  promo: Scalars['String'];
  signUpState: Scalars['String'];
  tsevoCustomerID: Scalars['String'];
  walletAmount: Scalars['Float'];
};

export type VoidContestInputType = {
  contestId: Scalars['Int'];
};

export enum Win_Criteria {
  Higher = 'HIGHER',
  Lower = 'LOWER'
}

export type WalletDepositDto = {
  amount: Scalars['Int'];
  email: Scalars['String'];
};

export type WalletResponseDto = {
  message: Scalars['String'];
};

export type WeeklyConstantDto = {
  id: Scalars['Int'];
  value: Scalars['Float'];
};

export type WeeklyConstantFields = {
  id: Scalars['Int'];
  value: Scalars['Float'];
};

export type WeeklyConstantInput = {
  data: Array<WeeklyConstantFields>;
};

export type WeeklyHistoryDto = {
  entryFee: Scalars['Float'];
  moneyAddedByTopprop: Scalars['Float'];
  profit?: Maybe<Scalars['Float']>;
  sportContestCount: SportContestCount;
  totalContestVig: Scalars['Float'];
  totalEntryFees: Scalars['Float'];
  totalRolloverVig: Scalars['Float'];
  totalSubscribers: Scalars['Float'];
  totalUserEntry: Scalars['Float'];
  totalWeeklyReserve: Scalars['Float'];
  touchdownId: Scalars['Int'];
  weekDate: Scalars['String'];
  weeklyPrize: Scalars['Float'];
};

export type WeeklyPrizeDto = {
  prizes: Array<WeeklyConstantDto>;
};

export type WeeklyPrizeInput = {
  prizes: Array<PrizeInput>;
};

export type WeeklySubscriberDto = {
  items?: Maybe<Array<WeeklySubscriberFieldsDto>>;
};

export type WeeklySubscriberFieldsDto = {
  email: Scalars['String'];
  entryFee: Scalars['String'];
  fullName: Scalars['String'];
  subScribedDays: Scalars['Int'];
  subScriberDate: Scalars['String'];
  userName: Scalars['String'];
};

export enum WinCriteria {
  Higher = 'HIGHER',
  Lower = 'LOWER'
}

export type WithdrawRequestActionDto = {
  status: WithdrawRequestStatus;
  withdrawRequestId: Scalars['Int'];
};

export type WithdrawRequestDto = {
  amount: Scalars['Int'];
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  user: UserDto;
};

/** Withdraw Request Status */
export enum WithdrawRequestStatus {
  Approved = 'APPROVED',
  Denied = 'DENIED',
  Pending = 'PENDING'
}

export type BroadcastNotificationMutationVariables = Exact<{
  title: Scalars['String'];
  body: Scalars['String'];
}>;


export type BroadcastNotificationMutation = { broadcastNotification: string };

export type CancelContestMutationVariables = Exact<{
  contestId: Scalars['Int'];
}>;


export type CancelContestMutation = { cancelContest: { id: number } };

export type CountCommonContestsQueryVariables = Exact<{
  date: Scalars['String'];
  type: Scalars['String'];
}>;


export type CountCommonContestsQuery = { countCommonContests: Array<{ id: number, count: number, name: string }> };

export type CountF2PContestsQueryVariables = Exact<{
  partnerId: Scalars['Int'];
  type: Scalars['String'];
  date: Scalars['String'];
  prizePoolId: Scalars['Int'];
}>;


export type CountF2PContestsQuery = { countF2PContests: Array<{ id: number, count: number, name: string }> };

export type CountF2PHubContestsQueryVariables = Exact<{
  date: Scalars['String'];
  prizePoolId: Scalars['Int'];
}>;


export type CountF2PHubContestsQuery = { countF2PHubContests: Array<{ id: number, count: number, name: string }> };

export type CountHubContestsQueryVariables = Exact<{
  date: Scalars['String'];
}>;


export type CountHubContestsQuery = { countHubContests: Array<{ sportId: number, contestCount: string }> };

export type CountPartnerContestsQueryVariables = Exact<{
  partnerId: Scalars['Int'];
  type: Scalars['String'];
  date: Scalars['String'];
  prizePoolId: Scalars['Int'];
}>;


export type CountPartnerContestsQuery = { countPartnerContests: Array<{ id: number, count: number, name: string }> };

export type CountPartnerHubContestsQueryVariables = Exact<{
  date: Scalars['String'];
  prizePoolId: Scalars['Int'];
}>;


export type CountPartnerHubContestsQuery = { countPartnerHubContests: Array<{ id: number, count: number, name: string }> };

export type CreateCommonHubContestMutationVariables = Exact<{
  data: CreateCommonHubContestInput;
}>;


export type CreateCommonHubContestMutation = { createCommonHubContest: { id: number, player1Id: string, player1FullName: string, player1PhotoUrl?: string | null, player1BonusPoints: number, player1Game: string, player1GameStartTime: string, player1FantasyPoints?: number | null, player2Id: string, player2FullName: string, player2PhotoUrl?: string | null, player2BonusPoints: number, player2Game: string, player2GameStartTime: string, player2FantasyPoints?: number | null, status: string, winnerPlayerId?: string | null, winnerLabel?: string | null, contestDate: string } };

export type CreateCommonNbaTouchdownContestMutationVariables = Exact<{
  data: NbaTouchdownCommonContestCreateInput;
}>;


export type CreateCommonNbaTouchdownContestMutation = { createCommonNbaTouchdownContest: Array<{ id: number, player1BonusPoints?: number | null, player2BonusPoints?: number | null, status: string, player1: { id: number, remoteId: number, firstName: string, lastName: string, fullName: string, shortName: string, injuryStatus?: string | null, photoUrl: string, photoUrlHiRes: string, available: boolean, position: string, teamName: string, opponentName: string, homeOrAway: string, hasStarted: boolean, isOver: boolean, fantasyPoints: number, fantasyPointsHalfPpr: number, fantasyPointsFullPpr: number, projectedFantasyPoints?: number | null, projectedFantasyPointsHalfPpr: number, teamId: number, yahooPlayerId: number, espnPlayerId: number, gameId: number, gameStartTime: string }, player2: { id: number, remoteId: number, firstName: string, lastName: string, fullName: string, shortName: string, injuryStatus?: string | null, photoUrl: string, photoUrlHiRes: string, available: boolean, position: string, teamName: string, opponentName: string, homeOrAway: string, hasStarted: boolean, isOver: boolean, fantasyPoints: number, fantasyPointsHalfPpr: number, fantasyPointsFullPpr: number, projectedFantasyPoints?: number | null, projectedFantasyPointsHalfPpr: number, teamId: number, yahooPlayerId: number, espnPlayerId: number, gameId: number, gameStartTime: string } }> };

export type CreateCommonSoccerTouchdownContestMutationVariables = Exact<{
  data: SoccerCommonTouchdownContestCreateInput;
}>;


export type CreateCommonSoccerTouchdownContestMutation = { createCommonSoccerTouchdownContest: Array<{ id: number, player1BonusPoints?: number | null, player2BonusPoints?: number | null, metric: string, status: string, player1: { id: number, remoteId: number, firstName: string, lastName: string, fullName: string, shortName: string, commonName: string, injuryStatus?: string | null, photoUrl: string, position: string, positionCategory: string, teamName: string, opponentName: string, homeOrAway: string, hasStarted: boolean, isOver: boolean, shots?: number | null, saves?: number | null, passes?: number | null, shotsOnGoal?: number | null, goals?: number | null, assists?: number | null, tackles?: number | null, gameId: number, gameStartTime: string, projectedShots?: number | null, projectedSaves?: number | null, projectedPasses?: number | null, projectedShotsOnGoal?: number | null, projectedGoals?: number | null, projectedAssists?: number | null, projectedTackles?: number | null }, player2: { id: number, remoteId: number, firstName: string, lastName: string, fullName: string, shortName: string, commonName: string, injuryStatus?: string | null, photoUrl: string, position: string, positionCategory: string, teamName: string, opponentName: string, homeOrAway: string, hasStarted: boolean, isOver: boolean, shots?: number | null, saves?: number | null, passes?: number | null, shotsOnGoal?: number | null, goals?: number | null, assists?: number | null, tackles?: number | null, gameId: number, gameStartTime: string, projectedShots?: number | null, projectedSaves?: number | null, projectedPasses?: number | null, projectedShotsOnGoal?: number | null, projectedGoals?: number | null, projectedAssists?: number | null, projectedTackles?: number | null } }> };

export type CreateCommonSportMutationVariables = Exact<{
  data: CreateCommonSportDto;
}>;


export type CreateCommonSportMutation = { createCommonSport: { id?: number | null, name?: string | null, key?: string | null, logoUrl?: string | null, winCriteria?: Win_Criteria | null, questions?: Array<{ id: number, question: string, metric: string }> | null } };

export type CreateCouponMutationVariables = Exact<{
  data: CreateCouponDto;
}>;


export type CreateCouponMutation = { createCoupon: { id: number, status: number, code: string, value: number, createdAt: string } };

export type CreateEditRoleMutationVariables = Exact<{
  data: CreateEditRoleDto;
}>;


export type CreateEditRoleMutation = { createEditRole: { id: number } };

export type CreateEditUserMutationVariables = Exact<{
  data: CreateEditUserDto;
}>;


export type CreateEditUserMutation = { createEditUser: { id: number } };

export type CreateF2PHubContestMutationVariables = Exact<{
  data: F2PCreateHubContestInputDto;
}>;


export type CreateF2PHubContestMutation = { createF2PHubContest: Array<{ id: number }> };

export type CreateF2PNbaTouchdownContestMutationVariables = Exact<{
  data: TouchdownF2PContestCreateInput;
}>;


export type CreateF2PNbaTouchdownContestMutation = { createF2PNbaTouchdownContest: Array<{ id: number }> };

export type CreateF2PPartnerMutationVariables = Exact<{
  data: CreateF2PPartnerInput;
}>;


export type CreateF2PPartnerMutation = { createF2PPartner: { id: number, name: string, email: string, contact: string, identifier: string, logo?: string | null, description: string, primaryColor?: string | null, secondaryColor?: string | null, active: boolean, createdBy: number, enableUserAuth: boolean } };

export type CreateF2PPrizePoolMutationVariables = Exact<{
  data: CreateF2PPrizePoolInputDto;
}>;


export type CreateF2PPrizePoolMutation = { createF2PPrizePool: { startDateTime: string, endDateTime: string, allowedSports: Array<number>, lockTime: string, maxEntriesPerUser: number, totalEntrants: number, id: number, status: Prize_Pool_Status, createdAt: string, updatedAt: string } };

export type CreateF2PSoccerTouchdownContestMutationVariables = Exact<{
  data: SoccerTouchdownF2PContestCreateInput;
}>;


export type CreateF2PSoccerTouchdownContestMutation = { createF2PSoccerTouchdownContest: Array<{ id: number }> };

export type CreateF2PSportMutationVariables = Exact<{
  data: F2PCreateSportDto;
}>;


export type CreateF2PSportMutation = { createF2PSport: { id?: number | null, name?: string | null, logoUrl?: string | null, winCriteria?: F2P_Win_Criteria | null, enabled?: boolean | null, orderIndex?: number | null, partnerId?: number | null } };

export type CreateF2PTouchdownMutationVariables = Exact<{
  data: F2PCreateTouchdownInputDto;
}>;


export type CreateF2PTouchdownMutation = { createF2PTouchdown: { id: number, name: string, startDateTime: string, endDateTime: string, allowedPick: number, createdAt: string, updatedAt: string, deletedAt?: string | null } };

export type CreateHubContestMutationVariables = Exact<{
  data: CreateHubContestInput;
}>;


export type CreateHubContestMutation = { createHubContest: { id: number, player1FullName: string, player1PhotoUrl: string, player1BonusPoints: number, player1Game: string, player1GameStartTime: string, player2FullName: string, player2PhotoUrl: string, player2BonusPoints: number, player2Game: string, player2GameStartTime: string, published: boolean, player1FantasyPoints?: number | null, player2FantasyPoints?: number | null, winnerLabel?: string | null, status?: string | null, createdAt?: string | null, updatedAt?: string | null, questionId: { id: number, question: string, metric: string }, sportId: { id?: number | null, name?: string | null, logoUrl?: string | null, winCriteria?: WinCriteria | null, enabled?: boolean | null } } };

export type CreateNbaTouchdownContestMutationVariables = Exact<{
  startTime: Scalars['String'];
  player1Id: Scalars['Int'];
  player2Id: Scalars['Int'];
  player1GameId: Scalars['Int'];
  player2GameId: Scalars['Int'];
  player1BonusPoints: Scalars['Float'];
  player2BonusPoints: Scalars['Float'];
  player1ProjFantasyPoints: Scalars['Float'];
  player2ProjFantasyPoints: Scalars['Float'];
}>;


export type CreateNbaTouchdownContestMutation = { createNbaTouchdownContest: Array<{ id: number }> };

export type CreateNflMatchupMutationVariables = Exact<{
  playerOneId: Scalars['Int'];
  playerTwoId: Scalars['Int'];
}>;


export type CreateNflMatchupMutation = { createNflMatchup: Array<{ id: number }> };

export type CreateNflTouchdownContestMutationVariables = Exact<{
  startTime: Scalars['String'];
  player1Id: Scalars['Int'];
  player2Id: Scalars['Int'];
  player1GameId: Scalars['Int'];
  player2GameId: Scalars['Int'];
  player1BonusPoints: Scalars['Float'];
  player2BonusPoints: Scalars['Float'];
  player1ProjFantasyPoints: Scalars['Float'];
  player2ProjFantasyPoints: Scalars['Float'];
}>;


export type CreateNflTouchdownContestMutation = { createNflTouchdownContest: Array<{ id: number }> };

export type CreateOrUpdateMathConstantMutationVariables = Exact<{
  mathConstant: Array<MathConstantInput> | MathConstantInput;
}>;


export type CreateOrUpdateMathConstantMutation = { createOrUpdateMathConstant: Array<{ id: number, name: TouchdownMathConstant, value: number }> };

export type CreateOrUpdateNbaContestTitleMutationVariables = Exact<{
  question: Scalars['String'];
}>;


export type CreateOrUpdateNbaContestTitleMutation = { createOrUpdateNbaContestTitle: { statName: string, question: string } };

export type CreateOrUpdateNflContestTitleMutationVariables = Exact<{
  question: Scalars['String'];
}>;


export type CreateOrUpdateNflContestTitleMutation = { createOrUpdateNflContestTitle: { statName: string, question: string } };

export type CreatePartnerMutationVariables = Exact<{
  data: CreatePartnerDto;
}>;


export type CreatePartnerMutation = { createPartner: { id: number, name: string, email: string, contact: string, active: boolean, iframeKey: string, secretKey: string, userCount?: number | null, totalTouchdownCount?: number | null, logo?: string | null, primaryColor?: string | null, secondaryColor?: string | null, identifier: string } };

export type CreatePartnerAdminMutationVariables = Exact<{
  data: CreatePartnerAdminInputDto;
}>;


export type CreatePartnerAdminMutation = { createPartnerAdmin: { id: number, fullName: string, email: string, phone?: string | null, active: boolean } };

export type CreatePartnerHubContestMutationVariables = Exact<{
  data: PartnerCreateHubContestInputDto;
}>;


export type CreatePartnerHubContestMutation = { createPartnerHubContest: Array<{ id: number }> };

export type CreatePartnerNbaTouchdownContestMutationVariables = Exact<{
  data: TouchdownPartnerContestCreateInput;
}>;


export type CreatePartnerNbaTouchdownContestMutation = { createPartnerNbaTouchdownContest: Array<{ id: number }> };

export type CreatePartnerPrizePoolMutationVariables = Exact<{
  data: CreatePrizePoolInputDto;
}>;


export type CreatePartnerPrizePoolMutation = { createPartnerPrizePool: { id: number } };

export type CreatePartnerSoccerTouchdownContestMutationVariables = Exact<{
  data: SoccerTouchdownPartnerContestCreateInput;
}>;


export type CreatePartnerSoccerTouchdownContestMutation = { createPartnerSoccerTouchdownContest: Array<{ id: number }> };

export type CreatePartnerSportMutationVariables = Exact<{
  data: CreateSportDto;
}>;


export type CreatePartnerSportMutation = { createPartnerSport: { id?: number | null } };

export type CreatePartnerTouchdownMutationVariables = Exact<{
  data: PartnerCreateTouchdownInputDto;
}>;


export type CreatePartnerTouchdownMutation = { createPartnerTouchdown: { id: number } };

export type UpdateWeeklyConstantMutationVariables = Exact<{
  data: WeeklyConstantInput;
}>;


export type UpdateWeeklyConstantMutation = { updateWeeklyConstant: { message: string } };

export type CreatePrizePoolMutationVariables = Exact<{
  data: CreatePrizePoolInput;
}>;


export type CreatePrizePoolMutation = { createPrizePool: { prizePoolId: number, entryFees: number, totalEntrants: number, supportedSports: Array<SupportedSports>, maxEntriesPerUser: number, predeterminePrizePool: number, predetermineDailyGPP: number, predetermineWeeklyReserve: number, predetermineJackpot: number, predetermineContestVig: number, actualPrizePool?: number | null, actualDailyGPP?: number | null, actualWeeklyReserve?: number | null, actualJackpot?: number | null, actualContestVig?: number | null, isRollsOver: boolean, status: PrizePoolStatus, startDate: string, endDate: string } };

export type CreateSoccerTouchdownContestMutationVariables = Exact<{
  question: Scalars['String'];
  metric: Scalars['String'];
  startTime: Scalars['String'];
  player1Id: Scalars['Int'];
  player2Id: Scalars['Int'];
  player1GameId: Scalars['Int'];
  player2GameId: Scalars['Int'];
  player1ProjFantasyPoints: Scalars['Float'];
  player2ProjFantasyPoints: Scalars['Float'];
  player1BonusPoints: Scalars['Float'];
  player2BonusPoints: Scalars['Float'];
  statName: Scalars['String'];
}>;


export type CreateSoccerTouchdownContestMutation = { createSoccerTouchdownContest: Array<{ id: number }> };

export type CreateSportMutationVariables = Exact<{
  data: CreateSportInput;
}>;


export type CreateSportMutation = { createSport: { id?: number | null, name?: string | null, logoUrl?: string | null, winCriteria?: WinCriteria | null, enabled?: boolean | null, questions?: Array<{ id: number, question: string, metric: string }> | null } };

export type CreateSuggestionMutationVariables = Exact<{
  mainPlayerId: Scalars['String'];
  tournamentId: Scalars['String'];
  roundNumber?: InputMaybe<Scalars['Int']>;
  contestType?: InputMaybe<Scalars['String']>;
  oppenentIds?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type CreateSuggestionMutation = { createSuggestion: { success: boolean } };

export type CreateTouchdownMutationVariables = Exact<{
  data: CreateTouchdownInput;
}>;


export type CreateTouchdownMutation = { createTouchdown: { touchdownId: number, startDate: string, endDate: string, touchDownType: string, mathConstant: { PRIZE_POOL: number, GPP: number, WEEKLY_RESERVE: number }, prizePool: Array<{ prizePoolId: number, entryFees: number, totalEntrants: number, supportedSports: Array<SupportedSports>, maxEntriesPerUser: number, predeterminePrizePool: number, predetermineDailyGPP: number, predetermineWeeklyReserve: number, predetermineJackpot: number, predetermineContestVig: number, actualPrizePool?: number | null, actualDailyGPP?: number | null, actualWeeklyReserve?: number | null, actualJackpot?: number | null, actualContestVig?: number | null, isRollsOver: boolean, status: PrizePoolStatus, startDate: string, endDate: string }> } };

export type DeleteCommonHubContestMutationVariables = Exact<{
  contestId: Scalars['Int'];
}>;


export type DeleteCommonHubContestMutation = { deleteCommonHubContest: { message: string } };

export type DeleteCommonNbaTouchdownContestMutationVariables = Exact<{
  data: CommonContestDeleteInput;
}>;


export type DeleteCommonNbaTouchdownContestMutation = { deleteCommonNbaTouchdownContest: number };

export type DeleteCommonSoccerTouchdownContestMutationVariables = Exact<{
  data: CommonContestDeleteInput;
}>;


export type DeleteCommonSoccerTouchdownContestMutation = { deleteCommonSoccerTouchdownContest: number };

export type DeleteCouponsMutationVariables = Exact<{
  data: DeleteCouponsDto;
}>;


export type DeleteCouponsMutation = { deleteCoupons: { affected: number } };

export type DeleteF2PHubContestMutationVariables = Exact<{
  contestId: Scalars['Int'];
}>;


export type DeleteF2PHubContestMutation = { deleteF2PHubContest: string };

export type DeleteF2PNbaTouchdownContestMutationVariables = Exact<{
  data: F2PTouchdownContestDeleteInput;
}>;


export type DeleteF2PNbaTouchdownContestMutation = { deleteF2PNbaTouchdownContest: number };

export type DeleteF2PSoccerTouchdownContestMutationVariables = Exact<{
  data: F2PTouchdownContestDeleteInput;
}>;


export type DeleteF2PSoccerTouchdownContestMutation = { deleteF2PSoccerTouchdownContest: number };

export type DeleteteFeaturedContestMutationVariables = Exact<{
  matchupId: Scalars['Int'];
}>;


export type DeleteteFeaturedContestMutation = { deleteFeaturedContest: string };

export type DeleteHubContestMutationVariables = Exact<{
  contestId: Scalars['Int'];
}>;


export type DeleteHubContestMutation = { deleteHubContest: string };

export type DeleteNbaTouchdownContestMutationVariables = Exact<{
  id: Scalars['Int'];
  status: Scalars['String'];
}>;


export type DeleteNbaTouchdownContestMutation = { deleteNbaTouchdownContest: { id: number } };

export type DeleteNflMatchupMutationVariables = Exact<{
  matchupId: Scalars['Int'];
  status: Scalars['String'];
}>;


export type DeleteNflMatchupMutation = { deleteNflMatchup: { id: number } };

export type DeleteNflTouchdownContestMutationVariables = Exact<{
  id: Scalars['Int'];
  status: Scalars['String'];
}>;


export type DeleteNflTouchdownContestMutation = { deleteNflTouchdownContest: { id: number } };

export type DeletePartnerHubContestMutationVariables = Exact<{
  contestId: Scalars['Int'];
}>;


export type DeletePartnerHubContestMutation = { deletePartnerHubContest: string };

export type DeletePartnerNbaTouchdownContestMutationVariables = Exact<{
  data: TouchdownContestDeleteInput;
}>;


export type DeletePartnerNbaTouchdownContestMutation = { deletePartnerNbaTouchdownContest: number };

export type DeletePartnerSoccerTouchdownContestMutationVariables = Exact<{
  data: TouchdownContestDeleteInput;
}>;


export type DeletePartnerSoccerTouchdownContestMutation = { deletePartnerSoccerTouchdownContest: number };

export type DeleteRoleMutationVariables = Exact<{
  roleId: Scalars['Int'];
}>;


export type DeleteRoleMutation = { deleteRole: { message: string, succcess: boolean } };

export type DeleteSoccerTouchdownContestMutationVariables = Exact<{
  id: Scalars['Int'];
  status: Scalars['String'];
}>;


export type DeleteSoccerTouchdownContestMutation = { deleteSoccerTouchdownContest: { id: number } };

export type DeleteSuggestionMutationVariables = Exact<{
  suggestionId: Scalars['Int'];
}>;


export type DeleteSuggestionMutation = { deleteSuggestion: { success: boolean } };

export type DeleteUserPermanentlyMutationVariables = Exact<{
  shouldDelete: Scalars['Boolean'];
  userId: Scalars['Int'];
}>;


export type DeleteUserPermanentlyMutation = { deleteUserPermanently: { message: string, succcess: boolean } };

export type EnableF2PSportMutationVariables = Exact<{
  data: F2PEnableSportInputDto;
}>;


export type EnableF2PSportMutation = { enableF2PSport: { message: string } };

export type EnablePartnerSportMutationVariables = Exact<{
  data: EnableSportInputDto;
}>;


export type EnablePartnerSportMutation = { enablePartnerSport: { message: string } };

export type EnabledSportMutationVariables = Exact<{
  data: EnabledSportInput;
}>;


export type EnabledSportMutation = { enabledSport: string };

export type FeatureFlagsQueryVariables = Exact<{ [key: string]: never; }>;


export type FeatureFlagsQuery = { featureFlags: { roundByRoundContestCreationEnabled: boolean, tournamentContestCreationEnabled: boolean } };

export type FetchContestsQueryVariables = Exact<{
  date: Scalars['String'];
  sportId: Scalars['Int'];
}>;


export type FetchContestsQuery = { fetchContests: Array<{ id: number, orderIndex?: number | null, player1FullName: string, player1PhotoUrl: string, player1BonusPoints: number, player2FullName: string, player2PhotoUrl: string, player2BonusPoints: number, matchDate: string, matchDateStartTime: string, sportName: string, type: string, metric: string }> };

export type FetchF2PContestsQueryVariables = Exact<{
  date: Scalars['String'];
  partnerId: Scalars['Int'];
  sportId?: InputMaybe<Scalars['Int']>;
  prizePoolId: Scalars['Int'];
  fetchFeatureContest: Scalars['Boolean'];
}>;


export type FetchF2PContestsQuery = { fetchF2PContests: Array<{ id: number, orderIndex?: number | null, player1FullName: string, player1PhotoUrl: string, player1BonusPoints: number, player2FullName: string, player2PhotoUrl: string, player2BonusPoints: number, matchDate: string, matchDateStartTime: string, sportName: string, type: string, metric: string, createdAt: string, prizePoolId: number }> };

export type FetchF2PHubContestsQueryVariables = Exact<{
  sportId: Scalars['Int'];
  date: Scalars['String'];
  prizePoolId: Scalars['Int'];
}>;


export type FetchF2PHubContestsQuery = { fetchF2PHubContests: Array<{ id: number, player1FullName: string, player1PhotoUrl: string, player1BonusPoints: number, player1Game: string, player1GameStartTime: string, player2FullName: string, player2PhotoUrl: string, player2BonusPoints: number, player2Game: string, player2GameStartTime: string, published: boolean, player1FantasyPoints?: number | null, player2FantasyPoints?: number | null, winnerLabel?: string | null, status?: string | null, createdAt?: string | null, updatedAt?: string | null, prizePoolId: number, sport: { id?: number | null, name?: string | null, logoUrl?: string | null, winCriteria?: F2P_Win_Criteria | null, enabled?: boolean | null, orderIndex?: number | null, partnerId?: number | null, questions?: Array<{ id: number, question: string, metric: string }> | null }, questionId: { id: number, question: string, metric: string }, availableInTouchdowns: Array<{ name: string, prizePoolId: number }> }> };

export type FetchF2PTouchdownQueryVariables = Exact<{
  data: F2PFetchTouchdownInputDto;
}>;


export type FetchF2PTouchdownQuery = { fetchF2PTouchdown: { page: number, limit: number, totalCount: number, data?: Array<{ id: number, name: string, startDateTime: string, endDateTime: string, allowedPick: number, createdAt: string, updatedAt: string, deletedAt?: string | null, prizePools: Array<{ startDateTime: string, endDateTime: string, allowedSports: Array<number>, lockTime: string, maxEntriesPerUser: number, totalEntrants: number, id: number, status: Prize_Pool_Status, createdAt: string, updatedAt: string }> }> | null } };

export type FetchF2PTouchdownByDateQueryVariables = Exact<{
  data: FetchF2PTouchdownByDateInputDto;
}>;


export type FetchF2PTouchdownByDateQuery = { fetchF2PTouchdownByDate: Array<{ prizePoolId?: number | null, touchdown: { touchdownId: number, touchdownName: string } }> };

export type FetchF2PTouchdownByIdQueryVariables = Exact<{
  touchdownId: Scalars['Float'];
}>;


export type FetchF2PTouchdownByIdQuery = { fetchF2PTouchdownById: { id: number, name: string, startDateTime: string, endDateTime: string, allowedPick: number, createdAt: string, updatedAt: string, deletedAt?: string | null, prizePools: Array<{ startDateTime: string, endDateTime: string, allowedSports: Array<number>, lockTime: string, maxEntriesPerUser: number, totalEntrants: number, id: number, status: Prize_Pool_Status, createdAt: string, updatedAt: string }> } };

export type FetchHubContestsQueryVariables = Exact<{
  date: Scalars['String'];
  sportId: Scalars['Int'];
}>;


export type FetchHubContestsQuery = { fetchHubContests: Array<{ id: number, player1FullName: string, player1PhotoUrl: string, player1BonusPoints: number, player1Game: string, player1GameStartTime: string, player2FullName: string, player2PhotoUrl: string, player2BonusPoints: number, player2Game: string, player2GameStartTime: string, published: boolean, player1FantasyPoints?: number | null, player2FantasyPoints?: number | null, winnerLabel?: string | null, status?: string | null, createdAt?: string | null, updatedAt?: string | null, questionId: { id: number, question: string, metric: string }, sportId: { id?: number | null, name?: string | null, logoUrl?: string | null, winCriteria?: WinCriteria | null, enabled?: boolean | null } }> };

export type FetchPartnerContestsQueryVariables = Exact<{
  date: Scalars['String'];
  partnerId: Scalars['Int'];
  sportId?: InputMaybe<Scalars['Int']>;
  prizePoolId: Scalars['Int'];
}>;


export type FetchPartnerContestsQuery = { fetchPartnerContests: Array<{ id: number, orderIndex?: number | null, player1FullName: string, player1PhotoUrl: string, player1BonusPoints: number, player2FullName: string, player2PhotoUrl: string, player2BonusPoints: number, matchDate: string, matchDateStartTime: string, sportName: string, type: string, metric: string, createdAt: string, commonContestId?: number | null }> };

export type FetchPartnerHubContestsQueryVariables = Exact<{
  sportId: Scalars['Int'];
  date: Scalars['String'];
  prizePoolId: Scalars['Int'];
}>;


export type FetchPartnerHubContestsQuery = { fetchPartnerHubContests: Array<{ id: number, player1FullName: string, player1PhotoUrl: string, player1BonusPoints: number, player1Game: string, player1GameStartTime: string, player2FullName: string, player2PhotoUrl: string, player2BonusPoints: number, player2Game: string, player2GameStartTime: string, published: boolean, player1FantasyPoints?: number | null, player2FantasyPoints?: number | null, winnerLabel?: string | null, status?: string | null, createdAt?: string | null, updatedAt?: string | null, questionId: { id: number, question: string, metric: string } }> };

export type FetchPartnerTouchdownQueryVariables = Exact<{
  data: PartnerFetchTouchdownInputDto;
}>;


export type FetchPartnerTouchdownQuery = { fetchPartnerTouchdown: { page: number, limit: number, totalCount: number, data?: Array<{ id: number, name: string, startDateTime: string, endDateTime: string, allowedPick: number, entryFees: number, createdAt: string, updatedAt: string, deletedAt?: string | null, prizePools: Array<{ startDateTime: string, endDateTime: string, allowedSports: Array<number>, lockTime: string, maxEntriesPerUser: number, totalEntrants: number, totalPrize: number, jackpotAmount: number, gppAmount: number, contestVig: number, id: number, status: PrizePoolStatus, isRollsOver: boolean, weeklyReserveAmount: number, predetermineJackpotAmount: number, rolloverAmount: number, rolloverJackpotAmount: number, rolloverVig: number, createdAt: string, updatedAt: string }> }> | null } };

export type FetchPartnerTouchdownByDateQueryVariables = Exact<{
  data: FetchPartnerTouchdownByDateInputDto;
}>;


export type FetchPartnerTouchdownByDateQuery = { fetchPartnerTouchdownByDate: Array<{ prizePoolId?: number | null, prizePoolStatus: string, touchdown: { touchdownId: number, touchdownName: string } }> };

export type FetchPartnerTouchdownByIdQueryVariables = Exact<{
  touchdownId: Scalars['Float'];
}>;


export type FetchPartnerTouchdownByIdQuery = { fetchPartnerTouchdownById: { id: number, name: string, startDateTime: string, endDateTime: string, allowedPick: number, entryFees: number, createdAt: string, updatedAt: string, deletedAt?: string | null, prizePools: Array<{ startDateTime: string, endDateTime: string, allowedSports: Array<number>, lockTime: string, maxEntriesPerUser: number, totalEntrants: number, totalPrize: number, jackpotAmount: number, gppAmount: number, contestVig: number, id: number, status: PrizePoolStatus, isRollsOver: boolean, weeklyReserveAmount: number, predetermineJackpotAmount: number, rolloverAmount: number, rolloverJackpotAmount: number, rolloverVig: number, createdAt: string, updatedAt: string }> } };

export type GetAllContestsQueryVariables = Exact<{
  data: ContestsReportInputType;
}>;


export type GetAllContestsQuery = { getAllContests: Array<{ id: number, createdAt: string, entryAmount: number, endTime?: string | null, winnerId?: number | null, contestType: string, creatorUser?: { id: number, fullName: string, email: string } | null, claimerUser?: { id: number, fullName: string, email: string } | null, creatorPlayer: { playerId: string, firstName: string, lastName: string }, claimerPlayer: { playerId: string, firstName: string, lastName: string } }> };

export type GetAllF2PPartnerQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllF2PPartnerQuery = { getAllF2PPartner: Array<{ id: number, name: string, email: string, contact: string, identifier: string, logo?: string | null, description: string, primaryColor?: string | null, secondaryColor?: string | null, active: boolean, createdBy: number, userCount: number, totalTouchdownCount: number, enableUserAuth: boolean }> };

export type GetAllNflPlayersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllNflPlayersQuery = { getAllNflPlayers: Array<{ id: number, remoteId: number, fullName: string }> };

export type GetAllPartnerQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllPartnerQuery = { getAllPartner: Array<{ id: number, name: string, email: string, contact: string, active: boolean, iframeKey: string, secretKey: string, userCount?: number | null, totalTouchdownCount?: number | null, logo?: string | null, primaryColor?: string | null, secondaryColor?: string | null, identifier: string, apiKeys: Array<{ id: number, apiKey: string, createdBy: number, active: boolean, expiredAt?: any | null, createdAt: string }>, admins: Array<{ id: number, fullName: string, email: string, phone?: string | null, active: boolean }> }> };

export type GetAllPermissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllPermissionsQuery = { getAllPermissions: Array<PermissionId> };

export type GetAuditsQueryVariables = Exact<{
  data: AuditActionInputType;
}>;


export type GetAuditsQuery = { getAudits: Array<{ id: number, createdAt: any, action: AuditActionFliterType, payload: any, user: { email: string } }> };

export type GetCommonHubContestQueryVariables = Exact<{
  date: Scalars['String'];
  sportId: Scalars['Int'];
}>;


export type GetCommonHubContestQuery = { getCommonHubContest: Array<{ id: number, player1Id: string, player1FullName: string, player1PhotoUrl?: string | null, player1BonusPoints: number, player1Game: string, player1GameStartTime: string, player1FantasyPoints?: number | null, player2Id: string, player2FullName: string, player2PhotoUrl?: string | null, player2BonusPoints: number, player2Game: string, player2GameStartTime: string, player2FantasyPoints?: number | null, status: string, winnerPlayerId?: string | null, winnerLabel?: string | null, contestDate: string, questionId?: { id: number, question: string, metric: string } | null }> };

export type GetCommonNbaTouchdownContestPlayersQueryVariables = Exact<{
  date: Scalars['String'];
}>;


export type GetCommonNbaTouchdownContestPlayersQuery = { getCommonNbaTouchdownContestPlayers: Array<{ id: number, remoteId: number, firstName: string, lastName: string, fullName: string, shortName: string, injuryStatus?: string | null, photoUrl: string, photoUrlHiRes: string, available: boolean, position: string, teamName: string, opponentName: string, homeOrAway: string, hasStarted: boolean, isOver: boolean, fantasyPoints: number, fantasyPointsHalfPpr: number, fantasyPointsFullPpr: number, projectedFantasyPoints?: number | null, projectedFantasyPointsHalfPpr: number, teamId: number, yahooPlayerId: number, espnPlayerId: number, gameId: number, gameStartTime: string }> };

export type GetCommonNbaTouchdownContestsQueryVariables = Exact<{
  date: Scalars['String'];
}>;


export type GetCommonNbaTouchdownContestsQuery = { getCommonNbaTouchdownContests: Array<{ id: number, player1BonusPoints?: number | null, player2BonusPoints?: number | null, status: string, player1: { id: number, remoteId: number, firstName: string, lastName: string, fullName: string, shortName: string, injuryStatus?: string | null, photoUrl: string, photoUrlHiRes: string, available: boolean, position: string, teamName: string, opponentName: string, homeOrAway: string, hasStarted: boolean, isOver: boolean, fantasyPoints: number, fantasyPointsHalfPpr: number, fantasyPointsFullPpr: number, projectedFantasyPoints?: number | null, projectedFantasyPointsHalfPpr: number, teamId: number, yahooPlayerId: number, espnPlayerId: number, gameId: number, gameStartTime: string }, player2: { id: number, remoteId: number, firstName: string, lastName: string, fullName: string, shortName: string, injuryStatus?: string | null, photoUrl: string, photoUrlHiRes: string, available: boolean, position: string, teamName: string, opponentName: string, homeOrAway: string, hasStarted: boolean, isOver: boolean, fantasyPoints: number, fantasyPointsHalfPpr: number, fantasyPointsFullPpr: number, projectedFantasyPoints?: number | null, projectedFantasyPointsHalfPpr: number, teamId: number, yahooPlayerId: number, espnPlayerId: number, gameId: number, gameStartTime: string } }> };

export type GetCommonSoccerTouchdownContestPlayersQueryVariables = Exact<{
  date: Scalars['String'];
}>;


export type GetCommonSoccerTouchdownContestPlayersQuery = { getCommonSoccerTouchdownContestPlayers: Array<{ id: number, remoteId: number, firstName: string, lastName: string, fullName: string, shortName: string, commonName: string, injuryStatus?: string | null, photoUrl: string, position: string, positionCategory: string, teamName: string, opponentName: string, homeOrAway: string, hasStarted: boolean, isOver: boolean, shots?: number | null, saves?: number | null, passes?: number | null, shotsOnGoal?: number | null, goals?: number | null, assists?: number | null, tackles?: number | null, gameId: number, gameStartTime: string, projectedShots?: number | null, projectedSaves?: number | null, projectedPasses?: number | null, projectedShotsOnGoal?: number | null, projectedGoals?: number | null, projectedAssists?: number | null, projectedTackles?: number | null }> };

export type GetCommonSoccerTouchdownContestsQueryVariables = Exact<{
  date: Scalars['String'];
}>;


export type GetCommonSoccerTouchdownContestsQuery = { getCommonSoccerTouchdownContests: Array<{ id: number, player1BonusPoints?: number | null, player2BonusPoints?: number | null, metric: string, status: string, player1: { id: number, remoteId: number, firstName: string, lastName: string, fullName: string, shortName: string, commonName: string, injuryStatus?: string | null, photoUrl: string, position: string, positionCategory: string, teamName: string, opponentName: string, homeOrAway: string, hasStarted: boolean, isOver: boolean, shots?: number | null, saves?: number | null, passes?: number | null, shotsOnGoal?: number | null, goals?: number | null, assists?: number | null, tackles?: number | null, gameId: number, gameStartTime: string, projectedShots?: number | null, projectedSaves?: number | null, projectedPasses?: number | null, projectedShotsOnGoal?: number | null, projectedGoals?: number | null, projectedAssists?: number | null, projectedTackles?: number | null }, player2: { id: number, remoteId: number, firstName: string, lastName: string, fullName: string, shortName: string, commonName: string, injuryStatus?: string | null, photoUrl: string, position: string, positionCategory: string, teamName: string, opponentName: string, homeOrAway: string, hasStarted: boolean, isOver: boolean, shots?: number | null, saves?: number | null, passes?: number | null, shotsOnGoal?: number | null, goals?: number | null, assists?: number | null, tackles?: number | null, gameId: number, gameStartTime: string, projectedShots?: number | null, projectedSaves?: number | null, projectedPasses?: number | null, projectedShotsOnGoal?: number | null, projectedGoals?: number | null, projectedAssists?: number | null, projectedTackles?: number | null } }> };

export type GetCommonSportQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCommonSportQuery = { getCommonSport: Array<{ id?: number | null, name?: string | null, key?: string | null, logoUrl?: string | null, winCriteria?: Win_Criteria | null, questions?: Array<{ id: number, question: string, metric: string }> | null }> };

export type GetCouponsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCouponsQuery = { getCoupons: Array<{ id: number, status: number, code: string, value: number, createdAt: string }> };

export type GetCurrentTournamentQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentTournamentQuery = { getCurrentTournament: { remoteId: string, name: string, startDate: string } };

export type GetCurrentTournamentPlayersMutationVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentTournamentPlayersMutation = { getCurrentTournamentPlayers: { success: boolean } };

export type GetDailyLeaderBoardQueryVariables = Exact<{
  prizePoolId: Scalars['Int'];
  searchStr: Scalars['String'];
}>;


export type GetDailyLeaderBoardQuery = { getDailyLeaderBoard: { page: number, limit: number, totalCount: number, data: Array<{ id: number, rank: number, leaderboardRank: string, winAmount: string, name: string, score: number, status: string, payout?: string | null, entryId: number, entryNumber: number }> } };

export type GetDailyReportQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDailyReportQuery = { getDailyReport: { amountAddedSystemCount: number, battlegroundContestCount: number, userCount: number } };

export type GetF2PActiveTouchdownsQueryVariables = Exact<{
  partnerId: Scalars['Int'];
}>;


export type GetF2PActiveTouchdownsQuery = { getF2PActiveTouchdowns: Array<{ id: number, name: string, startDateTime: string, endDateTime: string, allowedPick: number, prizePools: Array<{ startDateTime: string, endDateTime: string, allowedSports: Array<number>, lockTime: string, maxEntriesPerUser: number, totalEntrants: number, id: number, status: Prize_Pool_Status, createdAt: string, updatedAt: string, userEntryCount: number, nbaContestCount: number, nflContestCount: number, soccerContestCount: number, hubContestCount: number }> }> };

export type GetF2PDailyLeaderBoardQueryVariables = Exact<{
  prizePoolId: Scalars['Int'];
}>;


export type GetF2PDailyLeaderBoardQuery = { getF2PDailyLeaderBoard: { startDateTime: string, endDateTime: string, entries: Array<{ entryId: number, userId: number, name: string, score: number, rank: string, leaderBoardRank: string }> } };

export type GetF2PManualSportsQueryVariables = Exact<{
  partnerId: Scalars['Int'];
}>;


export type GetF2PManualSportsQuery = { getF2PManualSports: Array<{ id?: number | null, name?: string | null, logoUrl?: string | null, winCriteria?: F2P_Win_Criteria | null, enabled?: boolean | null, orderIndex?: number | null, partnerId?: number | null }> };

export type GetF2PNbaTouchdownContestPlayersQueryVariables = Exact<{
  date: Scalars['String'];
}>;


export type GetF2PNbaTouchdownContestPlayersQuery = { getF2PNbaTouchdownContestPlayers: Array<{ id: number, remoteId: number, fullName: string, projectedFantasyPoints?: number | null, gameId: number, gameStartTime: string }> };

export type GetF2PNbaTouchdownContestsQueryVariables = Exact<{
  date: Scalars['String'];
  prizePoolId: Scalars['Int'];
}>;


export type GetF2PNbaTouchdownContestsQuery = { getF2PNbaTouchdownContests: Array<{ id: number, player1BonusPoints?: number | null, player2BonusPoints?: number | null, status: string, player1: { id: number, remoteId: number, firstName: string, lastName: string, photoUrl: string, injuryStatus?: string | null }, player2: { id: number, remoteId: number, firstName: string, lastName: string, photoUrl: string, injuryStatus?: string | null }, availableInTouchdowns: Array<{ name: string, prizePoolId: number }> }> };

export type GetF2PPartnerByIdQueryVariables = Exact<{
  partnerId: Scalars['Int'];
}>;


export type GetF2PPartnerByIdQuery = { getF2PPartnerById: { id: number, name: string, email: string, contact: string, identifier: string, logo?: string | null, description: string, primaryColor?: string | null, secondaryColor?: string | null, active: boolean, createdBy: number } };

export type GetF2PPartnerUsersQueryVariables = Exact<{
  partnerId: Scalars['Int'];
}>;


export type GetF2PPartnerUsersQuery = { getF2PPartnerUsers: Array<{ id: number, firstName: string, lastName: string, email: string, gender: Gender, userName: string }> };

export type GetF2PSoccerTouchdownContestPlayersQueryVariables = Exact<{
  date: Scalars['String'];
}>;


export type GetF2PSoccerTouchdownContestPlayersQuery = { getF2PSoccerTouchdownContestPlayers: Array<{ id: number, remoteId: number, fullName: string, gameId: number, gameStartTime: string, projectedShots?: number | null, projectedSaves?: number | null, projectedPasses?: number | null, projectedShotsOnGoal?: number | null, projectedGoals?: number | null, projectedAssists?: number | null, projectedTackles?: number | null }> };

export type GetF2PSoccerTouchdownContestsQueryVariables = Exact<{
  date: Scalars['String'];
  prizePoolId: Scalars['Int'];
}>;


export type GetF2PSoccerTouchdownContestsQuery = { getF2PSoccerTouchdownContests: Array<{ id: number, player1BonusPoints?: number | null, player2BonusPoints?: number | null, metric: string, status: string, player1: { id: number, remoteId: number, firstName: string, lastName: string, photoUrl: string, injuryStatus?: string | null }, player2: { id: number, remoteId: number, firstName: string, lastName: string, photoUrl: string, injuryStatus?: string | null } }> };

export type GetF2PSportByNameQueryVariables = Exact<{
  name: Scalars['String'];
  partnerId: Scalars['Int'];
}>;


export type GetF2PSportByNameQuery = { getF2PSportByName: { id?: number | null, name?: string | null, logoUrl?: string | null, winCriteria?: F2P_Win_Criteria | null, enabled?: boolean | null, orderIndex?: number | null, partnerId?: number | null } };

export type GetF2PSportsQueryVariables = Exact<{
  type: Scalars['String'];
  partnerId: Scalars['Int'];
}>;


export type GetF2PSportsQuery = { getF2PSports: Array<{ id?: number | null, key?: string | null, name?: string | null, logoUrl?: string | null, enabled?: boolean | null, winCriteria?: F2P_Win_Criteria | null, orderIndex?: number | null, questions?: Array<{ id: number, question: string, metric: string }> | null }> };

export type GetF2PTouchdownAnalyticsQueryVariables = Exact<{
  partnerId: Scalars['Int'];
}>;


export type GetF2PTouchdownAnalyticsQuery = { getF2PTouchdownAnalytics: { totalTouchdowns: number, totalActiveTouchdowns: number, totalUpcomingTouchdowns: number, totalPartners: number, totalUsers: number, touchdownsPerMonth: Array<{ month: string, count: number }> } };

export type GetFeaturedContestsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFeaturedContestsQuery = { getFeaturedContests: Array<{ id: number, sportType: string, isFeaturedContest?: boolean | null, playerOne: { fullName: string, firstName: string, lastName: string, photoUrl: string, photoUrlHiRes: string }, playerTwo: { fullName: string, firstName: string, lastName: string, photoUrl: string, photoUrlHiRes: string } }> };

export type GetMathConstantQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMathConstantQuery = { getMathConstant: Array<{ id: number, name: TouchdownMathConstant, value: number }> };

export type GetNbaTouchdownContestPlayersQueryVariables = Exact<{
  date: Scalars['String'];
}>;


export type GetNbaTouchdownContestPlayersQuery = { getNbaTouchdownContestPlayers: Array<{ id: number, remoteId: number, fullName: string, gameId: number, gameStartTime: string, projectedFantasyPoints?: number | null }> };

export type GetNbaTouchdownContestsQueryVariables = Exact<{
  date: Scalars['String'];
}>;


export type GetNbaTouchdownContestsQuery = { getNbaTouchdownContests: Array<{ id: number, player1BonusPoints?: number | null, player2BonusPoints?: number | null, status: string, player1: { id: number, remoteId: number, firstName: string, lastName: string, photoUrl: string, injuryStatus?: string | null }, player2: { id: number, remoteId: number, firstName: string, lastName: string, photoUrl: string, injuryStatus?: string | null } }> };

export type GetNflContestTitleQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNflContestTitleQuery = { getNflContestTitle: { statName: string, question: string } };

export type GetNflContestsReportQueryVariables = Exact<{
  data: GetContestsReportInput;
}>;


export type GetNflContestsReportQuery = { getContestsReport?: Array<{ id: number, createdAt: string, entryAmount: number, winnerLabel?: string | null, status: string, ended: boolean, endedAt?: string | null, creatorPlayer: { fullName: string }, claimerPlayer: { fullName: string }, creatorUser: { fullName: string, email: string }, claimerUser?: { fullName: string, email: string } | null }> | null };

export type GetNflMatchupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNflMatchupsQuery = { getNflMatchups: Array<{ id: number, orderIndex: number, status: string, playerOne: { id: number, remoteId: number, firstName: string, lastName: string, photoUrl: string, photoUrlHiRes: string }, playerTwo: { id: number, remoteId: number, firstName: string, lastName: string, photoUrl: string, photoUrlHiRes: string } }> };

export type GetNflTouchdownContestPlayersQueryVariables = Exact<{
  date: Scalars['String'];
}>;


export type GetNflTouchdownContestPlayersQuery = { getNflTouchdownContestPlayers: Array<{ id: number, remoteId: number, fullName: string, gameId: number, gameStartTime: string, projectedFantasyPoints?: number | null }> };

export type GetNflTouchdownContestsQueryVariables = Exact<{
  date: Scalars['String'];
}>;


export type GetNflTouchdownContestsQuery = { getNflTouchdownContests: Array<{ id: number, player1BonusPoints?: number | null, player2BonusPoints?: number | null, status: string, player1: { id: number, remoteId: number, firstName: string, lastName: string, photoUrl: string, photoUrlHiRes: string, injuryStatus?: string | null }, player2: { id: number, remoteId: number, firstName: string, lastName: string, photoUrl: string, photoUrlHiRes: string, injuryStatus?: string | null } }> };

export type GetPartnerActiveTouchdownsQueryVariables = Exact<{
  partnerId: Scalars['Int'];
}>;


export type GetPartnerActiveTouchdownsQuery = { getPartnerActiveTouchdowns: Array<{ id: number, name: string, startDateTime: string, endDateTime: string, allowedPick: number, entryFees: number, prizePools: Array<{ startDateTime: string, endDateTime: string, allowedSports: Array<number>, lockTime: string, maxEntriesPerUser: number, totalEntrants: number, totalPrize: number, jackpotAmount: number, gppAmount: number, contestVig: number, id: number, status: PrizePoolStatus, isRollsOver: boolean, weeklyReserveAmount: number, predetermineJackpotAmount: number, rolloverAmount: number, rolloverJackpotAmount: number, rolloverVig: number, createdAt: string, updatedAt: string, userEntryCount: number, jackpotWinners: number, gppWinners: number, nbaContestCount: number, nflContestCount: number, soccerContestCount: number, hubContestCount: number }> }> };

export type GetPartnerAdminQueryVariables = Exact<{
  partnerId: Scalars['Int'];
}>;


export type GetPartnerAdminQuery = { getPartnerAdmin: Array<{ id: number, fullName: string, email: string, phone?: string | null, active: boolean }> };

export type GetPartnerByIdQueryVariables = Exact<{
  partnerId: Scalars['Int'];
}>;


export type GetPartnerByIdQuery = { getPartnerById: { id: number, name: string, email: string, contact: string, active: boolean } };

export type GetPartnerCommonContestsQueryVariables = Exact<{
  sportId: Scalars['Int'];
  date: Scalars['String'];
  prizePoolId: Scalars['Int'];
}>;


export type GetPartnerCommonContestsQuery = { getPartnerCommonContests: { publishedContestsId: Array<string>, commonContests: Array<{ id: number, player1Name: string, player2Name: string, player1Id: string, player2Id: string, source: Contest_Source, player1BonusPoints: number, player2BonusPoints: number, metric: string, question: string, player1PhotoUrl: string, player2PhotoUrl: string, status: Contest_Statuses }> } };

export type GetPartnerDailyLeaderBoardQueryVariables = Exact<{
  prizePoolId: Scalars['Int'];
}>;


export type GetPartnerDailyLeaderBoardQuery = { getPartnerDailyLeaderBoard: { startDateTime: string, endDateTime: string, entries: Array<{ entryId: number, userId: number, name: string, score: number, rank: string, winAmount: number, leaderBoardRank: string }> } };

export type GetPartnerNbaTouchdownContestPlayersQueryVariables = Exact<{
  date: Scalars['String'];
}>;


export type GetPartnerNbaTouchdownContestPlayersQuery = { getPartnerNbaTouchdownContestPlayers: Array<{ id: number, remoteId: number, fullName: string, projectedFantasyPoints?: number | null, gameId: number, gameStartTime: string }> };

export type GetPartnerNbaTouchdownContestsQueryVariables = Exact<{
  date: Scalars['String'];
  prizePoolId: Scalars['Int'];
}>;


export type GetPartnerNbaTouchdownContestsQuery = { getPartnerNbaTouchdownContests: Array<{ id: number, player1BonusPoints?: number | null, player2BonusPoints?: number | null, status: string, player1: { id: number, remoteId: number, firstName: string, lastName: string, photoUrl: string, injuryStatus?: string | null }, player2: { id: number, remoteId: number, firstName: string, lastName: string, photoUrl: string, injuryStatus?: string | null } }> };

export type GetPartnerPrizeConstantQueryVariables = Exact<{
  partnerId: Scalars['Int'];
}>;


export type GetPartnerPrizeConstantQuery = { getPartnerPrizeConstant: { prizeConfiguration: { GPP: number, PRIZE_POOL: number, WEEKLY_RESERVE: number } } };

export type GetPartnerSoccerTouchdownContestPlayersQueryVariables = Exact<{
  date: Scalars['String'];
}>;


export type GetPartnerSoccerTouchdownContestPlayersQuery = { getPartnerSoccerTouchdownContestPlayers: Array<{ id: number, remoteId: number, fullName: string, gameId: number, gameStartTime: string, projectedShots?: number | null, projectedSaves?: number | null, projectedPasses?: number | null, projectedShotsOnGoal?: number | null, projectedGoals?: number | null, projectedAssists?: number | null, projectedTackles?: number | null }> };

export type GetPartnerSoccerTouchdownContestsQueryVariables = Exact<{
  date: Scalars['String'];
  prizePoolId: Scalars['Int'];
}>;


export type GetPartnerSoccerTouchdownContestsQuery = { getPartnerSoccerTouchdownContests: Array<{ id: number, player1BonusPoints?: number | null, player2BonusPoints?: number | null, metric: string, status: string, player1: { id: number, remoteId: number, firstName: string, lastName: string, photoUrl: string, injuryStatus?: string | null }, player2: { id: number, remoteId: number, firstName: string, lastName: string, photoUrl: string, injuryStatus?: string | null } }> };

export type GetPartnerSportsQueryVariables = Exact<{
  partnerId: Scalars['Int'];
  type: Scalars['String'];
}>;


export type GetPartnerSportsQuery = { getPartnerSports: Array<{ id?: number | null, name?: string | null, logoUrl?: string | null, winCriteria?: Win_Criteria | null, enabled?: boolean | null, orderIndex?: number | null, partnerId?: number | null, key?: string | null, automated?: boolean | null, commonSportId?: number | null, questions?: Array<{ id: number, question: string, metric: string }> | null }> };

export type GetPartnerTouchdownAnalyticsQueryVariables = Exact<{
  partnerId: Scalars['Int'];
}>;


export type GetPartnerTouchdownAnalyticsQuery = { getPartnerTouchdownAnalytics: { totalUsersCount: number, totalTouchdownCount: number, upcomingTouchdownCount: number, totalContestVig: string, totalReceivedFees: string, totalActiveTouchdowns: number, touchdownCount: Array<{ month: string, count: number }>, activeTouchdowns: Array<{ id: number, name: string, startDateTime: string, endDateTime: string, allowedPick: number, entryFees: number, prizePools: Array<{ startDateTime: string, endDateTime: string, allowedSports: Array<number>, lockTime: string, maxEntriesPerUser: number, totalEntrants: number, totalPrize: number, jackpotAmount: number, gppAmount: number, contestVig: number, id: number, status: PrizePoolStatus, isRollsOver: boolean, weeklyReserveAmount: number, predetermineJackpotAmount: number, rolloverAmount: number, rolloverJackpotAmount: number, rolloverVig: number, createdAt: string, updatedAt: string, userEntryCount: number, jackpotWinners: number, gppWinners: number, nbaContestCount: number, nflContestCount: number, soccerContestCount: number, hubContestCount: number }> }> } };

export type GetPlayersForSuggestionQueryVariables = Exact<{
  contestType?: InputMaybe<Scalars['String']>;
  roundNumber?: InputMaybe<Scalars['Int']>;
  tournamentId: Scalars['String'];
}>;


export type GetPlayersForSuggestionQuery = { getPlayersForSuggestion: Array<{ id: number, playerId: string, lastName: string, firstName: string, headshotUrlLarge: string, headshotUrlSmall: string }> };

export type GetRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRolesQuery = { getRoles: Array<{ id: number, createdAt: any, name: string, permissions: Array<PermissionId> }> };

export type GetSoccerTouchdownContestPlayersQueryVariables = Exact<{
  date: Scalars['String'];
}>;


export type GetSoccerTouchdownContestPlayersQuery = { getSoccerTouchdownContestPlayers: Array<{ id: number, remoteId: number, fullName: string, gameId: number, gameStartTime: string, projectedShots?: number | null, projectedSaves?: number | null, projectedPasses?: number | null, projectedShotsOnGoal?: number | null, projectedGoals?: number | null, projectedAssists?: number | null, projectedTackles?: number | null }> };

export type GetSoccerTouchdownContestsQueryVariables = Exact<{
  date: Scalars['String'];
}>;


export type GetSoccerTouchdownContestsQuery = { getSoccerTouchdownContests: Array<{ id: number, player1BonusPoints?: number | null, player2BonusPoints?: number | null, metric: string, status: string, player1: { id: number, remoteId: number, firstName: string, lastName: string, photoUrl: string, injuryStatus?: string | null }, player2: { id: number, remoteId: number, firstName: string, lastName: string, photoUrl: string, injuryStatus?: string | null } }> };

export type GetSportQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type GetSportQuery = { getSport: { id?: number | null, name?: string | null, logoUrl?: string | null, winCriteria?: WinCriteria | null, enabled?: boolean | null, questions?: Array<{ id: number, question: string, metric: string }> | null } };

export type GetSportsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSportsQuery = { getSports: Array<{ id?: number | null, name?: string | null, logoUrl?: string | null, winCriteria?: WinCriteria | null, enabled?: boolean | null, questions?: Array<{ id: number, question: string, metric: string }> | null }> };

export type GetSportsLogoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSportsLogoQuery = { getSportsLogo: Array<string> };

export type GetSuggestionQueryVariables = Exact<{
  sportType: Scalars['String'];
}>;


export type GetSuggestionQuery = { getSuggestions: Array<{ id: number, isFeaturedContest?: boolean | null, playerOne: { fullName: string, firstName: string, lastName: string, photoUrl: string, photoUrlHiRes: string }, playerTwo: { fullName: string, firstName: string, lastName: string, photoUrl: string, photoUrlHiRes: string } }> };

export type GetAllSupportTicketsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllSupportTicketsQuery = { getAllSupportTickets: Array<{ id: number, userId: number, email: string, message: string, createdAt: string }> };

export type GetTeeTimeByRoundMutationVariables = Exact<{
  year: Scalars['Int'];
  tournamentId?: InputMaybe<Scalars['String']>;
  roundNumber: Scalars['Int'];
}>;


export type GetTeeTimeByRoundMutation = { getTeeTimeByRound: { success: boolean } };

export type GetTeeTimeForCurrentTournamentMutationVariables = Exact<{ [key: string]: never; }>;


export type GetTeeTimeForCurrentTournamentMutation = { getTeeTimeForCurrentTournament: { success: boolean } };

export type GetTopupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTopupsQuery = { getTopups: Array<{ id: number, amount: number, createdAt: string, notes: string, user: { id: number, fullName: string, email: string } }> };

export type GetTouchdownByDateQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type GetTouchdownByDateQuery = { getTouchdownByDate: Array<{ touchdownId: number, startDate?: string | null, endDate?: string | null, touchDownType: string, mathConstant: { PRIZE_POOL: number, GPP: number, WEEKLY_RESERVE: number }, prizePools?: Array<{ prizePoolId: number, startDate: string, endDate: string, supportedSports: Array<SupportedSports>, entryFees: number, lockTime?: string | null, totalEntrants: number, maxEntriesPerUser: number, isRollsOver: boolean, predeterminePrizePool: number, predetermineDailyGPP: number, predetermineWeeklyReserve: number, predetermineJackpot: number, predetermineContestVig: number, predetermineRolloverVig: number, status: PrizePoolStatus, actualPrizePool?: number | null, actualDailyGPP?: number | null, actualWeeklyReserve?: number | null, actualJackpot?: number | null, actualContestVig?: number | null, actualRolloverVig?: number | null, userEntryCount: number, jackpotWinnersCount: number, gppWinnersCount: number, nbaContestsCount: number, mlbContestsCount: number, pgaContestsCount: number, nflContestsCount: number, soccerContestsCount: number, profit?: number | null, addedByTopProp: number }> | null, weeklyPrizeDistribution: { prizes: Array<{ id: number, value: number }> } }> };

export type GetTournamentSuggestionsQueryVariables = Exact<{
  contestType?: InputMaybe<Scalars['String']>;
  roundNumber?: InputMaybe<Scalars['Int']>;
  tournamentId?: InputMaybe<Scalars['String']>;
}>;


export type GetTournamentSuggestionsQuery = { getTournamentSuggestions: Array<{ id: number, roundNumber?: number | null, tournament: { remoteId: string, name: string, startDate: string }, mainPlayer: { playerId: string, firstName: string, lastName: string, headshotUrlSmall: string }, suggestedPlayer?: { playerId: string, firstName: string, lastName: string, headshotUrlSmall: string } | null }> };

export type GetUserSignUpsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserSignUpsQuery = { getUserSignUps: Array<{ id: number, fullName: string, email: string, signUpState: string, promo: string, dateOfBirth: string, createdAt: string, phone: string, walletAmount: number, isDeactivated: boolean, isDeactivatedPermanently: boolean, tsevoCustomerID: string }> };

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { getUsers: Array<{ id: number, createdAt: any, email: string, permissions: Array<PermissionId>, isActive: boolean, roles: Array<{ id: number, name: string }> }> };

export type GetWeeklyConstantQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWeeklyConstantQuery = { getWeeklyConstant: Array<{ id: number, value: number }> };

export type GetWeeklyHistoryByDateRangeQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type GetWeeklyHistoryByDateRangeQuery = { getWeeklyHistoryByDateRange: Array<{ touchdownId: number, weekDate: string, entryFee: number, totalUserEntry: number, totalEntryFees: number, totalContestVig: number, totalRolloverVig: number, totalWeeklyReserve: number, weeklyPrize: number, profit?: number | null, totalSubscribers: number, moneyAddedByTopprop: number, sportContestCount: { NBA: number, NFL: number } }> };

export type GetWeeklyLeaderBoardQueryVariables = Exact<{
  touchdownId: Scalars['Int'];
  searchStr: Scalars['String'];
}>;


export type GetWeeklyLeaderBoardQuery = { getWeeklyLeaderBoard: { page: number, limit: number, totalCount: number, data: Array<{ id: number, rank: number, leaderboardRank: string, winAmount: string, name: string, score: number, status: string, payout?: string | null }> } };

export type GetWeeklySubscribersQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  searchStr?: InputMaybe<Scalars['String']>;
}>;


export type GetWeeklySubscribersQuery = { getWeeklySubscribers: { items?: Array<{ email: string, userName: string, fullName: string, subScriberDate: string, subScribedDays: number, entryFee: string }> | null } };

export type GetWithdrawRequestsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWithdrawRequestsQuery = { getWithdrawRequests: Array<{ id: number, amount: number, createdAt: string, user: { email: string, fullName: string } }> };

export type GradeCommonHubContestMutationVariables = Exact<{
  data: GradeCommonHubContestInput;
}>;


export type GradeCommonHubContestMutation = { gradeCommonHubContest: { id: number, player1Id: string, player1FullName: string, player1PhotoUrl?: string | null, player1BonusPoints: number, player1Game: string, player1GameStartTime: string, player1FantasyPoints?: number | null, player2Id: string, player2FullName: string, player2PhotoUrl?: string | null, player2BonusPoints: number, player2Game: string, player2GameStartTime: string, player2FantasyPoints?: number | null, status: string, winnerPlayerId?: string | null, winnerLabel?: string | null, contestDate: string } };

export type GradeF2PHubContestMutationVariables = Exact<{
  data: GradeF2PHubContestInputDto;
}>;


export type GradeF2PHubContestMutation = { gradeF2PHubContest: Array<{ id: number }> };

export type GradeHubContestMutationVariables = Exact<{
  data: GradeHubContestInput;
}>;


export type GradeHubContestMutation = { gradeHubContest: { id: number, player1FullName: string, player1PhotoUrl: string, player1BonusPoints: number, player1Game: string, player1GameStartTime: string, player2FullName: string, player2PhotoUrl: string, player2BonusPoints: number, player2Game: string, player2GameStartTime: string, published: boolean, player1FantasyPoints?: number | null, player2FantasyPoints?: number | null, winnerLabel?: string | null, status?: string | null, createdAt?: string | null, updatedAt?: string | null } };

export type GradePartnerHubContestMutationVariables = Exact<{
  data: GradeHubContestInputDto;
}>;


export type GradePartnerHubContestMutation = { gradePartnerHubContest: Array<{ id: number }> };

export type GradeTournamentMutationVariables = Exact<{
  year?: InputMaybe<Scalars['Int']>;
  tournamentId?: InputMaybe<Scalars['String']>;
}>;


export type GradeTournamentMutation = { gradeTournament: { success: boolean } };

export type ImportTournamentsMutationVariables = Exact<{
  data: Array<FjTournamentInputType> | FjTournamentInputType;
}>;


export type ImportTournamentsMutation = { importTournaments: { success: boolean, message?: string | null } };

export type LivePrizePoolMutationVariables = Exact<{ [key: string]: never; }>;


export type LivePrizePoolMutation = { livePrizePool: string };

export type OrderContestsMutationVariables = Exact<{
  data: Array<ContestOrderInput> | ContestOrderInput;
}>;


export type OrderContestsMutation = { orderContests: Array<number> };

export type OrderF2PContestsMutationVariables = Exact<{
  data: Array<ContestOrderF2PInput> | ContestOrderF2PInput;
  prizePoolId: Scalars['Int'];
  orderFeatureContest: Scalars['Boolean'];
}>;


export type OrderF2PContestsMutation = { orderF2PContests: { message: string } };

export type OrderF2PSportMutationVariables = Exact<{
  data: F2POrderSportsDto;
}>;


export type OrderF2PSportMutation = { orderF2PSport: Array<{ id?: number | null, name?: string | null, logoUrl?: string | null, winCriteria?: F2P_Win_Criteria | null, enabled?: boolean | null, orderIndex?: number | null, partnerId?: number | null }> };

export type OrderNflMatchupsMutationVariables = Exact<{
  data: Array<SuggestionOrderInput> | SuggestionOrderInput;
}>;


export type OrderNflMatchupsMutation = { orderMatchups: Array<{ id: number }> };

export type OrderPartnerContestsMutationVariables = Exact<{
  data: Array<ContestOrderPartnerInput> | ContestOrderPartnerInput;
  prizePoolId: Scalars['Int'];
}>;


export type OrderPartnerContestsMutation = { orderPartnerContests: { message: string } };

export type OrderPartnerSportMutationVariables = Exact<{
  data: OrderSportsDto;
}>;


export type OrderPartnerSportMutation = { orderPartnerSport: Array<{ id?: number | null, name?: string | null, logoUrl?: string | null, winCriteria?: Win_Criteria | null, enabled?: boolean | null, orderIndex?: number | null, partnerId?: number | null }> };

export type OrderSportsMutationVariables = Exact<{
  data: Array<SportOrderInput> | SportOrderInput;
}>;


export type OrderSportsMutation = { orderSports: Array<{ id?: number | null }> };

export type PublishF2PHubContestMutationVariables = Exact<{
  contestId: Scalars['Int'];
  published: Scalars['Boolean'];
}>;


export type PublishF2PHubContestMutation = { publishF2PHubContest: { id: number } };

export type PublishF2PPrizePoolMutationVariables = Exact<{
  prizePoolId: Scalars['Int'];
}>;


export type PublishF2PPrizePoolMutation = { publishF2PPrizePool: string };

export type PublishHubContestMutationVariables = Exact<{
  contestId: Scalars['Int'];
  published: Scalars['Boolean'];
}>;


export type PublishHubContestMutation = { publishHubContest: { id: number, player1FullName: string, player1PhotoUrl: string, player1BonusPoints: number, player1Game: string, player1GameStartTime: string, player2FullName: string, player2PhotoUrl: string, player2BonusPoints: number, player2Game: string, player2GameStartTime: string, published: boolean, player1FantasyPoints?: number | null, player2FantasyPoints?: number | null, winnerLabel?: string | null, status?: string | null, createdAt?: string | null, updatedAt?: string | null } };

export type PublishPartnerCommonContestMutationVariables = Exact<{
  data: PublishCommonContestInput;
}>;


export type PublishPartnerCommonContestMutation = { publishPartnerCommonContest: { message: string } };

export type PublishPartnerHubContestMutationVariables = Exact<{
  contestId: Scalars['Int'];
  published: Scalars['Boolean'];
}>;


export type PublishPartnerHubContestMutation = { publishPartnerHubContest: { id: number } };

export type PublishPartnerPrizePoolMutationVariables = Exact<{
  prizePoolId: Scalars['Int'];
}>;


export type PublishPartnerPrizePoolMutation = { publishPartnerPrizePool: string };

export type RegeneratePartnerKeyMutationVariables = Exact<{
  data: RegeneratePartnerKeyDto;
}>;


export type RegeneratePartnerKeyMutation = { regeneratePartnerKey: { id: number, name: string, email: string, contact: string, active: boolean } };

export type SendContestGradedEmailMutationVariables = Exact<{
  contestIds: Scalars['String'];
}>;


export type SendContestGradedEmailMutation = { sendContestGradedEmail: { success: boolean } };

export type SyncTournamentPlayersMutationVariables = Exact<{ [key: string]: never; }>;


export type SyncTournamentPlayersMutation = { syncTournamentPlayers: { success: boolean } };

export type SyncTournamentsMutationVariables = Exact<{ [key: string]: never; }>;


export type SyncTournamentsMutation = { syncTournaments: { success: boolean } };

export type TournamentsQueryVariables = Exact<{ [key: string]: never; }>;


export type TournamentsQuery = { tournaments: Array<{ id: number, isActive: boolean, remoteId: string, year: number, name: string, purse: number, startDate: string, endDate: string }> };

export type UpdateAdminActiveStatusMutationVariables = Exact<{
  data: PartnerAdminActiveInputDto;
}>;


export type UpdateAdminActiveStatusMutation = { updateAdminActiveStatus: { id: number, fullName: string, email: string, phone?: string | null, active: boolean } };

export type UpdateCommonHubContestMutationVariables = Exact<{
  data: UpdateCommonHubContestInput;
}>;


export type UpdateCommonHubContestMutation = { updateCommonHubContest: { id: number, player1Id: string, player1FullName: string, player1PhotoUrl?: string | null, player1BonusPoints: number, player1Game: string, player1GameStartTime: string, player1FantasyPoints?: number | null, player2Id: string, player2FullName: string, player2PhotoUrl?: string | null, player2BonusPoints: number, player2Game: string, player2GameStartTime: string, player2FantasyPoints?: number | null, status: string, winnerPlayerId?: string | null, winnerLabel?: string | null, contestDate: string } };

export type UpdateCommonSportMutationVariables = Exact<{
  data: UpdateCommonSportDto;
}>;


export type UpdateCommonSportMutation = { updateCommonSport: { id?: number | null, name?: string | null, key?: string | null, logoUrl?: string | null, winCriteria?: Win_Criteria | null, questions?: Array<{ id: number, question: string, metric: string }> | null } };

export type UpdateF2PHubContestMutationVariables = Exact<{
  data: UpdateF2PHubContestInputDto;
}>;


export type UpdateF2PHubContestMutation = { updateF2PHubContest: Array<{ id: number }> };

export type UpdateF2PNbaContestMutationVariables = Exact<{
  data: UpdateF2PNbaContestInput;
}>;


export type UpdateF2PNbaContestMutation = { updateF2PNbaContest: Array<{ id: number }> };

export type UpdateF2PPartnerMutationVariables = Exact<{
  data: UpdateF2PPartnerInput;
}>;


export type UpdateF2PPartnerMutation = { updateF2PPartner: { id: number, name: string, email: string, contact: string, identifier: string, logo?: string | null, description: string, primaryColor?: string | null, secondaryColor?: string | null, active: boolean, createdBy: number, enableUserAuth: boolean } };

export type UpdateF2PPartnerActiveStatusMutationVariables = Exact<{
  data: F2PPartnerActiveInputDto;
}>;


export type UpdateF2PPartnerActiveStatusMutation = { updateF2PPartnerActiveStatus: { id: number, name: string, email: string, contact: string, identifier: string, logo?: string | null, description: string, primaryColor?: string | null, secondaryColor?: string | null, active: boolean, createdBy: number } };

export type UpdateF2PSoccerTouchdownContestMutationVariables = Exact<{
  data: UpdateF2PSoccerContestInput;
}>;


export type UpdateF2PSoccerTouchdownContestMutation = { updateF2PSoccerTouchdownContest: Array<{ id: number }> };

export type UpdateF2PSportMutationVariables = Exact<{
  data: F2PUpdateSportDto;
}>;


export type UpdateF2PSportMutation = { updateF2PSport: { id?: number | null, name?: string | null, logoUrl?: string | null, winCriteria?: F2P_Win_Criteria | null, enabled?: boolean | null, orderIndex?: number | null, partnerId?: number | null } };

export type UpdateF2PTouchdownMutationVariables = Exact<{
  data: F2PUpdateTouchdownInputDto;
}>;


export type UpdateF2PTouchdownMutation = { updateF2PTouchdown: { id: number, name: string, startDateTime: string, endDateTime: string, allowedPick: number, createdAt: string, updatedAt: string, deletedAt?: string | null, prizePools: Array<{ startDateTime: string, endDateTime: string, allowedSports: Array<number>, lockTime: string, maxEntriesPerUser: number, totalEntrants: number, id: number, status: Prize_Pool_Status, createdAt: string, updatedAt: string }> } };

export type UpdateFeatureFlagsMutationVariables = Exact<{
  data: FeatureFlagInputType;
}>;


export type UpdateFeatureFlagsMutation = { updateFeatureFlags: { roundByRoundContestCreationEnabled: boolean, tournamentContestCreationEnabled: boolean } };

export type UpdateFeaturedContestMutationVariables = Exact<{
  oldMatchupId?: InputMaybe<Scalars['Int']>;
  newMatchupId: Scalars['Int'];
}>;


export type UpdateFeaturedContestMutation = { updateFeaturedContest: string };

export type UpdateHubContestMutationVariables = Exact<{
  data: UpdateHubContestInput;
}>;


export type UpdateHubContestMutation = { updateHubContest: { id: number, player1FullName: string, player1PhotoUrl: string, player1BonusPoints: number, player1Game: string, player1GameStartTime: string, player2FullName: string, player2PhotoUrl: string, player2BonusPoints: number, player2Game: string, player2GameStartTime: string, published: boolean, player1FantasyPoints?: number | null, player2FantasyPoints?: number | null, winnerLabel?: string | null, status?: string | null, createdAt?: string | null, updatedAt?: string | null } };

export type UpdatePartnerMutationVariables = Exact<{
  data: UpdatePartnerDto;
}>;


export type UpdatePartnerMutation = { updatePartner: { id: number } };

export type UpdatePartnerActiveStatusMutationVariables = Exact<{
  data: PartnerActiveInputDto;
}>;


export type UpdatePartnerActiveStatusMutation = { updatePartnerActiveStatus: { id: number } };

export type UpdatePartnerAdminMutationVariables = Exact<{
  data: UpdatePartnerAdminInputDto;
}>;


export type UpdatePartnerAdminMutation = { updatePartnerAdmin: { id: number, fullName: string, email: string, phone?: string | null, active: boolean } };

export type UpdatePartnerHubContestMutationVariables = Exact<{
  data: UpdateHubContestInputDto;
}>;


export type UpdatePartnerHubContestMutation = { updatePartnerHubContest: Array<{ id: number }> };

export type UpdatePartnerPrizeConstantMutationVariables = Exact<{
  data: PartnerUpdatePrizeConstantInputDto;
}>;


export type UpdatePartnerPrizeConstantMutation = { updatePartnerPrizeConstant: { prizeConfiguration: { GPP: number, PRIZE_POOL: number, WEEKLY_RESERVE: number } } };

export type UpdatePartnerSportMutationVariables = Exact<{
  data: UpdateSportDto;
}>;


export type UpdatePartnerSportMutation = { updatePartnerSport: { id?: number | null } };

export type UpdatePartnerTouchdownMutationVariables = Exact<{
  data: PartnerUpdateTouchdownInputDto;
}>;


export type UpdatePartnerTouchdownMutation = { updatePartnerTouchdown: { id: number } };

export type UpdatePrizePoolMutationVariables = Exact<{
  data: CreatePrizePoolInput;
  prizePoolId: Scalars['Float'];
}>;


export type UpdatePrizePoolMutation = { updatePrizePool: { prizePoolId: number, entryFees: number, totalEntrants: number, supportedSports: Array<SupportedSports>, maxEntriesPerUser: number, predeterminePrizePool: number, predetermineDailyGPP: number, predetermineWeeklyReserve: number, predetermineJackpot: number, predetermineContestVig: number, actualPrizePool?: number | null, actualDailyGPP?: number | null, actualWeeklyReserve?: number | null, actualJackpot?: number | null, actualContestVig?: number | null, isRollsOver: boolean, status: PrizePoolStatus, startDate: string, endDate: string, prizeDate: string } };

export type UpdateSportMutationVariables = Exact<{
  data: UpdateSportInput;
}>;


export type UpdateSportMutation = { updateSport: { id?: number | null, name?: string | null, logoUrl?: string | null, winCriteria?: WinCriteria | null, enabled?: boolean | null, questions?: Array<{ id: number, question: string }> | null } };

export type UpdateSuggestionMutationVariables = Exact<{
  oppenentId: Scalars['String'];
  suggestionId: Scalars['Int'];
}>;


export type UpdateSuggestionMutation = { updateSuggestion: { success: boolean } };

export type UpdateTournamentsMutationVariables = Exact<{
  data: TournamentsStateInputType;
}>;


export type UpdateTournamentsMutation = { updateTournaments: { success: boolean, message?: string | null } };

export type UserActivationMutationVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type UserActivationMutation = { userActivation: { message: string, succcess: boolean } };

export type VoidContestMutationVariables = Exact<{
  contestId: Scalars['Int'];
}>;


export type VoidContestMutation = { voidContest: { id: number } };

export type VoidContestsMutationVariables = Exact<{ [key: string]: never; }>;


export type VoidContestsMutation = { voidContests: { success: boolean } };

export type WalletBonusDepositMutationVariables = Exact<{
  data: WalletDepositDto;
}>;


export type WalletBonusDepositMutation = { walletBonusDeposit: { message: string } };

export type WithdrawRequestActionMutationVariables = Exact<{
  data: WithdrawRequestActionDto;
}>;


export type WithdrawRequestActionMutation = { withdrawRequestAction: { message: string } };


export const BroadcastNotificationDocument = `
    mutation BroadcastNotification($title: String!, $body: String!) {
  broadcastNotification(data: {title: $title, body: $body})
}
    `;
export const useBroadcastNotificationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<BroadcastNotificationMutation, TError, BroadcastNotificationMutationVariables, TContext>) =>
    useMutation<BroadcastNotificationMutation, TError, BroadcastNotificationMutationVariables, TContext>(
      ['BroadcastNotification'],
      useFetchData<BroadcastNotificationMutation, BroadcastNotificationMutationVariables>(BroadcastNotificationDocument),
      options
    );
export const CancelContestDocument = `
    mutation CancelContest($contestId: Int!) {
  cancelContest(data: {contestId: $contestId}) {
    id
  }
}
    `;
export const useCancelContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CancelContestMutation, TError, CancelContestMutationVariables, TContext>) =>
    useMutation<CancelContestMutation, TError, CancelContestMutationVariables, TContext>(
      ['CancelContest'],
      useFetchData<CancelContestMutation, CancelContestMutationVariables>(CancelContestDocument),
      options
    );
export const CountCommonContestsDocument = `
    query CountCommonContests($date: String!, $type: String!) {
  countCommonContests(date: $date, type: $type) {
    id
    count
    name
  }
}
    `;
export const useCountCommonContestsQuery = <
      TData = CountCommonContestsQuery,
      TError = unknown
    >(
      variables: CountCommonContestsQueryVariables,
      options?: UseQueryOptions<CountCommonContestsQuery, TError, TData>
    ) =>
    useQuery<CountCommonContestsQuery, TError, TData>(
      ['CountCommonContests', variables],
      useFetchData<CountCommonContestsQuery, CountCommonContestsQueryVariables>(CountCommonContestsDocument).bind(null, variables),
      options
    );

useCountCommonContestsQuery.getKey = (variables: CountCommonContestsQueryVariables) => ['CountCommonContests', variables];
;

export const CountF2PContestsDocument = `
    query CountF2PContests($partnerId: Int!, $type: String!, $date: String!, $prizePoolId: Int!) {
  countF2PContests(
    partnerId: $partnerId
    type: $type
    date: $date
    prizePoolId: $prizePoolId
  ) {
    id
    count
    name
  }
}
    `;
export const useCountF2PContestsQuery = <
      TData = CountF2PContestsQuery,
      TError = unknown
    >(
      variables: CountF2PContestsQueryVariables,
      options?: UseQueryOptions<CountF2PContestsQuery, TError, TData>
    ) =>
    useQuery<CountF2PContestsQuery, TError, TData>(
      ['CountF2PContests', variables],
      useFetchData<CountF2PContestsQuery, CountF2PContestsQueryVariables>(CountF2PContestsDocument).bind(null, variables),
      options
    );

useCountF2PContestsQuery.getKey = (variables: CountF2PContestsQueryVariables) => ['CountF2PContests', variables];
;

export const CountF2PHubContestsDocument = `
    query CountF2PHubContests($date: String!, $prizePoolId: Int!) {
  countF2PHubContests(date: $date, prizePoolId: $prizePoolId) {
    id
    count
    name
  }
}
    `;
export const useCountF2PHubContestsQuery = <
      TData = CountF2PHubContestsQuery,
      TError = unknown
    >(
      variables: CountF2PHubContestsQueryVariables,
      options?: UseQueryOptions<CountF2PHubContestsQuery, TError, TData>
    ) =>
    useQuery<CountF2PHubContestsQuery, TError, TData>(
      ['CountF2PHubContests', variables],
      useFetchData<CountF2PHubContestsQuery, CountF2PHubContestsQueryVariables>(CountF2PHubContestsDocument).bind(null, variables),
      options
    );

useCountF2PHubContestsQuery.getKey = (variables: CountF2PHubContestsQueryVariables) => ['CountF2PHubContests', variables];
;

export const CountHubContestsDocument = `
    query CountHubContests($date: String!) {
  countHubContests(date: $date) {
    sportId
    contestCount
  }
}
    `;
export const useCountHubContestsQuery = <
      TData = CountHubContestsQuery,
      TError = unknown
    >(
      variables: CountHubContestsQueryVariables,
      options?: UseQueryOptions<CountHubContestsQuery, TError, TData>
    ) =>
    useQuery<CountHubContestsQuery, TError, TData>(
      ['CountHubContests', variables],
      useFetchData<CountHubContestsQuery, CountHubContestsQueryVariables>(CountHubContestsDocument).bind(null, variables),
      options
    );

useCountHubContestsQuery.getKey = (variables: CountHubContestsQueryVariables) => ['CountHubContests', variables];
;

export const CountPartnerContestsDocument = `
    query CountPartnerContests($partnerId: Int!, $type: String!, $date: String!, $prizePoolId: Int!) {
  countPartnerContests(
    partnerId: $partnerId
    type: $type
    date: $date
    prizePoolId: $prizePoolId
  ) {
    id
    count
    name
  }
}
    `;
export const useCountPartnerContestsQuery = <
      TData = CountPartnerContestsQuery,
      TError = unknown
    >(
      variables: CountPartnerContestsQueryVariables,
      options?: UseQueryOptions<CountPartnerContestsQuery, TError, TData>
    ) =>
    useQuery<CountPartnerContestsQuery, TError, TData>(
      ['CountPartnerContests', variables],
      useFetchData<CountPartnerContestsQuery, CountPartnerContestsQueryVariables>(CountPartnerContestsDocument).bind(null, variables),
      options
    );

useCountPartnerContestsQuery.getKey = (variables: CountPartnerContestsQueryVariables) => ['CountPartnerContests', variables];
;

export const CountPartnerHubContestsDocument = `
    query CountPartnerHubContests($date: String!, $prizePoolId: Int!) {
  countPartnerHubContests(date: $date, prizePoolId: $prizePoolId) {
    id
    count
    name
  }
}
    `;
export const useCountPartnerHubContestsQuery = <
      TData = CountPartnerHubContestsQuery,
      TError = unknown
    >(
      variables: CountPartnerHubContestsQueryVariables,
      options?: UseQueryOptions<CountPartnerHubContestsQuery, TError, TData>
    ) =>
    useQuery<CountPartnerHubContestsQuery, TError, TData>(
      ['CountPartnerHubContests', variables],
      useFetchData<CountPartnerHubContestsQuery, CountPartnerHubContestsQueryVariables>(CountPartnerHubContestsDocument).bind(null, variables),
      options
    );

useCountPartnerHubContestsQuery.getKey = (variables: CountPartnerHubContestsQueryVariables) => ['CountPartnerHubContests', variables];
;

export const CreateCommonHubContestDocument = `
    mutation CreateCommonHubContest($data: CreateCommonHubContestInput!) {
  createCommonHubContest(data: $data) {
    id
    player1Id
    player1FullName
    player1PhotoUrl
    player1BonusPoints
    player1Game
    player1GameStartTime
    player1FantasyPoints
    player2Id
    player2FullName
    player2PhotoUrl
    player2BonusPoints
    player2Game
    player2GameStartTime
    player2FantasyPoints
    status
    winnerPlayerId
    winnerLabel
    contestDate
  }
}
    `;
export const useCreateCommonHubContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateCommonHubContestMutation, TError, CreateCommonHubContestMutationVariables, TContext>) =>
    useMutation<CreateCommonHubContestMutation, TError, CreateCommonHubContestMutationVariables, TContext>(
      ['CreateCommonHubContest'],
      useFetchData<CreateCommonHubContestMutation, CreateCommonHubContestMutationVariables>(CreateCommonHubContestDocument),
      options
    );
export const CreateCommonNbaTouchdownContestDocument = `
    mutation CreateCommonNbaTouchdownContest($data: NbaTouchdownCommonContestCreateInput!) {
  createCommonNbaTouchdownContest(data: $data) {
    id
    player1BonusPoints
    player2BonusPoints
    status
    player1 {
      id
      remoteId
      firstName
      lastName
      fullName
      shortName
      injuryStatus
      photoUrl
      photoUrlHiRes
      available
      position
      teamName
      opponentName
      homeOrAway
      hasStarted
      isOver
      fantasyPoints
      fantasyPointsHalfPpr
      fantasyPointsFullPpr
      projectedFantasyPoints
      projectedFantasyPointsHalfPpr
      teamId
      yahooPlayerId
      espnPlayerId
      gameId
      gameStartTime
    }
    player2 {
      id
      remoteId
      firstName
      lastName
      fullName
      shortName
      injuryStatus
      photoUrl
      photoUrlHiRes
      available
      position
      teamName
      opponentName
      homeOrAway
      hasStarted
      isOver
      fantasyPoints
      fantasyPointsHalfPpr
      fantasyPointsFullPpr
      projectedFantasyPoints
      projectedFantasyPointsHalfPpr
      teamId
      yahooPlayerId
      espnPlayerId
      gameId
      gameStartTime
    }
  }
}
    `;
export const useCreateCommonNbaTouchdownContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateCommonNbaTouchdownContestMutation, TError, CreateCommonNbaTouchdownContestMutationVariables, TContext>) =>
    useMutation<CreateCommonNbaTouchdownContestMutation, TError, CreateCommonNbaTouchdownContestMutationVariables, TContext>(
      ['CreateCommonNbaTouchdownContest'],
      useFetchData<CreateCommonNbaTouchdownContestMutation, CreateCommonNbaTouchdownContestMutationVariables>(CreateCommonNbaTouchdownContestDocument),
      options
    );
export const CreateCommonSoccerTouchdownContestDocument = `
    mutation CreateCommonSoccerTouchdownContest($data: SoccerCommonTouchdownContestCreateInput!) {
  createCommonSoccerTouchdownContest(data: $data) {
    id
    player1BonusPoints
    player2BonusPoints
    metric
    status
    player1 {
      id
      remoteId
      firstName
      lastName
      fullName
      shortName
      commonName
      injuryStatus
      photoUrl
      position
      positionCategory
      teamName
      opponentName
      homeOrAway
      hasStarted
      isOver
      shots
      saves
      passes
      shotsOnGoal
      goals
      assists
      tackles
      gameId
      gameStartTime
      projectedShots
      projectedSaves
      projectedPasses
      projectedShotsOnGoal
      projectedGoals
      projectedAssists
      projectedTackles
    }
    player2 {
      id
      remoteId
      firstName
      lastName
      fullName
      shortName
      commonName
      injuryStatus
      photoUrl
      position
      positionCategory
      teamName
      opponentName
      homeOrAway
      hasStarted
      isOver
      shots
      saves
      passes
      shotsOnGoal
      goals
      assists
      tackles
      gameId
      gameStartTime
      projectedShots
      projectedSaves
      projectedPasses
      projectedShotsOnGoal
      projectedGoals
      projectedAssists
      projectedTackles
    }
  }
}
    `;
export const useCreateCommonSoccerTouchdownContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateCommonSoccerTouchdownContestMutation, TError, CreateCommonSoccerTouchdownContestMutationVariables, TContext>) =>
    useMutation<CreateCommonSoccerTouchdownContestMutation, TError, CreateCommonSoccerTouchdownContestMutationVariables, TContext>(
      ['CreateCommonSoccerTouchdownContest'],
      useFetchData<CreateCommonSoccerTouchdownContestMutation, CreateCommonSoccerTouchdownContestMutationVariables>(CreateCommonSoccerTouchdownContestDocument),
      options
    );
export const CreateCommonSportDocument = `
    mutation CreateCommonSport($data: CreateCommonSportDTO!) {
  createCommonSport(data: $data) {
    id
    name
    key
    logoUrl
    questions {
      id
      question
      metric
    }
    winCriteria
  }
}
    `;
export const useCreateCommonSportMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateCommonSportMutation, TError, CreateCommonSportMutationVariables, TContext>) =>
    useMutation<CreateCommonSportMutation, TError, CreateCommonSportMutationVariables, TContext>(
      ['CreateCommonSport'],
      useFetchData<CreateCommonSportMutation, CreateCommonSportMutationVariables>(CreateCommonSportDocument),
      options
    );
export const CreateCouponDocument = `
    mutation CreateCoupon($data: CreateCouponDto!) {
  createCoupon(data: $data) {
    id
    status
    code
    value
    createdAt
  }
}
    `;
export const useCreateCouponMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateCouponMutation, TError, CreateCouponMutationVariables, TContext>) =>
    useMutation<CreateCouponMutation, TError, CreateCouponMutationVariables, TContext>(
      ['CreateCoupon'],
      useFetchData<CreateCouponMutation, CreateCouponMutationVariables>(CreateCouponDocument),
      options
    );
export const CreateEditRoleDocument = `
    mutation CreateEditRole($data: CreateEditRoleDto!) {
  createEditRole(data: $data) {
    id
  }
}
    `;
export const useCreateEditRoleMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateEditRoleMutation, TError, CreateEditRoleMutationVariables, TContext>) =>
    useMutation<CreateEditRoleMutation, TError, CreateEditRoleMutationVariables, TContext>(
      ['CreateEditRole'],
      useFetchData<CreateEditRoleMutation, CreateEditRoleMutationVariables>(CreateEditRoleDocument),
      options
    );
export const CreateEditUserDocument = `
    mutation CreateEditUser($data: CreateEditUserDto!) {
  createEditUser(data: $data) {
    id
  }
}
    `;
export const useCreateEditUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateEditUserMutation, TError, CreateEditUserMutationVariables, TContext>) =>
    useMutation<CreateEditUserMutation, TError, CreateEditUserMutationVariables, TContext>(
      ['CreateEditUser'],
      useFetchData<CreateEditUserMutation, CreateEditUserMutationVariables>(CreateEditUserDocument),
      options
    );
export const CreateF2PHubContestDocument = `
    mutation CreateF2PHubContest($data: F2PCreateHubContestInputDTO!) {
  createF2PHubContest(data: $data) {
    id
  }
}
    `;
export const useCreateF2PHubContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateF2PHubContestMutation, TError, CreateF2PHubContestMutationVariables, TContext>) =>
    useMutation<CreateF2PHubContestMutation, TError, CreateF2PHubContestMutationVariables, TContext>(
      ['CreateF2PHubContest'],
      useFetchData<CreateF2PHubContestMutation, CreateF2PHubContestMutationVariables>(CreateF2PHubContestDocument),
      options
    );
export const CreateF2PNbaTouchdownContestDocument = `
    mutation CreateF2PNbaTouchdownContest($data: TouchdownF2PContestCreateInput!) {
  createF2PNbaTouchdownContest(data: $data) {
    id
  }
}
    `;
export const useCreateF2PNbaTouchdownContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateF2PNbaTouchdownContestMutation, TError, CreateF2PNbaTouchdownContestMutationVariables, TContext>) =>
    useMutation<CreateF2PNbaTouchdownContestMutation, TError, CreateF2PNbaTouchdownContestMutationVariables, TContext>(
      ['CreateF2PNbaTouchdownContest'],
      useFetchData<CreateF2PNbaTouchdownContestMutation, CreateF2PNbaTouchdownContestMutationVariables>(CreateF2PNbaTouchdownContestDocument),
      options
    );
export const CreateF2PPartnerDocument = `
    mutation CreateF2PPartner($data: CreateF2PPartnerInput!) {
  createF2PPartner(data: $data) {
    id
    name
    email
    contact
    identifier
    logo
    description
    primaryColor
    secondaryColor
    active
    createdBy
    enableUserAuth
  }
}
    `;
export const useCreateF2PPartnerMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateF2PPartnerMutation, TError, CreateF2PPartnerMutationVariables, TContext>) =>
    useMutation<CreateF2PPartnerMutation, TError, CreateF2PPartnerMutationVariables, TContext>(
      ['CreateF2PPartner'],
      useFetchData<CreateF2PPartnerMutation, CreateF2PPartnerMutationVariables>(CreateF2PPartnerDocument),
      options
    );
export const CreateF2PPrizePoolDocument = `
    mutation CreateF2PPrizePool($data: CreateF2PPrizePoolInputDTO!) {
  createF2PPrizePool(data: $data) {
    startDateTime
    endDateTime
    allowedSports
    lockTime
    maxEntriesPerUser
    totalEntrants
    id
    status
    createdAt
    updatedAt
  }
}
    `;
export const useCreateF2PPrizePoolMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateF2PPrizePoolMutation, TError, CreateF2PPrizePoolMutationVariables, TContext>) =>
    useMutation<CreateF2PPrizePoolMutation, TError, CreateF2PPrizePoolMutationVariables, TContext>(
      ['CreateF2PPrizePool'],
      useFetchData<CreateF2PPrizePoolMutation, CreateF2PPrizePoolMutationVariables>(CreateF2PPrizePoolDocument),
      options
    );
export const CreateF2PSoccerTouchdownContestDocument = `
    mutation CreateF2PSoccerTouchdownContest($data: SoccerTouchdownF2PContestCreateInput!) {
  createF2PSoccerTouchdownContest(data: $data) {
    id
  }
}
    `;
export const useCreateF2PSoccerTouchdownContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateF2PSoccerTouchdownContestMutation, TError, CreateF2PSoccerTouchdownContestMutationVariables, TContext>) =>
    useMutation<CreateF2PSoccerTouchdownContestMutation, TError, CreateF2PSoccerTouchdownContestMutationVariables, TContext>(
      ['CreateF2PSoccerTouchdownContest'],
      useFetchData<CreateF2PSoccerTouchdownContestMutation, CreateF2PSoccerTouchdownContestMutationVariables>(CreateF2PSoccerTouchdownContestDocument),
      options
    );
export const CreateF2PSportDocument = `
    mutation CreateF2PSport($data: F2PCreateSportDTO!) {
  createF2PSport(data: $data) {
    id
    name
    logoUrl
    winCriteria
    enabled
    orderIndex
    partnerId
  }
}
    `;
export const useCreateF2PSportMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateF2PSportMutation, TError, CreateF2PSportMutationVariables, TContext>) =>
    useMutation<CreateF2PSportMutation, TError, CreateF2PSportMutationVariables, TContext>(
      ['CreateF2PSport'],
      useFetchData<CreateF2PSportMutation, CreateF2PSportMutationVariables>(CreateF2PSportDocument),
      options
    );
export const CreateF2PTouchdownDocument = `
    mutation CreateF2PTouchdown($data: F2PCreateTouchdownInputDTO!) {
  createF2PTouchdown(data: $data) {
    id
    name
    startDateTime
    endDateTime
    allowedPick
    createdAt
    updatedAt
    deletedAt
  }
}
    `;
export const useCreateF2PTouchdownMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateF2PTouchdownMutation, TError, CreateF2PTouchdownMutationVariables, TContext>) =>
    useMutation<CreateF2PTouchdownMutation, TError, CreateF2PTouchdownMutationVariables, TContext>(
      ['CreateF2PTouchdown'],
      useFetchData<CreateF2PTouchdownMutation, CreateF2PTouchdownMutationVariables>(CreateF2PTouchdownDocument),
      options
    );
export const CreateHubContestDocument = `
    mutation CreateHubContest($data: CreateHubContestInput!) {
  createHubContest(data: $data) {
    id
    player1FullName
    player1PhotoUrl
    player1BonusPoints
    player1Game
    player1GameStartTime
    player2FullName
    player2PhotoUrl
    player2BonusPoints
    player2Game
    player2GameStartTime
    published
    player1FantasyPoints
    player2FantasyPoints
    winnerLabel
    status
    createdAt
    updatedAt
    questionId {
      id
      question
      metric
    }
    sportId {
      id
      name
      logoUrl
      winCriteria
      enabled
    }
  }
}
    `;
export const useCreateHubContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateHubContestMutation, TError, CreateHubContestMutationVariables, TContext>) =>
    useMutation<CreateHubContestMutation, TError, CreateHubContestMutationVariables, TContext>(
      ['CreateHubContest'],
      useFetchData<CreateHubContestMutation, CreateHubContestMutationVariables>(CreateHubContestDocument),
      options
    );
export const CreateNbaTouchdownContestDocument = `
    mutation CreateNbaTouchdownContest($startTime: String!, $player1Id: Int!, $player2Id: Int!, $player1GameId: Int!, $player2GameId: Int!, $player1BonusPoints: Float!, $player2BonusPoints: Float!, $player1ProjFantasyPoints: Float!, $player2ProjFantasyPoints: Float!) {
  createNbaTouchdownContest(
    data: {startTime: $startTime, player1Id: $player1Id, player2Id: $player2Id, player1GameId: $player1GameId, player2GameId: $player2GameId, player1BonusPoints: $player1BonusPoints, player2BonusPoints: $player2BonusPoints, player1ProjFantasyPoints: $player1ProjFantasyPoints, player2ProjFantasyPoints: $player2ProjFantasyPoints}
  ) {
    id
  }
}
    `;
export const useCreateNbaTouchdownContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateNbaTouchdownContestMutation, TError, CreateNbaTouchdownContestMutationVariables, TContext>) =>
    useMutation<CreateNbaTouchdownContestMutation, TError, CreateNbaTouchdownContestMutationVariables, TContext>(
      ['CreateNbaTouchdownContest'],
      useFetchData<CreateNbaTouchdownContestMutation, CreateNbaTouchdownContestMutationVariables>(CreateNbaTouchdownContestDocument),
      options
    );
export const CreateNflMatchupDocument = `
    mutation CreateNflMatchup($playerOneId: Int!, $playerTwoId: Int!) {
  createNflMatchup(data: {playerOneId: $playerOneId, playerTwoId: $playerTwoId}) {
    id
  }
}
    `;
export const useCreateNflMatchupMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateNflMatchupMutation, TError, CreateNflMatchupMutationVariables, TContext>) =>
    useMutation<CreateNflMatchupMutation, TError, CreateNflMatchupMutationVariables, TContext>(
      ['CreateNflMatchup'],
      useFetchData<CreateNflMatchupMutation, CreateNflMatchupMutationVariables>(CreateNflMatchupDocument),
      options
    );
export const CreateNflTouchdownContestDocument = `
    mutation CreateNflTouchdownContest($startTime: String!, $player1Id: Int!, $player2Id: Int!, $player1GameId: Int!, $player2GameId: Int!, $player1BonusPoints: Float!, $player2BonusPoints: Float!, $player1ProjFantasyPoints: Float!, $player2ProjFantasyPoints: Float!) {
  createNflTouchdownContest(
    data: {startTime: $startTime, player1Id: $player1Id, player2Id: $player2Id, player1GameId: $player1GameId, player2GameId: $player2GameId, player1BonusPoints: $player1BonusPoints, player2BonusPoints: $player2BonusPoints, player1ProjFantasyPoints: $player1ProjFantasyPoints, player2ProjFantasyPoints: $player2ProjFantasyPoints}
  ) {
    id
  }
}
    `;
export const useCreateNflTouchdownContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateNflTouchdownContestMutation, TError, CreateNflTouchdownContestMutationVariables, TContext>) =>
    useMutation<CreateNflTouchdownContestMutation, TError, CreateNflTouchdownContestMutationVariables, TContext>(
      ['CreateNflTouchdownContest'],
      useFetchData<CreateNflTouchdownContestMutation, CreateNflTouchdownContestMutationVariables>(CreateNflTouchdownContestDocument),
      options
    );
export const CreateOrUpdateMathConstantDocument = `
    mutation CreateOrUpdateMathConstant($mathConstant: [MathConstantInput!]!) {
  createOrUpdateMathConstant(mathConstant: $mathConstant) {
    id
    name
    value
  }
}
    `;
export const useCreateOrUpdateMathConstantMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateOrUpdateMathConstantMutation, TError, CreateOrUpdateMathConstantMutationVariables, TContext>) =>
    useMutation<CreateOrUpdateMathConstantMutation, TError, CreateOrUpdateMathConstantMutationVariables, TContext>(
      ['CreateOrUpdateMathConstant'],
      useFetchData<CreateOrUpdateMathConstantMutation, CreateOrUpdateMathConstantMutationVariables>(CreateOrUpdateMathConstantDocument),
      options
    );
export const CreateOrUpdateNbaContestTitleDocument = `
    mutation CreateOrUpdateNbaContestTitle($question: String!) {
  createOrUpdateNbaContestTitle(data: {question: $question}) {
    statName
    question
  }
}
    `;
export const useCreateOrUpdateNbaContestTitleMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateOrUpdateNbaContestTitleMutation, TError, CreateOrUpdateNbaContestTitleMutationVariables, TContext>) =>
    useMutation<CreateOrUpdateNbaContestTitleMutation, TError, CreateOrUpdateNbaContestTitleMutationVariables, TContext>(
      ['CreateOrUpdateNbaContestTitle'],
      useFetchData<CreateOrUpdateNbaContestTitleMutation, CreateOrUpdateNbaContestTitleMutationVariables>(CreateOrUpdateNbaContestTitleDocument),
      options
    );
export const CreateOrUpdateNflContestTitleDocument = `
    mutation CreateOrUpdateNflContestTitle($question: String!) {
  createOrUpdateNflContestTitle(data: {question: $question}) {
    statName
    question
  }
}
    `;
export const useCreateOrUpdateNflContestTitleMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateOrUpdateNflContestTitleMutation, TError, CreateOrUpdateNflContestTitleMutationVariables, TContext>) =>
    useMutation<CreateOrUpdateNflContestTitleMutation, TError, CreateOrUpdateNflContestTitleMutationVariables, TContext>(
      ['CreateOrUpdateNflContestTitle'],
      useFetchData<CreateOrUpdateNflContestTitleMutation, CreateOrUpdateNflContestTitleMutationVariables>(CreateOrUpdateNflContestTitleDocument),
      options
    );
export const CreatePartnerDocument = `
    mutation CreatePartner($data: CreatePartnerDTO!) {
  createPartner(data: $data) {
    id
    name
    email
    contact
    active
    iframeKey
    secretKey
    userCount
    totalTouchdownCount
    logo
    primaryColor
    secondaryColor
    identifier
  }
}
    `;
export const useCreatePartnerMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreatePartnerMutation, TError, CreatePartnerMutationVariables, TContext>) =>
    useMutation<CreatePartnerMutation, TError, CreatePartnerMutationVariables, TContext>(
      ['CreatePartner'],
      useFetchData<CreatePartnerMutation, CreatePartnerMutationVariables>(CreatePartnerDocument),
      options
    );
export const CreatePartnerAdminDocument = `
    mutation CreatePartnerAdmin($data: CreatePartnerAdminInputDto!) {
  createPartnerAdmin(data: $data) {
    id
    fullName
    email
    phone
    active
  }
}
    `;
export const useCreatePartnerAdminMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreatePartnerAdminMutation, TError, CreatePartnerAdminMutationVariables, TContext>) =>
    useMutation<CreatePartnerAdminMutation, TError, CreatePartnerAdminMutationVariables, TContext>(
      ['CreatePartnerAdmin'],
      useFetchData<CreatePartnerAdminMutation, CreatePartnerAdminMutationVariables>(CreatePartnerAdminDocument),
      options
    );
export const CreatePartnerHubContestDocument = `
    mutation CreatePartnerHubContest($data: PartnerCreateHubContestInputDTO!) {
  createPartnerHubContest(data: $data) {
    id
  }
}
    `;
export const useCreatePartnerHubContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreatePartnerHubContestMutation, TError, CreatePartnerHubContestMutationVariables, TContext>) =>
    useMutation<CreatePartnerHubContestMutation, TError, CreatePartnerHubContestMutationVariables, TContext>(
      ['CreatePartnerHubContest'],
      useFetchData<CreatePartnerHubContestMutation, CreatePartnerHubContestMutationVariables>(CreatePartnerHubContestDocument),
      options
    );
export const CreatePartnerNbaTouchdownContestDocument = `
    mutation CreatePartnerNbaTouchdownContest($data: TouchdownPartnerContestCreateInput!) {
  createPartnerNbaTouchdownContest(data: $data) {
    id
  }
}
    `;
export const useCreatePartnerNbaTouchdownContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreatePartnerNbaTouchdownContestMutation, TError, CreatePartnerNbaTouchdownContestMutationVariables, TContext>) =>
    useMutation<CreatePartnerNbaTouchdownContestMutation, TError, CreatePartnerNbaTouchdownContestMutationVariables, TContext>(
      ['CreatePartnerNbaTouchdownContest'],
      useFetchData<CreatePartnerNbaTouchdownContestMutation, CreatePartnerNbaTouchdownContestMutationVariables>(CreatePartnerNbaTouchdownContestDocument),
      options
    );
export const CreatePartnerPrizePoolDocument = `
    mutation CreatePartnerPrizePool($data: CreatePrizePoolInputDTO!) {
  createPartnerPrizePool(data: $data) {
    id
  }
}
    `;
export const useCreatePartnerPrizePoolMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreatePartnerPrizePoolMutation, TError, CreatePartnerPrizePoolMutationVariables, TContext>) =>
    useMutation<CreatePartnerPrizePoolMutation, TError, CreatePartnerPrizePoolMutationVariables, TContext>(
      ['CreatePartnerPrizePool'],
      useFetchData<CreatePartnerPrizePoolMutation, CreatePartnerPrizePoolMutationVariables>(CreatePartnerPrizePoolDocument),
      options
    );
export const CreatePartnerSoccerTouchdownContestDocument = `
    mutation CreatePartnerSoccerTouchdownContest($data: SoccerTouchdownPartnerContestCreateInput!) {
  createPartnerSoccerTouchdownContest(data: $data) {
    id
  }
}
    `;
export const useCreatePartnerSoccerTouchdownContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreatePartnerSoccerTouchdownContestMutation, TError, CreatePartnerSoccerTouchdownContestMutationVariables, TContext>) =>
    useMutation<CreatePartnerSoccerTouchdownContestMutation, TError, CreatePartnerSoccerTouchdownContestMutationVariables, TContext>(
      ['CreatePartnerSoccerTouchdownContest'],
      useFetchData<CreatePartnerSoccerTouchdownContestMutation, CreatePartnerSoccerTouchdownContestMutationVariables>(CreatePartnerSoccerTouchdownContestDocument),
      options
    );
export const CreatePartnerSportDocument = `
    mutation CreatePartnerSport($data: CreateSportDTO!) {
  createPartnerSport(data: $data) {
    id
  }
}
    `;
export const useCreatePartnerSportMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreatePartnerSportMutation, TError, CreatePartnerSportMutationVariables, TContext>) =>
    useMutation<CreatePartnerSportMutation, TError, CreatePartnerSportMutationVariables, TContext>(
      ['CreatePartnerSport'],
      useFetchData<CreatePartnerSportMutation, CreatePartnerSportMutationVariables>(CreatePartnerSportDocument),
      options
    );
export const CreatePartnerTouchdownDocument = `
    mutation CreatePartnerTouchdown($data: PartnerCreateTouchdownInputDTO!) {
  createPartnerTouchdown(data: $data) {
    id
  }
}
    `;
export const useCreatePartnerTouchdownMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreatePartnerTouchdownMutation, TError, CreatePartnerTouchdownMutationVariables, TContext>) =>
    useMutation<CreatePartnerTouchdownMutation, TError, CreatePartnerTouchdownMutationVariables, TContext>(
      ['CreatePartnerTouchdown'],
      useFetchData<CreatePartnerTouchdownMutation, CreatePartnerTouchdownMutationVariables>(CreatePartnerTouchdownDocument),
      options
    );
export const UpdateWeeklyConstantDocument = `
    mutation UpdateWeeklyConstant($data: WeeklyConstantInput!) {
  updateWeeklyConstant(data: $data) {
    message
  }
}
    `;
export const useUpdateWeeklyConstantMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateWeeklyConstantMutation, TError, UpdateWeeklyConstantMutationVariables, TContext>) =>
    useMutation<UpdateWeeklyConstantMutation, TError, UpdateWeeklyConstantMutationVariables, TContext>(
      ['UpdateWeeklyConstant'],
      useFetchData<UpdateWeeklyConstantMutation, UpdateWeeklyConstantMutationVariables>(UpdateWeeklyConstantDocument),
      options
    );
export const CreatePrizePoolDocument = `
    mutation CreatePrizePool($data: CreatePrizePoolInput!) {
  createPrizePool(data: $data) {
    prizePoolId
    entryFees
    totalEntrants
    supportedSports
    maxEntriesPerUser
    predeterminePrizePool
    predetermineDailyGPP
    predetermineWeeklyReserve
    predetermineJackpot
    predetermineContestVig
    actualPrizePool
    actualDailyGPP
    actualWeeklyReserve
    actualJackpot
    actualContestVig
    isRollsOver
    status
    startDate
    endDate
  }
}
    `;
export const useCreatePrizePoolMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreatePrizePoolMutation, TError, CreatePrizePoolMutationVariables, TContext>) =>
    useMutation<CreatePrizePoolMutation, TError, CreatePrizePoolMutationVariables, TContext>(
      ['CreatePrizePool'],
      useFetchData<CreatePrizePoolMutation, CreatePrizePoolMutationVariables>(CreatePrizePoolDocument),
      options
    );
export const CreateSoccerTouchdownContestDocument = `
    mutation CreateSoccerTouchdownContest($question: String!, $metric: String!, $startTime: String!, $player1Id: Int!, $player2Id: Int!, $player1GameId: Int!, $player2GameId: Int!, $player1ProjFantasyPoints: Float!, $player2ProjFantasyPoints: Float!, $player1BonusPoints: Float!, $player2BonusPoints: Float!, $statName: String!) {
  createSoccerTouchdownContest(
    data: {question: $question, metric: $metric, startTime: $startTime, player1Id: $player1Id, player2Id: $player2Id, player1GameId: $player1GameId, player2GameId: $player2GameId, player1ProjFantasyPoints: $player1ProjFantasyPoints, player2ProjFantasyPoints: $player2ProjFantasyPoints, player1BonusPoints: $player1BonusPoints, player2BonusPoints: $player2BonusPoints, statName: $statName}
  ) {
    id
  }
}
    `;
export const useCreateSoccerTouchdownContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateSoccerTouchdownContestMutation, TError, CreateSoccerTouchdownContestMutationVariables, TContext>) =>
    useMutation<CreateSoccerTouchdownContestMutation, TError, CreateSoccerTouchdownContestMutationVariables, TContext>(
      ['CreateSoccerTouchdownContest'],
      useFetchData<CreateSoccerTouchdownContestMutation, CreateSoccerTouchdownContestMutationVariables>(CreateSoccerTouchdownContestDocument),
      options
    );
export const CreateSportDocument = `
    mutation CreateSport($data: CreateSportInput!) {
  createSport(data: $data) {
    id
    name
    logoUrl
    winCriteria
    enabled
    questions {
      id
      question
      metric
    }
  }
}
    `;
export const useCreateSportMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateSportMutation, TError, CreateSportMutationVariables, TContext>) =>
    useMutation<CreateSportMutation, TError, CreateSportMutationVariables, TContext>(
      ['CreateSport'],
      useFetchData<CreateSportMutation, CreateSportMutationVariables>(CreateSportDocument),
      options
    );
export const CreateSuggestionDocument = `
    mutation CreateSuggestion($mainPlayerId: String!, $tournamentId: String!, $roundNumber: Int, $contestType: String, $oppenentIds: [String]) {
  createSuggestion(
    data: {mainPlayerId: $mainPlayerId, tournamentId: $tournamentId, roundNumber: $roundNumber, contestType: $contestType, oppenentIds: $oppenentIds}
  ) {
    success
  }
}
    `;
export const useCreateSuggestionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateSuggestionMutation, TError, CreateSuggestionMutationVariables, TContext>) =>
    useMutation<CreateSuggestionMutation, TError, CreateSuggestionMutationVariables, TContext>(
      ['CreateSuggestion'],
      useFetchData<CreateSuggestionMutation, CreateSuggestionMutationVariables>(CreateSuggestionDocument),
      options
    );
export const CreateTouchdownDocument = `
    mutation CreateTouchdown($data: CreateTouchdownInput!) {
  createTouchdown(data: $data) {
    touchdownId
    startDate
    endDate
    touchDownType
    mathConstant {
      PRIZE_POOL
      GPP
      WEEKLY_RESERVE
    }
    prizePool {
      prizePoolId
      entryFees
      totalEntrants
      supportedSports
      maxEntriesPerUser
      predeterminePrizePool
      predetermineDailyGPP
      predetermineWeeklyReserve
      predetermineJackpot
      predetermineContestVig
      actualPrizePool
      actualDailyGPP
      actualWeeklyReserve
      actualJackpot
      actualContestVig
      isRollsOver
      status
      startDate
      endDate
    }
  }
}
    `;
export const useCreateTouchdownMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateTouchdownMutation, TError, CreateTouchdownMutationVariables, TContext>) =>
    useMutation<CreateTouchdownMutation, TError, CreateTouchdownMutationVariables, TContext>(
      ['CreateTouchdown'],
      useFetchData<CreateTouchdownMutation, CreateTouchdownMutationVariables>(CreateTouchdownDocument),
      options
    );
export const DeleteCommonHubContestDocument = `
    mutation DeleteCommonHubContest($contestId: Int!) {
  deleteCommonHubContest(contestId: $contestId) {
    message
  }
}
    `;
export const useDeleteCommonHubContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteCommonHubContestMutation, TError, DeleteCommonHubContestMutationVariables, TContext>) =>
    useMutation<DeleteCommonHubContestMutation, TError, DeleteCommonHubContestMutationVariables, TContext>(
      ['DeleteCommonHubContest'],
      useFetchData<DeleteCommonHubContestMutation, DeleteCommonHubContestMutationVariables>(DeleteCommonHubContestDocument),
      options
    );
export const DeleteCommonNbaTouchdownContestDocument = `
    mutation DeleteCommonNbaTouchdownContest($data: CommonContestDeleteInput!) {
  deleteCommonNbaTouchdownContest(data: $data)
}
    `;
export const useDeleteCommonNbaTouchdownContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteCommonNbaTouchdownContestMutation, TError, DeleteCommonNbaTouchdownContestMutationVariables, TContext>) =>
    useMutation<DeleteCommonNbaTouchdownContestMutation, TError, DeleteCommonNbaTouchdownContestMutationVariables, TContext>(
      ['DeleteCommonNbaTouchdownContest'],
      useFetchData<DeleteCommonNbaTouchdownContestMutation, DeleteCommonNbaTouchdownContestMutationVariables>(DeleteCommonNbaTouchdownContestDocument),
      options
    );
export const DeleteCommonSoccerTouchdownContestDocument = `
    mutation DeleteCommonSoccerTouchdownContest($data: CommonContestDeleteInput!) {
  deleteCommonSoccerTouchdownContest(data: $data)
}
    `;
export const useDeleteCommonSoccerTouchdownContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteCommonSoccerTouchdownContestMutation, TError, DeleteCommonSoccerTouchdownContestMutationVariables, TContext>) =>
    useMutation<DeleteCommonSoccerTouchdownContestMutation, TError, DeleteCommonSoccerTouchdownContestMutationVariables, TContext>(
      ['DeleteCommonSoccerTouchdownContest'],
      useFetchData<DeleteCommonSoccerTouchdownContestMutation, DeleteCommonSoccerTouchdownContestMutationVariables>(DeleteCommonSoccerTouchdownContestDocument),
      options
    );
export const DeleteCouponsDocument = `
    mutation DeleteCoupons($data: DeleteCouponsDto!) {
  deleteCoupons(data: $data) {
    affected
  }
}
    `;
export const useDeleteCouponsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteCouponsMutation, TError, DeleteCouponsMutationVariables, TContext>) =>
    useMutation<DeleteCouponsMutation, TError, DeleteCouponsMutationVariables, TContext>(
      ['DeleteCoupons'],
      useFetchData<DeleteCouponsMutation, DeleteCouponsMutationVariables>(DeleteCouponsDocument),
      options
    );
export const DeleteF2PHubContestDocument = `
    mutation DeleteF2PHubContest($contestId: Int!) {
  deleteF2PHubContest(contestId: $contestId)
}
    `;
export const useDeleteF2PHubContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteF2PHubContestMutation, TError, DeleteF2PHubContestMutationVariables, TContext>) =>
    useMutation<DeleteF2PHubContestMutation, TError, DeleteF2PHubContestMutationVariables, TContext>(
      ['DeleteF2PHubContest'],
      useFetchData<DeleteF2PHubContestMutation, DeleteF2PHubContestMutationVariables>(DeleteF2PHubContestDocument),
      options
    );
export const DeleteF2PNbaTouchdownContestDocument = `
    mutation DeleteF2PNbaTouchdownContest($data: F2PTouchdownContestDeleteInput!) {
  deleteF2PNbaTouchdownContest(data: $data)
}
    `;
export const useDeleteF2PNbaTouchdownContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteF2PNbaTouchdownContestMutation, TError, DeleteF2PNbaTouchdownContestMutationVariables, TContext>) =>
    useMutation<DeleteF2PNbaTouchdownContestMutation, TError, DeleteF2PNbaTouchdownContestMutationVariables, TContext>(
      ['DeleteF2PNbaTouchdownContest'],
      useFetchData<DeleteF2PNbaTouchdownContestMutation, DeleteF2PNbaTouchdownContestMutationVariables>(DeleteF2PNbaTouchdownContestDocument),
      options
    );
export const DeleteF2PSoccerTouchdownContestDocument = `
    mutation DeleteF2PSoccerTouchdownContest($data: F2PTouchdownContestDeleteInput!) {
  deleteF2PSoccerTouchdownContest(data: $data)
}
    `;
export const useDeleteF2PSoccerTouchdownContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteF2PSoccerTouchdownContestMutation, TError, DeleteF2PSoccerTouchdownContestMutationVariables, TContext>) =>
    useMutation<DeleteF2PSoccerTouchdownContestMutation, TError, DeleteF2PSoccerTouchdownContestMutationVariables, TContext>(
      ['DeleteF2PSoccerTouchdownContest'],
      useFetchData<DeleteF2PSoccerTouchdownContestMutation, DeleteF2PSoccerTouchdownContestMutationVariables>(DeleteF2PSoccerTouchdownContestDocument),
      options
    );
export const DeleteteFeaturedContestDocument = `
    mutation DeleteteFeaturedContest($matchupId: Int!) {
  deleteFeaturedContest(data: {matchupId: $matchupId})
}
    `;
export const useDeleteteFeaturedContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteteFeaturedContestMutation, TError, DeleteteFeaturedContestMutationVariables, TContext>) =>
    useMutation<DeleteteFeaturedContestMutation, TError, DeleteteFeaturedContestMutationVariables, TContext>(
      ['DeleteteFeaturedContest'],
      useFetchData<DeleteteFeaturedContestMutation, DeleteteFeaturedContestMutationVariables>(DeleteteFeaturedContestDocument),
      options
    );
export const DeleteHubContestDocument = `
    mutation DeleteHubContest($contestId: Int!) {
  deleteHubContest(contestId: $contestId)
}
    `;
export const useDeleteHubContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteHubContestMutation, TError, DeleteHubContestMutationVariables, TContext>) =>
    useMutation<DeleteHubContestMutation, TError, DeleteHubContestMutationVariables, TContext>(
      ['DeleteHubContest'],
      useFetchData<DeleteHubContestMutation, DeleteHubContestMutationVariables>(DeleteHubContestDocument),
      options
    );
export const DeleteNbaTouchdownContestDocument = `
    mutation DeleteNbaTouchdownContest($id: Int!, $status: String!) {
  deleteNbaTouchdownContest(data: {id: $id, status: $status}) {
    id
  }
}
    `;
export const useDeleteNbaTouchdownContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteNbaTouchdownContestMutation, TError, DeleteNbaTouchdownContestMutationVariables, TContext>) =>
    useMutation<DeleteNbaTouchdownContestMutation, TError, DeleteNbaTouchdownContestMutationVariables, TContext>(
      ['DeleteNbaTouchdownContest'],
      useFetchData<DeleteNbaTouchdownContestMutation, DeleteNbaTouchdownContestMutationVariables>(DeleteNbaTouchdownContestDocument),
      options
    );
export const DeleteNflMatchupDocument = `
    mutation DeleteNflMatchup($matchupId: Int!, $status: String!) {
  deleteNflMatchup(data: {matchupId: $matchupId, status: $status}) {
    id
  }
}
    `;
export const useDeleteNflMatchupMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteNflMatchupMutation, TError, DeleteNflMatchupMutationVariables, TContext>) =>
    useMutation<DeleteNflMatchupMutation, TError, DeleteNflMatchupMutationVariables, TContext>(
      ['DeleteNflMatchup'],
      useFetchData<DeleteNflMatchupMutation, DeleteNflMatchupMutationVariables>(DeleteNflMatchupDocument),
      options
    );
export const DeleteNflTouchdownContestDocument = `
    mutation DeleteNflTouchdownContest($id: Int!, $status: String!) {
  deleteNflTouchdownContest(data: {id: $id, status: $status}) {
    id
  }
}
    `;
export const useDeleteNflTouchdownContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteNflTouchdownContestMutation, TError, DeleteNflTouchdownContestMutationVariables, TContext>) =>
    useMutation<DeleteNflTouchdownContestMutation, TError, DeleteNflTouchdownContestMutationVariables, TContext>(
      ['DeleteNflTouchdownContest'],
      useFetchData<DeleteNflTouchdownContestMutation, DeleteNflTouchdownContestMutationVariables>(DeleteNflTouchdownContestDocument),
      options
    );
export const DeletePartnerHubContestDocument = `
    mutation DeletePartnerHubContest($contestId: Int!) {
  deletePartnerHubContest(contestId: $contestId)
}
    `;
export const useDeletePartnerHubContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeletePartnerHubContestMutation, TError, DeletePartnerHubContestMutationVariables, TContext>) =>
    useMutation<DeletePartnerHubContestMutation, TError, DeletePartnerHubContestMutationVariables, TContext>(
      ['DeletePartnerHubContest'],
      useFetchData<DeletePartnerHubContestMutation, DeletePartnerHubContestMutationVariables>(DeletePartnerHubContestDocument),
      options
    );
export const DeletePartnerNbaTouchdownContestDocument = `
    mutation DeletePartnerNbaTouchdownContest($data: TouchdownContestDeleteInput!) {
  deletePartnerNbaTouchdownContest(data: $data)
}
    `;
export const useDeletePartnerNbaTouchdownContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeletePartnerNbaTouchdownContestMutation, TError, DeletePartnerNbaTouchdownContestMutationVariables, TContext>) =>
    useMutation<DeletePartnerNbaTouchdownContestMutation, TError, DeletePartnerNbaTouchdownContestMutationVariables, TContext>(
      ['DeletePartnerNbaTouchdownContest'],
      useFetchData<DeletePartnerNbaTouchdownContestMutation, DeletePartnerNbaTouchdownContestMutationVariables>(DeletePartnerNbaTouchdownContestDocument),
      options
    );
export const DeletePartnerSoccerTouchdownContestDocument = `
    mutation DeletePartnerSoccerTouchdownContest($data: TouchdownContestDeleteInput!) {
  deletePartnerSoccerTouchdownContest(data: $data)
}
    `;
export const useDeletePartnerSoccerTouchdownContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeletePartnerSoccerTouchdownContestMutation, TError, DeletePartnerSoccerTouchdownContestMutationVariables, TContext>) =>
    useMutation<DeletePartnerSoccerTouchdownContestMutation, TError, DeletePartnerSoccerTouchdownContestMutationVariables, TContext>(
      ['DeletePartnerSoccerTouchdownContest'],
      useFetchData<DeletePartnerSoccerTouchdownContestMutation, DeletePartnerSoccerTouchdownContestMutationVariables>(DeletePartnerSoccerTouchdownContestDocument),
      options
    );
export const DeleteRoleDocument = `
    mutation DeleteRole($roleId: Int!) {
  deleteRole(roleId: $roleId) {
    message
    succcess
  }
}
    `;
export const useDeleteRoleMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteRoleMutation, TError, DeleteRoleMutationVariables, TContext>) =>
    useMutation<DeleteRoleMutation, TError, DeleteRoleMutationVariables, TContext>(
      ['DeleteRole'],
      useFetchData<DeleteRoleMutation, DeleteRoleMutationVariables>(DeleteRoleDocument),
      options
    );
export const DeleteSoccerTouchdownContestDocument = `
    mutation DeleteSoccerTouchdownContest($id: Int!, $status: String!) {
  deleteSoccerTouchdownContest(data: {id: $id, status: $status}) {
    id
  }
}
    `;
export const useDeleteSoccerTouchdownContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteSoccerTouchdownContestMutation, TError, DeleteSoccerTouchdownContestMutationVariables, TContext>) =>
    useMutation<DeleteSoccerTouchdownContestMutation, TError, DeleteSoccerTouchdownContestMutationVariables, TContext>(
      ['DeleteSoccerTouchdownContest'],
      useFetchData<DeleteSoccerTouchdownContestMutation, DeleteSoccerTouchdownContestMutationVariables>(DeleteSoccerTouchdownContestDocument),
      options
    );
export const DeleteSuggestionDocument = `
    mutation DeleteSuggestion($suggestionId: Int!) {
  deleteSuggestion(data: {suggestionId: $suggestionId}) {
    success
  }
}
    `;
export const useDeleteSuggestionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteSuggestionMutation, TError, DeleteSuggestionMutationVariables, TContext>) =>
    useMutation<DeleteSuggestionMutation, TError, DeleteSuggestionMutationVariables, TContext>(
      ['DeleteSuggestion'],
      useFetchData<DeleteSuggestionMutation, DeleteSuggestionMutationVariables>(DeleteSuggestionDocument),
      options
    );
export const DeleteUserPermanentlyDocument = `
    mutation DeleteUserPermanently($shouldDelete: Boolean!, $userId: Int!) {
  deleteUserPermanently(data: {shouldDelete: $shouldDelete, userId: $userId}) {
    message
    succcess
  }
}
    `;
export const useDeleteUserPermanentlyMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteUserPermanentlyMutation, TError, DeleteUserPermanentlyMutationVariables, TContext>) =>
    useMutation<DeleteUserPermanentlyMutation, TError, DeleteUserPermanentlyMutationVariables, TContext>(
      ['DeleteUserPermanently'],
      useFetchData<DeleteUserPermanentlyMutation, DeleteUserPermanentlyMutationVariables>(DeleteUserPermanentlyDocument),
      options
    );
export const EnableF2PSportDocument = `
    mutation EnableF2PSport($data: F2PEnableSportInputDTO!) {
  enableF2PSport(data: $data) {
    message
  }
}
    `;
export const useEnableF2PSportMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<EnableF2PSportMutation, TError, EnableF2PSportMutationVariables, TContext>) =>
    useMutation<EnableF2PSportMutation, TError, EnableF2PSportMutationVariables, TContext>(
      ['EnableF2PSport'],
      useFetchData<EnableF2PSportMutation, EnableF2PSportMutationVariables>(EnableF2PSportDocument),
      options
    );
export const EnablePartnerSportDocument = `
    mutation EnablePartnerSport($data: EnableSportInputDTO!) {
  enablePartnerSport(data: $data) {
    message
  }
}
    `;
export const useEnablePartnerSportMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<EnablePartnerSportMutation, TError, EnablePartnerSportMutationVariables, TContext>) =>
    useMutation<EnablePartnerSportMutation, TError, EnablePartnerSportMutationVariables, TContext>(
      ['EnablePartnerSport'],
      useFetchData<EnablePartnerSportMutation, EnablePartnerSportMutationVariables>(EnablePartnerSportDocument),
      options
    );
export const EnabledSportDocument = `
    mutation EnabledSport($data: EnabledSportInput!) {
  enabledSport(data: $data)
}
    `;
export const useEnabledSportMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<EnabledSportMutation, TError, EnabledSportMutationVariables, TContext>) =>
    useMutation<EnabledSportMutation, TError, EnabledSportMutationVariables, TContext>(
      ['EnabledSport'],
      useFetchData<EnabledSportMutation, EnabledSportMutationVariables>(EnabledSportDocument),
      options
    );
export const FeatureFlagsDocument = `
    query FeatureFlags {
  featureFlags {
    roundByRoundContestCreationEnabled
    tournamentContestCreationEnabled
  }
}
    `;
export const useFeatureFlagsQuery = <
      TData = FeatureFlagsQuery,
      TError = unknown
    >(
      variables?: FeatureFlagsQueryVariables,
      options?: UseQueryOptions<FeatureFlagsQuery, TError, TData>
    ) =>
    useQuery<FeatureFlagsQuery, TError, TData>(
      variables === undefined ? ['FeatureFlags'] : ['FeatureFlags', variables],
      useFetchData<FeatureFlagsQuery, FeatureFlagsQueryVariables>(FeatureFlagsDocument).bind(null, variables),
      options
    );

useFeatureFlagsQuery.getKey = (variables?: FeatureFlagsQueryVariables) => variables === undefined ? ['FeatureFlags'] : ['FeatureFlags', variables];
;

export const FetchContestsDocument = `
    query FetchContests($date: String!, $sportId: Int!) {
  fetchContests(date: $date, sportId: $sportId) {
    id
    orderIndex
    player1FullName
    player1PhotoUrl
    player1BonusPoints
    player2FullName
    player2PhotoUrl
    player2BonusPoints
    matchDate
    matchDateStartTime
    sportName
    type
    metric
  }
}
    `;
export const useFetchContestsQuery = <
      TData = FetchContestsQuery,
      TError = unknown
    >(
      variables: FetchContestsQueryVariables,
      options?: UseQueryOptions<FetchContestsQuery, TError, TData>
    ) =>
    useQuery<FetchContestsQuery, TError, TData>(
      ['FetchContests', variables],
      useFetchData<FetchContestsQuery, FetchContestsQueryVariables>(FetchContestsDocument).bind(null, variables),
      options
    );

useFetchContestsQuery.getKey = (variables: FetchContestsQueryVariables) => ['FetchContests', variables];
;

export const FetchF2PContestsDocument = `
    query FetchF2PContests($date: String!, $partnerId: Int!, $sportId: Int, $prizePoolId: Int!, $fetchFeatureContest: Boolean!) {
  fetchF2PContests(
    date: $date
    partnerId: $partnerId
    sportId: $sportId
    prizePoolId: $prizePoolId
    fetchFeatureContest: $fetchFeatureContest
  ) {
    id
    orderIndex
    player1FullName
    player1PhotoUrl
    player1BonusPoints
    player2FullName
    player2PhotoUrl
    player2BonusPoints
    matchDate
    matchDateStartTime
    sportName
    type
    metric
    createdAt
    prizePoolId
  }
}
    `;
export const useFetchF2PContestsQuery = <
      TData = FetchF2PContestsQuery,
      TError = unknown
    >(
      variables: FetchF2PContestsQueryVariables,
      options?: UseQueryOptions<FetchF2PContestsQuery, TError, TData>
    ) =>
    useQuery<FetchF2PContestsQuery, TError, TData>(
      ['FetchF2PContests', variables],
      useFetchData<FetchF2PContestsQuery, FetchF2PContestsQueryVariables>(FetchF2PContestsDocument).bind(null, variables),
      options
    );

useFetchF2PContestsQuery.getKey = (variables: FetchF2PContestsQueryVariables) => ['FetchF2PContests', variables];
;

export const FetchF2PHubContestsDocument = `
    query FetchF2PHubContests($sportId: Int!, $date: String!, $prizePoolId: Int!) {
  fetchF2PHubContests(sportId: $sportId, date: $date, prizePoolId: $prizePoolId) {
    id
    player1FullName
    player1PhotoUrl
    player1BonusPoints
    player1Game
    player1GameStartTime
    player2FullName
    player2PhotoUrl
    player2BonusPoints
    player2Game
    player2GameStartTime
    published
    player1FantasyPoints
    player2FantasyPoints
    winnerLabel
    status
    createdAt
    updatedAt
    prizePoolId
    sport {
      id
      name
      logoUrl
      winCriteria
      enabled
      orderIndex
      partnerId
      questions {
        id
        question
        metric
      }
    }
    questionId {
      id
      question
      metric
    }
    availableInTouchdowns {
      name
      prizePoolId
    }
  }
}
    `;
export const useFetchF2PHubContestsQuery = <
      TData = FetchF2PHubContestsQuery,
      TError = unknown
    >(
      variables: FetchF2PHubContestsQueryVariables,
      options?: UseQueryOptions<FetchF2PHubContestsQuery, TError, TData>
    ) =>
    useQuery<FetchF2PHubContestsQuery, TError, TData>(
      ['FetchF2PHubContests', variables],
      useFetchData<FetchF2PHubContestsQuery, FetchF2PHubContestsQueryVariables>(FetchF2PHubContestsDocument).bind(null, variables),
      options
    );

useFetchF2PHubContestsQuery.getKey = (variables: FetchF2PHubContestsQueryVariables) => ['FetchF2PHubContests', variables];
;

export const FetchF2PTouchdownDocument = `
    query FetchF2PTouchdown($data: F2PFetchTouchdownInputDTO!) {
  fetchF2PTouchdown(data: $data) {
    page
    limit
    totalCount
    data {
      id
      name
      startDateTime
      endDateTime
      allowedPick
      createdAt
      updatedAt
      deletedAt
      prizePools {
        startDateTime
        endDateTime
        allowedSports
        lockTime
        maxEntriesPerUser
        totalEntrants
        id
        status
        createdAt
        updatedAt
      }
    }
  }
}
    `;
export const useFetchF2PTouchdownQuery = <
      TData = FetchF2PTouchdownQuery,
      TError = unknown
    >(
      variables: FetchF2PTouchdownQueryVariables,
      options?: UseQueryOptions<FetchF2PTouchdownQuery, TError, TData>
    ) =>
    useQuery<FetchF2PTouchdownQuery, TError, TData>(
      ['FetchF2PTouchdown', variables],
      useFetchData<FetchF2PTouchdownQuery, FetchF2PTouchdownQueryVariables>(FetchF2PTouchdownDocument).bind(null, variables),
      options
    );

useFetchF2PTouchdownQuery.getKey = (variables: FetchF2PTouchdownQueryVariables) => ['FetchF2PTouchdown', variables];
;

export const FetchF2PTouchdownByDateDocument = `
    query FetchF2PTouchdownByDate($data: FetchF2PTouchdownByDateInputDTO!) {
  fetchF2PTouchdownByDate(data: $data) {
    prizePoolId
    touchdown {
      touchdownId
      touchdownName
    }
  }
}
    `;
export const useFetchF2PTouchdownByDateQuery = <
      TData = FetchF2PTouchdownByDateQuery,
      TError = unknown
    >(
      variables: FetchF2PTouchdownByDateQueryVariables,
      options?: UseQueryOptions<FetchF2PTouchdownByDateQuery, TError, TData>
    ) =>
    useQuery<FetchF2PTouchdownByDateQuery, TError, TData>(
      ['FetchF2PTouchdownByDate', variables],
      useFetchData<FetchF2PTouchdownByDateQuery, FetchF2PTouchdownByDateQueryVariables>(FetchF2PTouchdownByDateDocument).bind(null, variables),
      options
    );

useFetchF2PTouchdownByDateQuery.getKey = (variables: FetchF2PTouchdownByDateQueryVariables) => ['FetchF2PTouchdownByDate', variables];
;

export const FetchF2PTouchdownByIdDocument = `
    query FetchF2PTouchdownById($touchdownId: Float!) {
  fetchF2PTouchdownById(touchdownId: $touchdownId) {
    id
    name
    startDateTime
    endDateTime
    allowedPick
    createdAt
    updatedAt
    deletedAt
    prizePools {
      startDateTime
      endDateTime
      allowedSports
      lockTime
      maxEntriesPerUser
      totalEntrants
      id
      status
      createdAt
      updatedAt
    }
  }
}
    `;
export const useFetchF2PTouchdownByIdQuery = <
      TData = FetchF2PTouchdownByIdQuery,
      TError = unknown
    >(
      variables: FetchF2PTouchdownByIdQueryVariables,
      options?: UseQueryOptions<FetchF2PTouchdownByIdQuery, TError, TData>
    ) =>
    useQuery<FetchF2PTouchdownByIdQuery, TError, TData>(
      ['FetchF2PTouchdownById', variables],
      useFetchData<FetchF2PTouchdownByIdQuery, FetchF2PTouchdownByIdQueryVariables>(FetchF2PTouchdownByIdDocument).bind(null, variables),
      options
    );

useFetchF2PTouchdownByIdQuery.getKey = (variables: FetchF2PTouchdownByIdQueryVariables) => ['FetchF2PTouchdownById', variables];
;

export const FetchHubContestsDocument = `
    query FetchHubContests($date: String!, $sportId: Int!) {
  fetchHubContests(date: $date, sportId: $sportId) {
    id
    player1FullName
    player1PhotoUrl
    player1BonusPoints
    player1Game
    player1GameStartTime
    player2FullName
    player2PhotoUrl
    player2BonusPoints
    player2Game
    player2GameStartTime
    published
    player1FantasyPoints
    player2FantasyPoints
    winnerLabel
    status
    createdAt
    updatedAt
    questionId {
      id
      question
      metric
    }
    sportId {
      id
      name
      logoUrl
      winCriteria
      enabled
    }
  }
}
    `;
export const useFetchHubContestsQuery = <
      TData = FetchHubContestsQuery,
      TError = unknown
    >(
      variables: FetchHubContestsQueryVariables,
      options?: UseQueryOptions<FetchHubContestsQuery, TError, TData>
    ) =>
    useQuery<FetchHubContestsQuery, TError, TData>(
      ['FetchHubContests', variables],
      useFetchData<FetchHubContestsQuery, FetchHubContestsQueryVariables>(FetchHubContestsDocument).bind(null, variables),
      options
    );

useFetchHubContestsQuery.getKey = (variables: FetchHubContestsQueryVariables) => ['FetchHubContests', variables];
;

export const FetchPartnerContestsDocument = `
    query FetchPartnerContests($date: String!, $partnerId: Int!, $sportId: Int, $prizePoolId: Int!) {
  fetchPartnerContests(
    date: $date
    partnerId: $partnerId
    sportId: $sportId
    prizePoolId: $prizePoolId
  ) {
    id
    orderIndex
    player1FullName
    player1PhotoUrl
    player1BonusPoints
    player2FullName
    player2PhotoUrl
    player2BonusPoints
    matchDate
    matchDateStartTime
    sportName
    type
    metric
    createdAt
    commonContestId
  }
}
    `;
export const useFetchPartnerContestsQuery = <
      TData = FetchPartnerContestsQuery,
      TError = unknown
    >(
      variables: FetchPartnerContestsQueryVariables,
      options?: UseQueryOptions<FetchPartnerContestsQuery, TError, TData>
    ) =>
    useQuery<FetchPartnerContestsQuery, TError, TData>(
      ['FetchPartnerContests', variables],
      useFetchData<FetchPartnerContestsQuery, FetchPartnerContestsQueryVariables>(FetchPartnerContestsDocument).bind(null, variables),
      options
    );

useFetchPartnerContestsQuery.getKey = (variables: FetchPartnerContestsQueryVariables) => ['FetchPartnerContests', variables];
;

export const FetchPartnerHubContestsDocument = `
    query FetchPartnerHubContests($sportId: Int!, $date: String!, $prizePoolId: Int!) {
  fetchPartnerHubContests(
    sportId: $sportId
    date: $date
    prizePoolId: $prizePoolId
  ) {
    id
    player1FullName
    player1PhotoUrl
    player1BonusPoints
    player1Game
    player1GameStartTime
    player2FullName
    player2PhotoUrl
    player2BonusPoints
    player2Game
    player2GameStartTime
    published
    player1FantasyPoints
    player2FantasyPoints
    winnerLabel
    status
    createdAt
    updatedAt
    questionId {
      id
      question
      metric
    }
  }
}
    `;
export const useFetchPartnerHubContestsQuery = <
      TData = FetchPartnerHubContestsQuery,
      TError = unknown
    >(
      variables: FetchPartnerHubContestsQueryVariables,
      options?: UseQueryOptions<FetchPartnerHubContestsQuery, TError, TData>
    ) =>
    useQuery<FetchPartnerHubContestsQuery, TError, TData>(
      ['FetchPartnerHubContests', variables],
      useFetchData<FetchPartnerHubContestsQuery, FetchPartnerHubContestsQueryVariables>(FetchPartnerHubContestsDocument).bind(null, variables),
      options
    );

useFetchPartnerHubContestsQuery.getKey = (variables: FetchPartnerHubContestsQueryVariables) => ['FetchPartnerHubContests', variables];
;

export const FetchPartnerTouchdownDocument = `
    query FetchPartnerTouchdown($data: PartnerFetchTouchdownInputDTO!) {
  fetchPartnerTouchdown(data: $data) {
    page
    limit
    totalCount
    data {
      id
      name
      startDateTime
      endDateTime
      allowedPick
      entryFees
      createdAt
      updatedAt
      deletedAt
      prizePools {
        startDateTime
        endDateTime
        allowedSports
        lockTime
        maxEntriesPerUser
        totalEntrants
        totalPrize
        jackpotAmount
        gppAmount
        contestVig
        id
        status
        isRollsOver
        weeklyReserveAmount
        predetermineJackpotAmount
        rolloverAmount
        rolloverJackpotAmount
        rolloverVig
        createdAt
        updatedAt
      }
    }
  }
}
    `;
export const useFetchPartnerTouchdownQuery = <
      TData = FetchPartnerTouchdownQuery,
      TError = unknown
    >(
      variables: FetchPartnerTouchdownQueryVariables,
      options?: UseQueryOptions<FetchPartnerTouchdownQuery, TError, TData>
    ) =>
    useQuery<FetchPartnerTouchdownQuery, TError, TData>(
      ['FetchPartnerTouchdown', variables],
      useFetchData<FetchPartnerTouchdownQuery, FetchPartnerTouchdownQueryVariables>(FetchPartnerTouchdownDocument).bind(null, variables),
      options
    );

useFetchPartnerTouchdownQuery.getKey = (variables: FetchPartnerTouchdownQueryVariables) => ['FetchPartnerTouchdown', variables];
;

export const FetchPartnerTouchdownByDateDocument = `
    query FetchPartnerTouchdownByDate($data: FetchPartnerTouchdownByDateInputDTO!) {
  fetchPartnerTouchdownByDate(data: $data) {
    prizePoolId
    prizePoolStatus
    touchdown {
      touchdownId
      touchdownName
    }
  }
}
    `;
export const useFetchPartnerTouchdownByDateQuery = <
      TData = FetchPartnerTouchdownByDateQuery,
      TError = unknown
    >(
      variables: FetchPartnerTouchdownByDateQueryVariables,
      options?: UseQueryOptions<FetchPartnerTouchdownByDateQuery, TError, TData>
    ) =>
    useQuery<FetchPartnerTouchdownByDateQuery, TError, TData>(
      ['FetchPartnerTouchdownByDate', variables],
      useFetchData<FetchPartnerTouchdownByDateQuery, FetchPartnerTouchdownByDateQueryVariables>(FetchPartnerTouchdownByDateDocument).bind(null, variables),
      options
    );

useFetchPartnerTouchdownByDateQuery.getKey = (variables: FetchPartnerTouchdownByDateQueryVariables) => ['FetchPartnerTouchdownByDate', variables];
;

export const FetchPartnerTouchdownByIdDocument = `
    query FetchPartnerTouchdownById($touchdownId: Float!) {
  fetchPartnerTouchdownById(touchdownId: $touchdownId) {
    id
    name
    startDateTime
    endDateTime
    allowedPick
    entryFees
    createdAt
    updatedAt
    deletedAt
    prizePools {
      startDateTime
      endDateTime
      allowedSports
      lockTime
      maxEntriesPerUser
      totalEntrants
      totalPrize
      jackpotAmount
      gppAmount
      contestVig
      id
      status
      isRollsOver
      weeklyReserveAmount
      predetermineJackpotAmount
      rolloverAmount
      rolloverJackpotAmount
      rolloverVig
      createdAt
      updatedAt
    }
  }
}
    `;
export const useFetchPartnerTouchdownByIdQuery = <
      TData = FetchPartnerTouchdownByIdQuery,
      TError = unknown
    >(
      variables: FetchPartnerTouchdownByIdQueryVariables,
      options?: UseQueryOptions<FetchPartnerTouchdownByIdQuery, TError, TData>
    ) =>
    useQuery<FetchPartnerTouchdownByIdQuery, TError, TData>(
      ['FetchPartnerTouchdownById', variables],
      useFetchData<FetchPartnerTouchdownByIdQuery, FetchPartnerTouchdownByIdQueryVariables>(FetchPartnerTouchdownByIdDocument).bind(null, variables),
      options
    );

useFetchPartnerTouchdownByIdQuery.getKey = (variables: FetchPartnerTouchdownByIdQueryVariables) => ['FetchPartnerTouchdownById', variables];
;

export const GetAllContestsDocument = `
    query GetAllContests($data: ContestsReportInputType!) {
  getAllContests(data: $data) {
    id
    createdAt
    entryAmount
    endTime
    winnerId
    contestType
    creatorUser {
      id
      fullName
      email
    }
    claimerUser {
      id
      fullName
      email
    }
    creatorPlayer {
      playerId
      firstName
      lastName
    }
    claimerPlayer {
      playerId
      firstName
      lastName
    }
  }
}
    `;
export const useGetAllContestsQuery = <
      TData = GetAllContestsQuery,
      TError = unknown
    >(
      variables: GetAllContestsQueryVariables,
      options?: UseQueryOptions<GetAllContestsQuery, TError, TData>
    ) =>
    useQuery<GetAllContestsQuery, TError, TData>(
      ['GetAllContests', variables],
      useFetchData<GetAllContestsQuery, GetAllContestsQueryVariables>(GetAllContestsDocument).bind(null, variables),
      options
    );

useGetAllContestsQuery.getKey = (variables: GetAllContestsQueryVariables) => ['GetAllContests', variables];
;

export const GetAllF2PPartnerDocument = `
    query GetAllF2PPartner {
  getAllF2PPartner {
    id
    name
    email
    contact
    identifier
    logo
    description
    primaryColor
    secondaryColor
    active
    createdBy
    userCount
    totalTouchdownCount
    enableUserAuth
  }
}
    `;
export const useGetAllF2PPartnerQuery = <
      TData = GetAllF2PPartnerQuery,
      TError = unknown
    >(
      variables?: GetAllF2PPartnerQueryVariables,
      options?: UseQueryOptions<GetAllF2PPartnerQuery, TError, TData>
    ) =>
    useQuery<GetAllF2PPartnerQuery, TError, TData>(
      variables === undefined ? ['GetAllF2PPartner'] : ['GetAllF2PPartner', variables],
      useFetchData<GetAllF2PPartnerQuery, GetAllF2PPartnerQueryVariables>(GetAllF2PPartnerDocument).bind(null, variables),
      options
    );

useGetAllF2PPartnerQuery.getKey = (variables?: GetAllF2PPartnerQueryVariables) => variables === undefined ? ['GetAllF2PPartner'] : ['GetAllF2PPartner', variables];
;

export const GetAllNflPlayersDocument = `
    query GetAllNflPlayers {
  getAllNflPlayers {
    id
    remoteId
    fullName
  }
}
    `;
export const useGetAllNflPlayersQuery = <
      TData = GetAllNflPlayersQuery,
      TError = unknown
    >(
      variables?: GetAllNflPlayersQueryVariables,
      options?: UseQueryOptions<GetAllNflPlayersQuery, TError, TData>
    ) =>
    useQuery<GetAllNflPlayersQuery, TError, TData>(
      variables === undefined ? ['GetAllNflPlayers'] : ['GetAllNflPlayers', variables],
      useFetchData<GetAllNflPlayersQuery, GetAllNflPlayersQueryVariables>(GetAllNflPlayersDocument).bind(null, variables),
      options
    );

useGetAllNflPlayersQuery.getKey = (variables?: GetAllNflPlayersQueryVariables) => variables === undefined ? ['GetAllNflPlayers'] : ['GetAllNflPlayers', variables];
;

export const GetAllPartnerDocument = `
    query GetAllPartner {
  getAllPartner {
    id
    name
    email
    contact
    active
    iframeKey
    secretKey
    userCount
    totalTouchdownCount
    logo
    primaryColor
    secondaryColor
    identifier
    apiKeys {
      id
      apiKey
      createdBy
      active
      expiredAt
      createdAt
    }
    admins {
      id
      fullName
      email
      phone
      active
    }
  }
}
    `;
export const useGetAllPartnerQuery = <
      TData = GetAllPartnerQuery,
      TError = unknown
    >(
      variables?: GetAllPartnerQueryVariables,
      options?: UseQueryOptions<GetAllPartnerQuery, TError, TData>
    ) =>
    useQuery<GetAllPartnerQuery, TError, TData>(
      variables === undefined ? ['GetAllPartner'] : ['GetAllPartner', variables],
      useFetchData<GetAllPartnerQuery, GetAllPartnerQueryVariables>(GetAllPartnerDocument).bind(null, variables),
      options
    );

useGetAllPartnerQuery.getKey = (variables?: GetAllPartnerQueryVariables) => variables === undefined ? ['GetAllPartner'] : ['GetAllPartner', variables];
;

export const GetAllPermissionsDocument = `
    query GetAllPermissions {
  getAllPermissions
}
    `;
export const useGetAllPermissionsQuery = <
      TData = GetAllPermissionsQuery,
      TError = unknown
    >(
      variables?: GetAllPermissionsQueryVariables,
      options?: UseQueryOptions<GetAllPermissionsQuery, TError, TData>
    ) =>
    useQuery<GetAllPermissionsQuery, TError, TData>(
      variables === undefined ? ['GetAllPermissions'] : ['GetAllPermissions', variables],
      useFetchData<GetAllPermissionsQuery, GetAllPermissionsQueryVariables>(GetAllPermissionsDocument).bind(null, variables),
      options
    );

useGetAllPermissionsQuery.getKey = (variables?: GetAllPermissionsQueryVariables) => variables === undefined ? ['GetAllPermissions'] : ['GetAllPermissions', variables];
;

export const GetAuditsDocument = `
    query GetAudits($data: AuditActionInputType!) {
  getAudits(data: $data) {
    id
    createdAt
    action
    payload
    user {
      email
    }
  }
}
    `;
export const useGetAuditsQuery = <
      TData = GetAuditsQuery,
      TError = unknown
    >(
      variables: GetAuditsQueryVariables,
      options?: UseQueryOptions<GetAuditsQuery, TError, TData>
    ) =>
    useQuery<GetAuditsQuery, TError, TData>(
      ['GetAudits', variables],
      useFetchData<GetAuditsQuery, GetAuditsQueryVariables>(GetAuditsDocument).bind(null, variables),
      options
    );

useGetAuditsQuery.getKey = (variables: GetAuditsQueryVariables) => ['GetAudits', variables];
;

export const GetCommonHubContestDocument = `
    query GetCommonHubContest($date: String!, $sportId: Int!) {
  getCommonHubContest(date: $date, sportId: $sportId) {
    id
    player1Id
    player1FullName
    player1PhotoUrl
    player1BonusPoints
    player1Game
    player1GameStartTime
    player1FantasyPoints
    player2Id
    player2FullName
    player2PhotoUrl
    player2BonusPoints
    player2Game
    player2GameStartTime
    player2FantasyPoints
    status
    winnerPlayerId
    winnerLabel
    contestDate
    questionId {
      id
      question
      metric
    }
  }
}
    `;
export const useGetCommonHubContestQuery = <
      TData = GetCommonHubContestQuery,
      TError = unknown
    >(
      variables: GetCommonHubContestQueryVariables,
      options?: UseQueryOptions<GetCommonHubContestQuery, TError, TData>
    ) =>
    useQuery<GetCommonHubContestQuery, TError, TData>(
      ['GetCommonHubContest', variables],
      useFetchData<GetCommonHubContestQuery, GetCommonHubContestQueryVariables>(GetCommonHubContestDocument).bind(null, variables),
      options
    );

useGetCommonHubContestQuery.getKey = (variables: GetCommonHubContestQueryVariables) => ['GetCommonHubContest', variables];
;

export const GetCommonNbaTouchdownContestPlayersDocument = `
    query GetCommonNbaTouchdownContestPlayers($date: String!) {
  getCommonNbaTouchdownContestPlayers(date: $date) {
    id
    remoteId
    firstName
    lastName
    fullName
    shortName
    injuryStatus
    photoUrl
    photoUrlHiRes
    available
    position
    teamName
    opponentName
    homeOrAway
    hasStarted
    isOver
    fantasyPoints
    fantasyPointsHalfPpr
    fantasyPointsFullPpr
    projectedFantasyPoints
    projectedFantasyPointsHalfPpr
    teamId
    yahooPlayerId
    espnPlayerId
    gameId
    gameStartTime
  }
}
    `;
export const useGetCommonNbaTouchdownContestPlayersQuery = <
      TData = GetCommonNbaTouchdownContestPlayersQuery,
      TError = unknown
    >(
      variables: GetCommonNbaTouchdownContestPlayersQueryVariables,
      options?: UseQueryOptions<GetCommonNbaTouchdownContestPlayersQuery, TError, TData>
    ) =>
    useQuery<GetCommonNbaTouchdownContestPlayersQuery, TError, TData>(
      ['GetCommonNbaTouchdownContestPlayers', variables],
      useFetchData<GetCommonNbaTouchdownContestPlayersQuery, GetCommonNbaTouchdownContestPlayersQueryVariables>(GetCommonNbaTouchdownContestPlayersDocument).bind(null, variables),
      options
    );

useGetCommonNbaTouchdownContestPlayersQuery.getKey = (variables: GetCommonNbaTouchdownContestPlayersQueryVariables) => ['GetCommonNbaTouchdownContestPlayers', variables];
;

export const GetCommonNbaTouchdownContestsDocument = `
    query GetCommonNbaTouchdownContests($date: String!) {
  getCommonNbaTouchdownContests(date: $date) {
    id
    player1BonusPoints
    player2BonusPoints
    status
    player1 {
      id
      remoteId
      firstName
      lastName
      fullName
      shortName
      injuryStatus
      photoUrl
      photoUrlHiRes
      available
      position
      teamName
      opponentName
      homeOrAway
      hasStarted
      isOver
      fantasyPoints
      fantasyPointsHalfPpr
      fantasyPointsFullPpr
      projectedFantasyPoints
      projectedFantasyPointsHalfPpr
      teamId
      yahooPlayerId
      espnPlayerId
      gameId
      gameStartTime
    }
    player2 {
      id
      remoteId
      firstName
      lastName
      fullName
      shortName
      injuryStatus
      photoUrl
      photoUrlHiRes
      available
      position
      teamName
      opponentName
      homeOrAway
      hasStarted
      isOver
      fantasyPoints
      fantasyPointsHalfPpr
      fantasyPointsFullPpr
      projectedFantasyPoints
      projectedFantasyPointsHalfPpr
      teamId
      yahooPlayerId
      espnPlayerId
      gameId
      gameStartTime
    }
  }
}
    `;
export const useGetCommonNbaTouchdownContestsQuery = <
      TData = GetCommonNbaTouchdownContestsQuery,
      TError = unknown
    >(
      variables: GetCommonNbaTouchdownContestsQueryVariables,
      options?: UseQueryOptions<GetCommonNbaTouchdownContestsQuery, TError, TData>
    ) =>
    useQuery<GetCommonNbaTouchdownContestsQuery, TError, TData>(
      ['GetCommonNbaTouchdownContests', variables],
      useFetchData<GetCommonNbaTouchdownContestsQuery, GetCommonNbaTouchdownContestsQueryVariables>(GetCommonNbaTouchdownContestsDocument).bind(null, variables),
      options
    );

useGetCommonNbaTouchdownContestsQuery.getKey = (variables: GetCommonNbaTouchdownContestsQueryVariables) => ['GetCommonNbaTouchdownContests', variables];
;

export const GetCommonSoccerTouchdownContestPlayersDocument = `
    query GetCommonSoccerTouchdownContestPlayers($date: String!) {
  getCommonSoccerTouchdownContestPlayers(date: $date) {
    id
    remoteId
    firstName
    lastName
    fullName
    shortName
    commonName
    injuryStatus
    photoUrl
    position
    positionCategory
    teamName
    opponentName
    homeOrAway
    hasStarted
    isOver
    shots
    saves
    passes
    shotsOnGoal
    goals
    assists
    tackles
    gameId
    gameStartTime
    projectedShots
    projectedSaves
    projectedPasses
    projectedShotsOnGoal
    projectedGoals
    projectedAssists
    projectedTackles
  }
}
    `;
export const useGetCommonSoccerTouchdownContestPlayersQuery = <
      TData = GetCommonSoccerTouchdownContestPlayersQuery,
      TError = unknown
    >(
      variables: GetCommonSoccerTouchdownContestPlayersQueryVariables,
      options?: UseQueryOptions<GetCommonSoccerTouchdownContestPlayersQuery, TError, TData>
    ) =>
    useQuery<GetCommonSoccerTouchdownContestPlayersQuery, TError, TData>(
      ['GetCommonSoccerTouchdownContestPlayers', variables],
      useFetchData<GetCommonSoccerTouchdownContestPlayersQuery, GetCommonSoccerTouchdownContestPlayersQueryVariables>(GetCommonSoccerTouchdownContestPlayersDocument).bind(null, variables),
      options
    );

useGetCommonSoccerTouchdownContestPlayersQuery.getKey = (variables: GetCommonSoccerTouchdownContestPlayersQueryVariables) => ['GetCommonSoccerTouchdownContestPlayers', variables];
;

export const GetCommonSoccerTouchdownContestsDocument = `
    query GetCommonSoccerTouchdownContests($date: String!) {
  getCommonSoccerTouchdownContests(date: $date) {
    id
    player1BonusPoints
    player2BonusPoints
    metric
    status
    player1 {
      id
      remoteId
      firstName
      lastName
      fullName
      shortName
      commonName
      injuryStatus
      photoUrl
      position
      positionCategory
      teamName
      opponentName
      homeOrAway
      hasStarted
      isOver
      shots
      saves
      passes
      shotsOnGoal
      goals
      assists
      tackles
      gameId
      gameStartTime
      projectedShots
      projectedSaves
      projectedPasses
      projectedShotsOnGoal
      projectedGoals
      projectedAssists
      projectedTackles
    }
    player2 {
      id
      remoteId
      firstName
      lastName
      fullName
      shortName
      commonName
      injuryStatus
      photoUrl
      position
      positionCategory
      teamName
      opponentName
      homeOrAway
      hasStarted
      isOver
      shots
      saves
      passes
      shotsOnGoal
      goals
      assists
      tackles
      gameId
      gameStartTime
      projectedShots
      projectedSaves
      projectedPasses
      projectedShotsOnGoal
      projectedGoals
      projectedAssists
      projectedTackles
    }
  }
}
    `;
export const useGetCommonSoccerTouchdownContestsQuery = <
      TData = GetCommonSoccerTouchdownContestsQuery,
      TError = unknown
    >(
      variables: GetCommonSoccerTouchdownContestsQueryVariables,
      options?: UseQueryOptions<GetCommonSoccerTouchdownContestsQuery, TError, TData>
    ) =>
    useQuery<GetCommonSoccerTouchdownContestsQuery, TError, TData>(
      ['GetCommonSoccerTouchdownContests', variables],
      useFetchData<GetCommonSoccerTouchdownContestsQuery, GetCommonSoccerTouchdownContestsQueryVariables>(GetCommonSoccerTouchdownContestsDocument).bind(null, variables),
      options
    );

useGetCommonSoccerTouchdownContestsQuery.getKey = (variables: GetCommonSoccerTouchdownContestsQueryVariables) => ['GetCommonSoccerTouchdownContests', variables];
;

export const GetCommonSportDocument = `
    query GetCommonSport {
  getCommonSport {
    id
    name
    key
    logoUrl
    winCriteria
    questions {
      id
      question
      metric
    }
  }
}
    `;
export const useGetCommonSportQuery = <
      TData = GetCommonSportQuery,
      TError = unknown
    >(
      variables?: GetCommonSportQueryVariables,
      options?: UseQueryOptions<GetCommonSportQuery, TError, TData>
    ) =>
    useQuery<GetCommonSportQuery, TError, TData>(
      variables === undefined ? ['GetCommonSport'] : ['GetCommonSport', variables],
      useFetchData<GetCommonSportQuery, GetCommonSportQueryVariables>(GetCommonSportDocument).bind(null, variables),
      options
    );

useGetCommonSportQuery.getKey = (variables?: GetCommonSportQueryVariables) => variables === undefined ? ['GetCommonSport'] : ['GetCommonSport', variables];
;

export const GetCouponsDocument = `
    query GetCoupons {
  getCoupons {
    id
    status
    code
    value
    createdAt
  }
}
    `;
export const useGetCouponsQuery = <
      TData = GetCouponsQuery,
      TError = unknown
    >(
      variables?: GetCouponsQueryVariables,
      options?: UseQueryOptions<GetCouponsQuery, TError, TData>
    ) =>
    useQuery<GetCouponsQuery, TError, TData>(
      variables === undefined ? ['GetCoupons'] : ['GetCoupons', variables],
      useFetchData<GetCouponsQuery, GetCouponsQueryVariables>(GetCouponsDocument).bind(null, variables),
      options
    );

useGetCouponsQuery.getKey = (variables?: GetCouponsQueryVariables) => variables === undefined ? ['GetCoupons'] : ['GetCoupons', variables];
;

export const GetCurrentTournamentDocument = `
    query GetCurrentTournament {
  getCurrentTournament {
    remoteId
    name
    startDate
  }
}
    `;
export const useGetCurrentTournamentQuery = <
      TData = GetCurrentTournamentQuery,
      TError = unknown
    >(
      variables?: GetCurrentTournamentQueryVariables,
      options?: UseQueryOptions<GetCurrentTournamentQuery, TError, TData>
    ) =>
    useQuery<GetCurrentTournamentQuery, TError, TData>(
      variables === undefined ? ['GetCurrentTournament'] : ['GetCurrentTournament', variables],
      useFetchData<GetCurrentTournamentQuery, GetCurrentTournamentQueryVariables>(GetCurrentTournamentDocument).bind(null, variables),
      options
    );

useGetCurrentTournamentQuery.getKey = (variables?: GetCurrentTournamentQueryVariables) => variables === undefined ? ['GetCurrentTournament'] : ['GetCurrentTournament', variables];
;

export const GetCurrentTournamentPlayersDocument = `
    mutation GetCurrentTournamentPlayers {
  getCurrentTournamentPlayers {
    success
  }
}
    `;
export const useGetCurrentTournamentPlayersMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<GetCurrentTournamentPlayersMutation, TError, GetCurrentTournamentPlayersMutationVariables, TContext>) =>
    useMutation<GetCurrentTournamentPlayersMutation, TError, GetCurrentTournamentPlayersMutationVariables, TContext>(
      ['GetCurrentTournamentPlayers'],
      useFetchData<GetCurrentTournamentPlayersMutation, GetCurrentTournamentPlayersMutationVariables>(GetCurrentTournamentPlayersDocument),
      options
    );
export const GetDailyLeaderBoardDocument = `
    query GetDailyLeaderBoard($prizePoolId: Int!, $searchStr: String!) {
  getDailyLeaderBoard(prizePoolId: $prizePoolId, searchStr: $searchStr) {
    page
    limit
    totalCount
    data {
      id
      rank
      leaderboardRank
      winAmount
      name
      score
      status
      payout
      entryId
      entryNumber
    }
  }
}
    `;
export const useGetDailyLeaderBoardQuery = <
      TData = GetDailyLeaderBoardQuery,
      TError = unknown
    >(
      variables: GetDailyLeaderBoardQueryVariables,
      options?: UseQueryOptions<GetDailyLeaderBoardQuery, TError, TData>
    ) =>
    useQuery<GetDailyLeaderBoardQuery, TError, TData>(
      ['GetDailyLeaderBoard', variables],
      useFetchData<GetDailyLeaderBoardQuery, GetDailyLeaderBoardQueryVariables>(GetDailyLeaderBoardDocument).bind(null, variables),
      options
    );

useGetDailyLeaderBoardQuery.getKey = (variables: GetDailyLeaderBoardQueryVariables) => ['GetDailyLeaderBoard', variables];
;

export const GetDailyReportDocument = `
    query GetDailyReport {
  getDailyReport {
    amountAddedSystemCount
    battlegroundContestCount
    userCount
  }
}
    `;
export const useGetDailyReportQuery = <
      TData = GetDailyReportQuery,
      TError = unknown
    >(
      variables?: GetDailyReportQueryVariables,
      options?: UseQueryOptions<GetDailyReportQuery, TError, TData>
    ) =>
    useQuery<GetDailyReportQuery, TError, TData>(
      variables === undefined ? ['GetDailyReport'] : ['GetDailyReport', variables],
      useFetchData<GetDailyReportQuery, GetDailyReportQueryVariables>(GetDailyReportDocument).bind(null, variables),
      options
    );

useGetDailyReportQuery.getKey = (variables?: GetDailyReportQueryVariables) => variables === undefined ? ['GetDailyReport'] : ['GetDailyReport', variables];
;

export const GetF2PActiveTouchdownsDocument = `
    query GetF2PActiveTouchdowns($partnerId: Int!) {
  getF2PActiveTouchdowns(partnerId: $partnerId) {
    id
    name
    startDateTime
    endDateTime
    allowedPick
    prizePools {
      startDateTime
      endDateTime
      allowedSports
      lockTime
      maxEntriesPerUser
      totalEntrants
      id
      status
      createdAt
      updatedAt
      userEntryCount
      nbaContestCount
      nflContestCount
      soccerContestCount
      hubContestCount
    }
  }
}
    `;
export const useGetF2PActiveTouchdownsQuery = <
      TData = GetF2PActiveTouchdownsQuery,
      TError = unknown
    >(
      variables: GetF2PActiveTouchdownsQueryVariables,
      options?: UseQueryOptions<GetF2PActiveTouchdownsQuery, TError, TData>
    ) =>
    useQuery<GetF2PActiveTouchdownsQuery, TError, TData>(
      ['GetF2PActiveTouchdowns', variables],
      useFetchData<GetF2PActiveTouchdownsQuery, GetF2PActiveTouchdownsQueryVariables>(GetF2PActiveTouchdownsDocument).bind(null, variables),
      options
    );

useGetF2PActiveTouchdownsQuery.getKey = (variables: GetF2PActiveTouchdownsQueryVariables) => ['GetF2PActiveTouchdowns', variables];
;

export const GetF2PDailyLeaderBoardDocument = `
    query GetF2PDailyLeaderBoard($prizePoolId: Int!) {
  getF2PDailyLeaderBoard(prizePoolId: $prizePoolId) {
    startDateTime
    endDateTime
    entries {
      entryId
      userId
      name
      score
      rank
      leaderBoardRank
    }
  }
}
    `;
export const useGetF2PDailyLeaderBoardQuery = <
      TData = GetF2PDailyLeaderBoardQuery,
      TError = unknown
    >(
      variables: GetF2PDailyLeaderBoardQueryVariables,
      options?: UseQueryOptions<GetF2PDailyLeaderBoardQuery, TError, TData>
    ) =>
    useQuery<GetF2PDailyLeaderBoardQuery, TError, TData>(
      ['GetF2PDailyLeaderBoard', variables],
      useFetchData<GetF2PDailyLeaderBoardQuery, GetF2PDailyLeaderBoardQueryVariables>(GetF2PDailyLeaderBoardDocument).bind(null, variables),
      options
    );

useGetF2PDailyLeaderBoardQuery.getKey = (variables: GetF2PDailyLeaderBoardQueryVariables) => ['GetF2PDailyLeaderBoard', variables];
;

export const GetF2PManualSportsDocument = `
    query GetF2PManualSports($partnerId: Int!) {
  getF2PManualSports(partnerId: $partnerId) {
    id
    name
    logoUrl
    winCriteria
    enabled
    orderIndex
    partnerId
  }
}
    `;
export const useGetF2PManualSportsQuery = <
      TData = GetF2PManualSportsQuery,
      TError = unknown
    >(
      variables: GetF2PManualSportsQueryVariables,
      options?: UseQueryOptions<GetF2PManualSportsQuery, TError, TData>
    ) =>
    useQuery<GetF2PManualSportsQuery, TError, TData>(
      ['GetF2PManualSports', variables],
      useFetchData<GetF2PManualSportsQuery, GetF2PManualSportsQueryVariables>(GetF2PManualSportsDocument).bind(null, variables),
      options
    );

useGetF2PManualSportsQuery.getKey = (variables: GetF2PManualSportsQueryVariables) => ['GetF2PManualSports', variables];
;

export const GetF2PNbaTouchdownContestPlayersDocument = `
    query GetF2PNbaTouchdownContestPlayers($date: String!) {
  getF2PNbaTouchdownContestPlayers(date: $date) {
    id
    remoteId
    fullName
    projectedFantasyPoints
    gameId
    gameStartTime
  }
}
    `;
export const useGetF2PNbaTouchdownContestPlayersQuery = <
      TData = GetF2PNbaTouchdownContestPlayersQuery,
      TError = unknown
    >(
      variables: GetF2PNbaTouchdownContestPlayersQueryVariables,
      options?: UseQueryOptions<GetF2PNbaTouchdownContestPlayersQuery, TError, TData>
    ) =>
    useQuery<GetF2PNbaTouchdownContestPlayersQuery, TError, TData>(
      ['GetF2PNbaTouchdownContestPlayers', variables],
      useFetchData<GetF2PNbaTouchdownContestPlayersQuery, GetF2PNbaTouchdownContestPlayersQueryVariables>(GetF2PNbaTouchdownContestPlayersDocument).bind(null, variables),
      options
    );

useGetF2PNbaTouchdownContestPlayersQuery.getKey = (variables: GetF2PNbaTouchdownContestPlayersQueryVariables) => ['GetF2PNbaTouchdownContestPlayers', variables];
;

export const GetF2PNbaTouchdownContestsDocument = `
    query GetF2PNbaTouchdownContests($date: String!, $prizePoolId: Int!) {
  getF2PNbaTouchdownContests(date: $date, prizePoolId: $prizePoolId) {
    id
    player1BonusPoints
    player2BonusPoints
    status
    player1 {
      id
      remoteId
      firstName
      lastName
      photoUrl
      injuryStatus
    }
    player2 {
      id
      remoteId
      firstName
      lastName
      photoUrl
      injuryStatus
    }
    availableInTouchdowns {
      name
      prizePoolId
    }
  }
}
    `;
export const useGetF2PNbaTouchdownContestsQuery = <
      TData = GetF2PNbaTouchdownContestsQuery,
      TError = unknown
    >(
      variables: GetF2PNbaTouchdownContestsQueryVariables,
      options?: UseQueryOptions<GetF2PNbaTouchdownContestsQuery, TError, TData>
    ) =>
    useQuery<GetF2PNbaTouchdownContestsQuery, TError, TData>(
      ['GetF2PNbaTouchdownContests', variables],
      useFetchData<GetF2PNbaTouchdownContestsQuery, GetF2PNbaTouchdownContestsQueryVariables>(GetF2PNbaTouchdownContestsDocument).bind(null, variables),
      options
    );

useGetF2PNbaTouchdownContestsQuery.getKey = (variables: GetF2PNbaTouchdownContestsQueryVariables) => ['GetF2PNbaTouchdownContests', variables];
;

export const GetF2PPartnerByIdDocument = `
    query GetF2PPartnerById($partnerId: Int!) {
  getF2PPartnerById(partnerId: $partnerId) {
    id
    name
    email
    contact
    identifier
    logo
    description
    primaryColor
    secondaryColor
    active
    createdBy
  }
}
    `;
export const useGetF2PPartnerByIdQuery = <
      TData = GetF2PPartnerByIdQuery,
      TError = unknown
    >(
      variables: GetF2PPartnerByIdQueryVariables,
      options?: UseQueryOptions<GetF2PPartnerByIdQuery, TError, TData>
    ) =>
    useQuery<GetF2PPartnerByIdQuery, TError, TData>(
      ['GetF2PPartnerById', variables],
      useFetchData<GetF2PPartnerByIdQuery, GetF2PPartnerByIdQueryVariables>(GetF2PPartnerByIdDocument).bind(null, variables),
      options
    );

useGetF2PPartnerByIdQuery.getKey = (variables: GetF2PPartnerByIdQueryVariables) => ['GetF2PPartnerById', variables];
;

export const GetF2PPartnerUsersDocument = `
    query GetF2PPartnerUsers($partnerId: Int!) {
  getF2PPartnerUsers(partnerId: $partnerId) {
    id
    firstName
    lastName
    email
    gender
    userName
  }
}
    `;
export const useGetF2PPartnerUsersQuery = <
      TData = GetF2PPartnerUsersQuery,
      TError = unknown
    >(
      variables: GetF2PPartnerUsersQueryVariables,
      options?: UseQueryOptions<GetF2PPartnerUsersQuery, TError, TData>
    ) =>
    useQuery<GetF2PPartnerUsersQuery, TError, TData>(
      ['GetF2PPartnerUsers', variables],
      useFetchData<GetF2PPartnerUsersQuery, GetF2PPartnerUsersQueryVariables>(GetF2PPartnerUsersDocument).bind(null, variables),
      options
    );

useGetF2PPartnerUsersQuery.getKey = (variables: GetF2PPartnerUsersQueryVariables) => ['GetF2PPartnerUsers', variables];
;

export const GetF2PSoccerTouchdownContestPlayersDocument = `
    query GetF2PSoccerTouchdownContestPlayers($date: String!) {
  getF2PSoccerTouchdownContestPlayers(date: $date) {
    id
    remoteId
    fullName
    gameId
    gameStartTime
    projectedShots
    projectedSaves
    projectedPasses
    projectedShotsOnGoal
    projectedGoals
    projectedAssists
    projectedTackles
  }
}
    `;
export const useGetF2PSoccerTouchdownContestPlayersQuery = <
      TData = GetF2PSoccerTouchdownContestPlayersQuery,
      TError = unknown
    >(
      variables: GetF2PSoccerTouchdownContestPlayersQueryVariables,
      options?: UseQueryOptions<GetF2PSoccerTouchdownContestPlayersQuery, TError, TData>
    ) =>
    useQuery<GetF2PSoccerTouchdownContestPlayersQuery, TError, TData>(
      ['GetF2PSoccerTouchdownContestPlayers', variables],
      useFetchData<GetF2PSoccerTouchdownContestPlayersQuery, GetF2PSoccerTouchdownContestPlayersQueryVariables>(GetF2PSoccerTouchdownContestPlayersDocument).bind(null, variables),
      options
    );

useGetF2PSoccerTouchdownContestPlayersQuery.getKey = (variables: GetF2PSoccerTouchdownContestPlayersQueryVariables) => ['GetF2PSoccerTouchdownContestPlayers', variables];
;

export const GetF2PSoccerTouchdownContestsDocument = `
    query GetF2PSoccerTouchdownContests($date: String!, $prizePoolId: Int!) {
  getF2PSoccerTouchdownContests(date: $date, prizePoolId: $prizePoolId) {
    id
    player1BonusPoints
    player2BonusPoints
    metric
    status
    player1 {
      id
      remoteId
      firstName
      lastName
      photoUrl
      injuryStatus
    }
    player2 {
      id
      remoteId
      firstName
      lastName
      photoUrl
      injuryStatus
    }
  }
}
    `;
export const useGetF2PSoccerTouchdownContestsQuery = <
      TData = GetF2PSoccerTouchdownContestsQuery,
      TError = unknown
    >(
      variables: GetF2PSoccerTouchdownContestsQueryVariables,
      options?: UseQueryOptions<GetF2PSoccerTouchdownContestsQuery, TError, TData>
    ) =>
    useQuery<GetF2PSoccerTouchdownContestsQuery, TError, TData>(
      ['GetF2PSoccerTouchdownContests', variables],
      useFetchData<GetF2PSoccerTouchdownContestsQuery, GetF2PSoccerTouchdownContestsQueryVariables>(GetF2PSoccerTouchdownContestsDocument).bind(null, variables),
      options
    );

useGetF2PSoccerTouchdownContestsQuery.getKey = (variables: GetF2PSoccerTouchdownContestsQueryVariables) => ['GetF2PSoccerTouchdownContests', variables];
;

export const GetF2PSportByNameDocument = `
    query GetF2PSportByName($name: String!, $partnerId: Int!) {
  getF2PSportByName(name: $name, partnerId: $partnerId) {
    id
    name
    logoUrl
    winCriteria
    enabled
    orderIndex
    partnerId
  }
}
    `;
export const useGetF2PSportByNameQuery = <
      TData = GetF2PSportByNameQuery,
      TError = unknown
    >(
      variables: GetF2PSportByNameQueryVariables,
      options?: UseQueryOptions<GetF2PSportByNameQuery, TError, TData>
    ) =>
    useQuery<GetF2PSportByNameQuery, TError, TData>(
      ['GetF2PSportByName', variables],
      useFetchData<GetF2PSportByNameQuery, GetF2PSportByNameQueryVariables>(GetF2PSportByNameDocument).bind(null, variables),
      options
    );

useGetF2PSportByNameQuery.getKey = (variables: GetF2PSportByNameQueryVariables) => ['GetF2PSportByName', variables];
;

export const GetF2PSportsDocument = `
    query GetF2PSports($type: String!, $partnerId: Int!) {
  getF2PSports(partnerId: $partnerId, type: $type) {
    id
    key
    name
    logoUrl
    enabled
    winCriteria
    orderIndex
    questions {
      id
      question
      metric
    }
  }
}
    `;
export const useGetF2PSportsQuery = <
      TData = GetF2PSportsQuery,
      TError = unknown
    >(
      variables: GetF2PSportsQueryVariables,
      options?: UseQueryOptions<GetF2PSportsQuery, TError, TData>
    ) =>
    useQuery<GetF2PSportsQuery, TError, TData>(
      ['GetF2PSports', variables],
      useFetchData<GetF2PSportsQuery, GetF2PSportsQueryVariables>(GetF2PSportsDocument).bind(null, variables),
      options
    );

useGetF2PSportsQuery.getKey = (variables: GetF2PSportsQueryVariables) => ['GetF2PSports', variables];
;

export const GetF2PTouchdownAnalyticsDocument = `
    query GetF2PTouchdownAnalytics($partnerId: Int!) {
  getF2PTouchdownAnalytics(partnerId: $partnerId) {
    totalTouchdowns
    totalActiveTouchdowns
    totalUpcomingTouchdowns
    totalPartners
    totalUsers
    touchdownsPerMonth {
      month
      count
    }
  }
}
    `;
export const useGetF2PTouchdownAnalyticsQuery = <
      TData = GetF2PTouchdownAnalyticsQuery,
      TError = unknown
    >(
      variables: GetF2PTouchdownAnalyticsQueryVariables,
      options?: UseQueryOptions<GetF2PTouchdownAnalyticsQuery, TError, TData>
    ) =>
    useQuery<GetF2PTouchdownAnalyticsQuery, TError, TData>(
      ['GetF2PTouchdownAnalytics', variables],
      useFetchData<GetF2PTouchdownAnalyticsQuery, GetF2PTouchdownAnalyticsQueryVariables>(GetF2PTouchdownAnalyticsDocument).bind(null, variables),
      options
    );

useGetF2PTouchdownAnalyticsQuery.getKey = (variables: GetF2PTouchdownAnalyticsQueryVariables) => ['GetF2PTouchdownAnalytics', variables];
;

export const GetFeaturedContestsDocument = `
    query GetFeaturedContests {
  getFeaturedContests {
    id
    sportType
    isFeaturedContest
    playerOne {
      fullName
      firstName
      lastName
      photoUrl
      photoUrlHiRes
    }
    playerTwo {
      fullName
      firstName
      lastName
      photoUrl
      photoUrlHiRes
    }
  }
}
    `;
export const useGetFeaturedContestsQuery = <
      TData = GetFeaturedContestsQuery,
      TError = unknown
    >(
      variables?: GetFeaturedContestsQueryVariables,
      options?: UseQueryOptions<GetFeaturedContestsQuery, TError, TData>
    ) =>
    useQuery<GetFeaturedContestsQuery, TError, TData>(
      variables === undefined ? ['GetFeaturedContests'] : ['GetFeaturedContests', variables],
      useFetchData<GetFeaturedContestsQuery, GetFeaturedContestsQueryVariables>(GetFeaturedContestsDocument).bind(null, variables),
      options
    );

useGetFeaturedContestsQuery.getKey = (variables?: GetFeaturedContestsQueryVariables) => variables === undefined ? ['GetFeaturedContests'] : ['GetFeaturedContests', variables];
;

export const GetMathConstantDocument = `
    query GetMathConstant {
  getMathConstant {
    id
    name
    value
  }
}
    `;
export const useGetMathConstantQuery = <
      TData = GetMathConstantQuery,
      TError = unknown
    >(
      variables?: GetMathConstantQueryVariables,
      options?: UseQueryOptions<GetMathConstantQuery, TError, TData>
    ) =>
    useQuery<GetMathConstantQuery, TError, TData>(
      variables === undefined ? ['GetMathConstant'] : ['GetMathConstant', variables],
      useFetchData<GetMathConstantQuery, GetMathConstantQueryVariables>(GetMathConstantDocument).bind(null, variables),
      options
    );

useGetMathConstantQuery.getKey = (variables?: GetMathConstantQueryVariables) => variables === undefined ? ['GetMathConstant'] : ['GetMathConstant', variables];
;

export const GetNbaTouchdownContestPlayersDocument = `
    query GetNbaTouchdownContestPlayers($date: String!) {
  getNbaTouchdownContestPlayers(date: $date) {
    id
    remoteId
    fullName
    gameId
    gameStartTime
    projectedFantasyPoints
  }
}
    `;
export const useGetNbaTouchdownContestPlayersQuery = <
      TData = GetNbaTouchdownContestPlayersQuery,
      TError = unknown
    >(
      variables: GetNbaTouchdownContestPlayersQueryVariables,
      options?: UseQueryOptions<GetNbaTouchdownContestPlayersQuery, TError, TData>
    ) =>
    useQuery<GetNbaTouchdownContestPlayersQuery, TError, TData>(
      ['GetNbaTouchdownContestPlayers', variables],
      useFetchData<GetNbaTouchdownContestPlayersQuery, GetNbaTouchdownContestPlayersQueryVariables>(GetNbaTouchdownContestPlayersDocument).bind(null, variables),
      options
    );

useGetNbaTouchdownContestPlayersQuery.getKey = (variables: GetNbaTouchdownContestPlayersQueryVariables) => ['GetNbaTouchdownContestPlayers', variables];
;

export const GetNbaTouchdownContestsDocument = `
    query GetNbaTouchdownContests($date: String!) {
  getNbaTouchdownContests(date: $date) {
    id
    player1 {
      id
      remoteId
      firstName
      lastName
      photoUrl
      injuryStatus
    }
    player2 {
      id
      remoteId
      firstName
      lastName
      photoUrl
      injuryStatus
    }
    player1BonusPoints
    player2BonusPoints
    status
  }
}
    `;
export const useGetNbaTouchdownContestsQuery = <
      TData = GetNbaTouchdownContestsQuery,
      TError = unknown
    >(
      variables: GetNbaTouchdownContestsQueryVariables,
      options?: UseQueryOptions<GetNbaTouchdownContestsQuery, TError, TData>
    ) =>
    useQuery<GetNbaTouchdownContestsQuery, TError, TData>(
      ['GetNbaTouchdownContests', variables],
      useFetchData<GetNbaTouchdownContestsQuery, GetNbaTouchdownContestsQueryVariables>(GetNbaTouchdownContestsDocument).bind(null, variables),
      options
    );

useGetNbaTouchdownContestsQuery.getKey = (variables: GetNbaTouchdownContestsQueryVariables) => ['GetNbaTouchdownContests', variables];
;

export const GetNflContestTitleDocument = `
    query GetNflContestTitle {
  getNflContestTitle {
    statName
    question
  }
}
    `;
export const useGetNflContestTitleQuery = <
      TData = GetNflContestTitleQuery,
      TError = unknown
    >(
      variables?: GetNflContestTitleQueryVariables,
      options?: UseQueryOptions<GetNflContestTitleQuery, TError, TData>
    ) =>
    useQuery<GetNflContestTitleQuery, TError, TData>(
      variables === undefined ? ['GetNflContestTitle'] : ['GetNflContestTitle', variables],
      useFetchData<GetNflContestTitleQuery, GetNflContestTitleQueryVariables>(GetNflContestTitleDocument).bind(null, variables),
      options
    );

useGetNflContestTitleQuery.getKey = (variables?: GetNflContestTitleQueryVariables) => variables === undefined ? ['GetNflContestTitle'] : ['GetNflContestTitle', variables];
;

export const GetNflContestsReportDocument = `
    query GetNflContestsReport($data: GetContestsReportInput!) {
  getContestsReport(data: $data) {
    id
    createdAt
    entryAmount
    winnerLabel
    status
    ended
    endedAt
    creatorPlayer {
      fullName
    }
    claimerPlayer {
      fullName
    }
    creatorUser {
      fullName
      email
    }
    claimerUser {
      fullName
      email
    }
  }
}
    `;
export const useGetNflContestsReportQuery = <
      TData = GetNflContestsReportQuery,
      TError = unknown
    >(
      variables: GetNflContestsReportQueryVariables,
      options?: UseQueryOptions<GetNflContestsReportQuery, TError, TData>
    ) =>
    useQuery<GetNflContestsReportQuery, TError, TData>(
      ['GetNflContestsReport', variables],
      useFetchData<GetNflContestsReportQuery, GetNflContestsReportQueryVariables>(GetNflContestsReportDocument).bind(null, variables),
      options
    );

useGetNflContestsReportQuery.getKey = (variables: GetNflContestsReportQueryVariables) => ['GetNflContestsReport', variables];
;

export const GetNflMatchupsDocument = `
    query GetNflMatchups {
  getNflMatchups {
    id
    playerOne {
      id
      remoteId
      firstName
      lastName
      photoUrl
      photoUrlHiRes
    }
    playerTwo {
      id
      remoteId
      firstName
      lastName
      photoUrl
      photoUrlHiRes
    }
    orderIndex
    status
  }
}
    `;
export const useGetNflMatchupsQuery = <
      TData = GetNflMatchupsQuery,
      TError = unknown
    >(
      variables?: GetNflMatchupsQueryVariables,
      options?: UseQueryOptions<GetNflMatchupsQuery, TError, TData>
    ) =>
    useQuery<GetNflMatchupsQuery, TError, TData>(
      variables === undefined ? ['GetNflMatchups'] : ['GetNflMatchups', variables],
      useFetchData<GetNflMatchupsQuery, GetNflMatchupsQueryVariables>(GetNflMatchupsDocument).bind(null, variables),
      options
    );

useGetNflMatchupsQuery.getKey = (variables?: GetNflMatchupsQueryVariables) => variables === undefined ? ['GetNflMatchups'] : ['GetNflMatchups', variables];
;

export const GetNflTouchdownContestPlayersDocument = `
    query GetNflTouchdownContestPlayers($date: String!) {
  getNflTouchdownContestPlayers(date: $date) {
    id
    remoteId
    fullName
    gameId
    gameStartTime
    projectedFantasyPoints
  }
}
    `;
export const useGetNflTouchdownContestPlayersQuery = <
      TData = GetNflTouchdownContestPlayersQuery,
      TError = unknown
    >(
      variables: GetNflTouchdownContestPlayersQueryVariables,
      options?: UseQueryOptions<GetNflTouchdownContestPlayersQuery, TError, TData>
    ) =>
    useQuery<GetNflTouchdownContestPlayersQuery, TError, TData>(
      ['GetNflTouchdownContestPlayers', variables],
      useFetchData<GetNflTouchdownContestPlayersQuery, GetNflTouchdownContestPlayersQueryVariables>(GetNflTouchdownContestPlayersDocument).bind(null, variables),
      options
    );

useGetNflTouchdownContestPlayersQuery.getKey = (variables: GetNflTouchdownContestPlayersQueryVariables) => ['GetNflTouchdownContestPlayers', variables];
;

export const GetNflTouchdownContestsDocument = `
    query GetNflTouchdownContests($date: String!) {
  getNflTouchdownContests(date: $date) {
    id
    player1 {
      id
      remoteId
      firstName
      lastName
      photoUrl
      photoUrlHiRes
      injuryStatus
    }
    player2 {
      id
      remoteId
      firstName
      lastName
      photoUrl
      photoUrlHiRes
      injuryStatus
    }
    player1BonusPoints
    player2BonusPoints
    status
  }
}
    `;
export const useGetNflTouchdownContestsQuery = <
      TData = GetNflTouchdownContestsQuery,
      TError = unknown
    >(
      variables: GetNflTouchdownContestsQueryVariables,
      options?: UseQueryOptions<GetNflTouchdownContestsQuery, TError, TData>
    ) =>
    useQuery<GetNflTouchdownContestsQuery, TError, TData>(
      ['GetNflTouchdownContests', variables],
      useFetchData<GetNflTouchdownContestsQuery, GetNflTouchdownContestsQueryVariables>(GetNflTouchdownContestsDocument).bind(null, variables),
      options
    );

useGetNflTouchdownContestsQuery.getKey = (variables: GetNflTouchdownContestsQueryVariables) => ['GetNflTouchdownContests', variables];
;

export const GetPartnerActiveTouchdownsDocument = `
    query GetPartnerActiveTouchdowns($partnerId: Int!) {
  getPartnerActiveTouchdowns(partnerId: $partnerId) {
    id
    name
    startDateTime
    endDateTime
    allowedPick
    entryFees
    prizePools {
      startDateTime
      endDateTime
      allowedSports
      lockTime
      maxEntriesPerUser
      totalEntrants
      totalPrize
      jackpotAmount
      gppAmount
      contestVig
      id
      status
      isRollsOver
      weeklyReserveAmount
      predetermineJackpotAmount
      rolloverAmount
      rolloverJackpotAmount
      rolloverVig
      createdAt
      updatedAt
      userEntryCount
      jackpotWinners
      gppWinners
      nbaContestCount
      nflContestCount
      soccerContestCount
      hubContestCount
    }
  }
}
    `;
export const useGetPartnerActiveTouchdownsQuery = <
      TData = GetPartnerActiveTouchdownsQuery,
      TError = unknown
    >(
      variables: GetPartnerActiveTouchdownsQueryVariables,
      options?: UseQueryOptions<GetPartnerActiveTouchdownsQuery, TError, TData>
    ) =>
    useQuery<GetPartnerActiveTouchdownsQuery, TError, TData>(
      ['GetPartnerActiveTouchdowns', variables],
      useFetchData<GetPartnerActiveTouchdownsQuery, GetPartnerActiveTouchdownsQueryVariables>(GetPartnerActiveTouchdownsDocument).bind(null, variables),
      options
    );

useGetPartnerActiveTouchdownsQuery.getKey = (variables: GetPartnerActiveTouchdownsQueryVariables) => ['GetPartnerActiveTouchdowns', variables];
;

export const GetPartnerAdminDocument = `
    query GetPartnerAdmin($partnerId: Int!) {
  getPartnerAdmin(partnerId: $partnerId) {
    id
    fullName
    email
    phone
    active
  }
}
    `;
export const useGetPartnerAdminQuery = <
      TData = GetPartnerAdminQuery,
      TError = unknown
    >(
      variables: GetPartnerAdminQueryVariables,
      options?: UseQueryOptions<GetPartnerAdminQuery, TError, TData>
    ) =>
    useQuery<GetPartnerAdminQuery, TError, TData>(
      ['GetPartnerAdmin', variables],
      useFetchData<GetPartnerAdminQuery, GetPartnerAdminQueryVariables>(GetPartnerAdminDocument).bind(null, variables),
      options
    );

useGetPartnerAdminQuery.getKey = (variables: GetPartnerAdminQueryVariables) => ['GetPartnerAdmin', variables];
;

export const GetPartnerByIdDocument = `
    query GetPartnerById($partnerId: Int!) {
  getPartnerById(partnerId: $partnerId) {
    id
    name
    email
    contact
    active
  }
}
    `;
export const useGetPartnerByIdQuery = <
      TData = GetPartnerByIdQuery,
      TError = unknown
    >(
      variables: GetPartnerByIdQueryVariables,
      options?: UseQueryOptions<GetPartnerByIdQuery, TError, TData>
    ) =>
    useQuery<GetPartnerByIdQuery, TError, TData>(
      ['GetPartnerById', variables],
      useFetchData<GetPartnerByIdQuery, GetPartnerByIdQueryVariables>(GetPartnerByIdDocument).bind(null, variables),
      options
    );

useGetPartnerByIdQuery.getKey = (variables: GetPartnerByIdQueryVariables) => ['GetPartnerById', variables];
;

export const GetPartnerCommonContestsDocument = `
    query GetPartnerCommonContests($sportId: Int!, $date: String!, $prizePoolId: Int!) {
  getPartnerCommonContests(
    sportId: $sportId
    date: $date
    prizePoolId: $prizePoolId
  ) {
    publishedContestsId
    commonContests {
      id
      player1Name
      player2Name
      player1Id
      player2Id
      source
      player1BonusPoints
      player2BonusPoints
      metric
      question
      player1PhotoUrl
      player2PhotoUrl
      status
    }
  }
}
    `;
export const useGetPartnerCommonContestsQuery = <
      TData = GetPartnerCommonContestsQuery,
      TError = unknown
    >(
      variables: GetPartnerCommonContestsQueryVariables,
      options?: UseQueryOptions<GetPartnerCommonContestsQuery, TError, TData>
    ) =>
    useQuery<GetPartnerCommonContestsQuery, TError, TData>(
      ['GetPartnerCommonContests', variables],
      useFetchData<GetPartnerCommonContestsQuery, GetPartnerCommonContestsQueryVariables>(GetPartnerCommonContestsDocument).bind(null, variables),
      options
    );

useGetPartnerCommonContestsQuery.getKey = (variables: GetPartnerCommonContestsQueryVariables) => ['GetPartnerCommonContests', variables];
;

export const GetPartnerDailyLeaderBoardDocument = `
    query GetPartnerDailyLeaderBoard($prizePoolId: Int!) {
  getPartnerDailyLeaderBoard(prizePoolId: $prizePoolId) {
    startDateTime
    endDateTime
    entries {
      entryId
      userId
      name
      score
      rank
      winAmount
      leaderBoardRank
    }
  }
}
    `;
export const useGetPartnerDailyLeaderBoardQuery = <
      TData = GetPartnerDailyLeaderBoardQuery,
      TError = unknown
    >(
      variables: GetPartnerDailyLeaderBoardQueryVariables,
      options?: UseQueryOptions<GetPartnerDailyLeaderBoardQuery, TError, TData>
    ) =>
    useQuery<GetPartnerDailyLeaderBoardQuery, TError, TData>(
      ['GetPartnerDailyLeaderBoard', variables],
      useFetchData<GetPartnerDailyLeaderBoardQuery, GetPartnerDailyLeaderBoardQueryVariables>(GetPartnerDailyLeaderBoardDocument).bind(null, variables),
      options
    );

useGetPartnerDailyLeaderBoardQuery.getKey = (variables: GetPartnerDailyLeaderBoardQueryVariables) => ['GetPartnerDailyLeaderBoard', variables];
;

export const GetPartnerNbaTouchdownContestPlayersDocument = `
    query GetPartnerNbaTouchdownContestPlayers($date: String!) {
  getPartnerNbaTouchdownContestPlayers(date: $date) {
    id
    remoteId
    fullName
    projectedFantasyPoints
    gameId
    gameStartTime
  }
}
    `;
export const useGetPartnerNbaTouchdownContestPlayersQuery = <
      TData = GetPartnerNbaTouchdownContestPlayersQuery,
      TError = unknown
    >(
      variables: GetPartnerNbaTouchdownContestPlayersQueryVariables,
      options?: UseQueryOptions<GetPartnerNbaTouchdownContestPlayersQuery, TError, TData>
    ) =>
    useQuery<GetPartnerNbaTouchdownContestPlayersQuery, TError, TData>(
      ['GetPartnerNbaTouchdownContestPlayers', variables],
      useFetchData<GetPartnerNbaTouchdownContestPlayersQuery, GetPartnerNbaTouchdownContestPlayersQueryVariables>(GetPartnerNbaTouchdownContestPlayersDocument).bind(null, variables),
      options
    );

useGetPartnerNbaTouchdownContestPlayersQuery.getKey = (variables: GetPartnerNbaTouchdownContestPlayersQueryVariables) => ['GetPartnerNbaTouchdownContestPlayers', variables];
;

export const GetPartnerNbaTouchdownContestsDocument = `
    query GetPartnerNbaTouchdownContests($date: String!, $prizePoolId: Int!) {
  getPartnerNbaTouchdownContests(date: $date, prizePoolId: $prizePoolId) {
    id
    player1BonusPoints
    player2BonusPoints
    status
    player1 {
      id
      remoteId
      firstName
      lastName
      photoUrl
      injuryStatus
    }
    player2 {
      id
      remoteId
      firstName
      lastName
      photoUrl
      injuryStatus
    }
  }
}
    `;
export const useGetPartnerNbaTouchdownContestsQuery = <
      TData = GetPartnerNbaTouchdownContestsQuery,
      TError = unknown
    >(
      variables: GetPartnerNbaTouchdownContestsQueryVariables,
      options?: UseQueryOptions<GetPartnerNbaTouchdownContestsQuery, TError, TData>
    ) =>
    useQuery<GetPartnerNbaTouchdownContestsQuery, TError, TData>(
      ['GetPartnerNbaTouchdownContests', variables],
      useFetchData<GetPartnerNbaTouchdownContestsQuery, GetPartnerNbaTouchdownContestsQueryVariables>(GetPartnerNbaTouchdownContestsDocument).bind(null, variables),
      options
    );

useGetPartnerNbaTouchdownContestsQuery.getKey = (variables: GetPartnerNbaTouchdownContestsQueryVariables) => ['GetPartnerNbaTouchdownContests', variables];
;

export const GetPartnerPrizeConstantDocument = `
    query GetPartnerPrizeConstant($partnerId: Int!) {
  getPartnerPrizeConstant(partnerId: $partnerId) {
    prizeConfiguration {
      GPP
      PRIZE_POOL
      WEEKLY_RESERVE
    }
  }
}
    `;
export const useGetPartnerPrizeConstantQuery = <
      TData = GetPartnerPrizeConstantQuery,
      TError = unknown
    >(
      variables: GetPartnerPrizeConstantQueryVariables,
      options?: UseQueryOptions<GetPartnerPrizeConstantQuery, TError, TData>
    ) =>
    useQuery<GetPartnerPrizeConstantQuery, TError, TData>(
      ['GetPartnerPrizeConstant', variables],
      useFetchData<GetPartnerPrizeConstantQuery, GetPartnerPrizeConstantQueryVariables>(GetPartnerPrizeConstantDocument).bind(null, variables),
      options
    );

useGetPartnerPrizeConstantQuery.getKey = (variables: GetPartnerPrizeConstantQueryVariables) => ['GetPartnerPrizeConstant', variables];
;

export const GetPartnerSoccerTouchdownContestPlayersDocument = `
    query GetPartnerSoccerTouchdownContestPlayers($date: String!) {
  getPartnerSoccerTouchdownContestPlayers(date: $date) {
    id
    remoteId
    fullName
    gameId
    gameStartTime
    projectedShots
    projectedSaves
    projectedPasses
    projectedShotsOnGoal
    projectedGoals
    projectedAssists
    projectedTackles
  }
}
    `;
export const useGetPartnerSoccerTouchdownContestPlayersQuery = <
      TData = GetPartnerSoccerTouchdownContestPlayersQuery,
      TError = unknown
    >(
      variables: GetPartnerSoccerTouchdownContestPlayersQueryVariables,
      options?: UseQueryOptions<GetPartnerSoccerTouchdownContestPlayersQuery, TError, TData>
    ) =>
    useQuery<GetPartnerSoccerTouchdownContestPlayersQuery, TError, TData>(
      ['GetPartnerSoccerTouchdownContestPlayers', variables],
      useFetchData<GetPartnerSoccerTouchdownContestPlayersQuery, GetPartnerSoccerTouchdownContestPlayersQueryVariables>(GetPartnerSoccerTouchdownContestPlayersDocument).bind(null, variables),
      options
    );

useGetPartnerSoccerTouchdownContestPlayersQuery.getKey = (variables: GetPartnerSoccerTouchdownContestPlayersQueryVariables) => ['GetPartnerSoccerTouchdownContestPlayers', variables];
;

export const GetPartnerSoccerTouchdownContestsDocument = `
    query GetPartnerSoccerTouchdownContests($date: String!, $prizePoolId: Int!) {
  getPartnerSoccerTouchdownContests(date: $date, prizePoolId: $prizePoolId) {
    id
    player1BonusPoints
    player2BonusPoints
    metric
    status
    player1 {
      id
      remoteId
      firstName
      lastName
      photoUrl
      injuryStatus
    }
    player2 {
      id
      remoteId
      firstName
      lastName
      photoUrl
      injuryStatus
    }
  }
}
    `;
export const useGetPartnerSoccerTouchdownContestsQuery = <
      TData = GetPartnerSoccerTouchdownContestsQuery,
      TError = unknown
    >(
      variables: GetPartnerSoccerTouchdownContestsQueryVariables,
      options?: UseQueryOptions<GetPartnerSoccerTouchdownContestsQuery, TError, TData>
    ) =>
    useQuery<GetPartnerSoccerTouchdownContestsQuery, TError, TData>(
      ['GetPartnerSoccerTouchdownContests', variables],
      useFetchData<GetPartnerSoccerTouchdownContestsQuery, GetPartnerSoccerTouchdownContestsQueryVariables>(GetPartnerSoccerTouchdownContestsDocument).bind(null, variables),
      options
    );

useGetPartnerSoccerTouchdownContestsQuery.getKey = (variables: GetPartnerSoccerTouchdownContestsQueryVariables) => ['GetPartnerSoccerTouchdownContests', variables];
;

export const GetPartnerSportsDocument = `
    query GetPartnerSports($partnerId: Int!, $type: String!) {
  getPartnerSports(partnerId: $partnerId, type: $type) {
    id
    name
    logoUrl
    winCriteria
    enabled
    orderIndex
    partnerId
    key
    automated
    commonSportId
    questions {
      id
      question
      metric
    }
  }
}
    `;
export const useGetPartnerSportsQuery = <
      TData = GetPartnerSportsQuery,
      TError = unknown
    >(
      variables: GetPartnerSportsQueryVariables,
      options?: UseQueryOptions<GetPartnerSportsQuery, TError, TData>
    ) =>
    useQuery<GetPartnerSportsQuery, TError, TData>(
      ['GetPartnerSports', variables],
      useFetchData<GetPartnerSportsQuery, GetPartnerSportsQueryVariables>(GetPartnerSportsDocument).bind(null, variables),
      options
    );

useGetPartnerSportsQuery.getKey = (variables: GetPartnerSportsQueryVariables) => ['GetPartnerSports', variables];
;

export const GetPartnerTouchdownAnalyticsDocument = `
    query GetPartnerTouchdownAnalytics($partnerId: Int!) {
  getPartnerTouchdownAnalytics(partnerId: $partnerId) {
    totalUsersCount
    totalTouchdownCount
    upcomingTouchdownCount
    totalContestVig
    touchdownCount {
      month
      count
    }
    activeTouchdowns {
      id
      name
      startDateTime
      endDateTime
      allowedPick
      entryFees
      prizePools {
        startDateTime
        endDateTime
        allowedSports
        lockTime
        maxEntriesPerUser
        totalEntrants
        totalPrize
        jackpotAmount
        gppAmount
        contestVig
        id
        status
        isRollsOver
        weeklyReserveAmount
        predetermineJackpotAmount
        rolloverAmount
        rolloverJackpotAmount
        rolloverVig
        createdAt
        updatedAt
        userEntryCount
        jackpotWinners
        gppWinners
        nbaContestCount
        nflContestCount
        soccerContestCount
        hubContestCount
      }
    }
    totalReceivedFees
    totalActiveTouchdowns
  }
}
    `;
export const useGetPartnerTouchdownAnalyticsQuery = <
      TData = GetPartnerTouchdownAnalyticsQuery,
      TError = unknown
    >(
      variables: GetPartnerTouchdownAnalyticsQueryVariables,
      options?: UseQueryOptions<GetPartnerTouchdownAnalyticsQuery, TError, TData>
    ) =>
    useQuery<GetPartnerTouchdownAnalyticsQuery, TError, TData>(
      ['GetPartnerTouchdownAnalytics', variables],
      useFetchData<GetPartnerTouchdownAnalyticsQuery, GetPartnerTouchdownAnalyticsQueryVariables>(GetPartnerTouchdownAnalyticsDocument).bind(null, variables),
      options
    );

useGetPartnerTouchdownAnalyticsQuery.getKey = (variables: GetPartnerTouchdownAnalyticsQueryVariables) => ['GetPartnerTouchdownAnalytics', variables];
;

export const GetPlayersForSuggestionDocument = `
    query GetPlayersForSuggestion($contestType: String, $roundNumber: Int, $tournamentId: String!) {
  getPlayersForSuggestion(
    data: {roundNumber: $roundNumber, contestType: $contestType, tournamentId: $tournamentId}
  ) {
    id
    playerId
    lastName
    firstName
    headshotUrlLarge
    headshotUrlSmall
  }
}
    `;
export const useGetPlayersForSuggestionQuery = <
      TData = GetPlayersForSuggestionQuery,
      TError = unknown
    >(
      variables: GetPlayersForSuggestionQueryVariables,
      options?: UseQueryOptions<GetPlayersForSuggestionQuery, TError, TData>
    ) =>
    useQuery<GetPlayersForSuggestionQuery, TError, TData>(
      ['GetPlayersForSuggestion', variables],
      useFetchData<GetPlayersForSuggestionQuery, GetPlayersForSuggestionQueryVariables>(GetPlayersForSuggestionDocument).bind(null, variables),
      options
    );

useGetPlayersForSuggestionQuery.getKey = (variables: GetPlayersForSuggestionQueryVariables) => ['GetPlayersForSuggestion', variables];
;

export const GetRolesDocument = `
    query GetRoles {
  getRoles {
    id
    createdAt
    name
    permissions
  }
}
    `;
export const useGetRolesQuery = <
      TData = GetRolesQuery,
      TError = unknown
    >(
      variables?: GetRolesQueryVariables,
      options?: UseQueryOptions<GetRolesQuery, TError, TData>
    ) =>
    useQuery<GetRolesQuery, TError, TData>(
      variables === undefined ? ['GetRoles'] : ['GetRoles', variables],
      useFetchData<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument).bind(null, variables),
      options
    );

useGetRolesQuery.getKey = (variables?: GetRolesQueryVariables) => variables === undefined ? ['GetRoles'] : ['GetRoles', variables];
;

export const GetSoccerTouchdownContestPlayersDocument = `
    query GetSoccerTouchdownContestPlayers($date: String!) {
  getSoccerTouchdownContestPlayers(date: $date) {
    id
    remoteId
    fullName
    gameId
    gameStartTime
    projectedShots
    projectedSaves
    projectedPasses
    projectedShotsOnGoal
    projectedGoals
    projectedAssists
    projectedTackles
  }
}
    `;
export const useGetSoccerTouchdownContestPlayersQuery = <
      TData = GetSoccerTouchdownContestPlayersQuery,
      TError = unknown
    >(
      variables: GetSoccerTouchdownContestPlayersQueryVariables,
      options?: UseQueryOptions<GetSoccerTouchdownContestPlayersQuery, TError, TData>
    ) =>
    useQuery<GetSoccerTouchdownContestPlayersQuery, TError, TData>(
      ['GetSoccerTouchdownContestPlayers', variables],
      useFetchData<GetSoccerTouchdownContestPlayersQuery, GetSoccerTouchdownContestPlayersQueryVariables>(GetSoccerTouchdownContestPlayersDocument).bind(null, variables),
      options
    );

useGetSoccerTouchdownContestPlayersQuery.getKey = (variables: GetSoccerTouchdownContestPlayersQueryVariables) => ['GetSoccerTouchdownContestPlayers', variables];
;

export const GetSoccerTouchdownContestsDocument = `
    query GetSoccerTouchdownContests($date: String!) {
  getSoccerTouchdownContests(date: $date) {
    id
    player1BonusPoints
    player2BonusPoints
    metric
    status
    player1 {
      id
      remoteId
      firstName
      lastName
      photoUrl
      injuryStatus
    }
    player2 {
      id
      remoteId
      firstName
      lastName
      photoUrl
      injuryStatus
    }
  }
}
    `;
export const useGetSoccerTouchdownContestsQuery = <
      TData = GetSoccerTouchdownContestsQuery,
      TError = unknown
    >(
      variables: GetSoccerTouchdownContestsQueryVariables,
      options?: UseQueryOptions<GetSoccerTouchdownContestsQuery, TError, TData>
    ) =>
    useQuery<GetSoccerTouchdownContestsQuery, TError, TData>(
      ['GetSoccerTouchdownContests', variables],
      useFetchData<GetSoccerTouchdownContestsQuery, GetSoccerTouchdownContestsQueryVariables>(GetSoccerTouchdownContestsDocument).bind(null, variables),
      options
    );

useGetSoccerTouchdownContestsQuery.getKey = (variables: GetSoccerTouchdownContestsQueryVariables) => ['GetSoccerTouchdownContests', variables];
;

export const GetSportDocument = `
    query GetSport($name: String!) {
  getSport(name: $name) {
    id
    name
    logoUrl
    winCriteria
    enabled
    questions {
      id
      question
      metric
    }
  }
}
    `;
export const useGetSportQuery = <
      TData = GetSportQuery,
      TError = unknown
    >(
      variables: GetSportQueryVariables,
      options?: UseQueryOptions<GetSportQuery, TError, TData>
    ) =>
    useQuery<GetSportQuery, TError, TData>(
      ['GetSport', variables],
      useFetchData<GetSportQuery, GetSportQueryVariables>(GetSportDocument).bind(null, variables),
      options
    );

useGetSportQuery.getKey = (variables: GetSportQueryVariables) => ['GetSport', variables];
;

export const GetSportsDocument = `
    query GetSports {
  getSports {
    id
    name
    logoUrl
    winCriteria
    enabled
    questions {
      id
      question
      metric
    }
  }
}
    `;
export const useGetSportsQuery = <
      TData = GetSportsQuery,
      TError = unknown
    >(
      variables?: GetSportsQueryVariables,
      options?: UseQueryOptions<GetSportsQuery, TError, TData>
    ) =>
    useQuery<GetSportsQuery, TError, TData>(
      variables === undefined ? ['GetSports'] : ['GetSports', variables],
      useFetchData<GetSportsQuery, GetSportsQueryVariables>(GetSportsDocument).bind(null, variables),
      options
    );

useGetSportsQuery.getKey = (variables?: GetSportsQueryVariables) => variables === undefined ? ['GetSports'] : ['GetSports', variables];
;

export const GetSportsLogoDocument = `
    query GetSportsLogo {
  getSportsLogo
}
    `;
export const useGetSportsLogoQuery = <
      TData = GetSportsLogoQuery,
      TError = unknown
    >(
      variables?: GetSportsLogoQueryVariables,
      options?: UseQueryOptions<GetSportsLogoQuery, TError, TData>
    ) =>
    useQuery<GetSportsLogoQuery, TError, TData>(
      variables === undefined ? ['GetSportsLogo'] : ['GetSportsLogo', variables],
      useFetchData<GetSportsLogoQuery, GetSportsLogoQueryVariables>(GetSportsLogoDocument).bind(null, variables),
      options
    );

useGetSportsLogoQuery.getKey = (variables?: GetSportsLogoQueryVariables) => variables === undefined ? ['GetSportsLogo'] : ['GetSportsLogo', variables];
;

export const GetSuggestionDocument = `
    query GetSuggestion($sportType: String!) {
  getSuggestions(data: {sportType: $sportType}) {
    id
    isFeaturedContest
    playerOne {
      fullName
      firstName
      lastName
      photoUrl
      photoUrlHiRes
    }
    playerTwo {
      fullName
      firstName
      lastName
      photoUrl
      photoUrlHiRes
    }
  }
}
    `;
export const useGetSuggestionQuery = <
      TData = GetSuggestionQuery,
      TError = unknown
    >(
      variables: GetSuggestionQueryVariables,
      options?: UseQueryOptions<GetSuggestionQuery, TError, TData>
    ) =>
    useQuery<GetSuggestionQuery, TError, TData>(
      ['GetSuggestion', variables],
      useFetchData<GetSuggestionQuery, GetSuggestionQueryVariables>(GetSuggestionDocument).bind(null, variables),
      options
    );

useGetSuggestionQuery.getKey = (variables: GetSuggestionQueryVariables) => ['GetSuggestion', variables];
;

export const GetAllSupportTicketsDocument = `
    query GetAllSupportTickets {
  getAllSupportTickets {
    id
    userId
    email
    message
    createdAt
  }
}
    `;
export const useGetAllSupportTicketsQuery = <
      TData = GetAllSupportTicketsQuery,
      TError = unknown
    >(
      variables?: GetAllSupportTicketsQueryVariables,
      options?: UseQueryOptions<GetAllSupportTicketsQuery, TError, TData>
    ) =>
    useQuery<GetAllSupportTicketsQuery, TError, TData>(
      variables === undefined ? ['GetAllSupportTickets'] : ['GetAllSupportTickets', variables],
      useFetchData<GetAllSupportTicketsQuery, GetAllSupportTicketsQueryVariables>(GetAllSupportTicketsDocument).bind(null, variables),
      options
    );

useGetAllSupportTicketsQuery.getKey = (variables?: GetAllSupportTicketsQueryVariables) => variables === undefined ? ['GetAllSupportTickets'] : ['GetAllSupportTickets', variables];
;

export const GetTeeTimeByRoundDocument = `
    mutation GetTeeTimeByRound($year: Int!, $tournamentId: String, $roundNumber: Int!) {
  getTeeTimeByRound(
    year: $year
    tournamentId: $tournamentId
    roundNumber: $roundNumber
  ) {
    success
  }
}
    `;
export const useGetTeeTimeByRoundMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<GetTeeTimeByRoundMutation, TError, GetTeeTimeByRoundMutationVariables, TContext>) =>
    useMutation<GetTeeTimeByRoundMutation, TError, GetTeeTimeByRoundMutationVariables, TContext>(
      ['GetTeeTimeByRound'],
      useFetchData<GetTeeTimeByRoundMutation, GetTeeTimeByRoundMutationVariables>(GetTeeTimeByRoundDocument),
      options
    );
export const GetTeeTimeForCurrentTournamentDocument = `
    mutation GetTeeTimeForCurrentTournament {
  getTeeTimeForCurrentTournament {
    success
  }
}
    `;
export const useGetTeeTimeForCurrentTournamentMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<GetTeeTimeForCurrentTournamentMutation, TError, GetTeeTimeForCurrentTournamentMutationVariables, TContext>) =>
    useMutation<GetTeeTimeForCurrentTournamentMutation, TError, GetTeeTimeForCurrentTournamentMutationVariables, TContext>(
      ['GetTeeTimeForCurrentTournament'],
      useFetchData<GetTeeTimeForCurrentTournamentMutation, GetTeeTimeForCurrentTournamentMutationVariables>(GetTeeTimeForCurrentTournamentDocument),
      options
    );
export const GetTopupsDocument = `
    query GetTopups {
  getTopups {
    id
    amount
    createdAt
    notes
    user {
      id
      fullName
      email
    }
  }
}
    `;
export const useGetTopupsQuery = <
      TData = GetTopupsQuery,
      TError = unknown
    >(
      variables?: GetTopupsQueryVariables,
      options?: UseQueryOptions<GetTopupsQuery, TError, TData>
    ) =>
    useQuery<GetTopupsQuery, TError, TData>(
      variables === undefined ? ['GetTopups'] : ['GetTopups', variables],
      useFetchData<GetTopupsQuery, GetTopupsQueryVariables>(GetTopupsDocument).bind(null, variables),
      options
    );

useGetTopupsQuery.getKey = (variables?: GetTopupsQueryVariables) => variables === undefined ? ['GetTopups'] : ['GetTopups', variables];
;

export const GetTouchdownByDateDocument = `
    query GetTouchdownByDate($startDate: String!, $endDate: String!) {
  getTouchdownByDate(startDate: $startDate, endDate: $endDate) {
    touchdownId
    startDate
    endDate
    touchDownType
    mathConstant {
      PRIZE_POOL
      GPP
      WEEKLY_RESERVE
    }
    prizePools {
      prizePoolId
      startDate
      endDate
      supportedSports
      entryFees
      lockTime
      totalEntrants
      maxEntriesPerUser
      isRollsOver
      predeterminePrizePool
      predetermineDailyGPP
      predetermineWeeklyReserve
      predetermineJackpot
      predetermineContestVig
      predetermineRolloverVig
      status
      actualPrizePool
      actualDailyGPP
      actualWeeklyReserve
      actualJackpot
      actualContestVig
      actualRolloverVig
      userEntryCount
      jackpotWinnersCount
      gppWinnersCount
      nbaContestsCount
      mlbContestsCount
      pgaContestsCount
      nflContestsCount
      soccerContestsCount
      profit
      addedByTopProp
    }
    weeklyPrizeDistribution {
      prizes {
        id
        value
      }
    }
  }
}
    `;
export const useGetTouchdownByDateQuery = <
      TData = GetTouchdownByDateQuery,
      TError = unknown
    >(
      variables: GetTouchdownByDateQueryVariables,
      options?: UseQueryOptions<GetTouchdownByDateQuery, TError, TData>
    ) =>
    useQuery<GetTouchdownByDateQuery, TError, TData>(
      ['GetTouchdownByDate', variables],
      useFetchData<GetTouchdownByDateQuery, GetTouchdownByDateQueryVariables>(GetTouchdownByDateDocument).bind(null, variables),
      options
    );

useGetTouchdownByDateQuery.getKey = (variables: GetTouchdownByDateQueryVariables) => ['GetTouchdownByDate', variables];
;

export const GetTournamentSuggestionsDocument = `
    query GetTournamentSuggestions($contestType: String, $roundNumber: Int, $tournamentId: String) {
  getTournamentSuggestions(
    data: {roundNumber: $roundNumber, contestType: $contestType, tournamentId: $tournamentId}
  ) {
    id
    roundNumber
    tournament {
      remoteId
      name
      startDate
    }
    mainPlayer {
      playerId
      firstName
      lastName
      headshotUrlSmall
    }
    suggestedPlayer {
      playerId
      firstName
      lastName
      headshotUrlSmall
    }
  }
}
    `;
export const useGetTournamentSuggestionsQuery = <
      TData = GetTournamentSuggestionsQuery,
      TError = unknown
    >(
      variables?: GetTournamentSuggestionsQueryVariables,
      options?: UseQueryOptions<GetTournamentSuggestionsQuery, TError, TData>
    ) =>
    useQuery<GetTournamentSuggestionsQuery, TError, TData>(
      variables === undefined ? ['GetTournamentSuggestions'] : ['GetTournamentSuggestions', variables],
      useFetchData<GetTournamentSuggestionsQuery, GetTournamentSuggestionsQueryVariables>(GetTournamentSuggestionsDocument).bind(null, variables),
      options
    );

useGetTournamentSuggestionsQuery.getKey = (variables?: GetTournamentSuggestionsQueryVariables) => variables === undefined ? ['GetTournamentSuggestions'] : ['GetTournamentSuggestions', variables];
;

export const GetUserSignUpsDocument = `
    query GetUserSignUps {
  getUserSignUps {
    id
    fullName
    email
    signUpState
    promo
    dateOfBirth
    createdAt
    phone
    walletAmount
    isDeactivated
    isDeactivatedPermanently
    tsevoCustomerID
  }
}
    `;
export const useGetUserSignUpsQuery = <
      TData = GetUserSignUpsQuery,
      TError = unknown
    >(
      variables?: GetUserSignUpsQueryVariables,
      options?: UseQueryOptions<GetUserSignUpsQuery, TError, TData>
    ) =>
    useQuery<GetUserSignUpsQuery, TError, TData>(
      variables === undefined ? ['GetUserSignUps'] : ['GetUserSignUps', variables],
      useFetchData<GetUserSignUpsQuery, GetUserSignUpsQueryVariables>(GetUserSignUpsDocument).bind(null, variables),
      options
    );

useGetUserSignUpsQuery.getKey = (variables?: GetUserSignUpsQueryVariables) => variables === undefined ? ['GetUserSignUps'] : ['GetUserSignUps', variables];
;

export const GetUsersDocument = `
    query GetUsers {
  getUsers {
    id
    createdAt
    email
    permissions
    isActive
    roles {
      id
      name
    }
  }
}
    `;
export const useGetUsersQuery = <
      TData = GetUsersQuery,
      TError = unknown
    >(
      variables?: GetUsersQueryVariables,
      options?: UseQueryOptions<GetUsersQuery, TError, TData>
    ) =>
    useQuery<GetUsersQuery, TError, TData>(
      variables === undefined ? ['GetUsers'] : ['GetUsers', variables],
      useFetchData<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument).bind(null, variables),
      options
    );

useGetUsersQuery.getKey = (variables?: GetUsersQueryVariables) => variables === undefined ? ['GetUsers'] : ['GetUsers', variables];
;

export const GetWeeklyConstantDocument = `
    query GetWeeklyConstant {
  getWeeklyConstant {
    id
    value
  }
}
    `;
export const useGetWeeklyConstantQuery = <
      TData = GetWeeklyConstantQuery,
      TError = unknown
    >(
      variables?: GetWeeklyConstantQueryVariables,
      options?: UseQueryOptions<GetWeeklyConstantQuery, TError, TData>
    ) =>
    useQuery<GetWeeklyConstantQuery, TError, TData>(
      variables === undefined ? ['GetWeeklyConstant'] : ['GetWeeklyConstant', variables],
      useFetchData<GetWeeklyConstantQuery, GetWeeklyConstantQueryVariables>(GetWeeklyConstantDocument).bind(null, variables),
      options
    );

useGetWeeklyConstantQuery.getKey = (variables?: GetWeeklyConstantQueryVariables) => variables === undefined ? ['GetWeeklyConstant'] : ['GetWeeklyConstant', variables];
;

export const GetWeeklyHistoryByDateRangeDocument = `
    query GetWeeklyHistoryByDateRange($startDate: String!, $endDate: String!) {
  getWeeklyHistoryByDateRange(startDate: $startDate, endDate: $endDate) {
    touchdownId
    weekDate
    entryFee
    totalUserEntry
    totalEntryFees
    totalContestVig
    totalRolloverVig
    totalWeeklyReserve
    weeklyPrize
    profit
    totalSubscribers
    moneyAddedByTopprop
    sportContestCount {
      NBA
      NFL
    }
  }
}
    `;
export const useGetWeeklyHistoryByDateRangeQuery = <
      TData = GetWeeklyHistoryByDateRangeQuery,
      TError = unknown
    >(
      variables: GetWeeklyHistoryByDateRangeQueryVariables,
      options?: UseQueryOptions<GetWeeklyHistoryByDateRangeQuery, TError, TData>
    ) =>
    useQuery<GetWeeklyHistoryByDateRangeQuery, TError, TData>(
      ['GetWeeklyHistoryByDateRange', variables],
      useFetchData<GetWeeklyHistoryByDateRangeQuery, GetWeeklyHistoryByDateRangeQueryVariables>(GetWeeklyHistoryByDateRangeDocument).bind(null, variables),
      options
    );

useGetWeeklyHistoryByDateRangeQuery.getKey = (variables: GetWeeklyHistoryByDateRangeQueryVariables) => ['GetWeeklyHistoryByDateRange', variables];
;

export const GetWeeklyLeaderBoardDocument = `
    query GetWeeklyLeaderBoard($touchdownId: Int!, $searchStr: String!) {
  getWeeklyLeaderBoard(touchdownId: $touchdownId, searchStr: $searchStr) {
    page
    limit
    totalCount
    data {
      id
      rank
      leaderboardRank
      winAmount
      name
      score
      status
      payout
    }
  }
}
    `;
export const useGetWeeklyLeaderBoardQuery = <
      TData = GetWeeklyLeaderBoardQuery,
      TError = unknown
    >(
      variables: GetWeeklyLeaderBoardQueryVariables,
      options?: UseQueryOptions<GetWeeklyLeaderBoardQuery, TError, TData>
    ) =>
    useQuery<GetWeeklyLeaderBoardQuery, TError, TData>(
      ['GetWeeklyLeaderBoard', variables],
      useFetchData<GetWeeklyLeaderBoardQuery, GetWeeklyLeaderBoardQueryVariables>(GetWeeklyLeaderBoardDocument).bind(null, variables),
      options
    );

useGetWeeklyLeaderBoardQuery.getKey = (variables: GetWeeklyLeaderBoardQueryVariables) => ['GetWeeklyLeaderBoard', variables];
;

export const GetWeeklySubscribersDocument = `
    query GetWeeklySubscribers($startDate: String!, $endDate: String!, $searchStr: String) {
  getWeeklySubscribers(
    startDate: $startDate
    endDate: $endDate
    searchStr: $searchStr
  ) {
    items {
      email
      userName
      fullName
      subScriberDate
      subScribedDays
      entryFee
    }
  }
}
    `;
export const useGetWeeklySubscribersQuery = <
      TData = GetWeeklySubscribersQuery,
      TError = unknown
    >(
      variables: GetWeeklySubscribersQueryVariables,
      options?: UseQueryOptions<GetWeeklySubscribersQuery, TError, TData>
    ) =>
    useQuery<GetWeeklySubscribersQuery, TError, TData>(
      ['GetWeeklySubscribers', variables],
      useFetchData<GetWeeklySubscribersQuery, GetWeeklySubscribersQueryVariables>(GetWeeklySubscribersDocument).bind(null, variables),
      options
    );

useGetWeeklySubscribersQuery.getKey = (variables: GetWeeklySubscribersQueryVariables) => ['GetWeeklySubscribers', variables];
;

export const GetWithdrawRequestsDocument = `
    query GetWithdrawRequests {
  getWithdrawRequests {
    id
    amount
    createdAt
    user {
      email
      fullName
    }
  }
}
    `;
export const useGetWithdrawRequestsQuery = <
      TData = GetWithdrawRequestsQuery,
      TError = unknown
    >(
      variables?: GetWithdrawRequestsQueryVariables,
      options?: UseQueryOptions<GetWithdrawRequestsQuery, TError, TData>
    ) =>
    useQuery<GetWithdrawRequestsQuery, TError, TData>(
      variables === undefined ? ['GetWithdrawRequests'] : ['GetWithdrawRequests', variables],
      useFetchData<GetWithdrawRequestsQuery, GetWithdrawRequestsQueryVariables>(GetWithdrawRequestsDocument).bind(null, variables),
      options
    );

useGetWithdrawRequestsQuery.getKey = (variables?: GetWithdrawRequestsQueryVariables) => variables === undefined ? ['GetWithdrawRequests'] : ['GetWithdrawRequests', variables];
;

export const GradeCommonHubContestDocument = `
    mutation GradeCommonHubContest($data: GradeCommonHubContestInput!) {
  gradeCommonHubContest(data: $data) {
    id
    player1Id
    player1FullName
    player1PhotoUrl
    player1BonusPoints
    player1Game
    player1GameStartTime
    player1FantasyPoints
    player2Id
    player2FullName
    player2PhotoUrl
    player2BonusPoints
    player2Game
    player2GameStartTime
    player2FantasyPoints
    status
    winnerPlayerId
    winnerLabel
    contestDate
  }
}
    `;
export const useGradeCommonHubContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<GradeCommonHubContestMutation, TError, GradeCommonHubContestMutationVariables, TContext>) =>
    useMutation<GradeCommonHubContestMutation, TError, GradeCommonHubContestMutationVariables, TContext>(
      ['GradeCommonHubContest'],
      useFetchData<GradeCommonHubContestMutation, GradeCommonHubContestMutationVariables>(GradeCommonHubContestDocument),
      options
    );
export const GradeF2PHubContestDocument = `
    mutation GradeF2PHubContest($data: GradeF2PHubContestInputDTO!) {
  gradeF2PHubContest(data: $data) {
    id
  }
}
    `;
export const useGradeF2PHubContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<GradeF2PHubContestMutation, TError, GradeF2PHubContestMutationVariables, TContext>) =>
    useMutation<GradeF2PHubContestMutation, TError, GradeF2PHubContestMutationVariables, TContext>(
      ['GradeF2PHubContest'],
      useFetchData<GradeF2PHubContestMutation, GradeF2PHubContestMutationVariables>(GradeF2PHubContestDocument),
      options
    );
export const GradeHubContestDocument = `
    mutation GradeHubContest($data: GradeHubContestInput!) {
  gradeHubContest(data: $data) {
    id
    player1FullName
    player1PhotoUrl
    player1BonusPoints
    player1Game
    player1GameStartTime
    player2FullName
    player2PhotoUrl
    player2BonusPoints
    player2Game
    player2GameStartTime
    published
    player1FantasyPoints
    player2FantasyPoints
    winnerLabel
    status
    createdAt
    updatedAt
  }
}
    `;
export const useGradeHubContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<GradeHubContestMutation, TError, GradeHubContestMutationVariables, TContext>) =>
    useMutation<GradeHubContestMutation, TError, GradeHubContestMutationVariables, TContext>(
      ['GradeHubContest'],
      useFetchData<GradeHubContestMutation, GradeHubContestMutationVariables>(GradeHubContestDocument),
      options
    );
export const GradePartnerHubContestDocument = `
    mutation GradePartnerHubContest($data: GradeHubContestInputDTO!) {
  gradePartnerHubContest(data: $data) {
    id
  }
}
    `;
export const useGradePartnerHubContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<GradePartnerHubContestMutation, TError, GradePartnerHubContestMutationVariables, TContext>) =>
    useMutation<GradePartnerHubContestMutation, TError, GradePartnerHubContestMutationVariables, TContext>(
      ['GradePartnerHubContest'],
      useFetchData<GradePartnerHubContestMutation, GradePartnerHubContestMutationVariables>(GradePartnerHubContestDocument),
      options
    );
export const GradeTournamentDocument = `
    mutation GradeTournament($year: Int, $tournamentId: String) {
  gradeTournament(year: $year, tournamentId: $tournamentId) {
    success
  }
}
    `;
export const useGradeTournamentMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<GradeTournamentMutation, TError, GradeTournamentMutationVariables, TContext>) =>
    useMutation<GradeTournamentMutation, TError, GradeTournamentMutationVariables, TContext>(
      ['GradeTournament'],
      useFetchData<GradeTournamentMutation, GradeTournamentMutationVariables>(GradeTournamentDocument),
      options
    );
export const ImportTournamentsDocument = `
    mutation ImportTournaments($data: [FJTournamentInputType!]!) {
  importTournaments(data: $data) {
    success
    message
  }
}
    `;
export const useImportTournamentsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ImportTournamentsMutation, TError, ImportTournamentsMutationVariables, TContext>) =>
    useMutation<ImportTournamentsMutation, TError, ImportTournamentsMutationVariables, TContext>(
      ['ImportTournaments'],
      useFetchData<ImportTournamentsMutation, ImportTournamentsMutationVariables>(ImportTournamentsDocument),
      options
    );
export const LivePrizePoolDocument = `
    mutation LivePrizePool {
  livePrizePool
}
    `;
export const useLivePrizePoolMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<LivePrizePoolMutation, TError, LivePrizePoolMutationVariables, TContext>) =>
    useMutation<LivePrizePoolMutation, TError, LivePrizePoolMutationVariables, TContext>(
      ['LivePrizePool'],
      useFetchData<LivePrizePoolMutation, LivePrizePoolMutationVariables>(LivePrizePoolDocument),
      options
    );
export const OrderContestsDocument = `
    mutation OrderContests($data: [ContestOrderInput!]!) {
  orderContests(data: $data)
}
    `;
export const useOrderContestsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<OrderContestsMutation, TError, OrderContestsMutationVariables, TContext>) =>
    useMutation<OrderContestsMutation, TError, OrderContestsMutationVariables, TContext>(
      ['OrderContests'],
      useFetchData<OrderContestsMutation, OrderContestsMutationVariables>(OrderContestsDocument),
      options
    );
export const OrderF2PContestsDocument = `
    mutation OrderF2PContests($data: [ContestOrderF2PInput!]!, $prizePoolId: Int!, $orderFeatureContest: Boolean!) {
  orderF2PContests(
    data: $data
    prizePoolId: $prizePoolId
    orderFeatureContest: $orderFeatureContest
  ) {
    message
  }
}
    `;
export const useOrderF2PContestsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<OrderF2PContestsMutation, TError, OrderF2PContestsMutationVariables, TContext>) =>
    useMutation<OrderF2PContestsMutation, TError, OrderF2PContestsMutationVariables, TContext>(
      ['OrderF2PContests'],
      useFetchData<OrderF2PContestsMutation, OrderF2PContestsMutationVariables>(OrderF2PContestsDocument),
      options
    );
export const OrderF2PSportDocument = `
    mutation OrderF2PSport($data: F2POrderSportsDTO!) {
  orderF2PSport(data: $data) {
    id
    name
    logoUrl
    winCriteria
    enabled
    orderIndex
    partnerId
  }
}
    `;
export const useOrderF2PSportMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<OrderF2PSportMutation, TError, OrderF2PSportMutationVariables, TContext>) =>
    useMutation<OrderF2PSportMutation, TError, OrderF2PSportMutationVariables, TContext>(
      ['OrderF2PSport'],
      useFetchData<OrderF2PSportMutation, OrderF2PSportMutationVariables>(OrderF2PSportDocument),
      options
    );
export const OrderNflMatchupsDocument = `
    mutation OrderNflMatchups($data: [SuggestionOrderInput!]!) {
  orderMatchups(data: $data) {
    id
  }
}
    `;
export const useOrderNflMatchupsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<OrderNflMatchupsMutation, TError, OrderNflMatchupsMutationVariables, TContext>) =>
    useMutation<OrderNflMatchupsMutation, TError, OrderNflMatchupsMutationVariables, TContext>(
      ['OrderNflMatchups'],
      useFetchData<OrderNflMatchupsMutation, OrderNflMatchupsMutationVariables>(OrderNflMatchupsDocument),
      options
    );
export const OrderPartnerContestsDocument = `
    mutation OrderPartnerContests($data: [ContestOrderPartnerInput!]!, $prizePoolId: Int!) {
  orderPartnerContests(data: $data, prizePoolId: $prizePoolId) {
    message
  }
}
    `;
export const useOrderPartnerContestsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<OrderPartnerContestsMutation, TError, OrderPartnerContestsMutationVariables, TContext>) =>
    useMutation<OrderPartnerContestsMutation, TError, OrderPartnerContestsMutationVariables, TContext>(
      ['OrderPartnerContests'],
      useFetchData<OrderPartnerContestsMutation, OrderPartnerContestsMutationVariables>(OrderPartnerContestsDocument),
      options
    );
export const OrderPartnerSportDocument = `
    mutation OrderPartnerSport($data: OrderSportsDTO!) {
  orderPartnerSport(data: $data) {
    id
    name
    logoUrl
    winCriteria
    enabled
    orderIndex
    partnerId
  }
}
    `;
export const useOrderPartnerSportMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<OrderPartnerSportMutation, TError, OrderPartnerSportMutationVariables, TContext>) =>
    useMutation<OrderPartnerSportMutation, TError, OrderPartnerSportMutationVariables, TContext>(
      ['OrderPartnerSport'],
      useFetchData<OrderPartnerSportMutation, OrderPartnerSportMutationVariables>(OrderPartnerSportDocument),
      options
    );
export const OrderSportsDocument = `
    mutation OrderSports($data: [SportOrderInput!]!) {
  orderSports(data: $data) {
    id
  }
}
    `;
export const useOrderSportsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<OrderSportsMutation, TError, OrderSportsMutationVariables, TContext>) =>
    useMutation<OrderSportsMutation, TError, OrderSportsMutationVariables, TContext>(
      ['OrderSports'],
      useFetchData<OrderSportsMutation, OrderSportsMutationVariables>(OrderSportsDocument),
      options
    );
export const PublishF2PHubContestDocument = `
    mutation PublishF2PHubContest($contestId: Int!, $published: Boolean!) {
  publishF2PHubContest(contestId: $contestId, published: $published) {
    id
  }
}
    `;
export const usePublishF2PHubContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<PublishF2PHubContestMutation, TError, PublishF2PHubContestMutationVariables, TContext>) =>
    useMutation<PublishF2PHubContestMutation, TError, PublishF2PHubContestMutationVariables, TContext>(
      ['PublishF2PHubContest'],
      useFetchData<PublishF2PHubContestMutation, PublishF2PHubContestMutationVariables>(PublishF2PHubContestDocument),
      options
    );
export const PublishF2PPrizePoolDocument = `
    mutation PublishF2PPrizePool($prizePoolId: Int!) {
  publishF2PPrizePool(prizePoolId: $prizePoolId)
}
    `;
export const usePublishF2PPrizePoolMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<PublishF2PPrizePoolMutation, TError, PublishF2PPrizePoolMutationVariables, TContext>) =>
    useMutation<PublishF2PPrizePoolMutation, TError, PublishF2PPrizePoolMutationVariables, TContext>(
      ['PublishF2PPrizePool'],
      useFetchData<PublishF2PPrizePoolMutation, PublishF2PPrizePoolMutationVariables>(PublishF2PPrizePoolDocument),
      options
    );
export const PublishHubContestDocument = `
    mutation PublishHubContest($contestId: Int!, $published: Boolean!) {
  publishHubContest(contestId: $contestId, published: $published) {
    id
    player1FullName
    player1PhotoUrl
    player1BonusPoints
    player1Game
    player1GameStartTime
    player2FullName
    player2PhotoUrl
    player2BonusPoints
    player2Game
    player2GameStartTime
    published
    player1FantasyPoints
    player2FantasyPoints
    winnerLabel
    status
    createdAt
    updatedAt
  }
}
    `;
export const usePublishHubContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<PublishHubContestMutation, TError, PublishHubContestMutationVariables, TContext>) =>
    useMutation<PublishHubContestMutation, TError, PublishHubContestMutationVariables, TContext>(
      ['PublishHubContest'],
      useFetchData<PublishHubContestMutation, PublishHubContestMutationVariables>(PublishHubContestDocument),
      options
    );
export const PublishPartnerCommonContestDocument = `
    mutation PublishPartnerCommonContest($data: PublishCommonContestInput!) {
  publishPartnerCommonContest(data: $data) {
    message
  }
}
    `;
export const usePublishPartnerCommonContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<PublishPartnerCommonContestMutation, TError, PublishPartnerCommonContestMutationVariables, TContext>) =>
    useMutation<PublishPartnerCommonContestMutation, TError, PublishPartnerCommonContestMutationVariables, TContext>(
      ['PublishPartnerCommonContest'],
      useFetchData<PublishPartnerCommonContestMutation, PublishPartnerCommonContestMutationVariables>(PublishPartnerCommonContestDocument),
      options
    );
export const PublishPartnerHubContestDocument = `
    mutation PublishPartnerHubContest($contestId: Int!, $published: Boolean!) {
  publishPartnerHubContest(contestId: $contestId, published: $published) {
    id
  }
}
    `;
export const usePublishPartnerHubContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<PublishPartnerHubContestMutation, TError, PublishPartnerHubContestMutationVariables, TContext>) =>
    useMutation<PublishPartnerHubContestMutation, TError, PublishPartnerHubContestMutationVariables, TContext>(
      ['PublishPartnerHubContest'],
      useFetchData<PublishPartnerHubContestMutation, PublishPartnerHubContestMutationVariables>(PublishPartnerHubContestDocument),
      options
    );
export const PublishPartnerPrizePoolDocument = `
    mutation PublishPartnerPrizePool($prizePoolId: Int!) {
  publishPartnerPrizePool(prizePoolId: $prizePoolId)
}
    `;
export const usePublishPartnerPrizePoolMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<PublishPartnerPrizePoolMutation, TError, PublishPartnerPrizePoolMutationVariables, TContext>) =>
    useMutation<PublishPartnerPrizePoolMutation, TError, PublishPartnerPrizePoolMutationVariables, TContext>(
      ['PublishPartnerPrizePool'],
      useFetchData<PublishPartnerPrizePoolMutation, PublishPartnerPrizePoolMutationVariables>(PublishPartnerPrizePoolDocument),
      options
    );
export const RegeneratePartnerKeyDocument = `
    mutation RegeneratePartnerKey($data: RegeneratePartnerKeyDTO!) {
  regeneratePartnerKey(data: $data) {
    id
    name
    email
    contact
    active
  }
}
    `;
export const useRegeneratePartnerKeyMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RegeneratePartnerKeyMutation, TError, RegeneratePartnerKeyMutationVariables, TContext>) =>
    useMutation<RegeneratePartnerKeyMutation, TError, RegeneratePartnerKeyMutationVariables, TContext>(
      ['RegeneratePartnerKey'],
      useFetchData<RegeneratePartnerKeyMutation, RegeneratePartnerKeyMutationVariables>(RegeneratePartnerKeyDocument),
      options
    );
export const SendContestGradedEmailDocument = `
    mutation SendContestGradedEmail($contestIds: String!) {
  sendContestGradedEmail(contestIds: $contestIds) {
    success
  }
}
    `;
export const useSendContestGradedEmailMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SendContestGradedEmailMutation, TError, SendContestGradedEmailMutationVariables, TContext>) =>
    useMutation<SendContestGradedEmailMutation, TError, SendContestGradedEmailMutationVariables, TContext>(
      ['SendContestGradedEmail'],
      useFetchData<SendContestGradedEmailMutation, SendContestGradedEmailMutationVariables>(SendContestGradedEmailDocument),
      options
    );
export const SyncTournamentPlayersDocument = `
    mutation SyncTournamentPlayers {
  syncTournamentPlayers {
    success
  }
}
    `;
export const useSyncTournamentPlayersMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SyncTournamentPlayersMutation, TError, SyncTournamentPlayersMutationVariables, TContext>) =>
    useMutation<SyncTournamentPlayersMutation, TError, SyncTournamentPlayersMutationVariables, TContext>(
      ['SyncTournamentPlayers'],
      useFetchData<SyncTournamentPlayersMutation, SyncTournamentPlayersMutationVariables>(SyncTournamentPlayersDocument),
      options
    );
export const SyncTournamentsDocument = `
    mutation SyncTournaments {
  syncTournaments {
    success
  }
}
    `;
export const useSyncTournamentsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SyncTournamentsMutation, TError, SyncTournamentsMutationVariables, TContext>) =>
    useMutation<SyncTournamentsMutation, TError, SyncTournamentsMutationVariables, TContext>(
      ['SyncTournaments'],
      useFetchData<SyncTournamentsMutation, SyncTournamentsMutationVariables>(SyncTournamentsDocument),
      options
    );
export const TournamentsDocument = `
    query Tournaments {
  tournaments {
    id
    isActive
    remoteId
    year
    name
    purse
    startDate
    endDate
  }
}
    `;
export const useTournamentsQuery = <
      TData = TournamentsQuery,
      TError = unknown
    >(
      variables?: TournamentsQueryVariables,
      options?: UseQueryOptions<TournamentsQuery, TError, TData>
    ) =>
    useQuery<TournamentsQuery, TError, TData>(
      variables === undefined ? ['Tournaments'] : ['Tournaments', variables],
      useFetchData<TournamentsQuery, TournamentsQueryVariables>(TournamentsDocument).bind(null, variables),
      options
    );

useTournamentsQuery.getKey = (variables?: TournamentsQueryVariables) => variables === undefined ? ['Tournaments'] : ['Tournaments', variables];
;

export const UpdateAdminActiveStatusDocument = `
    mutation UpdateAdminActiveStatus($data: PartnerAdminActiveInputDto!) {
  updateAdminActiveStatus(data: $data) {
    id
    fullName
    email
    phone
    active
  }
}
    `;
export const useUpdateAdminActiveStatusMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateAdminActiveStatusMutation, TError, UpdateAdminActiveStatusMutationVariables, TContext>) =>
    useMutation<UpdateAdminActiveStatusMutation, TError, UpdateAdminActiveStatusMutationVariables, TContext>(
      ['UpdateAdminActiveStatus'],
      useFetchData<UpdateAdminActiveStatusMutation, UpdateAdminActiveStatusMutationVariables>(UpdateAdminActiveStatusDocument),
      options
    );
export const UpdateCommonHubContestDocument = `
    mutation UpdateCommonHubContest($data: UpdateCommonHubContestInput!) {
  updateCommonHubContest(data: $data) {
    id
    player1Id
    player1FullName
    player1PhotoUrl
    player1BonusPoints
    player1Game
    player1GameStartTime
    player1FantasyPoints
    player2Id
    player2FullName
    player2PhotoUrl
    player2BonusPoints
    player2Game
    player2GameStartTime
    player2FantasyPoints
    status
    winnerPlayerId
    winnerLabel
    contestDate
  }
}
    `;
export const useUpdateCommonHubContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateCommonHubContestMutation, TError, UpdateCommonHubContestMutationVariables, TContext>) =>
    useMutation<UpdateCommonHubContestMutation, TError, UpdateCommonHubContestMutationVariables, TContext>(
      ['UpdateCommonHubContest'],
      useFetchData<UpdateCommonHubContestMutation, UpdateCommonHubContestMutationVariables>(UpdateCommonHubContestDocument),
      options
    );
export const UpdateCommonSportDocument = `
    mutation UpdateCommonSport($data: UpdateCommonSportDTO!) {
  updateCommonSport(data: $data) {
    id
    name
    key
    logoUrl
    winCriteria
    questions {
      id
      question
      metric
    }
  }
}
    `;
export const useUpdateCommonSportMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateCommonSportMutation, TError, UpdateCommonSportMutationVariables, TContext>) =>
    useMutation<UpdateCommonSportMutation, TError, UpdateCommonSportMutationVariables, TContext>(
      ['UpdateCommonSport'],
      useFetchData<UpdateCommonSportMutation, UpdateCommonSportMutationVariables>(UpdateCommonSportDocument),
      options
    );
export const UpdateF2PHubContestDocument = `
    mutation UpdateF2PHubContest($data: UpdateF2PHubContestInputDTO!) {
  updateF2PHubContest(data: $data) {
    id
  }
}
    `;
export const useUpdateF2PHubContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateF2PHubContestMutation, TError, UpdateF2PHubContestMutationVariables, TContext>) =>
    useMutation<UpdateF2PHubContestMutation, TError, UpdateF2PHubContestMutationVariables, TContext>(
      ['UpdateF2PHubContest'],
      useFetchData<UpdateF2PHubContestMutation, UpdateF2PHubContestMutationVariables>(UpdateF2PHubContestDocument),
      options
    );
export const UpdateF2PNbaContestDocument = `
    mutation UpdateF2PNbaContest($data: UpdateF2PNbaContestInput!) {
  updateF2PNbaContest(data: $data) {
    id
  }
}
    `;
export const useUpdateF2PNbaContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateF2PNbaContestMutation, TError, UpdateF2PNbaContestMutationVariables, TContext>) =>
    useMutation<UpdateF2PNbaContestMutation, TError, UpdateF2PNbaContestMutationVariables, TContext>(
      ['UpdateF2PNbaContest'],
      useFetchData<UpdateF2PNbaContestMutation, UpdateF2PNbaContestMutationVariables>(UpdateF2PNbaContestDocument),
      options
    );
export const UpdateF2PPartnerDocument = `
    mutation UpdateF2PPartner($data: UpdateF2PPartnerInput!) {
  updateF2PPartner(data: $data) {
    id
    name
    email
    contact
    identifier
    logo
    description
    primaryColor
    secondaryColor
    active
    createdBy
    enableUserAuth
  }
}
    `;
export const useUpdateF2PPartnerMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateF2PPartnerMutation, TError, UpdateF2PPartnerMutationVariables, TContext>) =>
    useMutation<UpdateF2PPartnerMutation, TError, UpdateF2PPartnerMutationVariables, TContext>(
      ['UpdateF2PPartner'],
      useFetchData<UpdateF2PPartnerMutation, UpdateF2PPartnerMutationVariables>(UpdateF2PPartnerDocument),
      options
    );
export const UpdateF2PPartnerActiveStatusDocument = `
    mutation UpdateF2PPartnerActiveStatus($data: F2PPartnerActiveInputDto!) {
  updateF2PPartnerActiveStatus(data: $data) {
    id
    name
    email
    contact
    identifier
    logo
    description
    primaryColor
    secondaryColor
    active
    createdBy
  }
}
    `;
export const useUpdateF2PPartnerActiveStatusMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateF2PPartnerActiveStatusMutation, TError, UpdateF2PPartnerActiveStatusMutationVariables, TContext>) =>
    useMutation<UpdateF2PPartnerActiveStatusMutation, TError, UpdateF2PPartnerActiveStatusMutationVariables, TContext>(
      ['UpdateF2PPartnerActiveStatus'],
      useFetchData<UpdateF2PPartnerActiveStatusMutation, UpdateF2PPartnerActiveStatusMutationVariables>(UpdateF2PPartnerActiveStatusDocument),
      options
    );
export const UpdateF2PSoccerTouchdownContestDocument = `
    mutation UpdateF2PSoccerTouchdownContest($data: UpdateF2PSoccerContestInput!) {
  updateF2PSoccerTouchdownContest(data: $data) {
    id
  }
}
    `;
export const useUpdateF2PSoccerTouchdownContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateF2PSoccerTouchdownContestMutation, TError, UpdateF2PSoccerTouchdownContestMutationVariables, TContext>) =>
    useMutation<UpdateF2PSoccerTouchdownContestMutation, TError, UpdateF2PSoccerTouchdownContestMutationVariables, TContext>(
      ['UpdateF2PSoccerTouchdownContest'],
      useFetchData<UpdateF2PSoccerTouchdownContestMutation, UpdateF2PSoccerTouchdownContestMutationVariables>(UpdateF2PSoccerTouchdownContestDocument),
      options
    );
export const UpdateF2PSportDocument = `
    mutation UpdateF2PSport($data: F2PUpdateSportDTO!) {
  updateF2PSport(data: $data) {
    id
    name
    logoUrl
    winCriteria
    enabled
    orderIndex
    partnerId
  }
}
    `;
export const useUpdateF2PSportMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateF2PSportMutation, TError, UpdateF2PSportMutationVariables, TContext>) =>
    useMutation<UpdateF2PSportMutation, TError, UpdateF2PSportMutationVariables, TContext>(
      ['UpdateF2PSport'],
      useFetchData<UpdateF2PSportMutation, UpdateF2PSportMutationVariables>(UpdateF2PSportDocument),
      options
    );
export const UpdateF2PTouchdownDocument = `
    mutation UpdateF2PTouchdown($data: F2PUpdateTouchdownInputDTO!) {
  updateF2PTouchdown(data: $data) {
    id
    name
    startDateTime
    endDateTime
    allowedPick
    createdAt
    updatedAt
    deletedAt
    prizePools {
      startDateTime
      endDateTime
      allowedSports
      lockTime
      maxEntriesPerUser
      totalEntrants
      id
      status
      createdAt
      updatedAt
    }
  }
}
    `;
export const useUpdateF2PTouchdownMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateF2PTouchdownMutation, TError, UpdateF2PTouchdownMutationVariables, TContext>) =>
    useMutation<UpdateF2PTouchdownMutation, TError, UpdateF2PTouchdownMutationVariables, TContext>(
      ['UpdateF2PTouchdown'],
      useFetchData<UpdateF2PTouchdownMutation, UpdateF2PTouchdownMutationVariables>(UpdateF2PTouchdownDocument),
      options
    );
export const UpdateFeatureFlagsDocument = `
    mutation UpdateFeatureFlags($data: FeatureFlagInputType!) {
  updateFeatureFlags(data: $data) {
    roundByRoundContestCreationEnabled
    tournamentContestCreationEnabled
  }
}
    `;
export const useUpdateFeatureFlagsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateFeatureFlagsMutation, TError, UpdateFeatureFlagsMutationVariables, TContext>) =>
    useMutation<UpdateFeatureFlagsMutation, TError, UpdateFeatureFlagsMutationVariables, TContext>(
      ['UpdateFeatureFlags'],
      useFetchData<UpdateFeatureFlagsMutation, UpdateFeatureFlagsMutationVariables>(UpdateFeatureFlagsDocument),
      options
    );
export const UpdateFeaturedContestDocument = `
    mutation UpdateFeaturedContest($oldMatchupId: Int, $newMatchupId: Int!) {
  updateFeaturedContest(
    data: {oldMatchupId: $oldMatchupId, newMatchupId: $newMatchupId}
  )
}
    `;
export const useUpdateFeaturedContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateFeaturedContestMutation, TError, UpdateFeaturedContestMutationVariables, TContext>) =>
    useMutation<UpdateFeaturedContestMutation, TError, UpdateFeaturedContestMutationVariables, TContext>(
      ['UpdateFeaturedContest'],
      useFetchData<UpdateFeaturedContestMutation, UpdateFeaturedContestMutationVariables>(UpdateFeaturedContestDocument),
      options
    );
export const UpdateHubContestDocument = `
    mutation UpdateHubContest($data: UpdateHubContestInput!) {
  updateHubContest(data: $data) {
    id
    player1FullName
    player1PhotoUrl
    player1BonusPoints
    player1Game
    player1GameStartTime
    player2FullName
    player2PhotoUrl
    player2BonusPoints
    player2Game
    player2GameStartTime
    published
    player1FantasyPoints
    player2FantasyPoints
    winnerLabel
    status
    createdAt
    updatedAt
  }
}
    `;
export const useUpdateHubContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateHubContestMutation, TError, UpdateHubContestMutationVariables, TContext>) =>
    useMutation<UpdateHubContestMutation, TError, UpdateHubContestMutationVariables, TContext>(
      ['UpdateHubContest'],
      useFetchData<UpdateHubContestMutation, UpdateHubContestMutationVariables>(UpdateHubContestDocument),
      options
    );
export const UpdatePartnerDocument = `
    mutation UpdatePartner($data: UpdatePartnerDTO!) {
  updatePartner(data: $data) {
    id
  }
}
    `;
export const useUpdatePartnerMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdatePartnerMutation, TError, UpdatePartnerMutationVariables, TContext>) =>
    useMutation<UpdatePartnerMutation, TError, UpdatePartnerMutationVariables, TContext>(
      ['UpdatePartner'],
      useFetchData<UpdatePartnerMutation, UpdatePartnerMutationVariables>(UpdatePartnerDocument),
      options
    );
export const UpdatePartnerActiveStatusDocument = `
    mutation UpdatePartnerActiveStatus($data: PartnerActiveInputDto!) {
  updatePartnerActiveStatus(data: $data) {
    id
  }
}
    `;
export const useUpdatePartnerActiveStatusMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdatePartnerActiveStatusMutation, TError, UpdatePartnerActiveStatusMutationVariables, TContext>) =>
    useMutation<UpdatePartnerActiveStatusMutation, TError, UpdatePartnerActiveStatusMutationVariables, TContext>(
      ['UpdatePartnerActiveStatus'],
      useFetchData<UpdatePartnerActiveStatusMutation, UpdatePartnerActiveStatusMutationVariables>(UpdatePartnerActiveStatusDocument),
      options
    );
export const UpdatePartnerAdminDocument = `
    mutation UpdatePartnerAdmin($data: UpdatePartnerAdminInputDto!) {
  updatePartnerAdmin(data: $data) {
    id
    fullName
    email
    phone
    active
  }
}
    `;
export const useUpdatePartnerAdminMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdatePartnerAdminMutation, TError, UpdatePartnerAdminMutationVariables, TContext>) =>
    useMutation<UpdatePartnerAdminMutation, TError, UpdatePartnerAdminMutationVariables, TContext>(
      ['UpdatePartnerAdmin'],
      useFetchData<UpdatePartnerAdminMutation, UpdatePartnerAdminMutationVariables>(UpdatePartnerAdminDocument),
      options
    );
export const UpdatePartnerHubContestDocument = `
    mutation UpdatePartnerHubContest($data: UpdateHubContestInputDTO!) {
  updatePartnerHubContest(data: $data) {
    id
  }
}
    `;
export const useUpdatePartnerHubContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdatePartnerHubContestMutation, TError, UpdatePartnerHubContestMutationVariables, TContext>) =>
    useMutation<UpdatePartnerHubContestMutation, TError, UpdatePartnerHubContestMutationVariables, TContext>(
      ['UpdatePartnerHubContest'],
      useFetchData<UpdatePartnerHubContestMutation, UpdatePartnerHubContestMutationVariables>(UpdatePartnerHubContestDocument),
      options
    );
export const UpdatePartnerPrizeConstantDocument = `
    mutation UpdatePartnerPrizeConstant($data: PartnerUpdatePrizeConstantInputDTO!) {
  updatePartnerPrizeConstant(prizeConstant: $data) {
    prizeConfiguration {
      GPP
      PRIZE_POOL
      WEEKLY_RESERVE
    }
  }
}
    `;
export const useUpdatePartnerPrizeConstantMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdatePartnerPrizeConstantMutation, TError, UpdatePartnerPrizeConstantMutationVariables, TContext>) =>
    useMutation<UpdatePartnerPrizeConstantMutation, TError, UpdatePartnerPrizeConstantMutationVariables, TContext>(
      ['UpdatePartnerPrizeConstant'],
      useFetchData<UpdatePartnerPrizeConstantMutation, UpdatePartnerPrizeConstantMutationVariables>(UpdatePartnerPrizeConstantDocument),
      options
    );
export const UpdatePartnerSportDocument = `
    mutation UpdatePartnerSport($data: UpdateSportDTO!) {
  updatePartnerSport(data: $data) {
    id
  }
}
    `;
export const useUpdatePartnerSportMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdatePartnerSportMutation, TError, UpdatePartnerSportMutationVariables, TContext>) =>
    useMutation<UpdatePartnerSportMutation, TError, UpdatePartnerSportMutationVariables, TContext>(
      ['UpdatePartnerSport'],
      useFetchData<UpdatePartnerSportMutation, UpdatePartnerSportMutationVariables>(UpdatePartnerSportDocument),
      options
    );
export const UpdatePartnerTouchdownDocument = `
    mutation UpdatePartnerTouchdown($data: PartnerUpdateTouchdownInputDTO!) {
  updatePartnerTouchdown(data: $data) {
    id
  }
}
    `;
export const useUpdatePartnerTouchdownMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdatePartnerTouchdownMutation, TError, UpdatePartnerTouchdownMutationVariables, TContext>) =>
    useMutation<UpdatePartnerTouchdownMutation, TError, UpdatePartnerTouchdownMutationVariables, TContext>(
      ['UpdatePartnerTouchdown'],
      useFetchData<UpdatePartnerTouchdownMutation, UpdatePartnerTouchdownMutationVariables>(UpdatePartnerTouchdownDocument),
      options
    );
export const UpdatePrizePoolDocument = `
    mutation UpdatePrizePool($data: CreatePrizePoolInput!, $prizePoolId: Float!) {
  updatePrizePool(data: $data, prizePoolId: $prizePoolId) {
    prizePoolId
    entryFees
    totalEntrants
    supportedSports
    maxEntriesPerUser
    predeterminePrizePool
    predetermineDailyGPP
    predetermineWeeklyReserve
    predetermineJackpot
    predetermineContestVig
    actualPrizePool
    actualDailyGPP
    actualWeeklyReserve
    actualJackpot
    actualContestVig
    isRollsOver
    status
    startDate
    endDate
    prizeDate
  }
}
    `;
export const useUpdatePrizePoolMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdatePrizePoolMutation, TError, UpdatePrizePoolMutationVariables, TContext>) =>
    useMutation<UpdatePrizePoolMutation, TError, UpdatePrizePoolMutationVariables, TContext>(
      ['UpdatePrizePool'],
      useFetchData<UpdatePrizePoolMutation, UpdatePrizePoolMutationVariables>(UpdatePrizePoolDocument),
      options
    );
export const UpdateSportDocument = `
    mutation UpdateSport($data: UpdateSportInput!) {
  updateSport(data: $data) {
    id
    name
    logoUrl
    winCriteria
    enabled
    questions {
      id
      question
    }
  }
}
    `;
export const useUpdateSportMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateSportMutation, TError, UpdateSportMutationVariables, TContext>) =>
    useMutation<UpdateSportMutation, TError, UpdateSportMutationVariables, TContext>(
      ['UpdateSport'],
      useFetchData<UpdateSportMutation, UpdateSportMutationVariables>(UpdateSportDocument),
      options
    );
export const UpdateSuggestionDocument = `
    mutation UpdateSuggestion($oppenentId: String!, $suggestionId: Int!) {
  updateSuggestion(data: {oppenentId: $oppenentId, suggestionId: $suggestionId}) {
    success
  }
}
    `;
export const useUpdateSuggestionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateSuggestionMutation, TError, UpdateSuggestionMutationVariables, TContext>) =>
    useMutation<UpdateSuggestionMutation, TError, UpdateSuggestionMutationVariables, TContext>(
      ['UpdateSuggestion'],
      useFetchData<UpdateSuggestionMutation, UpdateSuggestionMutationVariables>(UpdateSuggestionDocument),
      options
    );
export const UpdateTournamentsDocument = `
    mutation UpdateTournaments($data: TournamentsStateInputType!) {
  updateTournaments(data: $data) {
    success
    message
  }
}
    `;
export const useUpdateTournamentsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateTournamentsMutation, TError, UpdateTournamentsMutationVariables, TContext>) =>
    useMutation<UpdateTournamentsMutation, TError, UpdateTournamentsMutationVariables, TContext>(
      ['UpdateTournaments'],
      useFetchData<UpdateTournamentsMutation, UpdateTournamentsMutationVariables>(UpdateTournamentsDocument),
      options
    );
export const UserActivationDocument = `
    mutation UserActivation($userId: Int!) {
  userActivation(userId: $userId) {
    message
    succcess
  }
}
    `;
export const useUserActivationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UserActivationMutation, TError, UserActivationMutationVariables, TContext>) =>
    useMutation<UserActivationMutation, TError, UserActivationMutationVariables, TContext>(
      ['UserActivation'],
      useFetchData<UserActivationMutation, UserActivationMutationVariables>(UserActivationDocument),
      options
    );
export const VoidContestDocument = `
    mutation VoidContest($contestId: Int!) {
  voidContest(data: {contestId: $contestId}) {
    id
  }
}
    `;
export const useVoidContestMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<VoidContestMutation, TError, VoidContestMutationVariables, TContext>) =>
    useMutation<VoidContestMutation, TError, VoidContestMutationVariables, TContext>(
      ['VoidContest'],
      useFetchData<VoidContestMutation, VoidContestMutationVariables>(VoidContestDocument),
      options
    );
export const VoidContestsDocument = `
    mutation VoidContests {
  voidContests {
    success
  }
}
    `;
export const useVoidContestsMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<VoidContestsMutation, TError, VoidContestsMutationVariables, TContext>) =>
    useMutation<VoidContestsMutation, TError, VoidContestsMutationVariables, TContext>(
      ['VoidContests'],
      useFetchData<VoidContestsMutation, VoidContestsMutationVariables>(VoidContestsDocument),
      options
    );
export const WalletBonusDepositDocument = `
    mutation WalletBonusDeposit($data: WalletDepositDTO!) {
  walletBonusDeposit(data: $data) {
    message
  }
}
    `;
export const useWalletBonusDepositMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<WalletBonusDepositMutation, TError, WalletBonusDepositMutationVariables, TContext>) =>
    useMutation<WalletBonusDepositMutation, TError, WalletBonusDepositMutationVariables, TContext>(
      ['WalletBonusDeposit'],
      useFetchData<WalletBonusDepositMutation, WalletBonusDepositMutationVariables>(WalletBonusDepositDocument),
      options
    );
export const WithdrawRequestActionDocument = `
    mutation WithdrawRequestAction($data: WithdrawRequestActionDto!) {
  withdrawRequestAction(data: $data) {
    message
  }
}
    `;
export const useWithdrawRequestActionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<WithdrawRequestActionMutation, TError, WithdrawRequestActionMutationVariables, TContext>) =>
    useMutation<WithdrawRequestActionMutation, TError, WithdrawRequestActionMutationVariables, TContext>(
      ['WithdrawRequestAction'],
      useFetchData<WithdrawRequestActionMutation, WithdrawRequestActionMutationVariables>(WithdrawRequestActionDocument),
      options
    );