import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { theme } from "@src/constants/theme";
import { AppRoutes } from "./Routes";
import { RecoilRoot } from "recoil";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import objectSupport from "dayjs/plugin/objectSupport";
import timezone from "dayjs/plugin/timezone";
import updateLocale from "dayjs/plugin/updateLocale";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";
import minMax from "dayjs/plugin/minMax";
import { PartnerProvider } from "./context/PartnerContext";

dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  weekStart: 1,
});
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(objectSupport);
dayjs.extend(weekday);
dayjs.extend(isBetween);
dayjs.extend(minMax);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000, // 60 sec
    },
  },
});

ReactDOM.render(
  <BrowserRouter basename="/admin">
    <StrictMode>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <PartnerProvider>
              <CssBaseline />
              <AppRoutes />
            </PartnerProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </RecoilRoot>
    </StrictMode>
  </BrowserRouter>,
  document.getElementById("root")
);
