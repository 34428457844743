import { TouchdownSummary } from "@src/components/f2pPartner/Dashboard/TouchdownSummary";
import { SignedInLayout } from "@src/components/templates/Layouts";
import {
  useGetAllPartnerQuery,
  useGetF2PActiveTouchdownsQuery,
  useGetF2PTouchdownAnalyticsQuery,
  useGetPartnerTouchdownAnalyticsQuery,
} from "@src/graphql/graphql.generated";
import TouchdownChart from "./TouchdownChart";
import { ActiveTouchdown } from "@src/components/f2pPartner/Dashboard/ActiveTouchdown";
import { ActiveTouchdownDetails } from "@src/components/f2pPartner/Dashboard/ActiveTouchdownDetails";
import { useState } from "react";
import { formatDate } from "@src/utils/utils";
import { useAuth } from "@src/hooks/useAuth";
import { usePartner } from "@src/context/PartnerContext";

export function PartnerDashboard() {
  const { partnerId: currentPartnerId }: any = usePartner();
  const { data: currentWeekTouchdownData } = useGetF2PActiveTouchdownsQuery(
    {
      partnerId: currentPartnerId,
    },
    {
      staleTime: 300_000, // 5 min
      enabled: Number(currentPartnerId) > 0,
    }
  );
  const { data: partnerTouchdownAnalytics } = useGetF2PTouchdownAnalyticsQuery({
    partnerId: currentPartnerId,
  });
  const touchdownCount =
    partnerTouchdownAnalytics?.getF2PTouchdownAnalytics?.touchdownsPerMonth;
  const activeTouchdowns = currentWeekTouchdownData?.getF2PActiveTouchdowns;

  const [activeTouchDownDetails, setActiveTouchDownDetails] =
    useState<boolean>(false);
  const [activeTouchDownIndex, setActiveTouchDownIndex] = useState<number>(0);

  return (
    <SignedInLayout title="Partner Dashboard" showF2pPartners>
      <>
        {activeTouchDownDetails && activeTouchdowns ? (
          <ActiveTouchdownDetails
            title={`${
              activeTouchdowns[activeTouchDownIndex]?.name
            } (${formatDate(
              activeTouchdowns[activeTouchDownIndex]?.startDateTime
            )} to
            ${formatDate(
              activeTouchdowns[activeTouchDownIndex]?.endDateTime
            )})`}
            prizePoolsData={activeTouchdowns[activeTouchDownIndex]?.prizePools}
            activeTouchDownId={activeTouchdowns[activeTouchDownIndex]?.id}
            setActiveTouchDownDetails={setActiveTouchDownDetails}
          />
        ) : (
          <>
            <TouchdownSummary
              title=""
              partnerTouchdownAnalytics={partnerTouchdownAnalytics}
            />
            <TouchdownChart data={touchdownCount} />
            <ActiveTouchdown
              title={"Active Touchdown"}
              activeTouchdownData={activeTouchdowns}
              setActiveTouchDownIndex={setActiveTouchDownIndex}
              setActiveTouchDownDetails={setActiveTouchDownDetails}
            />
          </>
        )}
      </>
    </SignedInLayout>
  );
}
