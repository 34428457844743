import { useMemo, useState, SyntheticEvent, useEffect } from "react";
import {
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
  useRecoilState,
} from "recoil";
import {
  CreateContestStep,
  contestCreationStepState,
  tournamentTypeState,
  tournamentIdState,
  mainPlayerIdState,
  TournamentType,
  opponentPlayerIdsState,
  roundState,
} from "./state";
import {
  Button,
  Stack,
  DialogContent,
  DialogActions,
  ButtonBase,
  Box,
  Typography,
  useTheme,
  CircularProgress,
} from "@mui/material";
import { useGetPlayersForSuggestionQuery } from "@src/graphql/graphql.generated";
import { PlayerImage } from "./PlayerImage";
import { SearchField } from "./SearchField";

interface OpponentsSuggestionProps {
  onSubmit: () => void;
}

export function Opponents(props: OpponentsSuggestionProps) {
  const { onSubmit } = props;
  const resetOpponent = useResetRecoilState(opponentPlayerIdsState);
  const setStep = useSetRecoilState(contestCreationStepState);
  const theme = useTheme();
  const round = useRecoilValue(roundState);
  const [searchString, setSearchString] = useState("");
  const mainPlayerId = useRecoilValue(mainPlayerIdState);
  const tournamentId = useRecoilValue(tournamentIdState);
  const tournamentType = useRecoilValue(tournamentTypeState);
  const [opponentPlayerIds, setOpponentPlayerIds] = useRecoilState(
    opponentPlayerIdsState
  );

  const {
    data: tournamentPlayers,
    isLoading: isTournamentPlayersLoading,
    isError: isTournamentPlayersError,
    refetch: refetchTournamentPlayers,
  } = useGetPlayersForSuggestionQuery({
    tournamentId: tournamentId!,
    contestType: tournamentType,
    roundNumber:
      tournamentType === TournamentType.RoundByRound ? +round! : null,
  });

  const myPlayer = useMemo(
    () =>
      (tournamentPlayers?.getPlayersForSuggestion ?? []).find(
        (p) => p.playerId === mainPlayerId
      ),
    [mainPlayerId, tournamentPlayers]
  );

  const opponentPlayers = useMemo(
    () =>
      (tournamentPlayers?.getPlayersForSuggestion ?? [])
        ?.filter((p) => p.playerId !== mainPlayerId)
        .filter(
          (p) =>
            p.playerId !== mainPlayerId &&
            `${p.firstName} ${p.lastName}`
              .toLocaleLowerCase()
              .includes(searchString.toLocaleLowerCase())
        ),
    [searchString, tournamentPlayers]
  );

  function handleClick(id: string) {
    if (!opponentPlayerIds.includes(id)) {
      setOpponentPlayerIds((opponentPlayerIds) => [...opponentPlayerIds, id]);
    } else {
      setOpponentPlayerIds(
        opponentPlayerIds.filter((opponentPlayerId) => opponentPlayerId !== id)
      );
    }
  }
  function handleChangePlayer() {
    resetOpponent();
    setStep(CreateContestStep.SelectMainPlayer);
  }

  useEffect(() => {
    refetchTournamentPlayers();
  }, [tournamentType, refetchTournamentPlayers, tournamentId]);

  function handleSubmit() {
    onSubmit();
  }

  return (
    <>
      <DialogContent
        sx={{
          display: "flex",
          overflow: "hidden",
          paddingX: 1,
          paddingY: 2.5,
        }}
      >
        {isTournamentPlayersLoading ? (
          <Stack alignItems="center" flex={1} justifyContent="center" m={5}>
            <CircularProgress size={24} />
          </Stack>
        ) : isTournamentPlayersError ? (
          <Typography variant="h5" align="center" m={5}>
            Sorry, players can't be fetched at the moment. Please try again on
            later.
          </Typography>
        ) : (
          <>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              {myPlayer && (
                <Stack>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    border="solid 2px #B69056"
                    borderRadius={2}
                    sx={{
                      width: "100%",
                      mr: 2,
                    }}
                  >
                    <Stack sx={{ mt: 1 }}>
                      <Typography color="#B69056" variant="body1">
                        Your main player is
                      </Typography>
                    </Stack>
                    <Stack ml={1}>
                      <PlayerImage
                        src={myPlayer.headshotUrlSmall}
                        alt={`${myPlayer.firstName} ${myPlayer.lastName}`}
                        borderRadius="50%"
                        size="lg"
                      />
                    </Stack>

                    <Typography fontWeight="bold">
                      {`${myPlayer.firstName} ${myPlayer.lastName}`}
                    </Typography>
                    <Stack>
                      <Button
                        sx={{ mb: 2 }}
                        className="text-white"
                        color="primary"
                        disableElevation
                        onClick={() => handleChangePlayer()}
                        type="button"
                        variant="outlined"
                      >
                        Change Player
                      </Button>
                    </Stack>
                  </Stack>
                </Stack>
              )}
              <Stack
                alignItems="center"
                border={`solid 1px ${theme.palette.secondary.main}`}
                borderRadius="50%"
                direction="row"
                height={{ xs: 28, md: 40 }}
                justifyContent="center"
                textAlign="center"
                width={{ xs: 28, md: 40 }}
                mt={{ md: 0, xs: "-100%" }}
              >
                <Typography variant="h5" fontWeight="bold">
                  vs
                </Typography>
              </Stack>
            </Stack>
            <Stack
              border="solid 2px #B69056"
              borderRadius={2}
              direction="column"
              flex={1}
              overflow="hidden"
              sx={{ overflowY: "auto" }}
              ml={2}
            >
              <Box
                flex={1}
                sx={{ display: "grid", gridTemplateRows: "60px 1fr" }}
                overflow="hidden"
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack sx={{ ml: 2 }}>
                    <Typography color="#B69056" variant="body1">
                      Choose opponents
                    </Typography>
                  </Stack>
                  <Stack sx={{ mr: 1 }}>
                    <SearchField
                      onSearch={setSearchString}
                      placeholder="Search Player"
                      size="small"
                      value={searchString}
                    />
                  </Stack>
                </Stack>
                <Stack component="ul" m={0} overflow="auto" p={0}>
                  {opponentPlayers.map((o) => (
                    <ButtonBase
                      key={`${o.firstName} ${o.lastName}`}
                      component="li"
                      onClick={() => handleClick(o.playerId)}
                      sx={{
                        bgcolor: opponentPlayerIds.includes(o.playerId)
                          ? "#e9decc"
                          : "",
                        borderTop: "solid 1px lightgrey",
                      }}
                    >
                      <Stack
                        direction="row"
                        py={1}
                        spacing={2}
                        sx={{ width: "100%" }}
                      >
                        <Stack ml={1}>
                          <PlayerImage
                            src={o.headshotUrlSmall}
                            alt={`${o.firstName} ${o.lastName}`}
                            borderRadius="50%"
                            size="md"
                          />
                        </Stack>
                        <Stack alignItems="flex-start" textAlign="left">
                          <Typography fontWeight={{ sm: 400, md: 600 }}>
                            {`${o.firstName} ${o.lastName}`}
                          </Typography>
                        </Stack>
                      </Stack>
                    </ButtonBase>
                  ))}
                </Stack>
              </Box>
            </Stack>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          className="text-white"
          color="primary"
          disableElevation
          onClick={() => handleSubmit()}
          type="submit"
          variant="contained"
          disabled={
            !opponentPlayerIds.length ||
            isTournamentPlayersLoading ||
            isTournamentPlayersError
          }
          sx={{
            fontWeight: "bold",
            px: 4,
            mb: 1,
          }}
        >
          Create Suggestion
        </Button>
      </DialogActions>
    </>
  );
}
