import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  Stack,
  Tab,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { SignedInLayout } from "@src/components/templates/Layouts";
import { theme } from "@src/constants/theme";
import { useGetAllPartnerQuery } from "@src/graphql/graphql.generated";
import { useEffect, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { TouchdownMath } from "@src/components/f2pPartner/Configure/TouchdownMath";
import { useSnackbar } from "notistack";
import { TitleOfTheContest } from "@src/components/f2pPartner/Configure/TitleOfTheContest/TitleOfTheContest";
import { useAuth } from "@src/hooks/useAuth";
import { usePartner } from "@src/context/PartnerContext";

const StyledLabel = styled("label")(({ theme }) => ({
  color: "#212121",
  fontSize: "14px",
}));

const StyledInput = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(1),
  "& input": {
    padding: "9px 12px 9px 12px",

    "&:focus": {
      outline: `none`,
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: `none`,
    "&:focus": {
      outline: `none`,
    },
  },
  "& .MuiOutlinedInput-root.Mui-error": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `#d32f2f !important`,
    },
  },
  "& .MuiFormHelperText-root.Mui-error": {
    fontSize: "12px",
    position: "absolute",
    width: "300px",
    top: "100%",
    margin: "5px 0",
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)} 0`,
  minHeight: "auto",
  justifyContent: "flex-start",
  marginRight: theme.spacing(3),
}));

export function Configure() {
  const [value, setValue] = useState("1");
  const { partnerId }: any = usePartner();
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <SignedInLayout title="Configure" showF2pPartners>
      <Box
        marginTop={theme.spacing(3)}
        sx={{
          border: "1px solid #EEE8DC",
          backgroundColor: "#fff",
          padding: theme.spacing(2),
        }}
      >
        <TabContext value={value}>
          <Box>
            <TabList
              onChange={handleTabChange}
              aria-label="lab API tabs example"
              sx={{
                minHeight: "auto",
                "& .MuiTabs-flexContainer": {
                  borderBottom: "1px solid rgba(240, 240, 240, 1)",
                },
              }}
            >
              <StyledTab label="Title Of The Contests" value="1" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <TitleOfTheContest partnerId={partnerId} />
          </TabPanel>
        </TabContext>
      </Box>
    </SignedInLayout>
  );
}
