import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import { Box, Typography } from "@mui/material";
import { theme } from "@src/constants/theme";

function EmptyContainer({paddingTop = '40%'}) {
  return (
    <Box
      sx={{
        marginTop: theme.spacing(1),
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        border: "1px solid #EEE8DC",
        backgroundColor: "#fff",
        padding: `${theme.spacing(14)} ${theme.spacing(2)}`,
        paddingTop: paddingTop,
        height: "100vh",
      }}
    >
      <EmojiEventsOutlinedIcon
        sx={{
          fontSize: "60px",
          color: "#EEE8DC",
        }}
      />
      <Typography>No Contest Created</Typography>
    </Box>
  );
}

export default EmptyContainer;
