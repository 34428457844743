import { FC, useState, MouseEvent, useMemo, useEffect } from "react";
import ReactJson from "react-json-view";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { DialogTitle } from "@src/components/molecules/DialogTitle";
import { GridCellParams, MuiEvent } from "@mui/x-data-grid";
import {
  AuditActionFliterType,
  useGetAuditsQuery,
} from "@src/graphql/graphql.generated";
import { AuditLogsTable } from "./AuditLogsTable";

interface AuditLogsModalProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  actions: Array<AuditActionFliterType>;
}

export const AuditLogsModal: FC<AuditLogsModalProps> = ({
  isOpen,
  onClose,
  actions,
  title,
}) => {
  const [payload, setPayload] = useState(null);
  const {
    data: audits,
    isLoading,
    refetch,
  } = useGetAuditsQuery(
    { data: { filter: actions } },
    {
      staleTime: 300_000, // 5 min
    }
  );

  useEffect(() => {
    if (isOpen) {
      refetch();
    }
  }, [isOpen, refetch]);

  const handleClose = () => {
    setPayload(null);
    onClose();
  };

  const handlePayloadClick = (
    params: GridCellParams,
    event: MuiEvent<MouseEvent>
  ) => {
    event.defaultMuiPrevented = true;
    if (params.field === "payload") {
      setPayload(JSON.parse(params.row.payload));
    }
  };

  const rows = useMemo(
    () =>
      audits?.getAudits.map((audit) => ({
        ...audit,
        payload: JSON.stringify(audit.payload),
      })) ?? [],
    [audits]
  );

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="xl"
      onClose={handleClose}
    >
      <DialogTitle onClose={handleClose}>
        {payload ? (
          <>
            <IconButton
              onClick={() => setPayload(null)}
              sx={{ marginRight: 1 }}
            >
              <ArrowBack />
            </IconButton>
            Payload
          </>
        ) : (
          <>{title}</>
        )}
      </DialogTitle>
      <DialogContent>
        {/* {payload ? (
          <ReactJson src={payload} />
        ) : (
          <AuditLogsTable
            isLoading={isLoading}
            rows={rows}
            handlePayloadClick={handlePayloadClick}
          />
        )} */}
      </DialogContent>
    </Dialog>
  );
};
