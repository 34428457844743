import { useMemo } from "react";
import { DataGrid, GridColumns, GridComparatorFn } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { SignedInLayout } from "@src/components/templates/Layouts";
import { useGetUserSignUpsQuery } from "@src/graphql/graphql.generated";
import { renderCurrency } from "@src/helpers/currency";

export function UserSignup() {
  const { data: users, isLoading } = useGetUserSignUpsQuery(undefined, {
    staleTime: 300_000, // 5 min
  });

  const columns = useMemo<GridColumns>(
    () => [
      { field: "fullName", headerName: "Full Name", width: 200 },
      { field: "email", headerName: "Email", width: 200 },
      { field: "signUpState", headerName: "Sign Up State", width: 120 },
      { field: "promo", headerName: "Promo", width: 120 },
      { field: "signUpState", headerName: "SignUp State", width: 120 },
      {
        field: "createdAt",
        headerName: "Creation Date",
        width: 200,
        valueFormatter: (params) =>
          dayjs(params.value as string).format("MMM D, YYYY, H:ma"),
        sortComparator: dateComparator,
      },
      {
        field: "dateOfBirth",
        headerName: "Date of Birth",
        width: 120,
        valueFormatter: (params) =>
          dayjs(params.value as string).format("MMM D, YYYY"),
        sortComparator: dateComparator,
      },
      { field: "id", headerName: "id", width: 15 },
      { field: "phone", headerName: "phone", width: 120 },
      { field: "tsevoCustomerID", headerName: "Tsevo CustomerID", width: 250 },
      {
        field: "walletAmount",
        headerName: "Wallet Balance",
        width: 120,
        valueFormatter: (params) =>
          renderCurrency((params.value as number) / 100),
      },
    ],
    []
  );

  return (
    <SignedInLayout title="User Sign Up">
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <DataGrid
          columns={columns}
          initialState={{
            sorting: {
              sortModel: [{ field: "startDate", sort: "asc" }],
            },
          }}
          rows={users?.getUserSignUps ?? []}
        />
      )}
    </SignedInLayout>
  );
}

const dateComparator: GridComparatorFn = (isoDate1, isoDate2): 1 | -1 => {
  return dayjs(isoDate1 as string).isBefore(dayjs(isoDate2 as string)) ? 1 : -1;
};
