import {
  AUTH_BASE_URL,
  F2P_PARTNER_BASE_URL,
  PARTNER_BASE_URL,
  TOUCHDOWN_BASE_URL,
} from "@src/constants/ApiHosts";
import { AccessTokenPayload } from "@src/types";
import axios from "axios";
import * as jwt from "jsonwebtoken";
import { renewAccessToken } from "./auth";
import { loadAuthTokens, saveAuthTokens } from "./authTokenStorage";

export const axiosAuthInstance = axios.create({
  baseURL: AUTH_BASE_URL,
  headers: { "Content-Type": "application/json" },
});

async function refreshAuthLogic(failedRequest: any) {
  const { accessToken, refreshToken } = loadAuthTokens();
  const userData: AccessTokenPayload = jwt.decode(
    accessToken
  ) as AccessTokenPayload;
  const userInfo = userData?.data;

  const payload = {
    accessToken: accessToken ?? "",
    refreshToken: refreshToken ?? "",
    email: userInfo?.email ?? "",
    role: userInfo?.role,
  };

  const tokenRefreshResponse = await renewAccessToken(payload);
  saveAuthTokens({
    accessToken: tokenRefreshResponse.data.data,
    refreshToken,
  });
  failedRequest.response.config.headers["Authorization"] =
    "Bearer " + tokenRefreshResponse.data.data;
  return Promise.resolve();
}

export const axiosTouchdownInstance = axios.create({
  baseURL: TOUCHDOWN_BASE_URL,
  headers: { "Content-Type": "application/json" },
});

export const axiosPartnerInstance = axios.create({
  baseURL: PARTNER_BASE_URL,
  headers: { "Content-Type": "application/json" },
});

export const axiosF2pPartnerInstance = axios.create({
  baseURL: F2P_PARTNER_BASE_URL,
  headers: { "Content-Type": "application/json" },
});
