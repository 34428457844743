import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import SportsBasketballIcon from "@mui/icons-material/SportsBasketball";
import SportsRugbyIcon from "@mui/icons-material/SportsRugby";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Badge,
  Box,
  Card,
  CardContent,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import WeekPicker from "@organisms/Pickers/WeekPicker";
import { NbaContestCreation } from "@src/components/f2pPartner/Contest/NbaContestCreation";
import { NflContestCreation } from "@src/components/f2pPartner/Contest/NflContestCreation";
import { SoccerCreation } from "@src/components/f2pPartner/Contest/SoccerCreation";
import { AllContests } from "@src/components/f2pPartner/ContestHub/AllContests";
import { MatchUpsInTouchDown } from "@src/components/f2pPartner/MatchUpsInTouchDown";
import { SignedInLayout } from "@src/components/templates/Layouts";
import { theme } from "@src/constants/theme";
import { usePartner } from "@src/context/PartnerContext";
import {
  SupportedSports,
  useCountF2PContestsQuery,
  useFetchF2PTouchdownByDateQuery,
} from "@src/graphql/graphql.generated";
import { useAuth } from "@src/hooks/useAuth";
import { PrizePoolCreationFields } from "@src/types/touchdown/contest";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

const StyledLabel = styled("label")(({ theme }) => ({
  color: "#212121",
  fontSize: "14px",
  lineHeight: "20px",
}));

const useStyles = makeStyles((theme) => ({
  required: {
    "&:after": {
      content: '" *"',
      color: "red",
    },
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const getSportIcon = (name: string) => {
  switch (name) {
    case SupportedSports.Nfl:
      return (
        <SportsRugbyIcon
          sx={{
            marginRight: theme.spacing(1),
            marginBottom: "0 !important",
            fontSize: "18px",
          }}
        />
      );

    case SupportedSports.Nba:
      return (
        <SportsBasketballIcon
          sx={{
            marginRight: theme.spacing(1),
            marginBottom: "0 !important",
            fontSize: "18px",
          }}
        />
      );

    case "SOCCER":
      return (
        <SportsSoccerIcon
          sx={{
            marginRight: theme.spacing(1),
            marginBottom: "0 !important",
            fontSize: "18px",
          }}
        />
      );

    default:
      return <div />;
  }
};

function MainTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ width: "100%" }}
    >
      {value === index && (
        <Box sx={{ pl: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export function Contests() {
  const { data: authInfo } = useAuth();
  const { partnerId: selectedPartnerId }: any = usePartner();
  const partnerId =
    authInfo?.role === "ADMIN" ? authInfo?.partnerId! : selectedPartnerId!;
  const [selectedTab, setSelectedTab] = useState<SupportedSports>(
    SupportedSports.Nba
  );
  const [selectedDate, setSelectedDate] = useState<string>(
    dayjs().format("MM-DD-YYYY")
  );
  const [nbaContestsCount, setNbaContestsCount] = useState<number>(0);
  const [nflContestsCount, setNflContestsCount] = useState<number>(0);
  const [prizePoolId, setPrizePoolId] = useState<number | null>(null);
  const [soccerContestsCount, setSoccerContestsCount] = useState<number>(0);
  const [contestsCount, setContestsCount] = useState<any>({});
  const [prizePool, setPrizePool] = useState<PrizePoolCreationFields | null>(
    null
  );
  const [value, setValue] = useState(0);
  const [currentWeekPrizePools, setCurrentWeekPrizePools] = useState<
    Array<any>
  >([]);
  const [selectedTouchdown, setSelectedTouchdown] = useState<any>("");

  const { data: hubContestsCount } = useCountF2PContestsQuery(
    {
      date: selectedDate,
      partnerId: partnerId!,
      type: "automated",
      prizePoolId: prizePoolId!,
    },
    {
      staleTime: 300_000, // 5 min
      enabled: partnerId! > 0 && prizePoolId! > 0,
    }
  );

  const { data: touchdowns, isLoading } = useFetchF2PTouchdownByDateQuery(
    {
      data: {
        date: selectedDate,
        partnerId: partnerId!,
      },
    },
    {
      staleTime: 300_000, // 5 min
      enabled: partnerId !== undefined,
    }
  );

  useEffect(() => {
    const currentPrizePools = touchdowns?.fetchF2PTouchdownByDate;
    if (currentPrizePools) {
      setCurrentWeekPrizePools(currentPrizePools);
      setSelectedTouchdown(currentPrizePools?.[0]?.prizePoolId);
      setPrizePoolId(currentPrizePools?.[0]?.prizePoolId!);
    }
  }, [touchdowns]);

  useEffect(() => {
    const countObj = hubContestsCount?.countF2PContests.reduce(
      (acc: any, curr) => {
        acc[curr.name] = curr.count;
        return acc;
      },
      {}
    );
    setContestsCount({ ...countObj });
  }, [hubContestsCount?.countF2PContests]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    shouldUnregister: false,
  });

  const handleDateChange = (selectedDate: dayjs.Dayjs | null) => {
    if (selectedDate) {
      setSelectedDate(selectedDate.format("MM-DD-YYYY"));
    }
  };

  const contestCountBySport: any = {
    [SupportedSports.Nba]: nbaContestsCount,
    [SupportedSports.Nfl]: nflContestsCount,
    [SupportedSports.Soccer]: soccerContestsCount,
  };

  const handleSelectTouchdown = (event: any) => {
    setSelectedTouchdown(event.target.value as string);
    const selectedPrizePool = currentWeekPrizePools.find(
      (d) => d?.prizePoolId == event.target.value
    );
    setPrizePool(selectedPrizePool);
    setPrizePoolId(event.target.value);
  };

  return (
    <SignedInLayout title="Partner - Touchdown" showF2pPartners>
      <Box marginBottom={theme.spacing(3)}>
        <Card
          elevation={0}
          sx={{
            // border: "1px solid #EEE8DC",
            padding: theme.spacing(1),
          }}
        >
          <CardContent>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row">
                <FormControl variant="standard">
                  <StyledLabel htmlFor="SelectWeek">Select date</StyledLabel>
                  <WeekPicker
                    defaultValue={dayjs()}
                    handleDateChange={handleDateChange}
                  />
                </FormControl>
                <FormControl fullWidth sx={{ marginLeft: 1 }}>
                  <StyledLabel htmlFor="SelectWeek">
                    Select TouchDown
                  </StyledLabel>
                  <Select
                    id="sort-menu"
                    onChange={(event) => handleSelectTouchdown(event)}
                    value={selectedTouchdown}
                    sx={{
                      "#sort-menu": {
                        paddingTop: "10px",
                        paddingBottom: "10px",
                      },
                    }}
                  >
                    {currentWeekPrizePools.map((single, index) => (
                      <MenuItem key={index + 1} value={single?.prizePoolId}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Typography fontSize={14}>
                            {single?.touchdown?.touchdownName}
                          </Typography>
                        </Stack>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
        {touchdowns?.fetchF2PTouchdownByDate?.length ? (
          <Box
            sx={{
              flexGrow: 1,
              bgcolor: "background.paper",
              display: "flex",
              minHeight: 500,
            }}
          >
            <Tabs
              orientation="vertical"
              value={value}
              onChange={handleChange}
              sx={{
                borderRight: 1,
                borderRightColor: "#D6D6D6",
                minWidth: 130,
                "& .MuiTabs-flexContainer": {
                  borderBottom: "none",
                },
              }}
            >
              <Tab label="Automated " {...a11yProps(0)} />
              <Tab label="Contest Hub" {...a11yProps(1)} />
              <Tab
                sx={{ border: "none" }}
                label="Contest Order"
                {...a11yProps(2)}
              />
            </Tabs>
            <MainTabPanel value={value} index={0}>
              <Typography variant="h5" fontWeight={500}>
                Automated
              </Typography>
              <Box>
                <TabContext value={selectedTab}>
                  <Box>
                    <TabList
                      onChange={(_, value) => setSelectedTab(value)}
                      aria-label="lab API tabs example"
                      sx={{
                        minHeight: "auto",
                        "& .MuiTabs-flexContainer": {
                          borderBottom: "1px solid rgba(240, 240, 240, 1)",
                        },
                      }}
                    >
                      {["NBA", "NFL", "SOCCER"].map((value) => (
                        <Tab
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            padding: `${theme.spacing(2)} ${theme.spacing(
                              2
                            )} ${theme.spacing(2)} 0`,
                            minHeight: "auto",
                            justifyContent: "flex-start",
                            marginRight: theme.spacing(3),
                            fontSize: "16px",
                          }}
                          key={value}
                          label={
                            <>
                              {value}
                              <Badge
                                badgeContent={`${contestsCount[value] || 0}`} // contest creation count
                                sx={{
                                  marginLeft: theme.spacing(2),
                                  "& .MuiBadge-badge": {
                                    backgroundColor: "rgba(250, 242, 226, 1)",
                                    color: theme.palette.primary.main,
                                    fontSize: "16px",
                                  },
                                }}
                              ></Badge>
                            </>
                          }
                          value={value}
                          icon={getSportIcon(value)}
                        />
                      ))}
                    </TabList>
                  </Box>

                  <TabPanel
                    value={SupportedSports.Nba}
                    sx={{
                      padding: `${theme.spacing(3)} 0 ${theme.spacing(3)} 0`,
                    }}
                  >
                    <NbaContestCreation
                      key={SupportedSports.Nba}
                      selectedDate={selectedDate}
                      updateCount={setNbaContestsCount}
                      prizePool={prizePool}
                      prizePoolId={prizePoolId}
                      touchdowns={touchdowns?.fetchF2PTouchdownByDate! || []}
                    />
                  </TabPanel>

                  <TabPanel
                    value={SupportedSports.Nfl}
                    sx={{
                      padding: `${theme.spacing(3)} 0 ${theme.spacing(3)} 0`,
                    }}
                  >
                    <NflContestCreation
                      key={SupportedSports.Nfl}
                      selectedDate={selectedDate}
                      updateCount={setNflContestsCount}
                      prizePool={prizePool}
                    />
                  </TabPanel>
                  <TabPanel
                    value={"SOCCER"}
                    sx={{
                      padding: `${theme.spacing(3)} 0 ${theme.spacing(3)} 0`,
                    }}
                  >
                    <SoccerCreation
                      key={SupportedSports.Nfl}
                      selectedDate={selectedDate}
                      updateCount={setSoccerContestsCount}
                      prizePool={prizePool}
                      partnerId={partnerId}
                      prizePoolId={prizePoolId!}
                      touchdowns={touchdowns?.fetchF2PTouchdownByDate! || []}
                    />
                  </TabPanel>
                </TabContext>
              </Box>
            </MainTabPanel>
            <MainTabPanel value={value} index={1}>
              <Typography variant="h5" fontWeight={500}>
                Contest Hub
              </Typography>
              <MatchUpsInTouchDown
                selectedDate={selectedDate}
                partnerId={partnerId}
                prizePoolId={prizePoolId}
                touchdowns={touchdowns?.fetchF2PTouchdownByDate! || []}
              />
            </MainTabPanel>
            <MainTabPanel value={value} index={2}>
              <Typography variant="h5" fontWeight={500}>
                Contest Order
              </Typography>
              <AllContests
                partnerId={partnerId}
                selectedDate={selectedDate}
                prizePoolId={prizePoolId!}
              />
            </MainTabPanel>
          </Box>
        ) : (
          <Box
            sx={{
              bgcolor: "background.paper",
              marginTop: theme.spacing(1),
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              border: "1px solid #EEE8DC",
              backgroundColor: "#fff",
              padding: `${theme.spacing(14)} ${theme.spacing(2)}`,
            }}
          >
            <EmojiEventsOutlinedIcon
              sx={{
                fontSize: "60px",
                color: "#EEE8DC",
              }}
            />
            <Typography>No Touchdown Created</Typography>
          </Box>
        )}
      </Box>
    </SignedInLayout>
  );
}
