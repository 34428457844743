import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Tab,
  styled
} from "@mui/material";
import { SignedInLayout } from "@src/components/templates/Layouts";
import { TitleOfTheContest } from "@src/components/touchDown/Configure/TitleOfTheContest/TitleOfTheContest";
import { TouchdownMath } from "@src/components/touchDown/Configure/TouchdownMath";
import { WeeklyPrizeDistribution } from "@src/components/touchDown/Configure/WeeklyPrizeDistribution";
import { theme } from "@src/constants/theme";
import { useState } from "react";

const StyledTab = styled(Tab)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)} 0`,
  minHeight: "auto",
  justifyContent: "flex-start",
  marginRight: theme.spacing(3),
}));

export function Configure() {
  const [value, setValue] = useState("1");
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <SignedInLayout title="Configure">
      <Box
        marginTop={theme.spacing(3)}
        sx={{
          border: "1px solid #EEE8DC",
          backgroundColor: "#fff",
          padding: theme.spacing(2),
        }}
      >
        <TabContext value={value}>
          <Box>
            <TabList
              onChange={handleTabChange}
              aria-label="lab API tabs example"
              sx={{
                minHeight: "auto",
                "& .MuiTabs-flexContainer": {
                  borderBottom: "1px solid rgba(240, 240, 240, 1)",
                },
              }}
            >
              <StyledTab label="Touchdown Math" value="1" />
              <StyledTab label="Weekly Prize Distribution" value="2" />
              <StyledTab label="Title Of The Contests" value="3" />
            </TabList>
          </Box>
          <TabPanel
            value="1"
            sx={{
              padding: `${theme.spacing(3)} 0 ${theme.spacing(3)} 0`,
            }}
          >
            <TouchdownMath />
          </TabPanel>
          <TabPanel value="2">
            <WeeklyPrizeDistribution />
          </TabPanel>
          <TabPanel value="3">
            <TitleOfTheContest />
          </TabPanel>
        </TabContext>
      </Box>
    </SignedInLayout>
  );
}
