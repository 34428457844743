import { PermissionId } from "@src/constants/permissions";
import {
  AccessToken,
  AccessTokenPayload,
  RefreshTokenPayload,
} from "@src/types";
import dayjs from "dayjs";
import * as jwt from "jsonwebtoken";
import { loadAuthTokens, saveAuthTokens } from "./authTokenStorage";
import { axiosAuthInstance } from "./axiosInstances";

export interface ISendAuthCodePayload {
  email: string;
}

export interface ISignInPayload {
  email: string;
  code: string;
  token: string;
}

export interface ISignInResponse {
  accessToken: string;
  refreshToken: string;
}

export interface IRenewTokenPayload {
  accessToken: string;
  refreshToken: string;
  email: string;
  role: string;
}

export interface IUserPermissions {
  isSuperuser: boolean;
  permissions: Array<PermissionId>;
}

// Authentication services
export async function sendAuthCode(
  payload: ISendAuthCodePayload
): Promise<void> {
  try {
    await axiosAuthInstance({
      method: "POST",
      url: `login`,
      data: payload,
    });
  } catch (ex: any) {
    throw new Error(ex.response.data.message);
  }
}

export async function signIn(
  payload: ISignInPayload
): Promise<Omit<ISignInResponse, "accessToken" | "refreshToken">> {
  try {
    const { data }: { data: ISignInResponse } = await axiosAuthInstance({
      method: "POST",
      url: `login`,
      data: payload,
    });
    const { accessToken, refreshToken, ...rest } = data;
    saveAuthTokens({
      accessToken,
      refreshToken,
    });
    return rest;
  } catch (ex: any) {
    throw new Error(ex.response.data.message);
  }
}

export async function renewAccessToken(payload: IRenewTokenPayload) {
  try {
    const resp = await axiosAuthInstance({
      method: "POST",
      url: `renew-access-token`,
      data: payload,
    });
    return resp.data;
  } catch (_ex) {}
}

export async function getAuthInfo({
  retry,
}: {
  retry: boolean;
}): Promise<AccessTokenPayload["data"] & AccessToken> {
  const tokens = loadAuthTokens();
  let payload: AccessTokenPayload = jwt.decode(
    tokens.accessToken
  ) as AccessTokenPayload;
  if (!payload || dayjs(new Date(payload.exp * 1000)).isBefore(dayjs())) {
    if (retry) {
      const payload: RefreshTokenPayload = jwt.decode(
        tokens.refreshToken
      ) as AccessTokenPayload;
      const accessToken = await renewAccessToken({
        ...tokens,
        email: payload.data.email,
        role: payload.data.role,
      });
      saveAuthTokens({
        accessToken,
        refreshToken: tokens.refreshToken,
      });
      return getAuthInfo({ retry: false });
    } else {
      throw new Error("Token expired");
    }
  }
  return {
    ...payload.data,
    accessToken: tokens.accessToken,
  };
}

// User permissions
export async function getUserPermissions(): Promise<IUserPermissions> {
  const { accessToken } = await getAuthInfo({ retry: true });
  const { data } = await axiosAuthInstance({
    method: "GET",
    url: `permissions`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return data;
}

// Logout service
export async function logOut() {
  try {
    const { accessToken } = await getAuthInfo({ retry: true });
    await axiosAuthInstance({
      method: "POST",
      url: `logout`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  } catch (_ex) {}
}
