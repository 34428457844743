import { useMemo } from "react";
import { styled } from "@mui/material/styles";
import _MuiAppBar, {
  AppBarProps as MuiAppBarProps,
} from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { DRAWER_WIDTH } from "@organisms/Navigation";
import { Breadcrumbs, Button, Stack } from "@mui/material";
import { useAuth } from "@src/hooks/useAuth";
import { useLogOut } from "@src/hooks/useLogout";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@src/Routes";
import PartnerDropdown from "@atoms/PartnerDropdown/PartnerDropdown";
import { useGetAllPartnerQuery } from "@src/graphql/graphql.generated";

interface AppBarProps extends MuiAppBarProps {
  breadcrumbs?: Array<string>;
  onOpen: () => void;
  open: boolean;
  title: string;
  showPartners?: boolean;
  showF2pPartners?: boolean;
  disable?: boolean;
}

export function AppBar(props: AppBarProps) {
  const {
    open,
    onOpen,
    breadcrumbs,
    title,
    showPartners,
    showF2pPartners,
    disable = false,
  } = props;
  const { data: authInfo } = useAuth();
  const { mutate: logout } = useLogOut();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout(undefined, {
      onSuccess: () => {
        navigate(ROUTES.Login);
      },
    });
  };

  const appBarProps = useMemo(() => {
    const { onOpen, ...rest } = props;
    return rest;
  }, [props]);

  return (
    <MuiAppBar elevation={0} position="fixed" {...appBarProps}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={onOpen}
          edge="start"
          sx={{ mr: 2, ...(open && { display: "none" }) }}
        >
          <MenuIcon />
        </IconButton>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          flexGrow={1}
        >
          {breadcrumbs ? (
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {breadcrumbs.map((text, index) => (
                <Typography
                  key={index}
                  fontWeight={
                    index === breadcrumbs.length - 1 ? "bold" : "normal"
                  }
                >
                  {text}
                </Typography>
              ))}
            </Breadcrumbs>
          ) : (
            <Typography variant="h1" fontSize={20} noWrap>
              {title}
            </Typography>
          )}
          {authInfo && (
            <Stack alignItems="center" direction="row" spacing={1}>
              {authInfo?.role === "SUPER_ADMIN" &&
                (showPartners || showF2pPartners) && (
                  <Stack
                    alignItems="center"
                    direction="row"
                    spacing={1}
                    sx={disable ? { opacity: 0.7, pointerEvents: "none" } : {}}
                  >
                    <PartnerDropdown
                      showPartners={showPartners}
                      showF2pPartners={showF2pPartners}
                    />
                  </Stack>
                )}
              <Typography>{authInfo.email}</Typography>
              <Button color="inherit" onClick={handleLogout}>
                Logout
              </Button>
            </Stack>
          )}
        </Stack>
      </Toolbar>
    </MuiAppBar>
  );
}

const MuiAppBar = styled(_MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<Omit<AppBarProps, "onOpen">>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    marginLeft: `${DRAWER_WIDTH}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
