import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  MenuProps,
  Stack,
} from "@mui/material";
import { styled } from "@mui/styles";
import { DataGrid, GridColumns, GridRowParams } from "@mui/x-data-grid";
import { CreateEditDialog } from "@src/components/f2pPartner/CreateEditDialog";
import { SignedInLayout } from "@src/components/templates/Layouts";
import {
  useGetAllF2PPartnerQuery,
  useUpdateF2PPartnerActiveStatusMutation,
} from "@src/graphql/graphql.generated";
import { useEffect, useMemo, useState } from "react";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: "1px solid #EEE8DC !important",
  "& .MuiDataGrid-cell": {
    borderBottom: "1px solid #EEE8DC",
  },
  "& .MuiDataGrid-columnHeaders": {
    borderBottom: "1px solid #EEE8DC",
  },
  "& .MuiDataGrid-footerContainer": {
    borderTop: "1px solid #EEE8DC",
  },
  "& .inactive-row": {
    backgroundColor: "#F5F5F5",
  },
}));

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    minWidth: 180,
    color: "rgb(55, 65, 81)",
    boxShadow: "rgb(0 0 0 / 20%) 0px 0px 1px 0px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
      },
    },
  },
}));

interface ApiKey {
  id: number;
  apiKey: string;
  createdBy: number;
  active: boolean;
  expiredAt: string | null;
  createdAt: string;
}

interface Partner {
  id: number;
  name: string;
  email: string;
  contact: string;
  active: boolean;
  iframeKey: string;
  secretKey: string;
  apiKeys: ApiKey[];
}
export function Partners() {
  const { data: allPartners, isLoading, refetch } = useGetAllF2PPartnerQuery();
  const { mutate: updatePartner } = useUpdateF2PPartnerActiveStatusMutation();
  const [isCreateOpen, setCreateOpen] = useState(false);
  const [action, setAction] = useState<any>(null);
  const [partners, setPartners] = useState<any>([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPartnerData, setSelectedPartner] = useState<any>({});

  useEffect(() => {
    setPartners(allPartners?.getAllF2PPartner);
  }, [allPartners]);

  const handleClick = (event: any, partnerData: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedPartner({ ...partnerData });
  };

  const handleClose = (type: string) => {
    setAnchorEl(null);
    if (type === "add") {
      refetch();
    } else if (type === "edit") {
      setAction({ target: selectedPartnerData as Partner, status: type });
      refetch();
    } else if (type === "disable") {
      updatePartner(
        {
          data: {
            id: selectedPartnerData?.id,
            active: !selectedPartnerData?.active,
          },
        },
        {
          onSuccess: () => {
            refetch();
          },
        }
      );
    }
  };

  const handleCloseCreateEdit = (refresh = false) => {
    setCreateOpen(false);
    setAction(null);
    setAnchorEl(null);
    if (refresh) {
      refetch();
    }
  };

  const getRowClassName = (params: GridRowParams) => {
    return params.row.active === false ? "inactive-row" : "";
  };

  const PARTNERS_COLUMNS = useMemo<GridColumns>(
    () => [
      { field: "name", headerName: "Company Name", width: 200 },
      { field: "email", headerName: "Emil id", width: 250 },
      { field: "identifier", headerName: "Unique name", width: 150 },
      { field: "contact", headerName: "Phone Number", width: 200 },
      { field: "userCount", headerName: "Total user", width: 200 },
      {
        field: "active",
        headerName: "Action ",
        width: 150,
        renderCell: (params) => (
          <>
            <IconButton
              sx={{
                position: "absolute",
                cursor: "pointer",
              }}
              onClick={(event) => handleClick(event, params?.row)}
            >
              <MoreVertIcon />
            </IconButton>
            <StyledMenu
              id="contest-actions-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => handleClose("disable")}>
                {selectedPartnerData?.active ? "Disable" : "Enable"}
              </MenuItem>
              <MenuItem onClick={() => handleClose("edit")}>Edit</MenuItem>
            </StyledMenu>
          </>
        ),
      },
    ],
    [anchorEl]
  );
  return (
    <SignedInLayout title="Partners List">
      <Box>
        <Stack
          sx={{ mb: 2 }}
          direction="row"
          alignItems="center"
          justifyContent="end"
        >
          <Button
            variant="contained"
            size="medium"
            onClick={() => setCreateOpen(true)}
          >
            Create Partner
          </Button>
        </Stack>
      </Box>
      <Box style={{ height: "85vh", overflow: "auto" }}>
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <StyledDataGrid
            columns={PARTNERS_COLUMNS}
            getRowId={(row) => row?.id}
            rows={partners ?? []}
            disableSelectionOnClick
            getRowClassName={getRowClassName}
          />
        )}
      </Box>
      <CreateEditDialog
        isCreate={isCreateOpen}
        isEdit={action?.status === "edit"}
        target={action?.target ?? null}
        onClose={handleCloseCreateEdit}
      />
    </SignedInLayout>
  );
}
