import { ReactComponent as PersonIcon } from "./user.svg";
import { Box, SxProps } from "@mui/material";
import { Image } from "./Image";

const FADE_DURATION = 325;
const SIZES = {
  xs: 24,
  sm: 32,
  md: 48,
  lg: 64,
  xl: 96,
  xxl: 128,
};

interface PlayerImageProps {
  alt: string;
  borderRadius?: number | string;
  fit?: "contain" | "cover" | "fill" | "none" | "scale-down";
  size?: keyof typeof SIZES;
  src: string;
  sx?: SxProps;
}

export function PlayerImage(props: PlayerImageProps) {
  const { src, alt, borderRadius, fit = "cover", size = "md", sx } = props;
  return (
    <Image
      alt={alt}
      errorIcon={
        <Box
          component={PersonIcon}
          sx={{ height: SIZES[size], width: SIZES[size] }}
        />
      }
      duration={FADE_DURATION}
      fit={fit}
      height={SIZES[size]}
      src={src}
      sx={{
        ...sx,
        ...(borderRadius !== undefined ? { borderRadius } : null),
      }}
      width={SIZES[size]}
    />
  );
}
