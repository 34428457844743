import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  styled,
} from "@mui/material";
import { theme } from "@src/constants/theme";
import { useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const StyledLabel = styled("label")(({ theme }) => ({
  color: "#212121",
  fontSize: "14px",
}));

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  marginTop: theme.spacing(1),

  "& .MuiOutlinedInput-root": {
    height: "50px",
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  marginTop: theme.spacing(1),
  height: "50px",
}));

export function TouchdownAnalysis() {
  const [timeInterval, setTimeInterval] = useState("");
  const handleTimeIntervalChange = (event: any) => {
    setTimeInterval(event.target.value as string);
  };
  return (
    <Box marginBottom={theme.spacing(4)}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <StyledLabel>Date Range</StyledLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StyledDatePicker />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <StyledLabel>Time Interval</StyledLabel>
            <StyledSelect
              value={timeInterval}
              onChange={handleTimeIntervalChange}
              sx={{ backgroundColor: "#fff" }}
              displayEmpty
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </StyledSelect>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={7}
          display="flex"
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          <Button variant="contained" size="large">
            <ArrowForwardIcon />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
