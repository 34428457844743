import { RemoveCircleOutline } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  CardMedia,
  FormControl,
  FormControlLabel,
  IconButton,
  Popover,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { QueryKeys } from "@src/constants/QueryKeys";
import {
  useCreatePartnerSportMutation,
  useUpdatePartnerSportMutation,
} from "@src/graphql/graphql.generated";
import { parseGQLError } from "@src/utils/parse-error";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import "./TitleOfTheContest.css";
const useStyles = makeStyles((theme) => ({
  required: {
    "&:after": {
      content: '" *"',
      color: "red",
    },
  },
}));
const StyledInput = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(1),
  "& input": {
    padding: "9px 12px 9px 12px",

    "&:focus": {
      outline: `none`,
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: `#E2D3BB !important`,
    "&:focus": {
      outline: `none`,
    },
  },
  "& .MuiOutlinedInput-root.Mui-error": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `#d32f2f !important`,
    },
  },
  "& .MuiFormHelperText-root.Mui-error": {
    fontSize: "12px",
    position: "absolute",
    top: "100%",
    margin: "5px 0",
  },
}));

const StyledLabel = styled("label")(({ theme }) => ({
  color: "#212121",
  fontSize: "14px",
}));
interface AddNewSportsProps {
  closeSportModal: () => void;
  addNewSportFormData: any;
  partnerId?: number;
  sportLogosData: Array<string>;
}

interface ContestQuestion {
  id?: number;
  question: string;
  metric: string;
}

export const AddNewSportForm: React.FC<AddNewSportsProps> = ({
  closeSportModal,
  addNewSportFormData,
  partnerId, // TODO: Pass the partnerId
  sportLogosData,
}) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    resetField,
    formState: { errors },
  } = useForm();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [isSportLogosOpen, setIsSportLogosOPen] = useState(false);
  const [contestQuestions, setContestQuestions] = useState<ContestQuestion[]>([
    { question: "", metric: "" },
  ]);
  const [sportLogoUrl, setSportLogoUrl] = useState<any>(
    addNewSportFormData?.logoUrl ?? " "
  );

  const { mutate: createSport, isLoading: isCreateSportLoading } =
    useCreatePartnerSportMutation();
  const { mutate: updateSport, isLoading: isUpdateSportLoading } =
    useUpdatePartnerSportMutation();
  const queryClient = useQueryClient();

  const id = isSportLogosOpen ? "simple-popover" : undefined;

  useEffect(() => {
    if (addNewSportFormData) {
      setValue("name", addNewSportFormData.name);
      setValue("id", addNewSportFormData.id);
      setValue("enabled", addNewSportFormData.enabled);
      setValue("logoUrl", addNewSportFormData.logoUrl);
      setValue("winCriteria", addNewSportFormData.winCriteria);
      addNewSportFormData?.questions?.forEach(
        (question: any, index: number) => {
          setValue(`questions[${index}].id`, question.id);
          setValue(`questions[${index}].question`, question.question);
          setValue(`questions[${index}].metric`, question.metric);
        }
      );
      setContestQuestions([...addNewSportFormData?.questions]);
    }
  }, [addNewSportFormData, setValue]);

  const handleQuestionChange = (
    event: any,
    index: number,
    questionId: any,
    key: any
  ) => {
    const value = event.target.value;
    const data: any = contestQuestions?.[index];

    data.id = questionId;
    data[key] = value;
    contestQuestions[index] = data;

    setContestQuestions([...contestQuestions]);
  };

  const handleDeletedQuestion = (deletedIndex: number) => {
    contestQuestions.splice(deletedIndex, 1);
    setContestQuestions([...contestQuestions]);

    contestQuestions?.forEach((question, index) => {
      setValue(`questions[${index}].id`, question.id);
      setValue(`questions[${index}].question`, question.question);
      setValue(`questions[${index}].metric`, question.metric);
    });

    resetField(`questions[${contestQuestions.length}].id`);
    resetField(`questions[${contestQuestions.length}].question`);
    resetField(`questions[${contestQuestions.length}].metric`);
  };

  const handleNumberOfQuestions = () => {
    setValue(`questions[${contestQuestions.length}].id`, undefined);
    setValue(`questions[${contestQuestions.length}].question`, "");
    setValue(`questions[${contestQuestions.length}].metric`, "");
    setContestQuestions((prev) => [...prev, { question: "", metric: "" }]);
  };

  const onSubmit = (data: any) => {
    if (!addNewSportFormData) {
      const formData = { ...data };
      const { name, questions, winCriteria } = formData;
      const trimmedName = name.trim();
      createSport(
        {
          data: {
            name: trimmedName,
            questions,
            winCriteria,
            logoUrl: sportLogoUrl,
            partnerId: partnerId!,
          },
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(QueryKeys.GetPartnerSports);

            enqueueSnackbar("Sport created successfully !", {
              variant: "success",
            });
            closeSportModal();
          },
          onError: (error: any) => {
            enqueueSnackbar(
              parseGQLError(error, "Error while creating sport."),
              {
                variant: "error",
              }
            );
          },
        }
      );
    } else {
      const { id, name, winCriteria, questions } = data;
      const contentQues = questions?.filter(
        ({ question, metric }: any) => question && metric
      );
      const trimmedName = name.trim();
      updateSport(
        {
          data: {
            id,
            name: trimmedName,
            questions: contentQues,
            winCriteria,
            logoUrl: sportLogoUrl,
            partnerId: partnerId!,
          },
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(QueryKeys.GetPartnerSports);

            enqueueSnackbar("Sport updated successfully !", {
              variant: "success",
            });
            closeSportModal();
          },
          onError: (error: any) => {
            enqueueSnackbar(
              parseGQLError(error, "Error while updating sport."),
              {
                variant: "error",
              }
            );
          },
        }
      );
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsSportLogosOPen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsSportLogosOPen(false);
  };

  const SportLogos = () => {
    const handleLogoUrl = (logoUrl: String) => {
      setSportLogoUrl(logoUrl);
    };

    const handleClickOfLogos = (logo: string) => {
      handleLogoUrl(logo);
      handleClose();
    };

    return (
      <Popover
        id={id}
        open={isSportLogosOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ width: "300px", minHeight: "180px", margin: "5px" }}>
          <Box
            sx={{
              width: "300px",
              minHeight: "auto",
              padding: "6px",
              display: "flex",
              flexDirection: "row",
              gap: "6px",
              flexWrap: "wrap",
            }}
          >
            {sportLogosData?.map((logoUrl, index) => {
              // const isLogoUsed: boolean = selectedLogoUrls.includes(logoUrl);
              return (
                <CardMedia
                  key={index}
                  onClick={() => {
                    handleClickOfLogos(logoUrl);
                  }}
                  sx={{
                    height: 30,
                    width: 30,
                    objectFit: "none",
                    cursor: "pointer",
                    ...(logoUrl === sportLogoUrl && {
                      backgroundColor: "rgba(239, 239, 239, 1)",
                      borderRadius: "6px",
                      border: "1px solid rgba(228, 228, 228, 1)",
                      gap: "11px",
                    }),
                  }}
                  component="img"
                  alt="image"
                  image={logoUrl}
                />
              );
            })}
          </Box>
        </Box>
      </Popover>
    );
  };

  return (
    <form className="add-new-sport-form-conatiner">
      <FormControl>
        <StyledLabel htmlFor="name" className={classes.required}>
          Sport title
        </StyledLabel>
        <StyledInput
          sx={{ width: "338px" }}
          id="name"
          required
          disabled={["NBA", "NFL", "soccer"].includes(
            addNewSportFormData?.name
          )}
          {...register("name", {
            required: true,
            validate: (value) => value.trim() !== "",
          })}
          placeholder="ENTER TITLE"
        />
        {(errors.name || errors.name?.type === "validate") && (
          <Typography sx={{ fontSize: "12px" }} color="error">
            Sport title is required.
          </Typography>
        )}
      </FormControl>
      <FormControl>
        <StyledLabel htmlFor="logo" className={classes.required}>
          Sport logo
        </StyledLabel>
        <Button
          sx={{
            position: "relative",
            padding: "0px 0px 0px 0px",
            cursor: "pointer",
            background: "#FAFAFA",
            display: "block",
            marginTop: "5px",
            height:
              sportLogoUrl ===
              "https://topprop-static-assets.s3.amazonaws.com/sports/pga.svg"
                ? "70px"
                : "62px",
            width: "62px",
            border: sportLogoUrl === " " ? "1px dashed #D9D9D9" : "none",
            borderRadius: "50%",
          }}
          onClick={handleClick}
        >
          {sportLogoUrl === " " ? (
            <span
              style={{
                display: "flex",
                color: "rgb(191 184 184)",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <AddIcon
                style={{
                  color: "rgb(191 184 184)",
                  width: "14px",
                  height: "14px",
                }}
                {...register("logoUrl", { required: true })}
              />
              <span>Upload</span>
            </span>
          ) : (
            <CardMedia component="img" alt="image" image={sportLogoUrl} />
          )}
        </Button>
        {errors.logoUrl && (
          <Typography sx={{ fontSize: "12px" }} color="error">
            Sport logo is required.
          </Typography>
        )}
      </FormControl>

      <SportLogos />
      <StyledLabel className={classes.required} htmlFor={"contest question"}>
        Contest question
      </StyledLabel>
      <Box sx={{ marginTop: "-12px" }}>
        {contestQuestions?.map(
          (question: any, index: number): React.ReactNode => {
            return (
              <FormControl
                key={index}
                sx={{ display: "flex", flexDirection: "row" }}
              >
                <Box sx={{ width: "338px" }}>
                  <StyledInput
                    sx={{ width: "338px" }}
                    id={`questions[${index}].question`}
                    required
                    {...register(`questions[${index}].question`, {
                      required: true,
                      onChange: (event) =>
                        handleQuestionChange(
                          event,
                          index,
                          question?.id,
                          "question"
                        ),
                      validate: (value) => value.trim() !== "",
                    })}
                    placeholder="ENTER QUESTION"
                  />
                  {(errors?.[`questions`]?.[index]?.question ||
                    errors?.[`questions`]?.[index]?.question?.type ===
                      "validate") && (
                    <Typography sx={{ fontSize: "12px" }} color="error">
                      Question is required.
                    </Typography>
                  )}
                </Box>

                <Box sx={{ width: "122px", marginLeft: "12px" }}>
                  <StyledInput
                    id={`questions[${index}].metric`}
                    type="string"
                    required
                    {...register(`questions[${index}].metric`, {
                      required: true,
                      onChange: (event) =>
                        handleQuestionChange(
                          event,
                          index,
                          question?.id,
                          "metric"
                        ),
                      validate: (value) => value.trim() !== "",
                    })}
                    placeholder="BONUS POINT"
                  />
                  {(errors?.[`questions`]?.[index]?.metric ||
                    errors?.[`questions`]?.[index]?.metric?.type ===
                      "validate") && (
                    <Typography sx={{ fontSize: "12px" }} color="error">
                      Bonus is required.
                    </Typography>
                  )}
                </Box>
                {!addNewSportFormData?.questions?.[index] && index !== 0 ? (
                  <IconButton
                    sx={{
                      cursor: "pointer",
                      margin: "7px 0px 0px 12px",
                    }}
                    onClick={() => handleDeletedQuestion(index)}
                  >
                    <RemoveCircleOutline />
                  </IconButton>
                ) : null}
              </FormControl>
            );
          }
        )}
      </Box>
      <Box>
        <Button
          variant="outlined"
          size="medium"
          sx={{
            fontSize: "16px",
            lineHeight: "22px",
            border: "1px solid #B69056",
            marginBottom: "12px",
          }}
          onClick={() => handleNumberOfQuestions()}
        >
          ADD QUESTION
        </Button>
      </Box>

      <FormControl sx={{ display: "flex" }}>
        <StyledLabel className={classes.required} htmlFor="winCriteria">
          Winning criteria {addNewSportFormData?.winCriteria}
        </StyledLabel>

        <Controller
          rules={{ required: true }}
          control={control}
          name="winCriteria"
          defaultValue={addNewSportFormData?.winCriteria || ""}
          render={({ field, fieldState }) => {
            return (
              <>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  row
                  aria-required
                  value={field.value}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    if (newValue !== field.value) {
                      setValue("winCriteria", newValue);
                    }
                  }}
                >
                  <FormControlLabel
                    value="LOWER"
                    control={<Radio />}
                    label="LOWER WIN"
                  />
                  <FormControlLabel
                    value="HIGHER"
                    control={<Radio />}
                    label="HIGHER WIN"
                  />
                </RadioGroup>
              </>
            );
          }}
        />

        {errors.winCriteria && (
          <Typography sx={{ fontSize: "12px" }} color="error">
            Win criteria is required.
          </Typography>
        )}
      </FormControl>
      <Box sx={{ borderTop: "1px solid #F0F0F0", padding: "8px 0px 0px 0px" }}>
        <Box sx={{ display: "flex", justifyContent: "end", gap: "8px" }}>
          <Button variant="outlined" size="small" onClick={closeSportModal}>
            Cancel
          </Button>

          <Button
            variant="contained"
            size="small"
            onClick={handleSubmit(onSubmit)}
            disabled={isCreateSportLoading || isUpdateSportLoading}
          >
            Save
          </Button>
        </Box>
      </Box>
    </form>
  );
};
