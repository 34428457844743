import { GridFilterInputValueProps } from "@mui/x-data-grid";
import AdapterDayjs from "@mui/lab/AdapterDayjs";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DateRangePicker } from "@mui/lab";
import { Stack, TextField } from "@mui/material";
import { Box } from "@mui/system";

export const DateRangeFilterPicker = (props: GridFilterInputValueProps) => {
  const { item, applyValue } = props;

  return (
    <Stack direction="row" ml={3}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateRangePicker
          startText="Start Date"
          endText="End Date"
          value={item.value || [null, null]}
          onChange={(newValue) => {
            applyValue({ ...item, value: newValue });
          }}
          renderInput={(startProps, endProps) => (
            <>
              <TextField
                {...startProps}
                variant="standard"
                sx={{ width: 100 }}
              />
              <Box sx={{ mx: 2 }}> to </Box>
              <TextField {...endProps} variant="standard" sx={{ width: 100 }} />
            </>
          )}
        />
      </LocalizationProvider>
    </Stack>
  );
};
