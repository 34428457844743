import { useState } from "react";
import { useSnackbar } from "notistack";
import {
  AuditActionFliterType,
  useWalletBonusDepositMutation,
} from "@src/graphql/graphql.generated";
import { SignedInWithSidebarLayout } from "@src/components/templates/Layouts";
import {
  Button,
  FormControl,
  Box,
  Stack,
  TextField,
  InputAdornment,
  FormHelperText,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import { parseGQLError } from "@src/utils/parse-error";
import { AuditLogsModal } from "@src/components/templates/AuditLogsModal";

export function BonusPayout() {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: update, isLoading } = useWalletBonusDepositMutation();
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState(0);
  const [showAuditTrail, setShowAuditTrail] = useState(false);

  const handleCloseAudit = () => {
    setShowAuditTrail(false);
  };

  const handleSend = () => {
    update(
      { data: { email, amount } },
      {
        onSuccess: () => {
          enqueueSnackbar("Amount has beend added successfully!", {
            variant: "success",
          });
        },
        onError: (error: any) => {
          enqueueSnackbar(parseGQLError(error, "Unable to send"), {
            variant: "error",
          });
        },
      }
    );
  };

  return (
    <SignedInWithSidebarLayout
      title="Bonus Payout"
      SidebarComponent={
        <Controls
          isLoading={isLoading}
          onSend={handleSend}
          setShowAuditTrail={setShowAuditTrail}
        />
      }
    >
      <Box component="main" sx={{ maxWidth: "400px" }}>
        <Stack spacing={2}>
          <FormControl>
            <TextField
              id="email"
              label="Email"
              type="email"
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(ev) => setEmail(ev.target.value)}
              value={email}
            />
          </FormControl>

          <FormControl>
            <TextField
              id="amount"
              label="Amount"
              type="number"
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocalAtmIcon />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                sx: {
                  WebkitAppearance: "none",
                  MozAppearance: "textfield",
                },
              }}
              onChange={(ev) => setAmount(Number(ev.target.value))}
              value={amount}
            />
            <FormHelperText>Amount by cents</FormHelperText>
          </FormControl>
        </Stack>
      </Box>
      <AuditLogsModal
        title="Bonus Payout Audit"
        isOpen={showAuditTrail}
        onClose={handleCloseAudit}
        actions={[AuditActionFliterType.BonusPayout]}
      />
    </SignedInWithSidebarLayout>
  );
}

interface ControlsProps {
  isLoading: boolean;
  onSend: () => void;
  setShowAuditTrail: (value: boolean) => void;
}

function Controls({ isLoading, onSend, setShowAuditTrail }: ControlsProps) {
  return (
    <Stack spacing={1}>
      <Button onClick={onSend} disabled={isLoading} variant="contained">
        Send
      </Button>
      <Button onClick={() => setShowAuditTrail(true)} variant="outlined">
        Audit Trail
      </Button>
    </Stack>
  );
}
