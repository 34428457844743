import { Box, Grid, Typography } from "@mui/material";
import { theme } from "@src/constants/theme";
import { useEffect, useState } from "react";
import { TouchdownSummaryCard } from "../TouchdownSummaryCard";
import { GetF2PTouchdownAnalyticsQuery } from "@src/graphql/graphql.generated";

const initTotals = {
  totalActiveTouchdowns: 0,
  totalTouchdowns: 0,
  totalPartners: 0,
  totalUsers: 0,
  totalUpcomingTouchdowns: 0,
};

export const WEEKLY_COUNT_LABELS = [
  {
    key: "totalTouchdowns",
    title: "Total Touchdown",
  },
  {
    key: "totalActiveTouchdowns",
    title: "Live Touchdown",
  },
  {
    key: "totalUpcomingTouchdowns",
    title: "Upcoming Touchdown",
  },
  {
    key: "totalUsers",
    title: "Total user",
  },
];
export interface PartnerTouchDownProps {
  title: string;
  partnerTouchdownAnalytics: GetF2PTouchdownAnalyticsQuery | undefined;
}

export interface WeeklyCounts {
  totalActiveTouchdowns: number;
  totalTouchdowns: number;
  totalUsers: number;
  totalPartners: number;
  totalUpcomingTouchdowns: number;
  [key: string]: number | string;
}

export function TouchdownSummary(props: PartnerTouchDownProps) {
  const { title, partnerTouchdownAnalytics } = props;

  const [weeklyCounts, setWeeklyCounts] = useState<WeeklyCounts>(initTotals);

  const calculateCounts = (touchdowns: any) => {
    const totals: WeeklyCounts = {
      totalActiveTouchdowns: 0,
      totalTouchdowns: 0,
      totalUsers: 0,
      totalPartners: 0,
      totalUpcomingTouchdowns: 0,
    };

    totals.totalActiveTouchdowns = touchdowns.totalActiveTouchdowns;
    totals.totalTouchdowns = touchdowns.totalTouchdowns;
    totals.totalUsers = touchdowns.totalUsers;
    totals.totalPartners = touchdowns.totalPartners;
    totals.totalUpcomingTouchdowns = touchdowns.totalUpcomingTouchdowns;

    setWeeklyCounts({ ...totals });
  };

  useEffect(() => {
    const touchdowns = partnerTouchdownAnalytics;
    if (touchdowns) {
      calculateCounts(touchdowns?.getF2PTouchdownAnalytics);
    } else {
      setWeeklyCounts({ ...initTotals });
    }
  }, [partnerTouchdownAnalytics]);

  return (
    <>
      <Box marginBottom={theme.spacing(3)}>
        {title ? (
          <Typography
            variant="h5"
            fontWeight={500}
            marginBottom={theme.spacing(3)}
          >
            {title}
          </Typography>
        ) : (
          ""
        )}
        <Grid container spacing={2}>
          {WEEKLY_COUNT_LABELS?.map((item, index) => (
            <TouchdownSummaryCard
              key={index}
              index={index}
              title={item.title}
              value={weeklyCounts[item.key]}
            />
          ))}
        </Grid>
      </Box>
    </>
  );
}
