import { useSnackbar } from "notistack";
import { useDeleteCouponsMutation } from "@src/graphql/graphql.generated";
import { parseGQLError } from "@src/utils/parse-error";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Box,
  Typography,
} from "@mui/material";

interface DeleteDialogProps {
  selectedCoupons: Array<number>;
  isOpen: boolean;
  onClose: (refresh: boolean) => void;
}

export function DeleteDialog({
  selectedCoupons,
  isOpen,
  onClose,
}: DeleteDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: deleteCoupons, isLoading } = useDeleteCouponsMutation();

  const handleDelete = () => {
    deleteCoupons(
      { data: { ids: selectedCoupons } },
      {
        onSuccess: (res) => {
          enqueueSnackbar(
            `${res.deleteCoupons.affected} Coupons have been deleted successfully!`,
            {
              variant: "success",
            }
          );
          onClose(true);
        },
        onError: (error: any) => {
          enqueueSnackbar(parseGQLError(error, "Unable to delete"), {
            variant: "error",
          });
        },
      }
    );
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={onClose}>
      <DialogTitle>Delete Coupons</DialogTitle>
      <DialogContent>
        <Box component="main">
          <Stack spacing={2}>
            <Typography>
              Do you want to delete the{" "}
              <strong>{selectedCoupons.length}</strong> selected coupon(s)?
            </Typography>
            <Button
              variant="contained"
              onClick={handleDelete}
              disabled={isLoading}
            >
              Delete
            </Button>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
