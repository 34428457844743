import { useState, useEffect } from "react";
import { useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import {
  Button,
  Stack,
  DialogContent,
  DialogActions,
  ButtonBase,
  Box,
  Typography,
  DialogTitle,
  Dialog,
  CircularProgress,
} from "@mui/material";
import {
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
  useRecoilState,
} from "recoil";
import {
  contestCreationStepState,
  tournamentTypeState,
  tournamentIdState,
  TournamentType,
  suggestionIdState,
  suggestionStepState,
} from "./state";
import {
  useTournamentsQuery,
  useGetTournamentSuggestionsQuery,
  useUpdateSuggestionMutation,
  useGetPlayersForSuggestionQuery,
  useDeleteSuggestionMutation,
} from "@src/graphql/graphql.generated";
import { parseGQLError } from "@src/utils/parse-error";
import { DeleteSuggestion } from "@src/pages/pga/ContestSuggestions/DeleteSuggestion";
import { SearchField } from "./SearchField";
import { PlayerImage } from "./PlayerImage";

interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
}

export function UpdateSuggestion(props: SimpleDialogProps) {
  const { onClose, open } = props;

  const { enqueueSnackbar } = useSnackbar();
  const tournamentType = useRecoilValue(tournamentTypeState);
  const queryClient = useQueryClient();
  const [round, setRound] = useState<number>();
  const [openDelete, setOpenDelete] = useState(false);
  const [searchString, setSearchString] = useState("");
  const suggestionId = useRecoilValue(suggestionIdState);
  const [mainPlayerId, setMainPlayerId] = useState<string>();
  const [tournamentId, setTournamentId] = useRecoilState(tournamentIdState);
  const [opponentId, setOpponentId] = useState<string | null>();
  const resetCurrentStep = useResetRecoilState(contestCreationStepState);
  const resetTournamentType = useResetRecoilState(tournamentTypeState);
  const resetSuggestionId = useResetRecoilState(suggestionIdState);
  const setTournamentType = useSetRecoilState(tournamentTypeState);
  const setSuggestionStep = useSetRecoilState(suggestionStepState);

  const {
    data: suggestions,
    isLoading: isSuggestionsLoading,
    isSuccess,
    isError: isSuggestionsError,
    refetch: refetchSuggestions,
  } = useGetTournamentSuggestionsQuery();
  const { mutate: update, isLoading: isUpdating } =
    useUpdateSuggestionMutation();
  const { mutate: deleteSuggestion, isLoading: isDelete } =
    useDeleteSuggestionMutation();
  const tournamentsQueryKey = useTournamentsQuery.getKey();

  const {
    data: tournamentPlayers,
    isLoading: isTournamentPlayersLoading,
    isError: isTournamentPlayersError,
    refetch: refetchTournamentPlayers,
  } = useGetPlayersForSuggestionQuery({
    tournamentId: tournamentId!,
    contestType: tournamentType,
    roundNumber:
      tournamentType === TournamentType.RoundByRound ? +round! : null,
  });

  const opponentPlayers = (tournamentPlayers?.getPlayersForSuggestion ?? [])
    ?.filter((p) => p.playerId !== mainPlayerId)
    .filter(
      (p) =>
        p.playerId !== mainPlayerId &&
        `${p.firstName} ${p.lastName}`
          .toLocaleLowerCase()
          .includes(searchString.toLocaleLowerCase())
    );

  const handleUpdateSuggestion = () => {
    update(
      {
        suggestionId: suggestionId!,
        oppenentId: opponentId!,
      },
      {
        onError: (err: any) => {
          enqueueSnackbar(parseGQLError(err, "Unable to update"), {
            variant: "error",
          });
        },
        onSuccess: () => {
          queryClient.invalidateQueries(tournamentsQueryKey);
          enqueueSnackbar("Updated", {
            variant: "success",
          });
          resetCurrentStep();
          resetSuggestionId();
          setSuggestionStep(true);
          setSuggestionStep(false);
          onClose();
          setOpponentId(null);
        },
      }
    );
  };
  const handleClickOpenDeleteSuggestion = () => {
    setOpenDelete(true);
    onClose();
  };
  const handleDeleteClose = () => {
    setOpenDelete(false);
  };
  const handleDeleteSuggestion = () => {
    deleteSuggestion(
      {
        suggestionId: suggestionId!,
      },
      {
        onError: (err: any) => {
          enqueueSnackbar(parseGQLError(err, "Unable to delete"), {
            variant: "error",
          });
        },
        onSuccess: () => {
          queryClient.invalidateQueries(tournamentsQueryKey);
          enqueueSnackbar("Deleted", {
            variant: "success",
          });
          handleDeleteClose();
          resetCurrentStep();
          setSuggestionStep(true);
          setSuggestionStep(false);
          resetTournamentType();
          resetSuggestionId();
        },
      }
    );
  };
  function handleClick(id: string) {
    if (opponentId !== id) {
      setOpponentId(id);
    }
  }
  const handleClose = () => {
    onClose();
    setOpponentId(null);
    resetCurrentStep();
    resetTournamentType();
    resetSuggestionId();
  };

  useEffect(() => {
    if (isSuccess && suggestions?.getTournamentSuggestions) {
      suggestions.getTournamentSuggestions.forEach((s) => {
        if (s.id === suggestionId) {
          setTournamentId(s.tournament.remoteId);

          setMainPlayerId(s.mainPlayer.playerId);
          if (s.roundNumber) {
            setTournamentType(TournamentType.RoundByRound);
            setRound(s.roundNumber);
          }
        }
      });
    }
  }, [isSuccess, suggestionId, suggestions]);
  useEffect(() => {
    refetchTournamentPlayers();
    refetchSuggestions();
  }, [refetchTournamentPlayers, tournamentId, suggestionId]);

  return (
    <>
      <Dialog
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle>Update a Suggestion</DialogTitle>{" "}
        <DialogContent
          sx={{
            display: "flex",
            overflow: "hidden",
            paddingX: 1,
            paddingY: 2.5,
          }}
        >
          {isTournamentPlayersLoading || isSuggestionsLoading ? (
            <Stack alignItems="center" flex={1} justifyContent="center" m={5}>
              <CircularProgress size={24} />
            </Stack>
          ) : isTournamentPlayersError || isSuggestionsError ? (
            <Stack alignItems="center" flex={1} justifyContent="center" m={5}>
              <Typography variant="h5" align="center">
                Sorry, players can't be fetched at the moment. Please try again
                on later.
              </Typography>
            </Stack>
          ) : !tournamentPlayers?.getPlayersForSuggestion.length ? (
            <Stack alignItems="center" flex={1} justifyContent="center" m={5}>
              <Typography variant="h5" align="center">
                Sorry, no players available at the moment.
              </Typography>
            </Stack>
          ) : (
            <>
              {" "}
              <Stack
                border="solid 2px #B69056"
                borderRadius={2}
                direction="column"
                flex={1}
                overflow="hidden"
                sx={{ overflowY: "auto" }}
              >
                <Box
                  flex={1}
                  sx={{ display: "grid", gridTemplateRows: "60px 1fr" }}
                  overflow="hidden"
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Stack sx={{ ml: 2 }}>
                      <Typography color="#B69056" variant="body1">
                        Choose opponent
                      </Typography>
                    </Stack>
                    <Stack sx={{ mr: 1 }}>
                      <SearchField
                        onSearch={setSearchString}
                        placeholder="Search Player"
                        size="small"
                        value={searchString}
                      />
                    </Stack>
                  </Stack>
                  <Stack component="ul" m={0} overflow="auto" p={0}>
                    {isTournamentPlayersLoading && (
                      <Stack
                        alignItems="center"
                        height="100%"
                        justifyContent="center"
                        mb={10}
                        mt={5}
                      >
                        <CircularProgress size={24} />
                      </Stack>
                    )}
                    {opponentPlayers.map((o) => (
                      <ButtonBase
                        key={`${o.firstName} ${o.lastName}`}
                        component="li"
                        onClick={() => handleClick(o.playerId)}
                        sx={{
                          bgcolor: opponentId === o.playerId
                            ? "#e9decc"
                            : "",
                          borderTop: "solid 1px lightgrey",
                        }}
                      >
                        <Stack
                          direction="row"
                          py={1}
                          spacing={2}
                          sx={{ width: "100%" }}
                        >
                          <Stack ml={1}>
                            <PlayerImage
                              src={o.headshotUrlSmall}
                              alt={`${o.firstName} ${o.lastName}`}
                              borderRadius="50%"
                              size="md"
                            />
                          </Stack>
                          <Stack alignItems="flex-start" textAlign="left">
                            <Typography fontWeight={{ sm: 400, md: 600 }}>
                              {`${o.firstName} ${o.lastName}`}
                            </Typography>
                          </Stack>
                        </Stack>
                      </ButtonBase>
                    ))}
                  </Stack>
                </Box>
              </Stack>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            className="text-white"
            color="primary"
            disableElevation
            onClick={handleClickOpenDeleteSuggestion}
            type="submit"
            variant="outlined"
            sx={{
              fontWeight: "bold",
              px: 4,
              mb: 1,
            }}
          >
            Delete a Suggestion
          </Button>
          <Button
            className="text-white"
            color="primary"
            disableElevation
            onClick={() => handleUpdateSuggestion()}
            type="submit"
            variant="contained"
            disabled={
              isTournamentPlayersLoading ||
              isSuggestionsLoading ||
              isTournamentPlayersError ||
              isSuggestionsError
            }
            sx={{
              fontWeight: "bold",
              px: 4,
              mb: 1,
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <DeleteSuggestion
        open={openDelete}
        onClose={handleDeleteClose}
        onDelete={handleDeleteSuggestion}
      />
    </>
  );
}
