import { Button, Stack } from "@mui/material";

interface ControlsProps {
  onStartCreate: () => void;
  setShowAuditTrail: (value: boolean) => void;
}

export function Controls({ onStartCreate, setShowAuditTrail }: ControlsProps) {
  return (
    <Stack spacing={1}>
      <Button variant="contained" onClick={onStartCreate}>
        Create
      </Button>
      <Button variant="outlined" onClick={() => setShowAuditTrail(true)}>
        Audit Trail
      </Button>
    </Stack>
  );
}
