import { SxProps, Theme } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker as MuiTimePicker } from "@mui/x-date-pickers/TimePicker";
import { Dayjs } from "dayjs";

interface TimePickerProps {
  onChange: (time: Dayjs | null) => void;
  value?: Dayjs | null;
  style?: SxProps<Theme>;
  disabled?: boolean;
  error?: string;
}

export default function TimePicker(props: Readonly<TimePickerProps>) {
  const { value, onChange, style, disabled, error } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MuiTimePicker
        disabled={disabled}
        value={value || null}
        onChange={(newValue) => onChange(newValue)}
        sx={style}
        slotProps={{
          textField: { error: !!error, helperText: error },
        }}
      />
    </LocalizationProvider>
  );
}
