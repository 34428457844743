import DeleteIcon from "@mui/icons-material/Delete";
import { useSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import { useEffect, useState } from "react";
import {
  Box,
  Stack,
  MenuItem,
  Select,
  Card,
  FormControl,
  FormLabel,
  Avatar,
  Typography,
  IconButton,
} from "@mui/material";
import {
  UpdateFeaturedContestMutationVariables,
  useDeleteteFeaturedContestMutation,
  useGetFeaturedContestsQuery,
  useGetSuggestionQuery,
  useUpdateFeaturedContestMutation,
} from "@src/graphql/graphql.generated";
import { QueryKeys } from "@src/constants/QueryKeys";

interface FeaturedContestProps {
  index: number;
  contest?: {
    id: number;
    sportType: string;
    isFeaturedContest?: boolean | null;
    playerOne: {
      fullName: string;
      firstName: string;
      lastName: string;
      photoUrl: string;
      photoUrlHiRes: string;
    };
    playerTwo: {
      fullName: string;
      firstName: string;
      lastName: string;
      photoUrl: string;
      photoUrlHiRes: string;
    };
  };
}

export function FeaturedContestCard({ index, contest }: FeaturedContestProps) {
  const [selectedSport, setSelectedSport] = useState("");
  const [selectedSuggestionId, setSelectedSuggestionId] = useState<number>(-1);
  const { data } = useGetSuggestionQuery({ sportType: selectedSport });
  const { mutate: updateFeaturedContest } = useUpdateFeaturedContestMutation();
  const { mutate: deleteFeaturedContest } =
    useDeleteteFeaturedContestMutation();
  const { data: featuredContests, isLoading } = useGetFeaturedContestsQuery();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  useEffect(() => {
    contest && setSelectedSport(contest.sportType);
  }, [contest]);

  useEffect(() => {
    if (contest) {
      data?.getSuggestions && setSelectedSuggestionId(contest.id);
    } else {
      setSelectedSuggestionId(-1);
    }
  }, [contest, data, data?.getSuggestions]);

  const handleUpdate = (newMatchupId: number) => {
    const oldMatchupId =
      !!index || index === 0
        ? featuredContests?.getFeaturedContests[index]?.id
        : undefined;
    if (newMatchupId) {
      const request: UpdateFeaturedContestMutationVariables = {
        newMatchupId: newMatchupId,
        oldMatchupId,
      };
      updateFeaturedContest(request, {
        onSuccess: () => {
          enqueueSnackbar("Featured matchup updated successfully!", {
            variant: "success",
          });
          queryClient.invalidateQueries(QueryKeys.GetFeaturedContests);
        },
      });
    }
  };

  const handleDelete = () => {
    const oldMatchupId =
      !!index || index === 0
        ? featuredContests?.getFeaturedContests[index]?.id
        : undefined;
    oldMatchupId &&
      deleteFeaturedContest(
        { matchupId: oldMatchupId },
        {
          onSuccess: () => {
            enqueueSnackbar("Suggestion is removed successfully!", {
              variant: "success",
            });
            queryClient.invalidateQueries(QueryKeys.GetFeaturedContests);
          },
        }
      );
  };

  return (
    <Card
      sx={{
        borderRadius: 3,
        boxShadow: 1,
        position: "relative",
        py: 2,
      }}
    >
      <Stack
        sx={{ padding: 1 }}
        spacing={2}
        justifyContent="space-between"
        direction="column"
      >
        <IconButton
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            cursor: "pointer",
            zIndex: 25,
          }}
          onClick={handleDelete}
        >
          <DeleteIcon />
        </IconButton>
        <Box flex={1} mr={3}>
          <FormControl fullWidth>
            <FormLabel id={`sport-type-${index}`}>Select a sport</FormLabel>
            <Select
              labelId={`sport-type-${index}`}
              label="Select a sport"
              value={selectedSport}
              fullWidth
              onChange={(event) =>
                setSelectedSport(event.target.value as string)
              }
            >
              <MenuItem disabled value="">
                Select a sport
              </MenuItem>
              <MenuItem value="nfl">Nfl</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box flex={3}>
          <FormControl fullWidth>
            <FormLabel id="suggestion">Select a suggestion</FormLabel>
            {!isLoading && (
              <Select
                labelId="suggestion"
                label="Select a suggestion"
                defaultValue={contest?.id || 0}
                onChange={(event) => {
                  setSelectedSuggestionId(event.target.value as number);
                  handleUpdate(event.target.value as number);
                }}
                value={selectedSuggestionId}
                fullWidth
                disabled={!selectedSport}
              >
                {data?.getSuggestions.map((suggestion) => (
                  <MenuItem
                    key={suggestion.id}
                    value={suggestion.id}
                    disabled={featuredContests?.getFeaturedContests?.some(
                      (featuredContest) => suggestion.id === featuredContest.id
                    )}
                  >
                    <Stack direction="row" alignItems="center">
                      <Stack flex={1} direction="row" alignItems="center">
                        <Avatar
                          sx={{ width: 32, height: 32, mr: 3 }}
                          src={suggestion.playerOne.photoUrlHiRes}
                        />
                        <Typography fontSize={18}>
                          {suggestion.playerOne.fullName}
                        </Typography>
                      </Stack>
                      <Typography mx={2} fontSize={22} textAlign="center">
                        vs
                      </Typography>
                      <Stack
                        flex={1}
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Typography fontSize={22}>
                          {suggestion.playerTwo.fullName}
                        </Typography>
                        <Avatar
                          sx={{ width: 32, height: 32, ml: 3 }}
                          src={suggestion.playerTwo.photoUrlHiRes}
                        />
                      </Stack>
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
        </Box>
      </Stack>
    </Card>
  );
}
