import { useMemo, useState } from "react";
import {
  DataGrid,
  GridColumns,
  GridComparatorFn,
  GridFilterModel,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import { SignedInWithSidebarLayout } from "@src/components/templates/Layouts";
import {
  AuditActionFliterType,
  useGetCouponsQuery,
} from "@src/graphql/graphql.generated";
import {
  CreateDialog,
  DeleteDialog,
  Controls,
} from "@src/components/organisms/Coupons";
import { c2d, renderCurrency } from "@src/helpers/currency";
import { dateRangeOperators } from "@organisms/DataGridFilters/DateRange/dateRangeOperators";
import { AuditLogsModal } from "@src/components/templates/AuditLogsModal";

export function Coupons() {
  const {
    data: coupons,
    isLoading,
    refetch,
  } = useGetCouponsQuery(
    {},
    {
      staleTime: 300_000, // 5 min
    }
  );
  const [isFilterDate, setIsFilterDate] = useState(false);
  const [isCreateOpen, setCreateOpen] = useState(false);
  const [isDeleteOpen, setDeleteOpen] = useState(false);
  const [showAuditTrail, setShowAuditTrail] = useState(false);
  const [selectedCoupons, setSelectedCoupons] = useState<Array<number>>([]);

  const onFilterChange = (model: GridFilterModel) => {
    const { columnField } = model.items[0];
    if (columnField === "createdAt") {
      setIsFilterDate(true);
      return;
    }
    setIsFilterDate(false);
  };

  const handleCloseCreate = (refresh = false) => {
    setCreateOpen(false);
    if (refresh) {
      refetch();
    }
  };

  const handleCloseDelete = (refresh = false) => {
    setDeleteOpen(false);
    if (refresh) {
      refetch();
    }
  };

  const handleCloseAudit = () => {
    setShowAuditTrail(false);
  };

  const columns = useMemo<GridColumns>(
    () => [
      { field: "code", headerName: "Code", width: 80 },
      { field: "value", headerName: "Value", width: 100 },
      { field: "status", headerName: "Status", width: 100 },
      { field: "id", headerName: "ID", width: 80 },
      {
        field: "createdAt",
        headerName: "Creation Date",
        width: 200,
        valueFormatter: (params) =>
          dayjs(params.value as string).format("MMM D, YYYY, H:ma"),
        sortComparator: dateComparator,
        filterOperators: dateRangeOperators,
      },
    ],
    []
  );

  const rows = useMemo(
    () =>
      coupons?.getCoupons.map((coupon) => ({
        ...coupon,
        value: renderCurrency(c2d(coupon.value)),
        status: coupon.status === 1 ? "Active" : "Not Active",
      })) ?? [],
    [coupons]
  );

  return (
    <SignedInWithSidebarLayout
      title="Coupons"
      SidebarComponent={
        <Controls
          isDeleteDisabled={selectedCoupons.length < 1}
          onStartCreate={() => setCreateOpen(true)}
          onStartDelete={() => setDeleteOpen(true)}
          setShowAuditTrail={setShowAuditTrail}
        />
      }
    >
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <DataGrid
          checkboxSelection
          columns={columns}
          initialState={{
            sorting: {
              sortModel: [{ field: "startDate", sort: "asc" }],
            },
          }}
          rows={rows}
          sx={{
            minHeight: "75vh",
          }}
          componentsProps={{
            panel: {
              sx: {
                "& .MuiDataGrid-filterForm": {
                  width: isFilterDate ? 600 : 500,
                },
              },
            },
          }}
          onSelectionModelChange={(idArr) =>
            setSelectedCoupons(idArr as Array<number>)
          }
          selectionModel={selectedCoupons}
          onFilterModelChange={onFilterChange}
        />
      )}
      <CreateDialog isOpen={isCreateOpen} onClose={handleCloseCreate} />
      <DeleteDialog
        selectedCoupons={selectedCoupons}
        isOpen={isDeleteOpen}
        onClose={handleCloseDelete}
      />
      <AuditLogsModal
        title="Coupons Audit"
        isOpen={showAuditTrail}
        onClose={handleCloseAudit}
        actions={[
          AuditActionFliterType.CreateCoupon,
          AuditActionFliterType.DeleteCoupon,
        ]}
      />
    </SignedInWithSidebarLayout>
  );
}

const dateComparator: GridComparatorFn = (isoDate1, isoDate2): 1 | -1 => {
  return dayjs(isoDate1 as string).isBefore(dayjs(isoDate2 as string)) ? 1 : -1;
};
