import SportsBasketballIcon from "@mui/icons-material/SportsBasketball";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Badge, Box, Tab, Typography, styled } from "@mui/material";
import { theme } from "@src/constants/theme";
import { useEffect, useState } from "react";

import SportsRugbyIcon from "@mui/icons-material/SportsRugby";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import { YYYY_MM_DD } from "@src/constants/Touchdown";
import { SupportedSports } from "@src/graphql/graphql.generated";
import {
  PrizePoolCreationFields,
  TouchdownCreationFields,
} from "@src/types/touchdown/contest";
import { NbaContestCreation } from "./NbaContestCreation";
import { NflContestCreation } from "./NflContestCreation";
import { SoccerCreation } from "./SoccerCreation";

const StyledTab = styled(Tab)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)} 0`,
  minHeight: "auto",
  justifyContent: "flex-start",
  marginRight: theme.spacing(3),
  fontSize: "16px",
}));

const getSportIcon = (name: string) => {
  switch (name) {
    case SupportedSports.Nfl:
      return (
        <SportsRugbyIcon
          sx={{
            marginRight: theme.spacing(1),
            marginBottom: "0 !important",
            fontSize: "18px",
          }}
        />
      );

    case SupportedSports.Nba:
      return (
        <SportsBasketballIcon
          sx={{
            marginRight: theme.spacing(1),
            marginBottom: "0 !important",
            fontSize: "18px",
          }}
        />
      );

    case "SOCCER":
      return (
        <SportsSoccerIcon
          sx={{
            marginRight: theme.spacing(1),
            marginBottom: "0 !important",
            fontSize: "18px",
          }}
        />
      );

    default:
      return <div />;
  }
};
interface ContestCreationProps {
  touchdownData?: TouchdownCreationFields;
  selectedDate: string;
}

export function ContestCreation(props: ContestCreationProps) {
  const { selectedDate, touchdownData } = props;

  const [nflContestsCount, setNflContestsCount] = useState<number>(0);
  const [soccerContestsCount, setSoccerContestsCount] = useState<number>(0);
  const [nbaContestsCount, setNbaContestsCount] = useState<number>(0);
  const [selectedTab, setSelectedTab] = useState<SupportedSports>(
    SupportedSports.Nba
  );
  const [prizePool, setPrizePool] = useState<PrizePoolCreationFields | null>(
    null
  );

  useEffect(() => {
    if (touchdownData?.prizePools.length) {
      const prizePool = touchdownData?.prizePools?.find(
        (pp) => pp?.startDate?.format(YYYY_MM_DD) === selectedDate
      );
      if (prizePool) {
        setPrizePool(prizePool);
        setNflContestsCount(prizePool.nflContestsCount || 0);
        setSoccerContestsCount(prizePool.soccerContestsCount || 0);
        setNbaContestsCount(prizePool.nbaContestsCount || 0);
      }
    }
  }, [touchdownData?.prizePools, selectedDate]);

  const contestCountBySport: any = {
    [SupportedSports.Nba]: nbaContestsCount,
    [SupportedSports.Nfl]: nflContestsCount,
    [SupportedSports.Soccer]: soccerContestsCount,
  };

  return (
    <Box>
      <Typography variant="h5" fontWeight={500}>
        Contest
      </Typography>
      <Box marginTop={theme.spacing(3)}>
        <TabContext value={selectedTab}>
          <Box>
            <TabList
              onChange={(_, value) => setSelectedTab(value)}
              aria-label="lab API tabs example"
              sx={{
                minHeight: "auto",
                "& .MuiTabs-flexContainer": {
                  borderBottom: "1px solid rgba(240, 240, 240, 1)",
                },
              }}
            >
              {["NBA", "NFL", "SOCCER"].map((value) => (
                <StyledTab
                  key={value}
                  label={
                    <>
                      {value}
                      <Badge
                        badgeContent={contestCountBySport[value] || "0"} // contest creation count
                        sx={{
                          marginLeft: theme.spacing(2),
                          "& .MuiBadge-badge": {
                            backgroundColor: "rgba(250, 242, 226, 1)",
                            color: theme.palette.primary.main,
                            fontSize: "16px",
                          },
                        }}
                      ></Badge>
                    </>
                  }
                  value={value}
                  icon={getSportIcon(value)}
                />
              ))}
            </TabList>
          </Box>

          <TabPanel
            value={SupportedSports.Nba}
            sx={{
              padding: `${theme.spacing(3)} 0 ${theme.spacing(3)} 0`,
            }}
          >
            <NbaContestCreation
              key={SupportedSports.Nba}
              selectedDate={selectedDate}
              updateCount={setNbaContestsCount}
              prizePool={prizePool}
            />
          </TabPanel>

          <TabPanel
            value={SupportedSports.Nfl}
            sx={{
              padding: `${theme.spacing(3)} 0 ${theme.spacing(3)} 0`,
            }}
          >
            <NflContestCreation
              key={SupportedSports.Nfl}
              selectedDate={selectedDate}
              updateCount={setNflContestsCount}
              prizePool={prizePool}
            />
          </TabPanel>
          <TabPanel
            value={"SOCCER"}
            sx={{
              padding: `${theme.spacing(3)} 0 ${theme.spacing(3)} 0`,
            }}
          >
            <SoccerCreation
              key={SupportedSports.Nfl}
              selectedDate={selectedDate}
              updateCount={setSoccerContestsCount}
              prizePool={prizePool}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
}
