import { useState } from "react";
import { useSnackbar } from "notistack";
import {
  AuditActionFliterType,
  useBroadcastNotificationMutation,
} from "@src/graphql/graphql.generated";
import { SignedInWithSidebarLayout } from "@templates/Layouts";
import {
  Button,
  FormControl,
  Box,
  Stack,
  TextField,
  InputAdornment,
  FormHelperText,
} from "@mui/material";
import { parseGQLError } from "@src/utils/parse-error";
import TitleIcon from "@mui/icons-material/TitleOutlined";
import DescriptionIcon from "@mui/icons-material/DescriptionOutlined";
import { AuditLogsModal } from "@src/components/templates/AuditLogsModal";

const MAX_NOTIFICATION_TITLE_LENGTH = 64;
const MAX_NOTIFICATION_TEXT_LENGTH = 240;

export function BroadcastNotifications() {
  const [body, setBody] = useState("");
  const [title, setTitle] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [showAuditTrail, setShowAuditTrail] = useState(false);
  const { mutate: update, isLoading } = useBroadcastNotificationMutation();

  const handleSend = () => {
    if (!body || !title) {
      return enqueueSnackbar("The Notification title or description cannot be empty", { variant: "error" });
    }
    update(
      { title, body },
      {
        onSuccess: () => {
          enqueueSnackbar("Successfully sent the notification to users!", {
            variant: "success",
          });
          setTitle("");
          setBody("");
        },
        onError: (error: any) => {
          enqueueSnackbar(parseGQLError(error, "Unable to send"), {
            variant: "error",
          });
        },
      }
    );
  };

  const handleCloseAudit = () => {
    setShowAuditTrail(false);
  };

  return (
    <SignedInWithSidebarLayout
      title="Broadcast Notification"
      SidebarComponent={
        <Controls
          isLoading={false}
          onSend={handleSend}
          setShowAuditTrail={setShowAuditTrail}
        />
      }
    >
      <Box component="main" sx={{ width: "700px" }}>
        <Stack spacing={2}>
          <FormControl>
            <TextField
              id="title"
              label="Notification Title"
              type="text"
              required
              InputProps={{
                inputProps: { maxLength: MAX_NOTIFICATION_TITLE_LENGTH },
                startAdornment: (
                  <InputAdornment position="start">
                    <TitleIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(ev) => setTitle(ev.target.value)}
              value={title}
            />
            <FormHelperText error={title.length >= MAX_NOTIFICATION_TITLE_LENGTH}>
              {title.length}/{MAX_NOTIFICATION_TITLE_LENGTH}
            </FormHelperText>
          </FormControl>

          <FormControl>
            <TextField
              id="notification-body"
              label="Notification Text"
              type="text"
              required
              multiline
              minRows={4}
              InputProps={{
                inputProps: {maxLength: 240},
                startAdornment: (
                  <InputAdornment position="start">
                    <DescriptionIcon />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                sx: {
                  WebkitAppearance: "none",
                  MozAppearance: "textfield",
                },
              }}
              onChange={(ev) => setBody(ev.target.value)}
              value={body}
            />
            <FormHelperText error={body.length >= MAX_NOTIFICATION_TEXT_LENGTH}>
              {body.length}/{MAX_NOTIFICATION_TEXT_LENGTH}
            </FormHelperText>
          </FormControl>
        </Stack>
      </Box>
      <AuditLogsModal
        title="Broadcast Notification Audit"
        isOpen={showAuditTrail}
        onClose={handleCloseAudit}
        actions={[AuditActionFliterType.BroadcastNotification]}
      />
    </SignedInWithSidebarLayout>
  );
}

interface ControlsProps {
  isLoading: boolean;
  onSend: () => void;
  setShowAuditTrail: (value: boolean) => void;
}

function Controls({ isLoading, onSend, setShowAuditTrail }: ControlsProps) {
  return (
    <Stack spacing={1}>
      <Button onClick={onSend} disabled={isLoading} variant="contained">
        Send
      </Button>
      <Button onClick={() => setShowAuditTrail(true)} variant="outlined">
        Audit Trail
      </Button>
    </Stack>
  );
}
