import ClosedCaptionIcon from "@mui/icons-material/ClosedCaption";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { useCreateCouponMutation } from "@src/graphql/graphql.generated";
import { parseGQLError } from "@src/utils/parse-error";
import { useSnackbar } from "notistack";
import { useState } from "react";

interface CreateDialogProps {
  isOpen: boolean;
  onClose: (refresh: boolean) => void;
}

export function CreateDialog({ isOpen, onClose }: CreateDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: create, isLoading } = useCreateCouponMutation();
  const [code, setCode] = useState("");
  const [value, setValue] = useState(0);

  const handleCreate = () => {
    create(
      { data: { code, value } },
      {
        onSuccess: () => {
          enqueueSnackbar("Coupon has been added successfully!", {
            variant: "success",
          });
          onClose(true);
        },
        onError: (error: any) => {
          enqueueSnackbar(parseGQLError(error, "Unable to create"), {
            variant: "error",
          });
        },
      }
    );
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={onClose}>
      <DialogTitle>Create Coupon</DialogTitle>
      <DialogContent>
        <Box component="main" mt={2}>
          <Stack spacing={2}>
            <FormControl>
              <TextField
                label="Coupon Code"
                required
                InputProps={{
                  "aria-label": "code-input",
                  startAdornment: (
                    <InputAdornment position="start">
                      <ClosedCaptionIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(ev) => setCode(ev.target.value)}
                value={code}
              />
            </FormControl>

            <FormControl>
              <TextField
                label="Value"
                type="number"
                required
                InputProps={{
                  "aria-label": "value-input",
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocalAtmIcon />
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  sx: {
                    WebkitAppearance: "none",
                    MozAppearance: "textfield",
                  },
                }}
                onChange={(ev) => setValue(Number(ev.target.value))}
                value={value}
              />
              <FormHelperText>value by cents</FormHelperText>
            </FormControl>
            <Button
              variant="contained"
              onClick={handleCreate}
              disabled={isLoading || !value || !code}
            >
              Create
            </Button>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
