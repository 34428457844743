import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { theme } from "@src/constants/theme";
import { useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const StyledLabel = styled("label")(({ theme }) => ({
  color: "#212121",
  fontSize: "14px",
}));

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  marginTop: theme.spacing(1),

  "& .MuiOutlinedInput-root": {
    height: "50px",
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  marginTop: theme.spacing(1),
  height: "50px",
}));

export function ContestAnalysis() {
  const [contest, setContest] = useState("");
  const handleContestChange = (event: any) => {
    setContest(event.target.value as string);
  };
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <StyledLabel>Date Range</StyledLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StyledDatePicker />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth>
            <StyledLabel>Contests</StyledLabel>
            <StyledSelect
              value={contest}
              onChange={handleContestChange}
              sx={{ backgroundColor: "#fff" }}
              displayEmpty
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </StyledSelect>
          </FormControl>
        </Grid>
        <Grid item xs={3} display="flex" alignItems="flex-end">
          <FormControl fullWidth>
            <StyledLabel></StyledLabel>
            <FormControlLabel
              control={<Checkbox />}
              label="Show Graphs by Sport"
            />
          </FormControl>
        </Grid>
        <Grid
          item
          xs={4}
          display="flex"
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          <Button variant="contained" size="large">
            <ArrowForwardIcon />
          </Button>
        </Grid>
      </Grid>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" marginTop={theme.spacing(3)}>
          <Stack
            direction="row"
            alignItems="center"
            marginRight={theme.spacing(3)}
          >
            <Typography>Total Contests :</Typography>
            <Typography fontWeight={600} marginLeft={theme.spacing(1)}>
              100
            </Typography>
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            marginRight={theme.spacing(3)}
          >
            <Typography>NBA :</Typography>
            <Typography fontWeight={600} marginLeft={theme.spacing(1)}>
              40
            </Typography>
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            marginRight={theme.spacing(3)}
          >
            <Typography>PGA :</Typography>
            <Typography fontWeight={600} marginLeft={theme.spacing(1)}>
              20
            </Typography>
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            marginRight={theme.spacing(3)}
          >
            <Typography>MLB :</Typography>
            <Typography fontWeight={600} marginLeft={theme.spacing(1)}>
              20
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" marginTop={theme.spacing(3)}>
          <Stack direction="row" alignItems="center">
            <Typography>Total User Entry :</Typography>
            <Typography fontWeight={600} marginLeft={theme.spacing(1)}>
              150
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}
