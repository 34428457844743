export interface GQLError {
  response: {
    errors: Array<{
      message: string;
      extensions: {
        code: string;
        response: {
          statusCode: number;
          message: string;
          error: string;
        };
      };
    }>;
  };
}

export function parseGQLError(error: GQLError, fallbackMessage?: string) {
  try {
    return error.response.errors[0].message;
  } catch (_ex) {
    return (
      fallbackMessage ??
      "Error retrieving data. Do you have sufficient privileges?"
    );
  }
}

export const NumberValidationSchema = {
  required: {
    value: true,
    message: "Please fill this field",
  },
  pattern: {
    value: /^[0-9]+(\.[0-9]+)?$/,
    message: "Please enter an integer",
  },
  min: {
    value: 0.1,
    message: "Value should be atleast 0.1",
  },
};

export const NumberOfPicksValidationSchema = {
  required: {
    value: true,
    message: "Please fill this field",
  },
  pattern: {
    value: /^[0-9]+(\.[0-9]+)?$/,
    message: "Please enter an integer",
  },
  min: {
    value: 0.1,
    message: "Value should be atleast 0.1",
  },
  max: {
    value: 10,
    message: "Value should be less than 10",
  },
};

export const StringValidationSchema = {
  required: {
    value: true,
    message: "Please fill this field",
  },
};
