import { FC } from "react";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import {
  Button,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  Stack,
  TextField,
  useTheme,
  FormHelperText,
} from "@mui/material";
import { DialogTitle } from "@src/components/molecules/DialogTitle";
import { useSendContestGradedEmailMutation } from "@src/graphql/graphql.generated";
import { SOMETHING_WRONG_HAPPENED } from "@src/constants/strings";
import { ModalProps, SEND_CONTEST_GRADED_EMAIL } from "./crons";

interface IFormValues {
  contestIds: string;
}

export const SendContestGradedEmail: FC<ModalProps> = ({
  isOpen,
  onSubmit,
  onClose,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { register, handleSubmit } = useForm<IFormValues>();
  const { mutate: sendContestGradedEmail, isLoading } =
    useSendContestGradedEmailMutation();

  const onFormSubmit = (data: IFormValues) => {
    sendContestGradedEmail(
      {
        contestIds: data.contestIds,
      },
      {
        onSuccess: (resData) => {
          if (resData.sendContestGradedEmail.success) {
            onSubmit(SEND_CONTEST_GRADED_EMAIL);
            onClose();
          } else {
            enqueueSnackbar(SOMETHING_WRONG_HAPPENED, {
              variant: "default",
            });
          }
        },
        onError: () => {
          enqueueSnackbar(SOMETHING_WRONG_HAPPENED, {
            variant: "default",
          });
        },
      }
    );
  };

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <DialogTitle onClose={onClose}>Send Contest Graded Email</DialogTitle>
        <DialogContent>
          <Stack
            alignItems="center"
            flex={1}
            justifyContent="center"
            p={2}
            spacing={1}
          >
            <Box component="main" sx={{ maxWidth: "400px" }}>
              <Stack spacing={2}>
                <FormControl>
                  <TextField
                    label="Contest ID(s)"
                    required
                    inputProps={{
                      sx: {
                        WebkitAppearance: "none",
                        MozAppearance: "textfield",
                      },
                    }}
                    {...register("contestIds")}
                  />
                  <FormHelperText sx={{ fontSize: "0.8rem" }}>
                    You can enter multiple Contest IDs seprated by comma ","
                  </FormHelperText>
                </FormControl>
              </Stack>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ bgcolor: theme.palette.grey[100], p: 2 }}>
          <Button
            className="text-white"
            color="secondary"
            disableElevation
            onClick={onClose}
            type="button"
            variant="outlined"
          >
            Close
          </Button>
          <Button
            className="text-white"
            color="primary"
            disableElevation
            type="submit"
            variant="contained"
            disabled={isLoading}
          >
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
