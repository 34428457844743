import { Box, Grid, Typography, styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { theme } from "@src/constants/theme";
import {
  PrizePoolDetails,
  TouchdownDetails,
  WEEKLY_COUNT_LABELS,
  WeeklyCounts,
  WeeklyTouchDownProps,
  initTotals,
  initTouchdown,
} from "@src/types/touchdown/dashboard";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { WeeklyTouchDownDetail } from "../WeeklyTouchDownDetail";
import { EST_TIME_ZONE } from "@src/constants/Touchdown";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: "1px solid #EEE8DC",
  "& .MuiDataGrid-cell": {
    borderBottom: "1px solid #EEE8DC",
  },
  "& .MuiDataGrid-columnHeaders": {
    borderBottom: "1px solid #EEE8DC",
  },
  "& .MuiDataGrid-footerContainer": {
    borderTop: "1px solid #EEE8DC",
  },
}));

export function WeeklyTouchDown(props: WeeklyTouchDownProps) {
  const { title, columns, weeklyTouchdownData } = props;

  const [touchdownData, setTouchdownData] =
    useState<
      Omit<TouchdownDetails, "mathConstant" | "weeklyPrizeDistribution">
    >();
  const [weeklyCounts, setWeeklyCounts] = useState<WeeklyCounts>(initTotals);

  const createPrizePoolList = (touchdownInfo: TouchdownDetails) =>
    touchdownInfo?.prizePools?.map(
      (pp: PrizePoolDetails) =>
        ({
          ...pp,
          id: pp.prizePoolId,
          date: dayjs(pp.startDate)
            .utc()
            .tz(EST_TIME_ZONE)
            .format("MM-DD-YYYY"),
          predetermineJackpot: +pp.predetermineJackpot.toFixed(2),
          predetermineDailyGPP: +pp.predetermineDailyGPP.toFixed(2),
          profit: pp.profit,
        } as PrizePoolDetails)
    );

  const calculateCounts = (touchdownData: TouchdownDetails) => {
    const totals: WeeklyCounts = {
      totalWeeklyPrize: 0,
      totalEntries: 0,
      receivedFees: 0,
      totalTopPropVig: 0,
      actualTotalWeeklyReserve: 0,
      moneyAddedByTopProp: 0,
      profitLoss: 0,
    };
    touchdownData.prizePools?.forEach(
      ({
        userEntryCount,
        entryFees,
        predetermineWeeklyReserve,
        actualWeeklyReserve,
        actualContestVig,
        actualRolloverVig,
        profit,
      }: PrizePoolDetails) => {
        totals.totalWeeklyPrize += predetermineWeeklyReserve;
        totals.actualTotalWeeklyReserve += actualWeeklyReserve ?? 0;
        totals.totalEntries += userEntryCount;
        totals.receivedFees += userEntryCount * entryFees;
        totals.totalTopPropVig +=
          (actualContestVig ?? 0) + (actualRolloverVig ?? 0);
        totals.profitLoss += profit ?? 0;
      }
    );

    if (totals.profitLoss < 0) {
      totals.moneyAddedByTopProp = totals.profitLoss;
    }

    const prizePoolList: PrizePoolDetails[] | undefined =
      createPrizePoolList(touchdownData);
    const touchdown: TouchdownDetails = {
      ...touchdownData,
      prizePools: prizePoolList,
    };

    setWeeklyCounts({ ...totals });
    setTouchdownData({ ...touchdown });
  };

  useEffect(() => {
    const touchdowns = weeklyTouchdownData?.getTouchdownByDate;
    if (touchdowns?.length) {
      const touchdownData = touchdowns[0];
      calculateCounts(touchdownData);
    } else {
      setWeeklyCounts({ ...initTotals });
      setTouchdownData({ ...initTouchdown });
    }
  }, [weeklyTouchdownData]);

  return (
    <>
      <Box marginBottom={theme.spacing(3)}>
        <Typography
          variant="h5"
          fontWeight={500}
          marginBottom={theme.spacing(3)}
        >
          {title}
        </Typography>
        <Grid container spacing={2}>
          {WEEKLY_COUNT_LABELS?.map((item, index) => (
            <WeeklyTouchDownDetail
              key={index}
              index={index}
              title={item.title}
              value={weeklyCounts[item.key]}
            />
          ))}
        </Grid>
      </Box>
      <Box
        style={{ height: "475px", overflow: "auto" }}
        marginBottom={theme.spacing(3)}
      >
        <StyledDataGrid
          columns={columns}
          initialState={{
            sorting: {
              sortModel: [{ field: "startDate", sort: "asc" }],
            },
          }}
          rows={touchdownData?.prizePools ?? []}
          pageSize={7}
        />
      </Box>
    </>
  );
}
