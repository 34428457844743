import { FormEvent, useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import NumbersIcon from "@mui/icons-material/NumbersOutlined";
import { grey } from "@mui/material/colors";
import { useSendAuthCode } from "@src/hooks/useSendAuthCode";
import { useLogin } from "@src/hooks/useLogin";
import { ROUTES } from "@src/Routes";
import TopPropFullLogo from "@src/images/TopProp_Full_Logo.png";
import { getUserPermissions } from "@src/services/auth";
import { PermissionId } from "@src/constants/permissions";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useAuth } from "@src/hooks/useAuth";
import { loadAuthTokens } from "@src/services/authTokenStorage";
import { AccessTokenPayload } from "@src/types";
import * as jwt from "jsonwebtoken";

export function Login() {
  const [email, setEmail] = useState("");
  const [authCode, setAuthCode] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [token, setToken] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { mutate: sendAuthCode, isLoading: isSendingAuthCode } =
    useSendAuthCode();
  const { mutate: login, isLoading: isLoggingIn } = useLogin();

  useEffect(() => {
    const loginRedirect = async () => {
      const { isSuperuser, permissions } = await getUserPermissions();
      const tokens = loadAuthTokens();
      let payload: AccessTokenPayload = jwt.decode(
        tokens.accessToken
      ) as AccessTokenPayload;
      const { data } = payload;

      if (data && data?.role === "ADMIN") {
        return navigate(ROUTES.Dashboard);
      } else if (isSuperuser) {
        navigate(ROUTES.Touchdown.Dashboard);
      } else {
        switch (permissions[0]) {
          case PermissionId.GolfContestSuggestionsManage:
            return navigate(ROUTES.PGA.Matchups);
          case PermissionId.GolfTournamentView:
            return navigate(ROUTES.PGA.Tournaments);
          case PermissionId.GolfFeaturesView:
            return navigate(ROUTES.PGA.FeatureFlags);
          case PermissionId.CommonBonusPayout:
            return navigate(ROUTES.PGA.BonusPayout);
          case PermissionId.GolfTasks:
            return navigate(ROUTES.PGA.Crons);
          case PermissionId.GolfReports:
            return navigate(ROUTES.PGAReports.Contests);
          case PermissionId.CommonUserSignups:
            return navigate(ROUTES.General.UserSignup);
          case PermissionId.CommonCoupons:
            return navigate(ROUTES.General.Coupons);

          default:
            break;
        }
      }
    };
    if (loggedIn) {
      loginRedirect();
    }
  }, [loggedIn, navigate]);

  const handleSendAuthCode = () => {
    if (!email.trim()) {
      enqueueSnackbar("Email is required", {
        variant: "error",
      });
      return;
    }
    sendAuthCode(
      {
        email: email.trim(),
      },
      {
        onError: (err) => {
          enqueueSnackbar(
            err?.message ??
              "Failed to send auth code. Do you have an admin account?",
            {
              variant: "error",
            }
          );
        },
        onSuccess: () => {
          enqueueSnackbar("Auth code sent. Valid for 5 minutes", {
            variant: "info",
          });
        },
      }
    );
  };

  const handleVerify = (newToken: string) => {
    if (!token) {
      setToken(newToken);
    }
  };

  const handleLogin = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    if (!authCode.trim()) {
      enqueueSnackbar("Auth Code is required", {
        variant: "error",
      });
      return;
    }
    login(
      {
        email: email.trim(),
        code: authCode.trim(),
        token,
      },
      {
        onError: (err) => {
          enqueueSnackbar(err?.message ?? "Invalid email or auth code", {
            variant: "error",
          });
        },
        onSuccess: async () => {
          setLoggedIn(true);
        },
      }
    );
  };

  return (
    <>
      <Box
        component="main"
        alignItems="center"
        display="flex"
        justifyContent="center"
        minHeight="100vh"
      >
        <Card sx={{ p: 4, pt: 0, width: 500 }}>
          <form onSubmit={handleLogin}>
            <Stack spacing={2}>
              <Box component="img" src={TopPropFullLogo} />
              <Typography color={grey[600]} fontSize={18} variant="h1">
                Login to TopProp Admin
              </Typography>
              <FormControl>
                <TextField
                  id="email"
                  label="Email"
                  type="email"
                  required
                  InputProps={{
                    "aria-label": "email-input",
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(ev) => setEmail(ev.target.value)}
                  value={email}
                />
              </FormControl>

              <FormControl>
                <TextField
                  id="code"
                  label="Auth Code"
                  type="number"
                  required
                  InputProps={{
                    "aria-label": "code-input",
                    startAdornment: (
                      <InputAdornment position="start">
                        <NumbersIcon />
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    sx: {
                      WebkitAppearance: "none",
                      MozAppearance: "textfield",
                    },
                  }}
                  onChange={(ev) => setAuthCode(ev.target.value)}
                  value={authCode}
                />
                <FormHelperText>
                  Check your inbox for the auth code
                </FormHelperText>
              </FormControl>

              <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
              >
                <Button
                  disabled={isLoggingIn || isSendingAuthCode}
                  onClick={handleSendAuthCode}
                  type="button"
                  variant="text"
                >
                  Send me the code
                </Button>
                <Button
                  disabled={isLoggingIn || isSendingAuthCode}
                  type="submit"
                  variant="contained"
                >
                  Login
                </Button>
              </Stack>
            </Stack>
          </form>
        </Card>
      </Box>
      <GoogleReCaptcha onVerify={handleVerify} />
    </>
  );
}
