import { ChromePicker } from "react-color";

const ColorPicker = ({ field, onChange, defaultColor }: any) => {
  const handleColorChange = (newColor: any) => {
    onChange(field, newColor.hex);
  };

  return <ChromePicker color={defaultColor} onChange={handleColorChange} />;
};

export default ColorPicker;
