import { Button, Chip, Stack } from "@mui/material";
import { GridColumns } from "@mui/x-data-grid";
import WeekPicker from "@organisms/Pickers/WeekPicker";
import { SignedInLayout } from "@src/components/templates/Layouts";
import { DailyLeaderboard } from "@src/components/touchDown/Dashboard/DailyLeaderboard";
import { WeeklyLeaderboard } from "@src/components/touchDown/Dashboard/WeeklyLeaderboard";
import { WeeklyTouchDown } from "@src/components/touchDown/Dashboard/WeeklyTouchdown";
import { MM_DD_YYYY, YYYY_MM_DD } from "@src/constants/Touchdown";
import { useGetTouchdownByDateQuery } from "@src/graphql/graphql.generated";
import { PrizePoolDetails } from "@src/types/touchdown/dashboard";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

// const style = {
//   position: "absolute" as "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "600px",
//   bgcolor: "background.paper",
//   border: `2px solid ${theme.palette.primary.main}`,
//   boxShadow: 24,
// };

export function Dashboard() {
  const WEEKELY_TOUCHDOWN_COLUMNS = useMemo<GridColumns>(
    () => [
      {
        field: "date",
        headerName: "Days",
        width: 150,
      },
      {
        field: "status",
        headerName: "Status",
        width: 150,
        renderCell: (params) => {
          let backgroundColor, borderColor, color;

          if (params.value === "COMPLETED") {
            backgroundColor = "#eaf1ed";
            borderColor = "rgba(20, 109, 52, 1)";
            color = "rgba(20, 109, 52, 1)";
          }
          if (params.value === "DRAFT") {
            backgroundColor = "rgba(255, 247, 234, 1)";
            borderColor = "rgba(182, 144, 86, 1)";
            color = "rgba(182, 144, 86, 1)";
          }
          if (params.value === "LIVE") {
            backgroundColor = "rgba(255, 229, 231, 1)";
            borderColor = "rgba(245, 34, 45, 1)";
            color = "rgba(245, 34, 45, 1)";
          }

          return (
            <Chip
              label={params.value}
              sx={{
                fontWeight: "500",
                borderRadius: "5px",
                backgroundColor,
                color,
                border: `1px solid ${borderColor}`,
                height: "auto",
              }}
            />
          );
        },
      },

      { field: "entryFees", headerName: "Entry Fees", width: 150 },
      { field: "totalEntrants", headerName: "Entry Limit", width: 150 },
      { field: "userEntryCount", headerName: "User Entry", width: 150 },
      {
        field: "predeterminePrizePool",
        headerName: "Prize Pool",
        width: 150,
        renderCell: (params) => <span>${params.value}</span>,
      },
      {
        field: "isRollsOver",
        headerName: "Pot Rolls Over",
        width: 150,
        renderCell: (params) => (
          <div
            style={{
              fontWeight: "500",
              borderRadius: "5px",
              color:
                params.row.status === "COMPLETED"
                  ? params.value === true
                    ? "rgba(20, 109, 52, 1)"
                    : "rgba(245, 34, 45, 1)"
                  : undefined,
            }}
          >
            {params.row.status === "COMPLETED"
              ? params.value
                ? "Yes"
                : "No"
              : "-"}
          </div>
        ),
      },
      {
        field: "predetermineJackpot",
        headerName: "Jackpot Prize",
        width: 150,
        renderCell: (params) => <span>${params.value}</span>,
      },
      {
        field: "jackpotWinnersCount",
        headerName: "Winners",
        width: 150,
        renderCell: (params) => (
          <span>{params.row.status === "COMPLETED" ? params.value : "-"}</span>
        ),
      },
      {
        field: "predetermineDailyGPP",
        headerName: "GPP",
        width: 150,
        renderCell: (params) => <span>${params.value}</span>,
      },
      {
        field: "gppWinnersCount",
        headerName: "Winners",
        width: 150,
        renderCell: (params) => (
          <span>{params.row.status === "COMPLETED" ? params.value : "-"}</span>
        ),
      },
      {
        field: "predetermineContestVig",
        headerName: "Contest VIG",
        width: 150,
        renderCell: (params) => <span>${params.value}</span>,
      },
      {
        field: "predetermineRolloverVig",
        headerName: "Rollover VIG",
        width: 150,
        renderCell: (params) => <span>${params.value}</span>,
      },
      {
        field: "addedByTopProp",
        headerName: "Money Added By TopProp",
        width: 150,
        renderCell: (params) => <span>${params.value}</span>,
      },
      {
        field: "profit",
        headerName: "Profit/Loss",
        width: 150,
        renderCell: (params) => (
          <span>
            {params.value && params.row.status === "COMPLETED"
              ? "$" + params.value
              : "-"}
          </span>
        ),
      },
      {
        field: "nbaContestsCount",
        headerName: "Created NBA Contest",
        width: 150,
      },
      {
        field: "nflContestsCount",
        headerName: "Created NFL Contest",
        width: 150,
      },
      {
        field: "dailyLeaderBoard",
        headerName: "Daily Leaderboard",
        width: 150,
        renderCell: (params) => (
          <>
            {params.row.status === "DRAFT" ? (
              "-"
            ) : (
              <Button
                variant="outlined"
                onClick={() => setSelectedPrizePool(params.row)}
                size="small"
                sx={{ width: "70px" }}
              >
                VIEW
              </Button>
            )}
          </>
        ),
      },
    ],
    []
  );

  const [selectedPrizePool, setSelectedPrizePool] =
    useState<null | PrizePoolDetails>(null);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [weekRange, setWeekRange] = useState<Array<dayjs.Dayjs>>([
    dayjs().startOf("week"),
    dayjs().endOf("week"),
  ]);

  const navigate = useNavigate();
  const { data: weeklyTouchdownData, isLoading } = useGetTouchdownByDateQuery({
    startDate: weekRange[0].format(YYYY_MM_DD),
    endDate: weekRange[1].format(YYYY_MM_DD),
  });
  // const openModal = () => {
  //   setIsModalOpen(true);
  // };

  // const onModalClose = () => {
  //   setIsModalOpen(false);
  // };

  const handleDateChange = (selectedDate: dayjs.Dayjs | null) => {
    if (selectedDate) {
      const weekStartDate = selectedDate.startOf("week");
      const weekEndDate = selectedDate.endOf("week");
      setWeekRange([weekStartDate, weekEndDate]);
    }
  };

  const getWeekRange = (text: string): string =>
    `${text} ( ${weekRange[0].format(MM_DD_YYYY)} to ${weekRange[1].format(
      MM_DD_YYYY
    )} ) `;

  return (
    <SignedInLayout title="Touchdown Dashboard">
      {selectedPrizePool ? (
        <DailyLeaderboard
          parentComponent="dashboard"
          prizePoolId={selectedPrizePool.prizePoolId}
          title={`Daily Leaderboard (${dayjs(
            selectedPrizePool.startDate
          ).format(MM_DD_YYYY)})`}
          onBack={() => setSelectedPrizePool(null)}
        />
      ) : (
        <>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              marginBottom: (theme1) => theme1.spacing(3),
            }}
          >
            <WeekPicker
              defaultValue={dayjs()}
              handleDateChange={handleDateChange}
            />

            <Button
              variant="contained"
              onClick={() =>
                navigate("/touchdown/contest", {
                  state: "touchdown",
                })
              }
            >
              Create Touchdown
            </Button>
          </Stack>
          <WeeklyTouchDown
            title={getWeekRange("Weekly Touchdown")}
            columns={WEEKELY_TOUCHDOWN_COLUMNS}
            weeklyTouchdownData={weeklyTouchdownData}
          />
          {!isLoading && (
            <WeeklyLeaderboard
              title={getWeekRange("Weekly Leaderboard")}
              weeklyTouchdownData={weeklyTouchdownData}
              parentComponent="dashboard"
            />
          )}
        </>
      )}

      {/* <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isModalOpen}
        onClose={onModalClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isModalOpen}>
          <Box sx={style}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",

                padding: theme.spacing(2),
              }}
            >
              <Typography variant="h6" id="transition-modal-title">
                Payout Confirmation
              </Typography>
              <IconButton onClick={onModalClose} sx={{ padding: 0 }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider sx={{ borderColor: "rgba(240, 240, 240, 1)" }} />
            <Box marginBottom={theme.spacing(2)} padding={theme.spacing(2)}>
              <Typography id="transition-modal-title">
                Please review payout information below.
              </Typography>
              <Stack
                direction="row"
                id="transition-modal-description"
                sx={{ mt: 2 }}
              >
                Payout amount :{" "}
                <Typography
                  sx={{ color: theme.palette.primary.main, fontWeight: 500 }}
                >
                  $120
                </Typography>
              </Stack>
              <Stack direction="row" marginTop={theme.spacing(1)}>
                <Stack direction="row" marginRight={theme.spacing(2)}>
                  Email :
                  <Typography
                    sx={{ color: theme.palette.primary.main, fontWeight: 500 }}
                  >
                    diannerussell@gmail.com
                  </Typography>
                </Stack>
                <Stack direction="row" marginRight={theme.spacing(2)}>
                  Username :
                  <Typography
                    sx={{ color: theme.palette.primary.main, fontWeight: 500 }}
                  >
                    blockedlunation
                  </Typography>
                </Stack>
              </Stack>
            </Box>
            <Divider sx={{ borderColor: "rgba(240, 240, 240, 1)" }} />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                padding: theme.spacing(1),
              }}
            >
              <Button
                variant="outlined"
                sx={{ marginRight: theme.spacing(1) }}
                size="small"
              >
                Cancel
              </Button>
              <Button variant="contained" size="small">
                Payout
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal> */}
    </SignedInLayout>
  );
}
