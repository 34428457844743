import WestIcon from "@mui/icons-material/West";
import { Box, Chip, Typography, styled } from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { EST_TIME_ZONE } from "@src/constants/Touchdown";
import { theme } from "@src/constants/theme";
import { useGetWeeklyLeaderBoardQuery } from "@src/graphql/graphql.generated";
import { WeeklyLeaderboardProps } from "@src/types/touchdown/dashboard";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: "1px solid #EEE8DC",
  "& .MuiDataGrid-cell": {
    borderBottom: "1px solid #EEE8DC",
  },
  "& .MuiDataGrid-columnHeaders": {
    borderBottom: "1px solid #EEE8DC",
  },
  "& .MuiDataGrid-footerContainer": {
    borderTop: "1px solid #EEE8DC",
  },
}));

export function WeeklyLeaderboard(props: WeeklyLeaderboardProps) {
  const {
    title,
    weeklyTouchdownData,
    renderBack,
    touchdownId,
    parentComponent,
    onBack,
  } = props;
  const touchdownData = weeklyTouchdownData?.getTouchdownByDate?.[0];

  const navigate = useNavigate();
  const { data: leaderboardData } = useGetWeeklyLeaderBoardQuery({
    touchdownId: touchdownData?.touchdownId || touchdownId || 0,
    searchStr: "",
  });

  const COLUMNS: GridColumns = useMemo<GridColumns>(
    () => [
      { field: "leaderboardRank", headerName: "Rank", width: 200 },
      {
        field: "winAmount",
        headerName: "Winning Amount",
        width: 200,
        renderCell: (params) => <span>${params.value}</span>,
      },

      { field: "name", headerName: "User", width: 200 },
      { field: "score", headerName: "Points", width: 200 },
      {
        field: "status",
        headerName: "Status",
        width: 200,
        renderCell: (params) => {
          if (params.value === "-") return "-";

          let backgroundColor, borderColor, color;

          if (params.value === "Success") {
            backgroundColor = "#eaf1ed";
            borderColor = "rgba(20, 109, 52, 1)";
            color = "rgba(20, 109, 52, 1)";
          } else {
            backgroundColor = "rgba(255, 229, 231, 1)";
            borderColor = "rgba(245, 34, 45, 1)";
            color = "rgba(245, 34, 45, 1)";
          }

          return (
            <Chip
              label={params.value}
              sx={{
                fontWeight: "500",
                borderRadius: "5px",
                backgroundColor,
                color,
                border: `1px solid ${borderColor}`,
                height: "auto",
              }}
            />
          );
        },
      },
      {
        field: "payout",
        headerName: "Payout",
        width: 200,

        renderCell: (params) => (
          <span>
            {params?.value && params.value !== "-"
              ? dayjs(params.value)
                  .tz(EST_TIME_ZONE)
                  .format("MM-DD-YYYY, h:mm A")
              : params.value}
          </span>
        ),
        // renderCell: (params) =>
        //   params.value ? (
        //     <span>Paid</span>
        //   ) : (
        //     <Button
        //       variant="contained"
        //       style={{
        //         fontWeight: "500",
        //         width: "80px",
        //       }}
        //       size="small"
        //       // onClick={openModal}
        //     >
        //       Payout
        //     </Button>
        //   ),
      },
    ],
    []
  );

  return (
    <>
      {renderBack && (
        <Box marginBottom={theme.spacing(3)}>
          <Typography
            style={{
              display: "flex",
              alignItems: "center",
              color: "#212121",
              textDecoration: "none",
              cursor: "pointer",
            }}
            // onClick={openModal}
            onClick={() => {
              navigate(`/touchdown/${parentComponent}`);
              if (onBack) onBack();
            }}
          >
            <WestIcon
              style={{ fontSize: "16px", marginRight: theme.spacing(1) }}
            />{" "}
            Back
          </Typography>
        </Box>
      )}
      <Box marginBottom={theme.spacing(3)}>
        <Typography
          variant="h5"
          fontWeight={500}
          marginBottom={theme.spacing(3)}
        >
          {title}
        </Typography>
      </Box>
      <Box
        style={{ height: "370px", overflow: "auto" }}
        marginBottom={theme.spacing(3)}
      >
        <StyledDataGrid
          columns={COLUMNS}
          initialState={{
            sorting: {
              sortModel: [{ field: "startDate", sort: "asc" }],
            },
          }}
          rows={leaderboardData?.getWeeklyLeaderBoard.data || []}
          pageSize={5}
          pagination
        />
      </Box>
    </>
  );
}
