import React, { createContext, useState, ReactNode, useContext } from "react";

// Define the type for the context state and update function
interface PartnerContextType {
  partnerId: number | null;
  setPartnerId: (id: number | null) => void;
}

// Create the context with a default value of undefined
export const PartnerContext = createContext<PartnerContextType | undefined>(
  undefined
);

// Create a provider component
interface PartnerProviderProps {
  children: ReactNode;
}

export const PartnerProvider: React.FC<PartnerProviderProps> = ({
  children,
}) => {
  const [partnerId, setPartnerId] = useState<number | null>(null);

  return (
    <PartnerContext.Provider value={{ partnerId, setPartnerId }}>
      {children}
    </PartnerContext.Provider>
  );
};

export const usePartner = () => useContext(PartnerContext);
