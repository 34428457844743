import TouchdownCreation from "@src/components/f2pPartner/Contest/TouchdownCreation";
import { SignedInLayout } from "@src/components/templates/Layouts";
import { usePartner } from "@src/context/PartnerContext";
import { useAuth } from "@src/hooks/useAuth";

export function ContestCreate() {
  const { data: authInfo } = useAuth();
  const { partnerId: partner }: any = usePartner();

  const partnerId = authInfo?.partnerId || partner;
  return (
    <SignedInLayout title="Partner - Create Touchdown" showF2pPartners disable>
      <TouchdownCreation partnerId={partnerId} />
    </SignedInLayout>
  );
}
