import DateRangePicker, { DateRange } from "@mui/lab/DateRangePicker";
import { RangeInput } from "@mui/lab/DateRangePicker/RangeTypes";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import * as React from "react";

interface RangePickerProps {
  value: RangeInput<dayjs.Dayjs | null>;
  onChange: (date: DateRange<dayjs.Dayjs>) => void;
}

export default function RangePicker(props: RangePickerProps) {
  const { value, onChange } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateRangePicker
        startText="Start Date"
        endText="End Date"
        value={value || [null, null]}
        onChange={onChange}
        renderInput={(startProps, endProps) => (
          <React.Fragment>
            <TextField
              {...startProps}
              sx={{
                input: {
                  padding: "9px 12px",
                },
              }}
            />
            <Box sx={{ mx: 2 }}> to </Box>
            <TextField
              {...endProps}
              sx={{
                input: {
                  padding: "9px 12px",
                },
              }}
            />
          </React.Fragment>
        )}
      />
    </LocalizationProvider>
  );
}
