import { FC, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { AppBar } from "@molecules/AppBar/AppBar";
import { DrawerHeader } from "@organisms/Navigation/DrawerHeader";
import { Navigation } from "@organisms/Navigation";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

export const SignedInLayout: FC<{
  breadcrumbs?: Array<string>;
  title: string;
  showPartners?: boolean;
  showF2pPartners?: boolean;
  disable?: boolean;
}> = ({
  breadcrumbs,
  title,
  children,
  showPartners,
  showF2pPartners,
  disable = false,
}) => {
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex", minHeight: "92vh" }}>
      <AppBar
        open={open}
        onOpen={handleDrawerOpen}
        breadcrumbs={breadcrumbs}
        title={title}
        showPartners={showPartners}
        showF2pPartners={showF2pPartners}
        disable={disable}
      />
      <Navigation open={open} onClose={handleDrawerClose} />
      <Main
        open={open}
        sx={{ backgroundColor: "#fbfbfb", overflowX: "hidden" }}
      >
        <DrawerHeader />
        {children}
      </Main>
    </Box>
  );
};
