import { Button, Stack } from "@mui/material";

interface ControlsProps {
  setShowAuditTrail: (value: boolean) => void;
}

export function Controls({ setShowAuditTrail }: ControlsProps) {
  return (
    <Stack spacing={1}>
      <Button variant="contained" onClick={() => setShowAuditTrail(true)}>
        Audit Trail
      </Button>
    </Stack>
  );
}
