import AddCardIcon from "@mui/icons-material/AddCard";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AlignVerticalBottomSharpIcon from "@mui/icons-material/AlignVerticalBottomSharp";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import BarChartIcon from "@mui/icons-material/BarChart";
import CampaignIcon from "@mui/icons-material/Campaign";
import ClosedCaptionIcon from "@mui/icons-material/ClosedCaption";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EventIcon from "@mui/icons-material/Event";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MenuIcon from "@mui/icons-material/Menu";
import PaymentsIcon from "@mui/icons-material/Payments";
import PeopleIcon from "@mui/icons-material/People";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import PointOfSaleSharpIcon from "@mui/icons-material/PointOfSaleSharp";
import TableChartIcon from "@mui/icons-material/TableChart";
import TuneIcon from "@mui/icons-material/Tune";
import {
  Box,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  ListItemButton as MuiListItemButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactNode, useEffect, useState } from "react";
import { NavLink, NavLinkProps, useLocation } from "react-router-dom";

import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GamepadRoundedIcon from "@mui/icons-material/GamepadRounded";
import RestoreIcon from "@mui/icons-material/Restore";
import SettingsIcon from "@mui/icons-material/Settings";
import { ROUTES } from "@src/Routes";
import { PermissionId } from "@src/constants/permissions";
import { useHasPermissions } from "@src/hooks/useHasPermission";
import { useIsSuperuser } from "@src/hooks/useIsSuperuser";
import FootballIcon from "@src/icons/football.png";
import { ReactComponent as TrophyCardIcon } from "@src/icons/trophy-card.svg";
import { ReactComponent as GolfIcon } from "@src/icons/golf-ball.svg";
import { ReactComponent as BattlegroundsIcon } from "@src/icons/battlegrounds.svg";
import { ReactComponent as PartnerIcon } from "@src/icons/partner.svg";
import { ReactComponent as F2PIcon } from "@src/icons/free2play.svg";
import { ReactComponent as CommonContestIcon } from "@src/icons/common-contest.svg";
import { ReactComponent as CommonSportsIcon } from "@src/icons/sports.svg";
import TopPropFullLogo from "@src/images/TopProp_Full_Logo.png";
import { DrawerHeader } from "./DrawerHeader";
import { DRAWER_WIDTH } from "./constants";
import { Group } from "@mui/icons-material";
import { useAuth } from "@src/hooks/useAuth";

export function Navigation({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const [isPgaOpen, setPgaOpen] = useState(false);
  const [isNflOpen, setNflOpen] = useState(false);
  const [isNflReportsOpen, setNflReportsOpen] = useState(false);
  const [isPgaReportsOpen, setPgaLogsOpen] = useState(false);
  const [isGeneralOpen, setGeneralOpen] = useState(false);
  const [isAdminOpen, setAdminOpen] = useState(false);
  const [isTouchDownOpen, setTouchDownOpen] = useState(false);
  const [isPartnerOpen, setPartnerOpen] = useState(false);
  const [isF2PartnerOpen, setF2PartnerOpen] = useState(false);
  const [isBattlegrounds, setBattlegrounds] = useState(false);
  const [isCommonContests, setCommonContests] = useState(false);
  const { data: authInfo } = useAuth();

  const showGolfMatchups = useHasPermissions(
    PermissionId.GolfContestSuggestionsManage
  );
  const showGolfTournaments = useHasPermissions(
    PermissionId.GolfTournamentView
  );
  const showGolfFeatures = useHasPermissions(PermissionId.GolfFeaturesView);
  const showBonusPayout = useHasPermissions(PermissionId.CommonBonusPayout);
  const showCrons = useHasPermissions(PermissionId.GolfTasks);
  const showGolfReports = useHasPermissions(PermissionId.GolfReports);
  const showUserSignups = useHasPermissions(PermissionId.CommonUserSignups);
  const showDeletionRequests = useHasPermissions(
    PermissionId.CommonDeletionRequestsManage
  );
  const showCoupons = useHasPermissions(PermissionId.CommonCoupons);
  const showWithdrawRequests = useHasPermissions(
    PermissionId.CommonWithdrawRequests
  );
  const showContestSuggestions = useHasPermissions(
    PermissionId.CommonContestSuggestions
  );
  const showSupportTickets = useHasPermissions(
    PermissionId.CommonSupportTickets
  );
  const showNflMatchups = useHasPermissions(PermissionId.NflMatchups);
  const showNflReports = useHasPermissions(PermissionId.NflReports);
  const showFeaturedContests = useHasPermissions(
    PermissionId.CommonFeaturedContestsView
  );
  const showBroadcastNotifications = useHasPermissions(
    PermissionId.CommonBroadcastNotifications
  );

  const showLogs = useIsSuperuser();
  const isSuperuser = useIsSuperuser();
  const isPartnerAdmin = authInfo && authInfo?.role === "ADMIN";

  const location = useLocation();

  const doesPathMatchRoute = (pathname: string, route: string): boolean => {
    const dynamicSegmentRegex = /:\w+/g; // Regex to identify dynamic segments like ':id'
    const routeWithoutDynamicSegments = route.replace(
      dynamicSegmentRegex,
      "[^/]+"
    ); // Replace dynamic segments with regex
    const routeRegex = new RegExp(`^${routeWithoutDynamicSegments}$`);
    return routeRegex.test(pathname);
  };

  useEffect(() => {
    // Check the current route and set state based on that

    // Loop over Touchdown routes
    Object.values(ROUTES.Touchdown).forEach((route) => {
      if (doesPathMatchRoute(location.pathname, route)) {
        setTouchDownOpen(true);
      }
    });

    // Loop over Partner routes
    Object.values(ROUTES.Partner).forEach((route) => {
      if (doesPathMatchRoute(location.pathname, route)) {
        setPartnerOpen(true);
      }
    });

    // Loop over F2P Partner routes
    Object.values(ROUTES.F2P).forEach((route) => {
      if (doesPathMatchRoute(location.pathname, route)) {
        setF2PartnerOpen(true);
      }
    });

    // Loop over Common contest routes
    Object.values(ROUTES.Common).forEach((route) => {
      if (doesPathMatchRoute(location.pathname, route)) {
        setCommonContests(true);
      }
    });

    // Loop over Battlegrounds routes
    Object.values({
      ...ROUTES.PGA,
      ...ROUTES.General,
      ...ROUTES.Admin,
      ...ROUTES.Nfl,
      ...ROUTES.PGAReports,
      ...ROUTES.NFLReports,
    }).forEach((route) => {
      if (doesPathMatchRoute(location.pathname, route)) {
        setBattlegrounds(true);
      }
    });

    // For Nested Routes
    // Loop over Partner routes
    Object.values(ROUTES.Nfl).forEach((route) => {
      if (doesPathMatchRoute(location.pathname, route)) {
        setNflOpen(true);
      }
    });

    Object.values(ROUTES.NFLReports).forEach((route) => {
      if (doesPathMatchRoute(location.pathname, route)) {
        setNflReportsOpen(true);
      }
    });

    Object.values(ROUTES.Admin).forEach((route) => {
      if (doesPathMatchRoute(location.pathname, route)) {
        setAdminOpen(true);
      }
    });

    Object.values(ROUTES.General).forEach((route) => {
      if (doesPathMatchRoute(location.pathname, route)) {
        setGeneralOpen(true);
      }
    });
  }, [location]);
  return (
    <Drawer
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: DRAWER_WIDTH,
          boxSizing: "border-box",
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader>
        <IconButton onClick={onClose}>
          <MenuIcon />
        </IconButton>
        <Box component="img" src={TopPropFullLogo} height={36} />
      </DrawerHeader>
      <Divider />
      <List sx={{ p: 0 }} component="nav">
        {isPartnerAdmin ? (
          <>
            <ListItemButton component={NavLink} to={ROUTES.Dashboard}>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItemButton>
            <ListItemButton component={NavLink} to={ROUTES.TouchdownScreen}>
              <ListItemIcon>
                <EmojiEventsIcon />
              </ListItemIcon>
              <ListItemText primary="Touchdown" />
            </ListItemButton>
            <ListItemButton component={NavLink} to={ROUTES.Contest}>
              <ListItemIcon>
                <TrophyCardIcon fill="grey" />
              </ListItemIcon>
              <ListItemText primary="Contest" />
            </ListItemButton>
            <ListItemButton component={NavLink} to={ROUTES.Configure}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Configure" />
            </ListItemButton>
            <ListItemButton component={NavLink} to={ROUTES.Users}>
              <ListItemIcon>
                <Group />
              </ListItemIcon>
              <ListItemText primary="Admin" />
            </ListItemButton>
          </>
        ) : (
          <>
            <ListItemButton onClick={() => setTouchDownOpen(!isTouchDownOpen)}>
              <ListItemIcon>
                <Box component={GolfIcon} height={24} width={24} />
              </ListItemIcon>
              <ListItemText primary="Touchdown" />
              {isTouchDownOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={isTouchDownOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  component={NavLink}
                  sx={{ pl: 4 }}
                  to={ROUTES.Touchdown.Dashboard}
                >
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </ListItemButton>
                <ListItemButton
                  component={NavLink}
                  sx={{ pl: 4 }}
                  to={ROUTES.Touchdown.Contest}
                >
                  <ListItemIcon>
                    <EmojiEventsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Contest" />
                </ListItemButton>
                <ListItemButton
                  component={NavLink}
                  sx={{ pl: 4 }}
                  to={ROUTES.Touchdown.ContestHistory}
                >
                  <ListItemIcon>
                    <RestoreIcon />
                  </ListItemIcon>
                  <ListItemText primary="Contest History" />
                </ListItemButton>
                {false && (
                  <ListItemButton
                    component={NavLink}
                    sx={{ pl: 4 }}
                    to={ROUTES.Touchdown.Analytics}
                  >
                    <ListItemIcon>
                      <BarChartIcon />
                    </ListItemIcon>
                    <ListItemText primary="Analytics" />
                  </ListItemButton>
                )}

                <ListItemButton
                  component={NavLink}
                  sx={{ pl: 4 }}
                  to={ROUTES.Touchdown.ContestHub}
                >
                  <ListItemIcon>
                    <GamepadRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Contest hub" />
                </ListItemButton>
                <ListItemButton
                  component={NavLink}
                  sx={{ pl: 4 }}
                  to={ROUTES.Touchdown.AllContests}
                >
                  <ListItemIcon>
                    <TrophyCardIcon fill="grey" />
                  </ListItemIcon>
                  <ListItemText primary="All Contest " />
                </ListItemButton>
                <ListItemButton
                  component={NavLink}
                  sx={{ pl: 4 }}
                  to={ROUTES.Touchdown.Configure}
                >
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Configure" />
                </ListItemButton>
              </List>
            </Collapse>
            <Divider />
            <ListItemButton onClick={() => setBattlegrounds(!isBattlegrounds)}>
              <ListItemIcon>
                <Box component={BattlegroundsIcon} height={24} width={24} />
              </ListItemIcon>
              <ListItemText primary="Battlegrounds" />
              {isBattlegrounds ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse
              in={isBattlegrounds}
              timeout="auto"
              unmountOnExit
              sx={{ paddingLeft: 2 }}
            >
              <ListItemButton onClick={() => setNflOpen(!isNflOpen)}>
                <ListItemIcon>
                  <Box
                    component="img"
                    src={FootballIcon}
                    height={24}
                    width={24}
                  />
                </ListItemIcon>
                <ListItemText primary="NFL" />
                {isNflOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={isNflOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {showNflMatchups && (
                    <ListItemButton
                      component={NavLink}
                      sx={{ pl: 4 }}
                      to={ROUTES.Nfl.Matchups}
                    >
                      <ListItemIcon>
                        <PeopleIcon />
                      </ListItemIcon>
                      <ListItemText primary="Matchups" />
                    </ListItemButton>
                  )}
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <TuneIcon />
                    </ListItemIcon>
                    <ListItemText primary="Feature Flags" />
                  </ListItemButton>
                </List>
              </Collapse>
              <Divider />
              <ListItemButton
                onClick={() => setNflReportsOpen(!isNflReportsOpen)}
              >
                <ListItemIcon>
                  <BarChartIcon />
                </ListItemIcon>
                <ListItemText primary="NFL History" />
                {isNflReportsOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={isNflReportsOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {showNflReports && (
                    <ListItemButton
                      component={NavLink}
                      sx={{ pl: 4 }}
                      to={ROUTES.NFLReports.Contests}
                    >
                      <ListItemIcon>
                        <TableChartIcon />
                      </ListItemIcon>
                      <ListItemText primary="Contests" />
                    </ListItemButton>
                  )}
                </List>
              </Collapse>
              <Divider />
              {isSuperuser && (
                <>
                  <ListItemButton onClick={() => setAdminOpen(!isAdminOpen)}>
                    <ListItemIcon>
                      <AdminPanelSettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Admin" />
                    {isAdminOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={isAdminOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton
                        component={NavLink}
                        sx={{ pl: 4 }}
                        to={ROUTES.Admin.Roles}
                      >
                        <ListItemIcon>
                          <ManageAccountsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Roles" />
                      </ListItemButton>
                      <ListItemButton
                        component={NavLink}
                        sx={{ pl: 4 }}
                        to={ROUTES.Admin.Users}
                      >
                        <ListItemIcon>
                          <PeopleAltIcon />
                        </ListItemIcon>
                        <ListItemText primary="Users" />
                      </ListItemButton>
                    </List>
                  </Collapse>
                  <Divider />
                </>
              )}
              <ListItemButton onClick={() => setGeneralOpen(!isGeneralOpen)}>
                <ListItemIcon>
                  <BarChartIcon />
                </ListItemIcon>
                <ListItemText primary="General" />
                {isGeneralOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={isGeneralOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {showFeaturedContests && (
                    <ListItemButton
                      component={NavLink}
                      sx={{ pl: 4 }}
                      to={ROUTES.General.FeaturedContests}
                    >
                      <ListItemIcon>
                        <PeopleIcon />
                      </ListItemIcon>
                      <ListItemText primary="Featured Contests" />
                    </ListItemButton>
                  )}
                  {showUserSignups && (
                    <ListItemButton
                      component={NavLink}
                      sx={{ pl: 4 }}
                      to={ROUTES.General.UserSignup}
                    >
                      <ListItemIcon>
                        <DashboardIcon />
                      </ListItemIcon>
                      <ListItemText primary="User Sign Up" />
                    </ListItemButton>
                  )}
                  {showDeletionRequests && (
                    <ListItemButton
                      component={NavLink}
                      sx={{ pl: 4 }}
                      to={ROUTES.General.DeletionRequests}
                    >
                      <ListItemIcon>
                        <PersonRemoveIcon />
                      </ListItemIcon>
                      <ListItemText primary="Deletion Requests" />
                    </ListItemButton>
                  )}
                  {showCoupons && (
                    <ListItemButton
                      component={NavLink}
                      sx={{ pl: 4 }}
                      to={ROUTES.General.Coupons}
                    >
                      <ListItemIcon>
                        <ClosedCaptionIcon />
                      </ListItemIcon>
                      <ListItemText primary="Coupons" />
                    </ListItemButton>
                  )}
                  {showBonusPayout && (
                    <ListItemButton
                      component={NavLink}
                      sx={{ pl: 4 }}
                      to={ROUTES.General.BonusPayout}
                    >
                      <ListItemIcon>
                        <PaymentsIcon />
                      </ListItemIcon>
                      <ListItemText primary="Bonus Payout" />
                    </ListItemButton>
                  )}
                  {showWithdrawRequests && (
                    <ListItemButton
                      component={NavLink}
                      sx={{ pl: 4 }}
                      to={ROUTES.General.WithdrawRequests}
                    >
                      <ListItemIcon>
                        <AddCardIcon />
                      </ListItemIcon>
                      <ListItemText primary="Withdraws" />
                    </ListItemButton>
                  )}
                  {showWithdrawRequests && (
                    <ListItemButton
                      component={NavLink}
                      sx={{ pl: 4 }}
                      to={ROUTES.General.Topups}
                    >
                      <ListItemIcon>
                        <PointOfSaleSharpIcon />
                      </ListItemIcon>
                      <ListItemText primary="Topups" />
                    </ListItemButton>
                  )}
                  {showBroadcastNotifications && (
                    <ListItemButton
                      component={NavLink}
                      sx={{ pl: 4 }}
                      to={ROUTES.General.BroadcastNotifications}
                    >
                      <ListItemIcon>
                        <CampaignIcon />
                      </ListItemIcon>
                      <ListItemText primary="Broadcast Notification" />
                    </ListItemButton>
                  )}
                  {showSupportTickets && (
                    <ListItemButton
                      component={NavLink}
                      sx={{ pl: 4 }}
                      to={ROUTES.General.SupportTickets}
                    >
                      <ListItemIcon>
                        <ContactSupportIcon />
                      </ListItemIcon>
                      <ListItemText primary="Support Tickets" />
                    </ListItemButton>
                  )}
                </List>
              </Collapse>
            </Collapse>
            <Divider />
            <ListItemButton
              onClick={() => setCommonContests(!isCommonContests)}
            >
              <ListItemIcon>
                <Box component={CommonContestIcon} height={24} width={24} />
              </ListItemIcon>
              <ListItemText primary="Common contest" />
              {isCommonContests ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={isCommonContests} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  component={NavLink}
                  sx={{ pl: 4 }}
                  to={ROUTES.Common.Sports}
                >
                  <ListItemIcon>
                    <CommonSportsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Sport" />
                </ListItemButton>
                <ListItemButton
                  component={NavLink}
                  sx={{ pl: 4 }}
                  to={ROUTES.Common.Contests}
                >
                  <ListItemIcon>
                    <TrophyCardIcon fill="gray" />
                  </ListItemIcon>
                  <ListItemText primary="Contests" />
                </ListItemButton>
              </List>
            </Collapse>
            <Divider />
            <ListItemButton onClick={() => setPartnerOpen(!isPartnerOpen)}>
              <ListItemIcon>
                <Box component={PartnerIcon} height={24} width={24} />
              </ListItemIcon>
              <ListItemText primary="Partner" />
              {isPartnerOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={isPartnerOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  component={NavLink}
                  sx={{ pl: 4 }}
                  to={ROUTES.Partner.Dashboard}
                >
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </ListItemButton>
                <ListItemButton
                  component={NavLink}
                  sx={{ pl: 4 }}
                  to={ROUTES.Partner.partners}
                >
                  <ListItemIcon>
                    <FormatListBulletedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Partners list" />
                </ListItemButton>
                <ListItemButton
                  component={NavLink}
                  sx={{ pl: 4 }}
                  to={ROUTES.Partner.Touchdown}
                >
                  <ListItemIcon>
                    <EmojiEventsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Touchdown" />
                </ListItemButton>
                <ListItemButton
                  component={NavLink}
                  sx={{ pl: 4 }}
                  to={ROUTES.Partner.Contest}
                >
                  <ListItemIcon>
                    <TrophyCardIcon fill="grey" />
                  </ListItemIcon>
                  <ListItemText primary="Contest" />
                </ListItemButton>
                <ListItemButton
                  component={NavLink}
                  sx={{ pl: 4 }}
                  to={ROUTES.Partner.TouchdownHistory}
                >
                  <ListItemIcon>
                    <RestoreIcon />
                  </ListItemIcon>
                  <ListItemText primary="Touchdown history" />
                </ListItemButton>
                <ListItemButton
                  component={NavLink}
                  sx={{ pl: 4 }}
                  to={ROUTES.Partner.Configure}
                >
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Configure" />
                </ListItemButton>
              </List>
            </Collapse>
            <Divider />
            <ListItemButton onClick={() => setF2PartnerOpen(!isF2PartnerOpen)}>
              <ListItemIcon>
                <Box component={F2PIcon} height={24} width={24} />
              </ListItemIcon>
              <ListItemText primary="Free to play" />
              {isF2PartnerOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={isF2PartnerOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  component={NavLink}
                  sx={{ pl: 4 }}
                  to={ROUTES.F2P.partners}
                >
                  <ListItemIcon>
                    <FormatListBulletedIcon />
                  </ListItemIcon>
                  <ListItemText primary="f2p partner list" />
                </ListItemButton>
                <ListItemButton
                  component={NavLink}
                  sx={{ pl: 4 }}
                  to={ROUTES.F2P.Dashboard}
                >
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </ListItemButton>
                <ListItemButton
                  component={NavLink}
                  sx={{ pl: 4 }}
                  to={ROUTES.F2P.Touchdown}
                >
                  <ListItemIcon>
                    <EmojiEventsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Touchdown" />
                </ListItemButton>
                <ListItemButton
                  component={NavLink}
                  sx={{ pl: 4 }}
                  to={ROUTES.F2P.Contest}
                >
                  <ListItemIcon>
                    <TrophyCardIcon fill="grey" />
                  </ListItemIcon>
                  <ListItemText primary="Contest" />
                </ListItemButton>
                <ListItemButton
                  component={NavLink}
                  sx={{ pl: 4 }}
                  to={ROUTES.F2P.Configure}
                >
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Configure" />
                </ListItemButton>
                <ListItemButton
                  component={NavLink}
                  sx={{ pl: 4 }}
                  to={ROUTES.F2P.User}
                >
                  <ListItemIcon>
                    <PeopleAltIcon />
                  </ListItemIcon>
                  <ListItemText primary="Users" />
                </ListItemButton>
              </List>
            </Collapse>
            <Divider />
            {false && (
              <>
                <ListItemButton onClick={() => setPgaOpen(!isPgaOpen)}>
                  <ListItemIcon>
                    <Box component={GolfIcon} height={24} width={24} />
                  </ListItemIcon>
                  <ListItemText primary="PGA" />
                  {isPgaOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={isPgaOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {showGolfMatchups && (
                      <ListItemButton
                        component={NavLink}
                        sx={{ pl: 4 }}
                        to={ROUTES.PGA.Matchups}
                      >
                        <ListItemIcon>
                          <PeopleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Matchups" />
                      </ListItemButton>
                    )}
                    {showGolfTournaments && (
                      <ListItemButton
                        component={NavLink}
                        sx={{ pl: 4 }}
                        to={ROUTES.PGA.Tournaments}
                      >
                        <ListItemIcon>
                          <EventIcon />
                        </ListItemIcon>
                        <ListItemText primary="Tournaments" />
                      </ListItemButton>
                    )}
                    {showGolfFeatures && (
                      <ListItemButton
                        component={NavLink}
                        sx={{ pl: 4 }}
                        to={ROUTES.PGA.FeatureFlags}
                      >
                        <ListItemIcon>
                          <TuneIcon />
                        </ListItemIcon>
                        <ListItemText primary="Feature Flags" />
                      </ListItemButton>
                    )}
                    {showCrons && (
                      <ListItemButton
                        component={NavLink}
                        sx={{ pl: 4 }}
                        to={ROUTES.PGA.Crons}
                      >
                        <ListItemIcon>
                          <EventRepeatIcon />
                        </ListItemIcon>
                        <ListItemText primary="Crons" />
                      </ListItemButton>
                    )}
                    {showBonusPayout && (
                      <ListItemButton
                        component={NavLink}
                        sx={{ pl: 4 }}
                        to={ROUTES.PGA.BonusPayout}
                      >
                        <ListItemIcon>
                          <PaymentsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Bonus Payout" />
                      </ListItemButton>
                    )}
                    {showContestSuggestions && (
                      <ListItemButton
                        component={NavLink}
                        sx={{ pl: 4 }}
                        to={ROUTES.PGA.ContestSuggestions}
                      >
                        <ListItemIcon>
                          <PersonAddAlt1Icon />
                        </ListItemIcon>
                        <ListItemText primary="Contest Suggestions" />
                      </ListItemButton>
                    )}
                  </List>
                </Collapse>{" "}
              </>
            )}

            {/* <Divider /> */}
            {false && showGolfReports && (
              <>
                <ListItemButton
                  onClick={() => setPgaLogsOpen(!isPgaReportsOpen)}
                >
                  <ListItemIcon>
                    <BarChartIcon />
                  </ListItemIcon>
                  <ListItemText primary="PGA Reports" />
                  {isPgaReportsOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={isPgaReportsOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton
                      component={NavLink}
                      sx={{ pl: 4 }}
                      to={ROUTES.PGAReports.Contests}
                    >
                      <ListItemIcon>
                        <TableChartIcon />
                      </ListItemIcon>
                      <ListItemText primary="Contests" />
                    </ListItemButton>
                    <ListItemButton
                      component={NavLink}
                      sx={{ pl: 4 }}
                      to={ROUTES.PGAReports.Daily}
                    >
                      <ListItemIcon>
                        <AlignVerticalBottomSharpIcon />
                      </ListItemIcon>
                      <ListItemText primary="Daily Metrics" />
                    </ListItemButton>
                  </List>
                </Collapse>

                <Divider />
              </>
            )}

            {/* <Divider /> */}

            {/* {isSuperuser && (
              <ListItemButton>
                <ListItemIcon>
                  <ArticleOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Logs" />
              </ListItemButton>
            )} */}
          </>
        )}
      </List>
    </Drawer>
  );
}

const ListItemButton = styled(MuiListItemButton)<
  ListItemButtonProps & Partial<NavLinkProps> & { component?: ReactNode }
>(({ theme }) => ({
  "&.active": {
    background: theme.palette.primary.light,
  },
}));
