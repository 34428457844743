import { Box, FormControl, MenuItem, Select, Stack } from "@mui/material";
import { usePartner } from "@src/context/PartnerContext";
import {
  useGetAllF2PPartnerQuery,
  useGetAllPartnerQuery,
} from "@src/graphql/graphql.generated";
import { useEffect, useState } from "react";
function PartnerDropdown({
  showF2pPartners,
  showPartners,
}: {
  showF2pPartners?: boolean;
  showPartners?: boolean;
}) {
  const { setPartnerId, partnerId }: any = usePartner();
  const { data: allPartners } = useGetAllPartnerQuery();
  const { data: allF2pPartners } = useGetAllF2PPartnerQuery();
  const [partners, setPartners] = useState<any[]>([]);
  const [selectedValue, setSelectedValue] = useState<any>();
  const handleChange = (event: any) => {
    setSelectedValue(event.target.value);
    setPartnerId(event.target.value?.id);
  };

  useEffect(() => {
    if (allPartners?.getAllPartner?.length) {
      if (showPartners) {
        setPartners(allPartners.getAllPartner);
        if (partnerId) {
          setSelectedValue(
            allPartners?.getAllPartner?.find((temp) => temp.id === partnerId)
          );
        } else {
          setSelectedValue(allPartners?.getAllPartner?.[0]);
          setPartnerId(allPartners?.getAllPartner?.[0]?.id);
        }
      } else {
        setPartners(allF2pPartners?.getAllF2PPartner || []);
        if (partnerId) {
          setSelectedValue(
            allF2pPartners?.getAllF2PPartner?.find(
              (temp) => temp.id === partnerId
            )
          );
        } else {
          setSelectedValue(allF2pPartners?.getAllF2PPartner?.[0]);
          setPartnerId(allF2pPartners?.getAllF2PPartner?.[0]?.id);
        }
      }
    }
  }, [allPartners?.getAllPartner, allF2pPartners?.getAllF2PPartner]);

  return (
    <Stack alignItems="center" direction="row" spacing={1}>
      <Box sx={{ minWidth: 200, border: "none" }}>
        <FormControl>
          <Select
            value={selectedValue}
            onChange={handleChange}
            displayEmpty
            autoWidth
            renderValue={() => {
              return (
                <Stack direction="row" alignItems="center">
                  {showPartners ? (
                    `Viewing as ${selectedValue?.name}`
                  ) : (
                    <>
                      <img
                        src={selectedValue?.logo}
                        style={{
                          width: "42px",
                          height: "42px",
                          borderRadius: "21px",
                          objectFit: "contain",
                          marginRight: "10px",
                        }}
                      />
                      <text>{selectedValue?.name}</text>
                    </>
                  )}
                </Stack>
              );
            }}
            sx={{
              color: "white",
              "& .MuiSelect-icon": {
                color: "white",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              borderRadius: 0,
            }}
          >
            {partners?.map((partner) => (
              <MenuItem key={partner.id} value={partner}>
                {partner?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Stack>
  );
}

export default PartnerDropdown;
