import AddIcon from "@mui/icons-material/Add";
import {
  useCreateF2PPartnerMutation,
  useUpdateF2PPartnerMutation,
} from "@src/graphql/graphql.generated";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/FileCopy";
import ColorPicker from "@molecules/ColorPicker/ColorPicker";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import { useAuth } from "@src/hooks/useAuth";
import { getAuthInfo } from "@src/services/auth";
import { axiosF2pPartnerInstance } from "@src/services/axiosInstances";
import { parseGQLError } from "@src/utils/parse-error";
import { bool } from "yup";

interface CreateEditDialogProps {
  isCreate: boolean;
  isEdit: boolean;
  target: any | null;
  onClose: (refresh?: boolean) => void;
}

type AdminData = {
  name: string;
  email: string;
  contact: string;
  logo: string;
  identifier: string;
  description: string;
  primaryColor: string;
  secondaryColor: string;
};

const initialAdminData = {
  name: "",
  email: "",
  contact: "",
  logo: "",
  identifier: "",
  description: "",
  primaryColor: "",
  secondaryColor: "",
};

const StyledLabel = styled("label")(({ theme }) => ({
  color: "#212121",
  fontSize: "14px",
}));
const StyledRequiredLabel = styled("label")(({ theme }) => ({
  color: "#EB1745",
  fontSize: "14px",
}));
const StyledInput = styled(TextField)(({ theme: any }) => ({
  marginTop: "10px",
  "& input": {
    padding: "9px 12px 9px 12px",

    "&:focus": {
      outline: `none`,
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: `#E2D3BB !important`,
    "&:focus": {
      outline: `none`,
    },
  },
  "& .MuiOutlinedInput-root.Mui-error": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `#d32f2f !important`,
    },
  },
  "& .MuiFormHelperText-root.Mui-error": {
    fontSize: "12px",
    margin: "3px",
  },
}));
const CopyIconButton = styled(IconButton)(({ theme }) => ({
  marginLeft: "10px",
}));
export function CreateEditDialog({
  isCreate,
  isEdit,
  target,
  onClose,
}: CreateEditDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: create, isLoading: createLoading } =
    useCreateF2PPartnerMutation();
  const { mutate: update, isLoading: updateLoading } =
    useUpdateF2PPartnerMutation();
  const { data: authInfo } = useAuth();
  const [isValid, setValid] = useState(false);
  const [logoUrl, setLogoUrl] = useState<string>("");
  const [secretKey, setSecretKey] = useState("");
  const [isForm, setForm] = useState(true);
  const [enableUserAuth, setEnableUserAuth] = useState<boolean>(true);
  const [adminData, setAdminData] = useState<AdminData>(initialAdminData);

  useEffect(() => {
    if (target) {
      setAdminData({
        name: target?.name!,
        email: target?.email,
        contact: target?.contact,
        logo: target?.logo,
        identifier: target?.identifier,
        description: target?.description,
        primaryColor: target?.primaryColor,
        secondaryColor: target?.secondaryColor,
      });
      setEnableUserAuth(target?.enableUserAuth);
      setLogoUrl(target?.logo);
    }
  }, [target]);

  useEffect(() => {
    setValid(validateAdminDetails(adminData));
  }, [adminData]);

  const handleClose = (refresh = false) => {
    setAdminData(initialAdminData);
    onClose(refresh);
    setLogoUrl("");
    setForm(true);
  };

  const validateAdminDetails = (details: AdminData): boolean => {
    return Object.values(details).every((value) => value?.trim() !== "");
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    enqueueSnackbar(`Copied to clipboard!`, {
      variant: "success",
    });
  };

  const handleEdit = () => {
    update(
      {
        data: {
          enableUserAuth: enableUserAuth,
          id: target?.id,
          name: adminData?.name,
          email: adminData?.email,
          contact: adminData?.contact,
          creatorId: Number(authInfo?.userId),
          identifier: adminData?.identifier,
          logo: adminData?.logo,
          description: adminData?.description,
          primaryColor: adminData?.primaryColor,
          secondaryColor: adminData?.secondaryColor,
        },
      },
      {
        onSuccess: () => {
          enqueueSnackbar(`F2P Partner has been updated successfully!`, {
            variant: "success",
          });
          setAdminData(initialAdminData);
          setLogoUrl("");
          onClose(true);
        },
        onError: (error: any) => {
          enqueueSnackbar(parseGQLError(error, `Unable to update`), {
            variant: "error",
          });
        },
      }
    );
  };

  const handleCreate = () => {
    create(
      {
        data: {
          enableUserAuth: enableUserAuth,
          name: adminData?.name,
          email: adminData?.email,
          contact: adminData?.contact,
          creatorId: Number(authInfo?.userId),
          identifier: adminData?.identifier,
          logo: logoUrl,
          description: adminData?.description,
          primaryColor: adminData?.primaryColor,
          secondaryColor: adminData?.secondaryColor,
        },
      },
      {
        onSuccess: (res) => {
          enqueueSnackbar(`F2P Partner has been created successfully!`, {
            variant: "success",
          });
          if (!enableUserAuth) {
            setSecretKey("U2FsdGVkX19IRPIW2BUw3ef9VTTb2BIaSTh7AfqTDFjwafWQVP");
            setForm(false);
          } else {
            setAdminData(initialAdminData);
            setLogoUrl("");
            onClose(true);
          }
        },
        onError: (error: any) => {
          enqueueSnackbar(parseGQLError(error, `Unable to create`), {
            variant: "error",
          });
        },
      }
    );
  };

  const uploadPlayerImage = async (file: any) => {
    const body = new FormData();
    body.append("file", file);

    const { accessToken } = await getAuthInfo({ retry: true });
    try {
      const response = await axiosF2pPartnerInstance.post(
        "/upload-image",
        body,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return response?.data?.data?.imageUrl;
    } catch (error) {
      console.error("Error uploading player image:", error);
    }
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        uploadPlayerImage(file).then((response) => {
          setLogoUrl(response);
          setAdminData({
            ...adminData,
            logo: response,
          });
        });
      };
      reader.onerror = (e) => {
        console.error("FileReader error:", e);
      };
      reader.readAsDataURL(file);
    } else {
      console.error("No file selected.");
    }
  };
  const updateColor = (field: string, color: string) => {
    if (field === "primaryColor") {
      setAdminData({
        ...adminData,
        primaryColor: color,
      });
    } else {
      setAdminData({
        ...adminData,
        secondaryColor: color,
      });
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={isCreate || isEdit}>
      {isForm ? (
        <>
          <DialogTitle>
            {isCreate ? "Add new F2P" : "Edit F2P"} Partner
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => handleClose(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <Box component="main">
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <FormControl variant="standard" fullWidth>
                    <StyledLabel htmlFor="name">
                      Company Name
                      <StyledRequiredLabel htmlFor="name">
                        *
                      </StyledRequiredLabel>
                    </StyledLabel>
                    <StyledInput
                      id="name"
                      onChange={(e) => {
                        setAdminData({
                          ...adminData,
                          name: e.target.value,
                        });
                      }}
                      error={false}
                      value={adminData?.name}
                      placeholder="Enter Company Name"
                      aria-describedby="Company Name"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="standard" fullWidth>
                    <StyledLabel htmlFor="email">
                      Email ID
                      <StyledRequiredLabel htmlFor="email">
                        *
                      </StyledRequiredLabel>
                    </StyledLabel>
                    <StyledInput
                      error={false}
                      disabled={isEdit}
                      onChange={(e) => {
                        setAdminData({
                          ...adminData,
                          email: e.target.value,
                        });
                      }}
                      value={adminData?.email}
                      id="email"
                      placeholder="Enter Registered Email ID"
                      aria-describedby="Registered Email"
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={6} sx={{ marginTop: "-30px" }}>
                <Grid item xs={6}>
                  <FormControl variant="standard" fullWidth>
                    <StyledLabel htmlFor="contact">
                      Phone Number
                      <StyledRequiredLabel htmlFor="contact">
                        *
                      </StyledRequiredLabel>
                    </StyledLabel>
                    <StyledInput
                      error={false}
                      onChange={(e) => {
                        setAdminData({
                          ...adminData,
                          contact: e.target.value,
                        });
                      }}
                      value={adminData?.contact}
                      id="contact"
                      placeholder="Enter Phone Number"
                      aria-describedby="Phone Number"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="standard" fullWidth>
                    <StyledLabel htmlFor="identifier">
                      Unique name
                      <StyledRequiredLabel htmlFor="identifier">
                        *
                      </StyledRequiredLabel>
                    </StyledLabel>
                    <StyledInput
                      error={false}
                      disabled={isEdit}
                      onChange={(e) => {
                        setAdminData({
                          ...adminData,
                          identifier: e.target.value,
                        });
                      }}
                      value={adminData?.identifier}
                      id="identifier"
                      placeholder="Enter Unique name"
                      aria-describedby="Unique name"
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={6}
                sx={{ marginTop: "-30px", flexDirection: "row" }}
              >
                <Grid item xs={12}>
                  <FormControl variant="standard" fullWidth>
                    <FormControlLabel
                      sx={isEdit ? { opacity: 0.5, pointerEvents: "none" } : {}}
                      control={
                        <Checkbox
                          checked={!enableUserAuth}
                          onChange={(e) => setEnableUserAuth(!e.target.checked)}
                        />
                      }
                      label={
                        <Typography
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          Allow users to continue without login and signup
                          <Tooltip
                            title="You can not edit after add f2p partner"
                            arrow
                          >
                            <InfoOutlinedIcon
                              sx={{
                                color: "#212121",
                                fontSize: "16px",
                                marginLeft: "5px",
                              }}
                            />
                          </Tooltip>
                        </Typography>
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={6} sx={{ marginTop: "-30px" }}>
                <Grid item xs={6}>
                  <FormControl variant="standard" fullWidth>
                    <StyledLabel htmlFor="logo">Company logo</StyledLabel>
                    <Box
                      sx={{
                        position: "relative",
                        display: "inline-block",
                        marginTop: "5px",
                      }}
                    >
                      <label
                        htmlFor="image1"
                        style={{
                          cursor: "pointer",
                          background: "#FAFAFA",
                          display: "block",
                          height: "62px",
                          width: "62px",
                          border: "1px dashed #D9D9D9",
                          borderRadius: "50%",
                          overflow: "hidden",
                        }}
                      >
                        {!logoUrl ? (
                          <span
                            style={{
                              display: "flex",
                              color: "rgb(191 184 184)",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <AddIcon
                              style={{
                                color: "rgb(191 184 184)",
                                width: "14px",
                                height: "14px",
                              }}
                            />
                            <span>Upload</span>
                          </span>
                        ) : null}
                        <input
                          type="file"
                          id="image1"
                          accept=".jpg, .jpeg, .png"
                          // {...register("player1PhotoUrl")}
                          onChange={(e) => handleFileChange(e)}
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            top: 0,
                            left: 0,
                            opacity: 0,
                            zIndex: -1,
                          }}
                        />
                        {logoUrl && (
                          <Avatar
                            sx={{ width: 62, height: 62 }}
                            src={logoUrl}
                          />
                        )}
                      </label>
                    </Box>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={6} sx={{ marginTop: "-30px" }}>
                <Grid item xs={12}>
                  <FormControl variant="standard" fullWidth>
                    <StyledLabel htmlFor="description">
                      Description
                      <StyledRequiredLabel htmlFor="description">
                        *
                      </StyledRequiredLabel>
                    </StyledLabel>
                    <StyledInput
                      error={false}
                      onChange={(e) => {
                        setAdminData({
                          ...adminData,
                          description: e.target.value,
                        });
                      }}
                      multiline
                      rows={2}
                      maxRows={4}
                      value={adminData?.description}
                      id="description"
                      placeholder="Enter Description"
                      aria-describedby="Description"
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={6} sx={{ marginTop: "-30px" }}>
                <Grid item xs={6}>
                  <FormControl variant="standard" fullWidth>
                    <StyledLabel htmlFor="primaryColor">
                      Primary Colour
                      <StyledRequiredLabel htmlFor="primaryColor">
                        *
                      </StyledRequiredLabel>
                    </StyledLabel>
                    <StyledInput
                      error={false}
                      onChange={(e) => {
                        setAdminData({
                          ...adminData,
                          primaryColor: e.target.value,
                        });
                      }}
                      value={adminData?.primaryColor}
                      id="primaryColor"
                      placeholder="Enter Primary  Colour"
                      aria-describedby="Primary  Colour"
                    />
                    <ColorPicker
                      field="primaryColor"
                      onChange={updateColor}
                      defaultColor={adminData?.primaryColor}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="standard" fullWidth>
                    <StyledLabel htmlFor="secondaryColor">
                      Secondary Color
                      <StyledRequiredLabel htmlFor="secondaryColor">
                        *
                      </StyledRequiredLabel>
                    </StyledLabel>
                    <StyledInput
                      error={false}
                      onChange={(e) => {
                        setAdminData({
                          ...adminData,
                          secondaryColor: e.target.value,
                        });
                      }}
                      value={adminData?.secondaryColor}
                      id="secondaryColor"
                      placeholder="Enter Secondary  Colour"
                      aria-describedby="Secondary  Colour"
                    />
                    <ColorPicker
                      field="secondaryColor"
                      onChange={updateColor}
                      defaultColor={adminData?.secondaryColor}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions sx={{ borderTop: "1px solid #F0F0F0" }}>
            {isEdit ? (
              <>
                <Button
                  variant="outlined"
                  size="medium"
                  // disabled={!isValid}
                  onClick={() => handleClose(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  disabled={!isValid || updateLoading}
                  onClick={handleEdit}
                >
                  {updateLoading ? "Loading..." : "Save"}
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="outlined"
                  size="medium"
                  // disabled={!isValid}
                  onClick={() => handleClose(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  disabled={!isValid || createLoading}
                  onClick={handleCreate}
                >
                  {createLoading
                    ? "Loading..."
                    : enableUserAuth
                    ? "Save"
                    : "Generate"}
                </Button>
              </>
            )}
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle
            alignItems="center"
            sx={{ paddingX: "100px", textAlign: "center" }}
          >
            Partner successfully added and Secret key is sent to registered
            email ID
          </DialogTitle>
          <DialogContent>
            <Box sx={{ mt: 2 }}>
              <Typography variant="body1" gutterBottom>
                <strong>Secret Key:-</strong>{" "}
                <span style={{ color: "#B69056" }}>{secretKey}</span>
                <Tooltip title="Copy Secret Key">
                  <CopyIconButton
                    onClick={() => copyToClipboard(secretKey)}
                    size="small"
                  >
                    <ContentCopyIcon
                      fontSize="small"
                      sx={{ color: "#B69056" }}
                    />
                  </CopyIconButton>
                </Tooltip>
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions sx={{ borderTop: "1px solid #F0F0F0" }}>
            <Button
              variant="contained"
              size="medium"
              onClick={() => handleClose(true)}
            >
              CLOSE
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
