export interface ModalProps {
  isOpen: boolean;
  onSubmit: (target: string) => void;
  onClose: () => void;
}

export const SYNC_TOURNAMENTS = "syncTournaments";
export const SYNC_TOURNAMENT_PLAYERS = "syncTournamentPlayers";
export const GET_CURRENT_TOURNAMENT_PLAYERS = "getCurrentTournamentPlayers";
export const GET_TEE_TIME_FOR_CURRENT_TOURNAMENT =
  "getTeeTimeForCurrentTournament";
export const GET_TEE_TIME_BY_ROUND = "getTeeTimeByRound";
export const GRADE_TOURNAMENT = "gradeTournament";
export const VOID_CONTESTS = "voidContests";
export const SEND_CONTEST_GRADED_EMAIL = "sendContestGradedEmail";

export const CRONS = [
  {
    id: SYNC_TOURNAMENTS,
    label: "Sync Tournaments",
  },
  {
    id: SYNC_TOURNAMENT_PLAYERS,
    label: "Sync Tournament Players",
  },
  {
    id: GET_CURRENT_TOURNAMENT_PLAYERS,
    label: "Get Current Tournament Players",
  },
  {
    id: GET_TEE_TIME_FOR_CURRENT_TOURNAMENT,
    label: "Get Tee Time For Current Tournament",
  },
  {
    id: GET_TEE_TIME_BY_ROUND,
    label: "Get Tee Time By Round",
  },
  {
    id: GRADE_TOURNAMENT,
    label: "Grade Tournament",
  },
  {
    id: VOID_CONTESTS,
    label: "Void Contests",
  },
  {
    id: SEND_CONTEST_GRADED_EMAIL,
    label: "Send Contest Graded Email",
  },
];

export * from "./SyncTournaments";
export * from "./SyncTournamentPlayers";
export * from "./GetCurrentTournamentPlayers";
export * from "./GetTeeTimeForCurrentTournament";
export * from "./GetTeeTimeByRound";
export * from "./GradeTournament";
export * from "./VoidContests";
export * from "./SendContestGradedEmail";
