import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Typography, Container, Box } from "@mui/material";

const UnauthorizedAccess = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };

  const handleGoHome = () => {
    navigate("/"); // Redirect to the home page
  };

  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        textAlign="center"
      >
        <Typography variant="h4" gutterBottom>
          Unauthorized Access
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          You do not have permission to view this page. Please contact your
          administrator if you believe this is an error.
        </Typography>
        <Box mt={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleGoBack}
            sx={{ marginRight: 2 }}
          >
            Go Back
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleGoHome}>
            Go to Home
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default UnauthorizedAccess;
