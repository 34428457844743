import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { weeklyPrizeDistributionLabels } from "@src/constants/Touchdown";
import { theme } from "@src/constants/theme";
import {
  useGetWeeklyConstantQuery,
  useUpdateWeeklyConstantMutation,
} from "@src/graphql/graphql.generated";
import { NumberValidationSchema, parseGQLError } from "@src/utils/parse-error";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

const StyledInput = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(1),
  "& input": {
    padding: "9px 12px 9px 12px",

    "&:focus": {
      outline: `none`,
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: `#E2D3BB !important`,
    "&:focus": {
      outline: `none`,
    },
  },
  "& .MuiOutlinedInput-root.Mui-error": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `#d32f2f !important`,
    },
  },
  "& .MuiFormHelperText-root.Mui-error": {
    fontSize: "12px",
    margin: "3px",
  },
}));

interface IValueId {
  id: number;
  value: number;
}

export interface WeeklyPrizeFormValues {
  [key: string]: number;
}

export function WeeklyPrizeDistribution() {
  const [totalReserve, setTotalReserve] = useState<number>(0);

  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<WeeklyPrizeFormValues>({
    mode: "all",
  });

  const {
    data,
    isLoading,
    refetch: refetchConstants,
  } = useGetWeeklyConstantQuery();
  const { mutate: weeklyConstantMutate } = useUpdateWeeklyConstantMutation();

  useEffect(() => {
    if (data?.getWeeklyConstant?.length) {
      data.getWeeklyConstant.forEach(({ id, value }) => {
        setValue(`${id}`, value);
      });
      sumOfPrizeDistribution();
    }
  }, [data]);

  const sumOfPrizeDistribution = () => {
    const sumOfWeeklyReserve = Object.values(getValues())?.reduce(
      (accumulator: number, currentValue: number) =>
        accumulator + +currentValue,
      0
    );
    setTotalReserve(+sumOfWeeklyReserve.toFixed(2));
  };

  const handleOnChange = (event: any) => {
    const value = +event.target.value;
    if (Number.isNaN(value)) return;
    sumOfPrizeDistribution();
  };

  const onFormSubmit = () => {
    if (totalReserve > 100) {
      enqueueSnackbar("Weekly Prize Distribution can not greater than 100% .", {
        variant: "error",
      });
      return;
    }

    const weeklyConstants: IValueId[] = Object.entries(getValues()).map(
      ([key, value]) => ({ id: +key, value: +value } as IValueId)
    );

    weeklyConstantMutate(
      { data: { data: weeklyConstants } },
      {
        onSuccess: () => {
          enqueueSnackbar(
            "Weekly Prize Distribution has been added successfully!",
            {
              variant: "success",
            }
          );
          refetchConstants();
        },
        onError: (error: any) => {
          enqueueSnackbar(
            parseGQLError(error, "Unable to create Weekly Prize Distribution."),
            {
              variant: "error",
            }
          );
        },
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      {data?.getWeeklyConstant.map(({ id }) => (
        <Box marginBottom={theme.spacing(2)} key={id}>
          <Grid container spacing={2}>
            <Grid item xs={2} display="flex" alignItems="center">
              <Typography fontWeight={500} variant="h6">
                {weeklyPrizeDistributionLabels[id]} prize :
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <FormControl variant="standard">
                <StyledInput
                  id={`${id}-prize`}
                  required
                  aria-describedby={`${id}-prize`}
                  InputProps={{
                    "aria-label": `${id} prize`,
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  {...register(id.toString(), {
                    ...NumberValidationSchema,
                    onChange: handleOnChange,
                    min: { value: 0, message: "Value should be at least 0" },
                  })}
                  error={!!errors?.[id]}
                  helperText={errors?.[id]?.message}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      ))}
      <Divider
        sx={{
          marginTop: theme.spacing(3),
          marginBottom: theme.spacing(3),
        }}
      />
      <Stack direction="row" alignItems="center">
        <Stack direction="row" alignItems="center">
          <Typography variant="body1" fontWeight={500}>
            Prize Distributed :
          </Typography>
          <Typography
            variant="body1"
            fontWeight={800}
            marginLeft={theme.spacing(1)}
            sx={{ color: theme.palette.primary.main }}
          >
            {totalReserve} %
          </Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          marginLeft={theme.spacing(3)}
        >
          <Typography variant="body1" fontWeight={500}>
            Total Weekly Reserve :
          </Typography>
          <Typography
            variant="body1"
            fontWeight={800}
            marginLeft={theme.spacing(1)}
            sx={{ color: totalReserve > 100 ? "red" : "green" }}
          >
            {(100 - totalReserve).toFixed(2)} %
          </Typography>
        </Stack>
      </Stack>
      <Box>
        <Grid container>
          <Grid item xs={12}>
            <Button
              disabled={isLoading}
              variant="contained"
              size="large"
              sx={{ width: "140px", marginTop: theme.spacing(3) }}
              type="submit"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
}
