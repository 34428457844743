import dayjs from "dayjs";
import { GridFilterItem, GridFilterOperator } from "@mui/x-data-grid";
import { DateRangeFilterPicker } from "./DateRangeFilterPicker";

export const dateRangeOperators: GridFilterOperator[] = [
  {
    label: "From To",
    value: "fromto",
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }
      if (!filterItem.value[0] || !filterItem.value[1]) {
        return null;
      }

      return (params): boolean => {
        const beforeDate = dayjs(filterItem.value[0] as string);
        const afterDate = dayjs(filterItem.value[1] as string);

        return (
          dayjs(params.value as string).isBefore(afterDate) &&
          dayjs(params.value as string).isAfter(beforeDate)
        );
      };
    },
    InputComponent: DateRangeFilterPicker,
  },
];
