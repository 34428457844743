import { makeStyles } from "@mui/styles";
import { SnackbarProvider as Provider } from "notistack";
import { FC } from "react";

const useStyles = makeStyles({
  success: { backgroundColor: "#FFFFFF!important", color: "#B69056!important" },
});

export const SnackbarProvider: FC = ({ children }) => {
  const classes = useStyles();
  return (
    <Provider
      anchorOrigin={{ horizontal: "center", vertical: "top" }}
      maxSnack={2}
      classes={{ variantSuccess: classes.success }}
    >
      {children}
    </Provider>
  );
};
