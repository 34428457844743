import { useSnackbar } from "notistack";
import {
  useWithdrawRequestActionMutation,
  WithdrawRequestStatus,
} from "@src/graphql/graphql.generated";
import { parseGQLError } from "@src/utils/parse-error";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Box,
  Typography,
} from "@mui/material";
import { WithdrawRequestAction } from "@src/pages/general/WithdrawRequests";

interface ActionDialogProps {
  isOpen: boolean;
  onClose: (refresh: boolean) => void;
  action: WithdrawRequestAction | null;
}

export function ActionDialog({ isOpen, onClose, action }: ActionDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: withDrawAction, isLoading } =
    useWithdrawRequestActionMutation();

  const handleAction = () => {
    action &&
      withDrawAction(
        {
          data: {
            withdrawRequestId: action?.target?.id,
            status: action.status,
          },
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Action has been processed successfully!", {
              variant: "success",
            });
            onClose(true);
          },
          onError: (error: any) => {
            enqueueSnackbar(parseGQLError(error, "Unable to perform action"), {
              variant: "error",
            });
          },
        }
      );
  };

  if (!action) {
    return null;
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={onClose}>
      <DialogTitle>
        {action.status === WithdrawRequestStatus.Approved
          ? "Approve"
          : "Reject"}{" "}
        Withdraw Request
      </DialogTitle>
      <DialogContent>
        <Box component="main">
          <Stack spacing={2}>
            <Typography>
              Do you want to{" "}
              {action.status === WithdrawRequestStatus.Approved
                ? "approve"
                : "reject"}{" "}
              this withdraw request?
            </Typography>
            <Typography>
              Email: <strong>{action.target.user.email}</strong>
            </Typography>
            <Typography>
              Amount: <strong>{action.target.amount}</strong>
            </Typography>
            <Button
              variant="contained"
              onClick={handleAction}
              disabled={isLoading}
            >
              {action.status === WithdrawRequestStatus.Approved
                ? "Approve"
                : "Reject"}{" "}
            </Button>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
