import { GridColumns } from "@mui/x-data-grid";
import {
  GetTouchdownByDateQuery,
  PrizePoolStatus,
  SupportedSports,
} from "@src/graphql/graphql.generated";

export interface WeeklyCounts {
  totalEntries: number;
  receivedFees: number;
  totalTopPropVig: number;
  actualTotalWeeklyReserve: number;
  totalWeeklyPrize: number;
  moneyAddedByTopProp: number;
  profitLoss: number;
  [key: string]: number;
}

export interface WeeklyTouchDownProps {
  title: string;
  columns: GridColumns;
  weeklyTouchdownData: GetTouchdownByDateQuery | undefined;
}

export interface DailyLeaderboardProps {
  prizePoolId: number;
  title: string;
  parentComponent: string;
  onBack: () => void;
}

export interface WeeklyLeaderboardProps {
  title: string;
  weeklyTouchdownData?: GetTouchdownByDateQuery | undefined;
  renderBack?: boolean;
  touchdownId?: number;
  parentComponent: string;
  onBack?: () => void;
}

export interface PrizePoolDetails {
  prizePoolId: number;
  startDate: string;
  endDate: string;
  supportedSports: Array<SupportedSports>;
  entryFees: number;
  lockTime?: string | null;
  totalEntrants: number;
  maxEntriesPerUser: number;
  isRollsOver: boolean;
  predeterminePrizePool: number;
  predetermineDailyGPP: number;
  predetermineWeeklyReserve: number;
  predetermineJackpot: number;
  predetermineContestVig: number;
  predetermineRolloverVig: number;
  status: PrizePoolStatus;
  actualPrizePool?: number | null;
  actualDailyGPP?: number | null;
  actualWeeklyReserve?: number | null;
  actualJackpot?: number | null;
  actualContestVig?: number | null;
  actualRolloverVig?: number | null;
  userEntryCount: number;
  jackpotWinnersCount: number;
  gppWinnersCount: number;
  nbaContestsCount: number;
  mlbContestsCount: number;
  pgaContestsCount: number;
  nflContestsCount: number;
  soccerContestsCount: number;
  profit?: number | null;
  addedByTopProp: number;
}

export interface TouchdownDetails {
  touchdownId: number;
  startDate?: string | null;
  endDate?: string | null;
  touchDownType: string;
  mathConstant: { PRIZE_POOL: number; GPP: number; WEEKLY_RESERVE: number };
  weeklyPrizeDistribution: { prizes: Array<{ id: number; value: number }> };
  prizePools?: Array<PrizePoolDetails> | null | [];
}

export interface PartnerTouchdownDetails {
  id: number;
  name: string;
  startDateTime: string;
  endDateTime: string;
  allowedPick: number;
  entryFees: number;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string | null;
  prizePools: Array<{
    startDateTime: string;
    endDateTime: string;
    allowedSports: Array<number>;
    lockTime: string;
    maxEntriesPerUser: number;
    totalEntrants: number;
    totalPrize: number;
    jackpotAmount: number;
    gppAmount: number;
    contestVig: number;
    id: number;
    status: PrizePoolStatus;
    isRollsOver: boolean;
    weeklyReserveAmount: number;
    predetermineJackpotAmount: number;
    rolloverAmount: number;
    rolloverJackpotAmount: number;
    rolloverVig: number;
    createdAt: string;
    updatedAt: string;
  }>;
}

export interface F2pPartnerTouchdownDetails {
  id: number;
  name: string;
  startDateTime: string;
  endDateTime: string;
  allowedPick: number;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string | null;
  entryFees?: number;
  prizePools: Array<{
    startDateTime: string;
    endDateTime: string;
    allowedSports: Array<number>;
    lockTime: string;
    maxEntriesPerUser: number;
    totalEntrants: number;
    id: number;
    status: PrizePoolStatus;
    createdAt: string;
    updatedAt: string;
  }>;
}

export interface F2pPartnerPrizePoolDetails {
  id: number;
  startDateTime: string;
  endDateTime: string;
  allowedSports: Array<number>;
  lockTime: string;
  maxEntriesPerUser: number;
  totalEntrants: number;
  status: PrizePoolStatus;
  createdAt: string;
  updatedAt: string;
}

export interface PartnerPrizePoolDetails {
  id: number;
  startDateTime: string;
  endDateTime: string;
  allowedSports: Array<number>;
  lockTime: string;
  maxEntriesPerUser: number;
  totalEntrants: number;
  totalPrize: number;
  jackpotAmount: number;
  gppAmount: number;
  contestVig: number;
  status: PrizePoolStatus;
  isRollsOver: boolean;
  weeklyReserveAmount: number;
  predetermineJackpotAmount: number;
  rolloverAmount: number;
  rolloverJackpotAmount: number;
  rolloverVig: number;
  createdAt: string;
  updatedAt: string;
}

export interface WeeklyTouchdownRow {
  days: string;
  status: string;
  entryFees: string;
  totalEntrants: string;
  userEntryCount: string;
  prizePool: string;
  isRollsOver: string;
  predetermineJackpot: string;
  jackpotWinnersCount: string;
  predetermineDailyGPP: string;
  gppWinnersCount: string;
  predetermineContestVig: string;
  addedByTopProp: string;
  profit?: number | null;
  nbaContestsCount: string;
  nflContestsCount: string;
  dailyLeaderBoard: string;
}

export const WEEKLY_COUNT_LABELS = [
  {
    key: "totalEntries",
    title: "Total Entries",
  },
  {
    key: "receivedFees",
    title: "Received Fees",
  },
  {
    key: "totalTopPropVig",
    title: "Total topprop vig",
  },
  {
    key: "actualTotalWeeklyReserve",
    title: "Total weekly reserve",
  },
  {
    key: "totalWeeklyPrize",
    title: "Weekly prize",
  },
  {
    key: "moneyAddedByTopProp",
    title: "Money added by topprop",
  },
  {
    key: "profitLoss",
    title: "Profit/ Loss",
  },
];

export const initTotals = {
  totalEntries: 0,
  receivedFees: 0,
  totalTopPropVig: 0,
  actualTotalWeeklyReserve: 0,
  totalWeeklyPrize: 0,
  moneyAddedByTopProp: 0,
  profitLoss: 0,
};

export const initTouchdown = {
  touchdownId: 0,
  startDate: "",
  endDate: "",
  touchDownType: "",
};
