import { SignedInLayout } from "@src/components/templates/Layouts";
import { ContestDetails } from "@src/components/touchDown/Contest/ContestDetails";

import TouchdownCreation from "@src/components/touchDown/Contest/TouchdownCreation";
import WeeklySubscriber from "@src/components/touchDown/Contest/WeeklySubscriber";
import { WeeklyLeaderboard } from "@src/components/touchDown/Dashboard/WeeklyLeaderboard";
import { MM_DD_YYYY, YYYY_MM_DD } from "@src/constants/Touchdown";
import { useGetTouchdownByDateQuery } from "@src/graphql/graphql.generated";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";

// const [isModalOpen, setIsModalOpen] = useState(false);

// const openModal = () => {
//   setIsModalOpen(true);
// };

// const closeModal = () => {
//   setIsModalOpen(false);
// };

interface RenderWeeklyLeaderboardProps {
  name: string;
  weekRange: dayjs.Dayjs[];
}

export function RenderWeeklyLeaderboard(props: RenderWeeklyLeaderboardProps) {
  const { name, weekRange } = props;
  const getTitle = (text: string): string =>
    `${text} ( ${weekRange[0].format(MM_DD_YYYY)} to ${weekRange[1].format(
      MM_DD_YYYY
    )} ) `;

  const { data: weeklyTouchdownData } = useGetTouchdownByDateQuery({
    startDate: weekRange[0].format(YYYY_MM_DD),
    endDate: weekRange[1].format(YYYY_MM_DD),
  });

  return (
    <WeeklyLeaderboard
      key={name}
      title={getTitle("Weekly Leaderboard")}
      weeklyTouchdownData={weeklyTouchdownData}
      renderBack
      parentComponent="contest"
    />
  );
}

export function Contest() {
  const location = useLocation();
  const { state: locationState } = location;
  const weekRange: Array<dayjs.Dayjs> = [
    dayjs().startOf("week"),
    dayjs().endOf("week"),
  ];

  const renderComponent = (name: string | unknown) => {
    switch (name) {
      case "touchdown":
        return <TouchdownCreation />;

      case "weeklySubscriber":
        return <WeeklySubscriber currentWeekRange={weekRange} />;

      case "weeklyLeaderboard":
        return <RenderWeeklyLeaderboard name={name} weekRange={weekRange} />;

      default:
        return <ContestDetails currentWeekRange={weekRange} />;
    }
  };

  return (
    <SignedInLayout title="Create Touchdown">
      {/* <ModalPopup isOpen={isModalOpen} onClose={closeModal} /> */}
      {renderComponent(locationState)}
    </SignedInLayout>
  );
}
