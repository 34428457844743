import { Info } from "@mui/icons-material";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Alert,
  Avatar,
  Box,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  MenuProps,
  Paper,
  Select,
  Stack,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import { QueryKeys } from "@src/constants/QueryKeys";
import { theme } from "@src/constants/theme";
import {
  useDeleteF2PHubContestMutation,
  useFetchF2PHubContestsQuery,
  usePublishF2PHubContestMutation,
} from "@src/graphql/graphql.generated";
import { ReactComponent as CrownIcon } from "@src/icons/crown.svg";
import { parseGQLError } from "@src/utils/parse-error";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { CreateMatchUpsForm } from "./CreateMatchUpsForm";
import EmptyContainer from "./EmptyContainer";
import { GradeContestForm } from "./GradeContestForm";

const StyledTypoItem = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 500,
}));

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow: "rgb(0 0 0 / 20%) 0px 0px 1px 0px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const contestStyles: any = {
  draft_background: "rgba(249, 249, 249, 1)",
  draft_border: "1px solid rgba(204, 204, 204, 1)",
  cancelled_background: "rgba(255, 240, 241, 1)",
  cancelled_border: "1px solid rgba(235, 23, 69, 1)",
  published_background: "rgba(255, 252, 246, 1)",
  published_border: "1px solid rgba(226, 211, 187, 1)",
  completed_background: "rgba(245, 255, 249, 1) ",
  completed_border: "1px solid rgba(189, 227, 202, 1)",
};

enum SortObj {
  createdAsc = "Created time",
  createdDesc = "Created time",
  matchStartAsc = "Match time",
  matchStartDesc = "Match time",
}

export const PlayersDashboard = ({
  partnerId,
  gameData,
  selectedDate,
  updateContestCount,
  paddingTop = "40%",
  prizePoolId,
  touchdowns,
}: any) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: deleteContest } = useDeleteF2PHubContestMutation();
  const { mutate: publishHubContest } = usePublishF2PHubContestMutation();
  const {
    data: hubContestData,
    isLoading: isFetchHubContestLoading,
    isError: isFetchHubContestError,
  } = useFetchF2PHubContestsQuery({
    date: selectedDate,
    sportId: gameData?.id,
    prizePoolId: prizePoolId,
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const [typeOfForm, setTypeOfForm] = useState<string>("create");
  const [selectedContestData, setSelectedContestData] = useState<any>({});
  const [hubContests, setHubContests] = useState<Array<any>>([]);
  const [selectedSort, setSelectedSort] = useState("createdAsc");

  const getSpecificForm = (type: string) => {
    switch (type) {
      case "edit":
        return (
          <CreateMatchUpsForm
            partnerId={partnerId}
            formData={selectedContestData}
            selectedDate={selectedDate}
            type="edit"
            isDisabled={true}
            updateContestCount={updateContestCount}
            setTypeOfForm={setTypeOfForm}
            touchdowns={touchdowns}
          />
        );

      case "grade":
        return (
          <GradeContestForm
            contestData={selectedContestData}
            setTypeOfForm={setTypeOfForm}
          />
        );

      case "create":
        return (
          <CreateMatchUpsForm
            partnerId={partnerId}
            formData={gameData}
            selectedDate={selectedDate}
            type="create"
            isDisabled={false}
            updateContestCount={updateContestCount}
            setTypeOfForm={setTypeOfForm}
            touchdowns={touchdowns}
          />
        );

      default:
        return <div />;
    }
  };

  const handleClick = (event: any, index: number, playerData: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedContestData({ ...playerData, sportId: gameData });
  };

  const handleClose = (type: string) => {
    if (type === "grade") {
      setTypeOfForm("grade");
    } else if (type === "edit") {
      setTypeOfForm("edit");
    } else if (type === "publish") {
      publishHubContest(
        {
          contestId: selectedContestData?.id,
          // sportName: selectedContestData?.sportId?.name,
          published: !selectedContestData?.published,
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Contest publish successfully.", {
              variant: "success",
            });
            queryClient.invalidateQueries(QueryKeys.FetchF2PHubContests);
          },
          onError: (error: any) => {
            enqueueSnackbar(
              parseGQLError(error, "Error while publishing contest."),
              {
                variant: "error",
              }
            );
          },
        }
      );
    } else if (type === "delete") {
      deleteContest(
        {
          contestId: selectedContestData?.id,
          // sportName: selectedContestData?.sportId?.name,
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Contest deleted successfully.", {
              variant: "success",
            });
            queryClient.invalidateQueries(QueryKeys.FetchF2PHubContests);
            queryClient.invalidateQueries(QueryKeys.FetchF2PContests);
            updateContestCount(gameData?.id, false);
          },
          onError: (error: any) => {
            enqueueSnackbar(
              parseGQLError(error, "Error while deleting contest."),
              {
                variant: "error",
              }
            );
          },
        }
      );
    }
    setAnchorEl(null);
  };

  const getContestStyle = (contest: any, styleName: string) => {
    if (contest.status === "completed" || contest.status === "cancelled") {
      return contestStyles[`${contest.status}_${styleName}`];
    } else if (!contest.published) {
      return contestStyles[`draft_${styleName}`];
    } else {
      return contestStyles[`published_${styleName}`];
    }
  };

  useEffect(() => {
    if (hubContestData?.fetchF2PHubContests) {
      setHubContests(hubContestData.fetchF2PHubContests);
    }
  }, [hubContestData]);

  const sortHubContests = (event: any) => {
    const value = event?.target?.value;
    setSelectedSort(value);
    if (value === "matchStartAsc") {
      const sortedContests = [...hubContests].sort((contest1, contest2) => {
        const {
          player1GameStartTime: contest1player1GameStartTime,
          player2GameStartTime: contest1player2GameStartTime,
        } = contest1;

        const {
          player1GameStartTime: contest2player1GameStartTime,
          player2GameStartTime: contest2player2GameStartTime,
        } = contest2;

        const contest1MinStartTime: dayjs.Dayjs | null = dayjs?.min(
          dayjs(contest1player1GameStartTime),
          dayjs(contest1player2GameStartTime)
        );

        const contest2MinStartTime: dayjs.Dayjs | null = dayjs?.min(
          dayjs(contest2player1GameStartTime),
          dayjs(contest2player2GameStartTime)
        );

        return contest1MinStartTime!.diff(contest2MinStartTime, "second");
      });

      setHubContests(sortedContests);
    } else if (value === "matchStartDesc") {
      const sortedContests = [...hubContests].sort((contest1, contest2) => {
        const {
          player1GameStartTime: contest1player1GameStartTime,
          player2GameStartTime: contest1player2GameStartTime,
        } = contest1;

        const {
          player1GameStartTime: contest2player1GameStartTime,
          player2GameStartTime: contest2player2GameStartTime,
        } = contest2;

        const contest1MaxStartTime: dayjs.Dayjs | null = dayjs?.max(
          dayjs(contest1player1GameStartTime),
          dayjs(contest1player2GameStartTime)
        );

        const contest2MaxStartTime: dayjs.Dayjs | null = dayjs?.max(
          dayjs(contest2player1GameStartTime),
          dayjs(contest2player2GameStartTime)
        );

        return contest2MaxStartTime!.diff(contest1MaxStartTime, "second");
      });
      setHubContests([...sortedContests]);
    } else if (value === "createdDesc") {
      const sortedContests = [...hubContests].sort((contest1, contest2) => {
        const { createdAt: contest1CreatedAt } = contest1;
        const { createdAt: contest2CreatedAt } = contest2;

        return dayjs(contest2CreatedAt)!.diff(
          dayjs(contest1CreatedAt),
          "second"
        );
      });
      setHubContests([...sortedContests]);
    } else if (hubContestData) {
      setHubContests([...hubContestData.fetchF2PHubContests]);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={7}>
        {!isFetchHubContestLoading && hubContests.length === 0 ? (
          <EmptyContainer paddingTop={paddingTop} />
        ) : (
          <Box
            sx={{
              border: "1px solid #EEE8DC",
              backgroundColor: "#fff",
              padding: theme.spacing(2),
              marginTop: theme.spacing(1),
              height: "1000px",
              overflowX: "scroll",
            }}
          >
            {isFetchHubContestLoading && !isFetchHubContestError && (
              <Box
                py={2}
                sx={{ fontSize: "20px", textAlign: "center", marginTop: "50%" }}
              >
                Loading...
                <CircularProgress size={24} />
              </Box>
            )}
            {!isFetchHubContestLoading && (
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"end"}
                pb={2}
              >
                <Typography fontSize={20}>Sort by &nbsp;</Typography>
                <FormControl>
                  <Select
                    id="sort-menu"
                    onChange={sortHubContests}
                    value={selectedSort}
                    sx={{
                      "#sort-menu": {
                        paddingTop: "10px",
                        paddingBottom: "10px",
                      },
                    }}
                  >
                    {Object.entries(SortObj).map(([value, label], index) => (
                      <MenuItem key={index + 1} value={value}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          {value.includes("Asc") ? (
                            <ArrowUpwardIcon fontSize="small" />
                          ) : (
                            <ArrowDownward fontSize="small" />
                          )}
                          <Typography fontSize={14}>{label}</Typography>
                        </Stack>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}

            {!isFetchHubContestLoading && !gameData?.enabled && (
              <Alert
                icon={<Info fontSize="inherit" />}
                severity="warning"
                sx={{ mb: "15px" }}
              >
                Contests cannot be created or updated for this sport as it is
                currently disabled.
              </Alert>
            )}

            {hubContests.map((contest, index) => {
              return (
                <Paper
                  key={contest.id}
                  sx={{
                    position: "relative",
                    marginBottom: theme.spacing(2),
                    padding: "6px",
                    backgroundColor: getContestStyle(contest, "background"),
                    border: getContestStyle(contest, "border"),
                  }}
                  elevation={0}
                >
                  <Stack
                    sx={{ padding: 1 }}
                    justifyContent="space-between"
                    alignItems="center"
                    direction="row"
                    spacing={2}
                  >
                    {/* ==========  Player 1 ========== */}
                    <Stack flex={2}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Box sx={{ position: "relative" }}>
                          <Avatar
                            sx={{ width: 48, height: 48 }}
                            src={contest?.player1PhotoUrl}
                          />
                          {contest.status === "cancelled" &&
                          contest.player1FantasyPoints === 0 ? (
                            <Chip
                              label="out"
                              size="small"
                              variant="outlined"
                              sx={{
                                backgroundColor: "#B43B2B",
                                color: "#fff",
                                borderRadius: "2px",
                                position: "absolute",
                                bottom: "-7px",
                                left: "7px",
                                height: "18px",
                                fontWeight: 500,
                              }}
                            />
                          ) : null}
                        </Box>
                        <Box>
                          <Stack direction="row">
                            <StyledTypoItem
                              sx={{
                                padding: "0px 10px",
                              }}
                            >
                              {contest.player1FullName}
                            </StyledTypoItem>

                            {contest.status === "completed" &&
                              contest.winnerLabel === "player1" && (
                                <Box
                                  component={CrownIcon}
                                  sx={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                    marginInlineStart: 1,
                                    mt: "3px",
                                  }}
                                />
                              )}
                          </Stack>
                          {contest?.player1BonusPoints ? (
                            <Chip
                              label={`+${contest?.player1BonusPoints} ${contest?.questionId?.metric} `}
                              size="small"
                              sx={{
                                backgroundColor: "rgba(22, 77, 52, 1)",
                                color: "#fff",
                                marginTop: "3px",
                                fontSize: "11px",
                                fontWeight: 700,
                                height: "auto",
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </Box>
                      </Stack>
                    </Stack>
                    <Typography flex={1} variant="h4">
                      Vs
                    </Typography>
                    {/* ==========  Player 2 ========== */}
                    <Stack flex={2} alignItems="flex-start">
                      <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <Box sx={{ position: "relative" }}>
                          <Avatar
                            sx={{ width: 48, height: 48 }}
                            src={contest?.player2PhotoUrl}
                          />
                          {contest.status === "cancelled" &&
                          contest.player2FantasyPoints === 0 ? (
                            <Chip
                              label="out"
                              size="small"
                              variant="outlined"
                              sx={{
                                backgroundColor: "#B43B2B",
                                color: "#fff",
                                borderRadius: "3px",
                                position: "absolute",
                                bottom: "-7px",
                                left: "7px",
                                height: "18px",
                                fontWeight: 500,
                              }}
                            />
                          ) : null}
                        </Box>

                        <Box>
                          <Stack direction="row">
                            <StyledTypoItem
                              sx={{
                                padding: "0px 10px",
                              }}
                            >
                              {contest?.player2FullName}
                            </StyledTypoItem>

                            {contest.status === "completed" &&
                              contest.winnerLabel === "player2" && (
                                <Box
                                  component={CrownIcon}
                                  sx={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                    marginInlineStart: 1,
                                    mt: "3px",
                                  }}
                                />
                              )}
                          </Stack>
                          {contest.player2BonusPoints ? (
                            <Chip
                              label={`+${contest.player2BonusPoints} ${contest?.questionId?.metric}`}
                              size="small"
                              sx={{
                                backgroundColor: "rgba(22, 77, 52, 1)",
                                color: "#fff",
                                marginTop: "3px",
                                fontSize: "11px",
                                fontWeight: 700,
                                height: "auto",
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </Box>
                      </Stack>
                    </Stack>
                  </Stack>

                  {gameData?.enabled && contest.status !== "completed" ? (
                    <IconButton
                      sx={{
                        position: "absolute",
                        right: 0,
                        top: 0,
                        bottom: 0,
                        cursor: "pointer",
                      }}
                      onClick={(event) => handleClick(event, index, contest)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  ) : null}

                  {contest.status === "cancelled" ? (
                    <Box sx={{ paddingX: 1, pb: 1 }}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          color: "rgba(235, 23, 69, 1)",
                          fontWeight: "500",
                        }}
                      >
                        Player Ruled out
                      </Typography>
                    </Box>
                  ) : (
                    <>
                      {!contest.published ? (
                        <Box sx={{ paddingX: 1, pb: 1 }}>
                          <Typography
                            sx={{
                              fontSize: "16px",
                              color: "rgba(154, 154, 154, 1)",
                              fontWeight: "500",
                            }}
                          >
                            Draft
                          </Typography>
                        </Box>
                      ) : (
                        ""
                      )}
                    </>
                  )}

                  <StyledMenu
                    id="contest-actions-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    {selectedContestData.status !== "cancelled" &&
                      selectedContestData.status !== "completed" && (
                        <MenuItem onClick={() => handleClose("edit")}>
                          Edit Contest
                        </MenuItem>
                      )}

                    {selectedContestData.status !== "cancelled" &&
                      selectedContestData.published && (
                        <MenuItem onClick={() => handleClose("grade")}>
                          Grade Contest
                        </MenuItem>
                      )}
                    {!selectedContestData.published && (
                      <MenuItem onClick={() => handleClose("publish")}>
                        Publish Contest
                      </MenuItem>
                    )}
                    {(selectedContestData.status === "upcoming" ||
                      selectedContestData.status === "cancelled") && (
                      <MenuItem onClick={() => handleClose("delete")}>
                        Delete Contest
                      </MenuItem>
                    )}
                  </StyledMenu>
                </Paper>
              );
            })}
          </Box>
        )}
      </Grid>
      <Grid sx={{ marginLeft: "48px", marginTop: "6px" }} item xs={4}>
        <Box sx={{ backgroundColor: "#FAF2E2", padding: "16px 16px 0px 16px" }}>
          {getSpecificForm(typeOfForm)}
        </Box>
      </Grid>
    </Grid>
  );
};
